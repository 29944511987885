import { Grid, List, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { URLParams, getUnitsWithStatus, selectColumns } from "../../../../utils";
import { DeviceColumnsMode } from "../../../devices";
import { IfullUnit, unitStatus, useUnits } from "../../../unit";
import { UnitsRecords } from "../UnitsRecords";
import { RemoveUnit } from "./RemoveUnit";
import { RestoreUnit } from "./RestoreUnit";

export function DisplayUnits({ unitIds, planID, type }: {unitIds: number[], planID: number, type: 'Remove' | 'Restore'} ){
    const [ units, setUnits ] = useState<IfullUnit[]>([])
    const unitParam = new URLParams();
    if(type === 'Remove'){
        unitParam.includes(
        'device',
        'simCard',
        'vehicle',
        'vehicle.organization as org',
        'tasks',
        'purchaseOrderPlan'
        )
        unitParam.select('id', 
          'vehicle.id','vehicle.vin', 'simCard.id', 'simCard.status',
          ...selectColumns(DeviceColumnsMode, {join: 'device', exclude: ['purchaseDate']}), 
          'org.id', 'org.name', 'org.status',
          'purchaseOrderPlan.id', 'purchaseOrderPlan.status',
          'tasks.id', 'tasks.type', 'tasks.status'
        )
    }else {
        unitParam.includes(
        'device',
        'simCard',
        'vehicle',
        'vehicle.organization as org',
        'deviceConfig',
        'tasks',
        )
        unitParam.select('id', 
          'vehicle.id','vehicle.vin', 'simCard.id', 'simCard.status',
          ...selectColumns(DeviceColumnsMode, {join: 'device', exclude: ['purchaseDate']}), 
          'org.id', 'org.name', 'org.status',
          'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform',
          'tasks.id', 'tasks.type', 'tasks.status'
        )
    }
    if(unitIds.length) unitParam.searchMany('id', unitIds.map((id) => `${id}`))
    else unitParam.searchMany('id', ['0'])
    const { data: unitData } = useUnits({params: unitParam.toString()})
    useEffect(() => {
    if (unitData) {
      setUnits(getUnitsWithStatus(unitData.data.items))
    }
  }, [unitData])
  if(type === 'Remove'){
      return(
        <Grid>
            <Grid.Col span={2}>
            <Text>Status:</Text>
            <List>
                {units.map((unit) => <List.Item key={`status-${unit.id}`}> {unit.status}</List.Item>)}
            </List>
            </Grid.Col>
            <Grid.Col span={3}>
            <Text>Devices:</Text>
            <List>
                {units.map((unit) => <List.Item key={`device-${unit.device?.id}`}> {unit.device?.imei}</List.Item>)}
            </List>
            </Grid.Col>
            <Grid.Col span={3}>
            <Text>Vehicles:</Text>
            <List>
                {units.map((unit, index) => <List.Item key={`vehicle-${index}-${unit.vehicle?.id}`}>{unit.vehicle?.vin}</List.Item>)}
            </List>
            </Grid.Col>
            <Grid.Col span={1}>
            <Text>Remove Unit:</Text>
            <List>
                {units.map((unit) => <List.Item key={unit.id} ><RemoveUnit  planID={planID} unitID={unit.id} status={unit.status === unitStatus.Not_Active || unit.status === unitStatus.Pending_Removal || unit.status === unitStatus.To_Be_Approved_Removal} /></List.Item>)}
            </List>
            </Grid.Col>
            <Grid.Col span={1}>
            <Text>Tasks:</Text>
            <List>
                {units.map((unit) => <List.Item key={unit.id} ><UnitsRecords id={unit.id} /></List.Item>)}
            </List>
            </Grid.Col>
        </Grid>
      )
  }else {
    return (
        <Grid>
            <Grid.Col span={3}>
            <Text>Platforms:</Text>
            <List>
                {units.map((unit) => <List.Item key={`platform-${unit.deviceConfig?.id}`}> {unit.deviceConfig.platform ?? ''}{unit.deviceConfig.secondaryPlatform? ' + ' + unit.deviceConfig.secondaryPlatform : ''}</List.Item>)}
            </List>
            </Grid.Col>
            <Grid.Col span={3}>
            <Text>Devices:</Text>
            <List>
                {units.map((unit) => <List.Item key={`device-${unit.device?.id}`}> {unit.device?.imei ?? ''}</List.Item>)}
            </List>
            </Grid.Col>
            <Grid.Col span={3}>
            <Text>Vehicles:</Text>
            <List>
                {units.map((unit, index) => <List.Item key={`vehicle-${index}-${unit.vehicle?.id}`}>{unit.vehicle?.vin}</List.Item>)}
            </List>
            </Grid.Col>
            <Grid.Col span={3}>
            <Text>Restore Unit:</Text>
                {units.map((unit) => <RestoreUnit key={unit.id} planID={planID} unitID={unit.id} status={unit.purchaseOrderPlan ? true : false} />)}
            </Grid.Col>
        </Grid>
    )
  }
}