export enum TaskStatus {
  Pending = 'pending',
  ToBeApproved = 'to be approved',
  Done = 'done',
  Cancelled = 'cancelled',
  onHold = 'on hold',
  NoShow = 'client no show',
  TechinicianNoShow = 'technician no show',
}
export enum TaskStatusColor {
  pending = '#bf5858',
  to_be_approved = '#bf9658',
  done = '#5c8f43',
  cancelled = '#3857b5',
  on_hold = '#6458bf',
  no_show = '#b5a638',
  technician_no_show = '#9e99d8',
}

export enum TaskType {
  Installation = 'installation',
  Removal = 'removal',
  ReInstallation = 're Installation',
  DeviceChange = 'device change',
  SimCardChange = 'simCard change',
  Inspection = 'inspection',
  Upgrade = 'upgrade',
  AccessoryInstallation = 'accessory installation',
  Other = 'other',
}

export enum TaskTypeColor {
  installation = '#5c8f43',
  removal = '#bf5858',
  device_change = '#be58bf',
  simCard_change = '#bf9658',
  inspection = '#6458bf',
  upgrade = '#b5a638',
  re_Installation = '#9e99d8',
  accessory_installation = '#b5a638',
  other = '#9e99d8',
}

export enum TaskTypeText {
  installation = 'Install',
  removal = 'Remove',
  device_change = 'Change Device',
  simCard_change = 'Change Sim',
  inspection = 'Inspect',
  re_Installation = 'Reinstall',
  accessory_installation = 'Accessory Installation',
  upgrade = 'Upgrade',
  other = 'Other',
}

export enum TaskColumnsMode {
  ID = 'id',
  Title = 'title',
  Type = 'type',
  RecipientGroup = 'recipientGroup',
  Address = 'address',
  TargetDate = 'targetDate',
  CompletionDate = 'completionDate',
  TaskGroup = 'taskGroup',
  TaskRemarks = 'taskRemarks',
  Status = 'status',
  Location = 'location',
  JobRemarks = 'jobRemarks',
  ContactName = 'contactName',
  ContactNumber = 'contactNumber',
  ImageRepositoryId = 'imageRepositoryId',
  CreatedAt = 'createdAt',
}
