import { Button, LoadingOverlay } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from 'react-query';
import { types } from '../../../constants';
import { IauthUser } from '../../../context/AuthContext';
import { IaxiosError } from '../../common';
import { postAttendance } from '../api/postAttendance';
import { IattendanceInput } from '../types/types';
import { useLocation } from '../../../utils';

type timeIn = Omit<IattendanceInput, 'timeOut' | 'timeOutSubmittedAt' | 'remarks' | 'status' | 'timeOutLatitude' | 'timeOutLongitude'>;

export function FormTimeIn({ user, day }: { user: IauthUser | null; day: Date }) {
  const { location, error, getLocation } = useLocation();
  const formTimeIn = useForm<timeIn>({
    initialValues: {
      id: 0,
      day: new Date(day.getFullYear(), day.getMonth(), day.getDate(), 4),
      user: user ? { id: user.id } : { id: 0 },
      timeIn: new Date(),
      timeInSubmittedAt: new Date(),
      timeInLatitude: null,
      timeInLongitude: null,
    },
  });

  const queryClient = useQueryClient();
  const { mutate: newMutate, isLoading } = useMutation(postAttendance, {
    onSuccess: () => {
      queryClient.invalidateQueries([types.Attendance, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: 10000,
      });
    },
  });

  function handleSubmit(values: timeIn) {
    if (values) {
      values.timeInSubmittedAt = new Date();
      values.day = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 4);
      if (location) {
        values.timeInLatitude = `${location.lat}`;
        values.timeInLongitude = `${location.lng}`;
      }
      if (error) {
        notifications.show({
          title: 'Error',
          message: `${error}`,
          color: 'red',
          autoClose: 10000,
        });
      }
      newMutate(values);
      // console.log(values);
    }
  }

  return (
    <form onSubmit={formTimeIn.onSubmit((values) => handleSubmit(values))}>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      {/* <DatePickerInput 
        label='Day'
        required
        {...formTimeIn.getInputProps('day')}
        onChange={(v)=> {
          v&& formTimeIn.setFieldValue('day', new Date(v.getFullYear(), v.getMonth(), v.getDate(),4))
        }}
       /> */}
      <TimeInput required label="Time In:" {...formTimeIn.getInputProps('timeIn')} onClick={getLocation} />

      <Button fullWidth mt="sm" type="submit">
        Submit
      </Button>
    </form>
  );
}
