import { Carousel } from '@mantine/carousel';
import { ActionIcon, Badge, Card, Group, Image, Modal, Paper, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { TbPencil, TbTrash } from 'react-icons/tb';
import { IcollectionLink, getImageCollection } from '../../modules/common';
import { DisplayImg } from './DisplayImg';

export function DisplayCollection({cardHei,image}:{cardHei:number,image?: number | null}){
  const [imageLink, setImageLink] = useState<IcollectionLink[]>([]);


  useEffect(() => {
    if (image) {
      getImageCollection(image).then((collection) => {
        setImageLink(collection.data.collection.reverse());
        return collection;
      });
    }
  }, [image]);

  const slides = imageLink.filter(item => item).map((item) => (
    <Carousel.Slide key={item.id}>
      <IMG imageLink={item} cardHei={cardHei} />
    </Carousel.Slide>
  ));

  return (
    <Paper h={cardHei} withBorder>
        <Carousel
        slideSize="33.333%"
        slidesToScroll={3}
        slideGap='md'
        breakpoints={[{ maxWidth: 'sm', slideSize: '50%' }]}
        h={300}
        align="start"
        withControls={imageLink.length > 1}
        styles={{
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
        >
        {slides}
        </Carousel>
    </Paper>
  );
}

function IMG({imageLink,cardHei}:{imageLink: IcollectionLink,cardHei:number}) {
  const [opened, { open, close }] = useDisclosure(false);
  const tags = imageLink.tags.split(',').map(tag => {
    return(
        <Badge key={tag}>{tag}</Badge>
    )
  })


  return (
    <Card m='xs' withBorder mah={cardHei-10}>
      <Group position='right'>
        <Tooltip label="View">
            <ActionIcon variant="subtle" 
            onClick={async () => {
              await fetch(`${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${imageLink?.path}`)
              .then((res) => res.blob())
              .then((myimg) => {
                const download = document.createElement("a");
                const link = window.URL.createObjectURL(myimg);
                download.href = link;
                download.download = imageLink.tags;
                download.click();
                window.URL.revokeObjectURL(link);
              });
            }}><AiOutlineDownload size="1rem" /></ActionIcon>
        </Tooltip>
        <Tooltip label="Edit">
            <ActionIcon variant="subtle" onClick={open}><TbPencil size="1rem" /></ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
            <ActionIcon variant="subtle"><TbTrash size="1rem" /></ActionIcon>
        </Tooltip>
      </Group>

      {imageLink.path.includes('pdf') ?
      <embed src={`${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${imageLink.path}`} height={300} /> 
      : 
        <Image 
        fit="contain"
        height={300}
        src={`${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${imageLink.path}`}
        onClick={open}
        
        />
      }

          <Group spacing='sm' position='center' >
            {tags}
          </Group>

      <Modal opened={opened} onClose={close} size={'auto'} >
        <DisplayImg imageLink={imageLink} />  
      </Modal>
    </Card>
  );
  }