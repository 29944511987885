import { Badge, Group, Pagination, Paper, Table, TableProps } from '@mantine/core';
import { ColumnDef, Row } from '@tanstack/react-table';

import { useState } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { UserRole } from '../../modules/users';
import { ColumnVisibilityFilter, GlobalSearchInput } from './components';
import TableHeaders from './core/TableHeaders';
import TableRows from './core/TableRows.tsx';
import { useTableInstance } from './hooks';

type BaseTableProps<T extends object> = {
  data: T[];
  columns: ColumnDef<T>[];
  rowSelection: {},
  page:number,
  setPage: (value:number) => void;
  pages: number,
  loading: boolean,
  // withRowSelection?: boolean;
  withColumnFilters?: boolean;
  withGlobalFilters?: boolean;
  csvExport?: JSX.Element;
  withExpandepleRows?: boolean;
  renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement;
  ids?: number[],
  setIds?: React.Dispatch<React.SetStateAction<number[]>>;
  setRowSelection: React.Dispatch<React.SetStateAction<{}>>
  getRowCanExpand?: (row: Row<T>) => boolean;
  columnTypes?: Record<string, string>
  toSelect?: Record<string, Record<string, string>>
  defaultVisibleColumns?: Record<string, boolean>;
  filterData?: (column: string, data: any, type: string) => void
  globalFilterData?: (data: any, headers: string[]) => void
  // selectedData?: T[];
  //   renderSubComponent: (props: { row: Row<T> }) => React.ReactElement;
  //   getRowCanExpand: (row: Row<T>) => boolean;
};

function BaseTable<Entity extends { id: number }>({
  data,
  columns,
  rowSelection,
  page,
  setPage,
  pages,
  loading,
  // withRowSelection = true,
  setRowSelection,
  withGlobalFilters = true,
  // withColumnFilters = true,
  csvExport,
  withExpandepleRows = false,
  renderSubComponent,
  getRowCanExpand = () => withExpandepleRows,
  ids,
  setIds,
  defaultVisibleColumns = {},
  columnTypes = {},
  toSelect = {},
  filterData,
  globalFilterData,
  ...props
  // selectedData
}: TableProps & BaseTableProps<Entity>) {
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(defaultVisibleColumns);
  const { instance } = useTableInstance<Entity>({ data, columns, rowSelection, setRowSelection, getRowCanExpand, columnVisibility, setColumnVisibility });
  const { user } = useAuthContext()
  // const rowSelectionProps = useRowSelection(instance, withRowSelection);
  // const columnResizeProps = useColumnResize(instance, true);
  // const columnSortProps = useColumnSort(instance, true);
  // const columnPaginationProps = useColumnPagination(instance, true);
  // useEffect(() => {
  //   if (setIds) {
  //     console.log(ids)
  //     setIds(instance.getSelectedRowModel().flatRows.map((item) => item.original.id))
  //   }
  // }, [instance.getSelectedRowModel().flatRows])

  if (!instance) return null;
  return (
    <Paper withBorder>
      <Group position="apart" pr="sm">
        {withGlobalFilters && <GlobalSearchInput instance={instance} globalFilterData={globalFilterData} />}
        <Group>
          {csvExport}
          {/* {withCsvExport && data[0] && withCsvExport && <ExportToCsvButton keys={getKeysWithPath(data[0], [], ':')} 
          onSubmit={(v) => {
            DownloadAsCsv<Entity>(instance.getSelectedRowModel().flatRows.map((item) => item.original), v.fileName, Object.values(v.columns).reduce((cols, item) => {
              if (item?.checked) cols.push({ path: item.path, alias: item.alias })
              return cols
            }, [] as Format[]))
          }} />} */}
          {data[0] && <ColumnVisibilityFilter instance={instance} />}
        </Group>
      </Group>
      {/* TODO: Test this */}
      <Table striped highlightOnHover sx={{ width: '100%', overflowX: 'scroll' }} {...props}>
        <TableHeaders instance={instance} withColumnFilters columnTypes={columnTypes} toSelect={toSelect} filterData={filterData} />
        <TableRows loading={loading} instance={instance} renderSubComponent={renderSubComponent} />
      </Table>
      <Group position='apart' py={10} px='sm'>
        <div></div>
          <Pagination
            total={pages}
            siblings={2}
            boundaries={2}
            onChange={(n) => setPage(n)}
            value={page}
          />
        {user?.role !== UserRole.Client && 
        <Badge ml='md'>selected: {ids?.length ?? 0}</Badge>
        }  
      </Group>
      {/* <Center py={10}>
      </Center> */}
    </Paper>
  );
}

export default BaseTable;
