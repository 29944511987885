import { useQuery } from 'react-query';
import { types } from '../../../constants';
import api from '../../common/api/api';
import { TaskGroup } from '../types/types';

interface myResTaskGroup {
  data: TaskGroup;
}

export const getTaskGroup = (id: number, joins?: string[], selects?: string[]): Promise<myResTaskGroup> => {
  let params = '';
  if (joins) params = '?joins='.concat(joins.join());
  if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
  return api.get(`task-groups/${id}${params}`);
};

export const useTaskGroup = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResTaskGroup) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.TaskGroup, { id }];
  if (options && options.joins) cacheKey.push(...options.joins);
  const query = useQuery(
    cacheKey,
    () =>
      getTaskGroup(id, options?.joins, options?.selects).then((taskGroup) => {
        options && options.then && options.then(taskGroup);
        return taskGroup;
      }),
    { enabled: id !== 0 },
  );
  return query;
};
