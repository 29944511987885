import { Button, Center, Container, Paper } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { TextInputEditable } from "../../../../../components/form";
import { IorganizationUpdateInput } from "../../../types/types";

export function UpdateMultipleContactsForm({
    form,
    setOpenEdited,
    contactIndex
}:{
    form:UseFormReturnType<IorganizationUpdateInput, (values: IorganizationUpdateInput) => IorganizationUpdateInput>,
    setOpenEdited: React.Dispatch<React.SetStateAction<number>>,
    contactIndex: number
}){
    return (
        <Container mt='md'>
            <Paper withBorder p='md'>
                <TextInputEditable
                label="Name"
                placeholder="name"
                {...form.getInputProps(`contacts.${contactIndex}.name`)}
                />
                <TextInputEditable
                label="position"
                placeholder="position"
                {...form.getInputProps(`contacts.${contactIndex}.position`)}
                mt="md"
                />
                <TextInputEditable
                label="phone"
                placeholder="phone"
                {...form.getInputProps(`contacts.${contactIndex}.phone`)}
                mt="md"
                />
                <TextInputEditable
                label="email"
                placeholder="email"
                {...form.getInputProps(`contacts.${contactIndex}.email`)}
                mt="md"
                />
                <Center>
                    <Button mt='md' onClick={() => setOpenEdited(-1)}>Save</Button>
                </Center>
            </Paper>
        </Container>
    )
}