import { Card, Center, createStyles, em, getBreakpointValue, Modal, Text, Title, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { TaskType, TaskTypeColor } from '../constants/constants';
import { UpgradeConfirmModal } from '../pages/UpgradeConfirmModal';
import { Task } from '../types/types';

const useStyles = createStyles((theme, color: string) => ({
  color: {
    borderLeftColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    borderLeftWidth: 5,
  },
  card: {
    position: 'relative',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    marginTop: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
    padding: theme.spacing.xs,
    paddingLeft: `cal(${theme.spacing.xs} * 2)`,
    paddingRight: 0,
    background: theme.fn.rgba(color, 0.1),
    paddingBottom: 55,
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      paddingBottom: 10,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 6,
      background: color,
    },
  },
  unButton: {
    position: 'absolute',
    background: color,
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm))})`]: {
      bottom: 0,
      left: 0,
      right: 0,
      height: 40,
    },
    [`@media (min-width: ${em(getBreakpointValue(theme.breakpoints.sm))})`]: {
      right: 0,
      top: 0,
      bottom: 0,
      left: 'auto',
      width: 70,
      height: 'auto',
    },
  },
}));

export function MyTaskCard({ task, handleSubmit }: { task: Task; handleSubmit(task: Task): void }) {
  const { classes } = useStyles(TaskTypeColor[task.type as keyof typeof TaskTypeColor]);
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Card withBorder mt="xs" className={classes.color} pr={70}>
      <Card.Section withBorder p="xs">
        <Title order={5}>
          <Text size="xl">{task.title}</Text>
        </Title>
      </Card.Section>
      <Card.Section withBorder p="xs">
        <Text mt="xs" size="md" lineClamp={4}>
          Task Remarks:
          {task.taskRemarks ? ' ' + task.taskRemarks : ' Not provided'}
        </Text>
        <Text size="xs" mr={50}>
          {task.type}
        </Text>
        <Text size="xs">Target Date: {task.targetDate ? new Date(task.targetDate).toDateString() : ' Not provided'}</Text>
        <UnstyledButton className={classes.unButton} onClick={() => (task.type !== TaskType.Upgrade ? handleSubmit(task) : open())}>
          <Center>Done!</Center>
        </UnstyledButton>
        <Modal
          opened={opened}
          onClose={close}
          title={
            <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
              Upgrade Unit
            </Text>
          }
        >
          {task.unit && <UpgradeConfirmModal unitId={task.unit.id} taskId={task.id} />}
          {!task.unit && (
            <Text w={'100%'} align="center" fz="lg" fw={700}>
              Please Select the unit you want to upgrade
            </Text>
          )}
        </Modal>
      </Card.Section>
    </Card>
  );
}
