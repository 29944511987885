import {
  ActionIcon,
  Anchor,
  Center,
  createStyles,
  em,
  getBreakpointValue,
  Grid,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  UnstyledButton
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { TbX } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { OtherTaskCompleteModal, TaskType, TaskTypeColor, TaskTypeText } from '../../../modules/tasks';
import { ClientDidNotShowModal } from '../../../modules/tasks/pages/ClientDidNotShowModal';
import { UpgradeConfirmModal } from '../../../modules/tasks/pages/UpgradeConfirmModal';
import { Iunit } from '../../../modules/unit';
import { Ivehicle } from '../../../modules/vehicles';

const useStyles = createStyles((theme, color: string) => ({
  card: {
    position: 'relative',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    marginTop: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
    padding: theme.spacing.xs,
    paddingLeft: `cal(${theme.spacing.xs} * 2)`,
    paddingRight: 0,
    background: theme.fn.rgba(color, 0.1),
    paddingBottom: 55,
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      paddingBottom: 10,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 6,
      background: color,
    },
  },
  unButton: {
    position: 'absolute',
    background: color,
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm))})`]: {
      bottom: 0,
      left: 0,
      right: 0,
      height: 40,
    },
  [`@media (min-width: ${em(getBreakpointValue(theme.breakpoints.sm))})`]: {
      right: 0,
      top: 0,
      bottom: 0,
      left: 'auto',
      width: 70,
      height: 'auto',
    },
  },
}));

interface TaskCardProps {
  id: number;
  title:string;
  type: string;
  client: { id: number; name: string };
  address: string;
  location: string;
  contactName: string;
  contactNumber: string;
  vehicle: Ivehicle;
  unit: Iunit | null;
  remarks: string;
}

export function TaskCard({
  id,
  title,
  type,
  client,
  address,
  location,
  vehicle,
  unit,
  remarks,
  contactName,
  contactNumber,
}: TaskCardProps) {
  const [openedClientNotShowModal, { open: openClientNotShowModal, close: closeClientNotShowModal }] = useDisclosure(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedCompleteOther, { open: openCompleteOther, close: closeCompleteOther }] = useDisclosure(false);
  const { classes } = useStyles(TaskTypeColor[type.split(' ').join('_') as keyof typeof TaskTypeColor]);
  const navigate = useNavigate();
  return (
    <Paper withBorder radius="md" className={classes.card}>
      <Group position="apart">
        <Stack w={'100%'}>
          <Grid w={'100%'}>
            <Grid.Col span={10}>
              <Text size="md" weight={700}>
                {title}
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
                <ActionIcon onClick={openClientNotShowModal}><TbX size={25} /></ActionIcon>
            </Grid.Col>
            
          </Grid>
          <Grid>
            <Grid.Col>
              <Text size="md">Client: {client.name}</Text>
            </Grid.Col>
            {location && <Grid.Col span={6}>
              <Anchor size="md" href={location} target="_blank">Map Location</Anchor>
            </Grid.Col>}
            {address && <Grid.Col span={6}>
              <Text size="md">Address: {address}</Text>
            </Grid.Col>}
            {contactName && <Grid.Col span={6}>
              <Text size="md">Name: {contactName}</Text>
            </Grid.Col>}
            {contactNumber && <Grid.Col span={6}>
              <Text size="md">Phone: {contactNumber}</Text>
            </Grid.Col>}
             <Grid.Col>
                  <Text size="md">
                    vin: {vehicle? vehicle.vin : unit?.vehicle?.vin}
                  </Text>
            </Grid.Col>
            {unit?.device && <Grid.Col>
              <Text size="md">
                imei: {unit?.device?.imei}
              </Text>
            </Grid.Col>}
          </Grid>
          {remarks && <Text size="sm" color="dimmed">
            {remarks}
          </Text>}
        </Stack>
        <UnstyledButton
          className={classes.unButton}
          onClick={() => ![TaskType.Other,  TaskType.Upgrade].includes(type as TaskType) ?navigate(`/technician/job/${type.split(' ').join('_')}/${id}`) : ![TaskType.Other].includes(type as TaskType) ? open(): openCompleteOther()}
        >
          <Center>{TaskTypeText[type.split(' ').join('_') as keyof typeof TaskTypeText]}</Center>
        </UnstyledButton>
      </Group>
      <Modal opened={opened} onClose={close} title={<Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}` ,fontWeight: 800, fontSize:'1.625rem' })}>Upgrade Unit</Text>}>
        {unit && <UpgradeConfirmModal unitId={unit.id} taskId={id} />}
        {!unit && <Text w={'100%'} align='center' fz='lg' fw={700} >Please Select the unit you want to upgrade</Text>}
      </Modal>
      <Modal opened={openedClientNotShowModal} onClose={closeClientNotShowModal}>
        <ClientDidNotShowModal  id={id} close={closeClientNotShowModal} />
      </Modal>
      <Modal opened={openedCompleteOther} onClose={closeCompleteOther}>
        <OtherTaskCompleteModal  id={id} close={closeCompleteOther} remarks={remarks} />
      </Modal>
    </Paper>
  );
}
