import { Alert, Button, Container, Grid, List, LoadingOverlay, Paper, SegmentedControl, TextInput, Title } from '@mantine/core';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { FormProvider, SpecifiedValues, TransferListRfid, TransferListRfidList, useForm } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { IaxiosError } from '../../common';
import { patchRfidList, postRfidList, useRfidList } from '../api';

interface IlistInput {
  name: string;
  rfids: {
    value: string;
    label: string;
    bceValue: string;
    teltonikaValue: string;
  }[][];
  rfidLists: { value: string; label: string }[][];
}

export function RfidListForm() {
  const { id } = useParams();
  const [idN] = useState(+(id || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [value, setValue] = useState('Printed');
  const [reset, setReset] = useState(false);
  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      name: '',
      rfids: [
        [{ value: '0', label: 'loading...', bceValue: '', teltonikaValue: '' }],
        [{ value: '0', label: 'loading...', bceValue: '', teltonikaValue: '' }],
      ],
      rfidLists: [[{ value: '0', label: 'loading...' }], [{ value: '0', label: 'loading...' }]],
    },
  });

  const { data: rfidListData } = useRfidList(idN, {
    then: (rfidList) => {
      form.setValues({ name: rfidList.data.name });
    },
  });

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postRfidList, {
    onSuccess: () => {
      setAlert(null);
      // setUpdateTrListData(true)
      // rfidListsRefetch()
      form.reset();
      queryClient.invalidateQueries([types.RFID, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchRfidList, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.RFIDList, types.ALL]);
      queryClient.invalidateQueries([types.RFIDList, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.RFIDList, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: SpecifiedValues) {
    setAlert(null);
    const newValues = Object.assign({}, values);
    const rfidsNew = newValues.rfids[0].map((row) => {
      const obj = { id: 0 };
      obj['id'] = parseInt(row.value);
      return obj;
    });
    const rfidListsNew = newValues.rfidLists[0].map((row) => {
      const obj = { id: 0 };
      obj['id'] = parseInt(row.value);
      return obj;
    });
    const finalValues = {
      name: newValues.name,
      rfids: rfidsNew,
      rfidLists: rfidListsNew,
    };
    if (finalValues.rfidLists.length !== 0 || finalValues.rfids.length !== 0) {
      if (idN !== 0 && !Number.isNaN(idN)) {
        patchMutate({ id: idN, data: finalValues });
      } else {
        newMutate(finalValues);
      }
    } else {
      setAlert(['Both Rfids and Rfid Lists can not be empty!']);
    }
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          {idN !== 0 && !Number.isNaN(idN) ? 'Edit a ' : 'Add a new '} Rfid List:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading || patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {(newSuccess || patchSuccess) && (
            <Alert icon={<TbCircleCheck size={16} />} title={newSuccess ? 'Created!' : 'Updated!'} color="green" mb={10}>
              Successfully {newSuccess ? 'added to' : 'updated in'} the database!
            </Alert>
          )}
          <FormProvider form={form}>
            <form
              onSubmit={form.onSubmit((values) => {
                handleSubmit(values), setReset(true);
              })}
            >
              <TextInput label="Name" placeholder="Name" {...form.getInputProps('name')} required />
              <Grid>
                <Grid.Col sm={6}></Grid.Col>
                <Grid.Col sm={6}>
                  <SegmentedControl
                    mt="md"
                    fullWidth
                    value={value}
                    onChange={(v) => {
                      setValue(v);
                    }}
                    data={['Printed', 'Bce', 'Teltonika']}
                  />
                </Grid.Col>
              </Grid>
              <TransferListRfid value={value} id={idN} reset={reset} setReset={setReset} />
              <TransferListRfidList id={idN} reset={reset} setReset={setReset} />
              <Button fullWidth mt="xl" type="submit">
                Submit
              </Button>
            </form>
          </FormProvider>
        </Paper>
      </Container>
    </Layout>
  );
}
