import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Iattendance } from '../types/types';

interface myResAttendances {
  data: {
    items: Iattendance[];
    meta: metaData,
  };
}

export const getattendances = (query = ''): Promise<myResAttendances> => {
  return api.get(`attendance${query}`);
};

export const useAttendances = (options?: { params?: string; then?: (x: myResAttendances) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Attendance, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
  getattendances(options?.params).then((attendances) => {
      options && options.then && options.then(attendances);
      return attendances;
    }), {enabled: options?.enabled ?? true },
  );
  return query;
};

interface myResCountAttendancesNumber {
  data: number;
}

export const countAttendances = (query = ''): Promise<myResCountAttendancesNumber> => {
  return api.get(`attendance/count${query}`);
};

export const useCountAttendances = (options?: { params?: string; then?: (x: myResCountAttendancesNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Attendance, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countAttendances(options?.params).then((attendances) => {
      options && options.then && options.then(attendances);
      return attendances;
    }),
  );
  return query;
};