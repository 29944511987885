import api from "../../common/api/api";
import { Iticket, IticketInput } from "../types/types";

interface myResTicketPatch {
	data: Iticket;
}

export const patchTicket = (props: {
	id: number;
	data: Partial<IticketInput>;
}): Promise<myResTicketPatch> => {
	return api.patch(`tickets/${props.id}`, props.data);
};

export const patchTickets = (data: Record<string, any>[]): Promise<myResTicketPatch[]> => {
	return api.patch('tickets/multi', data);
};
