import { useMantineTheme } from '@mantine/core';
import jsPDF from 'jspdf';
import { useCallback } from 'react';
import logo from '../assets/ats_logo.png';
import car from '../assets/delivery_note.png';
import { IDeliveryNote } from '../modules/deliveryNote/types/types';
import { Idevice } from '../modules/devices';
import { IsimCard } from '../modules/sim-cards';
import { Task } from '../modules/tasks';
import { Ivehicle } from '../modules/vehicles';
import { checkBoxField, square, textField } from './jsPDFSquare';

export function delivaryNote(
  task: Task,
  vehicle: Ivehicle,
  device: Idevice,
  simCard: IsimCard,
  platforms: string,
  deliveryNoteValues: IDeliveryNote,

  // performedBy: string,
  // plateNo: string,
  // vin: string,
  // vehicleBrand: string,
  // vehicleModel: string,
  // Year: string,
  // vehicleColor: string,
  // odometer: string,
  // imei: string,
  // deviceModal: string,
  // sim: string,
  // acceptedBy: string,
) {
  const checkinArr = [
    deliveryNoteValues?.checkInRMBMeter,
    deliveryNoteValues?.checkInSpeed,
    deliveryNoteValues?.checkIn4X4,
    deliveryNoteValues?.checkInSeatBelt,
    deliveryNoteValues?.checkInAC,
    deliveryNoteValues?.checkInHeadLights,
    deliveryNoteValues?.checkInBrakeLights,
    deliveryNoteValues?.checkInIndecators,
    deliveryNoteValues?.checkInRadioCD,
    deliveryNoteValues?.checkInSideMirror,
    deliveryNoteValues?.checkInPowerWindows,
  ];
  const checkOutArr = [
    deliveryNoteValues?.checkOutRMBMeter,
    deliveryNoteValues?.checkOutSpeed,
    deliveryNoteValues?.checkOut4X4,
    deliveryNoteValues?.checkOutSeatBelt,
    deliveryNoteValues?.checkOutAC,
    deliveryNoteValues?.checkOutHeadLights,
    deliveryNoteValues?.checkOutBrakeLights,
    deliveryNoteValues?.checkOutIndecators,
    deliveryNoteValues?.checkOutRadioCD,
    deliveryNoteValues?.checkOutSideMirror,
    deliveryNoteValues?.checkOutPowerWindows,
  ];
  const sensorArr = [
    deliveryNoteValues?.sensorRFIDCard,
    deliveryNoteValues?.sensorBuzzer,
    deliveryNoteValues?.checkOut4X4,
    deliveryNoteValues?.sensorCanbusConnect,
    deliveryNoteValues?.sensorDriverDoorSernsor,
    deliveryNoteValues?.sensorDriverSeatBeltSensor,
    deliveryNoteValues?.sensor4X4,
    deliveryNoteValues?.sensorRPM,
    deliveryNoteValues?.sensorGroundSpeed,
    deliveryNoteValues?.sensorFuelSensor,
    deliveryNoteValues?.sensorPTOSwitch,
    deliveryNoteValues?.sensorAutoSpeedController,
    deliveryNoteValues?.sensorOtherSensors,
  ];
  const sensorBuzzer = [deliveryNoteValues?.sensorBuzzer12V, deliveryNoteValues?.sensorBuzzer24V];
  const cutTypeArr = [
    deliveryNoteValues?.cutOffTypeIgnition,
    deliveryNoteValues?.cutOffTypeFuel,
    deliveryNoteValues?.cutOffTypeStarterMotor,
    deliveryNoteValues?.cutOffTypeImmobilizerRelayType,
  ];
  const cutTypeImmobilizerArr = [
    deliveryNoteValues?.cutOffTypeImmobilizerRelayType12V,
    deliveryNoteValues?.cutOffTypeImmobilizerRelayType24V,
  ];
  function toEmptyString(value: any) {
    if (value) return `${value}`;
    else return '';
  }
  const theme = useMantineTheme();
  const tetsDoc = new jsPDF();
  type docType = typeof tetsDoc;

  const createPdf = useCallback(() => {
    const doc: docType = new jsPDF({ orientation: 'portrait' });
    const width = doc.internal.pageSize.getWidth();
    doc.setProperties({
      title: 'DELIVERY NOTE',
    });
    doc.viewerPreferences({ DisplayDocTitle: true });
    doc.addImage(logo, 'JPEG', 5, 2, 50, 35);
    doc.setFont('Times', 'Bold');
    doc.setFontSize(16);
    doc.text('DELIVERY NOTE', width / 2, 20, { align: 'center' });

    doc.setFont('Times', 'Roman');
    doc.setFontSize(8);

    doc.text('Advanced Telematic Solutions L.L.C', width / 1.4, 10);

    doc.text('tell: +971 4 551 9488, Fax: +971 4 551 9359', width / 1.4, 15);

    doc.text('Office 9C11, I-Rise Tower, Al Barsha Heights,', width / 1.4, 19);

    doc.text('P.O. Box: 390588, Dubai, UAE', width / 1.4, 23);

    doc.text('www.atsuae.net', width / 1.4, 27);

    doc.line(0, 30, width, 30);

    doc.setFontSize(10);

    textField(
      doc,
      'ClientNameUpperForm',
      30,
      37,
      { width: 70, height: 5, fontSize: 8, textAlign: 'center', value: toEmptyString(vehicle?.organization?.name) },
      'Client Name:',
    );

    textField(doc, 'ContactNo', 30, 47, { width: 70, height: 5, fontSize: 8, textAlign: 'center' }, 'Contact No:', { xOffset: -2 });

    textField(doc, 'Address', 30, 57, { width: 70, height: 5, fontSize: 8, textAlign: 'center' }, 'Address:', { xOffset: -8 });

    textField(doc, 'Date', width / 1.4, 37, { width: 45, height: 5, fontSize: 8, textAlign: 'center' }, 'Date:', { xOffset: -6 });

    textField(doc, 'PORef', width / 1.4, 47, { width: 45, height: 5, fontSize: 8, textAlign: 'center' }, 'P.O.ref:');

    textField(doc, 'QRef', width / 1.4, 57, { width: 45, height: 5, fontSize: 8, textAlign: 'center' }, 'Q.ref:', { xOffset: -4 });

    square(doc, { x1: 10, x2: width - 10, y1: 65, y2: 80 });

    checkBoxField(
      doc,
      `installation`,
      15,
      70,
      {
        width: 5,
        height: 5,
        textAlign: 'center',
        fontSize: 9,
        appearanceState: `installation` === toEmptyString(task?.type) ? 'On' : 'Off',
      },
      'installation',
      { postions: 'right' },
    );

    checkBoxField(
      doc,
      `removal`,
      55,
      70,
      { width: 5, height: 5, textAlign: 'center', fontSize: 9, appearanceState: `removal` === toEmptyString(task?.type) ? 'On' : 'Off' },
      'removal',
      { postions: 'right' },
    );

    checkBoxField(
      doc,
      `reInstallation`,
      95,
      70,
      {
        width: 5,
        height: 5,
        textAlign: 'center',
        fontSize: 9,
        appearanceState: `re Installation` === toEmptyString(task?.type) ? 'On' : 'Off',
      },
      're Installation',
      { postions: 'right' },
    );

    checkBoxField(
      doc,
      `otherTaskType`,
      135,
      70,
      {
        width: 5,
        height: 5,
        textAlign: 'center',
        fontSize: 9,
        appearanceState: ![`installation`, `removal`, `re Installation`].includes(toEmptyString(task?.type)) ? 'On' : 'Off',
      },
      'Other',
      { postions: 'right' },
    );

    textField(doc, 'otherTaskTypeField', 155, 70, {
      width: 30,
      height: 5,
      fontSize: 8,
      textAlign: 'center',
      value: !['installation', 'removal', 're Installation'].includes(toEmptyString(task?.type)) ? toEmptyString(task?.type) : '',
    });

    doc.setDrawColor('Gray');
    square(doc, { x1: 10, x2: width - 10, y1: 82, y2: 295 });

    square(doc, { x1: 10, x2: 68, y1: 82, y2: 90 }, 'VEHICLE DETAILS:', {
      postions: 'left',
      fontSize: 12,
      xOffset: -2,
      font: ['Times', 'Bold'],
    });

    square(doc, { x1: 10, x2: 68, y1: 90, y2: 97 });

    textField(
      doc,
      'plateNo',
      39,
      91,
      { width: 26, height: 5, fontSize: 8, textAlign: 'center', value: toEmptyString(vehicle?.plateNumber) },
      'Vehicle Plate No.:',
      { xOffset: 9, fontSize: 10 },
    );

    square(doc, { x1: 10, x2: 68, y1: 97, y2: 105 });

    textField(
      doc,
      'vin',
      25,
      99,
      { width: 40, height: 5, fontSize: 8, textAlign: 'center', value: toEmptyString(vehicle?.vin) },
      'Chassis:',
      { xOffset: 3, fontSize: 10 },
    );

    square(doc, { x1: 10, x2: 68, y1: 105, y2: 112 });

    textField(
      doc,
      'vehicleBrand',
      25,
      106,
      { width: 40, height: 5, fontSize: 8, textAlign: 'center', value: toEmptyString(vehicle?.vehicleType?.brand) },
      'Brand:',
      { xOffset: -1, fontSize: 10 },
    );

    square(doc, { x1: 10, x2: 40, y1: 112, y2: 119 });

    textField(
      doc,
      'vehicleModel',
      22,
      113,
      { width: 16, height: 5, fontSize: 8, textAlign: 'center', value: toEmptyString(vehicle?.vehicleType?.model) },
      'Model:',
      { xOffset: 2, fontSize: 8 },
    );

    square(doc, { x1: 10, x2: 40, y1: 119, y2: 126 });

    textField(
      doc,
      'vehicleColor',
      22,
      120,
      { width: 16, height: 5, fontSize: 8, textAlign: 'center', value: toEmptyString(vehicle?.color) },
      'Color:',
      { xOffset: 2, fontSize: 8 },
    );

    square(doc, { x1: 40, x2: 68, y1: 112, y2: 119 });

    textField(
      doc,
      'Year',
      48,
      113,
      { width: 17, height: 5, fontSize: 8, textAlign: 'center', value: toEmptyString(vehicle?.vehicleType?.year) },
      'Year:',
      { xOffset: 3, fontSize: 8 },
    );

    square(doc, { x1: 40, x2: 68, y1: 119, y2: 126 });

    textField(
      doc,
      'odometer',
      55.5,
      120,
      {
        width: 10,
        height: 5,
        fontSize: 8,
        textAlign: 'center',
        value: toEmptyString(vehicle?.odometerKM ? vehicle?.odometerKM : vehicle?.odometerHours),
      },
      'Odomenter:',
      { xOffset: 6, fontSize: 8 },
    );

    square(doc, { x1: 68, x2: width - 10, y1: 82, y2: 90 }, 'IVMS INFORMATION:', {
      postions: 'left',
      fontSize: 12,
      xOffset: -2,
      font: ['Times', 'Bold'],
    });

    doc.setFontSize(10);

    square(doc, { x1: 68, x2: 120, y1: 90, y2: 97 });

    textField(
      doc,
      'imei',
      85,
      91,
      { width: 30, height: 5, fontSize: 8, textAlign: 'center', multiLines: false, value: toEmptyString(device?.imei) },
      'IMEI No:',
      { xOffset: 1 },
    );

    square(doc, { x1: 68, x2: 120, y1: 97, y2: 105 });

    textField(
      doc,
      'sim',
      85,
      99,
      { width: 30, height: 5, fontSize: 8, textAlign: 'center', multiLines: false, value: toEmptyString(simCard?.number) },
      'Sim No:',
      { xOffset: -1 },
    );

    square(doc, { x1: 68, x2: 120, y1: 105, y2: 112 });

    textField(
      doc,
      'deviceModal',
      92,
      106,
      { width: 23, height: 5, fontSize: 8, textAlign: 'center', multiLines: false, value: toEmptyString(device?.deviceType?.type) },
      'Device Modal:',
      { xOffset: 4 },
    );

    square(doc, { x1: 68, x2: 120, y1: 112, y2: 119 });

    textField(
      doc,
      'platforms',
      85,
      113,
      { width: 30, height: 5, fontSize: 8, textAlign: 'center', multiLines: false, value: platforms },
      'Platforms:',
      { xOffset: 5 },
    );

    square(doc, { x1: 68, x2: 120, y1: 119, y2: 126 });

    textField(doc, 'OtherIVMS', 85, 120.5, { width: 30, height: 5, fontSize: 8, textAlign: 'center', multiLines: false }, 'Other:', {
      xOffset: -3,
    });

    square(doc, { x1: 120, x2: width - 10, y1: 90, y2: 97 });

    textField(
      doc,
      'CardNo',
      150,
      91,
      {
        width: 50,
        height: 5,
        fontSize: 8,
        textAlign: 'center',
        multiLines: false,
        value: toEmptyString(deliveryNoteValues?.RFIDCardNumber),
      },
      'RFID Card No.:',
      { xOffset: 0 },
    );

    square(doc, { x1: 120, x2: width - 10, y1: 97, y2: 126 });

    textField(
      doc,
      'otherRFID',
      122,
      105,
      {
        width: 60,
        height: 20,
        fontSize: 8,
        textAlign: 'left',
        multiLines: true,
        value: toEmptyString(deliveryNoteValues?.RFIDCardNumberRemarks),
      },
      'Others (Specify):',
      { postions: 'topLeft', yOffset: 2 },
    );

    square(
      doc,
      { x1: 10, x2: 120, y1: 126, y2: 136, bgc: theme.primaryColor, style: 'FD' },
      'pre-Operation & Post Operation Vehicle Check:',
      { fontSize: 10 },
    );

    square(doc, { x1: 10, x2: 120, y1: 136, y2: 210 });

    doc.text('before', 12, 140);
    doc.text('after', 90, 140);

    Array(11)
      .fill('')
      .forEach((item, index) => {
        checkBoxField(doc, `checkInVehicle${index}`, 11, 145 + 5.5 * index, {
          width: 5,
          height: 5,
          textAlign: 'center',
          fontSize: 9,
          appearanceState: checkinArr[index] ? 'On' : 'Off',
        });
      });
    doc.addImage(car, 'JPEG', 30, 145, 50, 50);

    doc.setFontSize(8);
    [
      'RMB METER',
      'SpeedMeter',
      '4X4',
      'SeatBelt',
      'A/C',
      'Head Lights',
      'Brake Lights',
      'Indecators',
      'Radio/CD',
      'Side Mirror',
      'Power Windows',
    ].forEach((item, index) => {
      checkBoxField(
        doc,
        `checkOutVehicle${index}`,
        90,
        145 + 5.5 * index,
        { width: 5, height: 5, textAlign: 'center', fontSize: 9, appearanceState: checkOutArr[index] ? 'On' : 'Off' },
        item,
        { postions: 'right', xOffset: -3 },
      );
    });

    square(doc, { x1: 120, x2: width - 10, y1: 126, y2: 136, bgc: theme.primaryColor, style: 'FD' }, 'Configuration Details:', {
      fontSize: 10,
    });

    doc.text('Connected:', 122, 140);

    square(doc, { x1: 125, x2: width - 20, y1: 145, y2: 160 });

    checkBoxField(
      doc,
      `RPM`,
      138,
      146.5,
      { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: deliveryNoteValues?.configRPM ? 'On' : 'Off' },
      'RPM:',
      { xOffset: -4 },
    );
    doc.line(125, 150, width - 20, 150);

    checkBoxField(
      doc,
      `CAN`,
      138,
      151.5,
      { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: deliveryNoteValues?.configCAN ? 'On' : 'Off' },
      'CAN:',
      { xOffset: -4 },
    );
    doc.line(125, 155, width - 20, 155);

    checkBoxField(
      doc,
      `Speed`,
      138,
      156.5,
      { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: deliveryNoteValues?.configSpeed ? 'On' : 'Off' },
      'Speed:',
    );
    doc.line(143, 145, 143, 160);

    checkBoxField(
      doc,
      `4X4`,
      width - 25,
      146.5,
      { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: deliveryNoteValues?.config4X4 ? 'On' : 'Off' },
      '4X4:',
      { xOffset: -32 },
    );
    doc.line(143, 145, 143, 160);

    checkBoxField(
      doc,
      `DriverBelt`,
      width - 25,
      151.5,
      { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: deliveryNoteValues?.configDriverSeatBelt ? 'On' : 'Off' },
      'Driver SeatBelt:',
      { xOffset: -8 },
    );
    doc.line(143, 145, 143, 160);

    checkBoxField(
      doc,
      `PassengerBelt`,
      width - 25,
      156.5,
      {
        width: 3,
        height: 3,
        textAlign: 'center',
        fontSize: 9,
        appearanceState: deliveryNoteValues?.configPassengerSeatBelt ? 'On' : 'Off',
      },
      'Passenger SeatBelt:',
      { xOffset: -2 },
    );
    doc.line(143, 145, 143, 160);

    textField(
      doc,
      'otherConf',
      134,
      163,
      {
        width: 60,
        height: 20,
        fontSize: 9,
        textAlign: 'center',
        multiLines: true,
        value: toEmptyString(deliveryNoteValues?.configRemarks),
      },
      'Other:',
    );

    square(doc, { x1: 120, x2: width - 10, y1: 185, y2: 195, bgc: theme.primaryColor, style: 'FD' }, 'Additional Sensors:', {
      fontSize: 10,
    });

    square(doc, { x1: 120, x2: width - 10, y1: 195, y2: 263 });

    doc.setFontSize(8);
    [
      'RFID CARD',
      'Buzzer',
      'CANBUS Connect',
      'Driver Door Sensor',
      'Driver Seat Belt Sensor',
      '4x4 Sensor',
      'RPM',
      'Ground Speed (D.C Sensor/UK Sensor/CANBUS)',
      'Fuel Sensor',
      'PTO Switch',
      'Auto Speed Controller Sensor',
      'other Sensors',
    ].forEach((item, index) => {
      checkBoxField(
        doc,
        item.split(' ').join(''),
        122,
        197 + 5.5 * index,
        { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: sensorArr[index] ? 'On' : 'Off' },
        item,
        { postions: 'right', xOffset: -3 },
      );
    });

    ['12V', '24V'].forEach((item, index) => {
      checkBoxField(
        doc,
        `Buzzer${item}`,
        140 + 15 * index,
        202.5,
        { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: sensorBuzzer[index] ? 'On' : 'Off' },
        item,
        { postions: 'right', xOffset: -3 },
      );
    });

    textField(doc, 'otherSensors', 147, 257, {
      width: 50,
      height: 5,
      fontSize: 9,
      textAlign: 'center',
      value: toEmptyString(deliveryNoteValues?.sensorRemarks),
    });

    square(doc, { x1: 120, x2: width - 10, y1: 263, y2: 273, bgc: theme.primaryColor, style: 'FD' }, 'Type Of Cut Off:', { fontSize: 10 });

    square(doc, { x1: 120, x2: width - 10, y1: 273, y2: 295 });

    ['Ignition Cut off', 'Fuel Cut off', 'Starter Motor Cut Off', 'Immobilizer Relay Type'].forEach((item, index) => {
      checkBoxField(
        doc,
        item.split(' ').join(''),
        122,
        274 + 5.5 * index,
        { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: cutTypeArr[index] ? 'On' : 'Off' },
        item,
        { postions: 'right', xOffset: -3 },
      );
    });

    ['12V', '24V'].forEach((item, index) => {
      checkBoxField(
        doc,
        `Immobilizer${item}`,
        160 + 15 * index,
        290.5,
        { width: 3, height: 3, textAlign: 'center', fontSize: 9, appearanceState: cutTypeImmobilizerArr[index] ? 'On' : 'Off' },
        item,
        { postions: 'right', xOffset: -3 },
      );
    });
    doc.setFontSize(10);

    square(doc, { x1: 10, x2: 120, y1: 210, y2: 220, bgc: theme.primaryColor, style: 'FD' }, 'Rechecking:');

    textField(
      doc,
      'ReportProblem',
      50,
      221,
      { width: 55, height: 5, fontSize: 9, textAlign: 'center', value: toEmptyString(deliveryNoteValues?.recheckingReportProblem) },
      `Report Problem:`,
      { xOffset: -5 },
    );
    doc.line(10, 227, 120, 227);

    textField(
      doc,
      'ProblemDetected',
      50,
      230.75,
      { width: 55, height: 5, fontSize: 9, textAlign: 'center', value: toEmptyString(deliveryNoteValues?.recheckingProblemDetected) },
      `Problem Detected:`,
      { xOffset: -1 },
    );
    doc.line(10, 237, 120, 237);

    textField(
      doc,
      'CorrectiveAction',
      50,
      240.5,
      { width: 55, height: 5, fontSize: 9, textAlign: 'center', value: toEmptyString(deliveryNoteValues?.recheckingCorrectiveAction) },
      `Corrective Action:`,
      { xOffset: 1 },
    );
    doc.line(10, 247, 120, 247);

    textField(
      doc,
      'OtherRemarks',
      50,
      250,
      { width: 55, height: 5, fontSize: 9, textAlign: 'center', value: toEmptyString(deliveryNoteValues?.recheckingOtherRemarks) },
      `Other Remarks:`,
      { xOffset: -7 },
    );
    doc.line(10, 257, 120, 257);

    textField(
      doc,
      'ClientNameSigning',
      50,
      259,
      { width: 55, height: 5, fontSize: 9, textAlign: 'center', value: toEmptyString(vehicle?.organization?.name) },
      'Client Name:',
      { xOffset: -11 },
    );

    doc.text('Signature:', 15, 268);

    textField(
      doc,
      'TechnicianName',
      50,
      273,
      {
        width: 55,
        height: 5,
        fontSize: 9,
        textAlign: 'center',
        value: toEmptyString(task?.performedBy?.firstName) + ' ' + toEmptyString(task?.performedBy?.lastName),
      },
      'Technician Name:',
      { xOffset: -3 },
    );
    doc.text('Signature:', 15, 280);

    textField(
      doc,
      'AcceptedBy',
      50,
      287,
      {
        width: 55,
        height: 5,
        fontSize: 9,
        textAlign: 'center',
        value: toEmptyString(task?.approvedBy?.firstName) + ' ' + toEmptyString(task?.approvedBy?.lastName),
      },
      'Accepted By:',
      { xOffset: -11 },
    );

    return doc;
  }, []);

  // const justTest = useCallback(
  //     () => {
  //         const doc:docType = new jsPDF({ orientation: 'portrait' });
  //         const width = doc.internal.pageSize.getWidth();
  //         const height = doc.internal.pageSize.getHeight();
  //         doc.setProperties({
  //           title: "Test"
  //         });
  //         doc.viewerPreferences({DisplayDocTitle: true});

  //         table(doc, 10, 10, 5, 5, 30, 20,)

  //         return doc;
  //       },
  //       [],
  //       );

  const openPdfNewTab = useCallback(() => {
    const finalDoc = createPdf();
    window.open(finalDoc.output('bloburl'));
  }, [createPdf]);

  return { openPdfNewTab };
}
