export enum AsateelCertificateColumnsMode {
    ID = "id",
    CertificateID = "certificateID",
    ExpiryDate = "expiryDate",
    ActivationDate = "activationDate",
    WithPlate = "withPlate",
    WithDate = "withDate",
    WithDevice = "withDevice",
    WithSimCard = "withSimCard",
    WithParent = "withParent",
}