import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IrfidList } from '../types/types';

interface myResRfidLists {
  data: {
    items: IrfidList[];
    meta: metaData,
  };
}

export const getRfidLists = (query = ''): Promise<myResRfidLists> => {
  return api.get(`rfid-list${query}`);
};

export const useRfidLists = (options?: { params?: string; then?: (x: myResRfidLists) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.RFIDList, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getRfidLists(options?.params).then((rfidLists) => {
      options && options.then && options.then(rfidLists);
      return rfidLists;
    }),
  );
  return query;
};
