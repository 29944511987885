import { Center, Container, Grid, Group, Loader, Pagination, Select, Title } from '@mantine/core';
import { useState } from 'react';
import { BaseCard, CardsFilter, useDeepFilter } from '../../../components/card';

import { useNavigate } from 'react-router-dom';
import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';
import Layout from '../../../components/layout/Layout';
import { PageSize } from '../../common';
import { useVehicleTypes } from '../api';
import { UpdateVehicleTypeForm } from '../components';
import { IvehicleType } from '../types/types';

export function VehicleTypesCards() {
  const { isSuccess, data } = useVehicleTypes();

  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const navigate = useNavigate();
  const { filter, setFilter, formattedData } = useDeepFilter(data ? data.data.items : []);
  let cardsLoop;
  if (isSuccess) {
    cardsLoop = formattedData
      .slice((page - 1) * pagesize, pagesize * page)
      .map((item: IvehicleType) => (
        <BaseCard
          key={`card-${item.id}`}
          name={item.brand}
          discription={`Model: ${item.model}, category: ${item.category}, id:${item.id}`}
          to={`/vehicle-type/${item.id}`}
          img={item.imageRepositoryId}
          updateModalContent={<UpdateVehicleTypeForm idM={item.id} />}
        />
      ));
  }

  return (
    <Layout>
      <Container fluid pt={10}>
        <Group px={13} mt={10} position="apart">
          <Title
            order={2}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Vehicle types:
          </Title>
          <AddMultipleButton add={() => navigate('/new/vehicleType')} addMany={() => navigate('/new/vehicleTypes')} />
        </Group>
        <CardsFilter setFilter={setFilter} filter={filter} />
        <Grid>
          {formattedData && cardsLoop ? (
            cardsLoop
          ) : (
            <Container p={25}>
              <Loader />
            </Container>
          )}
        </Grid>
        <Center my={15}>
          <Pagination
            total={Math.ceil(formattedData.length / pagesize)}
            siblings={2}
            boundaries={2}
            onChange={(n) => setPage(n)}
            value={page}
          />
          <Select placeholder="Page Size" size="sm" ml={10} data={Object.values(PageSize)} onChange={(e) => setPagesize(Number(e))} />
        </Center>
      </Container>
    </Layout>
  );
}
