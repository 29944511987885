import { ColorSwatch, Flex, Group, Paper, Text, Tooltip } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { useEffect } from 'react';
import { TbEngine, TbBrandSpeedtest } from 'react-icons/tb';
import { MapContainer } from 'react-leaflet';
import { useTimeAgo } from '../../utils';
import { BaseMap } from './BaseMap';
import { useUnitLastMessage } from '../../modules/common';

export function MapCard({ cardHei, externalId }: { cardHei: number; externalId: number | undefined }) {
  const { data, refetch } = useUnitLastMessage(externalId || 0);
  const coords: [number, number] =
    data?.data.latitude && data?.data.longitude
      ? [data?.data.latitude || 0, data?.data.longitude || 0]
      : [25.097233744121343, 55.181343725493576];

  const { time, unixTime } = useTimeAgo(data?.data.messageTime || 0);
  useEffect(() => {
    if (data?.data.messageTime) {
      // console.log('resting unixTime');
      unixTime.current = data.data.messageTime;
    }
  }, [data?.data.messageTime]);

  const devHight = 50;

  const refetchInterval = useInterval(() => refetch(), 10000);

  useEffect(() => {
    refetchInterval.start();
    // console.log('refetch timer runing');
    return refetchInterval.stop;
  }, []);

  function online(time: number | undefined | null, engine: number | null | undefined) {
    const now = Math.floor(Date.now() / 1000);
    if (time) {
      const diff = now - time;
      if (engine === 1) {
        if (diff < 60 * 10) return 'green';
        else return 'red';
      } else {
        if (diff < 3 * 60 * 60) return 'green';
        else return 'red';
      }
    } else return 'orange';
  }
  return (
    <Paper p={10} radius={'md'} withBorder style={{ height: cardHei }}>
      <Flex h={devHight}>
        <Group>
          <Tooltip
            label={
              online(data?.data.messageTime, data?.data.engine) === 'green'
                ? 'Online'
                : online(data?.data.messageTime, data?.data.engine) === 'orange'
                ? 'Loading'
                : 'Offline'
            }
          >
            <ColorSwatch size={12} color={online(data?.data.messageTime, data?.data.engine)} />
          </Tooltip>
          <Text>{time}</Text>
          <Tooltip label="Engine">
            <div>
              <TbEngine size={14} color={data?.data.engine === 1 ? 'green' : 'red'} />
            </div>
          </Tooltip>
          <Group>
            <TbBrandSpeedtest size={14} />
            <Text>{data?.data.speed} Km/h</Text>
          </Group>
        </Group>
      </Flex>
      <MapContainer center={coords} zoom={6} scrollWheelZoom={false} style={{ height: cardHei - 20 - devHight }}>
        <BaseMap coords={coords} marker />
      </MapContainer>
    </Paper>
  );
}
