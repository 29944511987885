import {
  Alert,
  Container,
  createStyles,
  LoadingOverlay,
  Paper,
  Table,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { selectColumns } from '../../../utils';
import { DeviceTypeColumnsMode } from '../../device-types';
import { useDevice } from '../api';
import { DeviceColumnsMode } from '../constants';

const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));

export function DeviceCard({
  id,
  setImage,
}: {
  id: number;
  setImage?: React.Dispatch<React.SetStateAction<number | null>>;
}) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);

  const { isLoading, isError, data, refetch } = useDevice(id, {
    joins: ['deviceType', 'issuedTo', 'organization', 'organization.parent as parent'],
    selects: [...selectColumns(DeviceColumnsMode), ...selectColumns(DeviceTypeColumnsMode, {join: 'deviceType'}), 'issuedTo.id', 'issuedTo.firstName', 'issuedTo.lastName', 'organization.id', 'organization.name', 'parent.id', 'parent.name']
  });
  useEffect(() => {
    if (setImage && data) {
      setImage(data.data.deviceType.imageRepositoryId);
    }
  }, [data, setImage]);

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);


  return (
    <Paper pos="relative">
      <Container className={classes.detailsContainer} m={0} p={0}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        {data && data.data && (
          <Table mt={12} p={5}>
            <tbody>
              <tr>
                <td className={classes.leftSideTable}>id:</td>
                <td>{data.data.id}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>imei:</td>
                <td>{data.data.imei}</td>
              </tr>
              <tr>
                <td className={classes.leftSubtitleTable}>Device Type:</td>
                <td></td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>id:</td>
                <td>{data.data.deviceType.id}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Supplier:</td>
                <td>{data.data.deviceType.supplier}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Type:</td>
                <td>{data.data.deviceType.type}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Status:</td>
                <td>{data.data.status}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Ownership:</td>
                <td>{data.data.ownership}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Generation:</td>
                <td>{data.data.deviceType.generation}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Purchase Date:</td>
                <td>{data.data.purchaseDate ? new Date (data.data.purchaseDate).toDateString(): ''}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Issued To:</td>
                <td>{data.data.issuedTo ? data.data.issuedTo.firstName + ' ' +data.data.issuedTo.lastName: ''}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Organization:</td>
                <td>{data.data.organization?.name ?? ''}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Parent Organization:</td>
                <td>{data.data.organization?.parent?.name ?? ''}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Remarks:</td>
                <td>{data.data.remarks ?? ''}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {isError && (
          <Alert
            icon={<TbAlertOctagon size={16} />}
            title="Error!"
            color="red"
            mb={10}
          >
            Error Loading data from the server!!
          </Alert>
        )}
      </Container>
    </Paper>
  );
}
