import { Button, Checkbox, Divider, Group, LoadingOverlay, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TextInputEditable } from '../../../components/form';
import { noEmptyString } from '../../../utils/noEmptyString';
import { IaxiosError } from '../../common';
import { postDeliveryNote } from '../api';
import { IDeliveryNoteInput } from '../types/types';

export function NewDeliveryNoteForm({ taskID }: { taskID: number }) {
  const navigate = useNavigate();

  const form = useForm<IDeliveryNoteInput>({
    initialValues: {
      RFIDCardNumber: '',
      RFIDCardNumberRemarks: '',
      checkInRMBMeter: false,
      checkOutRMBMeter: false,
      checkInSpeed: false,
      checkOutSpeed: false,
      checkIn4X4: false,
      checkOut4X4: false,
      checkInSeatBelt: false,
      checkOutSeatBelt: false,
      checkInAC: false,
      checkOutAC: false,
      checkInHeadLights: false,
      checkOutHeadLights: false,
      checkInBrakeLights: false,
      checkOutBrakeLights: false,
      checkInIndecators: false,
      checkOutIndecators: false,
      checkInRadioCD: false,
      checkOutRadioCD: false,
      checkInSideMirror: false,
      checkOutSideMirror: false,
      checkInPowerWindows: false,
      checkOutPowerWindows: false,
      configRPM: false,
      config4X4: false,
      configCAN: false,
      configDriverSeatBelt: false,
      configPassengerSeatBelt: false,
      configSpeed: false,
      configRemarks: '',
      sensorRFIDCard: false,
      sensorBuzzer: false,
      sensorBuzzer12V: false,
      sensorBuzzer24V: false,
      sensorCanbusConnect: false,
      sensorDriverDoorSernsor: false,
      sensorDriverSeatBeltSensor: false,
      sensor4X4: false,
      sensorRPM: false,
      sensorGroundSpeed: false,
      sensorFuelSensor: false,
      sensorPTOSwitch: false,
      sensorAutoSpeedController: false,
      sensorOtherSensors: false,
      sensorRemarks: '',
      cutOffTypeIgnition: false,
      cutOffTypeFuel: false,
      cutOffTypeStarterMotor: false,
      cutOffTypeImmobilizerRelayType: false,
      cutOffTypeImmobilizerRelayType12V: false,
      cutOffTypeImmobilizerRelayType24V: false,
      recheckingReportProblem: '',
      recheckingProblemDetected: '',
      recheckingCorrectiveAction: '',
      recheckingOtherRemarks: '',
      task: {
        id: taskID,
      },
    },
  });

  const { mutate: newMutate, isLoading: newLoading } = useMutation(postDeliveryNote, {
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: `Successfully Created`,
        color: 'green',
        autoClose: 10000,
      });
      navigate('/myTasks');
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: 10000,
      });
    },
  });

  function handleSubmit(values: IDeliveryNoteInput) {
    const newValues = noEmptyString({ ...values });

    newMutate(newValues);
  }

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <LoadingOverlay visible={newLoading} overlayBlur={2} />
      <TextInputEditable mt="sm" label="RFID Card Number" {...form.getInputProps('RFIDCardNumber')} />
      <TextInputEditable mt="sm" label="RFID Card Number Remarks" {...form.getInputProps('RFIDCardNumberRemarks')} />
      <Divider mt="sm" />
      <Group position="apart">
        <Text fz="xs">Check In</Text>
        <Text fz="xs" mt="sm">
          Check Out
        </Text>
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInRMBMeter} {...form.getInputProps('checkInRMBMeter')} />
        <Text fz="xs" mt="sm">
          RMB Meter
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutRMBMeter} {...form.getInputProps('checkOutRMBMeter')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInSpeed} {...form.getInputProps('checkInSpeed')} />
        <Text fz="xs" mt="sm">
          Speed
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutSpeed} {...form.getInputProps('checkOutSpeed')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkIn4X4} {...form.getInputProps('checkIn4X4')} />
        <Text fz="xs" mt="sm">
          4X4
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOut4X4} {...form.getInputProps('checkOut4X4')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInSeatBelt} {...form.getInputProps('checkInSeatBelt')} />
        <Text fz="xs" mt="sm">
          SeatBelt
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutSeatBelt} {...form.getInputProps('checkOutSeatBelt')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInAC} {...form.getInputProps('checkInAC')} />
        <Text fz="xs" mt="sm">
          AC
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutAC} {...form.getInputProps('checkOutAC')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInHeadLights} {...form.getInputProps('checkInHeadLights')} />
        <Text fz="xs" mt="sm">
          Head Lights
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutHeadLights} {...form.getInputProps('checkOutHeadLights')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInBrakeLights} {...form.getInputProps('checkInBrakeLights')} />
        <Text fz="xs" mt="sm">
          Brake Lights
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutBrakeLights} {...form.getInputProps('checkOutBrakeLights')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInIndecators} {...form.getInputProps('checkInIndecators')} />
        <Text fz="xs" mt="sm">
          Indecators
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutIndecators} {...form.getInputProps('checkOutIndecators')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInRadioCD} {...form.getInputProps('checkInRadioCD')} />
        <Text fz="xs" mt="sm">
          Radio/CD
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutRadioCD} {...form.getInputProps('checkOutRadioCD')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInSideMirror} {...form.getInputProps('checkInSideMirror')} />
        <Text fz="xs" mt="sm">
          Side Mirror
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutSideMirror} {...form.getInputProps('checkOutSideMirror')} />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" checked={form.values.checkInPowerWindows} {...form.getInputProps('checkInPowerWindows')} />
        <Text fz="xs" mt="sm">
          Power Windows
        </Text>
        <Checkbox mt="sm" checked={form.values.checkOutPowerWindows} {...form.getInputProps('checkOutPowerWindows')} />
      </Group>
      <Divider mt="sm" />
      <Text fz="xs" mt="sm">
        Configuration Details
      </Text>
      <Group position="apart">
        <Checkbox mt="sm" label="4X4" checked={form.values.config4X4} {...form.getInputProps('config4X4')} />
        <Checkbox
          mt="sm"
          label="Passenger Seat Belt"
          labelPosition="left"
          checked={form.values.configPassengerSeatBelt}
          {...form.getInputProps('configPassengerSeatBelt')}
        />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" label="CAN" checked={form.values.configCAN} {...form.getInputProps('configCAN')} />
        <Checkbox
          mt="sm"
          label="Driver Seat Belt"
          labelPosition="left"
          checked={form.values.configDriverSeatBelt}
          {...form.getInputProps('configDriverSeatBelt')}
        />
      </Group>
      <Group position="apart">
        <Checkbox mt="sm" label="RPM" checked={form.values.configRPM} {...form.getInputProps('configRPM')} />
        <Checkbox mt="sm" label="Speed" labelPosition="left" checked={form.values.configSpeed} {...form.getInputProps('configSpeed')} />
      </Group>
      <Textarea mt="sm" label="Remarks" {...form.getInputProps('configRemarks')} />
      <Divider mt="sm" />
      <Text fz="xs" mt="sm">
        Additional Sensors
      </Text>
      <Checkbox mt="sm" label="RFID CARD" checked={form.values.sensorRFIDCard} {...form.getInputProps('sensorRFIDCard')} />
      <Group>
        <Checkbox
          mt="sm"
          label="Buzzer"
          checked={form.values.sensorBuzzer}
          {...form.getInputProps('sensorBuzzer')}
          onChange={(v) => {
            form.setFieldValue('sensorBuzzer', v.currentTarget.checked);
            if (!v.currentTarget.checked) {
              form.setFieldValue('sensorBuzzer12V', v.currentTarget.checked);
              form.setFieldValue('sensorBuzzer24V', v.currentTarget.checked);
            }
          }}
        />
        <Checkbox
          mt="sm"
          label="12V"
          disabled={!form.values.sensorBuzzer}
          checked={form.values.sensorBuzzer12V}
          {...form.getInputProps('sensorBuzzer12V')}
        />
        <Checkbox
          mt="sm"
          label="24V"
          disabled={!form.values.sensorBuzzer}
          checked={form.values.sensorBuzzer24V}
          {...form.getInputProps('sensorBuzzer24V')}
        />
      </Group>
      <Checkbox mt="sm" label="CANBUS Connect" checked={form.values.sensorCanbusConnect} {...form.getInputProps('sensorCanbusConnect')} />
      <Checkbox
        mt="sm"
        label="Driver Door Sensor"
        checked={form.values.sensorDriverDoorSernsor}
        {...form.getInputProps('sensorDriverDoorSernsor')}
      />
      <Checkbox
        mt="sm"
        label="Driver Seat Belt Sensor"
        checked={form.values.sensorDriverSeatBeltSensor}
        {...form.getInputProps('sensorDriverSeatBeltSensor')}
      />
      <Checkbox
        mt="sm"
        label="4x4 Sensor"
        checked={form.values.configPassengerSeatBelt}
        {...form.getInputProps('configPassengerSeatBelt')}
      />
      <Checkbox mt="sm" label="RPM" checked={form.values.sensorRPM} {...form.getInputProps('sensorRPM')} />
      <Checkbox
        mt="sm"
        label="Ground Speed (D.C Sensor/UK Sensor/CANBUS)"
        checked={form.values.sensorGroundSpeed}
        {...form.getInputProps('sensorGroundSpeed')}
      />
      <Checkbox mt="sm" label="Fuel Sensor" checked={form.values.sensorFuelSensor} {...form.getInputProps('sensorFuelSensor')} />
      <Checkbox mt="sm" label="PTO Switch" checked={form.values.sensorPTOSwitch} {...form.getInputProps('sensorPTOSwitch')} />
      <Checkbox
        mt="sm"
        label="Auto Speed Controller Sensor"
        checked={form.values.sensorAutoSpeedController}
        {...form.getInputProps('sensorAutoSpeedController')}
      />
      <Checkbox mt="sm" label="other Sensors" checked={form.values.sensorOtherSensors} {...form.getInputProps('sensorOtherSensors')} />
      <Textarea mt="sm" disabled={!form.values.sensorOtherSensors} {...form.getInputProps('sensorRemarks')} />
      <Divider mt="sm" />
      <Text fz="xs" mt="sm">
        Type Of Cut Off
      </Text>
      <Checkbox mt="sm" label="Ignition" checked={form.values.cutOffTypeIgnition} {...form.getInputProps('cutOffTypeIgnition')} />
      <Checkbox mt="sm" label="Fuel" checked={form.values.cutOffTypeFuel} {...form.getInputProps('cutOffTypeFuel')} />
      <Checkbox
        mt="sm"
        label="Starter Motor"
        checked={form.values.cutOffTypeStarterMotor}
        {...form.getInputProps('cutOffTypeStarterMotor')}
      />
      <Group>
        <Checkbox
          mt="sm"
          label="Immobilizer Relay Type"
          checked={form.values.cutOffTypeImmobilizerRelayType}
          {...form.getInputProps('cutOffTypeImmobilizerRelayType')}
          onChange={(v) => {
            form.setFieldValue('cutOffTypeImmobilizerRelayType', v.currentTarget.checked);
            if (!v.currentTarget.checked) {
              form.setFieldValue('cutOffTypeImmobilizerRelayType12V', v.currentTarget.checked);
              form.setFieldValue('cutOffTypeImmobilizerRelayType24V', v.currentTarget.checked);
            }
          }}
        />
        <Checkbox
          mt="sm"
          label="12V"
          disabled={!form.values.cutOffTypeImmobilizerRelayType}
          checked={form.values.cutOffTypeImmobilizerRelayType12V}
          {...form.getInputProps('cutOffTypeImmobilizerRelayType12V')}
        />
        <Checkbox
          mt="sm"
          label="24V"
          disabled={!form.values.cutOffTypeImmobilizerRelayType}
          checked={form.values.cutOffTypeImmobilizerRelayType24V}
          {...form.getInputProps('cutOffTypeImmobilizerRelayType24V')}
        />
      </Group>
      <Divider mt="sm" />
      <Text fz="xs" mt="sm">
        Rechecking
      </Text>
      <TextInputEditable mt="sm" label="Report Problem" {...form.getInputProps('recheckingReportProblem')} />
      <TextInputEditable mt="sm" label="Problem Detected" {...form.getInputProps('recheckingProblemDetected')} />
      <TextInputEditable mt="sm" label="Corrective Action" {...form.getInputProps('recheckingCorrectiveAction')} />
      <TextInputEditable mt="sm" label="Other Remarks" {...form.getInputProps('recheckingOtherRemarks')} />
      <Button fullWidth type="submit" mt="sm">
        Submit & End
      </Button>
    </form>
  );
}
