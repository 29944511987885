import { Aside, ScrollArea, Space } from '@mantine/core';
import { types } from '../../constants';
import { ActionButtons } from '../../modules/common';
import { HistoryCard } from '../../modules/history';
import { ActionButtonsCard, DetailsCard } from '../detailsCards';

export function SideBar({
  children,
  id,
  type,
  customActions,
  withCard = true,
  extraSection,
  hideCustomActions,
}: {
  children: JSX.Element;
  id: number[];
  type: types;
  customActions?: ActionButtons[];
  withCard?: boolean;
  extraSection?: JSX.Element;
  hideCustomActions?: string[];
}) {
  if (id.length === 0) return null;
  return (
    <Aside p="md" hidden={true} hiddenBreakpoint="xs" width={{ xs: 250, sm: 300, lg: 350 }}>
      <Aside.Section grow component={ScrollArea}>
        {/* inside details card shold be childern with one element */}
        {id.length === 1 && withCard ? (
          <DetailsCard id={id[0]} cardHei={420}>
            {children}
          </DetailsCard>
        ) : null}
        {id.length && extraSection}
        <Space h="md" />
        <ActionButtonsCard id={id} type={type} customActions={customActions} hideCustomActions={hideCustomActions} />
        <Space h="md" />
        {id.length === 1 ? <HistoryCard historySearch={[{ id: id[0], type: type }]} cardHei={400} /> : null}
      </Aside.Section>
    </Aside>
  );
}
