import { Button, Container, Grid, LoadingOverlay, Paper, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { SelectEditable } from '../../../components/form';
import { types } from '../../../constants';
import { IauthUser } from '../../../context/AuthContext';
import { enumToSelectData } from '../../../utils';
import { IaxiosError } from '../../common';
import { postAttendance } from '../api';
import { DisplayTime } from '../components/DisplayTime';
import { FormTimeIn } from '../components/FomTimeIn';
import { FormTimeOut } from '../components/FormTimeOut';
import { AttendanceStatus } from '../constants/constants';
import { IattendanceInput } from '../types/types';

export function NewAttendanceCard({
  item,
  user,
  day,
  setDay,
}: {
  item: IattendanceInput;
  user: IauthUser | null;
  day: Date;
  setDay: React.Dispatch<React.SetStateAction<Date>>;
}) {
  const [status, setStatus] = useState('present');

  const queryClient = useQueryClient();
  const { mutate: newMutate, isLoading } = useMutation(postAttendance, {
    onSuccess: () => {
      queryClient.invalidateQueries([types.Attendance, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: 10000,
      });
    },
  });

  function handleSubmit() {
    if (user) newMutate({ status: status, day: new Date(day.getFullYear(), day.getMonth(), day.getDate(), 4), user: { id: user.id } });
    else
      notifications.show({
        title: 'Error',
        message: `Please Refresh to Log In`,
        color: 'red',
        autoClose: 10000,
      });
  }
  return (
    <Container mt={25}>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <Paper pt={25} withBorder>
        <Grid>
          <Grid.Col offset={1} span={4}>
            <SelectEditable
              label="Status"
              data={enumToSelectData(AttendanceStatus)}
              onChange={(v) => v && setStatus(v)}
              withinPortal
              value={status}
            />
          </Grid.Col>
          <Grid.Col offset={2} span={4}>
            {item.id !== 0 && (
              <Title order={3} mt="md">
                {new Date(item.day).toDateString()}
              </Title>
            )}
            {item.id === 0 && (
              <DatePickerInput popoverProps={{ withinPortal: true }} label="Day" required onChange={(v) => v && setDay(v)} value={day} />
            )}
          </Grid.Col>
          <Grid.Col offset={1} span={10}>
            {status !== AttendanceStatus.Present && (
              <Button onClick={handleSubmit} fullWidth my="sm">
                Submit
              </Button>
            )}
          </Grid.Col>
        </Grid>
        {status === AttendanceStatus.Present && (
          <Grid>
            <Grid.Col md={6} sm={12} p={25}>
              {item.id !== 0 ? <DisplayTime time={item.timeIn} title="Time In:" /> : <FormTimeIn user={user} day={day} />}
            </Grid.Col>

            <Grid.Col p={25} md={6} sm={12}>
              {item.id !== 0 && item.timeOut ? (
                <DisplayTime time={item.timeOut} title="Time Out:" />
              ) : (
                <FormTimeOut id={item.id} timeOut={item.timeOut} timeOutSubmittedAt={item.timeOutSubmittedAt} user={user} />
              )}
            </Grid.Col>
          </Grid>
        )}
      </Paper>
    </Container>
  );
}
