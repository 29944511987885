type Operator = '&' | '|';

export interface Param {
  field: string;
  value: string | number | boolean;
  level: number;
}
export interface DateParam {
  field: string;
  from: Date | number;
  to: Date | number;
}

export interface Level {
  level: number;
  operator: Operator;
  parentOperator?: Operator;
}

export class URLParams {
  private selects: string[] = [];
  private search: Param[] = [];
  private groupSearch: string[] = [];
  private levels: Level[] = [];
  private relations: string[] = [];
  private pages: string[] = [];
  private sorts: string[] = [];
  private dates: DateParam[] = [];
  /**
   * Default Operator is '&',
   * cann't have multiple operators per level (each level one operator)
   */
  constructor(private operatorSymbol: Operator = '&') {}

  private parentOperator = this.operatorSymbol === '|' ? '%7C' : '%26';

  /**
   *
   * @param field column name in the database
   * @param value search value for that column, add = at the start to make it strict
   * @param level 0 is the default and increment by 1 for nested search. the operator is '&' by default, you can use setLevel() to set it
   */
  filterSetField(field: string, value: string | number | boolean, level = 0) {
    const levelIndex = this.levels.findIndex((item) => item.level === level);
    if (levelIndex === -1) {
      this.levels.push({ level, operator: '&' });
    }
    const fieldParam = {
      field,
      value,
      level,
    };
    this.search.push(fieldParam);
  }

  filterRemoveField(field: string) {
    const fieldIndex = this.search.findIndex((item) => item.field === field);
    if (fieldIndex !== -1) {
      this.search.splice(fieldIndex, 1);
    }
  }
  filterRemoveAllField(field: string) {
    const fieldIndex = this.search.findIndex((item) => item.field === field);
    if (fieldIndex !== -1) {
      this.search.splice(fieldIndex, 1);
      if(this.search.findIndex((item) => item.field === field) !== -1) this.filterRemoveAllField(field)
    }
  }
  filterRemoveDate(col: string) {
    const fieldIndex = this.dates.findIndex((item) => item.field === col);
    if (fieldIndex !== -1) {
      this.dates.splice(fieldIndex, 1);
    }
  }

  filterRemovePages() {
      this.pages = [];
  }
  filterRemoveSelects() {
      this.selects = [];
  }

  filterGetValue(field: string) {
    return this.search.find((item) => item.field === field);
  }

  /**
   *
   * @param level level number to set
   * @param operator '&' or '|'
   */
  filterSetLevel(level: number, operator: Operator, parentOperator?: Operator) {
    const levelIndex = this.levels.findIndex((item) => item.level === level);
    if (levelIndex !== -1) {
      this.levels[levelIndex].operator = operator;
      this.levels[levelIndex].parentOperator = parentOperator;
    } else {
      this.levels.push({ level, operator, parentOperator });
    }
  }

  filterGetLevels() {
    return this.levels;
  }

  filterResetFilter() {
    this.search = [];
  }

  filterValues() {
    return this.search.map((item) => item.value);
  }

  filterKeys() {
    return this.search.map((item) => item.field);
  }

  /**
   * sets the selects, calling this function again deletes the old selects and set using the new call
   * @param selects columns to select
   */
  select(...selects: string[]) {
    this.selects = selects;
  }

  /**
   *
   * @returns an array of available key, value and their level
   */
  filterEntries() {
    return this.search;
  }

  /**
   * sets the relations, calling this function again deletes the old relations and set using the new call
   * @param relations relations to include
   */
  includes(...relations: string[]) {
    this.relations = relations;
  }


  /**
   *
   * @param size page size
   * @param offset number of pages to skip default 0
   */
  page(size: number, offset = 0) {
    this.pages = [`page[size]=${size}`, `page[offset]=${offset}`];
  }

  searchMany(field: string, value: string[]) {
    this.groupSearch = [`filterMany[${field}]=${value.join(',')}`];
  }
  resetSearchMany() {
    this.groupSearch = [];
  }

  /**
   * Default - ascending order, prefix with (-) for descending order
   * @param fields fields to use to sort
   */
  sort(...fields: string[]) {
    this.sorts = fields;
  }

  /**
   * set the createdAt range in the database
   * @param from start date
   * @param to end date
   */
  date(from: Date | number, to: Date | number, field: string) {
    const fieldParam = {
      field,
      from,
      to,
    };
    this.dates.push(fieldParam);
  }

  /**
   *
   * @returns a string formated includes all param search options ready to be added to the API URL
   */
  toString() {
    const filter: string[] = [];
    const dates: string[] = [];
    const result: string[] = [];
    if (this.search.length >= 1) {
      if (this.levels.length >= 1) {
        this.levels.forEach((level) => {
          const levelOperator = level.operator === '|' ? '%7C' : '%26';
          this.search.forEach((item) => {
            if (level.level === item.level) {
              let filterItem = 'filter';
              filterItem = filterItem.concat(
                '[',
                level.parentOperator ? level.parentOperator : this.parentOperator,
                '][',
                `${level.level}`,
                '][',
                levelOperator,
                '][',
                item.field,
                ']=',
                `${item.value}`,
              );
              filter.push(filterItem);
            }
          });
        });
      } 
      else {
          this.search.forEach((item) => {
              let filterItem = 'filter';
              filterItem = filterItem.concat('[', this.parentOperator, '][', item.field, ']=', `${item.value}`);
              filter.push(filterItem);
            });
          }
        }
      if (this.dates.length >= 1) {
          this.dates.forEach((date) => {
              dates.push('dates'.concat(
                '[',
                `from`,
                '][',
                date.field,
                ']=',
                `${date.from}`,
              ));
              dates.push('dates'.concat(
                '[',
                `to`,
                '][',
                date.field,
                ']=',
                `${date.to}`,
              ));
          });
      } 
    if (this.search.length) result.push(filter.join('&'));
    if (this.groupSearch.length) result.push(this.groupSearch.join(','));
    if (this.relations.length) result.push('includes='.concat(this.relations.join(',')));
    if (this.selects.length) result.push('selects='.concat(this.selects.join(',')));
    if (this.pages.length) result.push(this.pages.join('&'));
    if (this.sorts.length) result.push('sort='.concat(this.sorts.join(',')));
    if (this.dates.length) result.push(dates.join('&'));
    return '?'.concat(result.join('&'));
  }
}
