import {
  Avatar,
  Badge,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  Grid,
  LoadingOverlay,
  Menu,
  Paper,
  Text,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { types } from '../../../constants';
import { IcommentInput } from '../../comments';
import { postComment } from '../../comments/api';
import { IaxiosError, commonConstants } from '../../common';
import { UserRole } from '../../users';
import { patchTicket, useTicket } from '../api';
import { TicketStatus, TicketType } from '../constants/constants';
import { enumToSelectData } from '../../../utils';

export function TicketCard({ id, userID, role }: { id: number; userID: number; role: string | undefined }) {
  const [oldId, setOldId] = useState<number | null>(null);

  const { data, refetch, isLoading } = useTicket(id, {
    joins: [
      'assignedToUser',
      'client',
      'comments',
      'comments.writer',
      'client.profiles as profiles',
      'profiles.organization',
      //   'writer.profiles as wrProfiles',
    ],
  });
  const queryClient = useQueryClient();

  const form = useForm<IcommentInput>({
    initialValues: {
      body: '',
      ticket: { id: id },
      writer: { id: userID },
    },
  });

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);

  const { mutate: newMutate, isLoading: newLoading } = useMutation(postComment, {
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: `comment succesfully created`,
        color: 'green',
        autoClose: 10000,
      });
      form.reset();
      queryClient.invalidateQueries([types.Comment, types.ALL]);
      queryClient.invalidateQueries([types.Ticket, types.ALL]);
      queryClient.invalidateQueries([types.Ticket, { id: id }]);
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: data.response.data.message,
        color: 'red',
        autoClose: 10000,
      });
    },
  });

  const { mutate: patchMutate } = useMutation(patchTicket, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([types.Ticket, { id: data.data.id }]);
    },
    onError: (data: IaxiosError) => {
      console.log(data.response.data.message);
    },
  });

  function handleSubmit(values: IcommentInput) {
    values.body = values.body.replace(/\n\r?/g, '<br />');
    console.log(values);
    if (values.ticket.id !== 0 && values.writer.id !== 0) {
      newMutate(values);
      if (userID && role && role !== UserRole.Client && data?.data.assignedOn)
        patchMutate({ id: id, data: { status: TicketStatus.In_Progress } });
      else if (userID && role && role !== UserRole.Client && !data?.data.assignedOn)
        patchMutate({ id: id, data: { status: TicketStatus.In_Progress, assignedToUser: { id: userID }, assignedOn: new Date() } });
    }
    if (values.ticket.id === 0 && values.writer.id === 0) {
      notifications.show({
        title: 'Error',
        message: 'Please Enter Something.',
        color: 'red',
        autoClose: 10000,
      });
    }
  }

  const comments = data?.data.comments?.map((item) => {
    return (
      <Flex py="xs" style={{ justifyContent: item.writer.id === userID ? 'flex-end' : 'flex-start' }} key={item.id}>
        {item.writer.id !== userID && (
          <Avatar variant="outline" color="gray.6" mr="xs" radius="xl">
            {item.writer.firstName[0] + item.writer.lastName[0]}
          </Avatar>
        )}
        <Paper withBorder p="sm">
          <Flex direction="column" w={'fit-content'} miw={230}>
            <Flex style={{ justifyContent: 'flex-start' }}>
              <Text fz="sm" color="dimmed">
                {item.writer.id === userID ? 'You' : item.writer.firstName + ' ' + item.writer.lastName}
              </Text>
            </Flex>
            <Flex style={{ justifyContent: 'flex-start' }} mt="xs">
              {item.body.split('<br />').map((item, index) => (
                <Text key={`l-${index}`}>{item}</Text>
              ))}
            </Flex>
            <Flex style={{ justifyContent: 'flex-end' }}>
              <Text fz="sm" color="dimmed">
                {new Date(item.createdAt).toDateString()}
              </Text>
            </Flex>
          </Flex>
        </Paper>
        {item.writer.id === userID && (
          <Avatar variant="outline" color="gray.6" ml="xs" radius="xl">
            {item.writer.firstName[0] + item.writer.lastName[0]}
          </Avatar>
        )}
      </Flex>
    );
  });

  return (
    <Container>
      <LoadingOverlay visible={isLoading || newLoading} />
      <Card withBorder>
        <Grid>
          <Grid.Col span={4}>
            <Text>#TIC{String(data?.data.id).padStart(4, '0')}</Text>
          </Grid.Col>
          <Grid.Col span={4} style={{ textAlign: 'center' }}>
            {role === UserRole.Client && <Badge>{data?.data.status}</Badge>}
            {role !== UserRole.Client && (
              <Menu shadow="md" width={200} withinPortal>
                <Menu.Target>
                  <Badge>{data?.data.status}</Badge>
                </Menu.Target>
                <Menu.Dropdown>
                  {enumToSelectData(TicketStatus).map((item) => {
                    return (
                      <Menu.Item key={item.value} onClick={() => patchMutate({ id: id, data: { status: item.value } })}>
                        {item.label}
                      </Menu.Item>
                    );
                  })}
                </Menu.Dropdown>
              </Menu>
            )}
          </Grid.Col>
          <Grid.Col span={4}>
            <Text align="right" fz="sm" fw={220}>
              {data?.data.assignedToUser
                ? data?.data.assignedToUser.firstName + ' ' + data?.data.assignedToUser.lastName
                : 'not assigned yet'}
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text>
              {data?.data.client.profiles.filter((p) => p.app === commonConstants.AiosApp)[0].organization.name}
              {data?.data.client.profiles.filter((p) => p.app === commonConstants.AiosApp)[0].organization.name ? ': ' : ''}
              {data?.data.client.firstName} {data?.data.client.lastName}{' '}
            </Text>
          </Grid.Col>
          <Grid.Col span={4} style={{ textAlign: 'center' }}>
            {role === UserRole.Client && <Badge>{data?.data.type}</Badge>}
            {role !== UserRole.Client && (
              <Menu shadow="md" width={200} withinPortal>
                <Menu.Target>
                  <Badge>{data?.data.type}</Badge>
                </Menu.Target>
                <Menu.Dropdown>
                  {enumToSelectData(TicketType).map((item) => {
                    return (
                      <Menu.Item key={item.value} onClick={() => patchMutate({ id: id, data: { type: item.value } })}>
                        {item.label}
                      </Menu.Item>
                    );
                  })}
                </Menu.Dropdown>
              </Menu>
            )}
          </Grid.Col>
          <Grid.Col span={4}>
            <Text align="right" fz="sm" fw={220}>
              {data?.data.assignedOn ? new Date(data?.data.assignedOn).toDateString() : 'not assigned yet'}
            </Text>
          </Grid.Col>
          <Grid.Col p={0}>
            <Divider />
          </Grid.Col>
          <Grid.Col py="lg">
            <Title order={5} mb="xs">
              {data?.data.title}
            </Title>
            <Text>{data?.data.body}</Text>
          </Grid.Col>
        </Grid>
      </Card>
      {comments}
      <Paper p="md" mt="md" withBorder>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Textarea minRows={6} label="Massage" placeholder="massage" {...form.getInputProps('body')} />
          {/* <Select 
                    withinPortal
                    data={[
                    { value: 'solved', label: 'Solved' },
                    { value: 'closed', label: 'Closed' },
                    ]} 
                    mt='md'
                    onChange={(v) => v && setStatus(v)}
                    value={status}
                    label='status'
                    /> */}
          <Button fullWidth mt="xl" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
