import { TimeInput } from "@mantine/dates";
import { TableFilterMenu } from "./TableFilterMenu";

export function TimeInputField({
    label,
    value,
    setValue,
    filterData,
  }: {
    label: string;
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>
    filterData?: (column: string, data: string, type:string) => void,
  }){
    return(
        <TimeInput 
        value={value}
        rightSection={<TableFilterMenu 
          column={label} 
          columnType={''} 
          filterData={filterData}
          setValue={setValue}
          />
        } 
          onChange={(v) => {
            if(filterData && v.currentTarget.value){
              filterData(label, v.currentTarget.value, 'string')
              setValue(v.currentTarget.value)
            } 
            else filterData && filterData(label, '', 'string')
        }} />
    )
}