import { Card, SimpleGrid, Text, UnstyledButton, createStyles } from '@mantine/core';
import { useEffect, useState } from 'react';
import { FaEdit, FaExpandArrowsAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { types } from '../../constants';
import { ActionButtons } from '../../modules/common/types/types';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.md,
    height: 97,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: `${theme.shadows.md} !important`,
      transform: 'scale(1.05)',
    },
  },
}));

export function ActionButtonsCard({
  id,
  type,
  customActions,
  hideCustomActions,
}: {
  id: number[];
  type: string;
  customActions?: ActionButtons[];
  hideCustomActions?: string[];
}) {
  const { classes, theme } = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  function handleView() {
    navigate(`/${type}/${id[0]}`);
  }

  function handleEdit() {
    navigate(`/edit/${type}/${id[0]}`);
  }

  const mockdata = [
    {
      id: 'view',
      multi: false,
      title: 'View details',
      icon: FaExpandArrowsAlt,
      color: 'violet',
      handler: handleView,
      noDisplay: [`${types.RFID}`, `${types.Record}`],
    },
    { id: 'edit', multi: false, title: 'Edit', icon: FaEdit, color: 'blue', handler: handleEdit, noDisplay: [`${types.Record}`] },
    // { id: 'refund', multi: false, title: 'Refunds', icon: FaExpandArrowsAlt, color: 'green', handler: handleView, noDisplay: [] },
    // { id: 'receipt', multi: false, title: 'Receipts', icon: FaExpandArrowsAlt, color: 'teal', handler: handleView, noDisplay: [] },
    // { id: 'tax', multi: false, title: 'Taxes', icon: FaExpandArrowsAlt, color: 'cyan', handler: handleView, noDisplay: [] },
    // { id: 'report', multi: false, title: 'Reports', icon: FaExpandArrowsAlt, color: 'pink', handler: handleView, noDisplay: [] },
  ];
  if (customActions) mockdata.push(...customActions);
  // const [actionsButtons, setActionsButtons] = useState<ActionButtons[]>(mockdata);

  // useEffect(() => {
  //   if (customActions) setActionsButtons(mockdata.concat(customActions));
  // }, [customActions]);

  const items = mockdata.map((item) => {
    if (item.noDisplay.includes(type) || hideCustomActions?.includes(item.id)) return null;
    return id.length === 1 ? (
      item.title === 'View details' && location.pathname === `/${type}/${id[0]}` ? null : (
        <UnstyledButton key={item.title} className={classes.item} onClick={item.handler}>
          <item.icon color={theme.colors[item.color][6]} size={32} />
          <Text size="xs" mt={7}>
            {item.title}
          </Text>
        </UnstyledButton>
      )
    ) : item.multi ? (
      <UnstyledButton key={item.title} className={classes.item} onClick={item.handler}>
        <item.icon color={theme.colors[item.color][6]} size={32} />
        <Text size="xs" mt={7}>
          {item.title}
        </Text>
      </UnstyledButton>
    ) : null;
  });

  return (
    <Card withBorder radius="md" className={classes.card}>
      {/* <Group position="apart"> */}
      <Text className={classes.title}>Actions</Text>
      {/* <Anchor size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
               + 21 other services
            </Anchor> */}
      {/* </Group> */}
      <SimpleGrid cols={3} mt="md">
        {items}
      </SimpleGrid>
    </Card>
  );
}
