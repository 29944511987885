import { Alert, Button, Container, List, LoadingOverlay, Paper, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { useParams } from 'react-router-dom';
import { TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { removeNotEdited } from '../../../utils';
import { UploadButton, useUploadImage } from '../../common';
import { IaxiosError } from '../../common';
import { patchSimCardType, useSimCardType } from '../api';
import { IsimCardTypeInput } from '../types/types';

export function UpdateSimCardTypeForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { upload, progress, uploadCompleted } = useUploadImage();

  const queryClient = useQueryClient();

  const { data: simCardTypeData } = useSimCardType(idN, {
    then: (simCardType) => {
      form.setValues({
        supplier: simCardType.data.supplier,
        type: simCardType.data.type,
      });
      form.resetDirty({
        supplier: simCardType.data.supplier,
        type: simCardType.data.type,
      });
    },
  });

  const form = useForm({
    initialValues: {
      supplier: simCardTypeData?.data.supplier || '',
      type: simCardTypeData?.data.type || '',
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchSimCardType, {
    onSuccess: (data) => {
      setAlert(null);
      const imageRepositoryId = data.data.imageRepositoryId;
      if (imageRepositoryId && file) {
        upload(imageRepositoryId, file, `${data.data.supplier}_${data.data.type}`, types.SimCareType, types.SimCareType, data.data.id);
      }
      queryClient.invalidateQueries([types.SimCareType, types.ALL]);
      queryClient.invalidateQueries([types.SimCareType, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.SimCareType, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IsimCardTypeInput) {
    setAlert(null);
    const newValues = removeNotEdited(values, form);
    if (idN !== 0 && !Number.isNaN(idN)) {
      if (Object.keys(newValues).length) {
        patchMutate({ id: idN, data: newValues });
      }
      if (simCardTypeData && simCardTypeData.data.imageRepositoryId && file) {
        upload(
          simCardTypeData.data.imageRepositoryId,
          file,
          `${simCardTypeData.data.supplier}_${simCardTypeData.data.type}`,
          types.SimCareType,
          types.SimCareType,
          simCardTypeData.data.id,
        );
        queryClient.invalidateQueries([types.Images, { id: simCardTypeData.data.imageRepositoryId }]);
      }
    } else {
      setAlert(['The id provided is incorrect!']);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit a Sim Card Type:
        </Title>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {(patchSuccess || uploadCompleted) && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              dirty={form.isDirty('supplier')}
              label="Supplier"
              placeholder="Supplier name"
              {...form.getInputProps('supplier')}
              required
            />
            <TextInputEditable
              dirty={form.isDirty('type')}
              label="Type"
              placeholder="Sim card Type"
              {...form.getInputProps('type')}
              required
              mt="md"
            />
            <UploadButton file={file} onChange={setFile} progress={progress}>
              {(props) => (
                <Button {...props} mt="md">
                  Select sim card image
                </Button>
              )}
            </UploadButton>
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
}
