
import Layout from '../../../components/layout/Layout';
import { NewDeviceTypeForm } from '../components';

export function NewDeviceTypeFormPage() {

  return (
    <Layout>
      <NewDeviceTypeForm/>
    </Layout>
  );
}
