import { ActionIcon, Group, Paper, Stack, Text } from '@mantine/core';
import { AiFillDelete, AiTwotoneEdit } from 'react-icons/ai';
import { Contacts } from '../../../types/types';

export function ContactSummary({
  contact,
  setOpenEdited,
  contactIndex,
  setDeleteContact,
  setDeleteContactID,
}: {
  contact: Omit<Contacts, 'id'>;
  setOpenEdited: React.Dispatch<React.SetStateAction<number>>;
  contactIndex: number;
  setDeleteContact: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteContactID: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <Paper withBorder p="sm">
      <Group position="apart" style={{ alignItems: 'flex-start' }} mt="md">
        <Stack>
          <Text>Name: {contact.name}</Text>
          <Text>Position: {contact.position}</Text>
        </Stack>
        <Stack>
          <Text>Phone: {contact.phone}</Text>
          <Text>Email: {contact.email}</Text>
        </Stack>
        <Group>
          <ActionIcon onClick={() => setOpenEdited(contactIndex)}>
            <AiTwotoneEdit />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              setDeleteContactID(contactIndex), setDeleteContact(true);
            }}
          >
            <AiFillDelete />
          </ActionIcon>
        </Group>
      </Group>
    </Paper>
  );
}
