import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IvehicleType } from '../types/types';

export const ALL_VEHICLE_TYPES = 'all.vehicle.type';

interface myResVehicleTypes {
  data: {
    items: IvehicleType[];
    meta: metaData,
  };
}

export const getVehicleTypes = (query = ''): Promise<myResVehicleTypes> => {
  return api.get(`vehicle-types${query}`);
};

export const useVehicleTypes = (options?: { params?: string; then?: (x: myResVehicleTypes) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.VehicleType, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getVehicleTypes(options?.params).then((vehicleTypes) => {
      options && options.then && options.then(vehicleTypes);
      return vehicleTypes;
    }), {enabled: options?.enabled ?? true }
  );
  return query;
};


interface myResCountVehicleTypeNumber {
  data: number;
}

export const countVehicleTypes = (query = ''): Promise<myResCountVehicleTypeNumber> => {
  return api.get(`vehicle-types/count${query}`);
};

export const useCountVehicleTypes = (options?: { params?: string; then?: (x: myResCountVehicleTypeNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.VehicleType, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countVehicleTypes(options?.params).then((vehicleTypes) => {
      options && options.then && options.then(vehicleTypes);
      return vehicleTypes;
    }),
  );
  return query;
};