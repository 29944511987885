import { ActionIcon, Alert, Button, Container, Divider, Grid, List, LoadingOverlay, Modal, Paper, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck, TbX } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { useDisclosure } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { DetailsCard } from '../../../components/detailsCards';
import { SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, enumToSelectData, noEmptyString, removeNotEdited, selectColumns } from '../../../utils';
import { IaxiosError } from '../../common';
import { DeviceCard, useDevices } from '../../devices';
import { useRfidLists } from '../../rfid-lists';
import { IsimCard, SimCardCard, SimStatus, useSimCards } from '../../sim-cards';
import { NewVehicleForm, VehicleCard, useVehicles } from '../../vehicles';
import { patchUnit, useUnit } from '../api';
import { DeviceConfigColumnsMode, DevicePlatforms } from '../constants/constants';
import { IUpdateUnitInput, Iunit } from '../types/types';
import { UpdateAccessoriesTransferList } from './UpdateAccessoriesTransferList';

export function UpdateUnitForm({ idM }: { idM: number | undefined }) {
  const { id } = useParams();
  const [idN] = useState(idM || +(id || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [opened, { open, close }] = useDisclosure(false);

  const [deviceSelect, setDeviceSelect] = useState([{ value: '', label: 'Loading...' }]);
  const [deviceId, setDeviceId] = useState(0);
  const [simCardSelect, setSimCardSelect] = useState([{ value: '', label: 'Loading...' }]);
  const [simCardId, setSimCardId] = useState(0);
  const [vehicleSelect, setVehicleSelect] = useState([{ value: '0', label: 'Loading...' }]);
  const [vehicleId, setVehicleId] = useState(0);
  const [rfidListSelect, setRfidListSelect] = useState([{ value: '0', label: 'Loading...' }]);
  const queryClient = useQueryClient();

  const deviceParams = new URLParams();
  deviceParams.includes('deviceType', 'unit');
  deviceParams.filterSetLevel(0, '|');
  deviceParams.filterSetField('=unit.id', 'null', 0);
  deviceParams.filterSetField('=unit.id', idN, 0);
  const { data: deviceData } = useDevices({ params: deviceParams.toString() });
  useEffect(() => {
    if (deviceData) {
      const tempArr = deviceData.data.items.map((device) => {
        return { value: `${device.id}`, label: `${device.imei}` };
      });
      tempArr.unshift({ value: '', label: 'No Device' });
      setDeviceSelect(tempArr);
    }
  }, [deviceData]);

  const simParams = new URLParams();
  simParams.includes('simCardType', 'unit');
  simParams.select('id', 'number', 'simCardType.imageRepositoryId', 'unit.id');
  simParams.filterSetLevel(0, '|');
  simParams.filterSetField('=unit.id', 'null', 0);
  simParams.filterSetField('=unit.id', idN, 0);
  const { data: simCardsData } = useSimCards({ params: simParams.toString() });
  useEffect(() => {
    if (simCardsData) {
      const tempArr = simCardsData.data.items
        .filter((item) => {
          const sim = item as unknown as IsimCard & { unit: Iunit };
          return sim.status !== SimStatus.Deactivated;
        })
        .map((simCard) => {
          return { value: `${simCard.id}`, label: `${simCard.number}` };
        });
      tempArr.unshift({ value: '0', label: 'No sim card' });
      setSimCardSelect(tempArr);
    }
  }, [simCardsData]);

  const { data: rfidListsData } = useRfidLists();
  useEffect(() => {
    if (rfidListsData) {
      const tempArr = rfidListsData.data.items.map((rfidList) => {
        return { value: `${rfidList.id}`, label: `${rfidList.name}` };
      });
      tempArr.unshift({ value: '0', label: 'No RfidList' });
      setRfidListSelect(tempArr);
    }
  }, [rfidListsData]);

  const vehicleParams = new URLParams();
  vehicleParams.includes('vehicleType');
  vehicleParams.select('id', 'plateNumber', 'vin');
  const { data: vehiclesData } = useVehicles({ params: vehicleParams.toString() });
  useEffect(() => {
    if (vehiclesData) {
      const tempArr = vehiclesData.data.items.map((vehicle) => {
        return { value: `${vehicle.id}`, label: `${vehicle.plateNumber}, ${vehicle.vin}` };
      });
      tempArr.unshift({ value: '0', label: 'No Vehicle' });
      setVehicleSelect(tempArr);
    }
  }, [vehiclesData]);

  const { data: unitData } = useUnit(idN, {
    joins: ['accessories', 'accessoriesTypes', 'device', 'simCard', 'vehicle', 'deviceConfig'],
    selects: [
      'id',
      'remarks',
      'accessories.id',
      'accessoriesTypes.id',
      'device.id',
      'simCard.id',
      'vehicle.id',
      ...selectColumns(DeviceConfigColumnsMode, { join: 'deviceConfig' }),
    ],
    then: (unit) => {
      form.setValues({
        device: {
          id: unit.data.device ? unit.data.device.id : 0,
        },
        simCard: {
          id: unit.data.simCard ? unit.data.simCard.id : 0,
        },
        vehicle: {
          id: unit.data.vehicle ? unit.data.vehicle.id : 0,
        },
        deviceConfig: {
          id: unit.data.deviceConfig.id,
          platform: unit.data.deviceConfig.platform ? unit.data.deviceConfig.platform : '',
          secondaryPlatform: unit.data.deviceConfig.secondaryPlatform ? unit.data.deviceConfig.secondaryPlatform : '',
          externalId: unit.data.deviceConfig.externalId ? unit.data.deviceConfig.externalId : '',
          rfidList: {
            id: unit.data.deviceConfig.rfidList ? unit.data.deviceConfig.rfidList.id : 0,
          },
        },
        accessories:
          unit.data.accessories.map((item) => {
            return { id: item.id };
          }) || [],
        accessoriesTypes:
          unit.data.accessoriesTypes.map((item) => {
            return { id: item.id };
          }) || [],
        remarks: unit.data.remarks ? unit.data.remarks : '',
      });
      form.resetDirty({
        device: {
          id: unit.data.device ? unit.data.device.id : 0,
        },
        simCard: {
          id: unit.data.simCard ? unit.data.simCard.id : 0,
        },
        vehicle: {
          id: unit.data.vehicle ? unit.data.vehicle.id : 0,
        },
        deviceConfig: {
          id: unit.data.deviceConfig.id,
          platform: unit.data.deviceConfig.platform ? unit.data.deviceConfig.platform : '',
          secondaryPlatform: unit.data.deviceConfig.secondaryPlatform ? unit.data.deviceConfig.secondaryPlatform : '',
          externalId: unit.data.deviceConfig.externalId ? unit.data.deviceConfig.externalId : '',
          rfidList: {
            id: unit.data.deviceConfig.rfidList ? unit.data.deviceConfig.rfidList.id : 0,
          },
        },
        accessories:
          unit.data.accessories.map((item) => {
            return { id: item.id };
          }) || [],
        accessoriesTypes:
          unit.data.accessoriesTypes.map((item) => {
            return { id: item.id };
          }) || [],
        remarks: unit.data.remarks ? unit.data.remarks : '',
      });
      setDeviceId(unit.data.device ? unit.data.device.id : 0);
      setSimCardId(unit.data.simCard ? unit.data.simCard.id : 0);
      setVehicleId(unit.data.vehicle ? unit.data.vehicle.id : 0);
    },
  });

  const form = useForm<IUpdateUnitInput>({
    initialValues: {
      device: {
        id: unitData?.data.device?.id || 0,
      },
      simCard: {
        id: unitData?.data.simCard?.id || 0,
      },
      vehicle: {
        id: unitData?.data.vehicle?.id || 0,
      },
      deviceConfig: {
        id: unitData?.data.deviceConfig.id || 0,
        platform: unitData?.data.deviceConfig.platform || '',
        secondaryPlatform: unitData?.data.deviceConfig.secondaryPlatform || '',
        externalId: unitData?.data.deviceConfig.externalId || '',
        rfidList: {
          id: unitData?.data.deviceConfig.rfidList?.id || 0,
        },
      },
      accessories:
        unitData?.data.accessories.map((item) => {
          return { id: item.id };
        }) || [],
      accessoriesTypes:
        unitData?.data.accessoriesTypes.map((item) => {
          return { id: item.id };
        }) || [],
      remarks: unitData?.data.remarks || '',
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchUnit, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Unit, types.ALL]);
      queryClient.invalidateQueries([types.Unit, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.Unit, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IUpdateUnitInput) {
    setAlert(null);

    const oldValues = removeNotEdited(values, form);
    if (oldValues.deviceConfig) oldValues.deviceConfig.id = values.deviceConfig.id;
    const newValues = noEmptyString(oldValues);

    if (newValues?.deviceConfig?.rfidList?.id === 0) {
      newValues.deviceConfig.rfidList = null;
    }
    if (newValues.vehicle?.id === 0) {
      newValues.vehicle = null;
    }
    if (newValues.simCard?.id === 0) {
      newValues.simCard = null;
    }
    if (newValues.device?.id === 0) {
      newValues.device = null;
    }

    if (idN !== 0 || newValues.vehicle) {
      if (values.accessories?.length !== 0) {
        newValues.accessories = values.accessories;
      } else newValues.accessories = null;
      if (values.accessoriesTypes?.length !== 0) {
        newValues.accessoriesTypes = values.accessoriesTypes;
      } else newValues.accessoriesTypes = null;
      patchMutate({ id: idN, data: newValues });
    } else {
      setAlert(['The id provided is incorrect']);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit a Unit:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {patchSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Title order={4}>Unit</Title>
            <Divider size="md" />
            <SelectEditable
              dirty={form.isDirty('device.id')}
              label="Device"
              placeholder="Device"
              rightSection={
                form.values.device &&
                form.values.device.id !== 0 && (
                  <ActionIcon onClick={() => form.setFieldValue('device.id', 0)}>
                    <TbX />
                  </ActionIcon>
                )
              }
              {...form.getInputProps('device.id')}
              onChange={(v) => {
                v && form.setFieldValue('device.id', parseInt(v));
                setDeviceId(parseInt(v || '0'));
              }}
              searchable
              nothingFound="Not found"
              value={`${form.values.device?.id}`}
              data={deviceSelect}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('simCard.id')}
              label="Sim Card"
              placeholder="Search here"
              rightSection={
                form.values.simCard &&
                form.values.simCard.id !== 0 &&
                !isNaN(form.values.simCard.id) && (
                  <ActionIcon onClick={() => form.setFieldValue('simCard.id', 0)}>
                    <TbX />
                  </ActionIcon>
                )
              }
              {...form.getInputProps('simCard.id')}
              onChange={(v) => {
                v && form.setFieldValue('simCard.id', parseInt(v));
                setSimCardId(parseInt(v || '0'));
              }}
              value={`${form.values.simCard?.id}`}
              searchable
              nothingFound="Not found"
              data={simCardSelect}
              mt="md"
            />
            <Grid mt="md">
              <Grid.Col span={9}>
                <SelectEditable
                  dirty={form.isDirty('vehicle.id')}
                  label="Vehicle"
                  placeholder="Search here"
                  rightSection={
                    form.values.vehicle &&
                    form.values.vehicle.id !== 0 &&
                    !isNaN(form.values.vehicle.id) && (
                      <ActionIcon onClick={() => form.setFieldValue('vehicle.id', 0)}>
                        <TbX />
                      </ActionIcon>
                    )
                  }
                  {...form.getInputProps('vehicle.id')}
                  onChange={(v) => {
                    v && form.setFieldValue('vehicle.id', parseInt(v));
                    setVehicleId(parseInt(v || '0'));
                  }}
                  value={`${form.values.vehicle?.id}`}
                  searchable
                  nothingFound="Not found"
                  data={vehicleSelect}
                />
              </Grid.Col>
              <Grid.Col span={3} mt="xl">
                <Button onClick={open} fullWidth>
                  add Vehicle
                </Button>
              </Grid.Col>
            </Grid>
            <Title order={4} mt="md">
              Device Config
            </Title>
            <Divider size="md" />
            <SelectEditable
              dirty={form.isDirty('deviceConfig.platform')}
              label="Platform"
              placeholder="Search here"
              {...form.getInputProps('deviceConfig.platform')}
              data={enumToSelectData(DevicePlatforms)}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('deviceConfig.secondaryPlatform')}
              label="secondaryPlatform"
              placeholder="Search here"
              clearable
              {...form.getInputProps('deviceConfig.secondaryPlatform')}
              data={enumToSelectData(DevicePlatforms)}
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('deviceConfig.externalId')}
              label="External Id"
              placeholder="External Id"
              {...form.getInputProps('deviceConfig.externalId')}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('deviceConfigrfidList.id')}
              label="Rfid List"
              placeholder="Search here"
              {...form.getInputProps('deviceConfig.rfidList.id')}
              onChange={(v) => {
                v && form.setFieldValue('deviceConfig.rfidList.id', parseInt(v));
              }}
              searchable
              value={`${form.values.deviceConfig.rfidList?.id}`}
              nothingFound="Not found"
              data={rfidListSelect}
              mt="md"
            />
            <UpdateAccessoriesTransferList form={form} id={idN} />
            <Textarea label="Remarks" placeholder="Remarks" {...form.getInputProps('remarks')} mt="md" />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {deviceId !== 0 && (
            <DetailsCard
              id={deviceId}
              cardHei={400}
              image={
                deviceData && deviceData.data && deviceData.data.items.filter((org) => org.id === deviceId)[0].deviceType.imageRepositoryId
              }
              withImage
            >
              <DeviceCard id={deviceId} />
            </DetailsCard>
          )}
        </Grid.Col>
        <Grid.Col lg={6}>
          {simCardId !== 0 && (
            <DetailsCard
              id={simCardId}
              cardHei={400}
              image={
                simCardsData &&
                simCardsData.data &&
                simCardsData.data.items.filter((org) => org.id === simCardId)[0].simCardType.imageRepositoryId
              }
              withImage
            >
              <SimCardCard id={simCardId} />
            </DetailsCard>
          )}
        </Grid.Col>
        <Grid.Col lg={6}>
          {vehicleId !== 0 && (
            <DetailsCard
              id={vehicleId}
              cardHei={400}
              image={
                vehiclesData && vehiclesData.data && vehiclesData.data.items.filter((org) => org.id === vehicleId)[0].imageRepositoryId
              }
              withImage
            >
              <VehicleCard id={vehicleId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
      <Modal opened={opened} onClose={close} size="60%">
        <NewVehicleForm />
      </Modal>
    </>
  );
}
