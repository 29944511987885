import { useQuery } from 'react-query';
import { types } from '../../../../constants';
import api from '../api';
import { UnitLastMessage } from '../../../unit/types/types';
import { metaData } from '../../types';

interface myResUnit {
  data: {
    items: UnitLastMessage[];
    meta: metaData;
  };
}

export const getUnitLastMessages = (): Promise<myResUnit> => {
  return api.get(`unit-last-message`);
};

export const useUnitLastMessages = (options?: { enabled?: boolean; then?: (x: myResUnit) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.UnitLastMessage, types.ALL];
  const query = useQuery(
    cacheKey,
    () =>
      getUnitLastMessages().then((units) => {
        options && options.then && options.then(units);
        return units;
      }),
    { enabled: options?.enabled, staleTime: 1000 * 10 },
  );
  return query;
};
