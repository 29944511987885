import api from "../../common/api/api";
import { Iquotation, IquotationInput } from "../types/types";

interface myResQuotation {
	data: Iquotation;
}

export const postQuotation = (data: IquotationInput): Promise<myResQuotation> => {
	return api.post(`quotation`, data);
};

