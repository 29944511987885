
import Layout from '../../../components/layout/Layout';
import { NewAccessoryTypeForm } from '../components/NewAccessoryTypeForm';

export function NewAccessoryTypeFormPage() {

  return (
    <Layout>
      <NewAccessoryTypeForm idM={0}/>
    </Layout>
  );
}
