import { Text } from "@mantine/core"
import { useEffect, useState } from "react"
import { SelectEditable } from "../../../../components/form"
import { DownloadAsCsv, Format, URLParams, enumToSelectData, getAllKeysWithPath, getKeysWithPath, getUnitStatusParam, getUnitsWithStatus, selectColumns } from "../../../../utils"
import { Iunit, unitStatus, useUnits } from "../../../unit"
import { VehicleTypeColumnsMode } from "../../../vehicle-types"

export function ExportOrgUnits({
    id,
}:{
    id: number
}) {
    const [ type, setType ] = useState('all')
    const [ fetch, setFetch ] = useState(false)
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
        'deviceConfig',
        'device',
        'device.deviceType as deviceType',
        'simCard',
        'simCard.simCardType as simType',
        'vehicle',
        'vehicle.vehicleType as vehicleType',
        'vehicle.organization as org',
        'purchaseOrderPlan',
        'tasks'
        );
        param.select('id', 'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform',
        'device.id', 'device.imei', 'device.status', 'deviceType.id', 'deviceType.type',
        'simCard.id', 'simCard.number', 'simCard.status', 'simType.id', 'simType.type',
        'vehicle.id', 'vehicle.vin', 'vehicle.plateNumber', 'vehicle.category', 'vehicle.placeOfIssue', ...selectColumns(VehicleTypeColumnsMode, {join: 'vehicleType', exclude: ['imageRepositoryId']}), 
        'org.id', 'org.name', 'org.status',
        )

        param.filterSetField('=org.id', id)
        param.sort(('-unit.id'))
        return param
      })
    // params.se
    const { data: allUnits } = useUnits({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (allUnits) {
          DownloadAsCsv(getUnitsWithStatus(allUnits.data.items) as Iunit[], `${type} Units`, getKeysWithPath(getAllKeysWithPath(allUnits.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [allUnits]);

    return(
        <>
            <Text>Export Units for Organization</Text>
            <SelectEditable data={enumToSelectData(unitStatus)} value={type} onChange={(v) => {
                v && setType(v)
                setParams(param => {
                    param.filterSetField('=org.id', id)
                    if(v){
                        return getUnitStatusParam(v, param, 'vehicle.id|device.id|simCard.id|simCard.status|org.status|device.status|device.status|device.status|purchaseOrderPlan.status|purchaseOrderPlan.status|tasks.type||tasks.status|unit.id|unit.id|unit.id|unit.id')
                    }
                    else return param
                })
                setFetch(true)
            }}
            withinPortal 
            />
        </>
    )
}