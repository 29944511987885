import { Grid } from '@mantine/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ActionButtonsCard,
  DetailsCard
} from '../../../components/detailsCards';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { HistoryCard } from '../../history';
import { VehicleTypeCard } from '../components/vehicleTypeCard';

const Height = 400;

export function VehicleTypeDetails() {
  const { id } = useParams();
  const [image, setImage] = useState<number | null>(null);

  let idN = 0;
  if (typeof id === 'string') {
    idN = parseInt(id);
  }

  return (
    <Layout>
      <Grid>
        <Grid.Col lg={7} md={12}>
          <DetailsCard cardHei={Height} id={idN} image={image} withImage>
            <VehicleTypeCard setImage={setImage} id={idN} />
          </DetailsCard>
        </Grid.Col>
        <Grid.Col lg={5} md={12}>
          <ActionButtonsCard id={[idN]} type={types.VehicleType} />
        </Grid.Col>
        <Grid.Col lg={12} md={12}>
          <HistoryCard historySearch={[{id: idN, type: types.VehicleType}]} cardHei={Height} />
        </Grid.Col>
      </Grid>
    </Layout>
  );
}
