import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { useColorScheme, useHotkeys, useLocalStorage } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './context/AuthContext';
import { IaxiosError } from './modules/common';
import Pages, { publicRoutes } from './pages';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (err) => {
        if ((err as IaxiosError).response?.data?.message === 'Unauthorized') {
          if (!publicRoutes.includes(window.location.pathname)) window.location.href = `/login?redirect=${window.location.pathname}`;
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 1000 * 60 * 5,
    },
  },
});

//improve the dark theme, maybe use cookie
function App() {
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: preferredColorScheme,
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        theme={{
          primaryColor: 'lime',
          primaryShade: 8,
          loader: 'bars',
          colorScheme,
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Notifications />
            <Pages />
          </AuthProvider>
        </QueryClientProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
