import { Button, Checkbox, Container, Group, LoadingOverlay, Paper } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { types } from "../../../constants";
import { URLParams, selectColumns, useAsateelActions } from "../../../utils";
import { IaxiosError } from "../../common";
import { patchCertificates, useCertificates } from "../api";
import { AsateelCertificateColumnsMode } from "../constants";

export function RenewCerticficates ({ids}:{ids: number[]}) {
    const {downloadPdf} = useAsateelActions()
    const [newCertificates, setNewCertificates] = useState<{id: number, activationDate: Date, expiryDate: Date}[]>([])
    const [withDate, setWithDate] = useState(true)
    const params = new URLParams()
    params.searchMany('id', ids.map(id => `${id}`))
    params.includes(
        'vehicle',
        'vehicle.organization as vehicleOrg',
        'vehicleOrg.parent as orgParent',
        'device',
        'device.deviceType as deviceType',
        'simCard as simCard',
        );
      params.sort(('-asateel_certificate.certificateID'))
      params.select(...selectColumns(AsateelCertificateColumnsMode),
      'vehicle.id', 'vehicle.vin', 'vehicle.plateNumber', 'vehicle.licenseIssueDate', 'vehicle.licenseExpiryDate', 'vehicle.category', 'vehicle.placeOfIssue',
      'vehicleOrg.id', 'vehicleOrg.name', 'orgParent.id', 'orgParent.name',
      'device.id', 'device.imei', 'deviceType.id', 'deviceType.type', 'simCard.id', 'simCard.number',
      )
    const { data: allCertificates, isLoading } = useCertificates({ params: params.toString() });
    const queryClient = useQueryClient();
    
    useEffect(() => {
        if (allCertificates) {
            setNewCertificates(allCertificates.data.items.map((item) => {return {id: item.id, activationDate: dayjs(new Date(item.activationDate)).add(1, 'year').toDate(), expiryDate: dayjs(new Date(item.expiryDate)).add(1, 'year').subtract(1, 'day').toDate()}}))
        }
    }, [allCertificates]);

    const {
        mutate: patchMutate,
        isLoading: patchLoading,
      } = useMutation(patchCertificates, {
        onSuccess: () => {
        notifications.show({
            title: 'Success',
            message: `Certificates renewed Sucessfully`,
            color: 'green',
            autoClose: false,
            });
          queryClient.invalidateQueries([types.AsateelCertificate, types.ALL]);
          queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
          });
        },
        onError: (data: IaxiosError) => {
            notifications.show({
                title: 'Error',
                message: `${data.response.data.message}`,
                color: 'red',
                autoClose: false,
              });
        },
      });

      function handleSubmit(){
        patchMutate(newCertificates)
      }

      function downloadCerificates(){
        if(allCertificates) {
            allCertificates.data.items.forEach((item) => {
                item.withDate = withDate
                downloadPdf(item)
            })
        }
      }

      return(
        <Container pt={10} fluid>
            <Paper withBorder shadow="md" p={30} my={30} radius="md">
            <LoadingOverlay visible={patchLoading || isLoading} overlayBlur={2} />
            <Checkbox label="with Date?" checked={withDate} onChange={() => setWithDate(!withDate)} />
            <Group position="apart" mt='sm' >
            <Button
                fullWidth
                onClick={handleSubmit}
            >
                Confirm
            </Button>
            <Button
                fullWidth
                onClick={downloadCerificates}
            >
                download Certificates
            </Button>
            </Group>

            
            </Paper>
        </Container>
    );
}