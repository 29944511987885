import {
   ActionIcon,
   Badge,
   Grid,
   Paper,
   Text
} from '@mantine/core';
  
  import { AiTwotoneEdit } from 'react-icons/ai';
import { selectColumns } from '../../../../utils';
import { usePurchasePlan } from '../../api/getPurchasePlan';
import { PlanColumnsMode } from '../../constants';
  
  
  
  
export function UpdatePlanSummary(
{setOpenEdited, planId, planIndex}
:
{
   setOpenEdited:React.Dispatch<React.SetStateAction<number>>,
   planId: number, 
   planIndex:number,
}) {
   const { data: purchasePlanData } = usePurchasePlan(planId, {
      selects: [...selectColumns(PlanColumnsMode)],
      });
   
   return(
   <Paper withBorder p="sm" mt='sm'>
      <Grid>
         <Grid.Col span={4}>
            <Text>Type: {purchasePlanData?.data.type}</Text>
            <Text>Interval: {purchasePlanData?.data.interval}</Text>
            <Text>Status: <Badge>{purchasePlanData?.data.status}</Badge></Text>
         </Grid.Col>
         <Grid.Col span={4}>
         {purchasePlanData?.data.startDate && <Text>start date: {new Date(purchasePlanData?.data.startDate).toDateString()}</Text>}
         {purchasePlanData?.data.endDate && <Text>end date: {new Date(purchasePlanData?.data.endDate).toDateString()}</Text>}
         <Text>Quantity: {purchasePlanData?.data.quantity?purchasePlanData?.data.quantity:0}</Text>
         <Text>Remarks: {purchasePlanData?.data.remarks?purchasePlanData?.data.remarks:'empty'}</Text>
         </Grid.Col>
         <Grid.Col span={4}>
            <ActionIcon onClick={() => setOpenEdited(planIndex)}>
                  <AiTwotoneEdit />
               </ActionIcon>
         </Grid.Col>
      </Grid>
   </Paper>
   )
}