import { ActionIcon, Alert, Center, Group, LoadingOverlay, Paper, ScrollArea, SegmentedControl, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { TbAlertCircle, TbListSearch, TbPlus } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { UnitWithAllExtras, patchUnit } from '..';
import { types } from '../../../constants';
import { useTimeAgo } from '../../../utils';
import { IaxiosError, useSearchWialonByName } from '../../common';

function UnitResult({
  unit,
  externalId,
  name,
  time,
  setLoading,
}: {
  unit: UnitWithAllExtras;
  externalId: number;
  name: string;
  time: number | undefined | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { time: timeAgo } = useTimeAgo(time || 0);
  const queryClient = useQueryClient();

  const { mutate: patchMutate } = useMutation(patchUnit, {
    onSuccess: () => {
      setLoading(false);
      notifications.show({
        title: 'Success',
        message: `Connected with Wialon successfully`,
        color: 'green',
        autoClose: 10000,
      });
      queryClient.invalidateQueries([types.Unit, types.ALL]);
      queryClient.invalidateQueries([types.Unit, { id: unit.id }]);
      queryClient.invalidateQueries([types.History, types.Unit, { id: unit.id }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: false,
      });
    },
  });

  console.log(unit.deviceConfig.externalId, externalId, unit.deviceConfig.externalId === externalId);

  return (
    <Paper p="xs" mb="xs" withBorder>
      <Group position="apart">
        {`${name} - ${timeAgo}`}
        <ActionIcon
          disabled={unit.deviceConfig.externalId === externalId}
          onClick={() => {
            setLoading(true);
            patchMutate({ id: unit.id, data: { deviceConfig: { id: unit.deviceConfig.id, externalId: externalId.toString() } } });
          }}
        >
          <TbPlus size="1.7rem" />
        </ActionIcon>
      </Group>
    </Paper>
  );
}

export function WialonExternalId({ unit }: { unit: UnitWithAllExtras }) {
  console.log(unit)
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      searchType: 'plate',
      search: `${unit.vehicle.plateNumber ?? ''}`,
    },
  });

  const { data, refetch } = useSearchWialonByName(form.values.search, {
    enabled,
    then(data) {
      setEnabled(false);
      return data.data.items as {
        id: number;
        nm: string;
        lmsg: {
          t: number;
        };
      }[];
    },
  });

  function handleSubmit(values: typeof form.values) {
    if (values.search === '') {
      if (values.searchType === 'plate') {
        notifications.show({
          title: 'Error',
          message: `No plate found for this unit`,
          color: 'red',
          autoClose: false,
        });
        return;
      } else if (values.searchType === 'vin') {
        notifications.show({
          title: 'Error',
          message: `No vin found for this unit`,
          color: 'red',
          autoClose: false,
        });
        return;
      } else {
        notifications.show({
          title: 'Error',
          message: `Please enter something`,
          color: 'red',
          autoClose: false,
        });
        return;
      }
    }
    if (!form.values.search.startsWith('*') && !form.values.search.endsWith('*')) form.values.search = `*${values.search}*`;
    setEnabled(true);
    refetch();
  }

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Alert icon={<TbAlertCircle size="1rem" />} title="Clarification!" mb="xs">
          Searching using plate or vin is done using the wialon unit name, not the plate or vin fileds. use custom search if needed!
        </Alert>
        <Group position="apart" mb="md">
          <SegmentedControl
            data={[
              { label: 'Plate', value: 'plate' },
              { label: 'Vin', value: 'vin' },
              { label: 'Custom', value: 'custom' },
            ]}
            {...form.getInputProps('searchType')}
            onChange={(v) => {
              const obj: Record<string, string> = {
                plate: `${unit.vehicle.plateNumber ?? ''}`,
                vin: `${unit.vehicle.vin ?? ''}`,
              };
              if (v !== 'custom') form.setFieldValue('search', obj[v]);
              form.setFieldValue('searchType', v);
            }}
          />
          <ActionIcon my="auto" variant="outline" size="lg" type="submit">
            <TbListSearch size="1.7rem" />
          </ActionIcon>
        </Group>
        {form.values.searchType === 'custom' && (
          <TextInput mb="xs" {...form.getInputProps('search')} onChange={(e) => form.setFieldValue('search', e.target.value)} />
        )}
      </form>
      <ScrollArea h={350}>
        {data?.data.items.length ? (
          data?.data.items.map((item, index) => (
            <UnitResult unit={unit} externalId={item.id} name={item.nm} time={item.lmsg?.t} key={index} setLoading={setLoading} />
          ))
        ) : (
          <Center>No Results</Center>
        )}
      </ScrollArea>
    </>
  );
}
