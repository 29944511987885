import api from "../../common/api/api";
import { IrfidList, IrfidListInput } from "../types/types";

interface myResRfidListPost {
	data: IrfidList;
}

export const postRfidList = (data: IrfidListInput): Promise<myResRfidListPost> => {
	return api.post(`rfid-list`, data);
};
