import { Chip } from "@mantine/core";

export function GlobalFilterChips({
  globalFilter,
  clearGlobalFilter,
}: {
  globalFilter: [];
  clearGlobalFilter: (args?: any) => void;
}): JSX.Element | null {
  if (globalFilter?.length > 0)
    return (
      <>
        {globalFilter.map((val, index) => (
          <Chip key={val + index} onClick={() => clearGlobalFilter(val)}>
            {val}
          </Chip>
        ))}
      </>
    );
  return null;
}
