import {
  Alert,
  Button,
  Center,
  Container,
  List,
  LoadingOverlay,
  Paper,
  Table,
  Text,
  Textarea,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DatePickerInputEditable } from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { useAuthContext } from '../../../../context/AuthContext';
import { noEmptyString } from '../../../../utils';
import { IaxiosError } from '../../../common';
import { DevicePlatforms, postUnit } from '../../../unit';
import { patchTask, useTask } from '../../api';
import { AccessoriesTransferList } from '../../components';
import { TaskStatus } from '../../constants';
import { Task } from '../../types/types';
  
  interface accessoryInstallation {
    accessories: { id: number }[] | null;
    jobRemarks: string | null;
    completionDate: Date | null;
  }
  
  export function AccessoryInstallationTaskForm() {
    const { id } = useParams();
    const [idN] = useState(id && !isNaN(+id) ? +id : 0);
    const [task, setTask] = useState<Task>();
    const [alert, setAlert] = useState<string | string[] | null>(null);

    const { user } = useAuthContext();
    
    const { data: taskData } = useTask(idN, {
      joins: ['unit', 'unit.deviceConfig as deviceConfig', 'unit.device as device', 'unit.vehicle as vehicle', 'unit.simCard as simCard'],
      selects: [
        'id',
        'completionDate',
        'jobRemarks',
        'unit.id',
        'device.id',
        'device.imei',
        'simCard.id',
        'simCard.number',
        'vehicle.id',
        'vehicle.vin',
        'vehicle.plateNumber',
        'deviceConfig.id',
        'deviceConfig.platform',
        'deviceConfig.secondaryPlatform',
      ],
      then: (task) => {
        form.setValues({
          accessories: [],
          jobRemarks: task.data.jobRemarks ? task.data.jobRemarks : '',
          completionDate: task.data.completionDate ? new Date(task.data.completionDate) : new Date(),
        });
      },
    });
  
    useEffect(() => {
      if (taskData) {
        setTask(taskData.data);
      }
    }, [taskData]);
    const form = useForm<accessoryInstallation>({
      initialValues: {
        accessories: [],
        jobRemarks: '',
        completionDate: new Date(),
      },
    });

  
    const queryClient = useQueryClient();

    const {
        mutate: newUnitMutate,
        isLoading: newUnitLoading,
      } = useMutation(postUnit, {
        onSuccess: (data) => {
          setAlert(null);
          queryClient.invalidateQueries([types.Unit, types.ALL]);
          queryClient.invalidateQueries([types.Accessories, types.ALL]);
          queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
          });
          if(task && user){
              patchMutate({id: task.id, data: {
                completionDate: form.values.completionDate,
                jobRemarks: form.values.jobRemarks ?? null,
                installationUnit: {id: data.data.id},
                status: TaskStatus.ToBeApproved,
                performedBy: {id: user.id}
              }})
          }
        },
        
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });

    const { mutate: patchMutate, isLoading: patchLoading } = useMutation(patchTask, {
      onSuccess: () => {
        notifications.show({
          title: 'Success',
          message: `Successfully installed accessory`,
          color: 'green',
          autoClose: 10000,
        });
        setAlert(null);
        queryClient.invalidateQueries([types.Task, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });
  
    function handleSubmit(values: accessoryInstallation) {
      setAlert(null);
      if (taskData && task) {
        const finalValues = noEmptyString({
            device: null,
            vehicle: null,
            simCard: null,
            accessories: values.accessories,
            accessoriesTypes: null,
            deviceConfig: {
              platform: DevicePlatforms.Wialon,
              secondaryPlatform: null,
            },
            remarks: null
        });
        newUnitMutate(finalValues);
      }
    }
  
    return (
      <Layout>
        <Container pt={10}>
          <Title
            order={2}
            pl={5}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Accessory Installation Form:
          </Title>
          <Paper withBorder shadow="md" p={30} my={30} radius="md" pos={'relative'}>
            <LoadingOverlay visible={patchLoading || newUnitLoading} overlayBlur={2} />
            {alert && (
              <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg, i) => (
                      <List.Item key={`E-${i}`}>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
            {task && task.unit && (
                <>
                  <Table mt={12} p={5}>
                    <tbody>
                      <tr>
                        <td>Plate No.:</td>
                        <td>{task.unit.vehicle?.plateNumber}</td>
                      </tr>
                      <tr>
                        <td>Vin:</td>
                        <td>{task.unit.vehicle?.vin}</td>
                      </tr>
                      <tr>
                        <td>Imei:</td>
                        <td>{task.unit.device?.imei}</td>
                      </tr>
                      <tr>
                        <td>Sim Card Number:</td>
                        <td>{task.unit.simCard?.number}</td>
                      </tr>
                      <tr>
                        <td>Platform:</td>
                        <td>{task.unit.deviceConfig.platform}</td>
                      </tr>
                      <tr>
                        <td>secondary Platform:</td>
                        <td>{task.unit.deviceConfig.secondaryPlatform}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                    <DatePickerInputEditable
                      placeholder="Search Date"
                      label="completion Date"
                      {...form.getInputProps('completionDate')}
                      required
                      mt="md"
                    />
                    <AccessoriesTransferList form={form} />
                    <Textarea label="Installation Remarks" placeholder="remarks..." mt="md" {...form.getInputProps('jobRemarks')} />
                    <Center mt="lg">
                      <Button type="submit" fullWidth>
                        Confirm Accessory Installation
                      </Button>
                    </Center>
                  </form>
                </>
  
            )}
            {task && !task.unit && (
              <Center>
                <Text> Please Contact Techinical Support To Add Unit For Task</Text>
              </Center>
            )}
          </Paper>
        </Container>
      </Layout>
    );
  }
  