import { Alert, Button, Container, Grid, List, LoadingOverlay, Paper, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DetailsCard } from '../../../components/detailsCards';
import { DatePickerInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { enumToSelectData, removeNotEdited } from '../../../utils';
import { IaxiosError } from '../../common';
import { SimCardTypeCard, useSimCardTypes } from '../../sim-card-types';
import { patchSimCard, useSimCard } from '../api';
import { SimStatus } from '../constants/constants';
import { IsimCardInput } from '../types/types';

export function UpdateSimCardForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [simCardTypesSelect, setSimCardTypesSelect] = useState([{ value: '', label: '' }]);
  const [simCardTypeId, setSimCardTypeId] = useState(0);
  const queryClient = useQueryClient();

  const { data: allSimCardTypesData } = useSimCardTypes();
  useEffect(() => {
    if (allSimCardTypesData) {
      const tempArr = allSimCardTypesData.data.items.map((simCard) => {
        return {
          value: `${simCard.id}`,
          label: `${simCard.supplier}, ${simCard.type}`,
        };
      });
      setSimCardTypesSelect(tempArr);
    }
  }, [allSimCardTypesData]);

  const { data: simCardData } = useSimCard(idN, {
    joins: ['simCardType'],
    then: (simCard) => {
      form.setValues({
        number: simCard.data.number,
        serial: simCard.data.serial,
        imsi: simCard.data.imsi,
        simCardType: simCard.data.simCardType.id,
        status: simCard.data.status,
        purchaseDate: new Date(simCard.data.purchaseDate),
        remarks: simCard.data.remarks ?? ''
      });
      setSimCardTypeId(simCard.data.simCardType.id);
      
      form.resetDirty({
        number: simCard.data.number,
        serial: simCard.data.serial,
        imsi: simCard.data.imsi,
        simCardType: simCard.data.simCardType.id,
        status: simCard.data.status,
        purchaseDate: new Date(simCard.data.purchaseDate),
        remarks: simCard.data.remarks ?? ''
      });
    },
  });

  const form = useForm<IsimCardInput>({
    initialValues: {
      number: simCardData?.data.number || '',
      serial: simCardData?.data.serial || '',
      imsi: simCardData?.data.imsi || '',
      simCardType: simCardData?.data.simCardType.id || NaN,
      status: simCardData?.data.status || '',
      purchaseDate: simCardData?.data.purchaseDate ? new Date(simCardData?.data.purchaseDate) : new Date(),
      remarks: simCardData?.data.remarks || ''
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchSimCard, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.SimCard, types.ALL]);
      queryClient.invalidateQueries([types.SimCard, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.SimCard, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IsimCardInput) {
    setAlert(null);
    const newValues = removeNotEdited(values, form);
    if (idN !== 0 && !Number.isNaN(idN)) {
      patchMutate({ id: idN, data: newValues });
    } else {
      setAlert(['The id provided is incorrect']);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit a Sim Card:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {patchSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              dirty={form.isDirty('number')}
              label="Number"
              placeholder="Sim Number"
              {...form.getInputProps('number')}
              required
            />
            <TextInputEditable
              dirty={form.isDirty('serial')}
              label="Serial"
              placeholder="Serial No."
              {...form.getInputProps('serial')}
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('imsi')}
              label="IMSI"
              placeholder="IMSI No."
              {...form.getInputProps('imsi')}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('simCardType')}
              label="Sim Card Type"
              placeholder="Search for Sim Card type"
              {...form.getInputProps('simCardType')}
              onChange={(v) => {
                form.setFieldValue('simCardType', parseInt(v || '0'));
                setSimCardTypeId(parseInt(v || '0'));
              }}
              value={`${form.values.simCardType}`}
              searchable
              nothingFound="Not found"
              data={simCardTypesSelect}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('status')}
              label="status"
              placeholder="Search for Sim Card status"
              {...form.getInputProps('status')}
              data={enumToSelectData(SimStatus)}
              mt="md"
            />

            <DatePickerInputEditable
              placeholder="Search Date"
              label="Purchase Date"
              {...form.getInputProps('purchaseDate')}
              clearable
              mt="md"
            />
            <Textarea label="Remarks" placeholder="Remarks" {...form.getInputProps('remarks')} mt="md" />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {simCardTypeId !== 0 && (
            <DetailsCard
              id={simCardTypeId}
              cardHei={400}
              image={
                allSimCardTypesData &&
                allSimCardTypesData.data &&
                allSimCardTypesData.data.items.filter((cardtype) => cardtype.id === simCardTypeId)[0].imageRepositoryId
              }
              withImage
            >
              <SimCardTypeCard id={simCardTypeId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
}
