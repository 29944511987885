import { Alert, Button, Center, Container, List, LoadingOverlay, Paper, Stepper, Table, Text, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DatePickerInputEditable } from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { useAuthContext } from '../../../../context/AuthContext';
import { IaxiosError } from '../../../common';
import { NewDeliveryNoteForm } from '../../../deliveryNote';
import { patchDevice } from '../../../devices';
import { patchTask, useTask } from '../../api';
import { TaskStatus } from '../../constants/constants';
import { Task } from '../../types/types';

export function RemovalTaskForm() {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [idN] = useState(id && !isNaN(+id) ? +id : 0);
  const [task, setTask] = useState<Task>();
  const [alert, setAlert] = useState<string | string[] | null>(null);

  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));

  const form = useForm({
    initialValues: {
      completionDate: new Date(),
      jobRemarks: '',
    },
  });

  const { data: taskData } = useTask(idN, {
    joins: ['unit', 'unit.device as device', 'unit.vehicle as vehicle', 'unit.simCard as simCard'],
    selects: [
      'id',
      'jobRemarks',
      'unit.id',
      'completionDate',
      'device.id',
      'device.imei',
      'vehicle.id',
      'vehicle.vin',
      'vehicle.plateNumber',
      'simCard.id',
      'simCard.number',
    ],
    then: (task) => {
      form.setValues({
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : new Date(),
      });
    },
  });

  useEffect(() => {
    if (taskData) {
      setTask(taskData.data);
    }
  }, [taskData]);

  const { mutate: patchDeviceMutate } = useMutation(patchDevice, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Device, types.ALL]);
      queryClient.invalidateQueries([types.Device, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.Device, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: 10000,
      });
    },
  });

  const queryClient = useQueryClient();
  const { mutate: patchMutate, isLoading: patchLoading } = useMutation(patchTask, {
    onSuccess: (data) => {
      if (data.data.unit && data.data.unit.device && user) {
        patchDeviceMutate({ id: data.data.unit.device.id, data: { issuedTo: { id: user.id } } });
      }
      notifications.show({
        title: 'Success',
        message: `Successfully removed device Please Fill Delivery Note`,
        color: 'green',
        autoClose: 10000,
      });
      nextStep();
      setAlert(null);
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: { jobRemarks: string; completionDate: Date }) {
    setAlert(null);
    if (taskData && user) {
      patchMutate({
        id: taskData.data.id,
        data: {
          status: TaskStatus.ToBeApproved,
          jobRemarks: values.jobRemarks,
          completionDate: values.completionDate,
          performedBy: { id: user.id },
        },
      });
    } else setAlert('something wrong with the data please refresh.');
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Removal Form:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos={'relative'}>
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg, i) => (
                    <List.Item key={`E-${i}`}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {task && task.unit && (
            <Stepper active={active} onStepClick={setActive}>
              <Stepper.Step label="Removal">
                <Table mt={12} p={5}>
                  <tbody>
                    <tr>
                      <td>Plate No.:</td>
                      <td>{task.unit.vehicle?.plateNumber}</td>
                    </tr>
                    <tr>
                      <td>Vin:</td>
                      <td>{task.unit.vehicle?.vin}</td>
                    </tr>
                    <tr>
                      <td>Imei:</td>
                      <td>{task.unit.device?.imei}</td>
                    </tr>
                    <tr>
                      <td>Sim Card Number:</td>
                      <td>{task.unit.simCard?.number}</td>
                    </tr>
                  </tbody>
                </Table>
                <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                  <DatePickerInputEditable
                    placeholder="Search Date"
                    label="completion Date"
                    {...form.getInputProps('completionDate')}
                    required
                    mt="md"
                  />

                  <Textarea label="Unit Remarks" placeholder="remarks..." mt="md" {...form.getInputProps('jobRemarks')} />
                  <Center mt="lg">
                    <Button type="submit" fullWidth>
                      Confirm Removal
                    </Button>
                  </Center>
                </form>
              </Stepper.Step>

              <Stepper.Step label="Delivery Note">
                <NewDeliveryNoteForm taskID={idN} />
              </Stepper.Step>
            </Stepper>
          )}
          {task && !task.unit && (
            <Center>
              <Text> Please Contact Techinical Support To Add Unit For Task</Text>
            </Center>
          )}
        </Paper>
      </Container>
    </Layout>
  );
}
