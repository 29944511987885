import { TextInput, TextInputProps } from "@mantine/core";
import { KeyboardEventHandler, useCallback, useState } from "react";

function KeyboardInput({
  label,
  handleValue,
  ...props
}: TextInputProps & {
  label: string;
  handleValue: (args: string) => void;
}) {
  const [value, setValue] = useState("");

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (handleValue) {
        if (e.key === "Enter") {
          handleValue(value);
          setValue("");
        }
      }
    },
    [handleValue, value]
  );

  return (
    <TextInput
      label={label}
      value={value}
      p={10}
      onChange={(event) => setValue(event.currentTarget.value)}
      onKeyUp={onKeyUp}
      {...props}
    />
  );
}

export default KeyboardInput;
