import { Button, Popover, Select } from "@mantine/core";
import { enumToSelectData } from "../../utils";

export function SelectEnumColumn({
    label,
    dbLabel,
    data,
    value,
    setValue,
    filterData,
  }: {
    label: string;
    data: Record<string, string>;
    dbLabel: string,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>
    filterData?: (column: string, data: string | null, type:string) => void,
  }){
    const selects = enumToSelectData(data)
    if(dbLabel!== 'noID'){
      selects.push({label: 'Empty', value: '='})
      selects.push({label: 'Not Empty', value: '!'})
      
    } 
    selects.unshift({label: 'All', value: ''})
    return(
        <Popover width={300} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button>{label}</Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Select 
        data={selects}
        value={value}
        onChange={(v) => {
          if(v === '=' || v === '!'){
            filterData && filterData(dbLabel, v, 'null')
            v !== null && setValue(v)
          }else {
            filterData && filterData(dbLabel, v, 'select')
            v !== null && setValue(v)
          }
        }}
        styles={{dropdown: {
            width: 300,
        }}}
        />
      </Popover.Dropdown>
    </Popover>
    )
}