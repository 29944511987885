import api from "../../common/api/api";
import { Iorganization, IorganizationInput } from "../types/types";

interface myResOrganization {
	data: Iorganization;
}

export const postOrganization = (data: IorganizationInput): Promise<myResOrganization> => {
	return api.post(`organizations`, data);
};

export const postOrganizations = (data: Record<string, any>[]): Promise<myResOrganization[]> => {
	return api.post(`organizations/multi`, data);
};
