import api from "../../common/api/api";
import { Icomment, IcommentInput } from "../types/types";

interface myResCommentPost {
	data: Icomment;
}

export const postComment = (data: IcommentInput): Promise<myResCommentPost> => {
	return api.post(`comments`, data);
};

export const postComments = (data: Record<string, any>[]): Promise<myResCommentPost[]> => {
	return api.post(`comments/multi`, data);
};