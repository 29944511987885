import {
    Alert,
    Button,
    Container,
    Grid,
    Input,
    List,
    LoadingOverlay,
    Modal,
    Paper,
    Title,
    Tooltip
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { NumberInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { RichTextEditorComponent } from '../../../components/richTextEditor/RichTextEditorComponent';
import { types } from '../../../constants';
import { enumToSelectData } from '../../../utils';
import { IaxiosError } from '../../common';
import { patchQuotation, useQuotation } from '../api';
import { CountryOfOrigin, QuotationPlanType, QuotationStatus } from '../constants/constants';
import { IquotationPlanInput, updateQuotation } from '../types/types';
import { NewQuotationContentForm } from './NewQuotationContentForm';
  
export function UpdateQuotationTemplete({idM}:{idM?:number}) {
    const { id } = useParams();
    const [idN] = useState(+(id || idM || 0));
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const [opened, { open, close }] = useDisclosure(false);
    const [planType, setPlanType] = useState(QuotationPlanType.FirstPayment)

    const queryClient = useQueryClient();

    const { data: quotationData } = useQuotation(idN, {
        joins:['quotationPlan', 'quotationPlan.quotationContent'],
        then: (quotation) => {
           form.setValues({
            name: quotation.data.name ,
            title: quotation.data.title ,
            status: quotation.data.status,
            organization: null,
            contact: null,
            qautationDate: null,
            countryOfOrigin: quotation.data.countryOfOrigin,
            validity: quotation.data.validity,
            termsConditions: quotation.data.termsConditions ,
            templete: true,
            discount: quotation.data.discount ?? '',
            quotationPlan: quotation.data.quotationPlan.map(plan => {
                return {
                    id: plan.id,
                    title: plan.title ,
                    type: plan.type,
                    massage: plan.massage ,
                    quotationContent: plan.quotationContent.map(content => {
                        return {
                            id: content.id,
                            description: content.description,
                            price: content.price,
                            quantity: content.quantity,
                        }
                    })
                }
            }) ,
           });
           form.resetDirty({
            name: quotation.data.name || '',
            title: quotation.data.title || '',
            status: quotation.data.status || QuotationStatus.New,
            organization: null,
            contact: null,
            qautationDate: null,
            countryOfOrigin: quotation.data.countryOfOrigin || CountryOfOrigin.Europe,
            validity: quotation.data.validity || 15,
            termsConditions: quotation.data.termsConditions || '',
            templete: true,
            discount: quotation.data.discount ?? '',
            quotationPlan: quotation.data.quotationPlan.map(plan => {
                return {
                    id: plan.id || 0,
                    title: plan.title || '',
                    type: plan.type || QuotationPlanType.FirstPayment,
                    massage: plan.massage || '',
                    quotationContent: plan.quotationContent.map(content => {
                        return {
                            id: content.id,
                            description: content.description,
                            price: content.price,
                            quantity: content.quantity,
                            }
                        })
                    }
                }) || [],
            });
           
        },
        
     });


    const quotationPlan: IquotationPlanInput = {
        massage: '',
        title: '',
        type: '',
        quotationContent: []
    }

    
    const form = useForm<updateQuotation>({
      initialValues: {
        name: quotationData?.data.name || '',
        title: quotationData?.data.title || '',
        status: quotationData?.data.status || QuotationStatus.New,
        organization: null,
        contact: null,
        qautationDate: null,
        countryOfOrigin: quotationData?.data.countryOfOrigin || CountryOfOrigin.Europe,
        validity: quotationData?.data.validity || 15,
        termsConditions: quotationData?.data.termsConditions || '',
        templete: true,
        discount: quotationData?.data.discount ?? '',
        quotationPlan: quotationData?.data.quotationPlan.map(plan => {
            return {
                id: plan.id || 0,
                title: plan.title || '',
                type: plan.type || QuotationPlanType.FirstPayment,
                massage: plan.massage || '',
                quotationContent: plan.quotationContent.map(content => {
                    return {
                        id: content.id || 0,
                        description: content.description || '',
                        price: content.price || 0,
                        quantity: content.quantity || 0,
                    }
                })
            }
        }) || [],
      },
    });
  
    const {
      mutate: newMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(patchQuotation, {
      onSuccess: () => {
        setAlert(null);
        queryClient.invalidateQueries([types.Quotation, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });

    function handleSubmit(values: updateQuotation) {
        let valid = true

        const newValues = {
            name: values.name,
            title: values.title,
            status: values.status,
            organization: null,
            contact:  null,
            qautationDate:  null,
            countryOfOrigin: values.countryOfOrigin,
            validity: values.validity,
            discount: values.discount ?? null,
            quotationPlan: values.quotationPlan.map(item => {
                return {
                    id: item.id,
                    massage: item.massage ? item.massage : null,
                    title: item.title ? item.title : null,
                    type: item.type,
                    quotationContent: item.quotationContent.filter(item => item.description && item.quantity).map(item => {
                        return {
                            id: item.id,
                            description: item.description,
                            price: item.price,
                            quantity: item.quantity,
                        }
                    })
                }
            }),
            termsConditions: values.termsConditions,
        }
        newValues.quotationPlan.forEach(item => {
            if(!item.quotationContent.filter(item => item.description && item.quantity).length) valid = false
        })
        if(newValues.quotationPlan.length && valid){
            setAlert(null)
            newMutate({id:idN, data:newValues});
        } else setAlert('a quotation needs at least to one 1 content to be created')
    //   setAlert(null);
    //   const newValues = noEmptyString({ ...values });
    }
  
    return (
        <Layout>

            <Container pt={10}>
            <Title
                order={2}
                pl={5}
                align="left"
                sx={(theme) => ({
                fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                fontWeight: 800,
                })}
            >
                Add a new Quotation:
            </Title>
            <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
                <LoadingOverlay visible={newLoading} overlayBlur={2} />
                {alert && (
                <Alert
                    icon={<TbAlertOctagon size={16} />}
                    title="Error!"
                    color="red"
                    mb={10}
                >
                    {Array.isArray(alert) ? (
                    <List>
                        {alert.map((errMsg) => (
                        <List.Item>{errMsg}!</List.Item>
                        ))}
                    </List>
                    ) : (
                    alert + '!'
                    )}
                </Alert>
                )}
                {newSuccess && (
                <Alert
                    icon={<TbCircleCheck size={16} />}
                    title={'Created!'}
                    color="green"
                    mb={10}
                >
                    Successfully added to the database!
                </Alert>
                )}
                <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                    <TextInputEditable
                    required
                    mt='md'
                    withAsterisk={false}
                    label={<Tooltip label={'The title at the top of the pdf above the client details'}><Input.Label required>Title</Input.Label></Tooltip>}
                    placeholder="title"
                    {...form.getInputProps('title')}
                    />
                    <Grid>
                        <Grid.Col span={6}>
                            <NumberInputEditable
                                    label="Discount"
                                    placeholder="discount"
                                    precision={2}
                                    {...form.getInputProps('discount')}
                                    hideControls
                                    mt='md'
                                    />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <SelectEditable
                            label="Country Of Origin"
                            placeholder="Search Here"
                            {...form.getInputProps('countryOfOrigin')}
                            required
                            data={enumToSelectData(CountryOfOrigin)}
                            mt="md"
                            />
                            <NumberInputEditable
                                label="Validity"
                                placeholder="contact validity days"
                                {...form.getInputProps('validity')}
                                required
                                hideControls
                                mt='md'
                            />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Button fullWidth onClick={() => {
                                if(!form.values.quotationPlan.find(item => item.type === QuotationPlanType.FirstPayment)) form.insertListItem('quotationPlan', {...quotationPlan, type: QuotationPlanType.FirstPayment})
                                setPlanType(QuotationPlanType.FirstPayment)
                                open()
                        }}>First Year Charge</Button>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Button fullWidth onClick={() => {
                                if(!form.values.quotationPlan.find(item => item.type === QuotationPlanType.Renwal)) form.insertListItem('quotationPlan', {...quotationPlan, type: QuotationPlanType.Renwal})
                                setPlanType(QuotationPlanType.Renwal)
                                open()
                        }}>Renewal Charge</Button>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Button fullWidth onClick={() => {
                                if(!form.values.quotationPlan.find(item => item.type === QuotationPlanType.OptionalAddOns)) form.insertListItem('quotationPlan', {...quotationPlan, type: QuotationPlanType.OptionalAddOns})
                                setPlanType(QuotationPlanType.OptionalAddOns)
                                open()
                            }}>Optional Items</Button>
                        </Grid.Col>
                    </Grid>
                {form.values.termsConditions && <RichTextEditorComponent label={'Terms and Conditions'} value={form.values.termsConditions} setValue={(data) => form.setFieldValue('termsConditions', data)}/>}

                <TextInputEditable
                    required
                    withAsterisk={false}
                    label={<Tooltip label={'Consider it as a file name that you can search for in the future'}><Input.Label required>File Name</Input.Label></Tooltip>}
                    placeholder="file Name"
                    {...form.getInputProps('name')}
                    />
                <Button fullWidth mt="xl" type="submit">
                    Submit
                </Button>
                </form>
            </Paper>
            </Container>
            <Modal opened={opened} onClose={close} size="90%">
                <NewQuotationContentForm 
                values={
                    form.values.quotationPlan.filter(item => item.type === planType)[0]
                } 
                setValues={(data, column) => {
                    const index = form.values.quotationPlan.findIndex(item => item.type === planType)
                    form.setFieldValue(`quotationPlan.${index}.${column}`, data)
                }}
                insertContent={(data) =>{
                    const index = form.values.quotationPlan.findIndex(item => item.type === planType)
                    form.insertListItem(`quotationPlan.${index}.quotationContent`, data)
                }} 
                />
         </Modal>
        </Layout>
    );
  }
  