
import Layout from '../../../components/layout/Layout';
import { UpdateUserForm } from '../components/UpdateUserForm';


export function UpdateUserFormPage() {

  return (
    <Layout>
      <UpdateUserForm idM={0}/>
    </Layout>
  );
}
