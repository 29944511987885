import { useEffect, useState } from "react";
import { SelectEditable } from "../../../components/form";
import { URLParams } from "../../../utils";
import { useContacts } from "../../organizations";

export function SelectContacts({
    orgID,
    value,
    setValue
}:{
    orgID:number,
    value:number,
    setValue: (data: number) => void
}){
    const [contactsSelect, setContactsSelect] = useState([
        { value: '', label: '' },
        ]);
        
    const params = new URLParams();
    params.includes('organization')
    params.filterSetField('=organization', orgID)
    params.select('id', 'name', 'phone')
    const { data: contactsData } = useContacts({params: params.toString()});
    useEffect(() => {
        if (contactsData) {
        const tempArr = contactsData.data.items.map((contact) => {
            return { value: `${contact.id}`, label: `${contact.name}, ${contact.phone}` };
        });
        if (tempArr.length === 0) {
            tempArr.push({
            value: '',
            label: 'No Contacts, Please add the contact first',
            });
        }
        setContactsSelect(tempArr);
        }
    }, [contactsData]);

    return(
        <SelectEditable
            label="Contact"
            placeholder="Search here"
            // {...form.getInputProps('contact.id')}
            onChange={(v) => {
            v && setValue(parseInt(v));
            }}
            value={`${value}`}
            searchable
            nothingFound="Not found"
            data={contactsSelect}
            mt='md'
        />
    )
}