import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Iuser } from '../types/types';

export const ALL_USERS = 'all.user';

interface myResUsers {
  data: {
    items: Iuser[];
    meta: metaData,
  };
}

export const getUsers = (query = ''): Promise<myResUsers> => {
  return api.get(`users${query}`);
};

export const useUsers = (options?: { params?: string; then?: (x: myResUsers) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.User, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getUsers(options?.params).then((users) => {
      
      options && options.then && options.then(users);
      return users;
    }), {enabled: options?.enabled ?? true },
    );
    return query;
  };
  interface myResCountUsersNumber {
    data: number;
  }
  
  export const countUsers = (query = ''): Promise<myResCountUsersNumber> => {
    return api.get(`users/count${query}`);
  };
  
  export const useCountUsers = (options?: { params?: string; then?: (x: myResCountUsersNumber) => any }) => {
    const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.User, types.ALL, types.Count];
    if (options && options.params) cacheKey.push(options.params);
    const query = useQuery(cacheKey, () =>
      countUsers(options?.params).then((users) => {
        options && options.then && options.then(users);
        return users;
      }),
    );
    return query;
  };