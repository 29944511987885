import jsPDF, { AcroFormCheckBox, AcroFormTextField } from "jspdf";

interface Text {
    text: string,
    x: number,
    y: number,
    font?: string[],
    fontSize?: number,
    color?: string,
}

interface TextOptions {
    xOffset?: number,
    yOffset?: number,
    font?: string[],
    fontSize?: number,
    color?: string,
    postions?: 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
}

interface TextInput {
    width?: number,
    height?: number,
    value?: string,
    multiLines?: boolean,
    textAlign?: 'center' | 'left'| 'right',
    fontSize?: number,
    color?: string,
}

interface CheckBox {
    width?: number,
    height?: number,
    value?: string,
    appearanceState?: 'On' | 'Off',
    textAlign?: 'center' | 'left'| 'right',
    fontSize?: number,
    color?: string,
}

interface OriginalOptions {
    fontSize:number,
    color:string,
    borderColor: string,
    font: string[],
}

interface Square {
    x1: number,
    x2: number,
    y1: number,
    y2: number,
    style?: 'S' | 'F' | 'FD',
    bgc?: string, // backGround Color
    borderColor?: string, // line color Color
}

interface Table {
    x1:number,
    x2: number,
    y1: number,
    y2: number,
    rows: number,
    columns: number,
    cellsWidth: number,
    cellsHeight: number,
    values?: CellType[] | CellType
    cellHeaders?: string[],
    cellOptions?: Cell[],
}


interface Cell {
    rowNo: number,
    columnNo: number,
    removeBorder?: ('left'| 'right'| 'top' | 'bottom')[]
    devideCell?: {position: 'horizontal' | 'vertical', quantity?: number},
    // cellType?: CellType,
}
interface CellType {
    type: string,
    value?: string,
    default?: boolean,
    postion?: 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
}

enum enumCellType {
    CheckBox = 'checkbox',
    TextBox = 'textbox',
    Text = 'text',
    Empty = 'empty'
}

export function displayText(doc: jsPDF, label: Text, textOptions?: {maxWidth?: number, align?: "center" | "left" | "right" | "justify", direct?: true} ){
    if(textOptions?.direct){
        const origin: OriginalOptions = {
            fontSize: doc.getFontSize(),
            color: doc.getTextColor(),
            borderColor: doc.getDrawColor(),
            font: [doc.getFont().fontName, doc.getFont().fontStyle],
        }
        if(label.fontSize) doc.setFontSize(label.fontSize);
        if(label.font) doc.setFont(label.font[0], label.font[1] ? label.font[1] : undefined, label.font[2] ? label.font[2] : undefined);
        if(label.color) doc.setTextColor(label.color)
        doc.text(label.text, label.x, label.y, textOptions)
    Original(doc, origin)
    }else{
        if(label.fontSize) doc.setFontSize(label.fontSize);
        if(label.font) doc.setFont(label.font[0], label.font[1] ? label.font[1] : undefined, label.font[2] ? label.font[2] : undefined);
        if(label.color) doc.setTextColor(label.color)
        doc.text(label.text, label.x, label.y, textOptions)
    }
}
function Original(doc:jsPDF, options: OriginalOptions){
    doc.setFont(options.font[0], options.font[1]);
    doc.setFontSize(options.fontSize);
    doc.setTextColor(options.color);
    doc.setDrawColor(options.borderColor);
}

export function square(doc:jsPDF, options: Square, text?: string, textOptions? : TextOptions){
    const origin: OriginalOptions = {
        fontSize: doc.getFontSize(),
        color: doc.getTextColor(),
        borderColor: doc.getDrawColor(),
        font: [doc.getFont().fontName, doc.getFont().fontStyle],
    }
    if(options.bgc) doc.setFillColor(options.bgc)
    if(options.borderColor) doc.setDrawColor(options.borderColor)
    doc.lines([[options.x2 - options.x1, 0], [0, options.y2 - options.y1], [options.x1 - options.x2, 0],[0, options.y1 - options.y2]], options.x1, options.y1,undefined,options.style)
    if(text){
        const xPosition = textOptions?.postions === 'right' || textOptions?.postions === 'bottomRight' || textOptions?.postions === 'topRight' ? options.x2 - 5 - text.length*1.5 : textOptions?.postions === 'left' || textOptions?.postions === 'topLeft' || textOptions?.postions === 'bottomLeft' ? options.x1 + 5 : ((options.x2 + options.x1)/2 - text.length/1.5)
        const yPosition = textOptions?.postions === 'bottom' || textOptions?.postions === 'bottomRight' || textOptions?.postions === 'bottomLeft' ? options.y2 - 5 : textOptions?.postions === 'top' || textOptions?.postions === 'topLeft' || textOptions?.postions === 'topRight' ? options.y1 + 5 : ((options.y2 + options.y1)/2 + 1)
        displayText(doc, {
            text: text, 
            x: xPosition + (textOptions?.xOffset ? textOptions.xOffset : 0),
            y: yPosition + (textOptions?.yOffset ? textOptions.yOffset : 0),
            font: textOptions?.font,
            fontSize: textOptions?.fontSize,
            color: textOptions?.color
        })
    }
    Original(doc, origin)
}

export function textField(doc:jsPDF,fieldName: string, x: number, y: number, options?: TextInput, label?: string, labelOptions? : TextOptions){
    const origin: OriginalOptions = {
        fontSize: doc.getFontSize(),
        color: doc.getTextColor(),
        borderColor: doc.getDrawColor(),
        font: [doc.getFont().fontName, doc.getFont().fontStyle],
    }
    const input = new AcroFormTextField();
    input.fieldName = fieldName;
    input.x = x
    input.y = y
    if(options){
        if(options.value) input.value = options.value
        if(options.width) input.width = options.width
        if(options.height) input.height = options.height
        if(options.fontSize) input.fontSize = options.fontSize
        if(options.textAlign) input.textAlign = options.textAlign
        if(options.multiLines) input.multiline = options.multiLines
    }
    if(label){
        const xPosition = labelOptions ? labelOptions.postions ? ['bottomRight', 'topRight'].includes(labelOptions.postions) ? x + input.width -label.length/1.5 : labelOptions.postions === 'right' ? x + input.width + 5 : labelOptions.postions === 'left' ? x  - label.length*2 : ['bottomLeft', 'topLeft'].includes(labelOptions.postions) ? x : x + input.width/2 - label.length/1.5 : x  - label.length*2 : x  - label.length*2
        const yPosition = labelOptions && labelOptions.postions ? ['bottom', 'bottomRight', 'bottomLeft'].includes(labelOptions.postions) ? y + input.height + 5 : labelOptions && ['top', 'topRight', 'topLeft'].includes(labelOptions.postions) ? y - 5 : y + input.height/2 + 1 : y + input.height/2 + 1 
        displayText(doc, {
            text: label,
            x: xPosition + (labelOptions && labelOptions.xOffset ? labelOptions.xOffset : 0),
            y: yPosition + (labelOptions && labelOptions.yOffset ? labelOptions.yOffset : 0),
            fontSize: labelOptions?.fontSize,
            color: labelOptions?.color
        })
    }
    doc.addField(input);
    Original(doc, origin)
}

export function checkBoxField(doc: jsPDF, fieldName: string, x: number, y: number, options?: CheckBox, label?: string, labelOptions?: TextOptions & {postions?: 'left' | 'right' | 'top' | 'bottom'}){
    const origin: OriginalOptions = {
        fontSize: doc.getFontSize(),
        color: doc.getTextColor(),
        borderColor: doc.getDrawColor(),
        font: [doc.getFont().fontName, doc.getFont().fontStyle],
    }

    const checkBox = new AcroFormCheckBox();
    checkBox.x = x
    checkBox.y = y
    if(options){
        if(options.value) checkBox.value = options.value
        if(options.width) checkBox.width = options.width
        if(options.height) checkBox.height = options.height
        if(options.fontSize) checkBox.fontSize = options.fontSize
        if(options.textAlign) checkBox.textAlign = options.textAlign
        if(options.appearanceState) checkBox.appearanceState = options.appearanceState
        if(options.color) checkBox.color = options.color
    }
    checkBox.fieldName = fieldName;
    if(label){
        const xPosition = labelOptions && labelOptions.postions ? labelOptions.postions === 'right' ? x + checkBox.width + 5 : ['bottom', 'top'].includes(labelOptions.postions) ? x + checkBox.width - label.length/1.5 : x  - label.length*2 : x  - label.length*2
        const yPosition = labelOptions && labelOptions.postions ? labelOptions.postions === 'bottom' ? y + checkBox.height + 5 : labelOptions.postions === 'top' ? y - 5 : y + checkBox.height/2 + 1 : y + checkBox.height/2 + 1
        displayText(doc, {
            text: label,
            x: xPosition + (labelOptions && labelOptions.xOffset ? labelOptions.xOffset : 0),
            y: yPosition + (labelOptions && labelOptions.yOffset ? labelOptions.yOffset : 0),
            fontSize: labelOptions?.fontSize,
            color: labelOptions?.color
        })
    }
    doc.addField(checkBox);

    Original(doc, origin)
}

// function cellValues(doc: jsPDF, x: number, y: number, type: string, value? : string){
//     if(type === enumCellType.CheckBox){
//         checkBoxField(doc, `${type}5`,x,y)
//     }else if(type === enumCellType.Text && value){
//         displayText(doc, {text: value, x: x, y: y})
//     }
// }

export function table(doc: jsPDF, x: number, y: number, rows: number, columns: number, cellsWidth: number, cellsHeight: number, cellOptions?: Cell[]){
    const oppositeSide: Cell[] = []
    if(cellOptions){
        Object.values(cellOptions).forEach(cell => {

            if(cell.removeBorder?.includes('top')){
                const alreadyExistCell = oppositeSide.find(item => {
                    if(item.columnNo === cell.columnNo && item.rowNo === cell.rowNo - 1){
                        item.removeBorder = item.removeBorder ? [...item.removeBorder, 'bottom'] : undefined
                        return true
                    }
                    return false
                    })
                    if(!alreadyExistCell) oppositeSide.push({...cell, rowNo: cell.rowNo - 1, removeBorder: ['bottom']})
            }
            
            if(cell.removeBorder?.includes('right')){
                const alreadyExistCell = oppositeSide.find(item => {
                    if(item.columnNo === cell.columnNo + 1 && item.rowNo === cell.rowNo){
                        item.removeBorder = item.removeBorder ? [...item.removeBorder, 'left'] : undefined
                        return true
                    }
                    return false
                })
                if(!alreadyExistCell) oppositeSide.push({...cell, columnNo: cell.columnNo + 1, removeBorder: ['left']})
            } 
            
            if(cell.removeBorder?.includes('bottom')){
                const alreadyExistCell = oppositeSide.find(item => {
                    if(item.columnNo === cell.columnNo && item.rowNo === cell.rowNo + 1){
                        item.removeBorder = item.removeBorder ? [...item.removeBorder, 'top'] : undefined
                        return true
                    }
                    return false
                })
                if(!alreadyExistCell) oppositeSide.push({...cell, rowNo: cell.rowNo + 1, removeBorder: ['top']})
            }
            
            if(cell.removeBorder?.includes('left')){
                const alreadyExistCell = oppositeSide.find(item => {
                    if(item.columnNo === cell.columnNo - 1 && item.rowNo === cell.rowNo){
                        item.removeBorder = item.removeBorder ? [...item.removeBorder, 'right'] : undefined
                        return true
                    }
                    return false
                })
                if(!alreadyExistCell) oppositeSide.push({...cell, columnNo: cell.columnNo - 1, removeBorder: ['right']})
            }
            
            const alreadyExistCell = oppositeSide.find(item => item.columnNo === cell.columnNo && item.rowNo === cell.rowNo)
            !alreadyExistCell && oppositeSide.push(cell)
        })
    }
    
    Array(rows).fill('').forEach((item, column) => {
        let counter = 0
        Array(columns).fill('').forEach((item, row) => {
            const removed = oppositeSide.find(item => item.columnNo - 1 === row && item.rowNo - 1 === column )?.removeBorder
            const devided = oppositeSide.find(item => item.columnNo - 1 === row && item.rowNo - 1 === column )?.devideCell
            // const cellType = cellOptions?.find(item => item.columnNo - 1 === row && item.rowNo - 1 === column )?.cellType

            const x1 = x + ( row * cellsWidth )
            const x2 = x + cellsWidth + (row * cellsWidth)
            const y1 = y + (column * cellsHeight)
            const y2 = y + cellsHeight + (column * cellsHeight)
            !removed?.includes('top') && doc.line(x1, y1, x2, y1, 'S')
            !removed?.includes('right') && doc.line(x2, y1, x2, y2, 'S')
            !removed?.includes('bottom') && doc.line(x1, y2, x2, y2, 'S')
            !removed?.includes('left') && doc.line(x1, y1, x1, y2, 'S')
            if(devided){
                for (let lines = 0; lines < (devided.quantity ? devided.quantity : 1); lines++) {
                    let ydiff = y1 + (cellsHeight/2) 
                    let xdiff = x1 + (cellsWidth/2)
                    if(devided.quantity){
                        ydiff = y1 + (cellsHeight/(devided.quantity + 1)) * (lines + 1) 
                        xdiff = x1 + (cellsWidth/(devided.quantity + 1)) * (lines + 1) 
                    }
                    
                    devided.position === 'horizontal' && doc.line(x1, ydiff , x2, ydiff)
                    devided.position === 'vertical' && doc.line(xdiff, y1, xdiff, y2)
                    
                }
            }
            // else if(cellType){
            //     const valuePositionX = cellType.postion === 'right' || cellType.postion === 'bottomRight' || cellType.postion === 'topRight' ? x1 - 5 - ((cellType.value ? cellType.value.length : 0) *1.5) : cellType.postion === 'left' || cellType.postion === 'topLeft' || cellType.postion === 'bottomLeft' ? x1 + 5 : (x2 + x1)/2 - ((cellType.value ? cellType.value.length : 0)*1.5)
            //     const valuePositionY = cellType.postion === 'bottom' || cellType.postion === 'bottomRight' || cellType.postion === 'bottomLeft' ? y2 - 5 : cellType.postion === 'top' || cellType.postion === 'topLeft' || cellType.postion === 'topRight' ? y1 + 5 : ((y2 + y1)/2 + 1)
            //     cellValues(doc, valuePositionX, valuePositionY, cellType.type, cellType.value)
                
            // }
            // else if(defaultValue){
            //     const valuePositionX = defaultValue.postion === 'right' || defaultValue.postion === 'bottomRight' || defaultValue.postion === 'topRight' ? x1 - 5 - ((defaultValue.value ? defaultValue.value.length : 0) *1.5) : defaultValue.postion === 'left' || defaultValue.postion === 'topLeft' || defaultValue.postion === 'bottomLeft' ? x1 + 5 : ((x2 + x1)/2 - ((defaultValue.value ? defaultValue.value.length : 0)/1.5))
            //     const valuePositionY = defaultValue.postion === 'bottom' || defaultValue.postion === 'bottomRight' || defaultValue.postion === 'bottomLeft' ? y2 - 5 : defaultValue.postion === 'top' || defaultValue.postion === 'topLeft' || defaultValue.postion === 'topRight' ? y1 + 5 : ((y2 + y1)/2 + 1)
            //     cellValues(doc, valuePositionX, valuePositionY, defaultValue.type, defaultValue.value)
            // }
            counter++
        })
    })
}

