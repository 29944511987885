import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { Iinvoice } from "../types/types";

interface myResInvoice {
	data: Iinvoice;
}

export const getInvoice = (id: number, joins?: string[], selects?: string[]): Promise<myResInvoice> => {
	let params = '';
  if (joins) params = '?joins='.concat(joins.join());
  if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`invoices/${id}${params}`);
};

export const useInvoice = (id: number, options?: { joins?: string[]; selects?: string[] ;then?: (x: myResInvoice) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Invoice, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
  const query = useQuery(
    cacheKey,
    () =>
	getInvoice(id, options?.joins, options?.selects).then((invoice) => {
        options && options.then && options.then(invoice);
        return invoice;
      }),
    { enabled: id !== 0 },
  );
  return query;
};
