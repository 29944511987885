import jsPDF from 'jspdf';
import { useCallback } from 'react';
import logo from '../assets/ats_asateel_logo.jpeg';
import { Icertificate } from '../modules/asateelCertificate';
import { Ivehicle } from '../modules/vehicles';

export function useAsateelActions() {
  const tetsDoc = new jsPDF();
  type docType = typeof tetsDoc;

  function parseISOandFormat(s: Date) {
    const b = new Date(s);
    const date = new Date(Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes(), b.getSeconds()));
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    return { year, month, day };
  }

  function plateFormater(simple: string, vehicle: Ivehicle) {
    let plate = '';
    if (vehicle.placeOfIssue) {
      plate = vehicle.placeOfIssue;
    }
    if (vehicle.plateNumber) {
      plate = plate ? plate + `${simple}${vehicle.plateNumber}` : `${vehicle.plateNumber}`;
    }
    if (vehicle.category) {
      plate = plate ? plate + `${simple}${vehicle.category}` : `${vehicle.category}`;
    }
    return plate;
  }
  function dateFormater(day: string, month: string, year: string) {
    return `${day} ${month} ${year}`;
  }

  const createPdf = useCallback((data: Icertificate) => {
    const doc: docType = new jsPDF({ orientation: 'landscape' });
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    doc.addImage(logo, 'JPEG', 0, 0, width, height);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#979797');
    doc.setFontSize(33);
    doc.setCharSpace(1);
    const cer = 'Certificate of Installation';
    doc.text(cer, width / 2 - cer.length / 2, 60, { align: 'center' });
    doc.setFont('helvetica', 'italic');
    doc.setTextColor('#000000');
    doc.setFontSize(25);
    const line1 = 'This is to certify that the following';
    doc.text(line1, width / 2 - line1.length / 2, 84, { align: 'center' });
    const line2 = 'vehicle has been successfully registered';
    doc.text(line2, width / 2 - line2.length / 2, 96, { align: 'center' });
    const line3 = 'under the Asateel services';
    doc.text(line3, width / 2 - line3.length / 2, 108, { align: 'center' });

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    doc.setCharSpace(0);
    doc.text(`Operator Name: ${data.vehicle?.organization.name} ${data.withParent && data.vehicle.organization?.parent?.name ? ` - ${data.vehicle.organization?.parent?.name}` : ''}`, width / 2, 122, {
      align: 'center',
    });

    if(data.withPlate) doc.text(`Vehicle Plate Number: ${plateFormater('-', data.vehicle)}`, width / 2, 130, {align: 'center'});
    doc.text('Vehicle Chassis:' + data.vehicle.vin, width / 2, 138, {
      align: 'center',
    });
    if (data.vehicle.licenseIssueDate && data.vehicle.licenseExpiryDate && data.withDate) {
      const VLI = parseISOandFormat(data.vehicle.licenseIssueDate);
      doc.text(`Vehicle License Issue Date: ${dateFormater(VLI.day, VLI.month, VLI.year)}`, width / 2, 146, { align: 'center' });
      const VLE = parseISOandFormat(data.vehicle.licenseExpiryDate);
      doc.text(`Vehicle License Expiry Date: ${dateFormater(VLE.day, VLE.month, VLE.year)}`, width / 2, 154, { align: 'center' });
    }

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(14);
    doc.setCharSpace(-0.2);

    if(data.withDevice){
      doc.text(`Device: ${data.device?.deviceType?.type ?? ''}`, 28, 176);
      doc.text(`IMEI: ${data.device?.imei ?? ''}`, 28, 182);
    }
    if(data.withSimCard) doc.text(`Sim #: ${data.simCard?.number ?? ''}`, 28, 188);

    doc.text(`Certificate ID: ${data.certificateID}`, 185, 176);
    const activationDate = parseISOandFormat(data.activationDate);
    doc.text(`Service Activation: ${activationDate.day} ${activationDate.month} ${activationDate.year}`, 185, 182);
    const expiryDate = parseISOandFormat(data.expiryDate);
    doc.text(`Service Expiry: ${expiryDate.day} ${expiryDate.month} ${expiryDate.year}`, 185, 188);

    return doc;
  }, []);

  const openPdfNewTab = useCallback((data: Icertificate) => {
    const finalDoc = createPdf(data);
    window.open(finalDoc.output('bloburl'));
  }, [createPdf]);

  const downloadPdf = useCallback((data: Icertificate) => {
    const finalDoc = createPdf(data);
    finalDoc.save(`${data.vehicle.vin}_${plateFormater('_', data.vehicle)}_${data.vehicle.organization.name}`);
  }, [createPdf]);

  return { downloadPdf, openPdfNewTab };
}
