import api from "../../common/api/api";
import { ipreSets, ipreSetsInput } from "../types/types";

interface myResPreSet {
	data: ipreSets;
}

export const postPreSet = (data: ipreSetsInput): Promise<myResPreSet> => {
	return api.post(`presets`, data);
};

