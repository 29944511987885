import { Card, createStyles, Grid, Group, Indicator, RingProgress, Text, Tooltip } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  inner: {
    display: 'flex',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  ring: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-around',

    [theme.fn.smallerThan('xs')]: {
      justifyContent: 'center',
      marginTop: theme.spacing.md,
    },
  },
}));

interface StatsRingCardProps {
  title: string;
  totalPDs:number,
  billedActivePD:number,
  billedNotActivePD:number,
  notBilledActivePD:number,
  notes: boolean
}

export function OrgUnitsBillsSummary({ title, totalPDs,billedActivePD,billedNotActivePD,notBilledActivePD, notes }: StatsRingCardProps,) {
    const { classes, theme } = useStyles();
    

  return (
    <Card withBorder p='sm' radius="md" className={classes.card} >
      <Grid className={classes.inner}>
        <Grid.Col span={7}>
          
          <Text fz="xl" className={classes.label} mt='sm'>{title}</Text>
          
          <Grid>
            <Grid.Col span={6}>
              <Text className={classes.lead} mt='md'>
              <Group>
                <Tooltip label="Scroll to the notes to read more details" disabled={!notes}>
                  <Indicator size={8} color="red" offset={-5} disabled={!notes}>
                    {totalPDs}
                  </Indicator>
                </Tooltip>
              </Group>
              </Text>
              <Text fz="xs" color="dimmed">units</Text>
            </Grid.Col>
            <Grid.Col span={6} mt='md'>
                <Text className={classes.label}>{billedActivePD}</Text>
                <Text size="xs" color="dimmed">billed active</Text>
            </Grid.Col>

            <Grid.Col span={6} mt='xs'>
                <Text className={classes.label}>{notBilledActivePD}</Text>
                <Text size="xs" color="dimmed">not billed active</Text>
            </Grid.Col>
            
            <Grid.Col span={6} mt='xs'>
                <Text className={classes.label}>{billedNotActivePD}</Text>
                <Text size="xs" color="dimmed">billed not active</Text>
            </Grid.Col>

          </Grid>
        </Grid.Col>
        <Grid.Col span={5} className={classes.ring}>
        <RingProgress
              roundCaps
              thickness={6}
              size={130}
              sections={[{ value: !isNaN(((billedActivePD+billedNotActivePD) / (totalPDs)) * 100) ?
              ((billedActivePD+billedNotActivePD) / (totalPDs)) * 100 : 0, color: theme.primaryColor }]}
              label={
                <div>
                  <Text ta="center" fz="lg" className={classes.label}>
                    {!isNaN(((billedActivePD+billedNotActivePD) / (totalPDs)) * 100) ?
                    (((billedActivePD+billedNotActivePD) / (totalPDs)) * 100).toFixed(0) : 0}%
                  </Text>
                  <Text ta="center" fz="xs" c="dimmed">
                    billed units
                  </Text>
                </div>
              }
            />
        </Grid.Col>
      </Grid>

    </Card>
  );
}