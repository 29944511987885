import api from '../../common/api/api';
import { TaskGroup, TaskGroupInput } from '../types/types';

export interface myResTaskGroupPost {
  data: TaskGroup;
}

export const postTaskGroup = (data: Partial<TaskGroupInput>): Promise<myResTaskGroupPost> => {
  return api.post(`task-groups`, data);
};

export const postTaskGroups = (data: Record<string, any>[]): Promise<myResTaskGroupPost[]> => {
  return api.post(`task-groups/multi`, data);
};
