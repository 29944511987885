import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { TaskGroup } from '../types/types';

interface myResTaskGroups {
  data: {
    items: TaskGroup[];
    meta: metaData;
  };
}

export const getTaskGroups = (query = ''): Promise<myResTaskGroups> => {
  return api.get(`task-groups${query}`);
};

export const useTaskGroups = (options?: { params?: string; then?: (x: myResTaskGroups) => any; enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.TaskGroup, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      getTaskGroups(options?.params).then((tasks) => {
        options && options.then && options.then(tasks);
        return tasks;
      }),
    { enabled: options?.enabled ?? true },
  );
  return query;
};
interface myResCountTaskGroupsNumber {
  data: number;
}

export const countTaskGroups = (query = ''): Promise<myResCountTaskGroupsNumber> => {
  return api.get(`task-groups/count${query}`);
};

export const useCountTaskGroups = (options?: { params?: string; then?: (x: myResCountTaskGroupsNumber) => any; enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.TaskGroup, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      countTaskGroups(options?.params).then((tasks) => {
        options && options.then && options.then(tasks);
        return tasks;
      }),
    { enabled: options?.enabled ?? true },
  );
  return query;
};

interface myResCountTaskGroups {
  data: Record<string, string | number>[];
}

export const analyticsTaskGroups = (query = ''): Promise<myResCountTaskGroups> => {
  return api.get(`tasks/analytics${query}`);
};

export const useAnalyticsTaskGroups = (options?: { params?: string; then?: (x: myResCountTaskGroups) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.TaskGroup, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      analyticsTaskGroups(options?.params).then((counts) => {
        options && options.then && options.then(counts);
        return counts;
      }),
    // { staleTime: 1000 * 30 },
  );
  return query;
};
