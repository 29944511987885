import {
  Alert,
  Button,
  Container,
  List,
  LoadingOverlay,
  Paper,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { IaxiosError, UploadButton, useUploadImage } from '../../common';
import { postSimCardType } from '../api';
import { IsimCardTypeInput } from '../types/types';

export function NewSimCardTypeForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { upload, progress } = useUploadImage();

  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      supplier: '',
      type: '',
    },
  });

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postSimCardType, {
    onSuccess: (data) => {
      setAlert(null);
      const imageRepositoryId = data.data.imageRepositoryId;
      if (imageRepositoryId && file) {
        upload(
          imageRepositoryId,
          file,
          `${data.data.supplier}_${data.data.type}`,
          types.SimCareType,
          types.SimCareType,
          data.data.id
        );
      }
      form.reset();
      queryClient.invalidateQueries([types.SimCareType, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IsimCardTypeInput) {
    setAlert(null);
    newMutate(values);
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Sim Card Type:
        </Title>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              label="Supplier"
              placeholder="Supplier name"
              {...form.getInputProps('supplier')}
              required
            />
            <TextInputEditable
              label="Type"
              placeholder="Sim card Type"
              {...form.getInputProps('type')}
              required
              mt="md"
            />
            <UploadButton file={file} onChange={setFile} progress={progress}>
              {(props) => (
                <Button {...props} mt="md">
                  Select sim card image
                </Button>
              )}
            </UploadButton>
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
}
