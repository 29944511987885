import { Grid, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { FaExpandArrowsAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { ActionButtonsCard, DetailsCard } from '../../../components/detailsCards';
import { DisplayCollection } from '../../../components/images/DisplayCollection';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { HistoryCard } from '../../history';
import { ExportOrgUnits, OrgVehiclesCard, OrganizationCard } from '../components';

const Height = 400;

export function OrganizationDetails() {
  // const { user } = useAuthContext();
  const { id } = useParams();
  const [image, setImage] = useState<number | null>(null);
  const [opened, { open, close }] = useDisclosure(false);

  let idN = 0;
  if (typeof id === 'string') {
    idN = parseInt(id);
  }

  const customActions = [
    { id: 'reports', multi: false, title: 'Reports', icon: FaExpandArrowsAlt, color: 'pink', handler: open, noDisplay: [] },
  ];
  return (
    <Layout>
      <Grid>
        <Grid.Col lg={7} md={12}>
          <DetailsCard cardHei={Height} id={idN} image={image} withImage>
            <OrganizationCard setImage={setImage} id={idN} />
          </DetailsCard>
        </Grid.Col>
        <Grid.Col lg={5} md={12}>
          <ActionButtonsCard id={[idN]} type={types.Organization} customActions={customActions} />
        </Grid.Col>
        {/* { (user?.role === UserRole.Finance || user?.role === UserRole.Master) &&   <Grid.Col lg={12} md={12}><OrgUnitsAnalysis id={idN} cardHei={Height} /></Grid.Col>} */}
        <Grid.Col lg={7} md={12}>
          <OrgVehiclesCard id={idN} cardHei={Height} />
        </Grid.Col>
        <Grid.Col lg={5} md={12}>
          <HistoryCard historySearch={[{ id: idN, type: types.Organization }]} cardHei={Height} />
        </Grid.Col>
        <Grid.Col lg={7} md={12}>
          <DisplayCollection cardHei={Height} image={image} />
        </Grid.Col>
      </Grid>
      <Modal opened={opened} onClose={close} title="Units">
        <ExportOrgUnits id={idN} />
      </Modal>
    </Layout>
  );
}
