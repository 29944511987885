import api from "../../common/api/api";
import { Contacts, ContactsInput } from "../types/types";

interface myResContacts {
	data: Contacts;
}

export const postContacts = (data: Partial<ContactsInput>): Promise<myResContacts> => {
	return api.post(`contacts`, data);
};

