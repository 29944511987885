import api from "../../common/api/api";
import { Iquotation, updateQuotation } from "../types/types";

interface myResQuotation {
	data: Iquotation;
}

export const patchQuotation = (props: { id: number; data: Partial<updateQuotation> }): Promise<myResQuotation> => {
	return api.patch(`quotation/${props.id}`, props.data);
};
