export function duplicatesSourceFinder<T extends Record<string, any>>(
	dataSource: T[],
	keySource: keyof T,
) {
	const unique: T[typeof keySource][] = [];
	const duplicates: T[typeof keySource][] = [];
	dataSource.forEach((itemTarget) => {
			if (!unique.includes(itemTarget[keySource])) {
				unique.push(itemTarget[keySource]);
			}else duplicates.push(itemTarget[keySource]);
	});
	return {unique, duplicates};
}