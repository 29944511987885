import { createSafeContext } from './create-safe-context';

interface ResizableContext {
  sideSize: { x: number; y: number };
  vertical: boolean;
  // setHideFirst: React.Dispatch<React.SetStateAction<boolean>>;
  // setHideSecond: React.Dispatch<React.SetStateAction<boolean>>;
  hideFirst: boolean;
  hideSecond: boolean;
}

export const [ResizableContextProvider, useResizableContext] = createSafeContext<ResizableContext>(
  'Resizable component was not found in the tree',
);
