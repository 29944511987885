import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IAccessoriesType } from '../types/types';


interface myResAccessoriesType {
  data: {
    items: IAccessoriesType[];
    meta: metaData,
  };
}

export const getAccessoriesType = (query = ''): Promise<myResAccessoriesType> => {
  return api.get(`accessories-type${query}`);
};

export const useAccessoriesType = (options?: { params?: string; then?: (x: myResAccessoriesType) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.AccessoriesType, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getAccessoriesType(options?.params).then((accessoriesType) => {
      options && options.then && options.then(accessoriesType);
      return accessoriesType;
    }),
  );
  return query;
};

interface myResCountAccessoryTypesNumber {
  data: number;
}

export const countAccessoryTypes = (query = ''): Promise<myResCountAccessoryTypesNumber> => {
  return api.get(`accessories-type/count${query}`);
};

export const useCountAccessoryTypes = (options?: { params?: string; then?: (x: myResCountAccessoryTypesNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.AccessoriesType, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countAccessoryTypes(options?.params).then((accessoryTypes) => {
      options && options.then && options.then(accessoryTypes);
      return accessoryTypes;
    }),
  );
  return query;
};

