import { Navigate, Route, Routes } from 'react-router-dom';
import { AttendanceListWidget } from '../components/widgets';
import { useAuthContext } from '../context/AuthContext';
import { UserSettings } from '../modules/Settings/pages/UserSettings';
import { AccessoryTable, NewAccessoryFormPage, UpdateAccessoryFormPage } from '../modules/accessories';
import { AccessoryTypeTable, NewAccessoryTypeFormPage, UpdateAccessoryTypeFormPage } from '../modules/accessoriesType';
import { AddMultipleAsateelCertificates, AsateelCertificateTable, EditMultipleAsateelCertificates, NewAsateelCertificateForm } from '../modules/asateelCertificate';
import { AttendanceTable, MonthlyReportForm, UpdateAttendanceForm } from '../modules/attendance';
import { MyAttendance } from '../modules/attendance/pages/AttendenceCard';
import { ForgetPassword, Login, Signup } from '../modules/common';
import { ResetPassword } from '../modules/common/auth/pages/ResetPassword';
import { DeviceTypeCards, DeviceTypeDetails, NewDeviceTypeFormPage, UpdateDeviceTypeFormPage } from '../modules/device-types';
import { AddMultipleDevices, DeviceCards, DeviceDetails, DeviceTable, EditMultipleDevicesCSV, IssueDevices, NewDeviceFormPage, UpdateDeviceFormPage } from '../modules/devices';
import { NewOrderFormPage, OrderTable, UpdateOrderFormPage } from '../modules/finance';
import { InvoiceTable } from '../modules/invoices';
import { AddMultipleOrganizations, EditMultipleOrganizationsCSV, NewOrganizationFormPage, OrganizationCards, OrganizationDetails, OrganizationTable, UpdateOrganizationFormPage } from '../modules/organizations';
import { ActiveOrganizationCards, InActiveOrganizationCards, LostOrganizationCards, PotentialOrganizationCards } from '../modules/organizations/pages/organizationCardsStatusBased';
import { Profile } from '../modules/profiles';
import { NewQuotationForm, NewQuotationTemplete, QuotationTable, TempleteTable } from '../modules/quotations';
import { UpdateQuotationForm } from '../modules/quotations/forms/UpdateQuotationForm';
import { UpdateQuotationTemplete } from '../modules/quotations/forms/updateQuotationTemplete';
import { RecordTable } from '../modules/records';
import { RfidListCards, RfidListForm } from '../modules/rfid-lists';
import { NewRfidFormPage, RfidTable, UpdateRfidFormPage } from '../modules/rfids';
import { NewSimCardTypeFormPage, SimCardTypeCards, SimCardTypeDetails, UpdateSimCardTypeFormPage } from '../modules/sim-card-types';
import { AddMultipleSimCards, EditMultipleSimCardsCSV, NewSimCardFormPage, SimCardCards, SimCardDetails, SimCardTable, UpdateSimCardFormPage } from '../modules/sim-cards';
import { NewTaskGroupForm, UpdateTaskGroupForm } from '../modules/task-groups';
import { TaskGroupTable } from '../modules/task-groups/pages';
import { AccessoryInstallationTaskForm, AddMultipleTasks, CompletedTasksTable, DeviceChangeApprovalForm, DeviceChangeTaskForm, EditMultipleTasksCSV, InspectionTaskForm, InstallationTaskForm, MyTasks, NewTaskForm, PendingTasksTable, ReinstallationTaskForm, RemovalTaskForm, SimChangeTaskForm, TBApprovedTasksTable, TaskAccessoryInstallationApproval, TaskInspectionApprovalForm, TaskInstallationApprovalForm, TaskRemovalApprovalForm, TaskSimCardChangeApprovalFrom, TaskTable, UpdateTaskForm } from '../modules/tasks';
import { TaskReinstallationApproval } from '../modules/tasks/forms/approvalForms/TaskReinstallationApproval';
import { NewSupportTicket, NewTicket, SupportTicketTable, TicketDetails, TicketNotification, TicketTable } from '../modules/tickets';
import { AddMultipleUnits, EditMultipleUnitsCSV, ExportActiveUnits, NewUnitFormPage, UnitCards, UnitDetails, UnitsTable, UpdateUnitFormPage } from '../modules/unit';
import { NewUserFormPage, UpdateUserFormPage, UserCards, UserDetails, UserTable } from '../modules/users';
import { AddMultipleVehicleTypes, NewVehicleTypeFormPage, UpdateVehicleTypeFormPage, VehicleTypeDetails, VehicleTypeTable, VehicleTypesCards } from '../modules/vehicle-types';
import { AddMultipleVehicles, EditMultipleVehiclesCSV, NewVehicleFormPage, UpdateVehicleFormPage, VehicleCards, VehicleDetails, VehicleTable } from '../modules/vehicles';
import { TechnicianTransferDevice } from '../views/technician';
import { Dashboard } from './Dashboard';
import { LiveMap } from './LiveMap';
import { NonReporting } from './NonReporting';
import { NotFound } from './NotFound';
import { Services } from './Services';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { isLoggedIn, refresh } = useAuthContext();
  if (!isLoggedIn) {
    const { isSuccess, isLoading } = refresh;
    if (isLoading) return null;
    if (isSuccess) return <>{children}</>;
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
}

export const publicRoutes = ["/login", "/signup", "/forgotPassword", "/reset-password"];

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgotPassword" element={<ForgetPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/services" element={<ProtectedRoute><Services /></ProtectedRoute>} />
      <Route path="/live" element={<ProtectedRoute><LiveMap /></ProtectedRoute>} />
      <Route path="/non-reporting" element={<ProtectedRoute><NonReporting /></ProtectedRoute>} />
      <Route path="/attendance-report" element={<ProtectedRoute><MonthlyReportForm /></ProtectedRoute>} />
      <Route path="/todays-attendance" element={<ProtectedRoute><AttendanceListWidget /></ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/userAttendance" element={<ProtectedRoute><MyAttendance /></ProtectedRoute>} />
      <Route path="/issue-devices" element={<ProtectedRoute><IssueDevices /></ProtectedRoute>} />
      <Route path="/active-units" element={<ProtectedRoute><ExportActiveUnits /></ProtectedRoute>} />
      <Route path="/settings" element={<ProtectedRoute><UserSettings /></ProtectedRoute>} />
      <Route path="/reports/devices" element={<ProtectedRoute><DeviceTable /></ProtectedRoute>} />
      <Route path="/reports/sim-cards" element={<ProtectedRoute><SimCardTable /></ProtectedRoute>} />
      <Route path="/reports/vehicles" element={<ProtectedRoute><VehicleTable /></ProtectedRoute>} />
      <Route path="/reports/vehicleTypes" element={<ProtectedRoute><VehicleTypeTable /></ProtectedRoute>} />
      <Route path="/reports/units" element={<ProtectedRoute><UnitsTable /></ProtectedRoute>} />
      <Route path="/reports/certificates" element={<ProtectedRoute><AsateelCertificateTable /></ProtectedRoute>} />
      <Route path="/reports/records" element={<ProtectedRoute><RecordTable /></ProtectedRoute>} />
      <Route path="/reports/organizations" element={<ProtectedRoute><OrganizationTable /></ProtectedRoute>} />
      <Route path="/reports/users" element={<ProtectedRoute><UserTable /></ProtectedRoute>} />
      <Route path="/reports/attendance" element={<ProtectedRoute><AttendanceTable /></ProtectedRoute>} />
      <Route path="/finance/orders" element={<ProtectedRoute><OrderTable /></ProtectedRoute>} />
      <Route path="/finance/new/order" element={<ProtectedRoute><NewOrderFormPage /></ProtectedRoute>} />
      <Route path="/finance/edit/order/:id" element={<ProtectedRoute><UpdateOrderFormPage /></ProtectedRoute>} />
      <Route path="/finance/detailedinvoice/:planid" element={<ProtectedRoute><InvoiceTable /></ProtectedRoute>} />
      <Route path="/ticketNotifications" element={<ProtectedRoute><TicketNotification /></ProtectedRoute>} />
      <Route path="/tickets" element={<ProtectedRoute><TicketTable /></ProtectedRoute>} />
      <Route path="/supporttickets" element={<ProtectedRoute><SupportTicketTable /></ProtectedRoute>} />
      <Route path="/devices" element={<ProtectedRoute><DeviceCards /></ProtectedRoute>} />
      <Route path="/device-types" element={<ProtectedRoute><DeviceTypeCards /></ProtectedRoute>} />
      <Route path="/units" element={<ProtectedRoute><UnitCards /></ProtectedRoute>} />
      <Route path="/sim-cards" element={<ProtectedRoute><SimCardCards /></ProtectedRoute>} />
      <Route path="/sim-card-types" element={<ProtectedRoute><SimCardTypeCards /></ProtectedRoute>} />
      <Route path="/vehicles" element={<ProtectedRoute><VehicleCards /></ProtectedRoute>} />
      <Route path="/vehicle-Types" element={<ProtectedRoute><VehicleTypesCards /></ProtectedRoute>} />
      <Route path="/organizations" element={<ProtectedRoute><OrganizationCards /></ProtectedRoute>} />
      <Route path="/organizations/active" element={<ProtectedRoute><ActiveOrganizationCards /></ProtectedRoute>} />
      <Route path="/organizations/inactive" element={<ProtectedRoute><InActiveOrganizationCards /></ProtectedRoute>} />
      <Route path="/organizations/potential" element={<ProtectedRoute><PotentialOrganizationCards /></ProtectedRoute>} />
      <Route path="/organizations/lost" element={<ProtectedRoute><LostOrganizationCards /></ProtectedRoute>} />
      <Route path="/users" element={<ProtectedRoute><UserCards /></ProtectedRoute>} />
      <Route path="/accessories" element={<ProtectedRoute><AccessoryTable /></ProtectedRoute>} />
      <Route path="/accessory-types" element={<ProtectedRoute><AccessoryTypeTable /></ProtectedRoute>} />
      <Route path="/rfids" element={<ProtectedRoute><RfidTable /></ProtectedRoute>} />
      <Route path="/rfid-lists" element={<ProtectedRoute><RfidListCards /></ProtectedRoute>} />
      <Route path="/quotations" element={<ProtectedRoute><QuotationTable /></ProtectedRoute>} />
      <Route path="/templetes" element={<ProtectedRoute><TempleteTable /></ProtectedRoute>} />
      <Route path="/tasks" element={<ProtectedRoute><TaskTable /></ProtectedRoute>} />
      <Route path="/tasks/groups" element={<ProtectedRoute><TaskGroupTable /></ProtectedRoute>} />
      <Route path="/myTasks" element={<ProtectedRoute><MyTasks /></ProtectedRoute>} />
      <Route path="/tasks/pending" element={<ProtectedRoute><PendingTasksTable /></ProtectedRoute>} />
      <Route path="/tasks/completed" element={<ProtectedRoute><CompletedTasksTable /></ProtectedRoute>} />
      <Route path="/tasks/approve" element={<ProtectedRoute><TBApprovedTasksTable /></ProtectedRoute>} />
      <Route path="/tasks/approveReinstallation/:id" element={<ProtectedRoute><TaskReinstallationApproval /></ProtectedRoute>} />
      <Route path="/tasks/approveInstallation/:id" element={<ProtectedRoute><TaskInstallationApprovalForm /></ProtectedRoute>} />
      <Route path="/tasks/approveRemoval/:id" element={<ProtectedRoute><TaskRemovalApprovalForm /></ProtectedRoute>} />
      <Route path="/tasks/approveInspection/:id" element={<ProtectedRoute><TaskInspectionApprovalForm /></ProtectedRoute>} />
      <Route path="/tasks/approveDeviceChange/:id" element={<ProtectedRoute><DeviceChangeApprovalForm /></ProtectedRoute>} />
      <Route path="/tasks/approveSimCardChange/:id" element={<ProtectedRoute><TaskSimCardChangeApprovalFrom /></ProtectedRoute>} />
      <Route path="/tasks/approveAccessoryInstallation/:id" element={<ProtectedRoute><TaskAccessoryInstallationApproval /></ProtectedRoute>} />
      <Route path="/new/ticket" element={<ProtectedRoute><NewTicket /></ProtectedRoute>} />
      <Route path="/new/task-group" element={<ProtectedRoute><NewTaskGroupForm /></ProtectedRoute>} />
      <Route path="/new/support-ticket" element={<ProtectedRoute><NewSupportTicket /></ProtectedRoute>} />
      <Route path="/new/device" element={<ProtectedRoute><NewDeviceFormPage /></ProtectedRoute>} />
      <Route path="/new/unit" element={<ProtectedRoute><NewUnitFormPage /></ProtectedRoute>} />
      <Route path="/new/certificate" element={<ProtectedRoute><NewAsateelCertificateForm /></ProtectedRoute>} />
      <Route path="/new/units" element={<ProtectedRoute><AddMultipleUnits /></ProtectedRoute>} />
      <Route path="/new/device-type" element={<ProtectedRoute><NewDeviceTypeFormPage /></ProtectedRoute>} />
      <Route path="/new/sim-card" element={<ProtectedRoute><NewSimCardFormPage /></ProtectedRoute>} />
      <Route path="/new/sim-card-type" element={<ProtectedRoute><NewSimCardTypeFormPage /></ProtectedRoute>} />
      <Route path="/new/vehicle" element={<ProtectedRoute><NewVehicleFormPage /></ProtectedRoute>} />
      <Route path="/new/vehicleType" element={<ProtectedRoute><NewVehicleTypeFormPage /></ProtectedRoute>} />
      <Route path="/new/organization" element={<ProtectedRoute><NewOrganizationFormPage /></ProtectedRoute>} />
      <Route path="/new/user" element={<ProtectedRoute><NewUserFormPage /></ProtectedRoute>} />
      <Route path="/new/accessories" element={<ProtectedRoute><NewAccessoryFormPage /></ProtectedRoute>} />
      <Route path="/new/accessories_type" element={<ProtectedRoute><NewAccessoryTypeFormPage /></ProtectedRoute>} />
      <Route path="/new/rfid" element={<ProtectedRoute><NewRfidFormPage /></ProtectedRoute>} />
      <Route path="/new/rfid-list" element={<ProtectedRoute><RfidListForm /></ProtectedRoute>} />
      <Route path="/new/quotation" element={<ProtectedRoute><NewQuotationForm /></ProtectedRoute>} />
      <Route path="/new/quotationtemplete" element={<ProtectedRoute><NewQuotationTemplete /></ProtectedRoute>} />
      <Route path="/new/task/" element={<ProtectedRoute><NewTaskForm /></ProtectedRoute>} />
      <Route path="/new/devices" element={<ProtectedRoute><AddMultipleDevices /></ProtectedRoute>} />
      <Route path="/new/sim-cards" element={<ProtectedRoute><AddMultipleSimCards /></ProtectedRoute>} />
      <Route path="/new/tasks" element={<ProtectedRoute><AddMultipleTasks /></ProtectedRoute>} />
      <Route path="/new/certificates" element={<ProtectedRoute><AddMultipleAsateelCertificates /></ProtectedRoute>} />
      <Route path="/new/vehicles" element={<ProtectedRoute><AddMultipleVehicles /></ProtectedRoute>} />
      <Route path="/new/vehicleTypes" element={<ProtectedRoute><AddMultipleVehicleTypes /></ProtectedRoute>} />
      <Route path="/new/organizations" element={<ProtectedRoute><AddMultipleOrganizations /></ProtectedRoute>} />
      <Route path="/edit/devices" element={<ProtectedRoute><EditMultipleDevicesCSV /></ProtectedRoute>} />
      <Route path="/edit/attendance" element={<ProtectedRoute><UpdateAttendanceForm /></ProtectedRoute>} />
      <Route path="/edit/certificates" element={<ProtectedRoute><EditMultipleAsateelCertificates /></ProtectedRoute>} />
      <Route path="/edit/device/:id" element={<ProtectedRoute><UpdateDeviceFormPage /></ProtectedRoute>} />
      <Route path="/edit/device-type/:id" element={<ProtectedRoute><UpdateDeviceTypeFormPage /></ProtectedRoute>} />
      <Route path="/edit/units" element={<ProtectedRoute><EditMultipleUnitsCSV /></ProtectedRoute>} />
      <Route path="/edit/unit/:id" element={<ProtectedRoute><UpdateUnitFormPage /></ProtectedRoute>} />
      <Route path="/edit/sim-card/:id" element={<ProtectedRoute><UpdateSimCardFormPage /></ProtectedRoute>} />
      <Route path="/edit/sim-cards" element={<ProtectedRoute><EditMultipleSimCardsCSV /></ProtectedRoute>} />
      <Route path="/edit/sim-card-type/:id" element={<ProtectedRoute><UpdateSimCardTypeFormPage /></ProtectedRoute>} />
      <Route path="/edit/vehicles" element={<ProtectedRoute><EditMultipleVehiclesCSV /></ProtectedRoute>} />
      <Route path="/edit/vehicle/:id" element={<ProtectedRoute><UpdateVehicleFormPage /></ProtectedRoute>} />
      <Route path="/edit/vehicle-type/:id" element={<ProtectedRoute><UpdateVehicleTypeFormPage /></ProtectedRoute>} />
      <Route path="/edit/organizations" element={<ProtectedRoute><EditMultipleOrganizationsCSV /></ProtectedRoute>} />
      <Route path="/edit/organization/:id" element={<ProtectedRoute><UpdateOrganizationFormPage /></ProtectedRoute>} />
      <Route path="/edit/user/:id" element={<ProtectedRoute><UpdateUserFormPage /></ProtectedRoute>} />
      <Route path="/edit/accessories/:id" element={<ProtectedRoute><UpdateAccessoryFormPage /></ProtectedRoute>} />
      <Route path="/edit/accessories_type/:id" element={<ProtectedRoute><UpdateAccessoryTypeFormPage /></ProtectedRoute>} />
      <Route path="/edit/rfid/:id" element={<ProtectedRoute><UpdateRfidFormPage /></ProtectedRoute>} />
      <Route path="/edit/rfid-list/:id" element={<ProtectedRoute><RfidListForm /></ProtectedRoute>} />
      <Route path="/edit/quotation/:id" element={<ProtectedRoute><UpdateQuotationForm /></ProtectedRoute>} />
      <Route path="/edit/quotationtemplete/:id" element={<ProtectedRoute><UpdateQuotationTemplete /></ProtectedRoute>} />
      <Route path="/edit/task/:id" element={<ProtectedRoute><UpdateTaskForm /></ProtectedRoute>} />
      <Route path="/edit/tasks" element={<ProtectedRoute><EditMultipleTasksCSV /></ProtectedRoute>} />
      <Route path="/edit/task_group/:id" element={<ProtectedRoute><UpdateTaskGroupForm /></ProtectedRoute>} />
      <Route path="/technician/job/installation/:id" element={<ProtectedRoute><InstallationTaskForm /></ProtectedRoute>} />
      <Route path="/technician/job/removal/:id" element={<ProtectedRoute><RemovalTaskForm /></ProtectedRoute>} />
      <Route path="/technician/job/device_change/:id" element={<ProtectedRoute><DeviceChangeTaskForm /></ProtectedRoute>} />
      <Route path="/technician/job/re_Installation/:id" element={<ProtectedRoute><ReinstallationTaskForm /></ProtectedRoute>} />
      <Route path="/technician/job/simCard_change/:id" element={<ProtectedRoute><SimChangeTaskForm /></ProtectedRoute>} />
      <Route path="/technician/job/inspection/:id" element={<ProtectedRoute><InspectionTaskForm /></ProtectedRoute>} />
      <Route path="/technician/job/accessory_installation/:id" element={<ProtectedRoute><AccessoryInstallationTaskForm /></ProtectedRoute>} />
      <Route path="/technician/deviceTransfer" element={<ProtectedRoute><TechnicianTransferDevice /></ProtectedRoute>} />
      <Route path="/ticket/:id" element={<ProtectedRoute><TicketDetails /></ProtectedRoute>} />
      <Route path="/device/:id" element={<ProtectedRoute><DeviceDetails /></ProtectedRoute>} />
      <Route path="/unit/:id" element={<ProtectedRoute><UnitDetails /></ProtectedRoute>} />
      <Route path="/device-type/:id" element={<ProtectedRoute><DeviceTypeDetails /></ProtectedRoute>} />
      <Route path="/sim-card/:id" element={<ProtectedRoute><SimCardDetails /></ProtectedRoute>} />
      <Route path="/sim-card-type/:id" element={<ProtectedRoute><SimCardTypeDetails /></ProtectedRoute>} />
      <Route path="/organization/:id" element={<ProtectedRoute><OrganizationDetails /></ProtectedRoute>} />
      <Route path="/vehicle/:id" element={<ProtectedRoute><VehicleDetails /></ProtectedRoute>} />
      <Route path="/vehicle-type/:id" element={<ProtectedRoute><VehicleTypeDetails /></ProtectedRoute>} />
      <Route path="/user/:id" element={<ProtectedRoute><UserDetails /></ProtectedRoute>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Pages;
