import { ActionIcon, Checkbox, Group, Modal, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { ExportAttendances } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import BaseTable from '../../../components/table/BaseTable';
import { URLParams, selectColumns, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { useAttendances } from '../api';
import { AttendanceColumnsMode } from '../constants';
import { Iattendance } from '../types';
import { TbBrandGoogleMaps, TbMap } from 'react-icons/tb';
import { BaseMap } from '../../../components';
import { MapContainer } from 'react-leaflet';
import { useDisclosure } from '@mantine/hooks';

export function AttendanceTable() {
  const [data, setData] = useState<Iattendance[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [page, setPage] = useState(1);
  const [opened, { open, close }] = useDisclosure(false);
  const [coords, setCoords] = useState<[number, number]>([0, 0]);
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes('user');
    param.sort('-attendance.id');
    param.select(...selectColumns(AttendanceColumnsMode), 'user.id', 'user.firstName', 'user.lastName');
    param.page(10, page - 1);
    return param;
  });
  const { data: allAttendances, refetch, isLoading } = useAttendances({ params: params.toString() });
  useEffect(() => {
    if (allAttendances) {
      setData(allAttendances.data.items);
      const selectedRows = allAttendances.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allAttendances]);

  const columnHelper = useMemo(() => createColumnHelper<Iattendance>(), []);
  const columns: ColumnDef<Iattendance, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allAttendances?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allAttendances?.data.meta.ids ? allAttendances?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => row.user.firstName, { header: 'First Name', id: 'user.firstName' }),
      columnHelper.accessor((row) => row.user.lastName, { header: 'Last Name', id: 'user.lastName' }),
      columnHelper.accessor((row) => simpleDateFormat(row.day), { header: 'Day', id: 'day' }),
      columnHelper.accessor(
        (row) => {
          if (row.timeIn) {
            const displayTime = row.timeIn.toString().split(':');
            const completeTime =
              +displayTime[0] > 12 ? `${+displayTime[0] - 12}:${displayTime[1]} PM` : `${+displayTime[0]}:${displayTime[1]} AM`;
            return completeTime;
          } else return '';
        },
        { header: 'Time In', id: 'timeIn' },
      ),
      columnHelper.display({
        header: 'Time In Location',
        id: 'timeInLocation',
        cell: ({ row }) => {
          return row.original.timeInLatitude && row.original.timeInLongitude ? (
            <Group noWrap spacing="xs">
              <ActionIcon
                onClick={() => {
                  open();
                  if (row.original.timeInLatitude && row.original.timeInLongitude)
                    setCoords([+row.original.timeInLatitude, +row.original.timeInLongitude]);
                }}
              >
                <TbMap />
              </ActionIcon>
              <ActionIcon
                component="a"
                href={'https://www.google.com/maps?q='.concat([row.original.timeInLatitude, row.original.timeInLongitude].join())}
                target="_blank"
              >
                <TbBrandGoogleMaps />
              </ActionIcon>
            </Group>
          ) : (
            'Unknown'
          );
        },
      }),
      columnHelper.accessor(
        (row) => {
          if (row.timeOut) {
            const displayTime = row.timeOut.toString().split(':');
            const completeTime =
              +displayTime[0] > 12 ? `${+displayTime[0] - 12}:${displayTime[1]} PM` : `${+displayTime[0]}:${displayTime[1]} AM`;
            return completeTime;
          } else return '';
        },
        { header: 'Time Out', id: 'timeOut' },
      ),
      columnHelper.display({
        header: 'Time Out Location',
        id: 'timeOutLocation',
        cell: ({ row }) => {
          return row.original.timeOutLatitude && row.original.timeOutLongitude ? (
            <Group noWrap spacing="xs">
              <ActionIcon
                onClick={() => {
                  open();
                  if (row.original.timeOutLatitude && row.original.timeOutLongitude)
                    setCoords([+row.original.timeOutLatitude, +row.original.timeOutLongitude]);
                }}
              >
                <TbMap />
              </ActionIcon>
              <ActionIcon
                component="a"
                href={'https://www.google.com/maps?q='.concat([row.original.timeOutLatitude, row.original.timeOutLongitude].join())}
                target="_blank"
              >
                <TbBrandGoogleMaps />
              </ActionIcon>
            </Group>
          ) : (
            'Unknown'
          );
        },
      }),
      columnHelper.accessor((row) => row.status, { header: 'status', id: 'status' }),
      columnHelper.accessor((row) => (row.remarks ? row.remarks : ''), { header: 'remarks', id: 'remarks' }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    ID: 'number',
    Day: 'date',
    'Time In': 'time',
    'Time Out': 'time',
  };

  return (
    <Layout>
      <Group position="apart" mb="md">
        <Title order={2} align="left" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
          Attendance:
        </Title>
      </Group>
      <BaseTable<Iattendance>
        data={data}
        columns={columns}
        defaultVisibleColumns={{ remarks: false }}
        loading={isLoading}
        csvExport={<ExportAttendances ids={ids} />}
        page={page}
        ids={ids}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers.filter((header) => header && header !== 'select' && header !== 'actions').join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        pages={allAttendances?.data.meta.ids.length ? Math.ceil(allAttendances?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        columnTypes={columnTypes}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
      />
      <Modal opened={opened} onClose={close}>
        <MapContainer center={coords} zoom={6} style={{ height: 450 }}>
          <BaseMap coords={coords} marker />
        </MapContainer>
      </Modal>
    </Layout>
  );
}
