import {
  Alert,
  Button,
  Container,
  List,
  LoadingOverlay,
  Paper,
  Select,
  Table,
  TextInput
} from '@mantine/core';
import { useEffect, useState } from 'react';
  
  import { useForm } from '@mantine/form';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { DatePickerInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, enumToSelectData } from '../../../utils';
import { IaxiosError } from '../../common';
import { useSimCardTypes } from '../../sim-card-types';
import { postSimCards, useSimCards } from '../api';
import { SimStatus } from '../constants';
import { IsimCardInputEdit } from '../types';
  
  export function EditMultipleSimCards({ids}:{ids:number[]}) {
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const params = new URLParams()
    params.filterSetLevel(0, '|')
    params.includes('simCardType')
    ids.forEach((id) => params.filterSetField('=id',id, 0))
    const {data:allSims } = useSimCards({ params: params.toString() });
    useEffect(() => {
        if (allSims) {
            const temp = allSims.data.items.map(item => {
                const obj =  Object.assign({},{...item, purchaseDate: item.purchaseDate? new Date(item.purchaseDate) : null, simCardType: item.simCardType.id})
                return obj
            })
            form.setValues(temp)
        }
    }, [allSims]);

    const [simCardTypesSelect, setSimCardTypesSelect] = useState([
        { value: '', label: '' },
      ]);
    const queryClient = useQueryClient();

    const { data: allSimCardTypesData } = useSimCardTypes();
    useEffect(() => {
        if (allSimCardTypesData) {
        const tempArr = allSimCardTypesData.data.items.map((simCard) => {
            return {
            value: `${simCard.id}`,
            label: `${simCard.supplier}, ${simCard.type}`,
            };
        });
        setSimCardTypesSelect(tempArr);
        }
    }, [allSimCardTypesData]);

    const form = useForm<IsimCardInputEdit[]>({
        initialValues: [
          {
            id:0,number:'',serial:'',simCardType:0,status:'', purchaseDate: null
          },
        ] 
        
      });



  
  
    const {
      mutate: newMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(postSimCards, {
      onSuccess: () => {
        setAlert(null);
        queryClient.invalidateQueries([types.SimCard, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });
  
    function handleSubmit(values: IsimCardInputEdit[]) {
        newMutate(Object.values(values))
    }

    const rows = Object.values(form.values).map((row, i) => {
        return(
            <tr key={'r' + i}>
                <td>{row.id}</td>
                <td>
                <TextInput
                {...form.getInputProps(`${i}.number`)}
                onChange={(v) => {
                    form.setFieldValue(`${i}.number`, v.currentTarget.value);
                    }}
                />
                </td>
                <td>
                <TextInput
                {...form.getInputProps(`${i}.serial`)}
                onChange={(v) => {
                    form.setFieldValue(`${i}.serial`, v.currentTarget.value);
                    }}
                />
                </td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.status`)}
                data={enumToSelectData(SimStatus)}
                />
                </td>
                <td>
                <DatePickerInputEditable
                popoverProps={{withinPortal: true}}
                  placeholder="Search Date"
                  {...form.getInputProps(`${i}.purchaseDate`)}
                />
                </td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.simCardType`)}
                onChange={(v) => {
                    if(v)
                    form.setFieldValue(`${i}.simCardType`, parseInt(v));
                    }}
                    data={simCardTypesSelect}
                    value={`${form.values[i].simCardType}`}
                />
                </td>
            </tr>
          )
    });
  
  
    return (
        <Container pt={10} fluid>
          <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
                mt="md"
              >
                Successfully added to the database!
              </Alert>
            )}
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                mt="md"
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item key={errMsg}>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>

            
            {form.values && (
            <Table highlightOnHover mt="md">
              <thead>
                <tr>
                  {Object.keys(form.values[0]).map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Button
              fullWidth
              type='submit'
              mt="md"
            >
              Submit
            </Button>
          </form>
          </Paper>
        </Container>
    );
  }
  