
export enum DeliveryNoteColumnsMode {
    ID = 'id',
    RFIDCardNumber = 'RFIDCardNumber',
    RFIDCardNumberRemarks = 'RFIDCardNumberRemarks',
    CheckInRMBMeter = 'checkInRMBMeter',
    CheckOutRMBMeter = 'checkOutRMBMeter',
    CheckInSpeed = 'checkInSpeed',
    CheckIn4X4 = 'checkIn4X4',
    CheckOut4X4 = 'checkOut4X4',
    CheckInSeatBelt = 'checkInSeatBelt',
    CheckOutSeatBelt = 'checkOutSeatBelt',
    CheckInAC = 'checkInAC',
    CheckOutAC = 'checkOutAC',
    CheckInHeadLights = 'checkInHeadLights',
    CheckOutHeadLights = 'checkOutHeadLights',
    CheckInBrakeLights = 'checkInBrakeLights',
    CheckOutBrakeLights = 'checkOutBrakeLights',
    CheckInIndecators = 'checkInIndecators',
    CheckOutIndecators = 'checkOutIndecators',
    CheckInRadioCD = 'checkInRadioCD',
    CheckOutRadioCD = 'checkOutRadioCD',
    CheckInSideMirror = 'checkInSideMirror',
    CheckOutSideMirror = 'checkOutSideMirror',
    CheckInPowerWindows = 'checkInPowerWindows',
    CheckOutPowerWindows = 'checkOutPowerWindows',
    ConfigRPM = 'configRPM',
    Config4X4 = 'config4X4',
    ConfigCAN = 'configCAN',
    ConfigDriverSeatBelt = 'configDriverSeatBelt',
    ConfigPassengerSeatBelt = 'configPassengerSeatBelt',
    ConfigSpeed = 'configSpeed',
    ConfigRemarks = 'configRemarks',
    SensorRFIDCard = 'sensorRFIDCard',
    SensorBuzzer = 'sensorBuzzer',
    SensorBuzzer12V = 'sensorBuzzer12V',
    SensorBuzzer24V = 'sensorBuzzer24V',
    SensorCanbusConnect = 'sensorCanbusConnect',
    SensorDriverDoorSernsor = 'sensorDriverDoorSernsor',
    SensorDriverSeatBeltSensor = 'sensorDriverSeatBeltSensor',
    Sensor4X4 = 'sensor4X4',
    SensorRPM = 'sensorRPM',
    SensorGroundSpeed = 'sensorGroundSpeed',
    SensorFuelSensor = 'sensorFuelSensor',
    SensorPTOSwitch = 'sensorPTOSwitch',
    SensorAutoSpeedController = 'sensorAutoSpeedController',
    SensorOtherSensors = 'sensorOtherSensors',
    sensorRemarks = 'sensorRemarks',
    CutOffTypeIgnition = 'cutOffTypeIgnition',
    CutOffTypeFuel = 'cutOffTypeFuel',
    CutOffTypeStarterMotor = 'cutOffTypeStarterMotor',
    CutOffTypeImmobilizerRelayType = 'cutOffTypeImmobilizerRelayType',
    CutOffTypeImmobilizerRelayType12V = 'cutOffTypeImmobilizerRelayType12V',
    CutOffTypeImmobilizerRelayType24V = 'cutOffTypeImmobilizerRelayType24V',
    RecheckingReportProblem = 'recheckingReportProblem',
    RecheckingProblemDetected = 'recheckingProblemDetected',
    RecheckingCorrectiveAction = 'recheckingCorrectiveAction',
    RecheckingOtherRemarks = 'recheckingOtherRemarks',
    CreatedAt = 'createdAt',
  }
  
  export interface IDeliveryNote {
    id: number;
    RFIDCardNumber: string;
    RFIDCardNumberRemarks: string;
    checkInRMBMeter: boolean;
    checkOutRMBMeter: boolean;
    checkInSpeed: boolean;
    checkOutSpeed: boolean;
    checkIn4X4: boolean;
    checkOut4X4: boolean;
    checkInSeatBelt: boolean;
    checkOutSeatBelt: boolean;
    checkInAC: boolean;
    checkOutAC: boolean;
    checkInHeadLights: boolean;
    checkOutHeadLights: boolean;
    checkInBrakeLights: boolean;
    checkOutBrakeLights: boolean;
    checkInIndecators: boolean;
    checkOutIndecators: boolean;
    checkInRadioCD: boolean;
    checkOutRadioCD: boolean;
    checkInSideMirror: boolean;
    checkOutSideMirror: boolean;
    checkInPowerWindows: boolean;
    checkOutPowerWindows: boolean;
    configRPM: boolean;
    config4X4: boolean;
    configCAN: boolean;
    configDriverSeatBelt: boolean;
    configPassengerSeatBelt: boolean;
    configSpeed: boolean;
    configRemarks: string;
    sensorRFIDCard: boolean;
    sensorBuzzer: boolean;
    sensorBuzzer12V: boolean;
    sensorBuzzer24V: boolean;
    sensorCanbusConnect: boolean;
    sensorDriverDoorSernsor: boolean;
    sensorDriverSeatBeltSensor: boolean;
    sensor4X4: boolean;
    sensorRPM: boolean;
    sensorGroundSpeed: boolean;
    sensorFuelSensor: boolean;
    sensorPTOSwitch: boolean;
    sensorAutoSpeedController: boolean;
    sensorOtherSensors: boolean;
    sensorRemarks: string;
    cutOffTypeIgnition: boolean;
    cutOffTypeFuel: boolean;
    cutOffTypeStarterMotor: boolean;
    cutOffTypeImmobilizerRelayType: boolean;
    cutOffTypeImmobilizerRelayType12V: boolean;
    cutOffTypeImmobilizerRelayType24V: boolean;
    recheckingReportProblem: string;
    recheckingProblemDetected: string;
    recheckingCorrectiveAction: string;
    recheckingOtherRemarks: string;
    // task: Task; // this cause exccesive deep on record table
    createdAt: Date;
  }
  