import { useEffect, useState } from "react"
import { useAttendances } from "../../modules/attendance"
import { DownloadAsCsv, Format, URLParams, dateToNewDate, getAllKeysWithPath, getKeysWithPath } from "../../utils"
import { ExportToCsvButton } from "../table/components"

export function ExportAttendances({
    ids,
}:{
    ids: number[]
}){

    const attendance = {
        id: 0,
        day: new Date(),
        timeIn: new Date(),
        timeOut: new Date(),
        status: "",
        remarks: '',
        user: {
            id: 0,
            firstName: "",
            lastName: "",
        },
    }

    const [ fetch, setFetch ] = useState(false)
    const [ fileName, setFileName ] = useState('')
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
            'user',
        );
        
        param.sort(('-attendance.id'))
        return param
      })
    // params.se
    const { data: allAttendances, refetch } = useAttendances({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (allAttendances) {
          DownloadAsCsv(dateToNewDate(allAttendances.data.items, ['day']), fileName, getKeysWithPath(getAllKeysWithPath(allAttendances.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [allAttendances]);

    return(
        <ExportToCsvButton keys={getKeysWithPath(attendance,[], ':')} onSubmit={(v => {
            setFileName(v.fileName)
            const paths = Object.values(v.columns).reduce((cols, item) => {
                    const path = handleReplacePath(item.path, [])
                    
                    if (item?.checked) cols.push(path)
                    return cols
                  }, [] as string[])
            setParams(param => {
                param.select(...paths)
                param.resetSearchMany()
                param.searchMany('id', ids.map(id => `${id}`))
                // param.filterRemoveSelects()
                // param.filterRemoveAllField('=id')
                // ids.forEach(id => {
                //     param.filterSetLevel(1 , '|')
                //     param.filterSetField('=id', id, 1)
                // })
                return param
            })
            
            if(!fetch){
                setFetch(true)
            } else refetch()
        })}/>
    )
}
function handleReplacePath(path: string, replaces:{originalPath: string, replaceTo: string}[]){
    const found = replaces.find(item => path.includes(item.originalPath))
    const paths = path.split('.')
    const column = paths[paths.length-1]
    if(found) return found.replaceTo+'.'+column
    else return path
}