import {
  Alert,
  Button,
  Container,
  List,
  LoadingOverlay,
  Paper,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { enumToSelectData, removeNotEdited } from '../../../utils';
import { IaxiosError, UploadButton, useUploadImage } from '../../common';
import { patchDeviceType, useDeviceType } from '../api';
import { DeviceGeneration } from '../constants/constants';
import { IdeviceTypeInput } from '../types/types';

export function UpdateDeviceTypeForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { upload, progress, uploadCompleted } = useUploadImage();

  const queryClient = useQueryClient();


  const { data: deviceTypeData } = useDeviceType(idN, {
    then: (deviceType) => {
      form.setValues({
        supplier: deviceType.data.supplier,
        type: deviceType.data.type,
        generation: deviceType.data.generation,
      });
      form.resetDirty({
        supplier: deviceType.data.supplier,
        type: deviceType.data.type,
        generation: deviceType.data.generation,
      });
    },
  });

  const form = useForm({
    initialValues: {
      supplier: deviceTypeData?.data.supplier || '',
      type: deviceTypeData?.data.type || '',
      generation: deviceTypeData?.data.generation || '',
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchDeviceType, {
    onSuccess: (data) => {
      setAlert(null);
      const imageRepositoryId = data.data.imageRepositoryId;
      if (imageRepositoryId && file) {
        upload(
          imageRepositoryId,
          file,
          `${data.data.supplier}_${data.data.type}`,
          types.DeviceType,
          types.DeviceType,
          data.data.id
        );
      }
      queryClient.invalidateQueries([types.DeviceType, types.ALL]);
      queryClient.invalidateQueries([types.DeviceType, { id: idN }]);
      queryClient.invalidateQueries([
        types.History,
        types.DeviceType,
        { id: idN },
      ]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IdeviceTypeInput) {
    setAlert(null);
    const newValues = removeNotEdited(values, form);
    if (idN !== 0 && !Number.isNaN(idN)) {
      if (Object.keys(newValues).length) {
        patchMutate({ id: idN, data: newValues });
      }
      if (deviceTypeData && deviceTypeData.data.imageRepositoryId && file) {
        upload(
          deviceTypeData.data.imageRepositoryId,
          file,
          `${deviceTypeData.data.supplier}_${deviceTypeData.data.type}`,
          types.DeviceType,
          types.DeviceType,
          deviceTypeData.data.id
        );
        queryClient.invalidateQueries([types.Images, { id: deviceTypeData.data.imageRepositoryId }]);
      }
    } else {
      setAlert(['The id provided is incorrect!']);
    }
  }

  return (
    <Container pt={10}>
      <Title
        order={2}
        pl={5}
        align="left"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 800,
        })}
      >
        {idN !== 0 && !Number.isNaN(idN) ? 'Edit a ' : 'Add a new '} device
        type:
      </Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
        <LoadingOverlay visible={patchLoading} overlayBlur={2} />
        {alert && (
          <Alert
            icon={<TbAlertOctagon size={16} />}
            title="Error!"
            color="red"
            mb={10}
          >
            {Array.isArray(alert) ? (
              <List>
                {alert.map((errMsg) => (
                  <List.Item>{errMsg}!</List.Item>
                ))}
              </List>
            ) : (
              alert + '!'
            )}
          </Alert>
        )}
        {(patchSuccess || uploadCompleted) && (
          <Alert
            icon={<TbCircleCheck size={16} />}
            title={'Updated!'}
            color="green"
            mb={10}
          >
            Successfully updated in the database!
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInputEditable
            dirty={form.isDirty('supplier')}
            label="Supplier"
            placeholder="Supplier name"
            {...form.getInputProps('supplier')}
            required
          />
          <TextInputEditable
            dirty={form.isDirty('type')}
            label="Type"
            placeholder="Type name"
            {...form.getInputProps('type')}
            required
            mt="md"
          />
          <SelectEditable
            label="generation"
            placeholder="Search Here"
            {...form.getInputProps('generation')}
            required
            data={enumToSelectData(DeviceGeneration)}
            mt="md"
          />
          <UploadButton file={file} onChange={setFile} progress={progress}>
            {(props) => (
              <Button {...props} mt="md">
                Select device image
              </Button>
            )}
          </UploadButton>
          <Button fullWidth mt="xl" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
