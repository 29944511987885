import api from "../../common/api/api";
import { Irfid, IrfidInput } from "../types/types";

interface myResRfidPost {
	data: Irfid;
}

export const postRfid = (data: IrfidInput): Promise<myResRfidPost> => {
	return api.post(`rfids`, data);
};
