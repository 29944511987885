  
  import Layout from '../../../components/layout/Layout';
import { NewUnitForm } from '../components/NewUnitForm';
    
    export function NewUnitFormPage() {
      return (
        <Layout>
          <NewUnitForm/>
        </Layout>
      );
    }
    