import api from "../../common/api/api";
import { IvehicleType, IvehicleTypeInput } from "../types/types";

interface myResVehicleTypePatch {
	data: IvehicleType;
}

export const patchVehicleType = (props: { id: number; data: IvehicleTypeInput }): Promise<myResVehicleTypePatch> => {
	return api.patch(`vehicle-types/${props.id}`, props.data);
};
