
import Layout from '../../../components/layout/Layout';
import { UpdateSimCardForm } from '../components/UpdateSimCardForm';

export function UpdateSimCardFormPage() {

  return (
    <Layout>
      <UpdateSimCardForm idM={0}/>
    </Layout>
  );
}
