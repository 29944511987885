import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { Icomment } from "../types/types";

interface myResComment {
	data: Icomment
}

export const getComment = (id: number, joins?: string[], selects?: string[]): Promise<myResComment> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`comments/${id}${params}`);
};

export const useComment = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResComment) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Comment, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
		getComment(id, options?.joins, options?.selects).then((comment) => {
			options && options.then && options.then(comment);
			return comment;
		}),
		{ enabled: id !== 0 },
	);
	return query;
};
