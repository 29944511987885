import { Alert, Button, LoadingOverlay, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { TbAlertOctagon } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { types } from "../../../constants";
import { useAuthContext } from "../../../context/AuthContext";
import { noEmptyString } from "../../../utils";
import { patchTask } from "../api";
import { TaskStatus } from "../constants/constants";

export function OtherTaskCompleteModal({id, close, setIds,  setRowSelection, remarks}: {id: number, close: () => void, setIds?: React.Dispatch<React.SetStateAction<number[]>>,
  setRowSelection?: React.Dispatch<React.SetStateAction<{}>>, remarks?: string
}){
    const { user } = useAuthContext();
    const queryClient = useQueryClient();
    const {
        mutate: patchMutate,
        isLoading: patchLoading,
    } = useMutation(patchTask, {
      onSuccess: (data) => {
        notifications.show({
          title: 'Success',
          message: `task with Id ${data.data.id} correctly Updated`,
          color: 'green',
          autoClose: 10000,
        })
        setIds && setIds([])
        setRowSelection && setRowSelection({})
        close()

        queryClient.invalidateQueries([types.Task, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
        });
        },
        onError: () => {
          notifications.show({
            title: 'Error',
            message: `Task was not cancelled`,
            color: 'red',
            autoClose: 10000,
    
          })
        },
    });
    
    const form = useForm({
        initialValues: {
            jobRemarks: remarks ?? ''
        }
    })

    function handleSubmit(value: {jobRemarks: string}){
        const newValues = noEmptyString(value)
        if(user){
          patchMutate({id: id, data: {...newValues, status: TaskStatus.Done, performedBy: {id: user.id}, completionDate: new Date()}})
        }
    }
    return(
        <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
            <LoadingOverlay visible={patchLoading} overlayBlur={2} />
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Warning!"
              color="red"
              mb={10}
            >Are you sure you want to set this Task as Done!</Alert>
          <Textarea label='Remarks' {...form.getInputProps('jobRemarks')} />
          <Button type='submit' fullWidth mt='md'>Confirm</Button>
        </form>
    )
}