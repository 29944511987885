import { useEffect, useState } from "react"
import { useRecords } from "../../modules/records"
import { DownloadAsCsv, Format, URLParams, dateToNewDate, getAllKeysWithPath, getKeysWithPath } from "../../utils"
import { ExportToCsvButton } from "../table/components"

export function ExportRecords({
    ids,
}:{
    ids: number[]
}){
    const defaultColumns = ['id', 'platform', 'secondaryPlatform', 'task:id', 'task:type', 'task:completionDate', 'oldDevice:id', 'oldDevice:imei', 'newDevice:id', 'newDevice:imei', 'oldSimCard:id', 'oldSimCard:number', 'newSimCard:id', 'newSimCard:number', 'vehicle:id', 'vehicle:vin', 'vehicle:plateNumber', 'vehicle:organization:id', 'vehicle:organization:name']
    const record = {
        id: 0,
        platform: "",
        secondaryPlatform: "",
        vehicle: {
            id: 0,
            category: "",
            plateNumber: 0,
            placeOfIssue: "",
            vin: "",
            vehicleType: {
                id: 0,
                model: "",
                brand: "",
                year: 0,
                type: "",
            },
            organization: {
                id: 0,
                name: "",
                parent: {
                    id: 0,
                    name: "",
                },
            },
        },
        oldDevice: {
            id: 0,
            imei: 0,
            status: "",
            ownership: "",
            deviceType: {
                id: 0,
                type: "",
            }
        },
        newDevice: {
            id: 0,
            imei: 0,
            status: "",
            ownership: "",
            deviceType: {
                id: 0,
                type: "",
            }
        },
        newSimCard: {
                id: 0,
                number: "",
                serial: "",
                status: "",
            },
        oldSimCard: {
                id: 0,
                number: "",
                serial: "",
                status: "",
            },
        task: {
            id: 0,
            title: "",
            type: "",
            targetDate: new Date(),
            completionDate: new Date(),
            status: "",
            address: "",
            taskRemarks: "",
            jobRemarks: '',
            location: "",
            contactName: "",
            contactNumber: "",
            recipientGroup: "",
            performedBy: {
                id: 0,
                firstName: "",
                lastName: "",
            },
            approvedBy: {
                id: 0,
                firstName: "",
                lastName: "",
            },
        },
    }

    const [ fetch, setFetch ] = useState(false)
    const [ fileName, setFileName ] = useState('')
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
            'vehicle',
            'vehicle.vehicleType as vehicleType',
            'vehicle.organization as vehicleOrg',
            'vehicleOrg.parent as orgParent',
            'newDevice',
            'oldDevice',
            'newSimCard',
            'oldSimCard',
            'task',
            'task.performedBy as userPerformed',
            'task.approvedBy as userApproved',
        );
        
        param.sort(('-record.id'))
        return param
      })
    // params.se
    const { data: alltasks, refetch } = useRecords({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (alltasks) {
          DownloadAsCsv(dateToNewDate(alltasks.data.items, ['targetDate', 'completionDate']), fileName, getKeysWithPath(getAllKeysWithPath(alltasks.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [alltasks]);

    return(
        <ExportToCsvButton keys={getKeysWithPath(record,[], ':')} setDefaultColumns={defaultColumns} onSubmit={(v => {
            setFileName(v.fileName)
            const paths = Object.values(v.columns).reduce((cols, item) => {
                    const path = handleReplacePath(item.path, [{originalPath: 'vehicle.organization.parent', replaceTo: 'orgParent'},{originalPath: 'vehicle.organization', replaceTo: 'vehicleOrg'}, {originalPath: 'vehicle.vehicleType', replaceTo: 'vehicleType'}, {originalPath: 'task.performedBy', replaceTo: 'userPerformed'}, {originalPath: 'task.approvedBy', replaceTo: 'userApproved'}])
                    
                    if (item?.checked) cols.push(path)
                    return cols
                  }, [] as string[])
            setParams(param => {
                param.select(...paths)
                param.resetSearchMany()
                param.searchMany('id', ids.map(id => `${id}`))
                // param.filterRemoveSelects()
                // param.filterRemoveAllField('=id')
                // ids.forEach(id => {
                //     param.filterSetLevel(1 , '|')
                //     param.filterSetField('=id', id, 1)
                // })
                return param
            })
            
            if(!fetch){
                setFetch(true)
            } else refetch()
        })}/>
    )
}
function handleReplacePath(path: string, replaces:{originalPath: string, replaceTo: string}[]){
    const found = replaces.find(item => path.includes(item.originalPath))
    const paths = path.split('.')
    const column = paths[paths.length-1]
    if(found) return found.replaceTo+'.'+column
    else return path
}