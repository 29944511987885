import { Alert, Button, LoadingOverlay, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { TbAlertOctagon } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { SelectEditable } from "../../../components/form";
import { types } from "../../../constants";
import { useAuthContext } from "../../../context/AuthContext";
import { enumToSelectData, noEmptyString } from "../../../utils";
import { patchTask } from "../api";

enum TaskStatus {
  onHold = "on hold",
  NoShow = "client no show",
  TechinicianNoShow = "technician no show",
  Cancelled = "cancelled",
}
export function StatusUpdateModal({id, close, setIds,  setRowSelection}: {id: number, close: () => void, setIds?: React.Dispatch<React.SetStateAction<number[]>>,
  setRowSelection?: React.Dispatch<React.SetStateAction<{}>>

}){
    const { user } = useAuthContext();
    const queryClient = useQueryClient();
    const {
        mutate: patchMutate,
        isLoading: patchLoading,
    } = useMutation(patchTask, {
      onSuccess: (data) => {
        notifications.show({
          title: 'Success',
          message: `status for task with Id ${data.data.id} correctly Updated`,
          color: 'green',
          autoClose: 10000,
        })
        setIds && setIds([])
        setRowSelection && setRowSelection({})
        close()

        queryClient.invalidateQueries([types.Task, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
        });
        },
        onError: () => {
          notifications.show({
            title: 'Error',
            message: `Task was not Updated`,
            color: 'red',
            autoClose: 10000,
    
          })
        },
    });
    
    const form = useForm({
        initialValues: {
            status: '',
            taskRemarks: ''
        }
    })

    function handleSubmit(value: {taskRemarks: string, status: string}){
        const newValues = noEmptyString(value)
        if(user){
            if(newValues.status){
                patchMutate({id: id, data: {...newValues, performedBy: {id: user.id}, completionDate: new Date()}})
            }else{
                form.setFieldError('status', 'please select the new status')
            }
        }
    }
    return(
        <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
            <LoadingOverlay visible={patchLoading} overlayBlur={2} />
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Warning!"
              color="red"
              mb={10}
            >Are you sure you want to set this Task Status!!!</Alert>
            <SelectEditable
              label="Status"
              placeholder="Search here"
              {...form.getInputProps('status')}
              data={enumToSelectData(TaskStatus)}
              required
              mt="md"
              withinPortal
            />
          <Textarea label='Remarks' {...form.getInputProps('taskRemarks')} />
          <Button type='submit' fullWidth mt='md'>Confirm</Button>
        </form>
    )
}