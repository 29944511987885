
export enum OrganizationType {
	ATS = "ats",
	Client = "client",
	Vendor = "vendor",
	Supplier = "supplier",
	Other = "other",
}

export enum OrganizationStatus {
    Active = 'active',
    Not_Active = 'inactive',
    Potential = 'potential',
    Lost = 'lost',
  }


export enum OrganizationStatusColor {
	active= '#5c8f43',
	inactive= '#be58bf',
	potential = "#b5a638",
	lost= '#bf5858',
}

export enum OrganizationColumnsMode {
	ID = "id",
	Name = "name",
	Type = "type",
	Status = "status",
	TradeLicense = "tradeLicense",
	Trn = "trn",
	TrafficFile = "trafficFile",
	Abbreviation = "abbreviation",
	ImageRepositoryId = "imageRepositoryId",
}

export enum ContactsColumnsMode {
	ID = "id",
	Name = "name",
	Position = "position",
	Phone = "phone",
	Email = "email",
}