
export const useBar = () => {
  /**
   *
   * @param data 2 level SQL grouped data
   * @param key key name used to group
   * @param secondKey key name of the common
   * @param relationReName key to use to rename a key
   * @param rename2ndKey false to rename the key, true to rename secondKey (default: false)
   * @returns dta grouped for a nivo bar chart
   */
  const prepareBarData = (
    data: Record<string, string | number>[],
    key: string,
    secondKey: string,
    relationReName?: string,
    rename2ndKey = false,
  ) => {
    return data.reduce<Record<string, string | number>[]>((acc, curr) => {
      const check = relationReName && !rename2ndKey ? relationReName : key;
      const exist = acc.some((i) => i[key] === curr[check]);
      if (exist) {
        return acc;
      } else {
        const item: Record<string, string | number> = {};
        item[key] = curr[check];
        data.forEach((i) => {
          if (i[check] === curr[check]) {
            if (relationReName && rename2ndKey) {
              item[i[relationReName]] = i['count'];
            } else {
              item[i[secondKey]] = i['count'];
            }
          }
        });
        acc.push(item);
        return acc;
      }
    }, []);
  };

  return { prepareBarData };
};
