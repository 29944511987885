import { Badge, Collapse, createStyles, Group, Navbar, NavLink, ScrollArea, Text, UnstyledButton } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { MdOutlineCreditCard, MdOutlineSimCard, MdOutlineSimCardDownload } from 'react-icons/md';
import { RiBankCard2Line, RiSimCard2Line } from 'react-icons/ri';
import {
  TbBook,
  TbBuilding,
  TbBuildingBroadcastTower,
  TbBus,
  TbCar,
  TbCertificate,
  TbCheckupList,
  TbChevronDown,
  TbClipboard,
  TbClipboardCheck,
  TbClipboardList,
  TbClipboardText,
  TbClipboardX,
  TbCopy,
  TbCreditCard,
  TbDeviceAudioTape,
  TbDeviceGamepad,
  TbDeviceGamepad2,
  TbDevices,
  TbDevicesCog,
  TbDevicesPc,
  TbDeviceTabletPlus,
  TbHome,
  TbNotebook,
  TbReceiptRefund,
  TbReportAnalytics,
  TbReportMoney,
  TbSettings,
  TbTag,
  TbTags,
  TbTicket,
  TbTruck,
  TbUserCheck,
  TbUsers,
  TbLayout2,
  TbListDetails,
} from 'react-icons/tb';

import { useMediaQuery } from '@mantine/hooks';
import { IconType } from 'react-icons/lib';
import { useAuthContext } from '../../context/AuthContext';
import { useAttendances } from '../../modules/attendance';
import { TaskStatus, useCountTasks } from '../../modules/tasks';
import { UserRole } from '../../modules/users';
import { URLParams } from '../../utils';
import { ClientSideNav } from './client/SideNav';
import { useCollapseState } from './useCollapseState';
import { UserButton } from './UserButton';

const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: 0,
  },

  section: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    marginBottom: theme.spacing.md,

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
    },
  },

  searchCode: {
    fontWeight: 700,
    fontSize: 10,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]}`,
  },

  mainLinks: {
    paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingBottom: theme.spacing.md,
  },

  mainLink: {
    display: 'flex',
    textAlign: 'center',
    width: '100%',
    fontSize: theme.fontSizes.xs,
    padding: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  mainLinkInner: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  mainLinkIcon: {
    marginRight: theme.spacing.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: 40,
    height: 20,
    pointerEvents: 'none',
  },

  collections: {
    paddingLeft: `calc(${theme.spacing.md} - 6)`,
    paddingRight: `calc(${theme.spacing.md} - 6)`,
    paddingBottom: theme.spacing.md,
  },

  collectionsHeader: {
    paddingLeft: theme.spacing.md + 2,
    paddingRight: theme.spacing.md,
    marginBottom: 5,
  },

  collectionLink: {
    display: 'block',
    padding: `8px ${theme.spacing.xs}px`,
    textDecoration: 'none',
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.xs,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    lineHeight: 1,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },
}));

interface InavLinks {
  roles: string[];
  emoji: JSX.Element;
  to: string;
  label: string;
  notifications?: number | null;
}

interface ISideNav {
  opened: boolean;
}

const SideNav = ({ opened }: ISideNav) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const reportOp = useCollapseState();
  const financeOp = useCollapseState();
  const deviceOp = useCollapseState();
  const simCardOp = useCollapseState();
  const rfidOp = useCollapseState();
  const taskOp = useCollapseState();
  const organizationOp = useCollapseState();
  const quotationOp = useCollapseState();
  const vehicleOp = useCollapseState();
  const accessoryOp = useCollapseState();
  const ticketsOp = useCollapseState();

  const { user, close } = useAuthContext();
  if (user?.role === UserRole.Client) return <ClientSideNav opened={opened} />;
  const screenSize = useMediaQuery('(max-width: 56.25em)');

  const params = new URLParams();
  if (user) {
    params.filterSetField('status', 'pending', 0);
    params.filterSetField('=recipient', user.id, 1);
    params.filterSetField('recipientGroup', user.role, 1);
    params.filterSetLevel(1, '|');
  }
  const { data } = useCountTasks({
    params: params.toString(),
  });

  // const paramsTickets = new URLParams();
  // paramsTickets.filterSetLevel(0, '|');
  // paramsTickets.filterSetField('=status', 'new', 0);
  // paramsTickets.filterSetField('=status', 'pending', 0);
  // const { data: ticketsCount } = useCountTickets({
  //   params: paramsTickets.toString(),
  // });

  const userAttendanceParams = new URLParams();
  if (user) {
    userAttendanceParams.filterSetField('=user', user.id);
    userAttendanceParams.filterSetField(
      'day',
      `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
        .getDate()
        .toString()
        .padStart(2, '0')}`,
    );
  }
  const { data: UserAttendanceData } = useAttendances({
    params: userAttendanceParams.toString(),
  });

  const pendingTasksParams = new URLParams();
  pendingTasksParams.filterSetField('status', 'pending');
  const { data: pendingTasksData } = useCountTasks({
    params: pendingTasksParams.toString(),
  });
  const toBeApprovedTasksParams = new URLParams();
  toBeApprovedTasksParams.filterSetField('status', TaskStatus.ToBeApproved);
  const { data: toBeApprovedTasksData } = useCountTasks({
    params: toBeApprovedTasksParams.toString(),
  });

  const collections: InavLinks[] = [
    {
      roles: [],
      emoji: <TbDeviceGamepad2 size={20} />,
      to: '/units',
      label: 'Units',
      notifications: null,
    },
    {
      roles: [UserRole.Admin, UserRole.Master],
      emoji: <TbUsers size={20} />,
      to: '/users',
      label: 'Users',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbLayout2 size={20} />,
      to: '/services',
      label: 'Services',
      notifications: null,
    },
  ];

  const reports = [
    {
      roles: [],
      emoji: <TbDeviceGamepad2 size={20} />,
      to: '/reports/units',
      label: 'units',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbCertificate size={20} />,
      to: '/reports/certificates',
      label: 'certificates',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbDeviceGamepad size={20} />,
      to: '/reports/devices',
      label: 'Devices',
      notifications: null,
    },
    {
      roles: [],
      emoji: <MdOutlineSimCard size={20} />,
      to: '/reports/sim-cards',
      label: 'Sim Cards',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbCar size={20} />,
      to: '/reports/vehicles',
      label: 'Vehicles',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbTruck size={20} />,
      to: '/reports/vehicleTypes',
      label: 'Vehicle Types',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbBuilding size={20} />,
      to: '/reports/organizations',
      label: 'Organizations',
      notifications: null,
    },
    {
      roles: [UserRole.Admin, UserRole.Master],
      emoji: <TbUsers size={20} />,
      to: '/reports/users',
      label: 'Users',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbBook size={20} />,
      to: '/reports/records',
      label: 'Records',
      notifications: null,
    },
    {
      roles: [UserRole.Master, UserRole.Finance, UserRole.Admin],
      emoji: <TbCheckupList size={20} />,
      to: '/reports/attendance',
      label: 'Attendance',
      notifications: null,
    },
  ];

  const finance = [
    {
      roles: [],
      emoji: <TbBook size={20} />,
      to: '/finance/orders',
      label: 'Orders',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbReceiptRefund size={20} />,
      to: '/finance/new/order',
      label: 'New Order',
      notifications: null,
    },
  ];

  const devices = [
    {
      roles: [],
      emoji: <TbDeviceGamepad size={20} />,
      to: '/devices',
      label: 'Inventory',
      notifications: null,
    },

    {
      roles: [],
      emoji: <TbDeviceAudioTape size={20} />,
      to: '/device-types',
      label: 'Device Types',
      notifications: null,
    },
  ];

  const simcards = [
    {
      roles: [],
      emoji: <RiSimCard2Line size={20} />,
      to: '/sim-cards',
      label: 'Inventory',
      notifications: null,
    },
    {
      roles: [],
      emoji: <MdOutlineSimCardDownload size={20} />,
      to: '/sim-card-types',
      label: 'Sim Card Types',
      notifications: null,
    },
  ];

  const rfids = [
    {
      roles: [UserRole.Master],
      emoji: <TbCreditCard size={20} />,
      to: '/rfids',
      label: 'Inventory',
      notifications: null,
    },
    {
      roles: [UserRole.Master],
      emoji: <RiBankCard2Line size={20} />,
      to: '/rfid-lists',
      label: 'Rfid Lists',
      notifications: null,
    },
  ];

  const tasks = [
    {
      roles: [],
      emoji: <TbClipboardText size={20} />,
      to: '/tasks',
      label: 'All tasks',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbListDetails size={20} />,
      to: '/tasks/groups',
      label: 'Tasks groups',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbClipboardX size={20} />,
      to: '/tasks/pending',
      label: 'Pending',
      notifications: pendingTasksData?.data,
    },
    {
      roles: [],
      emoji: <TbClipboard size={20} />,
      to: '/tasks/completed',
      label: 'Completed',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbClipboardCheck size={20} />,
      to: '/tasks/approve',
      label: 'To be Approved',
      notifications: toBeApprovedTasksData?.data,
    },
  ];
  const organizations = [
    {
      roles: [],
      emoji: <TbBuilding size={20} />,
      to: '/organizations',
      label: 'All organizations',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbBuilding size={20} />,
      to: '/organizations/active',
      label: 'Active',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbBuilding size={20} />,
      to: '/organizations/inactive',
      label: 'Inactive',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbBuilding size={20} />,
      to: '/organizations/potential',
      label: 'Potential',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbBuilding size={20} />,
      to: '/organizations/lost',
      label: 'Lost',
      notifications: null,
    },
  ];

  const vehicles = [
    {
      roles: [],
      emoji: <TbTruck size={20} />,
      to: '/vehicles',
      label: 'Vehicles',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbBus size={20} />,
      to: '/vehicle-Types',
      label: 'VehicleType',
      notifications: null,
    },
  ];

  const acccessories = [
    {
      roles: [],
      emoji: <TbDeviceTabletPlus size={20} />,
      to: '/accessories',
      label: 'Accessories',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbDevices size={20} />,
      to: '/accessory-types',
      label: 'accessory Types',
      notifications: null,
    },
  ];
  const tickets = [
    {
      roles: [],
      emoji: <TbNotebook size={20} />,
      to: '/supporttickets',
      label: 'Tickets',
      notifications: null,
    },
    {
      roles: [],
      emoji: <TbTicket size={20} />,
      to: '/new/support-ticket',
      label: 'New Ticket',
      notifications: null,
    },
  ];
  const quotations = [
    {
      roles: [UserRole.Admin, UserRole.Master, UserRole.Finance],
      emoji: <TbTags size={20} />,
      to: '/quotations',
      label: 'Quotations',
      notifications: null,
    },
    {
      roles: [UserRole.Admin, UserRole.Master],
      emoji: <TbCopy size={20} />,
      to: '/templetes',
      label: 'Templetes',
      notifications: null,
    },
  ];

  const links: { roles: string[]; icon: IconType; label: string; to: string; notifications?: number | null }[] = [
    { roles: [], icon: TbHome, label: 'Home', to: '/' },
    {
      roles: [],
      icon: TbUserCheck,
      label: 'Attendance',
      to: '/userAttendance',
      notifications: UserAttendanceData && UserAttendanceData.data.items.length > 0 ? undefined : 1,
    },

    {
      roles: [],
      icon: TbClipboardText,
      label: 'Tasks',
      to: '/myTasks',
      notifications: data?.data !== 0 ? data?.data : null,
    },

    {
      roles: [],
      icon: TbClipboardText,
      label: 'Tickets',
      to: '/ticketNotifications',
      // notifications: ticketsCount?.data !== 0 ? ticketsCount?.data : null,
      notifications: null,
    },
    { roles: [UserRole.Master], icon: TbSettings, label: 'Settings', to: '/settings' },
    { roles: [], icon: TbBuildingBroadcastTower, label: 'Todays Active Users', to: '/todays-attendance' },
  ];

  const mainLinks = links.map((link) => {
    if (user?.role && (link.roles?.includes(user?.role) || link.roles.length === 0))
      return (
        <UnstyledButton
          key={link.label}
          className={classes.mainLink}
          onClick={() => {
            navigate(link.to);
            screenSize && close();
          }}
        >
          <div className={classes.mainLinkInner}>
            <link.icon size={20} className={classes.mainLinkIcon} />
            <span>{link.label}</span>
          </div>
          {link.notifications && (
            <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
              {link.notifications}
            </Badge>
          )}
        </UnstyledButton>
      );
  });

  const collectionLinks = collections.map(
    (collection) =>
      user &&
      (collection.roles.length === 0 || collection.roles.includes(user?.role)) && (
        <NavLink
          pl={18}
          key={collection.label}
          icon={collection.emoji}
          label={
            <Group position="apart">
              <Text>{collection.label}</Text>
              {collection.notifications && (
                <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
                  {collection.notifications}
                </Badge>
              )}
            </Group>
          }
          onClick={() => {
            navigate(collection.to);
            screenSize && close();
          }}
        />
      ),
  );

  const nestedLinks = (nesteds: InavLinks[]) =>
    nesteds.map(
      (nested) =>
        user &&
        (nested.roles.length === 0 || nested.roles.includes(user?.role)) && (
          <NavLink
            pl={33}
            key={nested.label}
            icon={nested.emoji}
            label={
              <Group position="apart">
                <Text>{nested.label}</Text>
                {nested.notifications && (
                  <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
                    {nested.notifications}
                  </Badge>
                )}
              </Group>
            }
            onClick={() => {
              navigate(nested.to);
              screenSize && close();
            }}
          />
        ),
    );

  if (!opened) return null;
  if (user?.role === UserRole.Technician) return null;

  return (
    <Navbar hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }} className={classes.navbar}>
      {user && (
        <Navbar.Section className={classes.section} onClick={() => navigate('/profile')}>
          <UserButton image={user.img} name={user.username} role={user.role} />
        </Navbar.Section>
      )}
      <Navbar.Section className={classes.section}>
        <div className={classes.mainLinks}>{mainLinks}</div>
      </Navbar.Section>
      <Navbar.Section className={classes.section} grow component={ScrollArea}>
        <Group className={classes.collectionsHeader} position="apart">
          <Text size="xs" weight={500} color="dimmed" pl="md">
            Collections
          </Text>
        </Group>
        <NavLink
          pl={18}
          icon={<TbReportAnalytics size={20} />}
          label="Reports"
          onClick={() => reportOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={reportOp.opened} transitionDuration={500}>
          {nestedLinks(reports)}
        </Collapse>
        {(user?.role === UserRole.Master || user?.role === UserRole.Finance) && (
          <>
            <NavLink
              pl={18}
              icon={<TbReportMoney size={20} />}
              label="Finance"
              onClick={() => financeOp.setOpened((o) => !o)}
              rightSection={<TbChevronDown size={12} />}
            />
            <Collapse in={financeOp.opened} transitionDuration={500}>
              {nestedLinks(finance)}
            </Collapse>
          </>
        )}
        <NavLink
          pl={18}
          icon={<TbDevicesPc size={20} />}
          label="Devices"
          onClick={() => deviceOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={deviceOp.opened} transitionDuration={500}>
          {nestedLinks(devices)}
        </Collapse>
        <NavLink
          pl={18}
          icon={<MdOutlineSimCard size={20} />}
          label="Sim Cards"
          onClick={() => simCardOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={simCardOp.opened} transitionDuration={500}>
          {nestedLinks(simcards)}
        </Collapse>
        {user?.role === UserRole.Master && (
          <>
            <NavLink
              pl={18}
              icon={<MdOutlineCreditCard size={20} />}
              label="Rfids"
              onClick={() => rfidOp.setOpened((o) => !o)}
              rightSection={<TbChevronDown size={12} />}
            />
            <Collapse in={rfidOp.opened} transitionDuration={500}>
              {nestedLinks(rfids)}
            </Collapse>
          </>
        )}
        <NavLink
          pl={18}
          icon={<TbClipboardList size={20} />}
          label="Tasks"
          onClick={() => taskOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={taskOp.opened} transitionDuration={500}>
          {nestedLinks(tasks)}
        </Collapse>
        <NavLink
          pl={18}
          icon={<TbCar size={20} />}
          label="Vehicles"
          onClick={() => vehicleOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={vehicleOp.opened} transitionDuration={500}>
          {nestedLinks(vehicles)}
        </Collapse>
        <NavLink
          pl={18}
          icon={<TbDevicesCog size={20} />}
          label="Accessories"
          onClick={() => accessoryOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={accessoryOp.opened} transitionDuration={500}>
          {nestedLinks(acccessories)}
        </Collapse>
        <NavLink
          pl={18}
          icon={<TbTicket size={20} />}
          label="Tickets"
          onClick={() => ticketsOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={ticketsOp.opened} transitionDuration={500}>
          {nestedLinks(tickets)}
        </Collapse>
        <NavLink
          pl={18}
          icon={<TbBuilding size={20} />}
          label="Organization"
          onClick={() => organizationOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={organizationOp.opened} transitionDuration={500}>
          {nestedLinks(organizations)}
        </Collapse>
        <NavLink
          pl={18}
          icon={<TbTag size={20} />}
          label="Quotations"
          onClick={() => quotationOp.setOpened((o) => !o)}
          rightSection={<TbChevronDown size={12} />}
        />
        <Collapse in={quotationOp.opened} transitionDuration={500}>
          {nestedLinks(quotations)}
        </Collapse>
        {collectionLinks}
      </Navbar.Section>
    </Navbar>
  );
};

export default SideNav;
