import api from '../../common/api/api';
import { IUpdateUnitInput, Iunit } from '../types/types';

interface myResUnitPatch {
  data: Iunit;
}

export const patchUnit = (props: { id: number; data: Partial<IUpdateUnitInput> }): Promise<myResUnitPatch> => {
  return api.patch(`units/${props.id}`, props.data);
};

export const patchUnits = (data: Record<string, any>[]): Promise<myResUnitPatch[]> => {
  return api.patch('units/multi', data);
};
