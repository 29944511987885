import { Alert, Button, Center, Container, Group, List, LoadingOverlay, Paper, Stepper, Text, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useCallback, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePickerInputEditable, SelectEditable, TextInputEditable } from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { useAuthContext } from '../../../../context/AuthContext';
import { enumToSelectData } from '../../../../utils';
import { IaxiosError } from '../../../common';
import { DeviceStatus } from '../../../devices';
import { UserRole } from '../../../users';
import { patchTask, useTask } from '../../api';
import { TaskStatus } from '../../constants/constants';
import { Task, technicianTask } from '../../types/types';
import { DeviceChangeStepForm, SimChangeStepForm } from './steps';

enum TaskType {
  DeviceChange = 'device change',
  SimCardChange = 'simCard change',
  Other = 'other',
}

export function InspectionTaskForm() {
  const { id } = useParams();
  const [idN] = useState(id && !isNaN(+id) ? +id : 0);
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [task, setTask] = useState<Task>();
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const { data: taskData } = useTask(idN, {
    joins: ['unit', 'unit.device as device', 'unit.vehicle as vehicle', 'unit.simCard as simCard'],
    selects: [
      'id',
      'unit.id',
      'device.id',
      'device.imei',
      'vehicle.id',
      'vehicle.vin',
      'vehicle.plateNumber',
      'simCard.id',
      'simCard.number',
    ],
    then: (task) => {
      form.setValues({
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : new Date(),
      });
      setTask(task.data);
      remarksHandler(task.data.jobRemarks ? task.data.jobRemarks : '');
    },
  });

  const form = useForm<technicianTask>({
    initialValues: {
      taskType: '',
      deviceRemarks: '',
      VehicleRemarks: '',
      simCardRemarks: '',
      remarks: '',
      jobRemarks: '',
      simCard: { id: 0 },
      newUnit: 0,
      completionDate: new Date(),
      deviceStatus: DeviceStatus.Not_Active,

    },
    transformValues: (values) => {
      const remarks: string[] = [];
      values.deviceRemarks?.length && remarks.push(`<?deviceRemarks/:${values.deviceRemarks}/>`);
      values.VehicleRemarks?.length && remarks.push(`<?VehicleRemarks/:${values.VehicleRemarks}/>`);
      values.simCardRemarks?.length && remarks.push(`<?simCardRemarks/:${values.simCardRemarks}/>`);
      values.remarks?.length && remarks.push(`<?remarks/:${values.remarks}/>`);
      values.jobRemarks?.length && remarks.push(values.jobRemarks);
      return {
        ...values,
        jobRemarks: remarks.join(''),
      };
    },
  });

  const remarksHandler = useCallback(
    (value: string) => {
      value.split('/>').forEach((part) => {
        if (part.startsWith('<?')) {
          const temp = part.substring(2).split('/:');
          form.setFieldValue(temp[0], temp[1]);
        } else {
          form.setFieldValue('jobRemarks', part);
        }
      });
    },
    [form],
  );

  const { mutate: patchInpectionMutate, isLoading: patchLoading } = useMutation(patchTask, {
    onSuccess: () => {
      setAlert(null);
      setActive(2);
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
      if (user && user.role === UserRole.Technician) navigate('/');
      else navigate('/myTasks');
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
      setActive(2);
    },
  });

  function handleSubmit(values: technicianTask) {
    setAlert(null);
    if (task) {
      const newValues = { ...values, taskId: task.id, taskStatus: TaskStatus.Done };
      if (!newValues.jobRemarks) newValues.jobRemarks = null;
      if (newValues.taskType === TaskType.DeviceChange) {
        patchInpectionMutate({
          id: task.id,
          data: {
            status: TaskStatus.ToBeApproved,
            type: TaskType.DeviceChange,
            jobRemarks: newValues.jobRemarks,
            completionDate: newValues.completionDate,
            installationUnit: { id: newValues.newUnit },
          },
        });
      } else if (newValues.taskType === TaskType.SimCardChange) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { newUnit, ...rest } = newValues;
        patchInpectionMutate({
          id: task.id,
          data: {
            status: TaskStatus.ToBeApproved,
            performedBy: user ? { id: user?.id } : undefined,
            type: TaskType.SimCardChange,
            jobRemarks: newValues.jobRemarks,
            completionDate: newValues.completionDate,
            simCard: { id: newValues.simCard ? newValues.simCard?.id : 0 },
          },
        });
      } else {
        patchInpectionMutate({
          id: task.id,
          data: { status: TaskStatus.ToBeApproved, jobRemarks: newValues.jobRemarks, completionDate: newValues.completionDate },
        });
      }
    }
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Inspection Task Form:
        </Title>

        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos={'relative'}>
          {taskData?.data.unit && (
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Stepper active={active} onStepClick={setActive}>
                <Stepper.Step label="Inspection">
                  <SelectEditable label="Type" {...form.getInputProps('taskType')} data={enumToSelectData(TaskType)} />
                  <DatePickerInputEditable
                    placeholder="Search Date"
                    label="completion Date"
                    {...form.getInputProps('completionDate')}
                    required
                    mt="md"
                  />
                  <TextInputEditable placeholder="remarks..." mt="md" label="Device Remarks" {...form.getInputProps('deviceRemarks')} />
                  <TextInputEditable label="Vehicle Remarks" placeholder="remarks..." mt="md" {...form.getInputProps('VehicleRemarks')} />
                  <TextInputEditable label="Sim Card Remarks" placeholder="remarks..." mt="md" {...form.getInputProps('simCardRemarks')} />
                  <Textarea label="Unit Remarks" placeholder="remarks..." mt="md" {...form.getInputProps('remarks')} />
                </Stepper.Step>
                <Stepper.Step label={`Form`}>
                  {form.values.taskType === TaskType.DeviceChange && <DeviceChangeStepForm form={form} task={task} />}
                  {form.values.taskType === TaskType.SimCardChange && <SimChangeStepForm form={form} task={task} />}
                </Stepper.Step>
                <Stepper.Completed>
                  <LoadingOverlay visible={patchLoading} overlayBlur={2} />
                  {alert && (
                    <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
                      {Array.isArray(alert) ? (
                        <List>
                          {alert.map((errMsg, i) => (
                            <List.Item key={`E-${i}`}>{errMsg}!</List.Item>
                          ))}
                        </List>
                      ) : (
                        alert + '!'
                      )}
                    </Alert>
                  )}
                </Stepper.Completed>
              </Stepper>
              <Group position="center" mt="xl">
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
                {active < 1 && form.values.taskType !== TaskType.Other && (
                  <Button onClick={nextStep} disabled={!form.values.taskType}>
                    Next step
                  </Button>
                )}
                {(active === 1 || (active === 0 && form.values.taskType === TaskType.Other)) && <Button type="submit">Submit</Button>}
              </Group>
            </form>
          )}
          {task && !task.unit && (
            <Center>
              <Text> Please Contact Techinical Support To Add Unit For Task</Text>
            </Center>
          )}
        </Paper>
      </Container>
    </Layout>
  );
}
