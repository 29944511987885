  
  import Layout from '../../../components/layout/Layout';
import { NewDeviceForm } from '../components/NewDeviceForm';
  
  export function NewDeviceFormPage() {
    return (
      <Layout>
        <NewDeviceForm/>
      </Layout>
    );
  }
  