import api from "../../common/api/api";
import { IuserProfile, IuserProfileInput } from "../types/types";

interface myResUserProfilePost {
	data: IuserProfile;
}

export const postUserProfile = (data: IuserProfileInput): Promise<myResUserProfilePost> => {
	return api.post(`user-profiles`, data);
};
