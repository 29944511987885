import { Alert, Button, Container, List, LoadingOverlay, Paper, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DatePickerInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, removeNotEdited, selectColumns } from '../../../utils';
import { AccessoryTypeColumnsMode, useAccessoriesType } from '../../accessoriesType';
import { IaxiosError } from '../../common';
import { patchAccessory, useAccessory } from '../api';
import { AccessoryColumnsMode } from '../constants';
import { IAccessoriesInput } from '../types';

export function UpdateAccessoryForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();

  const [accessoryTypesSelect, setAccessoryTypesSelect] = useState([{ value: '', label: 'loading...' }]);

  const params = new URLParams();
  params.filterSetField(AccessoryTypeColumnsMode.HasAccessory, 1);
  params.select(AccessoryTypeColumnsMode.ID, AccessoryTypeColumnsMode.Name);
  const { data: allAccessoryTypes } = useAccessoriesType({ params: params.toString() });
  useEffect(() => {
    if (allAccessoryTypes) {
      const tempArr = allAccessoryTypes.data.items.map((type) => {
        return { value: `${type.id}`, label: `${type.name}` };
      });
      setAccessoryTypesSelect(tempArr);
    }
  }, [allAccessoryTypes]);

  const { data: accessoriesData } = useAccessory(idN, {
    joins: ['accessoriesType'],
    selects: [...selectColumns(AccessoryColumnsMode), 'accessoriesType.id'],
    then: (accessory) => {
      form.setValues({
        serialNo: accessory.data.serialNo,
        purchaseDate: new Date(accessory.data.purchaseDate),
        accessoriesType: { id: accessory.data.accessoriesType.id },
      });
      form.resetDirty({
        serialNo: accessory.data.serialNo,
        purchaseDate: new Date(accessory.data.purchaseDate),
        accessoriesType: { id: accessory.data.accessoriesType.id },
      });
    },
  });

  const form = useForm<IAccessoriesInput>({
    initialValues: {
      serialNo: accessoriesData?.data.serialNo || '',
      purchaseDate: accessoriesData?.data.purchaseDate ? new Date(accessoriesData?.data.purchaseDate) : new Date(),
      accessoriesType: accessoriesData?.data.accessoriesType.id ? { id: accessoriesData?.data.accessoriesType.id } : { id: 0 },
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchAccessory, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Accessories, types.ALL]);
      queryClient.invalidateQueries([types.Accessories, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.Accessories, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IAccessoriesInput) {
    setAlert(null);
    const newValues = removeNotEdited(values, form);
    if (idN !== 0 && !Number.isNaN(idN)) {
      patchMutate({ id: idN, data: newValues });
    } else {
      setAlert(['The id provided is incorrect']);
    }
  }

  return (
    <Container pt={10}>
      <Title
        order={2}
        pl={5}
        align="left"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 800,
        })}
      >
        Edit a Accessory :
      </Title>
      <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
        <LoadingOverlay visible={patchLoading} overlayBlur={2} />
        {alert && (
          <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
            {Array.isArray(alert) ? (
              <List>
                {alert.map((errMsg) => (
                  <List.Item>{errMsg}!</List.Item>
                ))}
              </List>
            ) : (
              alert + '!'
            )}
          </Alert>
        )}
        {patchSuccess && (
          <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
            Successfully updated in the database!
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInputEditable
            dirty={form.isDirty('serialNo')}
            label="serial number of Accessory"
            placeholder="serialNo"
            {...form.getInputProps('serialNo')}
            required
          />

          <DatePickerInputEditable
            placeholder="Search Date"
            label="Purchase Date"
            {...form.getInputProps('purchaseDate')}
            dirty={form.isDirty('purchaseDate')}
            clearable
            mt="md"
          />

          <SelectEditable
            label="Accessory Type"
            dirty={form.isDirty('accessoriesType.id')}
            placeholder="Search Here"
            onChange={(v) => {
              form.setFieldValue('accessoriesType.id', parseInt(v || '0'));
            }}
            searchable
            nothingFound="Not found"
            data={accessoryTypesSelect}
            value={`${form.values.accessoriesType.id}`}
            required
            mt="md"
          />

          <Button fullWidth mt="xl" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
