import { ActionIcon, Grid, Group, Modal, Paper, ScrollArea, Stack, Text, Tooltip, createStyles, rem } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { TbEye } from "react-icons/tb";

const useStyles = createStyles((theme) => ({
    stat: {
      minWidth: rem(98),
      paddingTop: theme.spacing.xl,
      minHeight: rem(50),
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: theme.white,
    },
  
    label: {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: theme.fontSizes.xs,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      color: theme.colors.gray[6],
    },
  
    value: {
      fontSize: theme.fontSizes.sm,
      fontWeight: 700,
    },
  
    count: {
      color: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    },  
  }));
export function DevicesCount({title, value}: {title:string, value: Record<string, string | number>[]}) {
    const { classes } = useStyles();
    const [opened, { open, close }] = useDisclosure(false);
    const screenSize = useMediaQuery('(min-width: 56.25em)'); 
    
    const devices = value.map((type, index) => {
        return(
            <Grid.Col span={screenSize ? 4 : 6} key={index}>
                <Stack spacing={3}>
                    <Text className={classes.label}>{type.deviceType_type}:</Text>
                    <Text fz="xs" className={classes.count} fw='bolder'>{type.count}</Text>
                </Stack>
            </Grid.Col>
        )
      })
      const totalCount = value.reduce((acc, value) => acc + +value.count, 0)

    return(
        <Paper className={classes.stat} radius="md" shadow="md" p="xs">
          <Group>
            <Text className={classes.label}>{title} ({totalCount})</Text>
            <Tooltip label="View Details"><ActionIcon variant="subtle" onClick={open} ><TbEye color={'black'} size="1rem" /></ActionIcon></Tooltip>
          </Group>
          <Modal opened={opened} onClose={close} title={<Text tt='uppercase'>{title}</Text>} centered>
            <ScrollArea style={{ height: 100 }}>
                <Grid m={0}>
                    {devices}
                </Grid>
            </ScrollArea>
          </Modal>
        </Paper>
      )
}