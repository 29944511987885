import { Alert, Button, Container, Group, List, LoadingOverlay, Paper, Select, Switch, Text, Title, useMantineColorScheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { TbAlertOctagon } from "react-icons/tb";
import { useMutation } from "react-query";
import Layout from "../../../components/layout/Layout";
import { useAuthContext } from "../../../context/AuthContext";
import { enumToSelectData } from "../../../utils";
import { IaxiosError, PageSize } from "../../common";
import { useSetting } from "../api/getSetting";
import { postSetting } from "../api/postSetting";
import { UserLocation } from "../constants/constants";
import { IsettingsInput } from "../types/types";


export function UserSettings() {
  const { user } = useAuthContext();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [checked, setChecked] = useState(false);
  const [alert, setAlert] = useState<string | string[] | null>(null);

  const { data: setting } = useSetting({
    then: (setting) => {
      form.setValues({
        theme: setting.theme,
        cardsPerPage: setting.cardsPerPage,
        Location: setting.Location,
      });
      form.resetDirty();
    },
  });

  const form = useForm<IsettingsInput>({
    initialValues: {
      theme: setting?.theme || colorScheme,
      cardsPerPage: setting?.cardsPerPage || '10',
      Location: setting?.Location || 'office',
    },
  });
  useEffect(() => {
    if (colorScheme === 'dark') {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [colorScheme]);

  const {
    mutate: newMutate,
    isLoading: patchLoading,
  } = useMutation(postSetting, {
    onSuccess: () => {
      setAlert(null);
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IsettingsInput) {
    values.theme = colorScheme
    newMutate(values)
  }

  if (user?.role !== 'master') return null;

  //TODO: WTH is this? fix
  return (
    <Layout>
      <Title order={1}>Settings: </Title>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Container size='md' pt={30}>
          <Paper p={30} pt={0} withBorder>
            <LoadingOverlay visible={patchLoading} overlayBlur={2} />
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mt={10}

              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
            <Group position="apart" pt={20}>
              <Text mt='lg'>Theme: </Text>
              <Switch onLabel={<FaSun size={20} />} offLabel={<FaMoon size={20} />}
                onClick={() => {
                  toggleColorScheme()
                }}
                checked={checked}
                size="lg"
                pt={25}
              />
            </Group>

            <Group position="apart" grow pt={20}>
              <Text>Cards Per Page: </Text>
              <Select
                placeholder="10, 20, 30, 40, 50"
                {...form.getInputProps('cardsPerPage')}
                data={Object.values(PageSize)}
              />
            </Group>

            <Group position="apart" grow pt={20}>
              <Text>Location: </Text>
              <Select
                placeholder="Office, Car 1, Car 2"
                {...form.getInputProps('Location')}
                data={enumToSelectData(UserLocation)}
              />
            </Group>

            <Button fullWidth mt="xl" type="submit">
              Save
            </Button>
          </Paper>
        </Container>
      </form>
    </Layout>
  )
}