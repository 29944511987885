import { Button, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import { SelectEditable } from "../../../components/form";
import { types } from "../../../constants";
import { useAuthContext } from "../../../context/AuthContext";
import { enumToSelectData, noEmptyString } from "../../../utils";
import { patchQuotation } from "../api";
import { QuotationStatus } from "../constants/constants";

export function QuotationStatusUpdateModal({id, close, setIds,  setRowSelection}: {id: number, close: () => void, setIds?: React.Dispatch<React.SetStateAction<number[]>>,
  setRowSelection?: React.Dispatch<React.SetStateAction<{}>>

}){
    const { user } = useAuthContext();
    const queryClient = useQueryClient();
    const {
        mutate: patchMutate,
        isLoading: patchLoading,
    } = useMutation(patchQuotation, {
      onSuccess: (data) => {
        notifications.show({
          title: 'Success',
          message: `status for quotation with file name ${data.data.name} correctly Updated`,
          color: 'green',
          autoClose: 10000,
        })
        setIds && setIds([])
        setRowSelection && setRowSelection({})
        close()

        queryClient.invalidateQueries([types.Quotation, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
        });
        },
        onError: () => {
          notifications.show({
            title: 'Error',
            message: `Quotation was not Updated`,
            color: 'red',
            autoClose: 10000,
          })
        },
    });
    
    const form = useForm({
        initialValues: {
            status: '',
        }
    })

    function handleSubmit(value: {status: string}){
        const newValues = noEmptyString(value)
        if(user){
            if(newValues.status){
                patchMutate({id: id, data: {...newValues}})
            }else{
                form.setFieldError('status', 'please select the new status')
            }
        }
    }
    return(
        <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
            <LoadingOverlay visible={patchLoading} overlayBlur={2} />
            <SelectEditable
              label="Status"
              placeholder="Search here"
              {...form.getInputProps('status')}
              data={enumToSelectData(QuotationStatus)}
              required
              mt="md"
              withinPortal
            />
          <Button type='submit' fullWidth mt='md'>Confirm</Button>
        </form>
    )
}