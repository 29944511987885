import { Alert, Button, Checkbox, Container, List, LoadingOverlay, Paper, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { IaxiosError } from '../../common';
import { patchRfid, useRfid } from '../api';
import { IrfidInput } from '../types/types';

export function UpdateRfidForm() {
  const { id } = useParams();
  const [idN] = useState(+(id || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();

  const { data: rfidData } = useRfid(idN, {
    then: (rfid) => {
      form.setValues({
        printedValue: rfid.data.printedValue,
        bceValue: rfid.data.bceValue,
        teltonikaValue: rfid.data.teltonikaValue,
        isUsed: rfid.data.isUsed,
      });
    },
  });

  const form = useForm({
    initialValues: {
      printedValue: rfidData?.data.printedValue || '',
      bceValue: rfidData?.data.bceValue || '',
      teltonikaValue: rfidData?.data.teltonikaValue || '',
      isUsed: false,
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchRfid, {
    onSuccess: (data) => {
      setAlert(null);
      queryClient.invalidateQueries([types.RFID, types.ALL]);
      queryClient.invalidateQueries([types.RFID, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.RFID, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IrfidInput) {
    setAlert(null);
    if (idN !== 0 && !Number.isNaN(idN)) {
      patchMutate({ id: idN, data: values });
    } else {
      setAlert(['The id provided is incorrect']);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit a Rfid:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {patchSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              dirty={form.isDirty('printedValue')}
              label="Printed Value"
              placeholder="Printed Value"
              {...form.getInputProps('printedValue')}
              required
            />
            <TextInputEditable
              dirty={form.isDirty('bceValue')}
              label="bce Value"
              placeholder="bce Value"
              {...form.getInputProps('bceValue')}
              required
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('teltonikaValue')}
              label="Teltonika Value"
              placeholder="Teltonika Value"
              {...form.getInputProps('teltonikaValue')}
              required
              mt="md"
            />
            <Checkbox label="Used" {...form.getInputProps('isUsed', { type: 'checkbox' })} mt="md" />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
}
