import { Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TaskCard } from '../../../components/task/taskCard/TaskCard';
import { useAuthContext } from '../../../context/AuthContext';
import { URLParams, selectColumns } from '../../../utils';
import { useTasks } from '../api';
import { TaskColumnsMode } from '../constants';
import { Task } from '../types/types';

export function GroupTasks() {
  const { user } = useAuthContext();
  const [data, setData] = useState<Task[]>([]);
  const params = new URLParams();

  if (user) {
    params.filterSetField('status', 'pending');
    params.filterSetField('recipientGroup', user.role);
  }
  params.includes('unit', 'vehicle', 'organization', 'unit.device as device', 'unit.vehicle as unitvehicle');
  params.sort('-task.id');
  params.select(
    ...selectColumns(TaskColumnsMode),
    'vehicle.id',
    'vehicle.vin',
    'vehicle.plateNumber',
    'organization.id',
    'organization.name',
    'unit.id',
    'device.id',
    'device.imei',
    'unitvehicle.id',
    'unitvehicle.vin',
    'unitvehicle.plateNumber',
  );
  const { data: allTasks } = useTasks({ params: params.toString() });

  useEffect(() => {
    if (allTasks) {
      setData(allTasks.data.items);
    }
  }, [allTasks]);

  const tasks = data
    .map((task) => (
      <TaskCard
        key={task.id}
        id={task.id}
        title={task.title}
        type={task.type}
        client={task.organization}
        address={task.address}
        location={task.location}
        contactName={task.contactName}
        contactNumber={task.contactNumber}
        vehicle={task.vehicle}
        unit={task.unit}
        remarks={task.taskRemarks}
      />
    ));

  return <>{tasks.length ? tasks : <Text>No Tasks</Text>}</>;
}
