import api from "../../common/api/api";
import { IvehicleType, IvehicleTypeInput } from "../types/types";

interface myResVehicleTypePost {
	data: IvehicleType;
}

export const postVehicleType = (data: IvehicleTypeInput): Promise<myResVehicleTypePost> => {
	return api.post(`vehicle-types`, data);
};

export const postVehicleTypes = (data: Record<string, any>[]): Promise<myResVehicleTypePost[]> => {
	return api.post(`vehicle-types/multi`, data);
};