import {
  ActionIcon,
  Alert,
  Button,
  Code,
  Container,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Table,
  TextInput,
  Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  TbAlertOctagon,
  TbAlertTriangle,
  TbCircleCheck,
  TbDownload,
  TbPlus
} from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
  
  import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { noEmptyString, URLParams, useCsv } from '../../../utils';
import { removeNullElement } from '../../../utils/removeNullElements';
import { IaxiosError } from '../../common';
import { Idevice, useDevices } from '../../devices';
import { IsimCard, useSimCards } from '../../sim-cards';
import { Ivehicle, useVehicles } from '../../vehicles';
import { patchUnits, useUnits } from '../api';
import { IfullUnit } from '../types/types';
  
  
  export function EditMultipleUnitsCSV() {
    const [file, setFile] = useState<File | null>(null);
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const [units,setUnits] = useState<IfullUnit[]>([])
    const [devices,setDevices] = useState<Idevice[]>([])
    const [vehicles,setVehicles] = useState<Ivehicle[]>([])
    const [sims,setSims] = useState<IsimCard[]>([])
    const queryClient = useQueryClient();
    const viewSubmit = document.getElementById('submit')
    const viewTopOfPage = document.getElementById('TopOfPage')
  
    const requirments = {
      id: /^\d+$/,
      device: /^$|^[0-9]+$/,
      simCard: /^$|^[0-9]+$/,
      vehicle: /^$|[A-Za-z0-9]$/,
      deviceConfig: /^$|^\d+$/,
      platform:
        /^$|\b(wialon|asateel|securepath|securepath premuim|softwareag|aiflux|custom)\b/,
      secondaryPlatform:
        /^$|\b(wialon|asateel|securepath|securepath premuim|softwareag|aiflux|custom)\b/,
      externalId: /^$|^[a-zA-Z0-9]*$/,
      rfidList: /^$|^\d+$/,
      remarks: /^$|^[\s\S]*$/,
    };
  
  
    const unitParams = new URLParams();
    unitParams.includes(
    'deviceConfig',
    'device',
    'simCard',
    'vehicle',
    );
    unitParams.select('id', 'vehicle.id', 'vehicle.vin', 'device.id', 'device.imei', 'simCard.id', 'simCard.number', 'deviceConfig.id')
    const { data: unitData } = useUnits({params: unitParams.toString()});
    useEffect(() => {
      if(unitData) setUnits(unitData.data.items)
    },[unitData])
  
    const deviceParams = new URLParams();
    deviceParams.includes('deviceType', 'unit');
    const { data: deviceData } = useDevices({ params: deviceParams.toString() });
    useEffect(() => {
      if(deviceData) setDevices(deviceData.data.items)
    },[deviceData])
  
    const simCardParams = new URLParams();
    simCardParams.includes('simCardType', 'unit');
    const { data: simCardsData } = useSimCards({params: simCardParams.toString()});
    useEffect(() => {
      if(simCardsData) setSims(simCardsData.data.items)
    },[simCardsData])
  
    const { data: vehiclesData } = useVehicles()
    useEffect(() => {
      if(vehiclesData) setVehicles(vehiclesData.data.items)
    },[vehiclesData])
  
  
    const templateText = `${Object.keys(requirments).join(',')}\r\n`;
    const { csvParser, data, headers, errorRows, validate, setItem, addRow } =
      useCsv(requirments, Object.keys(requirments));
  
    function handleSelectFile(value: File | null) {
      setFile(value);
      if (value) {
        csvParser(value);
      }
    }

    useEffect(() => {
      if(errorRows.length === 0){
        viewSubmit?.scrollIntoView()
      }
    }, [data])

    const {
      mutate: patchMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(patchUnits, {
      onSuccess: () => {
        setAlert(null);
        queryClient.invalidateQueries([types.Unit, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });
  
    function handleSubmit() {
      const newData: Record<string, any>[] = JSON.parse(JSON.stringify(data));
      viewTopOfPage?.scrollIntoView();

      const keys = ['deviceConfig','platform', 'secondaryPlatform', 'externalId', 'rfidList'];
  
  
      newData.forEach((item) => {
        let obj = {};
            keys.forEach((key) => {
              obj = Object.assign(obj, { [key]: item[key] });
              delete item[key];
            });
            item = Object.assign(item, { deviceConfig: obj });
        
      });
  
      if (newData) {
        setAlert(null);
  
          const tempAlert:string[] = []
          newData.forEach((item) => {
  
            ['device','simCard','vehicle'].forEach((key) => {
              if(key === 'device') {
                if(units.some((unit) => unit.device?.imei === item[key]))tempAlert.push('device with imei ' + item[key] + ' alraedy exist')
                else if(item[key] && item[key] !== '0'){
                    if(!devices.some((device) => {
                      if(device.imei === item[key]){
                        item[key] = `${device.id}`
                        return true
                      }
                    }))tempAlert.push('device with imei ' + item[key] + ' does not exist in the database')
                }
              }
              if(key === 'simCard') {
                if(units.some((unit) => unit.simCard?.number === item[key]))tempAlert.push('simCard with number ' + item[key] + ' alraedy exist')
                if(item[key] && item[key] !== '0'){
                    if(!sims.some((sim) => {
                      if(sim.number === item[key]){
                        item[key] = `${sim.id}`
                        return true
                      }
                    }))tempAlert.push('sim with number ' + item[key] + ' does not exist in the database')
                }
              }
              if(key === 'vehicle') {
                if(item[key] && item[key] !== '0'){
                    if(!vehicles.some((vehicle) => {
                        if(vehicle.vin === item[key]){
                        item[key] = `${vehicle.id}`
                        return true
                        }
                    }))tempAlert.push('vehicle with vin ' + item[key] + ' does not exist in the database')
                }
              }
            })
          })
          if(tempAlert.length === 0){
            const newValues = newData.map((item) => noEmptyString(item)).map((item) => removeNullElement(item))
            newValues.forEach((item) => {
                if(item.vehicle && item.vehicle === '0') item.vehicle = null
                if(item.simCard && item.simCard === '0') item.simCard = null
                if(item.device && item.device === '0') item.device = null
                if(item.deviceConfig && item.deviceConfig.deviceConfig){
                  item.deviceConfig.id = +item.deviceConfig.deviceConfig
                  delete item.deviceConfig.deviceConfig
                }else if(item.deviceConfig) delete item.deviceConfig
            })
            patchMutate(newValues);
          }else setAlert(tempAlert)
      } else {
        setAlert(
          'something went wrong while preparing the data, please reload the page and try again.',
        );
      }
    }
  
  
    const rows = data?.map((row, i) => (
      <tr
        key={'r' + i}
      >
        {headers.map((key, index) => (
          <td key={key + index}>
            <TextInput
              value={row[key]}
              error={errorRows[index]?.includes(i + 1)}
              variant="unstyled"
              onChange={(v) => setItem(i, key, v.target.value)}
            />
          </td>
        ))}
      </tr>
    ));
  
    return (
      <Layout>
        <Container pt={10} fluid>
          <Title
            order={2}
            pl={5}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Edit Multiple Units:
          </Title>
          <Paper withBorder shadow="md" p={30} my={30} radius="md">
            <Group position="apart">
              <UploadCSVButton file={file} onChange={handleSelectFile}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload CSV
                  </Button>
                )}
              </UploadCSVButton>
              <Button
                mt="md"
                component="a"
                href={`data:text/csv;charset=utf-8,${templateText}`}
                download={'newUnitsTemplate.csv'}
                leftIcon={<TbDownload size={16} />}
              >
                Download a template
              </Button>
            </Group>
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Important!"
              withCloseButton
              color="blue"
              mt="md"
            >
              <List id='TopOfPage'>
                <List.Item>
                  Currently only accepts CSV files, file must include the headers. All values is optional except for ID.
                </List.Item>
                <List.Item>
                  Note: if you are trying to update any of the values <Code>platform</Code> or <Code>secondaryPlatform</Code>, <Code>externalId</Code>,<Code>rfidList</Code> you will have to add <Code>deviceConfig</Code>.
                </List.Item>
                <List.Item>id: unit id from the system.</List.Item>
                <List.Item>
                  device: optional, use imei.
                </List.Item>
                <List.Item>
                  simCard: optional, use sim number.
                </List.Item>
                <List.Item>
                  vehicle: optional, use vin/chassis.
                </List.Item>
                <List.Item>deviceConfig: deviceConfig id from the system.</List.Item>
                <List.Item>
                  platform: optional, it have one of the following values: (<Code>wialon</Code>,{' '}
                  <Code>asateel</Code>, <Code>securepath</Code>,{' '}
                  <Code>securepath premuim</Code> <Code>softwareag</Code>,{' '}
                  <Code>aiflux</Code>, <Code>custom</Code>).
                </List.Item>
                <List.Item>
                  secondaryPlatform: optional, could have one of the following values: (<Code>wialon</Code>,{' '}
                  <Code>asateel</Code>, <Code>securepath</Code>,{' '}
                  <Code>securepath premuim</Code> <Code>softwareag</Code>,{' '}
                  <Code>aiflux</Code>, <Code>custom</Code>).
                </List.Item>
                <List.Item>
                  externalId: optional.
                </List.Item>
                <List.Item>rfidList: must be a number.</List.Item>
                <List.Item>remarks: can be empty.</List.Item>
              </List>
            </Alert>
            {data && Object.keys(requirments).length !== headers.length && (
              <Alert
                icon={<TbAlertTriangle size={16} />}
                title="Warning!"
                color="orange"
                mt="md"
              >
                The number of column uploaded is different than expected!
              </Alert>
            )}
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
            {validate() && (
              <Alert
                icon={<TbAlertTriangle size={16} />}
                title="Error!"
                color="red"
                mt="md"
              >
                <List>
                  <List.Item>Errors in the following rows:</List.Item>
                  {errorRows.map((errorRow, i) => (
                    <List.Item key={`error-${headers[i]}`}>
                      {headers[i]}: {errorRow.join(', ')}
                    </List.Item>
                  ))}
                </List>
              </Alert>
            )}
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
                mt="md"
              >
                Successfully added to the database!
              </Alert>
            )}
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                mt="md"
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item key={errMsg}>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
            {data && (
              <Table mt="md">
                <thead>
                  <tr>
                    {headers.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            )}
            <Group position="right" mt="md">
              <ActionIcon size="lg" variant="subtle">
                <TbPlus size={28} onClick={() => addRow()} />
              </ActionIcon>
            </Group>
            <Button
              id="submit"
              fullWidth
              disabled={(!data && !file) || validate() || newLoading}
              onClick={handleSubmit}
              mt="md"
            >
              Submit
            </Button>
          </Paper>
        </Container>
      </Layout>
    );
  }
  