import { ActionIcon, Table, Tooltip } from "@mantine/core";
import { TbPlus } from "react-icons/tb";
import { NumberInputEditable, TextInputEditable } from "../../../components/form";
import { RichTextEditorComponent } from "../../../components/richTextEditor/RichTextEditorComponent";
import { PresetsType } from "../../preSets";
import { IquotationContentInput, IquotationPlanInput } from "../types/types";

export function NewQuotationContentForm(
    {
        values,
        insertContent,
        setValues,
    }:
    {
        values: IquotationPlanInput,
        insertContent:  (data: Record<string, any>) => void,
        setValues:  (data: string | number, column: string) => void,
    }){
        const listContents: IquotationContentInput = {
            description: '',
            price: 0,
            quantity: 0,
        }

        const contents = values.quotationContent.map((item, index) => {
            return (
                <tr key={item.description + index}>
                    <td>{index + 1}</td>
                    <td>
                        <RichTextEditorComponent value={item.description} setValue={(data) => setValues(data, `quotationContent.${index}.description`)} submittable={true} withPreset={PresetsType.Content}/>
                    </td>
                    <td>
                        <NumberInputEditable
                            placeholder="quantity"
                            value={item.quantity}
                            onChange={(v) => v && setValues(v, `quotationContent.${index}.quantity`)}
                            required
                            hideControls
                        />
                    </td>
                    <td>
                        <NumberInputEditable
                            placeholder="price"
                            value={item.price}
                            onChange={(v) => v && setValues(v, `quotationContent.${index}.price`)}
                            required
                            precision={2}
                            hideControls
                        />
                    </td>
                    <td>{item.price * item.quantity}</td>
                    <td></td>
                </tr>
            )
        })
        return(
            <>
                <RichTextEditorComponent label="massage" value={values.massage} setValue={(data) => setValues(data, 'massage')} withPreset={PresetsType.Massages}/>
                <TextInputEditable label={'Title'} mt='sm' onChange={(v) => v && setValues(v.currentTarget.value, 'title')} value={values.title ? values.title : ''} />
                <Table>
                    <thead>
                        <tr>
                            <th>item</th>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                            <th><Tooltip label={'add item'}><ActionIcon onClick={() => insertContent(listContents)}><TbPlus /></ActionIcon></Tooltip></th>
                        </tr>
                    </thead>
                    <tbody>
                        {contents}
                    </tbody>
                </Table>
            </>
        )
}