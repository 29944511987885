import api from "../../common/api/api";
import { Iuser, IuserInput } from "../types/types";

interface myResUserPatch {
	data: Iuser;
}

export const patchUser = (props: { id: number; data: Partial<IuserInput> }): Promise<myResUserPatch> => {
	return api.patch(`users/${props.id}`, props.data);
};
