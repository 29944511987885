import { Badge, Checkbox, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { ExportUsers } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types, userStatusColor } from '../../../constants';
import { URLParams, setTableParams } from '../../../utils';
import { commonConstants } from '../../common';
import { useUsers } from '../api';
import { UserCard } from '../components';
import { UserDepartment, UserRole, UserStatus } from '../constants';

interface IuserNew {
  id: number;
  username: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  status: string;
  profiles:
    | {
        id: number;
        role: string;
        app: string;
        department: string;
        organization: {
          id: number;
          name: string;
        };
        phone: string;
        status: string;
      }
    | undefined;
}

export function UserTable() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<IuserNew[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [ids, setIds] = useState<number[]>([]);
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes('profiles', 'profiles.organization as org');
    param.sort('-user.id');
    param.page(10, page - 1);
    return param;
  });
  const { data: allUsers, refetch, isLoading } = useUsers({ params: params.toString() });

  useEffect(() => {
    if (allUsers) {
      const tempData = allUsers.data.items.map((user) => {
        return { ...user, profiles: user.profiles.find((profile) => profile.app === commonConstants.AiosApp) };
      });
      setData(tempData);
      const selectedRows = allUsers.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allUsers]);

  const columnHelper = useMemo(() => createColumnHelper<IuserNew>(), []);
  const columns: ColumnDef<IuserNew, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allUsers?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allUsers?.data.meta.ids ? allUsers?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => `${row.id}`, { header: 'ID', id: 'id' }),
      columnHelper.accessor('username', { header: () => 'Username', id: 'username' }),
      columnHelper.accessor('email', { header: () => 'E-mail', id: 'email' }),
      columnHelper.accessor('firstName', { header: () => 'First Name', id: 'firstName' }),
      columnHelper.accessor('lastName', { header: () => 'Last Name', id: 'lastName' }),
      columnHelper.accessor('status', {
        header: 'Status',
        id: 'status',
        cell: (props) => {
          if (props.getValue())
            return (
              <Badge
                variant="light"
                c={userStatusColor[props.getValue().split(' ').join('_') as keyof typeof userStatusColor]}
                styles={{
                  root: { backgroundColor: `${userStatusColor[props.getValue().split(' ').join('_') as keyof typeof userStatusColor]}40` },
                }}
              >
                {props.getValue()}
              </Badge>
            );
        },
      }),
      columnHelper.accessor((row) => row.profiles?.id, { header: 'Profile id', id: 'profiles.id' }),
      columnHelper.accessor((row) => row.profiles?.role, { header: 'Role', id: 'profiles.role' }),
      columnHelper.accessor((row) => row.profiles?.department, { header: 'Department', id: 'profiles.department' }),
      columnHelper.accessor((row) => row.profiles?.organization.name, { header: 'Organization', id: 'org.name' }),
      columnHelper.accessor((row) => row.profiles?.phone, { header: 'Phone', id: 'profiles.phone' }),
      columnHelper.accessor((row) => row.profiles?.status, { header: 'Profile Status', id: 'profiles.status' }),
    ],
    [columnHelper, data, ids],
  );

  const toSelect = {
    Status: UserStatus,
    Role: UserRole,
    Department: UserDepartment,
    'Profile Status': UserStatus,
  };

  return (
    <Layout>
      <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
        Users:
      </Title>
      <BaseTable<IuserNew>
        data={data}
        loading={isLoading}
        csvExport={<ExportUsers ids={ids} />}
        toSelect={toSelect}
        page={page}
        ids={ids}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'Active')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        pages={allUsers?.data.meta.ids.length ? Math.ceil(allUsers?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        columns={columns}
        setIds={setIds}
        defaultVisibleColumns={{ 'profiles.id': false }}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
      />
      <SideBar id={ids} type={types.User}>
        <UserCard id={ids[0]} />
      </SideBar>
    </Layout>
  );
}
