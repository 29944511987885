import { ActionIcon, Badge, Checkbox, Group, Modal, Text, Title, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { TbEdit, TbEye } from 'react-icons/tb';
import { ExportRecords } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, delivaryNote, selectColumns, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { DeliveryNoteColumnsMode } from '../../deliveryNote';
import { TaskType, TaskTypeColor } from '../../tasks';
import { DevicePlatforms, PlatformColor } from '../../unit';
import { useRecords } from '../api';
import { RecordCard } from '../components';
import { UpdateRecordModal } from '../forms/UpdateRecordModal';
import { Irecord } from '../types/types';

export function RecordTable() {
  const [page, setPage] = useState(1);
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState<Irecord[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const [opened, { open, close }] = useDisclosure(false);

  const customActions = [
    { id: 'update', multi: false, title: 'Update Record', icon: TbEdit, color: 'orange', handler: open, noDisplay: [] },
  ];
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.sort('-task.completionDate');
    param.includes(
      'vehicle',
      'vehicle.vehicleType as vehicleType',
      'vehicle.organization as vehicleOrg',
      'vehicleOrg.parent as orgParent',
      'newDevice',
      'newDevice.deviceType as newDeviceType',
      'oldDevice',
      'oldDevice.deviceType as oldDeviceType',
      'newSimCard',
      'oldSimCard',
      'task',
      'task.performedBy as userPerformed',
      'task.approvedBy as userApproved',
      'task.deliveryNote as deliveryNote',
    );
    param.select('id', 'platform', 'secondaryPlatform', 'vehicle.id', 'vehicle.vin', 'vehicle.plateNumber', 'vehicle.odometerKM', 'vehicle.odometerHours', 
    'vehicle.color', 'vehicleType.brand', 'vehicleType.model', 'vehicleType.year', 'vehicleOrg.id', 'vehicleOrg.name', 'orgParent.id', 'orgParent.name',
    'newDevice.id', 'newDevice.imei','newDevice.ownership', 'newDeviceType.id', 'newDeviceType.type', 
    'oldDevice.id', 'oldDevice.imei', 'oldDevice.ownership', 'oldDeviceType.id', 'oldDeviceType.type',
    'newSimCard.id', 'newSimCard.number', 'oldSimCard.id', 'oldSimCard.number', 'task.id', 'task.jobRemarks', 'task.type', 'task.completionDate',
    'userPerformed.id', 'userPerformed.firstName', 'userPerformed.lastName', 'userApproved.id', 'userApproved.firstName', 'userApproved.lastName',
    ...selectColumns(DeliveryNoteColumnsMode, { join: 'deliveryNote' }),)
    param.page(10, page - 1);
    return param;
  });

  const { data: allRecords, refetch, isLoading } = useRecords({ params: params.toString() });

  useEffect(() => {
    if (allRecords) {
      setData(allRecords.data.items);
      const selectedRows = allRecords.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allRecords]);

  const columnHelper = useMemo(() => createColumnHelper<Irecord>(), []);
  const columns: ColumnDef<Irecord, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allRecords?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allRecords?.data.meta.ids ? allRecords?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => `${row.id}`, { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.plateNumber : 0), { header: 'Plate Number', id: 'vehicle.plateNumber' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vin : ''), { header: 'Vin', id: 'vehicle.vin' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vehicleType.type : ''), { header: 'Vehicle Type', id: 'vehicleType.type' }),
      columnHelper.accessor((row) => row.vehicle?.organization?.name ?? '', { header: 'organization', id: 'vehicleOrg.name' }),
      columnHelper.accessor((row) => row.vehicle?.organization?.parent?.name ?? '', { header: 'parent', id: 'orgParent.name' }),
      columnHelper.accessor((row) => (row.oldDevice ? row.oldDevice.imei : ''), { header: 'old imei', id: 'oldDevice.imei' }),
      columnHelper.accessor((row) => (row.oldDevice ? row.oldDevice.ownership : ''), { header: 'old imei', id: 'oldDevice.ownership' }),
      columnHelper.accessor((row) => (row.newDevice ? row.newDevice.deviceType.type : ''), { header: 'new device Type', id: 'newDeviceType.type' }),
      columnHelper.accessor((row) => (row.newDevice ? row.newDevice.imei : ''), { header: 'new imei', id: 'newDevice.imei' }),
      columnHelper.accessor((row) => (row.newDevice ? row.newDevice.ownership : ''), { header: 'new imei', id: 'newDevice.ownership' }),
      columnHelper.accessor((row) => (row.oldDevice ? row.oldDevice.deviceType.type : ''), { header: 'old device Type', id: 'oldDeviceType.type' }),
      columnHelper.accessor(
        (row) =>
          `${row.newDevice ? `${row.newDevice.imei}` : 'No New Device'} <- ${row.oldDevice ? `${row.oldDevice.imei}` : 'No Old Device'}`,
        { header: 'imei', id: 'newDevice.imei|oldDevice.imei' },
      ),
      columnHelper.accessor((row) => (row.oldSimCard ? row.oldSimCard.number : ''), {
        header: 'old Sim Card Number',
        id: 'oldSimCard.number',
      }),
      columnHelper.accessor((row) => (row.newSimCard ? row.newSimCard.number : ''), {
        header: 'new Sim Card Number',
        id: 'newSimCard.number',
      }),
      columnHelper.accessor(
        (row) =>
          `${row.newSimCard ? `${row.newSimCard.number}` : 'No New Sim'} <- ${row.oldSimCard ? `${row.oldSimCard.number}` : 'No Old Sim'}`,
        { header: 'sim', id: 'newSimCard.number|oldSimCard.number' },
      ),
      columnHelper.accessor((row) => row.task.type, {
        header: 'Task Type',
        id: 'task.type',
        cell: (props) => (
          <Badge
            variant="light"
            c={TaskTypeColor[props.getValue().split(' ').join('_') as keyof typeof TaskTypeColor]}
            styles={{
              root: { backgroundColor: `${TaskTypeColor[props.getValue().split(' ').join('_') as keyof typeof TaskTypeColor]}40` },
            }}
          >
            {props.getValue()}
          </Badge>
        ),
      }),
      columnHelper.accessor((row) => (row.task.jobRemarks ? row.task?.jobRemarks : ''), { header: 'Remarks', id: 'task.jobRemarks' }),
      columnHelper.accessor((row) => row.task?.performedBy?.firstName ?? '', { header: 'performed By FN', id: 'userPerformed.firstName' }),
      columnHelper.accessor((row) => row.task?.performedBy?.lastName ?? '', { header: 'performed By FN', id: 'userPerformed.lastName' }),
      columnHelper.accessor((row) => row.task?.approvedBy?.firstName ?? '', { header: 'approved By FN', id: 'userApproved.firstName' }),
      columnHelper.accessor((row) => row.task?.approvedBy?.lastName ?? '', { header: 'approved By FN', id: 'userApproved.lastName' }),
      columnHelper.accessor((row) => (row.task.completionDate ? simpleDateFormat(new Date(row.task.completionDate)) : ''), {
        header: 'completion Date',
        id: 'task.completionDate',
      }),
      columnHelper.accessor((row) => `${row.platform} ${row.secondaryPlatform ? ', ' + row.secondaryPlatform : ''}`, {
        header: 'Platforms',
        id: 'platform|secondaryPlatform',
      }),
      columnHelper.accessor((row) => row.platform, {
        header: 'Platform Only',
        id: 'platform',
        cell: (props) => {
          if (props.getValue())
            return (
              <Badge
                variant="light"
                c={PlatformColor[props.getValue().split(' ').join('_') as keyof typeof PlatformColor]}
                styles={{
                  root: { backgroundColor: `${PlatformColor[props.getValue().split(' ').join('_') as keyof typeof PlatformColor]}40` },
                }}
              >
                {props.getValue()}
              </Badge>
            );
        },
      }),
      columnHelper.accessor((row) => row.secondaryPlatform ?? '', {
        header: 'Secondary Platform',
        id: 'secondaryPlatform',
        cell: (props) => {
          if (props.getValue())
            return (
              <Badge
                variant="light"
                c={PlatformColor[props.getValue().split(' ').join('_') as keyof typeof PlatformColor]}
                styles={{
                  root: { backgroundColor: `${PlatformColor[props.getValue().split(' ').join('_') as keyof typeof PlatformColor]}40` },
                }}
              >
                {props.getValue()}
              </Badge>
            );
        },
      }),
      columnHelper.display({
        id: 'Delivary Note',
        header: 'Delivary Note',
        cell: ({ row }) => {
          const { openPdfNewTab } = delivaryNote(
            row.original.task,
            row.original.vehicle,
            row.original.task?.type !== TaskType.Removal ? row.original.newDevice : row.original.oldDevice,
            row.original.task?.type !== TaskType.Removal ? row.original.newSimCard : row.original.oldSimCard,
            row.original.secondaryPlatform ? row.original.platform + ', ' + row.original.secondaryPlatform : row.original.platform,
            row.original.task?.deliveryNote,
          );
          return (
            <Group spacing="xs">
              <Tooltip label="View">
                <ActionIcon onClick={openPdfNewTab}>
                  <TbEye size={15} />
                </ActionIcon>
              </Tooltip>
              {/* <Tooltip label="test" >
                   <ActionIcon onClick={test} ><TbEye size={10} /></ActionIcon>
                </Tooltip> */}
            </Group>
          );
        },
      }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    'completion Date': 'date',
  };

  const toSelect = {
    'Task Type': TaskType,
    Platforms: DevicePlatforms,
    'Platform Only': DevicePlatforms,
    'Secondary Platform': DevicePlatforms,
  };

  return (
    <Layout>
      <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
        Records:
      </Title>
      <BaseTable<Irecord>
        data={data}
        columns={columns}
        setIds={setIds}
        loading={isLoading}
        ids={ids}
        csvExport={<ExportRecords ids={ids} />}
        defaultVisibleColumns={{
          id: false,
          'task.jobRemarks': false,
          'vehicleType.type': false,
          platform: false,
          secondaryPlatform: false,
          'userPerformed.firstName': false,
          'userPerformed.lastName': false,
          'userApproved.firstName': false,
          'userApproved.lastName': false,
          'oldDevice.imei': false,
          'oldDevice.ownership': false,
          'newDeviceType.type': false,
          'newDevice.imei': false,
          'newDevice.ownership': false,
          'oldDeviceType.type': false,
          'oldSimCard.number': false,
          'newSimCard.number': false,
        }}
        page={page}
        toSelect={toSelect}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'Delivary Note')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        pages={allRecords?.data.meta.ids.length ? Math.ceil(allRecords?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        columnTypes={columnTypes}
      />
      <SideBar id={ids} type={types.Record} customActions={customActions}>
        <RecordCard id={ids[0]} />
      </SideBar>
      <Modal
        size={'70%'}
        opened={opened}
        onClose={close}
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Update Record
          </Text>
        }
      >
        {ids.length === 1 && <UpdateRecordModal recordID={ids[0]} />}
      </Modal>
    </Layout>
  );
}
