import { ActionIcon, Group, Modal, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FaEdit } from 'react-icons/fa';
import { TbPlus } from 'react-icons/tb';
import { ExportInvoice } from '../../../components/exportToCSV';
import { NewPaymentForm } from '../../payments';
import { UpdateInvoiceForm } from '../forms/updateInvoiceForm';

export function InvoiceActions({ id, withAddPayment }: { id: number; withAddPayment?: boolean }) {
  const [openedEditInvoice, { open: openEditInvoice, close: closeEditInvoice }] = useDisclosure(false);
  const [openedAddPayment, { open: openAddPayment, close: closeAddPayment }] = useDisclosure(false);

  return (
    <Group>
      {withAddPayment && (
        <Tooltip label="Add Payment">
          <ActionIcon onClick={openAddPayment}>
            <TbPlus />
          </ActionIcon>
        </Tooltip>
      )}
      <ActionIcon onClick={openEditInvoice}>
        <FaEdit />
      </ActionIcon>
      <ExportInvoice id={id} />
      <Modal
        opened={openedEditInvoice}
        onClose={closeEditInvoice}
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Edit Invoice
          </Text>
        }
      >
        <UpdateInvoiceForm id={id} />
      </Modal>
      {withAddPayment && (
        <Modal
          opened={openedAddPayment}
          onClose={closeAddPayment}
          title={
            <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
              New Payment
            </Text>
          }
        >
          <NewPaymentForm id={id} />
        </Modal>
      )}
    </Group>
  );
}
