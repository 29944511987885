import { Box } from '@mantine/core';
import { useResizableContext } from './Resizable.context';

interface ResizableSecondProps {
  children: React.ReactNode;
  hide?: boolean;
}

export function ResizableSecond({ children, hide = false }: ResizableSecondProps) {
  const ctx = useResizableContext();
  // ctx.setHideSecond(hide);
  if (hide && !ctx.hideFirst) return null;
  return (
    <Box
      w={ctx.vertical ? '100%' : ctx.hideFirst ? '100%' : `${(1 - ctx.sideSize.x) * 100}%`}
      h={ctx.vertical ? (ctx.hideFirst ? '100%' : `${(1 - ctx.sideSize.y) * 100}%`) : '100%'}
    >
      {children}
    </Box>
  );
}

ResizableSecond.displayName = 'ResizableSecond';
