import { useQuery } from 'react-query';
import { types } from '../../../constants';
import api from '../../common/api/api';
import { IpurchasePlan } from '../types/type';


interface myResPurchasePlan {
  data: IpurchasePlan;
}

export const getPurchasePlan = (id: number, joins?: string[], selects?: string[]): Promise<myResPurchasePlan> => {
  let params = '';
  if (joins) params = '?joins='.concat(joins.join());
  if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
  return api.get(`purchase-order-plan/${id}${params}`);
};


export const usePurchasePlan = (id: number, options?: { joins?: string[]; selects?: string[] ; then?: (x: myResPurchasePlan) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.PurchasePlan, { id }];
  if (options && options.joins) cacheKey.push(...options.joins);
  const query = useQuery(
    cacheKey,
    () =>
    getPurchasePlan(id, options?.joins, options?.selects).then((purchasePlan) => {
        options && options.then && options.then(purchasePlan);
        return purchasePlan;
      }),
    { enabled: id !== 0 },
  );
  return query;
};
