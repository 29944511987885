import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { IsimCardType } from "../types/types";

interface myResSimCardType {
	data: IsimCardType;
}

export const getSimCardType = (id: number, joins?: string[], selects?: string[]): Promise<myResSimCardType> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`sim-card-types/${id}${params}`);
};

export const useSimCardType = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResSimCardType) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.SimCareType, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
			getSimCardType(id, options?.joins, options?.selects).then((simCardType) => {
				options && options.then && options.then(simCardType);
				return simCardType;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};
