import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Group,
  List,
  LoadingOverlay,
  Paper,
  PasswordInput,
  TextInput,
  Title,
  createStyles,
  rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import sideImage from '../../../../assets/login_side_image.jpg';
import { useAuthContext } from '../../../../context/AuthContext';
import { IaxiosError } from '../../api';
import { getImageCollection } from '../../imageRepository';
import { signIn } from '../api';
import { LoginFormInput } from '../types/types';

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundSize: 'cover',
    backgroundImage: `url(${sideImage})`,
  },

  form: {
    borderRight: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]}`,
    height: '100vh',
    maxWidth: rem(450),
    paddingTop: rem(80),

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export function Login() {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
  });

  const { mutate, isLoading } = useMutation(signIn, {
    onSuccess: (data) => {
      setAlert(null);
      const imageRepositoryId = data.data.profile.imageRepositoryId;
      if (imageRepositoryId) {
        getImageCollection(imageRepositoryId).then((collection) => {
          const link = collection.data.collection.find((image) => image.id === collection.data.previewId) || undefined;
          login({
            id: data.data.id,
            username: data.data.username,
            role: data.data.profile.role,
            orgnizationId: data.data.profile.organizationId,
            img: `${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${link?.path}`,
          });
          navigate(redirect ? redirect : '/');
        });
      } else {
        login({
          id: data.data.id,
          username: data.data.username,
          role: data.data.profile.role,
          orgnizationId: data.data.profile.organizationId,
          img: '',
        });
        navigate(redirect ? redirect : '/');
      }
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: LoginFormInput) {
    setAlert(null);
    mutate(values);
  }

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30} pos="relative">
        <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
          Welcome back!
        </Title>

        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        {alert && (
          <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
            {Array.isArray(alert) ? (
              <List>
                {alert.map((errMsg) => (
                  <List.Item>{errMsg}!</List.Item>
                ))}
              </List>
            ) : (
              alert + '!'
            )}
          </Alert>
        )}

        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput label="Username" placeholder="Your username" {...form.getInputProps('username')} required />
          <PasswordInput label="Password" placeholder="Your password" {...form.getInputProps('password')} required mt="md" />
          <Group position="apart" mt="lg">
            <Checkbox label="Remember me" sx={{ lineHeight: 1 }} {...form.getInputProps('rememberMe', { type: 'checkbox' })} />
            <Anchor<'a'> href="/forgotPassword" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </form>
      </Paper>
    </div>
  );
}
