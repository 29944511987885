import { Group, LoadingOverlay, Paper, Title, createStyles } from '@mantine/core';
import { useEffect, useState } from 'react';
import { DeviceStatus, useAnalyticsDevices } from '../../modules/devices';
import { URLGroupParams, groupBy, sortObjectByOrder } from '../../utils';
import { DevicesCount } from './Components';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
      theme.colors[theme.primaryColor][7]
    } 100%)`,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    display: 'flex',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },
}));




export function DeviceWidget() {
  const { classes } = useStyles();
  const countDevicesOptions = new URLGroupParams()
  countDevicesOptions.group('status', 'deviceType')
  countDevicesOptions.joins('deviceType')
  const { data: deviceData, isLoading: isLoadingDevice } = useAnalyticsDevices({params: countDevicesOptions.toString()})
  const [ data, setData ] = useState<Record<string | number, Record<string, string | number>[]>>({})
  useEffect(() => {
    if(deviceData){
      const temp = groupBy(deviceData?.data, i => i.status)
      const result = sortObjectByOrder(temp, [DeviceStatus.Available, DeviceStatus.Ready, DeviceStatus.InUse, DeviceStatus.Broken, DeviceStatus.Lost, DeviceStatus.Not_Active])
      setData(result)
    }
  }, [deviceData])

  const status = Object.entries(data).map(([key, value], index) => (<DevicesCount title={key} value={value} key={index} />))
  

  return ( 
    <Paper withBorder  radius="md" p='md'>
      <Title order={3} py={10} style={{ fontWeight: 700 }}>
          Devices
      </Title>
        <div className={classes.root} >
          <LoadingOverlay visible={isLoadingDevice} />
            <Group sx={{ flex: 1 }}>
                {status}
            </Group>
        </div>
    </Paper>
  );
}