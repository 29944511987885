export enum TicketStatus {
    New = 'new',
    In_Progress = 'in progress',
    Pending = 'pending',
    Solved = 'solved',
    Closed = 'closed',
  }

export enum TicketType {
    Issue_Report = 'issue report',
    Service_Request = 'service request',
    Technical_Question = 'technical question',
}
  
export enum TicketStatusColors {
  new = '#5862bf',
  solved = '#5c8f43',
  closed = '#bf5858',
  in_progress = '#bf9658',
  pending = '#bfbf58',
}

export enum TicketTypeColors {
  issue_report = '#bf5858',
  service_request = '#5c8f43',
  technical_question = '#bfbf58',
}