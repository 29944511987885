import api from "../../common/api/api";
import { IsimCardType, IsimCardTypeInput } from "../types/types";

interface myResDeviceType {
	data: IsimCardType;
}

export const postSimCardType = (data: IsimCardTypeInput): Promise<myResDeviceType> => {
	return api.post(`sim-card-types`, data);
};
