import api from "../../common/api/api";
import { IDeliveryNote, IDeliveryNoteInput } from "../types/types";

interface myResDeliveryNote {
	data: IDeliveryNote;
}

export const postDeliveryNote = (data: IDeliveryNoteInput): Promise<myResDeliveryNote> => {
	return api.post(`delivery-note`, data);
};

export const postDeliveryNotes = (data: Record<string, any>[]): Promise<myResDeliveryNote[]> => {
	return api.post(`delivery-note/multi`, data);
};
