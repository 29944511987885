import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Icertificate } from '../types/types';

interface myResCertificates {
  data: {
    items: Icertificate[];
    meta: metaData,
  };
}

export const getCertificates = (query = ''): Promise<myResCertificates> => {
  return api.get(`asateel-certificates${query}`);
};

export const useCertificates = (options?: { params?: string; then?: (x: myResCertificates) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.AsateelCertificate, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getCertificates(options?.params).then((certificates) => {
      options && options.then && options.then(certificates);
      return certificates;
    }), {enabled: options?.enabled ?? true },
  );
  return query;
};

interface myResCountCertificatesNumber {
  data: number;
}

export const countCertificates = (query = ''): Promise<myResCountCertificatesNumber> => {
  return api.get(`asateel-certificates/count${query}`);
};

export const useCountCertificates = (options?: { params?: string; then?: (x: myResCountCertificatesNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.AsateelCertificate, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countCertificates(options?.params).then((certificate) => {
      options && options.then && options.then(certificate);
      return certificate;
    }),
  );
  return query;
};