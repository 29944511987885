import { ActionIcon, createStyles } from '@mantine/core';
import { DatePickerInput, DatePickerInputProps } from '@mantine/dates';
import { useState } from 'react';
import { TbEdit } from 'react-icons/tb';

const useStyles = createStyles((theme) => ({
  inputEditableDirty: {
    input: {
      borderColor: theme.colors.violet,
    },
  },
  buttonBody: {
    minWidth: 36,
    minHeight: 36,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export function DatePickerInputEditable({
  editable,
  dirty,
  ...props
}: DatePickerInputProps & { editable?: boolean; dirty?: boolean }) {
  const { classes } = useStyles();
  const [locked, setLocked] = useState(editable);

  return (
    <DatePickerInput
      disabled={locked}
      {...props}
      className={dirty ? classes.inputEditableDirty : undefined}
      rightSection={
        editable && (
          <ActionIcon
            className={classes.buttonBody}
            variant="default"
            onClick={() => setLocked(!locked)}
          >
            <TbEdit size={16} />
          </ActionIcon>
        )
      }
      rightSectionWidth={36}
    />
  );
}
