export function selectColumns(enumData: Record<string, string>, params?:{exclude?:string[],select?:string[], join?:string} ) {
    if(params && params.exclude){
      return Object.values(enumData).filter((value) => !params.exclude?.includes(value)).map((value) => {
        if(params.join) return `${params.join}.${value}`
        else return value
      });
    }
    else if(params && params.select){
      return Object.values(enumData).filter((value) => params.select?.includes(value)).map((value) => {
        if(params.join) return `${params.join}.${value}`
        else return value
      });
    }
    else {
        return Object.values(enumData).map((value) => {
            if(params?.join) return `${params.join}.${value}`
            else return value
        });
    }
  }
  