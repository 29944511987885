import api from '../../common/api/api';
import { TaskGroup, TaskGroupInput } from '../types/types';

interface myResTaskGroupPatch {
  data: TaskGroup;
}

export const patchTaskGroup = (props: { id: number; data: Partial<TaskGroupInput> }): Promise<myResTaskGroupPatch> => {
  return api.patch(`task-groups/${props.id}`, props.data);
};
