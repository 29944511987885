import { Badge, Group, Title, UnstyledButton } from '@mantine/core';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { TbChevronDown, TbChevronUp, TbEdit } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import BaseTable from '../../../components/table/BaseTable';
import { useAuthContext } from '../../../context/AuthContext';
import { URLParams, selectColumns, setTableParams } from '../../../utils';
import { UserRole } from '../../users';
import { usePurchaseOrders } from '../api';
import { OrderActions } from '../components/OrderActions';
import { PurchaseOrderColumnsMode, PurchaseOrderStatus, PurchaseOrderStatusColors } from '../constants/constants';
import { IpurchaseOrder } from '../types/type';
import { PlanDetails } from './PlanDetails';

//FIXME: here, view and forms almost the same component


export function OrderTable() {
   const [rowSelection, setRowSelection] = useState({})
   const [page, setPage] = useState(1)
   const [data, setData] = useState<IpurchaseOrder[]>([]);
   const navigate = useNavigate()
   const { user } = useAuthContext()
   const renderSubComponent = ({ row }: { row: Row<IpurchaseOrder> }) => {
      
      return (
         <>
            {row.original.purchaseOrderPlan.map((plan, i) => {
               return (
                  <PlanDetails key={i} planID={plan.id} index={i} />
               )
            }
            )}
         </>
      )
   }

   const [params, setParams] = useState<URLParams>(() => {
      const param = new URLParams()
      param.includes(
         'purchaseOrderPlan', 'organization', 'organization.parent as orgParent', 
         'purchaseOrderPlan.units as planUnits', 'planUnits.device as unitDevice',
         'planUnits.vehicle as unitVehicle',
         'purchaseOrderPlan.removedUnits as removedUnits', 
         'removedUnits.vehicle as removedVehicle',
         'invoices'
      );
      param.select(...selectColumns(PurchaseOrderColumnsMode), 
      'organization.id', 'organization.name', 'orgParent.id', 'orgParent.name', 'purchaseOrderPlan.id',
      'planUnits.id', 'unitDevice.id', 'unitVehicle.id', 'removedUnits.id', 'invoices.id', 'removedVehicle.id'
      )
      param.sort(('-purchase_order.id'))
      param.page(10, page-1)
      return param
    })
   const { data: allOrders, refetch, isLoading } = usePurchaseOrders({
      params: params.toString(),
   });

   useEffect(() => {
      if (allOrders) {
         setData(allOrders.data.items);
      }
   }, [allOrders]);

   const columnHelper = useMemo(() => createColumnHelper<IpurchaseOrder>(), []);
   const columns: ColumnDef<IpurchaseOrder, any>[] = useMemo(
      () => [
         columnHelper.display({
            id: 'expander',
            header: () => null,
            cell: ({ row }) => {
               return row.getCanExpand() ? (
                  <UnstyledButton
                     {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                     }}
                  >
                     {row.getIsExpanded() ? <TbChevronUp size={16} /> : <TbChevronDown size={16} />}
                  </UnstyledButton>
               ) : (
                  ''
               )
            },
         }),
         columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
         columnHelper.accessor('title', { header: () => 'Title', id: 'title' }),
         columnHelper.accessor((row) => row.organization.name, { header: 'Organization', id: 'organization.name' }),
         columnHelper.accessor((row) => row.organization.parent ? `${row.organization.parent.name}` : '', { header: 'Parent', id: 'orgParent.name' }),
         columnHelper.accessor('status', { header: 'Status', id:'status', cell: props => <Badge color={PurchaseOrderStatusColors[props.getValue() as keyof typeof PurchaseOrderStatusColors]}>{props.getValue()}</Badge>, }),
         columnHelper.accessor(() => '', { header:"unit", id: 'unitDevice.imei|unitVehicle.vin|removedVehicle.vin' }),
         columnHelper.accessor(() => '', { header:"invoice", id: 'invoices.invoiceNo' }),
         columnHelper.display({
            id: 'actions',
            header: () => null,
            cell: ({ row }) => {
               return (
                  <Group>
                     <OrderActions orderID={row.original.id} planIDs={row.original.purchaseOrderPlan.map(plan => plan.id)} />
                     <UnstyledButton onClick={() => navigate(`/finance/edit/order/${row.original.id}`)}>
                        <TbEdit size={16} />
                     </UnstyledButton>
                  </Group>
               )
            },
         }),
      ],
      [columnHelper, data],
   );

   if (user?.role !== UserRole.Master && user?.role !== UserRole.Finance) return null

   const toSelect = {
      Status: PurchaseOrderStatus,
    }

   return (
      <Layout>
         <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
            Orders:
         </Title>
         <BaseTable<IpurchaseOrder> data={data} columns={columns}  withExpandepleRows={true} renderSubComponent={renderSubComponent}
         loading={isLoading}
         page={page}
         toSelect={toSelect}
         setPage={(value) => {
            setParams((param) => {
              param.filterRemovePages()
              param.page(10, value - 1)
              return param
            })
            setPage(value)
          }}
          globalFilterData={(data, headers) => {
            const newHeaders = headers.filter(header => header && header !== 'select' && header !== 'actions' && header !== 'expander').join('|')
            setTableParams(newHeaders, data, '', setParams)
            refetch()
            setPage(1)
          }}
          pages={allOrders?.data.meta.ids.length ? Math.ceil(allOrders?.data.meta.ids.length/10): 1}
          filterData={(column, value, type)=> {
            setTableParams(column, value, type, setParams)
            refetch()
            setPage(1)
          }}
         verticalSpacing={'md'} setRowSelection={setRowSelection} rowSelection={rowSelection} />
      </Layout>
   );
}
