import { Button, Center, Container, Grid, Group, Loader, Pagination, Select, Title } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BaseCard, CardsFilter, useDeepFilter } from '../../../components/card';
import Layout from '../../../components/layout/Layout';
import { URLParams } from '../../../utils';
import { PageSize, commonConstants } from '../../common';
import { useUsers } from '../api';
import { UpdateUserForm } from '../components';
import { UserRoleColor } from '../constants/constants';
import { Iuser } from '../types/types';

export function UserCards() {
  const params = new URLParams();
  params.includes('profiles', 'profiles.organization');
  const { isSuccess, data } = useUsers({ params: params.toString() });
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const navigate = useNavigate();
  const { filter, setFilter, formattedData } = useDeepFilter(data ? data.data.items : []);
  let cardsLoop;
  if (isSuccess) {
    cardsLoop = formattedData.slice((page - 1) * pagesize, pagesize * page).map((item: Iuser) => {
      const profile = item.profiles.find((profile) => profile.app === commonConstants.AiosApp);
      return (
        <BaseCard
          key={`card-${item.id}`}
          name={`${item.firstName} ${item.lastName}`}
          discription={`userName ${item.username} ` + `E-mail: ${item.email}, Organization: ${profile?.organization.name}`}
          to={`/user/${item.id}`}
          img={
            profile?.imageRepositoryId ||
            'https://media.istockphoto.com/id/476085198/photo/businessman-silhouette-as-avatar-or-default-profile-picture.jpg?s=612x612&w=0&k=20&c=GVYAgYvyLb082gop8rg0XC_wNsu0qupfSLtO7q9wu38='
          }
          label={profile?.role}
          color={UserRoleColor[profile?.role.split(' ').join('_') as keyof typeof UserRoleColor]}
          updateModalContent={<UpdateUserForm idM={item.id} />}
        />
      );
    });
  }

  return (
    <Layout>
      <Container fluid>
        <Group px={13} mt={10} position="apart">
          <Title
            order={2}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Users:
          </Title>
          <Button onClick={() => navigate('/new/user')}>Add new</Button>
        </Group>
        <CardsFilter setFilter={setFilter} filter={filter} />
        <Grid>
          {formattedData && cardsLoop ? (
            cardsLoop
          ) : (
            <Container p={25}>
              <Loader />
            </Container>
          )}
        </Grid>
        <Center my={15}>
          <Pagination
            total={Math.ceil(formattedData.length / pagesize)}
            siblings={2}
            boundaries={2}
            onChange={(n) => setPage(n)}
            value={page}
          />
          <Select placeholder="Page Size" size="sm" ml={10} data={Object.values(PageSize)} onChange={(e) => setPagesize(Number(e))} />
        </Center>
      </Container>
    </Layout>
  );
}
