import api from "../../common/api/api";
import { Iunit, IunitInput } from "../types/types";

interface myResUnitPatch {
	data: Iunit;
}

export const patchUnitTechnicianTask = (props: {
	id: number;
	data: Partial<IunitInput> & Partial<{taskId: number, taskStatus: string, jobRemarks: string | null, completionDate:Date, deviceStatus?: string}>;
}): Promise<myResUnitPatch> => {
	return api.patch(`units/technicianTask/${props.id}`, props.data);
};

export const patchUnitDeviceChange = (props: {
	id: number;
	data: Partial<IunitInput> & {taskId: number, taskStatus: string, newUnit: number, jobRemarks: string | null, completionDate: Date,};
}): Promise<myResUnitPatch> => {
	return api.patch(`units/technicianTask/deviceChange/${props.id}`, props.data);
};

export const approveAccessoryInstallation = (props: {
	id: number;
	data: Partial<IunitInput> & {taskId: number, newUnit: number, jobRemarks: string | null, taskRemarks: string | null, completionDate: Date,};
}): Promise<myResUnitPatch> => {
	return api.patch(`units/technicianTask/accessoryinstallation/${props.id}`, props.data);
};

export const patchUnitReinstallation = (props: {
	id: number;
	data: {taskId: number, completionDate: Date,};
}): Promise<myResUnitPatch> => {
	return api.patch(`units/technicianTask/approveReinstallation/${props.id}`, props.data);
};


