import {
  FilterFn,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  Table,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';


export type GlobalFilterProps<Entity extends { id?: number }> = ReturnType<
  typeof useGlobalFilter<Entity>
>;

export const useGlobalFilter = <Entity extends { id?: number }>(
  instance: Table<Entity>, globalFilterData?: (data: string, headers: string[]) => void
) => {
  const [globalFilter, setGlobalFilter] = useState<any>([]);
  const addGlobalFilter = useCallback((value: string) => {
    if(value && globalFilter.indexOf(value) === -1){
      setGlobalFilter([...globalFilter, value])
      const headers = instance.getHeaderGroups()[0].headers.map(header => header.id) 
      globalFilterData && globalFilterData(value, headers)
    }
  }, [[globalFilter, setGlobalFilter]])
  
  const clearGlobalFilter = useCallback((term?:string) => {
    const globalFilterValues = term
    ? globalFilter.filter((keys: string) => keys !== term)
    : [];
    setGlobalFilter(globalFilterValues);
    const headers = instance.getHeaderGroups()[0].headers.map(header => header.id) 
    globalFilterData && globalFilterData('', headers)
  }, [globalFilter])
  const multiTextFilter: FilterFn<any> = () => true 
  useEffect(() => {
    instance.setOptions((prev) => ({
      ...prev,
      globalFilterFn: multiTextFilter,
      onGlobalFilterChange: setGlobalFilter,
      getFilteredRowModel: getFilteredRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      getFacetedMinMaxValues: getFacetedMinMaxValues(),
    }));
  }, [instance]);

  useEffect(() => {
    instance.setState((prev) => ({
      ...prev,
      globalFilter: globalFilter || [],
    }));
  }, [globalFilter, instance]);

  return useMemo(
    () => ({ addGlobalFilter, clearGlobalFilter, globalFilter }),
    [addGlobalFilter, clearGlobalFilter, globalFilter],
  );
};
