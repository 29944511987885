import { TextInput } from '@mantine/core';
import { FaSearch } from 'react-icons/fa';
import { DeepFilterOptions } from './useDeepFilter';

//global for now
export function CardsFilter({ setFilter, filter }: Pick<DeepFilterOptions, 'setFilter' | 'filter'>) {
  return (
    <TextInput
      my={15}
      px={13}
      label="Search"
      placeholder="Search here..."
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      icon={<FaSearch size={16} />}
    />
  );
}
