
import { Badge, Grid, List, Paper, Stack, Text, Title, UnstyledButton } from '@mantine/core';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import BaseTable from '../../../components/table/BaseTable';
import { useAuthContext } from '../../../context/AuthContext';
import { URLParams, selectColumns, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { PaymentColumnsMode } from '../../payments';
import { UserRole } from '../../users';
import { useInvoices } from '../api';
import { InvoiceActions } from '../components';
import { InvoiceColumnsMode } from '../constants';
import { Iinvoice } from '../types/types';

//FIXME: here, view and forms almost the same component


export function InvoiceTable() {
   const [rowSelection, setRowSelection] = useState({})
   const [page, setPage] = useState(1)
   const [data, setData] = useState<Iinvoice[]>([]);
   // const navigate = useNavigate()
   const { user } = useAuthContext()
   const { planid } = useParams();
  const [idN] = useState(+(planid  || 0));
   const renderSubComponent = ({ row }: { row: Row<Iinvoice> }) => {
      const totalPaid = row.original.payments.reduce((acc, item ) => {
         return acc + item.amount
      }, 0)
      const finalPrice = !row.original.vat ? row.original.amount * 1.05 : row.original.amount
      return (
         <>
            {row.original.units.length && 
               (
                  <Paper withBorder p="sm" key={`invoice-${row.id}`}>
                     <Grid p="sm">
                        <Grid.Col span={4}>
                           <Stack>
                              <Text>title: {row.original.invoiceNo}</Text>
                              <Text>{row.original.units.length} devices</Text>
                              <Text>Price: {row.original.amount}</Text>
                              {!row.original.vat && <Text>VAT: {row.original.amount * 0.05}</Text>}
                              {!row.original.vat && <Text>Total: {row.original.amount * 1.05}</Text>}
                              <Text>Total Paid: {totalPaid}</Text>
                              <Text>Balance: {finalPrice - totalPaid}</Text>
                           </Stack>
                        </Grid.Col>
                        <Grid.Col span={4}>
                           <Stack>
                              <Text>Type: {row.original.type}</Text>
                              <Text>Date: {new Date(row.original.date).toDateString()}</Text>
                              <Text>Paid: <Badge color={totalPaid >= finalPrice ? 'green': 'red'}>{totalPaid >= finalPrice ? 'Yes' : 'No'}</Badge></Text>
                              <Text>Remarks: {row.original.remarks}</Text>
                           </Stack>
                        </Grid.Col>
                     </Grid>
                     <Grid>
                        <Grid.Col span={6}>
                           <Paper withBorder p="xs">
                              <Grid>
                                 <Grid.Col span={6}>
                                    <Text>Devices:</Text>
                                    <List>
                                       {row.original.units.map((unit,index) => <List.Item key={`device-${index}-${unit.device?.id}`}> {unit.device?.imei}</List.Item>)}
                                    </List>
                                 </Grid.Col>
                                 <Grid.Col span={6}>
                                    <Text>Vehicles:</Text>
                                    <List>
                                       {row.original.units.map((unit,index) => <List.Item key={`vehicle-${index}-${unit.vehicle?.id}`}>{unit.vehicle?.vin}</List.Item>)}
                                    </List>
                                 </Grid.Col>
                              </Grid>
                           </Paper>
                        </Grid.Col>
                        <Grid.Col span={6}>
                           
                           <Paper withBorder p="xs">
                              <Text fw='bold'>Payments:</Text>
                              <Grid>
                                 <Grid.Col span={6}>
                                    <Text>Amount:</Text>
                                    <List>
                                       {row.original.payments.map((payment,index) => <List.Item key={`payment-${index}-${payment.amount}`}> {payment.amount}</List.Item>)}
                                    </List>
                                 </Grid.Col>
                                 <Grid.Col span={6}>
                                    <Text>Date:</Text>
                                    <List>
                                       {row.original.payments.map((payment,index) => <List.Item key={`payment-${index}-${payment.date}`}> {simpleDateFormat(payment.date)}</List.Item>)}
                                    </List>
                                 </Grid.Col>
                              </Grid>
                           </Paper>
                        </Grid.Col>
                     </Grid>
                  </Paper>
               )
            }
            
         </>
      )
   }

   const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes('units','payments', 'units.device as unitDevice', 'units.vehicle as unitVehicle')
        param.filterSetField('purchaseOrderId',idN)
        param.sort(('-invoice.id'))
        param.page(10, page-1)
        param.select('units.id', ...selectColumns(InvoiceColumnsMode), ...selectColumns(PaymentColumnsMode, {join: 'payments'}), 'unitDevice.id', 'unitDevice.imei', 'unitVehicle.id', 'unitVehicle.vin', 'purchaseOrderId  ')
        return param
      })
      const { data: allInvoices, refetch, isLoading } = useInvoices({
         params: params.toString(),
      });
      useEffect(() => {
         setParams(param => {
            params.filterRemoveField('purchaseOrderId')
            params.filterSetField('purchaseOrderId',idN)
         return param
      })
   }, [idN])

   useEffect(() => {
      if (allInvoices) {
         setData(allInvoices.data.items);
      }
   }, [allInvoices]);

   const columnHelper = useMemo(() => createColumnHelper<Iinvoice>(), []);
   const columns: ColumnDef<Iinvoice, any>[] = useMemo(
      () => [
         columnHelper.display({
            id: 'expander',
            header: () => null,
            cell: ({ row }) => {
               return row.getCanExpand() ? (
                  <UnstyledButton
                     {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                     }}
                  >
                     {row.getIsExpanded() ? <TbChevronUp size={16} /> : <TbChevronDown size={16} />}
                  </UnstyledButton>
               ) : (
                  ''
               )
            },
         }),
         columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
         columnHelper.accessor((row) => row.invoiceNo, { header: 'Invoice', id: 'invoiceNo' }),
         columnHelper.accessor((row) => simpleDateFormat(row.date), { header: 'Invoice Date', id: 'date' }),
         columnHelper.accessor(() => '', { header:"unit", id: 'unitDevice.imei|unitVehicle.vin' }),
         columnHelper.display({
            id: 'actions',
            header: () => null,
            cell: ({ row }) => {
               return (
                  <InvoiceActions id={row.original.id} withAddPayment />
               )
            },
         }),
      ],
      [columnHelper, data],
   );

   if (user?.role !== UserRole.Master && user?.role !== UserRole.Finance) return null

   return (
      <Layout>
         <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
            Invoices:
         </Title>
         <BaseTable<Iinvoice> data={data} columns={columns}  withExpandepleRows={true} renderSubComponent={renderSubComponent}
         loading={isLoading}
         page={page}
         setPage={(value) => {
            setParams((param) => {
              param.filterRemovePages()
              param.page(10, value - 1)
              return param
            })
            setPage(value)
          }}
          globalFilterData={(data, headers) => {
            const newHeaders = headers.filter(header => header && header !== 'select' && header !== 'actions' && header !== 'expander').join('|')
            setTableParams(newHeaders, data, '', setParams)
            refetch()
            setPage(1)
          }}
          pages={allInvoices?.data.meta.ids.length ? Math.ceil(allInvoices?.data.meta.ids.length/10): 1}
          filterData={(column, value, type)=> {
            setTableParams(column, value, type, setParams)
            refetch()
            setPage(1)
          }}
         verticalSpacing={'md'} setRowSelection={setRowSelection} rowSelection={rowSelection} />
      </Layout>
   );
}
