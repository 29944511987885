import { FileButton, FileButtonProps, Group, Text } from "@mantine/core";

export function UploadCSVButton({ file, onChange, ...props }: FileButtonProps & { file: File | null, onChange: (value: File | null) => void }) {

   return (
      <Group position="left">
         <FileButton onChange={onChange} accept="text/csv">
            {props.children}
         </FileButton>
         {file && (
            <Text size="sm" mt="xs">
               <Text span fw={600} >Selected file</Text>: {file.name}
            </Text>
         )}
      </Group>
   )
}