import { Alert, Button, Container, Divider, List, LoadingOverlay, Paper, PasswordInput, Select, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { TextInputEditable } from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { noEmptyString } from '../../../../utils';
import { useOrganizationsNames } from '../../../organizations';
import { IaxiosError } from '../../api';
import { commonConstants } from '../../constants';
import { signUp } from '../api';
import { IsignUpUserInput } from '../types';

//TODO: maybe separate the profile and user, the new relation is OneToMany
export function Signup() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [organizationSelect, setOrganizationSelect] = useState([{ value: `0`, label: `` }]);
  const [SearchChange, setSearchChange] = useState('');

  const queryClient = useQueryClient();

  const form = useForm<IsignUpUserInput>({
    initialValues: {
      username: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      app: commonConstants.AiosApp,
      organization: {
        id: 0,
      },
      phone: '',
    },
  });

  const { data: organizationsData } = useOrganizationsNames();
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.map((organization) => {
        return { value: `${organization.id}`, label: `${organization.name}` };
      });
      setOrganizationSelect(tempArr);
    }
  }, [organizationsData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(signUp, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.User, types.ALL]);
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IsignUpUserInput) {
    setAlert(null);

    const newValues = noEmptyString(values);

    newMutate(newValues);
  }

  return (
    <Layout>
      <Container size={560} pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
            textAlign: 'center',
          })}
        >
          Create an Accounnt
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully created! Please Contact ATS To activate your account.
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Title order={4}>User's Details</Title>
            <Divider size="md" />
            <TextInputEditable label="Username" placeholder="Username" {...form.getInputProps('username')} required mt="md" />
            <TextInputEditable label="E-mail" placeholder="E-mail" {...form.getInputProps('email')} required mt="md" />
            <PasswordInput label="Password" placeholder="Password" {...form.getInputProps('password')} required mt="md" />
            <TextInputEditable label="First Name" placeholder="First Name" {...form.getInputProps('firstName')} mt="md" required />
            <TextInputEditable required label="Last Name" placeholder="Last Name" {...form.getInputProps('lastName')} mt="md" />
            <Select
              label="Organization"
              placeholder="Search here"
              {...form.getInputProps('organization.id')}
              onChange={(v) => {
                v && form.setFieldValue('organization.id', parseInt(v));
              }}
              searchable
              filter={(value, item) => (item.label && item.label.toLowerCase().includes(value.toLowerCase().trim()) ? true : false)}
              onSearchChange={setSearchChange}
              limit={SearchChange ? 2 : 0}
              clearable
              nothingFound="Not found"
              data={organizationSelect}
              value={`${form.values.organization.id}`}
              mt="md"
              required
            />
            <TextInputEditable label="Phone Number" placeholder="Phone Number" {...form.getInputProps('phone')} mt="md" />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </Layout>
  );
}
