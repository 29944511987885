import { Alert, Button, Checkbox, List, LoadingOverlay, NumberInput, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { TbAlertOctagon, TbCircleCheck } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { DatePickerInputEditable, NumberInputEditable, SelectEditable } from "../../../components/form";
import { types } from "../../../constants";
import { enumToSelectData } from "../../../utils";
import { IaxiosError } from "../../common";
import { postInvoice } from "../api";
import { UnitsTransferList } from "../components/UnitsTransferList";
import { InvoiceType } from "../constants/constants";
import { IinvoiceInput } from "../types/types";

export function NewInvoiceForm({planIds, orderID}:{planIds:number[], orderID:number}){
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();

    const form = useForm<IinvoiceInput>({
        initialValues: {
            invoiceNo: '',
            type: 'fees',
            amount: 0,
            vat: false,
            date: new Date(),
            remarks: null,
            purchaseOrder: {id: orderID},
            units: []
        }
    })

    const {
        mutate: newMutate,
        isLoading: newLoading,
        isSuccess: newSuccess,
      } = useMutation(postInvoice, {
        onSuccess: () => {
          setAlert(null);
          form.reset();
          queryClient.invalidateQueries([types.Invoice, types.ALL]);
        },
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });

    function handleSubmit(values: IinvoiceInput){
        newMutate(values)
    }
    return(
        <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
            <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
            <TextInput 
            label="invoice" 
            placeholder='invoice number' 
            required
            {...form.getInputProps(`invoiceNo`)}
            />
            <SelectEditable 
            required
            mt='md'
            label= 'Invoice Type'
            placeholder="Invoice Type"
            {...form.getInputProps('type')}
            data={enumToSelectData(InvoiceType)}
             ></SelectEditable>
            
            <NumberInputEditable
            label="Amount"
            placeholder="Amount"
            {...form.getInputProps('amount')}
            precision={2}
            required
            mt="md"
            />
            <NumberInput
                label="Quantity of Vehicles"
                placeholder="quantity"
                value={form.values.units.length}
                required
                mt="md"
                disabled
            />
            <DatePickerInputEditable
              popoverProps={{withinPortal: true}}
                placeholder="Invoice Date"
                label="Invoice Date"
                {...form.getInputProps(`date`)}
                mt="md"
            />

            <Textarea
              label="Remarks"
              placeholder="Remarks"
              {...form.getInputProps('remarks')}
              value={form.values.remarks ?? ''}
              mt="md"
            />
            <UnitsTransferList values={form.values.units.map(unit => unit.id)} setValues={(value) => {
              form.setFieldValue('units', value)
            }} planIDs={planIds} withCSV
            
            />
            <Checkbox label='includes VAT?' mt='sm'  checked={form.values.vat} {...form.getInputProps('vat')} />
            <Button type="submit" fullWidth mt='md'>Submit</Button>
        </form>
    )
}