import { Grid } from "@mantine/core";
import { TasksCard } from "../../components/task/TasksCard/TasksCard";

export function TechnicianDashboard() {
   const cardHei = 600
   return (
      <Grid>
         <Grid.Col span={12}>
            <TasksCard cardHei={cardHei} />
         </Grid.Col>
         {/* <Grid.Col sm={8}>
            <HistoryCard id={0} cardHei={cardHei} type={types.Task} />
         </Grid.Col> */}
         {/* <Grid.Col sm={4}>
            <ActionButtonsCard id={[0]} type={'technician'} />
         </Grid.Col> */}
      </Grid>
   )
}
