
export enum PurchaseOrderInterval {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Annual = 'annual',
  Once = 'once',
}

export enum PurchaseOrderStatus {
  Paid = 'paid',
  OnGoing = 'ongoing',
  Cancelled = 'cancelled',
}

export enum PurchaseOrderType {
  Lease = 'lease',
  Sold = 'sold',
}

export enum PurchaseOrderStatusColors {
  paid = 'blue',
  pending = 'orange',
  ongoing = 'green',
  cancelled = 'red',
}

export enum PurchaseOrderColumnsMode {
  ID = "id",
  Title = "title",
  Remarks = "remarks",
  Status = "status",
  Used = "used",
  Date = "date",

}

export enum PlanColumnsMode {
  ID = "id",
  Platform = 'platform',
  SecondaryPlatform = 'secondaryPlatform',
  Price = "price",
  Quantity = "quantity",
  Type = "type",
  Interval = "interval",
  Status = "status",
  StartDate = "startDate",
  EndDate = "endDate",
  Remarks = "remarks",
  UpdatedAt = "updatedAt",
}