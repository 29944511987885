import { Alert, Button, Container, List, LoadingOverlay, Paper, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { useParams } from 'react-router-dom';
import { SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { enumToSelectData, removeNotEdited } from '../../../utils';
import { IaxiosError, UploadButton, useUploadImage } from '../../common';
import { patchVehicleType, useVehicleType } from '../api';
import { VehicleCategory, VehicleType } from '../constants/constants';
import { IvehicleTypeInput } from '../types/types';

export function UpdateVehicleTypeForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { upload, progress, uploadCompleted } = useUploadImage();

  const queryClient = useQueryClient();

  const { data: vehicleType } = useVehicleType(idN, {
    then: (vehicle) => {
      form.setValues({
        brand: vehicle.data.brand,
        model: vehicle.data.model,
        year: vehicle.data.year ? vehicle.data.year : '',
        type: vehicle.data.type,
        category: vehicle.data.category ?? '',
      });
      form.resetDirty({
        brand: vehicle.data.brand,
        model: vehicle.data.model,
        year: vehicle.data.year ? vehicle.data.year : '',
        type: vehicle.data.type,
        category: vehicle.data.category ?? '',
      });
    },
  });

  const form = useForm<IvehicleTypeInput>({
    initialValues: {
      brand: vehicleType?.data.brand || '',
      model: vehicleType?.data.model || '',
      year: vehicleType?.data.year || '',
      type: vehicleType?.data.type || '',
      category: vehicleType?.data.category || '',
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchVehicleType, {
    onSuccess: (data) => {
      setAlert(null);
      const imageRepositoryId = data.data.imageRepositoryId;
      if (imageRepositoryId && file) {
        upload(imageRepositoryId, file, `${data.data.brand}_${data.data.model}`, types.VehicleType, types.VehicleType, data.data.id);
      }
      queryClient.invalidateQueries([types.VehicleType, types.ALL]);
      queryClient.invalidateQueries([types.VehicleType, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.VehicleType, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IvehicleTypeInput) {
    setAlert(null);
    const newValues = removeNotEdited(values, form);
    if (idN !== 0 && !Number.isNaN(idN)) {
      if (Object.keys(newValues).length) {
        patchMutate({ id: idN, data: newValues });
      }
      if (vehicleType && vehicleType.data.imageRepositoryId && file) {
        upload(
          vehicleType.data.imageRepositoryId,
          file,
          `${vehicleType.data.brand}_${vehicleType.data.model}`,
          types.VehicleType,
          types.VehicleType,
          vehicleType.data.id,
        );
        queryClient.invalidateQueries([types.Images, { id: vehicleType.data.imageRepositoryId }]);
      }
    } else {
      setAlert(['The id provided is incorrect!']);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit a Vehicle Type:
        </Title>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {(patchSuccess || uploadCompleted) && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              dirty={form.isDirty('brand')}
              label="brand"
              placeholder="brand name"
              {...form.getInputProps('brand')}
              required
            />
            <TextInputEditable
              dirty={form.isDirty('model')}
              label="Model"
              placeholder="vehicle Type"
              {...form.getInputProps('model')}
              required
              mt="md"
            />
            <TextInputEditable dirty={form.isDirty('year')} label="Year" placeholder="year" {...form.getInputProps('year')} mt="md" />
            <SelectEditable
              dirty={form.isDirty('type')}
              label="type"
              placeholder="Search Here"
              {...form.getInputProps('type')}
              required
              data={enumToSelectData(VehicleType)}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('category')}
              label="Vehicle Category"
              placeholder="Vehicle Category"
              {...form.getInputProps('category')}
              mt="md"
              data={enumToSelectData(VehicleCategory)}
              required
            />
            <UploadButton file={file} onChange={setFile} progress={progress}>
              {(props) => (
                <Button {...props} mt="md">
                  Select vehicle image
                </Button>
              )}
            </UploadButton>
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
}
