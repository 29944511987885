import { Alert, Button, Container, Divider, Grid, List, LoadingOverlay, Paper, PasswordInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { DetailsCard } from '../../../components/detailsCards';
import { SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { enumToSelectData, noEmptyString } from '../../../utils';
import { UploadButton, commonConstants, useUploadImage } from '../../common';
import { IaxiosError } from '../../common';
import { OrganizationCard, useOrganizations } from '../../organizations';
import { IuserProfileInput, postUserProfile } from '../../user-profiles';
import { postUser } from '../api';
import { UserDepartment, UserRole, UserStatus } from '../constants/constants';

interface IuserNewInput {
  username: string;
  email: string;
  password?: string;
  firstName: string | null;
  lastName: string | null;
  status: string;
  profiles: {
    role: string;
    app: string;
    department: string;
    organization: {
      id: number;
    };
    phone: string;
    employeeId: string;
    status: string;
  };
}

//TODO: maybe separate the profile and user, the new relation is OneToMany
export function NewUserForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [organizationSelect, setOrganizationSelect] = useState([{ value: '0', label: '' }]);
  const [organizationId, setOrganizationId] = useState(0);
  const profileN = useRef<IuserProfileInput | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { upload, progress } = useUploadImage();

  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      username: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      status: UserStatus.Pending,
      profiles: {
        role: UserRole.Client,
        app: commonConstants.AiosApp,
        department: UserDepartment.Client,
        organization: {
          id: 0,
        },
        phone: '',
        employeeId: '',
        status: UserStatus.Pending,
      },
    },
  });

  const { data: organizationsData } = useOrganizations();
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((organization) => {
        return { value: `${organization.id}`, label: `${organization.name}` };
      });
      setOrganizationSelect(tempArr);
    }
  }, [organizationsData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postUser, {
    onSuccess: (data) => {
      setAlert(null);
      if (profileN.current) {
        postUserProfile({ ...profileN.current, user: { id: data.data.id } }).then((profile) => {
          const imageRepositoryId = profile.data.imageRepositoryId;
          if (imageRepositoryId && file) {
            upload(
              imageRepositoryId,
              file,
              `${data.data.username}_${commonConstants.Profile}`,
              types.UserProfile,
              types.UserProfile,
              profile.data.id,
            );
          }
        });
      }
      queryClient.invalidateQueries([types.User, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IuserNewInput) {
    setAlert(null);
    profileN.current = { ...values.profiles, user: { id: 0 } };

    const newValues = noEmptyString(values);
    const finalPostValues = {
      ...newValues,
      profiles: [newValues.profiles],
    };

    newMutate(finalPostValues);
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new User:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Title order={4}>User's Details</Title>
            <Divider size="md" />
            <TextInputEditable label="Username" placeholder="Username" {...form.getInputProps('username')} required mt="md" />
            <TextInputEditable label="E-mail" placeholder="E-mail" {...form.getInputProps('email')} required mt="md" />
            <PasswordInput label="Password" placeholder="Password" {...form.getInputProps('password')} required mt="md" />
            <TextInputEditable label="First Name" placeholder="First Name" {...form.getInputProps('firstName')} mt="md" />
            <TextInputEditable label="Last Name" placeholder="Last Name" {...form.getInputProps('lastName')} mt="md" />
            <SelectEditable
              label="User Status"
              placeholder="Search here"
              {...form.getInputProps('status')}
              data={enumToSelectData(UserStatus)}
              required
              mt="md"
            />
            <Title order={4} mt="md">
              User's Profile Information
            </Title>
            <Divider size="md" />
            <SelectEditable
              label="Role"
              placeholder="Search here"
              {...form.getInputProps('profiles.role')}
              data={enumToSelectData(UserRole)}
              required
              mt="md"
            />
            <UploadButton file={file} onChange={setFile} progress={progress}>
              {(props) => (
                <Button {...props} mt="md">
                  Select profile image
                </Button>
              )}
            </UploadButton>
            <SelectEditable
              label="Department"
              placeholder="Search here"
              {...form.getInputProps('profiles.department')}
              data={enumToSelectData(UserDepartment)}
              required
              mt="md"
            />
            <SelectEditable
              label="Organization"
              placeholder="Search here"
              {...form.getInputProps('profiles.organization.id')}
              onChange={(v) => {
                v && form.setFieldValue('profiles.organization.id', parseInt(v));
                setOrganizationId(parseInt(v || '0'));
              }}
              searchable
              nothingFound="Not found"
              data={organizationSelect}
              value={`${form.values.profiles.organization.id}`}
              mt="md"
              required
            />
            <SelectEditable
              label="Profile Status"
              placeholder="Search here"
              {...form.getInputProps('profiles.status')}
              data={enumToSelectData(UserStatus)}
              required
              mt="md"
            />
            <TextInputEditable label="Phone Number" placeholder="Phone Number" {...form.getInputProps('profiles.phone')} mt="md" />
            <TextInputEditable label="Employee ID" placeholder="Employee ID" {...form.getInputProps('profiles.employeeId')} mt="md" />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {organizationId !== 0 && (
            <DetailsCard
              id={organizationId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter((org) => org.id === organizationId)[0].imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
}
