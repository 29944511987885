import {
  Alert,
  Box,
  Button,
  Center,
  Group,
  List,
  LoadingOverlay,
  PasswordInput,
  Progress,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useInputState } from '@mantine/hooks';
import { useState } from 'react';
import { TbAlertOctagon, TbCheck, TbCircleCheck, TbX } from 'react-icons/tb';
import { useMutation } from 'react-query';
import { useAuthContext } from '../../../context/AuthContext';
import { IaxiosError } from '../../common';
import { patchPassord } from '../api';
import { IupdatePassword } from '../types/types';

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text color={meets ? 'teal' : 'red'} mt={5} size="sm">
      <Center inline>
        {meets ? <TbCheck size={14} /> : <TbX size={14} />}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  // { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

export function UpdatePasswordForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const { user } = useAuthContext();

  const [value, setValue] = useInputState('');
  const strength = getStrength(value);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ bar: { transitionDuration: '0ms' } }}
        value={
          value.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
              ? 100
              : 0
        }
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    ));

  const form = useForm({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validate: {
      newPassword: () =>
        strength < 100 ? 'password not strong enough' : null,
      confirmPassword: (value, values) =>
        value !== values.newPassword ? 'passwords did not match' : null,
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchPassord, {
    onSuccess: () => {
      setAlert(null);
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IupdatePassword) {
    setAlert(null);
    if (user) {
      patchMutate({ id: user.id, data: values });
    }
  }

  return (
    <Stack pos="relative">
      <LoadingOverlay visible={patchLoading} overlayBlur={2} />
      {alert && (
        <Alert
          icon={<TbAlertOctagon size={16} />}
          title="Error!"
          color="red"
          mb={10}
        >
          {Array.isArray(alert) ? (
            <List>
              {alert.map((errMsg) => (
                <List.Item>{errMsg}!</List.Item>
              ))}
            </List>
          ) : null}
          {alert}!
        </Alert>
      )}
      {patchSuccess && (
        <Alert
          icon={<TbCircleCheck size={16} />}
          title={'Updated!'}
          color="green"
          mb={10}
        >
          Successfully updated in the database!
        </Alert>
      )}
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <PasswordInput
          label="Old Password"
          placeholder="Old Password"
          {...form.getInputProps('oldPassword')}
          required
        />
        <PasswordInput
          label="New Password"
          placeholder="New Password"
          {...form.getInputProps('newPassword')}
          value={value}
          onChange={(v) => {
            setValue(v.target.value);
            form.setFieldValue('newPassword', v.target.value);
          }}
          required
          mt="md"
        />
        <PasswordInput
          label="Confirm Password"
          placeholder="Confirm Password"
          {...form.getInputProps('confirmPassword')}
          required
          mt="md"
        />
        <Group spacing={5} grow mt="xs" my="md">
          {bars}
        </Group>

        <PasswordRequirement
          label="Has at least 6 characters"
          meets={value.length > 5}
        />
        {checks}
        <Button fullWidth mt="xl" type="submit">
          Submit
        </Button>
      </form>
    </Stack>
  );
}
