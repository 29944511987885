import api from "../../common/api/api";
import { Iunit, IunitInput } from "../types/types";

interface myResUnitPost {
	data: Iunit;
}

export const postUnit = (data: IunitInput): Promise<myResUnitPost> => {
	return api.post(`units`, data);
};

export const postUnits = (data: Record<string, any>[]): Promise<{data: Iunit[]}> => {
	return api.post(`units/multi`, data);
};