import { TransferList } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useEffect, useState } from "react";
import { URLParams } from "../../../utils";
import { useAccessories } from "../../accessories";

type listType = [{ value: string; label: string }[], { value: string; label: string }[]]
interface accessoryInstallation {
    accessories: { id: number }[] | null;
    jobRemarks: string | null;
    completionDate: Date | null;
    taskRemarks?: string | null;
  }
export function AccessoriesTransferList({ form, id }
    :
    {
        form: UseFormReturnType<accessoryInstallation, (values: accessoryInstallation) => accessoryInstallation>,
        id?: number
    }){
    const [accessories, setAccessories] = useState<listType>([[{ value: '0', label: 'loading...' }], [{ value: '0', label: 'loading...' }]]);
    const accessoriesParams = new URLParams();
    accessoriesParams.includes('accessoriesType', 'unit');
    if(id){

        accessoriesParams.filterSetLevel(0, '|');
        accessoriesParams.filterSetField('=unit.id', `${id}`, 0);
        accessoriesParams.filterSetField('=unit.id', 'null', 0);
    }else{
        accessoriesParams.filterSetField('=unit.id', 'null');
    }
    const { data: accessoriesData } = useAccessories({params: accessoriesParams.toString()});
    useEffect(() => {
        const select: { value: string; label: string }[] = []
        const notSelect: { value: string; label: string }[] = []
        if (accessoriesData) {
            accessoriesData.data.items.forEach((item) => {
                if(form.values.accessories?.some((element) => element.id === item.id)){
                    select.push({value:`${item.id}`,label:`serialNo: ${item.serialNo}, name: ${item.accessoriesType.name}`})
                }else{
                    notSelect.push({value:`${item.id}`,label:`serialNo: ${item.serialNo}, name: ${item.accessoriesType.name}`})
                }
                
            })
        }
        setAccessories([select,notSelect])
    }, [accessoriesData,form.values.accessories]);
    
    return (
        <>
        <TransferList
                value={accessories}
                onChange={(v: listType) => {
                    setAccessories(v);
                    v[0]
                    form.setFieldValue(`accessories`, v[0].map((item) => { return { id: +item.value} }));
                }}

                searchPlaceholder="Search..."
                nothingFound="Nothing here"
                titles={['accessories selected', 'accessories not selected']}
                breakpoint="sm"
                mt='md'
            />
        </>
    )
}