export enum AttendanceStatus {
  Present = 'present',
  Late = 'late',
  Leave = 'leave',
  Rest_Day = 'rest day',
  Holiday = 'holiday',
  Other = 'other',
}

export enum AttendanceColumnsMode {
  ID = 'id',
  Day = 'day',
  TimeIn = 'timeIn',
  TimeInLatitude = 'timeInLatitude',
  TimeInLongitude = 'timeInLongitude',
  TimeInSubmittedAt = 'timeInSubmittedAt',
  TimeOut = 'timeOut',
  TimeOutLatitude = 'timeOutLatitude',
  TimeOutLongitude = 'timeOutLongitude',
  TimeOutSubmittedAt = 'timeOutSubmittedAt',
  Status = 'status',
  Remarks = 'remarks',
}
