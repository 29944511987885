import { ActionIcon, TextInput, TextInputProps, createStyles } from "@mantine/core";
import { useState } from "react";
import { TbEdit } from "react-icons/tb";

const useStyles = createStyles((theme) => ({
   wrapper: {
      '&:has(input:disabled)': {
         '& .mantine-Input-rightSection': {
           display: 'block',
         },
       },
   },
   inputEditable: {
      flexGrow: 1,
      input: {
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0,
      }
   },
   inputEditableDirty: {
      wrapper: {
         '&:has(input:disabled)': {
            '& .mantine-Input-rightSection': {
              display: 'block',
            },
          },
      },
      input: {
         borderColor: theme.colors.violet
      }
   },
   buttonBody: {
      minWidth: 36,
      minHeight: 36,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
   }
}))


export function TextInputEditable({ editable, dirty, ...props }: TextInputProps & { editable?: boolean, dirty?: boolean }) {
   const { classes } = useStyles()
   const [locked, setLocked] = useState(editable)
   // const {systemStyles, ...rest} = extractSystemStyles(props)

   return (
      // <Box  className={classes.wrapper}>
      <TextInput
         className={dirty ? classes.inputEditableDirty : classes.wrapper}
         {...props}
         // disabled={locked}
         rightSection={editable && <ActionIcon className={classes.buttonBody} variant="default" onClick={() => setLocked(!locked)} ><TbEdit size={16} /></ActionIcon>}
      />
      //    {editable &&
      //       <Group {...systemStyles}   >
      //          <ActionIcon className={classes.buttonBody} variant="default" onClick={() => setLocked(!locked)} ><TbEdit size={16} /></ActionIcon>
      //       </Group>
      //    }
      // </Box>
   )
}