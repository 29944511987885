import { FileButton, FileButtonProps, Progress, Text } from "@mantine/core";

export function UploadButton({ file, onChange, progress, ...props }: FileButtonProps & { file: File | null, onChange: (payload: File | null) => any, progress?: number }) {

   return (
      <>

         {/* TODO: maybe make the file size limited */}
         <FileButton {...props} onChange={onChange} accept="image/png,image/jpeg,.pdf">
            {props.children}
         </FileButton>
         {file && (
            <Text size="sm" mt="xs">
               Selected image: {file.name}
            </Text>
         )}

         {progress ? <Progress value={progress} striped animate hidden={progress === 0 || progress === 100} mt="md" /> : null}
      </>
   )
}

export function UploadButtonMultiple({ files, onChange, progress, ...props }: FileButtonProps<true> & { files: File[], onChange: (payload: File[]) => any, progress?: number }) {

   return (
      <>

         {/* TODO: maybe make the files size limited */}
         <FileButton {...props} multiple onChange={onChange} accept="image/png,image/jpeg,.pdf">
            {props.children}
         </FileButton>
         {files.length !== 0 && (
            <Text size="sm" mt="xs">
               Selected images: {files.map((file) => file.name + ', ')}
            </Text>
         )}

         {progress ? <Progress value={progress} striped animate hidden={progress === 0 || progress === 100} mt="md" /> : null}
      </>
   )
}