import { useEffect, useState } from "react"
import { useTasks } from "../../modules/tasks"
import { DownloadAsCsv, Format, URLParams, dateToNewDate, getAllKeysWithPath, getKeysWithPath } from "../../utils"
import { ExportToCsvButton } from "../table/components"

export function ExportTasks({
    ids,
}:{
    ids: number[]
}){
    const defaultColumns = ['id', 'completionDate', 'type', 'taskRemarks', 'jobRemarks', 'performedBy:id', 'performedBy:firstName', 'unit:id', 'unit:device:id', 'unit:device:imei', 'unit:simCard:id', 'unit:simCard:number', 'unit:vehicle:id', 'unit:vehicle:vin', 'unit:vehicle:category', 'unit:vehicle:plateNumber', 'unit:vehicle:placeOfIssue', 'organization:id', 'organization:name', 'unit:deviceConfig:id', 'unit:deviceConfig:platform', 'unit:deviceConfig:secondaryPlatform']
    const task = {
        id: 0,
        title: "",
        type: "",
        targetDate: new Date(),
        completionDate: new Date(),
        status: "",
        address: "",
        taskRemarks: "",
        jobRemarks: '',
        location: "",
        contactName: "",
        contactNumber: "",
        recipientGroup: "",
        organization: {
            id: 0,
            name: "",
            parent: {
                id: 0,
                name: "",
            },
        } ,
        vehicle: {
            id: 0,
            category: "",
            plateNumber: 0,
            placeOfIssue: "",
            vin: "",
            vehicleType: {
                id: 0,
                model: "",
                brand: "",
                year: 0,
                type: "",
                category: "",
            } ,
        },
        unit: {
            id: 0,
            device: {
                id: 0,
                imei: 0,
                status: "",
            } ,
            simCard: {
                id: 0,
                number: "",
                serial: "",
                status: "",
            },
            vehicle: {
                id: 0,
                category: "",
                plateNumber: 0,
                placeOfIssue: "",
                vin: "",
                vehicleType: {
                    id: 0,
                    model: "",
                    brand: "",
                    year: 0,
                    type: "",
                } ,
            },
            deviceConfig: {
                id: 0,
                platform: "",
                secondaryPlatform: "",
            },
        } ,
        recipient: {
            id: 0,
            firstName: "",
            lastName: "",
        },
        performedBy: {
            id: 0,
            firstName: "",
            lastName: "",
        },
        approvedBy: {
            id: 0,
            firstName: "",
            lastName: "",
        },
        issuedBy: {
            id: 0,
            firstName: "",
            lastName: "",
        },
        contacts: {
            id: 0,
            name: "",
            position: "",
            phone: "",
            email: ""
        } ,
    }

    const [ fetch, setFetch ] = useState(false)
    const [ fileName, setFileName ] = useState('')
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
        'unit',
        'vehicle',
        'vehicle.vehicleType as vehicleType',
        'organization',
        'organization.parent as orgParent',
        'unit.device as unitDevice',
        'unit.deviceConfig as unitConfig',
        'unit.vehicle as unitVehicle',
        'unitVehicle.vehicleType as unitVehicleType',
        'unit.simCard as unitSimCard',
        'recipient',
        'issuedBy',
        'performedBy',
        'approvedBy',
        'contacts'
        );
        
        param.sort(('-task.id'))
        return param
      })
    // params.se
    const { data: alltasks, refetch } = useTasks({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (alltasks) {
          DownloadAsCsv(dateToNewDate(alltasks.data.items, ['targetDate', 'completionDate']), fileName, getKeysWithPath(getAllKeysWithPath(alltasks.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [alltasks]);

    return(
        <ExportToCsvButton keys={getKeysWithPath(task,[], ':')} setDefaultColumns={defaultColumns} onSubmit={(v => {
            setFileName(v.fileName)
            const paths = Object.values(v.columns).reduce((cols, item) => {
                    const path = handleReplacePath(item.path, [{originalPath: 'organization.parent', replaceTo: 'orgParent'},{originalPath: 'unit.deviceConfig', replaceTo: 'unitConfig'}, {originalPath: 'unit.device', replaceTo: 'unitDevice'},{originalPath:'unit.simCard', replaceTo: 'unitSimCard'},{originalPath: 'unit.vehicle.vehicleType', replaceTo: 'unitVehicleType'}, {originalPath: 'unit.vehicle', replaceTo: 'unitVehicle'}, {originalPath: 'vehicle.vehicleType', replaceTo: 'vehicleType'}])
                    
                    if (item?.checked) cols.push(path)
                    return cols
                  }, [] as string[])
            setParams(param => {
                param.select(...paths)
                param.resetSearchMany()
                param.searchMany('id', ids.map(id => `${id}`))
                return param
            })
            
            if(!fetch){
                setFetch(true)
            } else refetch()
        })}/>
    )
}
function handleReplacePath(path: string, replaces:{originalPath: string, replaceTo: string}[]){
    const found = replaces.find(item => path.includes(item.originalPath))
    const paths = path.split('.')
    const column = paths[paths.length-1]
    if(found) return found.replaceTo+'.'+column
    else return path
}