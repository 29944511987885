import { Alert, Button, Checkbox, Container, Grid, List, LoadingOverlay, Paper, Select, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';

import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { DatePickerInputEditable } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { URLParams, noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { useUnits } from '../../unit';
import { useVehicles } from '../../vehicles';
import { postCertificate, useCertificates } from '../api';
import { IcertificateInput } from '../types/types';

export function NewAsateelCertificateForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [vehicleSelect, setVehicleSelect] = useState([{ value: '', label: 'Loading...' }]);
  const [unitsSelect, setUnitsSelect] = useState([{ value: '', label: 'Loading...' }]);
  const [unitId, setUnitID] = useState(0);
  const queryClient = useQueryClient();

  const form = useForm<IcertificateInput>({
    initialValues: {
      certificateID: '',
      activationDate: new Date(),
      expiryDate: new Date(dayjs(dayjs(new Date()).add(1, 'year').toDate()).subtract(1, 'day').toDate()),
      withPlate: true,
      withDate: false,
      withDevice: true,
      withSimCard: true,
      withParent: false,
      vehicle: { id: 0 },
      device: { id: 0 },
      simCard: { id: 0 },
    },
  });

  const param = new URLParams();
  param.includes('device', 'simCard', 'vehicle');
  param.select('id', 'device.id', 'simCard.id', 'vehicle.id');
  const { data: certificateData } = useCertificates({ params: param.toString() });

  const vehicleParams = new URLParams();
  vehicleParams.includes('organization', 'asateelCertificate');
  vehicleParams.filterSetField('=asateelCertificate.id', 'null');
  vehicleParams.select(
    'id',
    'vin',
    'plateNumber',
    'licenseIssueDate',
    'licenseExpiryDate',
    'organization.id',
    'organization.name',
    'asateelCertificate.id',
  );
  const { data: vehiclesData } = useVehicles({ params: vehicleParams.toString() });
  useEffect(() => {
    if (vehiclesData) {
      const tempArr = vehiclesData.data.items.map((vehicle) => {
        return { value: `${vehicle.id}`, label: `${vehicle.vin}, ${vehicle.plateNumber ?? ''}, ${vehicle.organization.name}` };
      });
      tempArr.unshift({ value: '0', label: 'No vehicle' });
      setVehicleSelect(tempArr);
    }
  }, [vehiclesData]);

  const unitParam = new URLParams();
  unitParam.includes('device', 'simCard');
  unitParam.select('id', 'device.id', 'device.imei', 'simCard.id', 'simCard.number');
  unitParam.filterSetField('device.id', 'null');
  unitParam.filterSetField('simCard.id', 'null');
  const { data: unitData } = useUnits({ params: unitParam.toString() });
  useEffect(() => {
    if (unitData) {
      const tempArr = unitData.data.items.map((unit) => {
        return { value: `${unit.id}`, label: `${unit.device.imei}, ${unit.simCard.number}` };
      });
      tempArr.unshift({ value: '0', label: 'No Imei' });
      setUnitsSelect(tempArr);
    }
  }, [unitData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postCertificate, {
    onSuccess: () => {
      setAlert(null);
      form.reset();
      setUnitID(0);
      queryClient.invalidateQueries([types.AsateelCertificate, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IcertificateInput) {
    const foundCertificate = certificateData?.data.items.find((certificate) => certificate.vehicle.id === values.vehicle.id);
    const newValues = noEmptyString(values);
    if(newValues.device && newValues.device.id === 0) newValues.device = null
    if(newValues.simCard && newValues.simCard.id === 0) newValues.simCard = null
    if (newValues.vehicle.id && !foundCertificate) {
      newMutate(newValues);
    } else {
      if (foundCertificate) setAlert('Certificate already exists');
      else setAlert('Please select device, sim card and vehicle');
    }
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={3}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new asateel certificate:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Select
              label="Vehicle"
              placeholder="Search Here"
              required
              {...form.getInputProps('vehicle.id')}
              onChange={(v) => {
                v && form.setFieldValue('vehicle.id', parseInt(v));
              }}
              searchable
              nothingFound="Not found"
              data={vehicleSelect}
              value={`${form.values.vehicle.id}`}
            />
            <Select
              label="Unit"
              placeholder="Search Here"
              onChange={(v) => {
                const foundUnit = unitData?.data.items.find((unit) => `${unit.id}` === v);
                if (foundUnit) {
                  form.setFieldValue('device.id', foundUnit.device.id);
                  form.setFieldValue('simCard.id', foundUnit.simCard.id);
                  setUnitID(foundUnit.id);
                }
              }}
              searchable
              clearable
              nothingFound="Not found"
              data={unitsSelect}
              mt="md"
              value={`${unitId}`}
            />
            <DatePickerInput
              placeholder="Search Date"
              label="activation Date"
              {...form.getInputProps('activationDate')}
              onChange={(v) => {
                form.setFieldValue('activationDate', v);
                form.setFieldValue('expiryDate', new Date(dayjs(dayjs(v).add(1, 'year').toDate()).subtract(1, 'day').toDate()));
              }}
              required
              mt="md"
            />
            <DatePickerInputEditable placeholder="Search Date" label="expiry Date" {...form.getInputProps('expiryDate')} mt="md" />
            <Grid mt="sm">
              <Grid.Col span={3}>
                <Checkbox label="with Date?" checked={form.values.withDate} {...form.getInputProps('withDate')} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Checkbox label="with Plate?" checked={form.values.withPlate} {...form.getInputProps('withPlate')} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Checkbox label="with Device?" checked={form.values.withDevice} {...form.getInputProps('withDevice')} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Checkbox label="with SimCard?" checked={form.values.withSimCard} {...form.getInputProps('withSimCard')} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Checkbox label="with Parent?" checked={form.values.withParent} {...form.getInputProps('withParent')} />
              </Grid.Col>
            </Grid>
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </Layout>
  );
}
