import { ActionIcon, Divider, Menu } from "@mantine/core";
import { TbBracketsContain, TbEqual, TbEqualNot, TbFilter, TbX } from "react-icons/tb";

export function TableFilterMenu(
    {
        column,
        columnType,
        setValue,
        filterData,
        setDate,
        setDateRange,
    } : 
    {
        column:string
        columnType: string,
        setValue?: (type: any) => void
        filterData?: (column: string, data: any, type:string) => void,
        setDate?: React.Dispatch<React.SetStateAction<Date | null>>,
        setDateRange?: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>,

    }){
        //todo: fix these after finishing the work to backend
    return(
        <Menu shadow="md" width={200}>
        <Menu.Target>
            <ActionIcon><TbFilter /></ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
            <Menu.Label>Filters</Menu.Label>
            {columnType === 'date' && 
            <>
                <Menu.Item onClick={() => {
                    if(filterData)filterData(column,undefined,'')
                    setDateRange && setDateRange([null, null])
                    setValue && setValue('default')
                } } icon={<TbEqual size={14} />}>one</Menu.Item>
                <Menu.Item onClick={() => {
                    if(filterData)filterData(column,undefined,'')
                    setDate && setDate(null)
                    setValue && setValue('range')
                    }} icon={<TbBracketsContain size={14} />}>range</Menu.Item>
            </>
            }
            {/* <Menu.Item onClick={() => tableSetFilterValue(setFilterName, 'notEqualFilter', column)} icon={<TbEqualNot size={14} />}>Not Equal</Menu.Item> */}
            {/* <Divider />
            <Menu.Item onClick={() => tableSetFilterValue(setFilterName, 'notEmptyFilter', column)} icon={<TbEqualNot size={14} />}>Not Empty</Menu.Item>
        {(columnType === 'number' || columnType === 'date') && <Divider />} */}
            {/* {columnType === 'number' &&  <Menu.Item onClick={() => tableSetFilterValue(setFilterName, 'numberRangeFilter', column)} icon={<TbArrowsHorizontal size={14} />}>between</Menu.Item>} */}
            {/* {columnType === 'number' &&  <Menu.Item onClick={() => tableSetFilterValue(setFilterName, 'numberLargerOrEqualFilter', column)} icon={<TbMathEqualGreater size={14} />}>more than or equal</Menu.Item>}
            {columnType === 'number' &&  <Menu.Item onClick={() => tableSetFilterValue(setFilterName, 'numberLessOrEqualFilter', column)} icon={<TbMathEqualLower size={14} />}>less than or equal</Menu.Item>} */}
            {/* {columnType === 'date' && <Menu.Item onClick={() => tableSetFilterValue(setFilterName,'dateRangeFilter',column) } icon={<TbArrowsHorizontal size={14} />}>between</Menu.Item>} */}
            {/* {columnType === 'date' && <Menu.Item onClick={() => tableSetFilterValue(setFilterName,'dateLargerOrEqualFilter',column)} icon={<TbMathEqualGreater size={14} />}>more than or equal</Menu.Item>}
            {columnType === 'date' && <Menu.Item onClick={() => tableSetFilterValue(setFilterName,'dateLessOrEqualFilter',column)} icon={<TbMathEqualLower size={14} />}>less than or equal</Menu.Item>} */}
            <Menu.Item onClick={() => {
                filterData && filterData(column, '=', 'null')
            }} icon={<TbEqualNot size={14} />}>Empty</Menu.Item>
            <Menu.Item onClick={() => {
                filterData && filterData(column, '!', 'null')
            }} icon={<TbEqual size={14} />}>Not Empty</Menu.Item>
            <Divider />
            <Menu.Item onClick={() => {
                if(filterData)filterData(column,undefined,'')
                setDate && setDate(null)
                setDateRange && setDateRange([null, null])
                setValue&& setValue('')
                }} c='red' icon={<TbX size={14} />}>Clear Filter</Menu.Item>
        </Menu.Dropdown>
        </Menu>
    )
}