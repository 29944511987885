import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { Iuser } from "../types/types";

interface myResUser {
	data: Iuser;
}

export const getUser = (id: number, joins?: string[], selects?: string[]): Promise<myResUser> => {
	let params = "";
	if (joins) params = "?joins=".concat(joins.join());
	if (selects) params = params ? params.concat("&selects=".concat(selects.join())) : "?selects=".concat(selects.join());
	return api.get(`users/${id}${params}`);
};

export const useUser = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResUser) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.User, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
			getUser(id, options?.joins, options?.selects).then((user) => {
				options && options.then && options.then(user);
				return user;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};
