export const removeNullElement = <T extends Record<string, any>>(values: T) => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      if (typeof value === 'object' && value !== null && !(Object.prototype.toString.call(value) === '[object Date]')) {
        const temp = removeNullElement(value)
        if(Object.keys(temp).length){
          acc[key as keyof T] = temp;
        }
      } else {
        if (value !== null) {
          acc[key as keyof T] = value;
        }
      }
      return acc;
    }, {} as Record<keyof T, any>);
  };
  