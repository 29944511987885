import api from '../../common/api/api';
import { Task, ItechnicianTaskInput } from '../types/types';

interface myResTechnicianTaskPatch {
  data: Task;
}

export const patchTechnicianTask = (props: { id: number; data: ItechnicianTaskInput }): Promise<myResTechnicianTaskPatch> => {
  return api.patch(`tasks/job/${props.id}`, props.data);
};
