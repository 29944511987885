import {
  ActionIcon,
  Alert,
  Button,
  Code,
  Container,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Table,
  TextInput,
  Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  TbAlertOctagon,
  TbAlertTriangle,
  TbCircleCheck,
  TbDownload,
  TbPlus
} from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
  
  import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { noEmptyString, useCsv } from '../../../utils';
import { removeNullElement } from '../../../utils/removeNullElements';
import { IaxiosError } from '../../common';
import { patchOrganizations } from '../api';
  
  export function EditMultipleOrganizationsCSV() {
    const [file, setFile] = useState<File | null>(null);
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();
    const viewSubmit = document.getElementById('submit')
    const viewTopOfPage = document.getElementById('TopOfPage')
  
    const requirments = {
      id: /^\d+$/,
      name: /^$|^[\s\S]*$/,
      type: /^$|\b(ats|client|vendor|supplier|other)\b/,
      status: /^$|\b(active|inactive|potential|lost)\b/,
      tradeLicense: /^$|[A-Za-z0-9]$/,
      trn: /^$|[A-Za-z0-9]$/,
      trafficFile: /^$|[A-Za-z0-9]$/,
      abbreviation: /^$|[A-Za-z0-9]$/,
      parent: /^$|[0-9]*$/,
    };
  
    const templateText = `${Object.keys(requirments).join(',')}\r\n`;
  
    const { csvParser, data, headers, errorRows, validate, setItem, addRow } =
      useCsv(requirments, Object.keys(requirments));
  
    function handleSelectFile(value: File | null) {
      setFile(value);
      if (value) {
        csvParser(value);
      }
    }

    useEffect(() => {
      if(errorRows.length === 0){
        viewSubmit?.scrollIntoView()
      }
    }, [data])
  
    const {
      mutate: patchMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(patchOrganizations, {
      onSuccess: () => {
        setAlert(null);
        queryClient.invalidateQueries([types.Organization, types.ALL], {
          exact: true,
        });
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });
  
    function handleSubmit() {
    setAlert(null);
    viewTopOfPage?.scrollIntoView();
    if (data) {
        const newValues = data.map((item) => noEmptyString(item)).map((item) => removeNullElement(item));

        patchMutate(newValues);
      } else {
        setAlert(
          'something went wrong while preparing the data, please reload the page and try again.',
        );
      }
    }
  
    const rows = data?.map((row, i) => (
      <tr key={'r' + i}>
        {headers.map((key, index) => (
          <td key={key + index}>
            <TextInput
              value={row[key]}
              error={errorRows[index]?.includes(i + 1)}
              variant="unstyled"
              onChange={(v) => setItem(i, key, v.target.value)}
            />
          </td>
        ))}
      </tr>
    ));
  
    return (
      <Layout>
        <Container pt={10} fluid>
          <Title
            order={2}
            pl={5}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Edit Multiple Organizations:
          </Title>
          <Paper withBorder shadow="md" p={30} my={30} radius="md">
            <Group position="apart">
              <UploadCSVButton file={file} onChange={handleSelectFile}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload CSV
                  </Button>
                )}
              </UploadCSVButton>
              <Button
                mt="md"
                component="a"
                href={`data:text/csv;charset=utf-8,${templateText}`}
                download={'newVehiclesTemplate.csv'}
                leftIcon={<TbDownload size={16} />}
              >
                Download a template
              </Button>
            </Group>
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Important!"
              withCloseButton
              color="blue"
              mt="md"
            >
              <List id='TopOfPage'>
                <List.Item>
                  Currently only accepts CSV files, file must include the headers. All values is optional except for ID.
                </List.Item>
                <List.Item>id: organization id from the system.</List.Item>
                <List.Item>name: must be any string.</List.Item>
                <List.Item>
                  type: must be one of these values(<Code>ats</Code>,{' '}
                  <Code>client</Code>, <Code>vendor</Code>, <Code>supplier</Code>,{' '}
                  <Code>other</Code>).
                </List.Item>
                <List.Item>
                Status: must be one of these values(<Code>potential</Code>,{' '}
                <Code>active</Code>, <Code>inactive</Code>, <Code>lost</Code>).
              </List.Item>
                <List.Item>tradeLicense: any value but no special characters.</List.Item>
                <List.Item>trn: any value but no special characters.</List.Item>
                <List.Item>trafficFile: any value but no special characters.</List.Item>
                <List.Item>abbreviation: must be 2 to 3 letters.</List.Item>
                <List.Item>parent: can be any number or empty.</List.Item>
              </List>
            </Alert>
            {data && Object.keys(requirments).length !== headers.length && (
              <Alert
                icon={<TbAlertTriangle size={16} />}
                title="Warning!"
                color="orange"
                mt="md"
              >
                The number of column uploaded is different than expected!
              </Alert>
            )}
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
            {validate() && (
              <Alert
                icon={<TbAlertTriangle size={16} />}
                title="Error!"
                color="red"
                mt="md"
              >
                <List>
                  <List.Item>Errors in the following rows:</List.Item>
                  {errorRows.map((errorRow, i) => (
                    <List.Item key={`error-${headers[i]}`}>
                      {headers[i]}: {errorRow.join(', ')}
                    </List.Item>
                  ))}
                </List>
              </Alert>
            )}
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
                mt="md"
              >
                Successfully added to the database!
              </Alert>
            )}
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                mt="md"
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
            {data && (
              <Table striped highlightOnHover mt="md">
                <thead>
                  <tr>
                    {headers.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            )}
            <Group position="right" mt="md">
              <ActionIcon size="lg" variant="subtle">
                <TbPlus size={28} onClick={() => addRow()} />
              </ActionIcon>
            </Group>
            <Button
              id="submit"
              fullWidth
              disabled={!file || validate() || newLoading}
              onClick={handleSubmit}
              mt="md"
            >
              Submit
            </Button>
          </Paper>
        </Container>
      </Layout>
    );
  }
  