import { MapContainer, Marker } from 'react-leaflet';
import { BaseMap, Resizable } from '../components';
import {
  ActionIcon,
  Checkbox,
  ColorSwatch,
  Group,
  Paper,
  ScrollArea,
  Select,
  Text,
  rem,
  Tooltip,
  HoverCard,
  useMantineTheme,
  TextInput,
  Divider,
  Stack,
  Box,
} from '@mantine/core';
import { URLParams, ago } from '../utils';
import { useEffect, useMemo, useState } from 'react';
import {
  TbBrandSpeedtest,
  TbEngine,
  TbLayoutColumns,
  TbLayoutRows,
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
  TbMapPin,
  TbSearch,
  TbX,
} from 'react-icons/tb';
import { IfullUnit, UnitCard, UnitWithAllExtras, useUnits } from '../modules/unit';
import { Virtuoso } from 'react-virtuoso';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { UserRole } from '../modules/users';
import { DetailsCard } from '../components/detailsCards';
import { useDeepFilter } from '../components/card';
import { useUnitLastMessages } from '../modules/common';
import { useInterval } from '@mantine/hooks';

export function LiveMap() {
  const unitHoverCardHeight = 400;
  const unitHoverCardWidth = 600;

  const [side, setSide] = useState(false);
  const [vertical, setVertical] = useState(false);
  const coords: [number, number] = [25.097233744121343, 55.181343725493576];
  const [mapStats, setMapStats] = useState<{ center: [number, number]; zoom: number }>({ center: coords, zoom: 10 });
  const [selectedUnits, setSelectedUnits] = useState<IfullUnit[]>([]);
  const [search, setSearch] = useState('');
  const { user } = useAuthContext();
  const theme = useMantineTheme();

  const [data, setData] = useState<UnitWithAllExtras>();
  const [image, setImage] = useState<number | null>(null);

  const navigate = useNavigate();

  const unitParams = new URLParams();
  unitParams.includes(
    'deviceConfig',
    'deviceConfig.unitLastMessage as unitLastMessage',
    'vehicle',
    'vehicle.organization as org',
    'device',
    'simCard',
  );
  unitParams.filterSetField('vehicle', 'null');
  unitParams.filterSetField('device', 'null');
  unitParams.filterSetField('simCard', 'null');
  unitParams.filterSetField('deviceConfig.unitLastMessage', 'null');
  if (user?.role === UserRole.Client && user?.orgnizationId) unitParams.filterSetField('=org.id', user.orgnizationId);
  const { data: units } = useUnits({ params: unitParams.toString() });

  const { data: lastMessages, refetch } = useUnitLastMessages({
    then: (unitsLastMessages) => {
      if (units)
        units.data.items.map((unit) => {
          unit.deviceConfig.unitLastMessage =
            unitsLastMessages.data.items.find((message) => message.externalId === unit.deviceConfig.unitLastMessage?.externalId) || null;
        });
      return unitsLastMessages;
    },
  });

  const fields = ['device.imei', 'vehicle.plateNumber', 'vehicle.vin', 'simCard.number'];
  if (user?.role !== UserRole.Client) {
    fields.push('vehicle.organization.name');
    fields.push('vehicle.organization.abbreviation');
  }
  const { filter, setFilter, formattedData: formattedUnits } = useDeepFilter(units ? units.data.items : [], fields);

  const refetchInterval = useInterval(() => refetch(), 10000);

  useEffect(() => {
    refetchInterval.start();
    return refetchInterval.stop;
  }, []);

  function online(time: number | undefined | null, engine: number | null | undefined) {
    const now = Math.floor(Date.now() / 1000);
    if (time) {
      const diff = now - time;
      if (engine === 1) {
        if (diff < 60 * 10) return 'green';
        else return 'red';
      } else {
        if (diff < 3 * 60 * 60) return 'green';
        else return 'red';
      }
    } else return 'orange';
  }

  const memoedMap = useMemo(
    () => <BaseMap coords={mapStats.center} zoom={mapStats.zoom} setMapStats={setMapStats} />,
    [mapStats, side, vertical],
  );

  return (
    <Box h="100vh">
      <Resizable initialSideSize={0.4} sideMinSize={0.3} sideMaxSize={0.6} vertical={vertical}>
        <Resizable.First hide={!side}>
          <Stack h="100%" spacing={0}>
            <TextInput
              m="xs"
              placeholder="Search here..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              icon={<TbSearch size={16} />}
            />
            <Paper mx="xs" px="xs">
              <Group position="apart" noWrap>
                <Group noWrap>
                  <Checkbox
                    checked={selectedUnits.length === formattedUnits.length}
                    indeterminate={formattedUnits && selectedUnits.length > 0 && selectedUnits.length < formattedUnits.length}
                    onChange={() => {
                      const allSelected = selectedUnits.length === formattedUnits.length;
                      setSelectedUnits(allSelected ? [] : formattedUnits || []);
                    }}
                  />
                  <Text truncate>Unit</Text>
                </Group>
                <Group noWrap spacing="xs">
                  <ColorSwatch size={12} color="gray" />
                  <Text size={14} truncate>
                    Last Reporting
                  </Text>
                  <TbEngine size={14} />
                  <Group noWrap>
                    <TbBrandSpeedtest size={14} />
                    <Text miw={68} truncate>
                      speed
                    </Text>
                  </Group>
                  <TbMapPin size="1rem" />
                </Group>
              </Group>
            </Paper>
            <Divider mt="xs" />
            <Virtuoso
              style={{ height: '100%' }}
              totalCount={formattedUnits.length}
              components={{
                Scroller: ScrollArea,
              }}
              itemContent={(index) => {
                const item = formattedUnits[index];
                if (!item) return null;
                return (
                  <HoverCard
                    styles={{ dropdown: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] } }}
                    width={unitHoverCardWidth}
                    shadow="md"
                    withArrow
                    openDelay={500}
                    closeDelay={200}
                    withinPortal
                    zIndex={100}
                  >
                    <Paper withBorder m="xs" py={3} key={`unit-${item.id}`}>
                      <Group position="apart" noWrap>
                        <Group noWrap>
                          <Checkbox
                            checked={selectedUnits.includes(item)}
                            ml="xs"
                            onChange={(v) => {
                              if (v.target.checked) {
                                const newCenter: [number, number] =
                                  item.deviceConfig.unitLastMessage?.latitude && item.deviceConfig.unitLastMessage?.longitude
                                    ? [item.deviceConfig.unitLastMessage?.latitude, item.deviceConfig.unitLastMessage?.longitude]
                                    : mapStats.center;
                                setMapStats({ center: newCenter, zoom: mapStats.zoom });
                              }
                              if (selectedUnits.includes(item)) setSelectedUnits(selectedUnits.filter((u2) => u2 !== item));
                              else setSelectedUnits([...selectedUnits, item]);
                            }}
                          />
                          <HoverCard.Target>
                            <Text truncate>{`${
                              user?.role !== UserRole.Client && item.vehicle.organization.abbreviation
                                ? `${item.vehicle.organization.abbreviation}: `
                                : ''
                            }${item.vehicle.plateNumber ?? item.vehicle.vin}`}</Text>
                          </HoverCard.Target>
                        </Group>
                        {/* <Group h={'100%'}> */}
                        <Group noWrap spacing="xs">
                          <Tooltip
                            label={
                              online(item.deviceConfig.unitLastMessage?.messageTime, item.deviceConfig.unitLastMessage?.engine) === 'green'
                                ? 'Online'
                                : online(item.deviceConfig.unitLastMessage?.messageTime, item.deviceConfig.unitLastMessage?.engine) ===
                                  'orange'
                                ? 'Loading'
                                : 'Offline'
                            }
                          >
                            <ColorSwatch
                              size={12}
                              color={online(item.deviceConfig.unitLastMessage?.messageTime, item.deviceConfig.unitLastMessage?.engine)}
                            />
                          </Tooltip>
                          <Text size={14} truncate>
                            {ago(item.deviceConfig.unitLastMessage?.messageTime || 0)}
                          </Text>
                          <Tooltip label="Engine">
                            <div>
                              <TbEngine size={14} color={item.deviceConfig.unitLastMessage?.engine === 1 ? 'green' : 'red'} />
                            </div>
                          </Tooltip>
                          <Group noWrap>
                            <TbBrandSpeedtest size={14} />
                            <Text miw={68} truncate>
                              {item.deviceConfig.unitLastMessage?.speed ?? '?'} Km/h
                            </Text>
                          </Group>
                          <ActionIcon
                            variant="subtle"
                            onClick={() => {
                              const newCenter: [number, number] =
                                item.deviceConfig.unitLastMessage?.latitude && item.deviceConfig.unitLastMessage?.longitude
                                  ? [item.deviceConfig.unitLastMessage?.latitude, item.deviceConfig.unitLastMessage?.longitude]
                                  : mapStats.center;
                              // console.log(newCenter);
                              setMapStats({ center: newCenter, zoom: 13 });
                            }}
                          >
                            <TbMapPin size="1rem" />
                          </ActionIcon>
                        </Group>
                        {/* </Group> */}
                      </Group>
                    </Paper>
                    <HoverCard.Dropdown>
                      <DetailsCard cardHei={unitHoverCardHeight} id={item.id} image={image} withImage>
                        <UnitCard setImage={setImage} id={item.id} setData={setData} />
                      </DetailsCard>
                    </HoverCard.Dropdown>
                  </HoverCard>
                );
              }}
            />
          </Stack>
        </Resizable.First>
        <Resizable.Second>
          <MapContainer center={mapStats.center} zoom={mapStats.zoom} scrollWheelZoom={true} style={{ height: '100%' }}>
            {/* <MyComponent /> */}
            {memoedMap}
            {selectedUnits.map((u) =>
              u.deviceConfig.unitLastMessage?.latitude && u.deviceConfig.unitLastMessage?.longitude ? (
                <Marker
                  key={`marker-${u.id}`}
                  position={[u.deviceConfig.unitLastMessage?.latitude, u.deviceConfig.unitLastMessage?.longitude]}
                />
              ) : undefined,
            )}
            <Group p="xs" position="right" spacing="xs" style={{ position: 'relative', zIndex: 90000 }}>
              <ActionIcon size={side ? 'md' : rem(33)} variant="filled" onClick={() => setVertical(!vertical)}>
                {vertical ? <TbLayoutColumns size="1rem" /> : <TbLayoutRows size="1rem" />}
              </ActionIcon>
              <ActionIcon size={side ? 'md' : rem(33)} variant="filled" onClick={() => setSide(!side)}>
                {side ? <TbLayoutSidebarLeftCollapse size="1rem" /> : <TbLayoutSidebarLeftExpand size="1rem" />}
              </ActionIcon>
              {!side && (
                <Select
                  searchable
                  searchValue={search}
                  onSearchChange={(v) => {
                    setSearch(v);
                  }}
                  value={selectedUnits.map((u) => `${u.id}`)[0] ?? ''}
                  // value={search}
                  onChange={(v) => {
                    console.log(v);
                    // setSearch(v ?? '');
                    if (v) {
                      const unit = units?.data.items.find((u) => u.id === +v);
                      if (unit) {
                        setSelectedUnits([unit]);
                        const newCenter: [number, number] =
                          unit.deviceConfig.unitLastMessage?.latitude && unit.deviceConfig.unitLastMessage?.longitude
                            ? [unit.deviceConfig.unitLastMessage?.latitude, unit.deviceConfig.unitLastMessage?.longitude]
                            : mapStats.center;
                        console.log(newCenter);
                        setMapStats({ center: newCenter, zoom: 10 });
                      }
                    }
                  }}
                  rightSection={<></>}
                  hoverOnSearchChange
                  limit={5}
                  nothingFound="Nothing found"
                  placeholder="Search unit"
                  // withinPortal
                  data={
                    units?.data.items.map((u) => ({
                      value: `${u.id}`,
                      label: `${u.vehicle?.plateNumber ?? u.vehicle?.vin}`,
                      // latitude: u.deviceConfig.unitLastMessage?.latitude,
                      // longitude: u.deviceConfig.unitLastMessage?.longitude,
                    })) ?? []
                  }
                  // styles={{ dropdown: { zIndex: 100000 } }}
                />
              )}
              <ActionIcon size={side ? 'md' : rem(33)} variant="filled" color="red" onClick={() => navigate('/services')}>
                <TbX size="1rem" />
              </ActionIcon>
            </Group>
          </MapContainer>
        </Resizable.Second>
      </Resizable>
    </Box>
  );
}
