import { Alert, Button, Center, Container, Divider, Grid, Group, List, LoadingOverlay, Paper, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useListState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { TbAlertOctagon, TbCircleCheck } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { DetailsCard } from "../../../../components/detailsCards";
import { SelectEditable, TextInputEditable } from "../../../../components/form";
import { types } from "../../../../constants";
import { URLParams, enumToSelectData, selectColumns } from "../../../../utils";
import { IaxiosError, UploadButton, UploadButtonMultiple, commonConstants, uploadMultiplefilesProps, useUploadImage } from "../../../common";
import { patchOrganization, useOrganization, useOrganizations } from "../../api";
import { ContactsColumnsMode, OrganizationColumnsMode, OrganizationStatus, OrganizationType } from "../../constants/constants";
import { IorganizationUpdateInput } from "../../types/types";
import { OrganizationCard } from "../organizationCard";
import { UpdateMultipleContactsForm } from "./components";
import { ContactSummary } from "./components/ContactSummary";

interface Ifiles {
  file: File | null;
  name: string | null;
  tags: string | null;
  folder: string | null;
  itemId: number | null;
  defaultImage?: string;
}

export function UpdateOrganizationForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [organizationParentsSelect, setOrganizationParentsSelect] = useState([
    { value: '', label: '' },
  ]);
  const [organizationParentId, setOrganizationParentId] = useState(0);

  const [attachments, setAttachments] = useState<uploadMultiplefilesProps[]>([]);
  const [multipleFiles, MultipleFilesHandlers] = useListState<Ifiles>(
    Array(4).fill({ file: null, name: null, tags: null }),
  );
  const { progress, uploadCompleted, uploadMultiple } = useUploadImage();

  const [openEdit, setOpenEdited] = useState(-1)
  const [deleteContact, setDeleteContact] = useState(false)
  const [deleteContactID, setDeleteContactID] = useState(-1)

  const queryClient = useQueryClient();

  const { data: organizationData } = useOrganization(idN, {
    joins: ['parent', 'contacts'],
    selects: [...selectColumns(OrganizationColumnsMode), 'parent.id', ...selectColumns(ContactsColumnsMode, { join: 'contacts'})],
    then: (organization) => {
      form.setValues({
        name: organization.data.name,
        type: organization.data.type,
        status: organization.data.status,
        tradeLicense: organization.data.tradeLicense ? organization.data.tradeLicense : '',
        trn: organization.data.trn ? organization.data.trn : '',
        trafficFile: organization.data.trafficFile ? organization.data.trafficFile : '',
        abbreviation: organization.data.abbreviation,
        parent: {
          id: organization.data.parent ? organization.data.parent.id : 0,
        },
        contacts: organization.data.contacts
      });
      setOrganizationParentId(
        organization.data.parent ? organization.data.parent.id : 0,
      );
      form.resetDirty({
        name: organization.data.name,
        type: organization.data.type,
        status: organization.data.status,
        tradeLicense: organization.data.tradeLicense ? organization.data.tradeLicense : '',
        trn: organization.data.trn ? organization.data.trn : '',
        trafficFile: organization.data.trafficFile ? organization.data.trafficFile : '',
        abbreviation: organization.data.abbreviation,
        parent: {
          id: organization.data.parent ? organization.data.parent.id : 0,
        },
        contacts: organization.data.contacts ? organization.data.contacts.map(item => {
          return {
            id: item.id,
            name: item.name,
            position: item.position,
            email: item.email,
            phone: item.phone,
          }
        }) : []
      })
    },
  });
  const contacts = {
    name: '',
    position: '',
    phone: '',
    email: '',
  }

  const form = useForm<IorganizationUpdateInput>({
    initialValues: {
      name: organizationData?.data.name || '',
      type: organizationData?.data.type || '',
      status: organizationData?.data.status || '',
      tradeLicense: organizationData?.data.tradeLicense || '',
      trn: organizationData?.data.trn || '',
      trafficFile: organizationData?.data.trafficFile || '',
      abbreviation: organizationData?.data.abbreviation || '',
      parent: {
        id: organizationData?.data.parent?.id || 0,
      },
      contacts: organizationData?.data.contacts.map(item => {
        return {
          id: item.id,
          name: item.name,
          position: item.position,
          email: item.email,
          phone: item.phone,
        }
      }) || [],
    },
  });

  const orgParams = new URLParams()
  orgParams.select('id', 'name')
  const { data: organizationsData } = useOrganizations({ params: orgParams.toString() });
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((organization) => {
        return { value: `${organization.id}`, label: `${organization.name}` };
      });
      tempArr.unshift({ value: '0', label: 'No parent' });
      setOrganizationParentsSelect(tempArr);
    }
  }, [organizationsData]);

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchOrganization, {
    onSuccess: (data) => {
      setAlert(null);
      const imageRepositoryId = data.data.imageRepositoryId;
      const filestToUpload = [
        ...multipleFiles.filter((item) => item.file !== null),
      ] as uploadMultiplefilesProps[];
      if (imageRepositoryId && filestToUpload) {
        uploadMultiple(imageRepositoryId, filestToUpload)
        if (attachments.length) uploadMultiple(imageRepositoryId, attachments)
      }
      queryClient.invalidateQueries([types.Organization, types.ALL]);
      queryClient.invalidateQueries([types.Organization, { id: idN }]);
      queryClient.invalidateQueries([
        types.History,
        types.Organization,
        { id: idN },
      ]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IorganizationUpdateInput) {
    setAlert(null);
    const newValues: IorganizationUpdateInput = {
      name: values.name,
      type: values.type,
      status: values.status,
      tradeLicense: values.tradeLicense,
      trn: values.trn,
      trafficFile: values.trafficFile,
      abbreviation: values.abbreviation,
      parent: values.parent && values.parent.id !== 0 && values.parent.id !== null ? {
        id: values.parent.id,
      } : null,
      contacts: values.contacts.map((item) => {
        return {
          id: item.id,
          name: item.name,
          position: item.position,
          phone: item.phone,
          email: item.email,
        }
      })
    }
    if (newValues?.parent?.id === null || newValues?.parent?.id === 0) {
      newValues.parent = null;
    }
    const filestToUpload = [
      ...multipleFiles.filter((item) => item.file !== null),
    ] as uploadMultiplefilesProps[];

    if (idN !== 0 && !Number.isNaN(idN)) {
      if (Object.keys(newValues).length) {
        patchMutate({ id: idN, data: newValues });
      }
      if (organizationData && organizationData.data.imageRepositoryId && filestToUpload) {
        uploadMultiple(organizationData.data.imageRepositoryId, filestToUpload)
        if (attachments.length) uploadMultiple(organizationData.data.imageRepositoryId, attachments)

        queryClient.invalidateQueries([types.Images, { id: organizationData.data.imageRepositoryId }]);
      }
    } else {
      setAlert(['The id provided is incorrect']);
    }
  }

  const contact = form.values.contacts.map((contact, index) => {
    return openEdit === index ? <UpdateMultipleContactsForm key={index} form={form} setOpenEdited={setOpenEdited} contactIndex={index} />
      : <ContactSummary key={index} contact={contact} setOpenEdited={setOpenEdited} contactIndex={index} setDeleteContact={setDeleteContact} setDeleteContactID={setDeleteContactID} />
  })
  if (deleteContact) {
    setDeleteContact(false)
    form.values.contacts.splice(deleteContactID, 1)
  }

  return (
    <>

      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit an Organiztion:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {(patchSuccess || uploadCompleted) && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Updated!'}
              color="green"
              mb={10}
            >
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              dirty={form.isDirty('name')}
              label="Name"
              placeholder="Organization Name"
              {...form.getInputProps('name')}
              onChange={(v) => {
                form.setFieldValue('name', v.target.value);
                if (v.target.value.trim().split(' ').length > 1) {
                  const value = v.target.value.trim().split(' ').flatMap((item) => item[0].toUpperCase()).join('')
                  form.setFieldValue('abbreviation', value)
                } else {
                  const value = v.target.value.trim().slice(0, 3)
                  form.setFieldValue('abbreviation', value)
                }
              }}

            />
            <SelectEditable
              dirty={form.isDirty('type')}
              label="Type"
              placeholder="Search for Type of Organization"
              {...form.getInputProps('type')}
              data={enumToSelectData(OrganizationType)}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('status')}
              label="Status"
              placeholder="Search for Status of Organization"
              {...form.getInputProps('status')}
              data={enumToSelectData(OrganizationStatus)}
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('tradeLicense')}
              label="Trade License"
              placeholder="Trade License"
              {...form.getInputProps('tradeLicense')}
              mt="md"

            />
            <TextInputEditable
              dirty={form.isDirty('trafficFile')}
              label="Traffic File"
              placeholder="Traffic File"
              {...form.getInputProps('trafficFile')}
              mt="md"

            />
            <TextInputEditable
              dirty={form.isDirty('trn')}
              label="TRN"
              placeholder="TRN"
              {...form.getInputProps('trn')}
              mt="md"

            />
            <TextInputEditable
              dirty={form.isDirty('abbreviation')}
              label="abbreviation"
              placeholder="abbreviation"
              {...form.getInputProps('abbreviation')}
              mt="md"

            />
            <SelectEditable
              dirty={form.isDirty('parent.id')}
              label="Parent"
              placeholder="Search for Parent Organization"
              {...form.getInputProps('parent.id')}
              onChange={(v) => {
                form.setFieldValue('parent.id', parseInt(v || '0'));
                setOrganizationParentId(parseInt(v || '0'));
              }}
              searchable
              nothingFound="Not found"
              data={organizationParentsSelect}
              value={`${form.values.parent?.id}`}
              mt="md"

            />
            <Title order={4} mt="md">
              Contacts:
            </Title>
            <Divider size="md" />
            {contact}
            {openEdit === -1 && <Center>
              <Button radius="xs" size="xs" m='md' ml={0} onClick={() => { setOpenEdited(form.values.contacts.length), form.insertListItem('contacts', contacts) }}>
                Add Contact
              </Button>
            </Center>}
            <UploadButton file={multipleFiles[0].file} onChange={(v) => {
              const profileIMG = {
                file: v,
                name: `${form.values.name}_${commonConstants.Profile}`,
                tags: `${types.Organization},${commonConstants.Profile}`,
                folder: types.Organization,
                itemId: idN,
                defaultImage: 'true'
              };
              MultipleFilesHandlers.setItem(0, profileIMG);
            }}
              progress={progress}>
              {(props) => (
                <Button {...props} mt="md">
                  Upload profile image
                </Button>
              )}
            </UploadButton>
            <Title order={4} mt='md'>Attachments:</Title>
            <Group position='apart'>
              <UploadButton file={multipleFiles[1].file} onChange={(v) => {
                const tradeLicense = {
                  file: v,
                  name: 'Trade_License',
                  tags: `Trade License`,
                  folder: types.Organization,
                  itemId: idN,
                };
                MultipleFilesHandlers.setItem(1, tradeLicense);
              }}
                progress={progress}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload Trade License
                  </Button>
                )}
              </UploadButton>
              <UploadButton file={multipleFiles[2].file} onChange={(v) => {
                const tradeLicense = {
                  file: v,
                  name: 'trafficFile',
                  tags: `Traffic File`,
                  folder: types.Organization,
                  itemId: idN,
                };
                MultipleFilesHandlers.setItem(2, tradeLicense);
              }}
                progress={progress}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload traffic File
                  </Button>
                )}
              </UploadButton>
              <UploadButton file={multipleFiles[3].file} onChange={(v) => {
                const tradeLicense = {
                  file: v,
                  name: 'trn',
                  tags: `TRN`,
                  folder: types.Organization,
                  itemId: idN,
                };
                MultipleFilesHandlers.setItem(3, tradeLicense);
              }}
                progress={progress}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload TRN
                  </Button>
                )}
              </UploadButton>
              <UploadButtonMultiple files={attachments.map(item => item.file)} onChange={(v) => {
                const values = v.map(item => {
                  return { file: item, name: '', tags: '', folder: types.Organization, itemId: idN }
                })
                setAttachments(values)
              }
              } progress={progress}>
                {(props) => (
                  <Button {...props} mt="md">
                    Others
                  </Button>
                )}
              </UploadButtonMultiple>
            </Group>
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {organizationParentId !== 0 && (
            <DetailsCard
              id={organizationParentId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter(
                  (org) => org.id === organizationParentId,
                )[0].imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationParentId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
}
