import api from "../../common/api/api";
import { Irfid, IrfidInput } from "../types/types";

interface myResRfidPatch {
	data: Irfid;
}

export const patchRfid = (props: { id: number; data: IrfidInput }): Promise<myResRfidPatch> => {
	return api.patch(`rfids/${props.id}`, props.data);
};
