import { Badge, Checkbox, Modal, Text, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { FaEdit } from 'react-icons/fa';
import { TbBook } from 'react-icons/tb';
import { ExportDevices } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { deviceStatusColor, types } from '../../../constants';
import { URLParams, selectColumns, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { DeviceGeneration, DeviceTypeColumnsMode, deviceGenerationColor } from '../../device-types/constants/constants';
import { useDevices } from '../api';
import { DeviceCard } from '../components';
import { DeviceHistoryReport } from '../components/DeviceHistoryReport';
import { DeviceColumnsMode, DeviceOwnership, DeviceStatus } from '../constants';
import { EditMultipleDevices } from '../forms/EditMultipleDevices';
import { IdeviceUnit } from '../types/types';

export function DeviceTable() {
  const [data, setData] = useState<IdeviceUnit[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  const [rowSelection, setRowSelection] = useState({});
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.sort('-device.id');
    param.includes('deviceType', 'unit', 'issuedTo', 'organization', 'organization.parent as parent');
    param.select(
      ...selectColumns(DeviceColumnsMode),
      ...selectColumns(DeviceTypeColumnsMode, { join: 'deviceType' }),
      'issuedTo.id',
      'issuedTo.firstName',
      'issuedTo.lastName',
      'unit.id',
      'organization.id',
      'organization.name',
      'parent.id',
      'parent.name',
    );
    param.page(10, page - 1);
    return param;
  });
  const { data: allDevices, refetch, isLoading } = useDevices({ params: params.toString() });
  useEffect(() => {
    if (allDevices) {
      const temp = allDevices.data.items.map((item) => item);
      setData(temp as unknown as IdeviceUnit[]);
      const selectedRows = allDevices.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allDevices]);

  const [opened, { open, close }] = useDisclosure(false);
  // const [ createReport, setCreateReport ] = useState(false)
  const [openedReport, { open: openReport, close: closeReport }] = useDisclosure(false);
  const customActions = [
    { id: 'multiEdit', multi: true, title: 'Edit Multiple', icon: FaEdit, color: 'orange', handler: open, noDisplay: ['record'] },
    { id: 'issueReport', multi: true, title: 'Issuing Report', icon: TbBook, color: 'yellow', handler: openReport, noDisplay: ['record'] },
  ];

  const columnHelper = useMemo(() => createColumnHelper<IdeviceUnit>(), []);

  const columns: ColumnDef<IdeviceUnit, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allDevices?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allDevices?.data.meta.ids ? allDevices?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => `${row.id}`, { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => `${row.imei}`, { header: 'imei', id: 'imei' }),
      columnHelper.accessor('status', {
        header: 'Status',
        id: 'status',
        cell: (props) => (
          <Badge
            variant="light"
            c={deviceStatusColor[props.getValue().split(' ').join('_') as keyof typeof deviceStatusColor]}
            styles={{
              root: { backgroundColor: `${deviceStatusColor[props.getValue().split(' ').join('_') as keyof typeof deviceStatusColor]}40` },
            }}
          >
            {props.getValue()}
          </Badge>
        ),
      }),

      columnHelper.accessor('deviceType.generation', {
        header: 'Generation',
        id: 'deviceType.generation',
        cell: (props) => (
          <Badge
            variant="light"
            c={deviceGenerationColor[props.getValue().split(' ').join('_') as keyof typeof deviceGenerationColor]}
            styles={{
              root: {
                backgroundColor: `${deviceGenerationColor[props.getValue().split(' ').join('_') as keyof typeof deviceGenerationColor]}40`,
              },
            }}
          >
            {props.getValue()}
          </Badge>
        ),
      }),

      columnHelper.accessor((row) => (row.ownership), { header: 'Onwership', id: 'ownership' }),
      columnHelper.accessor('deviceType.id', { header: 'DeviceType id', id: 'deviceType.id' }),
      columnHelper.accessor('deviceType.supplier', { header: 'DeviceType Supplier', id: 'deviceType.supplier' }),
      columnHelper.accessor('deviceType.type', { header: 'Device Type', id: 'deviceType.type' }),
      columnHelper.accessor((row) => (row.unit ? `${row.unit.id}` : ''), { header: 'Unit ID', id: 'unit.id' }),
      columnHelper.accessor((row) => (row.purchaseDate ? simpleDateFormat(row.purchaseDate) : ''), {
        header: 'Purchase Date',
        id: 'purchaseDate',
      }),
      columnHelper.accessor((row) => (row.issuedTo ? row.issuedTo.firstName : ''), { header: 'Issued To (FN)', id: 'issuedTo.firstName' }),
      columnHelper.accessor((row) => (row.issuedTo ? row.issuedTo.lastName : ''), { header: 'Issued To (LN)', id: 'issuedTo.lastName' }),
      columnHelper.accessor((row) => (row.organization ? row.organization.name : ''), { header: 'Organization', id: 'organization.name' }),
      columnHelper.accessor((row) => row.organization?.parent?.name ?? '', { header: 'Parent', id: 'parent.name' }),
      columnHelper.accessor((row) => row.remarks ?? '', { header: 'Remarks', id: 'remarks' }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    'Purchase Date': 'date',
  };

  const toSelect = {
    Generation: DeviceGeneration,
    Status: DeviceStatus,
    Onwership: DeviceOwnership,
  };

  return (
    <Layout>
      <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
        Devices:
      </Title>
      <BaseTable<IdeviceUnit>
        data={data}
        columns={columns}
        setIds={setIds}
        defaultVisibleColumns={{
          'deviceType.id': false,
          'deviceType.supplier': false,
          'deviceType.generation': false,
          'issuedTo.lastName': false,
          remarks: false,
        }}
        loading={isLoading}
        page={page}
        ids={ids}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        csvExport={<ExportDevices ids={ids} />}
        pages={allDevices?.data.meta.ids.length ? Math.ceil(allDevices?.data.meta.ids.length / 10) : 1}
        toSelect={toSelect}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          
          refetch();
          setPage(1);
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        columnTypes={columnTypes}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
      />
      <SideBar id={ids} type={types.Device} customActions={customActions}>
        <DeviceCard id={ids[0]} />
      </SideBar>
      <Modal
        opened={opened}
        onClose={close}
        size="90%"
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Edit Multiple Devices
          </Text>
        }
      >
        {ids.length < 100 && <EditMultipleDevices ids={ids} />}
        {ids.length >= 100 && (
          <Text align="center" fw={500} fz="lg">
            Please Select less than 100 Device to perform Edit Multiple
          </Text>
        )}
      </Modal>
      <Modal
        opened={openedReport}
        onClose={closeReport}
        size="90%"
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Issued Devices Report
          </Text>
        }
      >
        <DeviceHistoryReport ids={ids} />
      </Modal>
    </Layout>
  );
}
