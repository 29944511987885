import { useState } from 'react';
import { ResizableFirst } from './ResizableFirst';
import { ResizableContextProvider } from './Resizable.context';
import { ResizableSecond } from './ResizableSecond';
import { ResizableCore } from './ResizableCore';

interface ResizableProps {
  // children: JSX.Element | JSX.Element[];
  children: JSX.Element[];
  initialSideSize: number;
  sideMinSize?: number;
  sideMaxSize?: number;
  vertical?: boolean;
}

//TODO: hide resizer div when one of the children is hidden
export function Resizable({ children, initialSideSize, sideMinSize, sideMaxSize, vertical = false }: ResizableProps) {
  const [sideSize, setSideSize] = useState({ x: initialSideSize, y: initialSideSize });

  return (
    <ResizableContextProvider
      value={{
        sideSize,
        vertical,
        hideFirst: children.find((child) => child?.type?.displayName === 'ResizableFirst')?.props?.hide,
        hideSecond: children.find((child) => child?.type?.displayName === 'ResizableSecond')?.props?.hide,
      }}
    >
      <ResizableCore setSideSize={setSideSize} sideMinSize={sideMinSize} sideMaxSize={sideMaxSize}>
        {children}
      </ResizableCore>
    </ResizableContextProvider>
  );
}

Resizable.First = ResizableFirst;
Resizable.Second = ResizableSecond;
