import {
  Alert,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  List,
  LoadingOverlay,
  Modal,
  Paper,
  SegmentedControl,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck, TbPlus } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { DetailsCard } from '../../../../components/detailsCards';
import { DatePickerInputEditable, NumberInputEditable, SelectEditable, TextInputEditable } from '../../../../components/form';
import { DisplayCollection } from '../../../../components/images';
import Layout from '../../../../components/layout/Layout';
import { colorSelect, types } from '../../../../constants';
import { URLParams, enumToSelectData, noEmptyString, selectColumns } from '../../../../utils';
import { IaxiosError, commonConstants } from '../../../common';
import { DeviceOwnership, patchDevice } from '../../../devices';
import { OrganizationCard, useOrganizations } from '../../../organizations';
import { DeviceConfigColumnsMode, UnitWithAllExtras, UpdateUnitForm, WialonExternalId, useUnits } from '../../../unit';
import { UserCard, useUsers } from '../../../users';
import { NewVehicleTypeForm, VehicleTypeColumnsMode, useVehicleTypes } from '../../../vehicle-types';
import { VehicleColumnsMode, placeOfIssue } from '../../../vehicles';
import { patchApproveTask, useTask } from '../../api';
import { TaskColumnsMode, TaskStatus, TaskType } from '../../constants/constants';
import { IapproveTaskInput } from '../../types/types';

export function TaskInstallationApprovalForm() {
  const { id } = useParams();
  const [idN] = useState(+(id || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [modalOpened, { open, close }] = useDisclosure(false);
  const [organizationsSelect, setOrganizationsSelect] = useState([{ value: '', label: '' }]);
  const [organizationId, setOrganizationId] = useState(0);
  const [unitsSelect, setUnitsSelect] = useState([{ value: '', label: '' }]);
  const [usersSelect, setUsersSelect] = useState([{ value: '', label: '' }]);
  const [userId, setUserId] = useState(0);
  const [vehicleTypeSelect, setVehicleTypeSelect] = useState([{ value: '', label: 'Loading...' }]);
  const [opened, setOpened] = useState(false);
  const [wialonOpened, { open: openWialon, close: closeWialon }] = useDisclosure(false);

  const largeScreen = useMediaQuery('(min-width: 768px)');
  const queryClient = useQueryClient();

  const [odometer, setOdometer] = useState('odometerKM');

  const { data: taskData } = useTask(idN, {
    joins: [
      'unit',
      'unit.vehicle as unitVehicle',
      'unitVehicle.organization as unitOrg',
      'unitVehicle.vehicleType as unitVehicleType',
      'unit.deviceConfig as unitDeviceConfig',
      'unitDeviceConfig.unitLastMessage as unitLastMessage',
      'vehicle',
      'vehicle.organization as vehicleOrg',
      'vehicle.vehicleType as vehicleType',
      'installationUnit',
      'installationUnit.device as device',
      'performedBy',
      'organization',
    ],
    selects: [
      ...selectColumns(TaskColumnsMode),
      ...selectColumns(VehicleColumnsMode, { join: 'vehicle' }),
      ...selectColumns(VehicleColumnsMode, { join: 'unitVehicle' }),
      ...selectColumns(VehicleTypeColumnsMode, { join: 'vehicleType' }),
      'unit.id',
      'installationUnit.id',
      'device.id',
      'device.ownership',
      'performedBy.id',
      'organization.id',
      'vehicleOrg.id',
      'unitOrg.id',
      ...selectColumns(DeviceConfigColumnsMode, { join: 'unitDeviceConfig' }),
      ...selectColumns(VehicleTypeColumnsMode, { join: 'unitVehicleType' }),
      'unitLastMessage.id',
      'unitLastMessage.externalId',
      'unitLastMessage.messageTime',
      'unitLastMessage.latitude',
      'unitLastMessage.longitude',
      'unitLastMessage.altitude',
      'unitLastMessage.engine',
      'unitLastMessage.speed',
    ],
    then: (task) => {
      form.setValues({
        title: task.data.title,
        type: task.data.type,
        address: task.data.address || '',
        targetDate: task.data.targetDate ? new Date(task.data.targetDate) : null,
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : new Date(),
        organization: { id: task.data.organization.id },
        vehicle: {
          id: task.data.vehicle ? task.data.vehicle.id : task.data.unit.vehicle?.id,
          vin: task.data.vehicle ? task.data.vehicle.vin : task.data.unit.vehicle?.vin,
          category: task.data.vehicle ? task.data.vehicle.category : task.data.unit.vehicle?.category,
          plateNumber: task.data.vehicle ? task.data.vehicle.plateNumber ?? '' : task.data.unit.vehicle?.plateNumber ?? '',
          placeOfIssue: task.data.vehicle ? task.data.vehicle.placeOfIssue : task.data.unit.vehicle?.placeOfIssue,
          odometerKM: task.data.vehicle ? task.data.vehicle.odometerKM ?? '' : task.data.unit.vehicle?.odometerKM ?? '',
          odometerHours: task.data.vehicle ? task.data.vehicle.odometerHours ?? '' : task.data.unit.vehicle?.odometerHours ?? '',
          vehicleType: {
            id: task.data.vehicle ? task.data.vehicle.vehicleType.id : task.data.unit.vehicle?.vehicleType?.id ?? NaN,
          },
          organization: {
            id: task.data.vehicle ? task.data.vehicle.organization.id : task.data.unit.vehicle?.organization.id,
          },
          color: task.data.vehicle ? task.data.vehicle.color : task.data.unit.vehicle?.color,
        },
        unit: {
          id: task.data.unit ? task.data.unit.id : 0,
        },
        installationUnit: {
          id: task.data.installationUnit ? task.data.installationUnit.id : 0,
        },
        deviceOnwership: task.data.installationUnit?.device?.ownership || '',
        performedBy: {
          id: task.data.performedBy ? task.data.performedBy.id : NaN,
        },
        status: task.data.status || TaskStatus.Done,
      });
      form.resetDirty({
        title: task.data.title,
        type: task.data.type,
        address: task.data.address || '',
        targetDate: task.data.targetDate ? new Date(task.data.targetDate) : null,
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : new Date(),
        organization: { id: task.data.organization.id },
        vehicle: {
          id: task.data.vehicle ? task.data.vehicle.id : task.data.unit.vehicle?.id,
          vin: task.data.vehicle ? task.data.vehicle.vin : task.data.unit.vehicle?.vin,
          category: task.data.vehicle ? task.data.vehicle.category : task.data.unit.vehicle?.category,
          plateNumber: task.data.vehicle ? task.data.vehicle.plateNumber ?? '' : task.data.unit.vehicle?.plateNumber ?? '',
          placeOfIssue: task.data.vehicle ? task.data.vehicle.placeOfIssue : task.data.unit.vehicle?.placeOfIssue,
          odometerKM: task.data.vehicle ? task.data.vehicle.odometerKM ?? '' : task.data.unit.vehicle?.odometerKM ?? '',
          odometerHours: task.data.vehicle ? task.data.vehicle.odometerHours ?? '' : task.data.unit.vehicle?.odometerHours ?? '',
          vehicleType: {
            id: task.data.vehicle ? task.data.vehicle.vehicleType.id : task.data.unit.vehicle?.vehicleType?.id ?? NaN,
          },
          organization: {
            id: task.data.vehicle ? task.data.vehicle.organization.id : task.data.unit.vehicle?.organization.id,
          },
          color: task.data.vehicle ? task.data.vehicle.color : task.data.unit.vehicle?.color,
        },
        unit: {
          id: task.data.unit ? task.data.unit.id : 0,
        },
        installationUnit: {
          id: task.data.installationUnit ? task.data.installationUnit.id : 0,
        },
        deviceOnwership: task.data.installationUnit?.device?.ownership || '',
        performedBy: {
          id: task.data.performedBy ? task.data.performedBy.id : NaN,
        },
        status: task.data.status || TaskStatus.Done,
      });
      setOrganizationId(task.data.organization.id);
    },
  });

  const form = useForm<IapproveTaskInput>({
    initialValues: {
      title: taskData?.data.title || '',
      type: taskData?.data.type || '',
      address: taskData?.data.address || '',
      targetDate: taskData?.data.targetDate ? new Date(taskData.data.targetDate) : null,
      completionDate: taskData?.data.completionDate ? new Date(taskData.data.completionDate) : new Date(),
      deviceOnwership: taskData?.data.installationUnit?.device?.ownership || '',
      organization: {
        id: taskData?.data.organization.id || 0,
      },
      vehicle: {
        id: taskData?.data.vehicle ? taskData?.data.vehicle.id : taskData?.data.unit.vehicle?.id || 0,
        vin: taskData?.data.vehicle ? taskData?.data.vehicle.vin : taskData?.data.unit.vehicle?.vin || '',
        category: taskData?.data.vehicle ? taskData?.data.vehicle.category : taskData?.data.unit.vehicle?.category || '',
        plateNumber: taskData?.data.vehicle ? taskData?.data.vehicle.plateNumber ?? '' : taskData?.data.unit.vehicle?.plateNumber ?? '',
        placeOfIssue: taskData?.data.vehicle ? taskData?.data.vehicle.placeOfIssue : taskData?.data.unit.vehicle?.placeOfIssue || '',
        odometerKM: taskData?.data.vehicle ? taskData?.data.vehicle.odometerKM ?? '' : taskData?.data.unit.vehicle?.odometerKM ?? '',
        odometerHours: taskData?.data.vehicle
          ? taskData?.data.vehicle.odometerHours ?? ''
          : taskData?.data.unit.vehicle?.odometerHours ?? '',
        vehicleType: {
          id: taskData?.data.vehicle ? taskData?.data.vehicle.vehicleType.id : taskData?.data.unit.vehicle?.vehicleType?.id ?? NaN,
        },
        organization: {
          id: taskData?.data.vehicle ? taskData?.data.vehicle.organization.id : taskData?.data.unit.vehicle?.organization.id || 0,
        },
        color: taskData?.data.vehicle ? taskData?.data.vehicle.color : taskData?.data.unit.vehicle?.color || '',
      },
      unit: {
        id: taskData?.data.unit?.id || 0,
      },
      installationUnit: {
        id: taskData?.data.installationUnit?.id || 0,
      },
      performedBy: {
        id: taskData?.data.performedBy?.id || NaN,
      },
      status: taskData?.data.status || TaskStatus.Done,
    },
  });

  const { data: vehicleTypesData } = useVehicleTypes();
  useEffect(() => {
    if (vehicleTypesData) {
      const tempArr = vehicleTypesData.data.items.map((type) => {
        return {
          value: `${type.id}`,
          label: `${type.model}, ${type.brand}, ${type.year}`,
        };
      });
      tempArr.unshift({ value: '', label: 'No type' });
      setVehicleTypeSelect(tempArr);
    }
  }, [vehicleTypesData]);

  const unitParams = new URLParams();
  unitParams.includes('device', 'simCard', 'deviceConfig', 'device.deviceType');
  const { data: unitsData } = useUnits({
    params: unitParams.toString(),
  });
  useEffect(() => {
    if (unitsData) {
      const tempArr = unitsData.data.items.map((unit) => {
        return {
          value: `${unit.id}`,
          label: `${unit.device?.imei}, ${unit.simCard?.number}, ${unit.deviceConfig?.platform ?? ''}, 
          ${unit.deviceConfig?.secondaryPlatform ? unit.deviceConfig?.secondaryPlatform : ''}`,
        };
      });
      setUnitsSelect(tempArr);
    }
  }, [unitsData]);

  const userParams = new URLParams();
  userParams.includes('profiles');
  const { data: usersData } = useUsers({ params: userParams.toString() });
  useEffect(() => {
    if (usersData) {
      const tempArr = usersData.data.items.map((user) => {
        return { value: `${user.id}`, label: `${user.username}` };
      });
      setUsersSelect(tempArr);
    }
  }, [usersData]);

  const { data: organizationsData } = useOrganizations();
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((parent) => {
        return { value: `${parent.id}`, label: `${parent.name}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '',
          label: 'No Clients, Please add the organization first',
        });
      }
      setOrganizationsSelect(tempArr);
    }
  }, [organizationsData]);

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchApproveTask, {
    onSuccess: (data) => {
      setAlert(null);
      if(form.isDirty('deviceOnwership') && taskData?.data.installationUnit?.device?.id ) patchDeviceMutate({id: taskData?.data.installationUnit?.device?.id, data: {ownership: form.values.deviceOnwership}})
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.Task, { id: idN }], { exact: true });
      queryClient.invalidateQueries([types.Unit, { id: data.data.unit.id }], { exact: true });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  const {
    mutate: patchDeviceMutate,
    // isLoading: patchDeviceLoading,
    // isSuccess: patchDeviceSuccess,
  } = useMutation(patchDevice, {
    onSuccess: (data) => {
      setAlert(null);
      queryClient.invalidateQueries([types.Device, types.ALL]);
      taskData?.data.installationUnit?.device.id && queryClient.invalidateQueries([types.Device, { id: data.data.id }], { exact: true });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IapproveTaskInput) {
    const newvalues = noEmptyString(values);
    if (newvalues.installationUnit?.id !== 0 && newvalues.unit?.id !== 0) {
      setAlert(null);
      if (idN !== 0 && !Number.isNaN(idN)) {
        newvalues.status = 'done';
        patchMutate({ id: idN, data: newvalues });
      } else {
        setAlert(['The id provided is incorrect']);
      }
    } else setAlert('you should choose a unit');
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Approve a job:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {patchSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title="Updated!" color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable dirty={form.isDirty('title')} label="Title" placeholder="Title" {...form.getInputProps('title')} required />
            <SelectEditable
              dirty={form.isDirty('type')}
              label="Type"
              placeholder="Search here"
              {...form.getInputProps('type')}
              data={enumToSelectData(TaskType)}
              required
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('address')}
              label="Address"
              placeholder="Address"
              {...form.getInputProps('address')}
              mt="md"
            />
            <DatePickerInputEditable
              dirty={form.isDirty('targetDate')}
              placeholder="Date"
              label="Task date"
              {...form.getInputProps('targetDate')}
              mt="md"
            />
            <DatePickerInputEditable
              placeholder="Date"
              label="Completion date"
              {...form.getInputProps('completionDate')}
              required
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('organization.id')}
              label="Client"
              placeholder="Search here"
              {...form.getInputProps('organization.id')}
              onChange={(v) => {
                v && form.setFieldValue('organization.id', parseInt(v));
                setOrganizationId(parseInt(v || '0'));
              }}
              searchable
              value={`${form.values.organization.id}`}
              nothingFound="Not found"
              data={organizationsSelect}
              required
              mt="md"
            />
            <Title order={4} mt="md">
              Vehicle's Details
            </Title>
            <Divider size="md" />
            <TextInputEditable
              dirty={form.isDirty('vehicle.vin')}
              label="Vin"
              placeholder="Vin"
              {...form.getInputProps('vehicle.vin')}
              required
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('vehicle.placeOfIssue')}
              label="Place Of Issue"
              placeholder="Search here"
              {...form.getInputProps('vehicle.placeOfIssue')}
              onChange={(v) => {
                form.setFieldValue('vehicle.placeOfIssue', v);
              }}
              searchable
              nothingFound="Not found"
              data={enumToSelectData(placeOfIssue)}
              mt="md"
            />
            <NumberInputEditable
              dirty={form.isDirty('vehicle.plateNumber')}
              label="PlateNumber"
              placeholder="PlateNumber"
              {...form.getInputProps('vehicle.plateNumber')}
              hideControls
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('vehicle.category')}
              label="Category"
              placeholder="Category"
              {...form.getInputProps('vehicle.category')}
              mt="md"
            />

            <Grid>
              <Grid.Col lg={10} sm={7}>
                <SelectEditable
                  dirty={form.isDirty('vehicle.vehicleType.id')}
                  label="Vehicle Type"
                  placeholder="Search here"
                  {...form.getInputProps('vehicle.vehicleType.id')}
                  onChange={(v) => {
                    v && form.setFieldValue('vehicle.vehicleType.id', parseInt(v));
                  }}
                  searchable
                  nothingFound="Not found"
                  value={`${form.values.vehicle?.vehicleType.id}`}
                  data={vehicleTypeSelect}
                  mt="md"
                />
              </Grid.Col>
              <Grid.Col lg={2} sm={5}>
                <Button fullWidth leftIcon={<TbPlus size={14} />} onClick={() => setOpened(true)} mt={largeScreen ? 40 : 10}>
                  Add a Type
                </Button>
              </Grid.Col>
            </Grid>
            <Paper mt="sm" p="md" withBorder>
              <SegmentedControl
                defaultValue="odometerKM"
                data={[
                  { label: 'Odometer by Kilometers', value: 'odometerKM' },
                  { label: 'Odometer by Hours', value: 'odometerHours' },
                ]}
                onChange={(v) => setOdometer(v)}
              />
              {odometer === 'odometerKM' && (
                <NumberInputEditable label="KM" placeholder="KM" {...form.getInputProps('vehicle.odometerKM')} hideControls mt="md" />
              )}

              {odometer === 'odometerHours' && (
                <NumberInputEditable
                  label="Hours"
                  placeholder="Hours"
                  {...form.getInputProps('vehicle.odometerHours')}
                  hideControls
                  mt="md"
                />
              )}
            </Paper>

            <SelectEditable
              dirty={form.isDirty('vehicle.color')}
              label="Color"
              placeholder="Search here"
              {...form.getInputProps('vehicle.color')}
              searchable
              nothingFound="Not found"
              data={colorSelect}
              mt="md"
            />
            {/* <TextInputEditable  dirty={form.isDirty()} label="Image" placeholder="Image" {...form.getInputProps('vehicle.img')} mt="md" /> */}
            <Divider size="md" mt="md" />
            <Grid mt="sm">
              <Grid.Col span={10}>
                <SelectEditable
                  dirty={form.isDirty('installationUnit.id')}
                  label="Unit"
                  placeholder="Search here"
                  {...form.getInputProps('installationUnit.id')}
                  onChange={(v) => {
                    v && form.setFieldValue('installationUnit.id', parseInt(v));
                  }}
                  searchable
                  nothingFound="Not found"
                  data={unitsSelect}
                  value={`${form.values.installationUnit?.id}`}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Button mt="xl" fullWidth onClick={open}>
                  Update Unit
                </Button>
              </Grid.Col>
            </Grid>
            <SelectEditable
              dirty={form.isDirty('performedBy.id')}
              label="Performed By"
              placeholder="Search here"
              {...form.getInputProps('performedBy.id')}
              onChange={(v) => {
                v && form.setFieldValue('performedBy.id', parseInt(v));
                setUserId(parseInt(v || '0'));
              }}
              searchable
              nothingFound="Not found"
              value={`${form.values.performedBy?.id}`}
              data={usersSelect}
              mt="md"
            />
             <SelectEditable
              dirty={form.isDirty('deviceOnwership')}
              label="device Ownership"
              placeholder="Search here"
              {...form.getInputProps('deviceOnwership')}
              data={enumToSelectData(DeviceOwnership)}
              mt="md"
            />
            {/* <Textarea label="Remarks" placeholder="Remarks" {...form.getInputProps('taskRemarks')} mt="md" /> */}
            <SelectEditable
              dirty={form.isDirty('status')}
              label="Status"
              placeholder="Search here"
              mt="md"
              {...form.getInputProps('status')}
              data={enumToSelectData(TaskStatus)}
            />
            
            {taskData && taskData.data.jobRemarks && (
              <Textarea mt="md" label="technician Remarks">
                {taskData.data.jobRemarks.split('/>').join('\n').split('<?').join('')}
              </Textarea>
            )}
            <Group position="right">
              <Button color="blue" mt="md" onClick={() => openWialon()}>
                Connect Wialon
              </Button>
            </Group>
            {/* <Textarea label="Remarks" placeholder="Remarks" {...form.getInputProps('remarks')} mt="md" /> */}
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Modal opened={opened} onClose={() => setOpened(false)} size={'90%'}>
        <NewVehicleTypeForm />
      </Modal>
      <Modal opened={wialonOpened} onClose={closeWialon} title="Connect With Wialon">
        {taskData && taskData.data.unit.vehicle && <WialonExternalId unit={taskData.data.unit as unknown as UnitWithAllExtras} />}
      </Modal>
      <Grid>
        {organizationId !== 0 && (
          <Grid.Col lg={6}>
            <DetailsCard
              id={organizationId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter((org) => org.id === organizationId)[0].imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationId} />
            </DetailsCard>
          </Grid.Col>
        )}
        {userId !== 0 && (
          <Grid.Col lg={6}>
            (
            <DetailsCard
              id={userId}
              cardHei={400}
              image={
                usersData &&
                usersData.data &&
                usersData.data.items
                  .filter((org) => org.id === userId)[0]
                  .profiles.find((profile) => profile.app === commonConstants.AiosApp)?.imageRepositoryId
              }
              withImage
            >
              <UserCard id={userId} />
            </DetailsCard>
            )
          </Grid.Col>
        )}
        {taskData && (taskData.data.vehicle || taskData.data.unit.vehicle?.imageRepositoryId) && (
          <Grid.Col lg={6} md={12}>
            <DisplayCollection
              cardHei={400}
              image={taskData.data.vehicle?.imageRepositoryId || taskData.data.unit.vehicle?.imageRepositoryId}
            />
          </Grid.Col>
        )}
      </Grid>
      <Modal opened={modalOpened} size="50%" onClose={close}>
        <UpdateUnitForm idM={form.values.installationUnit ? form.values.installationUnit.id : undefined} />
      </Modal>
    </Layout>
  );
}
