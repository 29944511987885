import api from "../../common/api/api";
import { Iorganization, IorganizationInput } from "../types/types";

interface myResOrganization {
	data: Iorganization;
}

export const patchOrganization = (props: {
	id: number;
	data: Partial<IorganizationInput>;
}): Promise<myResOrganization> => {
	return api.patch(`organizations/${props.id}`, props.data);
};

export const patchOrganizations = (data: Record<string, any>[]): Promise<myResOrganization[]> => {
	return api.patch('organizations/multi', data);
};