import { Center, Container, Grid, Group, Loader, Pagination, Select, Title } from '@mantine/core';
import { useState } from 'react';
import { BaseCard, CardsFilter, useDeepFilter } from '../../../components/card';

import { useNavigate } from 'react-router-dom';
import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';
import Layout from '../../../components/layout/Layout';
import { URLParams, selectColumns } from '../../../utils';
import { PageSize } from '../../common';
import { useOrganizations } from '../api';
import { UpdateOrganizationForm } from '../components/forms/UpdateOrganizationForm';
import { OrganizationColumnsMode } from '../constants';
import { Iorganization } from '../types/types';

export function OrganizationCards() {
  const orgParams = new URLParams();
  orgParams.includes('parent');
  orgParams.select(...selectColumns(OrganizationColumnsMode), 'parent.id', 'parent.name');
  const { isSuccess, data } = useOrganizations({ params: orgParams.toString() });
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const navigate = useNavigate();
  const { filter, setFilter, formattedData } = useDeepFilter(data ? data.data.items : []);
  let cardsLoop;
  //TODO: change all to if(data) and maybe but them in a useEffect
  if (isSuccess) {
    cardsLoop = formattedData
      .slice((page - 1) * pagesize, pagesize * page)
      .map((item: Iorganization) => (
        <BaseCard
          key={`card-${item.id}`}
          name={item.name}
          discription={`id: ${item.id}, type: ${item.type}, abbreviation: ${item.abbreviation}, parent: ${
            item.parent ? item.parent.name : 'no parent'
          }, status: ${item.status}`}
          to={`/organization/${item.id}`}
          img={item.imageRepositoryId}
          updateModalContent={<UpdateOrganizationForm idM={item.id} />}
        />
      ));
  }

  return (
    <Layout>
      <Container fluid>
        <Group px={13} mt={10} position="apart">
          <Title
            order={2}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Organizations:
          </Title>
          <AddMultipleButton
            add={() => navigate('/new/organization')}
            addMany={() => navigate('/new/organizations')}
            updateMany={() => navigate('/edit/organizations')}
          />
        </Group>
        <CardsFilter setFilter={setFilter} filter={filter} />
        <Grid>
          {formattedData && cardsLoop ? (
            cardsLoop
          ) : (
            <Container p={25}>
              <Loader />
            </Container>
          )}
        </Grid>
        <Center my={15}>
          <Pagination
            total={Math.ceil(formattedData.length / pagesize)}
            siblings={2}
            boundaries={2}
            onChange={(n) => setPage(n)}
            value={page}
          />
          <Select placeholder="Page Size" size="sm" ml={10} data={Object.values(PageSize)} onChange={(e) => setPagesize(Number(e))} />
        </Center>
      </Container>
    </Layout>
  );
}
