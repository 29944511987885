import { useQuery } from "react-query";
import api from "../../common/api/api";
import { types } from "../../../constants";
import { Irfid } from "../types/types";

interface myResRfid {
	data: Irfid;
}

export const getRfid = (id: number): Promise<myResRfid> => {
	return api.get(`rfids/${id}`);
};

export const useRfid = (id: number, options?: { then?: (x: myResRfid) => any }) => {
	const query = useQuery(
		[types.RFID, { id }],
		() =>
			getRfid(id).then((rfid) => {
				options && options.then && options.then(rfid);
				return rfid;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};
