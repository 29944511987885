import { ActionIcon, Button, Container, FocusTrap, Group, LoadingOverlay, Paper, Select, Table, TextInput } from '@mantine/core';
import Layout from '../../../components/layout/Layout';
import { patchDevices, useDevices } from '../api';
import { noEmptyString, removeNullElement, URLParams, useCsv } from '../../../utils';
import { useMutation, useQueryClient } from 'react-query';
import { types } from '../../../constants';
import { IaxiosError } from '../../common';
import { notifications } from '@mantine/notifications';
import { TbPlus } from 'react-icons/tb';
import { useDidUpdate } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { UserStatus, useUsers } from '../../users';

export function IssueDevices() {
  const [focus, setFocus] = useState(false);
  const [user, setUser] = useState<number>(0);
  const [userSelect, setUsersSelect] = useState([{ value: '', label: '' }]);

  const queryClient = useQueryClient();
  const deviceParams = new URLParams();
  const itemsRef = useRef<(HTMLInputElement | null)[]>([]);

  const userParams = new URLParams();
  userParams.includes('profiles');
  userParams.filterSetField('status', UserStatus.Active);
  const { data: usersData } = useUsers({ params: userParams.toString() });
  useEffect(() => {
    if (usersData) {
      const tempArr = usersData.data.items.map((user) => {
        return { value: `${user.id}`, label: `${user.firstName} ${user.lastName}` };
      });
      setUsersSelect(tempArr);
    }
  }, [usersData]);

  deviceParams.select('id', 'imei');
  const { data: devicesData } = useDevices({ params: deviceParams.toString() });

  const requirments = {
    imei: /^\d{15}$/,
  };

  const { data, headers, errorRows, validate, setItem, addRow } = useCsv(requirments, Object.keys(requirments), [{}]);

  useDidUpdate(() => {
    if (data && focus) {
      setFocus(false);
      itemsRef.current[data?.length - 1]?.focus();
    }
  }, [data?.length, focus]);

  const { mutate: patchMutate, isLoading: newLoading } = useMutation(patchDevices, {
    onSuccess: () => {
      queryClient.invalidateQueries([types.Device, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: false,
      });
    },
  });

  function handleSubmit() {
    // viewTopOfPage?.scrollIntoView()
    if (data) {
      const newValues = data
        .map((item) => noEmptyString(item))
        .map((item) => removeNullElement(item))
        .reduce((acc: Record<string, any>[], i) => {
          i['issuedTo'] = { id: user };
          if (i.imei) acc.push(i);
          return acc;
        }, [] as Record<string, any>[]);
      const tempAlert: string[] = [];
      newValues.forEach((item) => {
        const foundUnit = devicesData?.data.items.find((device) => device.imei === item.imei);
        if (foundUnit) item.id = foundUnit.id;
        else tempAlert.push('device with imei ' + item.imei + ' does not exist in the database');
      });
      if (user === 0) tempAlert.push('user must be selected');
      if (tempAlert.length === 0 && user) {
        patchMutate(newValues);
        // console.log('submitting...', newValues);
      } else
        tempAlert.forEach((e) => {
          notifications.show({
            title: 'Error',
            message: e,
            color: 'red',
            autoClose: false,
          });
        });
    } else {
      notifications.show({
        title: 'Error',
        message: 'something went wrong while preparing the data, please reload the page and try again.',
        color: 'red',
        autoClose: false,
      });
    }
  }

  const rows = data?.map((row, i) => (
    <tr key={'r' + i}>
      <FocusTrap active={true}>
        {headers.map((key, index) => (
          <td key={key + index}>
            <TextInput
              value={row[key]}
              error={errorRows[index]?.includes(i + 1)}
              ref={(ref) => (itemsRef.current[i] = ref)}
              variant="unstyled"
              onChange={(v) => setItem(i, key, v.target.value)}
              // onSubmit={() => addRow()}
              onKeyUp={(e) =>
                e.key === 'Enter' &&
                addRow({}, () => {
                  setFocus(true);
                })
              }
            />
          </td>
        ))}
      </FocusTrap>
    </tr>
  ));

  return (
    <Layout>
      <Container>
        <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {/* {validate() && (
            <Alert icon={<TbAlertTriangle size={16} />} title="Error!" color="red" mt="md">
              <List>
                <List.Item>Errors in the following rows:</List.Item>
                {errorRows.map((errorRow, i) => (
                  <List.Item key={`error-${headers[i]}`}>
                    {headers[i]}: {errorRow.join(', ')}
                  </List.Item>
                ))}
              </List>
            </Alert>
          )} */}
          {data && (
            <Table striped highlightOnHover mt="md">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Group position="apart" mt="md">
            <Select
              searchable
              label="Issue To"
              placeholder="Search here"
              value={`${user}`}
              onChange={(v) => {
                v && setUser(parseInt(v));
              }}
              data={userSelect}
            />
            <ActionIcon size="lg" variant="subtle">
              <TbPlus size={28} onClick={() => addRow()} />
            </ActionIcon>
          </Group>
          <Button id="submit" fullWidth disabled={!data || validate() || newLoading} onClick={handleSubmit} mt="md">
            Submit
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
}
