import { Button, Paper, SegmentedControl, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { types } from '../../../constants';
import { URLParams, noEmptyString } from '../../../utils';
import { IaxiosError, SelectData } from '../../common';
import { patchTasks } from '../../tasks';
import { upsertTaskGroup, useTaskGroups } from '../api';
import { TaskGroupInput } from '../types/types';

type newTaskGroupInput = Omit<TaskGroupInput, 'name'> & { id: number | undefined; name: string | undefined };

export function AddMultipleTasksToGroup({ ids }: { ids: number[] }) {
  // const [data, setData] = useState<TaskGroup[]>([]);
  const [targetTaskGroup, setTargetTaskGroup] = useState<'addTaskGroup' | 'selectTaskGroup'>('addTaskGroup');
  const [taskGroupsSelect, setTaskGroupsSelect] = useState<SelectData>([{ value: '', label: '' }]);

  const queryClient = useQueryClient();
  console.log(ids);
  const form = useForm<newTaskGroupInput>({
    initialValues: {
      id: undefined,
      name: undefined,
      tasks: ids.map((id) => ({ id })),
    },
  });

  const params = new URLParams();
  params.includes('tasks');
  const { data: taskGroupsData } = useTaskGroups({ params: params.toString() });
  useEffect(() => {
    if (taskGroupsData) {
      const tempArr: SelectData = taskGroupsData.data.items.map((group) => {
        return { value: `${group.id}`, label: `${group.name}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '0',
          label: 'No Groups, Please add a group first',
          disabled: true,
        });
      }
      setTaskGroupsSelect(tempArr);
    }
  }, [taskGroupsData]);

  const { mutate: upsertMutate } = useMutation(upsertTaskGroup, {
    onSuccess: (data) => {
      notifications.show({
        title: 'Success',
        message: 'Tasks Added to Group',
        color: 'green',
        autoClose: 5000,
      });
      queryClient.invalidateQueries([types.TaskGroup, types.ALL]); //, { exact: true } TODO: add this when needed
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.TaskGroup, { id: data.data.id }]);
      queryClient.invalidateQueries([types.History, types.TaskGroup, { id: data.data.id }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: false,
      });
    },
  });

  const {mutate: PatchMutate} = useMutation(patchTasks, {
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: 'Tasks Added to Group',
        color: 'green',
        autoClose: 5000,
      });
      queryClient.invalidateQueries([types.TaskGroup, types.ALL]); //, { exact: true } TODO: add this when needed
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.TaskGroup, { id: form.values.id }]);
      queryClient.invalidateQueries([types.History, types.TaskGroup, { id: form.values.id }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: false,
      });
    },
  });

  function handleSubmit(values: newTaskGroupInput) {
    const newValues = noEmptyString({ ...values });
    if (targetTaskGroup === 'addTaskGroup' &&  newValues?.name){
      if(newValues?.id) newValues.id = undefined;
      upsertMutate({ ...newValues });
    } 
    else if (targetTaskGroup === 'selectTaskGroup' && newValues?.id) {
      if(newValues.name) newValues.name = undefined;
      const tasks =  form.values.tasks?.map(task => {
        return {
          id: task.id,
          taskGroup: {id: newValues.id}
        }
      })

      
      if(tasks) PatchMutate(tasks)
    } 
  }

  return (
    <div>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Paper withBorder mt="sm" p="md">
          <SegmentedControl
            value={targetTaskGroup}
            data={[
              { label: 'Add TaskGroup', value: 'addTaskGroup' },
              { label: 'Select TaskGroup', value: 'selectTaskGroup' },
            ]}
            onChange={(v: typeof targetTaskGroup) => setTargetTaskGroup(v)}
          />

          {targetTaskGroup === 'addTaskGroup' && (
            <TextInput
              label="Task Group"
              placeholder="Task Group"
              {...form.getInputProps('name')}
              // description={
              //   <Group spacing={0}>
              //     <Text>{`suggeted name: ${taskGroupSuggestion}`}</Text>
              //     <ActionIcon onClick={() => form.setFieldValue('taskGroup.name', taskGroupSuggestion)}>
              //       <TbCircleCheck />
              //     </ActionIcon>
              //   </Group>
              // }
            />
          )}
          {targetTaskGroup === 'selectTaskGroup' && (
            <Select
              label="Task Group"
              data={taskGroupsSelect}
              {...form.getInputProps('id')}
              value={`${form.values?.id}` || ''}
              onChange={(v) => {
                if (v) {
                  form.setFieldValue('id', +v);
                  // form.setFieldValue(
                  //   'tasks',
                  //   (form.values.tasks || []).concat(taskGroupsData?.data.items.find((t) => t.id === +v)?.tasks || []),
                  // );
                }
              }}
              withinPortal
            />
          )}
        </Paper>
        <Button fullWidth mt="xl" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
}
