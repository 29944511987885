import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { IfullUnit } from "../types/types";

interface myResUnit {
	data: IfullUnit
}

export const getUnit = (id: number, joins?: string[], selects?: string[]): Promise<myResUnit> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`units/${id}${params}`);
};

export const useUnit = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResUnit) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Unit, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
		getUnit(id, options?.joins, options?.selects).then((unit) => {
			options && options.then && options.then(unit);
			return unit;
		}),
		{ enabled: id !== 0 },
	);
	return query;
};
