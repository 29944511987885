import { UseFormReturnType } from '@mantine/form';

export const removeNotEdited = <T extends Record<string, any>>(values: T, form: UseFormReturnType<T>, path: string[] = []) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (value !== null && typeof value === 'object' && !(Object.prototype.toString.call(value) === '[object Date]')) {
      const temp = removeNotEdited(value, form, [...path, key]);
      if (Object.keys(temp).length !== 0) acc[key as keyof T] = temp;
    } else {
      if (form.isDirty(path.length > 0 ? `${path.join('.')}.${key}` : key)) {
        acc[key as keyof T] = value;
      }
    }
    return acc;
  }, {} as Record<keyof T, any>);
};
