import api from "../../common/api/api";
import { IuserProfile, IuserProfileInput } from "../types/types";

interface myResUserProfilePast {
	data: IuserProfile;
}

export const patchUserProfile = (props: { id: number; data: Partial<IuserProfileInput> }): Promise<myResUserProfilePast> => {
	return api.patch(`user-profiles/${props.id}`, props.data);
};
