import api from "../../common/api/api";
import { Iuser, IuserInput } from "../types/types";

interface myResUserPost {
	data: Iuser;
}

export const postUser = (data: IuserInput): Promise<myResUserPost> => {
	return api.post(`users`, data);
};
