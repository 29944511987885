import { ActionIcon, Badge, Group, Modal, Text, Title, Tooltip } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { TbEdit, TbEye } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import BaseTable from '../../../components/table/BaseTable';
import { useAuthContext } from '../../../context/AuthContext';
import { URLParams, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { UserRole } from '../../users';
import { useTickets } from '../api';
import { TicketStatus, TicketStatusColors, TicketType, TicketTypeColors } from '../constants/constants';
import { UpdateTicketStatus } from '../forms';
import { Iticket } from '../types/types';
import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';

export function SupportTicketTable() {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [data, setData] = useState<Iticket[]>([]);
  const [id, setID] = useState<number | undefined>();
  const [rowSelection, setRowSelection] = useState({});

  const [opened, { open, close }] = useDisclosure(false);
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes('client');
    param.sort('-ticket.id');
    param.page(10, page - 1);
    return param;
  });
  const { data: allTickets, refetch, isLoading } = useTickets({ params: params.toString() });
  useEffect(() => {
    if (allTickets) {
      setData(allTickets.data.items);
    }
  }, [allTickets]);

  const columnHelper = useMemo(() => createColumnHelper<Iticket>(), []);
  const columns: ColumnDef<Iticket, any>[] = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, { header: 'Ticket ID', id: 'id' }),
      columnHelper.accessor((row) => row.title, { header: 'Title', id: 'title' }),
      columnHelper.accessor((row) => row.client?.firstName, { header: 'First Name', id: 'client.firstName' }),
      columnHelper.accessor((row) => row.client?.lastName, { header: 'First Name', id: 'client.lastName' }),
      columnHelper.accessor((row) => simpleDateFormat(new Date(row.createdAt)), { header: 'created Date', id: 'createdAt' }),
      columnHelper.accessor((row) => (row.assignedOn ? simpleDateFormat(new Date(row.assignedOn)) : 'Not Assigned Yet'), {
        header: 'assigned Date',
        id: 'assignedOn',
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        id: 'status',
        cell: (props) => (
          <Badge
            variant="light"
            c={TicketStatusColors[props.getValue().split(' ').join('_') as keyof typeof TicketStatusColors]}
            styles={{
              root: {
                backgroundColor: `${TicketStatusColors[props.getValue().split(' ').join('_') as keyof typeof TicketStatusColors]}40`,
              },
            }}
          >
            {props.getValue()}
          </Badge>
        ),
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        id: 'type',
        cell: (props) => (
          <Badge
            variant="light"
            c={TicketTypeColors[props.getValue().split(' ').join('_') as keyof typeof TicketTypeColors]}
            styles={{
              root: { backgroundColor: `${TicketTypeColors[props.getValue().split(' ').join('_') as keyof typeof TicketTypeColors]}40` },
            }}
          >
            {props.getValue()}
          </Badge>
        ),
      }),
      columnHelper.display({
        id: 'Actions',
        header: 'Actions',
        cell: ({ row }) => {
          return (
            <Group spacing="xs">
              <Tooltip label="View">
                <ActionIcon onClick={() => navigate(`/ticket/${row.original.id}`)}>
                  <TbEye size={15} />
                </ActionIcon>
              </Tooltip>
              {user?.role !== UserRole.Client && (
                <Tooltip label="Change Status">
                  <ActionIcon
                    onClick={() => {
                      setID(row.original.id);
                      open();
                    }}
                  >
                    <TbEdit size={15} />
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
          );
        },
      }),
    ],
    [columnHelper, data],
  );

  const columnTypes = {
    'assigned Date': 'date',
    'created Date': 'date',
  };

  const toSelect = {
    Status: TicketStatus,
    Type: TicketType,
  };

  return (
    <Layout>
      <Group position="apart" mb="md">
        <Title order={2} align="left" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
          Tickets:
        </Title>
        <AddMultipleButton add={() => navigate('/new/support-ticket')} />
      </Group>
      <BaseTable<Iticket>
        data={data}
        columns={columns}
        columnTypes={columnTypes}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        loading={isLoading}
        page={page}
        toSelect={toSelect}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'Actions' && header !== 'expander')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        pages={allTickets?.data.meta.ids.length ? Math.ceil(allTickets?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
      />
      <Modal
        size={'70%'}
        opened={opened}
        onClose={close}
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Chat
          </Text>
        }
      >
        {id && user && <UpdateTicketStatus id={id} userID={user.id} />}
        {!id && <Text>Something Went Wrong please try ro refresh</Text>}
      </Modal>
    </Layout>
  );
}
