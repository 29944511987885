import {
  ActionIcon,
  Alert,
  Button,
  Container,
  Group,
  List,
  Paper,
  Table,
  TextInput,
  Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  TbAlertOctagon,
  TbAlertTriangle,
  TbCircleCheck,
  TbDownload,
  TbPlus
} from 'react-icons/tb';
import { UploadCSVButton } from '../../../../components/form';
import { duplicatesSourceFinder, useCsv } from '../../../../utils';
  
  
  export function SelectMultipleUnits({
    setValuesCSV,
  }:{
    setValuesCSV: (data: any[], setAlert: React.Dispatch<React.SetStateAction<string | string[] | null>>, setSuccess: React.Dispatch<React.SetStateAction<boolean>>) => void,
  }) {
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [duplicateAlert, setDuplicateAlert] = useState<string[] | null>(null);
    const [newSuccess,setNewSuccess] = useState(false)
    const viewSubmit = document.getElementById('submit')
    const viewTopOfPage = document.getElementById('TopOfPage')
    
    const requirments = {
      id: /^\d+$/,
    };
    const templateText = `${Object.keys(requirments).join(',')}\r\n`;
    
    const { csvParser, data, headers, errorRows, validate, setItem, addRow, removeRow } =
    useCsv(requirments, Object.keys(requirments));
    
    function handleSelectFile(value: File | null) {
      setFile(value);
      if (value) {
        csvParser(value);
      }
    }

    useEffect(() => {
      if(errorRows.length === 0){
        viewSubmit?.scrollIntoView()
      }
    }, [data])
  
  
    function handleSubmit() {
      if(data){
        const {unique, duplicates} = duplicatesSourceFinder(data, 'id')
        if(!duplicates.length){
          viewTopOfPage?.scrollIntoView()
          setValuesCSV(unique, setAlert, setNewSuccess)
        }else {
          setDuplicateAlert(duplicates.map(d => `${d}`))
        }
      }else setAlert('Something Wrong with the Data')
    }
  
    const rows = data?.map((row, i) => (
      <tr key={'r' + i}>
        {headers.map((key, index) => (
          <td key={key + index}>
            <TextInput
              value={row[key]}
              error={errorRows[index]?.includes(i + 1)}
              variant="unstyled"
              onChange={(v) => setItem(i, key, v.target.value)}
            />
          </td>
        ))}
      </tr>
    ));
  
    return (
        <Container pt={10} fluid>
          <Title
            order={2}
            pl={5}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Select Multiple Units:
          </Title>
          <Paper withBorder shadow="md" p={30} my={30} radius="md">
            <Group position="apart">
              <UploadCSVButton file={file} onChange={handleSelectFile}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload CSV
                  </Button>
                )}
              </UploadCSVButton>
              <Button
                mt="md"
                component="a"
                href={`data:text/csv;charset=utf-8,${templateText}`}
                download={'newVehiclesTemplate.csv'}
                leftIcon={<TbDownload size={16} />}
              >
                Download a template
              </Button>
            </Group>
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Important!"
              withCloseButton
              color="blue"
              mt="md"
            >
              <List id={'TopOfPage'}>
                <List.Item>
                  Currently only accepts CSV files, file must include the headers. 
                </List.Item>
                <List.Item>id: It is the Unit ID not vehicle or anything else.</List.Item>
              </List>
            </Alert>
            {data && Object.keys(requirments).length !== headers.length && (
              <Alert
                icon={<TbAlertTriangle size={16} />}
                title="Warning!"
                color="orange"
                mt="md"
              >
                The number of column uploaded is different than expected!
              </Alert>
            )}
            {validate() && (
              <Alert
                icon={<TbAlertTriangle size={16} />}
                title="Error!"
                color="red"
                mt="md"
              >
                <List>
                  <List.Item>Errors in the following rows:</List.Item>
                  {errorRows.map((errorRow, i) => (
                    <List.Item key={`error-${headers[i]}`}>
                      {headers[i]}: {errorRow.join(', ')}
                    </List.Item>
                  ))}
                </List>
              </Alert>
            )}
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
                mt="md"
              >
                Successfully added to the database!
              </Alert>
            )}
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                mt="md"
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item key={errMsg}>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
            {duplicateAlert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
              mt="md"
            >
              <Group position='apart' align='center'>
              {Array.isArray(duplicateAlert) ? (
                <List>
                  {duplicateAlert.map((errMsg, index) => (
                    <List.Item key={`${errMsg}-${index}`}>unit with id: {errMsg} duplicated!</List.Item>
                    ))}
                </List>
              ) : (
                duplicateAlert + '!'
                )}
                <Button onClick={() => {
                  duplicateAlert.forEach(element => {
                    removeRow('id',element)
                  }
                  );
                  setDuplicateAlert(null)
                }}>Remove Duplicates</Button>
              </Group>
            </Alert>
          )}
            {data && (
              <Table striped highlightOnHover mt="md">
                <thead>
                  <tr>
                    {headers.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            )}
            <Group position="right" mt="md">
              <ActionIcon size="lg" variant="subtle">
                <TbPlus size={28} onClick={() => addRow()} />
              </ActionIcon>
            </Group>
            <Button
              id='submit'
              fullWidth
              disabled={validate()}
              onClick={handleSubmit}
              mt="md"
            >
              Submit
            </Button>
          </Paper>
        </Container>
    );
  }
  