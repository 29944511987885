import { useQuery } from "react-query";
import api from "../../common/api/api";
import { types } from "../../../constants";
import { Iprofile } from "../types/types";

interface myResProfile {
	data: Iprofile;
}

export const getProfile = (): Promise<myResProfile> => {
	return api.get(`users/profile`);
};

export const useProfile = (options?: { then?: (x: myResProfile) => any }) => {
	const query = useQuery([types.User, "mine"], () =>
		getProfile().then((profile) => {
			options && options.then && options.then(profile);
			return profile;
		})
	);
	return query;
};
