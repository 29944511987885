import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IfullUnit } from '../types/types';

interface myResUnit {
  data: {
    items: IfullUnit[];
    meta: metaData;
  };
}

export const getUnits = (query = ''): Promise<myResUnit> => {
  return api.get(`units${query}`);
};

export const useUnits = (options?: { params?: string; then?: (x: myResUnit) => any; enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Unit, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      getUnits(options?.params).then((unit) => {
        options && options.then && options.then(unit);
        return unit;
      }),
    { enabled: options?.enabled ?? true },
  );
  return query;
};
interface myResCountUnitsNumber {
  data: number;
}

export const countUnits = (query = ''): Promise<myResCountUnitsNumber> => {
  return api.get(`units/count${query}`);
};

export const useCountUnits = (options?: { params?: string; then?: (x: myResCountUnitsNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Unit, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countUnits(options?.params).then((units) => {
      options && options.then && options.then(units);
      return units;
    }),
  );
  return query;
};
interface myResCountUnits {
  data: Record<string, string | number>[];
}

export const analyticsUnits = (query = ''): Promise<myResCountUnits> => {
  return api.get(`units/analytics${query}`);
};

export const useAnalyticsUnits = (options?: { params?: string; then?: (x: myResCountUnits) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Unit, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      analyticsUnits(options?.params).then((counts) => {
        options && options.then && options.then(counts);
        return counts;
      }),
    // { staleTime: 1000 * 30 },
  );
  return query;
};
