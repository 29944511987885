import {
  ActionIcon,
  Alert,
  Button,
  Container,
  Grid,
  Group,
  List,
  LoadingOverlay,
  Modal,
  Paper,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { useDisclosure } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { DetailsCard } from '../../../components/detailsCards';
import { DatePickerInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { URLParams, enumToSelectData, removeNotEdited, selectColumns, taskTitleFormater } from '../../../utils';
import { IaxiosError, SelectData } from '../../common';
import { NewContactsForm, OrganizationCard, useContacts, useOrganizations } from '../../organizations';
import { useTaskGroups } from '../../task-groups/api';
import { NewUnitForm } from '../../unit';
import { UserRole, useUsers } from '../../users';
import { patchTask, useTask } from '../api';
import { SelectInstallationUnit } from '../components';
import { TaskColumnsMode, TaskStatus, TaskType } from '../constants/constants';
import { TaskInput } from '../types/types';

type UpdateTask = Omit<TaskInput, 'issuedBy' | 'performedBy' | 'approvedBy' | 'status' | 'jobRemarks'>;

export function UpdateTaskForm() {
  const { id } = useParams();
  const [idN] = useState(+(id || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedCreateUnit, { open: openCreateUnit, close: closeCreateUnit }] = useDisclosure(false);

  const [targetReciever, setTargetReciever] = useState('recipientGroup');
  const [targetContact, setTargetContact] = useState('selectContact');
  const [targetTaskGroup, setTargetTaskGroup] = useState<'addTaskGroup' | 'selectTaskGroup'>('addTaskGroup');

  const [contactsSelect, setContactsSelect] = useState<SelectData>([{ value: '', label: '' }]);
  const [taskGroupsSelect, setTaskGroupsSelect] = useState<SelectData>([{ value: '', label: '' }]);
  const [organizationsSelect, setOrganizationsSelect] = useState([{ value: '', label: '' }]);
  const [taskGroupSuggestion, setTaskGroupSuggestion] = useState('');
  const [organizationId, setOrganizationId] = useState(0);
  const [vehicle, setVehicle] = useState({
    plate: '',
    platform: '',
    secondaryPlatform: '',
    orgID: 0,
  });
  const queryClient = useQueryClient();

  const [userSelect, setUserSelect] = useState([{ value: '', label: '' }]);

  const userParams = new URLParams();
  userParams.select('firstName', 'lastName', 'id');
  const { data: userData } = useUsers({ params: userParams.toString() });
  useEffect(() => {
    if (userData) {
      const tempArr = userData.data.items.map((user) => {
        return {
          value: `${user.id}`,
          label: `${user.firstName} ${user.lastName}`,
        };
      });
      tempArr.unshift({ value: '', label: 'No Recipient' });
      setUserSelect(tempArr);
    }
  }, [userData]);

  const orgParams = new URLParams();
  orgParams.select('name', 'id', 'imageRepositoryId', 'abbreviation');
  const { data: organizationsData } = useOrganizations({ params: orgParams.toString() });
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((org) => {
        return { value: `${org.id}`, label: `${org.name}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '',
          label: 'No Clients, Please add the organization first',
        });
      }
      setOrganizationsSelect(tempArr);
    }
  }, [organizationsData]);

  const { data: contactsData } = useContacts();
  useEffect(() => {
    if (contactsData) {
      const tempArr: SelectData = contactsData.data.items.map((contact) => {
        return { value: `${contact.id}`, label: `${contact.name}, ${contact.phone}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '',
          label: 'No Contacts, Please add the contact first',
          disabled: true,
        });
      }
      setContactsSelect(tempArr);
    }
  }, [contactsData]);

  const { data: taskGroupsData } = useTaskGroups();
  useEffect(() => {
    if (taskGroupsData) {
      const tempArr: SelectData = taskGroupsData.data.items.map((group) => {
        return { value: `${group.id}`, label: `${group.name}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '0',
          label: 'No Groups, Please add a group first',
          disabled: true,
        });
      }
      setTaskGroupsSelect(tempArr);
    }
  }, [taskGroupsData]);

  const { data: taskData, isLoading } = useTask(idN, {
    joins: [
      'unit',
      'taskGroup',
      'unit.vehicle as unitVehicle',
      'unitVehicle.organization as unitOrg',
      'unit.deviceConfig as config',
      'installationUnit',
      'organization',
      'vehicle',
      'recipient',
      'contacts',
    ],
    selects: [
      ...selectColumns(TaskColumnsMode),
      'taskGroup.id',
      'taskGroup.name',
      'unit.id',
      'unitVehicle.id',
      'unitVehicle.plateNumber',
      'config.id',
      'config.platform',
      'config.secondaryPlatform',
      'installationUnit.id',
      'organization.id',
      'vehicle.id',
      'recipient.id',
      'contacts.id',
      'unitOrg.id',
    ],
    then: (task) => {
      form.setValues({
        title: task.data.title,
        type: task.data.type,
        recipient: { id: task.data.recipient ? task.data.recipient.id : 0 },
        recipientGroup: task.data.recipientGroup,
        address: task.data.address || '',
        location: task.data.location || '',
        contactName: task.data.contactName || '',
        contactNumber: task.data.contactNumber || '',
        targetDate: task.data.targetDate ? new Date(task.data.targetDate) : null,
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : null,
        organization: { id: task.data.organization.id },
        vehicle: { id: task.data.vehicle ? task.data.vehicle.id : 0 },
        contacts: { id: task.data.contacts ? task.data.contacts.id : 0 },
        unit: {
          id: task.data.unit ? task.data.unit.id : 0,
        },
        installationUnit: {
          id: task.data.installationUnit ? task.data.installationUnit.id : 0,
        },
        taskGroup: task.data.taskGroup || { id: undefined, name: undefined },
        taskRemarks: task.data.taskRemarks || '',
      });
      form.resetDirty({
        title: task.data.title,
        type: task.data.type,
        recipient: { id: task.data.recipient ? task.data.recipient.id : 0 },
        recipientGroup: task.data.recipientGroup,
        address: task.data.address || '',
        location: task.data.location || '',
        contactName: task.data.contactName || '',
        contactNumber: task.data.contactNumber || '',
        targetDate: task.data.targetDate ? new Date(task.data.targetDate) : null,
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : null,
        organization: { id: task.data.organization.id },
        vehicle: { id: task.data.vehicle ? task.data.vehicle.id : 0 },
        contacts: { id: task.data.contacts ? task.data.contacts?.id : 0 },
        unit: {
          id: task.data.unit ? task.data.unit.id : 0,
        },
        installationUnit: {
          id: task.data.installationUnit ? task.data.installationUnit.id : 0,
        },
        taskGroup: task.data.taskGroup || { id: undefined, name: undefined },
        taskRemarks: task.data.taskRemarks || '',
      });
      if (task.data.taskGroup?.id) {
        setTargetTaskGroup('selectTaskGroup');
      }
      setVehicle({
        plate: task.data.unit?.vehicle?.plateNumber ? `${task.data.unit?.vehicle?.plateNumber}` : '',
        platform: task.data.unit?.deviceConfig?.platform || '',
        secondaryPlatform: task.data.unit?.deviceConfig?.secondaryPlatform || '',
        orgID: task.data.unit?.vehicle?.organization?.id ?? 0,
      });
    },
  });

  const form = useForm<UpdateTask>({
    initialValues: {
      title: taskData?.data.title || '',
      type: taskData?.data.type || '',
      recipient: {
        id: taskData?.data.recipient?.id || 0,
      },
      recipientGroup: taskData?.data.recipientGroup || '',
      organization: {
        id: taskData?.data.organization?.id || 0,
      },
      targetDate: taskData?.data.targetDate ? new Date(taskData.data.targetDate) : null,
      completionDate: taskData?.data.completionDate ? new Date(taskData.data.completionDate) : null,
      address: taskData?.data.address || '',
      location: taskData?.data.location || '',
      contactName: taskData?.data.contactName || '',
      contactNumber: taskData?.data.contactNumber || '',
      taskRemarks: taskData?.data.taskRemarks || '',
      vehicle: {
        id: taskData?.data.vehicle?.id || 0,
      },
      contacts: {
        id: taskData?.data.contacts?.id || 0,
      },
      unit: {
        id: taskData?.data.unit?.id || 0,
      },
      installationUnit: {
        id: taskData?.data.installationUnit?.id || 0,
      },
      taskGroup: taskData?.data.taskGroup
        ? {
            id: taskData?.data.taskGroup.id,
            name: taskData?.data.taskGroup.name,
          }
        : {
            id: undefined,
            name: undefined,
          },
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchTask, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Task, types.ALL]); //, { exact: true } TODO: add this when needed
      queryClient.invalidateQueries([types.Task, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.Task, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  useEffect(() => {
    console.log('effect running');
    const formattedDate = `${form.values.targetDate?.getDate()}-${form.values.targetDate?.getMonth()}-${form.values.targetDate?.getFullYear()}`;
    setTaskGroupSuggestion(
      `${organizationsData?.data.items.find((org) => org.id === form.values.organization.id)?.abbreviation}-${formattedDate}`,
    );
  }, [form.values.targetDate, form.values.organization.id]);

  function handleSubmit(values: UpdateTask) {
    const newValues = removeNotEdited(values, form);
    if (targetTaskGroup === 'addTaskGroup' && newValues.taskGroup?.id && newValues.taskGroup?.name) newValues.taskGroup.id = undefined;
    if (targetTaskGroup === 'selectTaskGroup' && newValues.taskGroup?.name && newValues.taskGroup?.id) newValues.taskGroup.name = undefined;
    if(newValues.taskGroup?.id === undefined && newValues.taskGroup?.name === undefined) newValues.taskGroup = null;
    if (newValues.vehicle && newValues.vehicle?.id === 0) {
      newValues.vehicle = null;
    }
    if (newValues.unit && newValues.unit?.id === 0) {
      newValues.unit = null;
    }
    if (newValues.recipient && newValues.recipient?.id === 0) {
      newValues.recipient = null;
    }
    setAlert(null);
    if (idN !== 0 && !Number.isNaN(idN)) {
      patchMutate({ id: idN, data: newValues });
    } else {
      setAlert(['The id provided is incorrect!']);
    }
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit a task:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading || isLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {patchSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              disabled={true}
              // dirty={form.isDirty('title')}
              label="Title"
              placeholder="Title"
              {...form.getInputProps('title')}
            />
            <SelectEditable
              dirty={form.isDirty('type')}
              label="Type"
              placeholder="Search here"
              {...form.getInputProps('type')}
              data={enumToSelectData(TaskType)}
              onChange={(v) => {
                v && form.setFieldValue('type', v);
                form.setFieldValue(
                  'title',
                  taskTitleFormater(
                    organizationsData?.data.items.filter((org) => org.id === form.values.organization.id)[0]?.abbreviation || '',
                    vehicle.plate,
                    v,
                    userData?.data.items.filter((user) => form.values.recipient && user.id === form.values.recipient.id)[0]?.firstName ||
                      '',
                    vehicle.platform,
                    vehicle.secondaryPlatform,
                  ),
                );
              }}
              required
              mt="md"
            />

            <SegmentedControl
              mt="lg"
              value={targetReciever}
              data={[
                { label: 'Reciepent', value: 'reciepent' },
                { label: 'Recipient Group', value: 'recipientGroup' },
              ]}
              onChange={(v) => setTargetReciever(v)}
            />
            {targetReciever === 'reciepent' && (
              <SelectEditable
                dirty={form.isDirty('recipient.id')}
                label="Recipient"
                placeholder="Search here"
                {...form.getInputProps('recipient.id')}
                onChange={(v) => {
                  form.setFieldValue('recipient.id', parseInt(v ?? '0'));
                  form.setFieldValue(
                    'title',
                    taskTitleFormater(
                      organizationsData?.data.items.filter((org) => org.id === form.values.organization.id)[0]?.abbreviation || '',
                      vehicle.plate,
                      form.values.type,
                      userData?.data.items.filter((user) => v && user.id === parseInt(v))[0]?.firstName || '',
                      vehicle.platform,
                      vehicle.secondaryPlatform,
                    ),
                  );
                }}
                value={`${form.values.recipient?.id ?? ''}`}
                data={userSelect}
                mt="md"
                searchable
                clearable
                
              />
            )}

            {targetReciever === 'recipientGroup' && (
              <SelectEditable
                dirty={form.isDirty('recipientGroup')}
                label="Recipient Group"
                placeholder="Search here"
                {...form.getInputProps('recipientGroup')}
                data={enumToSelectData(UserRole)}
                mt="md"
                searchable
              />
            )}
            <TextInputEditable
              dirty={form.isDirty('address')}
              label="Address"
              placeholder="Search here"
              {...form.getInputProps('address')}
              mt="md"
            />
            <TextInputEditable label="Google Map Link" placeholder="Search here" {...form.getInputProps('location')} mt="md" />
            <DatePickerInputEditable
              dirty={form.isDirty('targetDate')}
              placeholder="Search here"
              label="Task date"
              {...form.getInputProps('targetDate')}
              mt="md"
            />
            <DatePickerInputEditable
              dirty={form.isDirty('completionDate')}
              placeholder="Search here"
              label="Completion Date"
              {...form.getInputProps('completionDate')}
              mt="md"
              clearable={taskData?.data.status === TaskStatus.Pending}
            />
            <SelectEditable
              dirty={form.isDirty('organization.id')}
              label="Client"
              placeholder="Search here"
              onChange={(v) => {
                v && form.setFieldValue('organization.id', parseInt(v));
                setOrganizationId(parseInt(v || '0'));
                form.setFieldValue(
                  'title',
                  taskTitleFormater(
                    organizationsData?.data.items.filter((org) => v && org.id === parseInt(v))[0]?.abbreviation || '',
                    vehicle.plate,
                    form.values.type,
                    userData?.data.items.filter((user) => form.values.recipient && user.id === form.values.recipient.id)[0]?.firstName ||
                      '',
                    vehicle.platform,
                    vehicle.secondaryPlatform,
                  ),
                );
              }}
              searchable
              nothingFound="Not found"
              data={organizationsSelect}
              required
              value={`${form.values.organization.id ?? ''}`}
              mt="md"
            />
            <Paper withBorder mt="sm" p="md">
              <SegmentedControl
                value={targetContact}
                data={[
                  { label: 'Add New Contact', value: 'addContact' },
                  { label: 'Select Contact', value: 'selectContact' },
                ]}
                onChange={(v) => setTargetContact(v)}
              />

              {targetContact === 'addContact' && (
                <>
                  <TextInputEditable label="Contact Name" placeholder="Contact Name" {...form.getInputProps('contactName')} />
                  <TextInputEditable mt="sm" label="Contact Number" placeholder="Contact Number" {...form.getInputProps('contactNumber')} />
                </>
              )}
              {targetContact === 'selectContact' && (
                <Grid>
                  <Grid.Col span={8}>
                    <SelectEditable
                      label="Contact"
                      placeholder="Search here"
                      {...form.getInputProps('contacts.id')}
                      onChange={(v) => {
                        v && form.setFieldValue('contacts.id', parseInt(v));
                      }}
                      value={`${form.values.contacts?.id}`}
                      searchable
                      nothingFound="Not found"
                      data={contactsSelect}
                    />
                  </Grid.Col>
                  <Grid.Col span={4} mt="xl">
                    <Button fullWidth onClick={open}>
                      Add New Contact
                    </Button>
                  </Grid.Col>
                </Grid>
              )}
            </Paper>
            <Paper withBorder mt="sm" p="md">
              <SegmentedControl
                value={targetTaskGroup}
                data={[
                  { label: 'Add TaskGroup', value: 'addTaskGroup' },
                  { label: 'Select TaskGroup', value: 'selectTaskGroup' },
                ]}
                onChange={(v: typeof targetTaskGroup) => setTargetTaskGroup(v)}
              />

              {targetTaskGroup === 'addTaskGroup' && (
                <TextInput
                  label="Task Group"
                  placeholder="Task Group"
                  {...form.getInputProps('taskGroup.name')}
                  description={
                    <Group spacing={0}>
                      <Text>{`suggeted name: ${taskGroupSuggestion}`}</Text>
                      <ActionIcon onClick={() => form.setFieldValue('taskGroup.name', taskGroupSuggestion)}>
                        <TbCircleCheck />
                      </ActionIcon>
                    </Group>
                  }
                />
              )}
              {targetTaskGroup === 'selectTaskGroup' && (
                <Select
                  label="Task Group"
                  data={taskGroupsSelect}
                  {...form.getInputProps('taskGroup.id')}
                  value={`${form.values.taskGroup?.id}` || ''}
                  onChange={(v) => {
                    if (v) {
                      form.setFieldValue('taskGroup.id', +v);
                    }
                  }}
                />
              )}
            </Paper>
            {taskData?.data.type && (
              <Paper withBorder mt="sm" p="md">
                <Grid>
                  <Grid.Col span={8}>
                    <SelectInstallationUnit
                      type={form.values.type}
                      value={`${form.values.unit?.id}` ?? ''}
                      status={taskData?.data.status}
                      setValues={(vehicle) => {
                        form.setFieldValue('unit.id', parseInt(vehicle.value));
                        form.setFieldValue('organization.id', vehicle.orgID);
                        // kept the vehicle in order to update vehicle throw approval task may change this process later
                        form.values.type === TaskType.Installation && form.setFieldValue('vehicle.id', parseInt(vehicle.vehicleid));
                        setVehicle({
                          plate: vehicle.plate,
                          platform: vehicle.platform,
                          secondaryPlatform: vehicle.secondaryplatform,
                          orgID: vehicle.orgID,
                        });
                        form.setFieldValue(
                          'title',
                          taskTitleFormater(
                            organizationsData?.data.items.filter((org) => org.id === vehicle.orgID)[0]?.abbreviation || '',
                            vehicle.plate,
                            form.values.type,
                            userData?.data.items.filter((user) => user.id === form.values.recipient?.id)[0]?.firstName || '',
                            vehicle?.platform,
                            vehicle?.secondaryplatform,
                          ),
                        );
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={4} mt="xl">
                    <Button fullWidth onClick={openCreateUnit}>
                      Add New Unit
                    </Button>
                  </Grid.Col>
                </Grid>
              </Paper>
            )}

            <Textarea label="Remarks" placeholder="Remarks" {...form.getInputProps('taskRemarks')} mt="md" />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {organizationId !== 0 && (
            <DetailsCard
              id={organizationId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter((org) => org.id === organizationId)[0].imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
      <Modal
        opened={opened}
        size={'60%'}
        onClose={close}
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Add New Contact
          </Text>
        }
      >
        <NewContactsForm orgID={organizationId} />
      </Modal>
      <Modal
        opened={openedCreateUnit}
        size={'60%'}
        onClose={closeCreateUnit}
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Add New Contact
          </Text>
        }
      >
        <NewUnitForm />
      </Modal>
    </Layout>
  );
}
