import { Column } from '@tanstack/react-table';
import { useState } from 'react';
import { SelectEnumColumn, TimeInputField } from '../../inputs';
import DateRangeInput from '../../inputs/DateRangeInput';
import DebouncedInput from '../../inputs/DebouncedInput';


export function ColumnSearchFilter({
  column,
  columnTypes,
  filterData,
  toSelect
}: {
  column: Column<any, unknown>;
  columnTypes?: object,
  filterData?: (column: string, data: any, type:string) => void,
  toSelect?: Record<string, Record<string, string>>
}) {
  const [value, setValue] = useState("");
  const columnType = columnTypes ? Object.entries(columnTypes).find(([key,value]) => {
    if(column.columnDef.header === key)
     return value
  })?.[1] as string : undefined

  const columnEnum = toSelect ? Object.entries(toSelect).find(([key,value]) => {
    if(column.columnDef.header === key)
     return value
  })?.[1] as Record<string, string> : undefined

  return columnType === 'date' ?  
  (
    <DateRangeInput label={column.id} filterData={filterData} />  
    ) : columnType === 'time' ? <TimeInputField value={value} setValue={setValue} label={column.id} filterData={filterData} /> : columnType === 'NA' ? <></> : 
    columnEnum ? <SelectEnumColumn value={value} setValue={setValue} dbLabel={column.id} label={column.columnDef.header as string} filterData={filterData} data={columnEnum} /> :(
      <DebouncedInput  label={column.id}  value={value} setValue={setValue} filterData={filterData} />
    )
}