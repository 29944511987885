import { useDisclosure } from '@mantine/hooks';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_REFRESH, IaxiosError, getImageCollection, signOut, useRefresh } from '../modules/common';
import { notifications } from '@mantine/notifications';
import { UserRole } from '../modules/users';

type IAuthContext = ReturnType<typeof useAuth>;

export interface IauthUser {
  id: number;
  username: string;
  role: UserRole;
  img: string;
  orgnizationId: number;
}

const useAuth = () => {
  const [user, setUser] = useState<IauthUser | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const location = useLocation();
  const refresh = useRefresh();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(signOut, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_REFRESH]);
      setIsLoggedIn(false);
      setUser(null);
      // refresh.refetch()
      // navigate(`/login?redirect=${location.pathname}`);
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: false,
      });
      setUser(null);
      setIsLoggedIn(false);
      // navigate(`/login?redirect=${location.pathname}`);
    },
  });

  const login = useCallback((user: IauthUser) => {
    refresh.refetch();
    setIsLoggedIn(true);
    setUser(user);
  }, []);

  const logout = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    if (!isLoggedIn) {
      const { isError, data } = refresh;
      if (!isError && data && data.data.profile.imageRepositoryId) {
        getImageCollection(data.data.profile.imageRepositoryId).then((collection) => {
          const link = collection.data.collection.find((image) => image.id === collection.data.previewId) || undefined;
          setUser({
            id: data.data.id,
            username: data.data.username,
            role: data.data.profile.role,
            orgnizationId: data.data.profile.organizationId,
            img: link?.path ? `${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${link?.path}` : '',
          });
          setIsLoggedIn(true);
          if (location.pathname === '/login') {
            navigate('/');
          }
        });
      } else if (!isError && data) {
        setUser({
          id: data.data.id,
          username: data.data.username,
          role: data.data.profile.role,
          orgnizationId: data.data.profile.organizationId,
          img: '',
        });
        setIsLoggedIn(true);
        if (location.pathname === '/login') {
          navigate(`/login?redirect=${location.pathname}`);
        }
      }

      if (
        isError &&
        location.pathname !== '/login' &&
        location.pathname !== '/signup' &&
        location.pathname !== '/forgotPassword' &&
        location.pathname !== '/reset-password'
      ) {
        setIsLoggedIn(false);
        setUser(null);
        navigate(`/login?redirect=${location.pathname}`);
      }
    }
  }, [isLoggedIn, location.pathname, navigate, refresh]);

  const value = useMemo(
    () => ({ user, isLoggedIn, login, logout, refresh, opened, toggle, close }),
    [isLoggedIn, login, logout, refresh, user, opened, toggle, close],
  );

  return value;
};

export const AuthProvider = (props: React.PropsWithChildren) => {
  const value = useAuth();
  return <AuthContext.Provider value={value} {...props} />;
};

export const AuthContext = createContext<IAuthContext | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useContext must be used within a Provider`);
  }
  return context;
};
