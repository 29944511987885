import { AppShell, useMantineTheme } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import Header from './Header';
import SideNav from './SideNav';

const Layout = ({ children }: PropsWithChildren) => {
  const theme = useMantineTheme();
  const { opened, toggle, isLoggedIn } = useAuthContext();

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={isLoggedIn ? <SideNav opened={opened} />: undefined}
      header={<Header opened={opened} toggle={toggle} links={[]} />}
    >
      {children}
    </AppShell>
  );
};

export default Layout;
