import { Button, Container, Group, LoadingOverlay, Paper, SegmentedControl } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { DatePickerInputEditable, SelectEditable } from "../../../components/form";
import Layout from "../../../components/layout/Layout";
import { types } from "../../../constants";
import { useAuthContext } from "../../../context/AuthContext";
import { URLParams, enumToSelectData, selectColumns } from "../../../utils";
import { IaxiosError } from "../../common";
import { patchAttendance, useAttendances } from "../api";
import { AttendanceColumnsMode, AttendanceStatus } from "../constants";

export function UpdateAttendanceForm() {

    const [day, setDay] = useState(`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${new Date().getDate()}`)    
    const [targetTImeType, setTargetTImeType] = useState<'updateTimeIn' | 'UpdateTimeOut' | 'Both'>('updateTimeIn');

    const { user } = useAuthContext();
    const dataParams = new URLParams();
    if (user) {
        dataParams.filterSetField('=userId', user.id);
        dataParams.includes('user');
        dataParams.select(...selectColumns(AttendanceColumnsMode), 'user.id');
    }
    dataParams.filterSetField('day', day);
    dataParams.sort('-day');
    const { data: allAttendance, isLoading } = useAttendances({
        params: dataParams.toString(),
        then: (x) => {
            console.log(x?.data.items[0]);
            form.setValues({
                id: x?.data.items[0].id ?? 0,
                timeOut: x?.data.items[0].timeOut === null ? new Date() : x?.data.items[0].timeOut,
                timeIn: x?.data.items[0].timeIn === null ? new Date() : x?.data.items[0].timeIn,
                status: x?.data.items[0].status ?? 'present',
            })
        }
    });
    const form = useForm({
        initialValues: {
          id: allAttendance?.data.items[0].id ?? 0,
          timeOut: allAttendance?.data.items[0].timeOut === null ? new Date() : allAttendance?.data.items[0].timeOut,
          timeIn: allAttendance?.data.items[0].timeIn === null ? new Date() : allAttendance?.data.items[0].timeIn,
          status: allAttendance?.data.items[0].status ?? 'present',
        },
      });

      const queryClient = useQueryClient();
      const { mutate: patchMutate, isLoading: patchLoading } = useMutation(patchAttendance, {
        onSuccess: () => {
          queryClient.invalidateQueries([types.Attendance, types.ALL]);
          queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
          });
        },
        onError: (data: IaxiosError) => {
          console.log(data.response.data.message);
        },
      });
      function handleSubmit() {
        const newValue: Record<"status" | "timeOut" | "timeIn", any> = {status: form.values.status, timeIn: form.values.status === AttendanceStatus.Present ? form.values.timeIn : null, timeOut: form.values.status === AttendanceStatus.Present ? form.values.timeOut : null};
        
        patchMutate({ id: form.values.id, data: { ...newValue } })
        // PatchMutate({ id: form.values.id, data: {...newValue} );
        }
      


    return (
        <Layout>
            <Container  pt={10}>
                <LoadingOverlay visible={isLoading || patchLoading} overlayBlur={2} />
                <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
                    <DatePickerInputEditable
                    placeholder="Search Date"
                    label="attendance Date"
                    value={new Date(day)}
                    onChange={(v) => v && setDay(`${v.getFullYear()}-${(v.getMonth()+1).toString().padStart(2, '0')}-${v.getDate().toString().padStart(2, '0')}`)}
                    required
                    maxDate={new Date()}
                    mt="md"
                    />
                    <SegmentedControl
                    mt='lg'
                    value={targetTImeType}
                    data={[
                    { label: 'update timein', value: 'updateTimeIn' },
                    { label: 'update timeout', value: 'UpdateTimeOut' },
                    { label: 'both', value: 'Both' },
                    ]}
                    onChange={(v: typeof targetTImeType) => setTargetTImeType(v)}
                    />
                    <Container>
                        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
                            <SelectEditable
                            label="Status"
                            data={enumToSelectData(AttendanceStatus)}
                            {...form.getInputProps('status')}
                            withinPortal
                            />
                            <Group position="apart" mt='md'>
                                <TimeInput disabled={targetTImeType === 'UpdateTimeOut'} required label="Time In: " {...form.getInputProps('timeIn')} />
                                <TimeInput disabled={targetTImeType === 'updateTimeIn'} required label="Time Out: " {...form.getInputProps('timeOut')} />
                            </Group>
                        </Paper>
                    </Container>

                    <Button onClick={handleSubmit} mt={'md'}>Submit</Button>
                </Paper>
            </Container>
        </Layout>
    )
}