import api from '../../common/api/api';
import { IPurchaseOrderInput, IpurchaseOrder } from '../types/type';


interface myResPurchaseOrderPost {
  data: IpurchaseOrder;
}

export const postPurchaseOrder = (data: IPurchaseOrderInput): Promise<myResPurchaseOrderPost> => {
  return api.post(`purchase-orders`, data);
};

export const postPurchaseOrders = (data: Record<string, any>[]): Promise<myResPurchaseOrderPost[]> => {
  return api.post(`purchase-orders/multi`, data);
};
