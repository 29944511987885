import { LoadingOverlay, SegmentedControl, Text, createStyles, rem } from '@mantine/core';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAnalyticsTasks } from '../../modules/tasks';
import { URLGroupParams } from '../../utils';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing.md,
    display: 'flex',
    backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
      theme.colors[theme.primaryColor][7]
    } 100%)`,
    padding: `calc(${theme.spacing.xl} * 1.5)`,
    borderRadius: theme.radius.md,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  title: {
    color: theme.white,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
  },

  count: {
    color: theme.white,
    fontSize: rem(32),
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    fontSize: theme.fontSizes.sm,
    marginTop: rem(5),
  },

  stat: {
    flex: 1,

    '& + &': {
      paddingLeft: theme.spacing.xl,
      marginLeft: theme.spacing.xl,
      borderLeft: `${rem(1)} solid ${theme.colors[theme.primaryColor][3]}`,

      [theme.fn.smallerThan('sm')]: {
        paddingLeft: 0,
        marginLeft: 0,
        borderLeft: 0,
        paddingTop: theme.spacing.xl,
        marginTop: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${theme.colors[theme.primaryColor][3]}`,
      },
    },
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor: 'transparent',
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 0,
      marginBottom: theme.spacing.xl,
    },
    color: theme.colors[theme.primaryColor][3],
    label: {
        '&[data-active]': {
            '&, &:hover': {
              color: theme.black,
            },
          },
    },
  },
  seg: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor: 'transparent',
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 0,
      marginBottom: theme.spacing.xl,
    },
    color: theme.colors[theme.primaryColor][3],
    label: {
        color: theme.white,
        '&[data-active]': {
            '&, &:hover': {
              color: theme.black,
            },
          },
          '&:hover': {
            color: theme.white,
          },
    },
    controlActive: {
        backgroundColor: `blue !important`,
    }
  },

  date: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  control: {
    height: rem(28),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    transition: 'background-color 50ms ease',

    [theme.fn.smallerThan('xs')]: {
      height: rem(34),
      width: rem(34),
    },

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][5],
      color: theme.white,
    },
  },

  controlIcon: {
    [theme.fn.smallerThan('xs')]: {
      transform: 'rotate(-90deg)',
    },
  },
  month: {
    fontSize: rem(44),
    fontWeight: 700,
    color: theme.white,
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 5,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));


const periods = [
    { value: '7', label: 'Weekly' },
    { value: '15', label: 'Half Month' },
    { value: '30', label: 'Monthly' },
  ]


  function diffFormela(lastperiod: number, periodBefore: number) {
    return (lastperiod - periodBefore) / ((periodBefore === 0 ? lastperiod : periodBefore) / 100)
 }


export function PeriodlyTasksWidget() {
  const { classes } = useStyles();
  const [ period, setPeriod ] = useState('30')
  const pendingParams = new URLGroupParams()
  pendingParams.period(+period, 'createdAt')
  pendingParams.range(dayjs(new Date()).subtract((+period * 2), 'day').toDate(), new Date(), 'createdAt')
  const { data: createdTasks, isLoading: createdTasksLoading } = useAnalyticsTasks({ params: pendingParams.toString() });
  const countCreatedTasks = createdTasks?.data.find((stat) => stat.daysAgo === period)?.count || "0"
  const percentageCreatedTasks = diffFormela(+countCreatedTasks, +(createdTasks?.data.find((stat) => stat.daysAgo === `${+period * 2}`)?.count || '0')).toFixed()

  const completedParams = new URLGroupParams()
  completedParams.period(+period, 'completionDate')
  completedParams.range(dayjs(new Date()).subtract((+period * 2), 'day').toDate(), new Date(), 'completionDate')
  completedParams.filterSetField('status', 'done')
  const { data: completedTasks, isLoading: completedTasksLoading } = useAnalyticsTasks({ params: completedParams.toString() });
  const countCompletedTasks = completedTasks?.data.find((stat) => stat.daysAgo === period)?.count || "0"
  const percentageCompletedTasks = diffFormela(+countCompletedTasks, +(completedTasks?.data.find((stat) => stat.daysAgo === `${+period * 2}`)?.count || '0')).toFixed()

  return (
    <div className={classes.root}>
      <LoadingOverlay visible={createdTasksLoading || completedTasksLoading} />
            <SegmentedControl 
            orientation="vertical" 
            value={period}
            className={classes.seg} 
            data={periods} 
            onChange={(v) => v && setPeriod(v)}
            styles={(theme) => ({
                controlActive:{
                    backgroundColor: theme.white
                },
                indicator: {
                    backgroundColor: theme.white
                },
                control: {
                    '&:not(:first-of-type)': {
                        borderWidth: `0`,
                      },
                }
            })  
                }
            />
        <div className={classes.stat}>
        <Text className={classes.count}>{countCreatedTasks}</Text>
        <Text className={classes.title}>Created Tasks</Text>
        <Text className={classes.description}>{percentageCreatedTasks}% compared to previous {period} days</Text>
        </div>
        <div className={classes.stat}>
        <Text className={classes.count}>{countCompletedTasks}</Text>
        <Text className={classes.title}>Completed Tasks</Text>
        <Text className={classes.description}>{percentageCompletedTasks}% compared to previous {period} days</Text>
        </div>
    </div>
    );
}