import { DeviceStatus } from "../modules/devices";
import { PurchaseOrderStatus } from "../modules/finance";
import { OrganizationStatus } from "../modules/organizations";
import { SimStatus } from "../modules/sim-cards";
import { TaskStatus, TaskType } from "../modules/tasks";
import { IfullUnit, unitStatus } from "../modules/unit";
import { URLParams } from "./URLParams";

export function getUnitStatusParam(value: string, param: URLParams, cols?:string){
    // note I am setting this for the units table page. So I am using fixed columns maybe you can send the parent column for each column if you want it

        // find better way
    if(cols){
        cols.split('|').forEach(col => {
            const key = param.filterKeys().find(params => params.includes(col))
            if(key) param.filterRemoveField(key)
        })
    }
    if(value === unitStatus.Not_Active){
        param.filterSetLevel(2, '|')
        param.filterSetField('=vehicle.id', 'null', 2)
        param.filterSetField('=device.id', 'null', 2)
        param.filterSetField('=simCard.id', 'null', 2)
        param.filterSetField('=simCard.status', SimStatus.Deactivated, 2)
        param.filterSetField('=org.status', OrganizationStatus.Not_Active, 2)
        param.filterSetField('=purchaseOrderPlan.status', PurchaseOrderStatus.Cancelled, 2)
        param.filterSetField('=purchaseOrderPlan.status', PurchaseOrderStatus.Paid, 2)
        param.filterSetField('=device.status', DeviceStatus.Broken, 2)
        param.filterSetField('=device.status', DeviceStatus.Lost, 2)
        param.filterSetField('=device.status', DeviceStatus.Not_Active, 2)
        param.filterSetLevel(3, '&')
        param.filterSetField('unit.id', `SQL<>(select unit.id where tasks.type = 'installation' AND tasks.status = 'pending')`, 3)
        param.filterSetField('unit.id', `SQL<>(select unit.id where tasks.type = 'installation' AND tasks.status = 'to be approved')`, 3)
    }
    else if(value === unitStatus.Pending_Installation){
        param.filterSetLevel(2, '&')
        param.filterSetField('vehicle.id', 'null', 2)
        param.filterSetField('=device.id', 'null', 2)
        param.filterSetField('=simCard.id', 'null', 2)
        param.filterSetField('=tasks.type', TaskType.Installation, 2)
        param.filterSetField('=tasks.status', TaskStatus.Pending, 2)
        // param.filterSetField('simCard.status', `!${SimStatus.Deactivated}` , 2)
        param.filterSetField('org.status', `!${OrganizationStatus.Not_Active}` , 2)
        param.filterSetLevel(3, '|')
        param.filterSetField('=purchaseOrderPlan.status', PurchaseOrderStatus.OnGoing, 3)
        param.filterSetField('=purchaseOrderPlan.status', 'null', 3)
    }
    else if(value === unitStatus.To_Be_Approved_Installation){
        param.filterSetLevel(2, '&')
        param.filterSetField('vehicle.id', 'null', 2)
        param.filterSetField('=device.id', 'null', 2)
        param.filterSetField('=simCard.id', 'null', 2)
        param.filterSetField('=tasks.type', TaskType.Installation, 2)
        param.filterSetField('=tasks.status', TaskStatus.ToBeApproved, 2)
        // param.filterSetField('simCard.status', `!${SimStatus.Deactivated}` , 2)
        param.filterSetField('org.status', `!${OrganizationStatus.Not_Active}` , 2)
        param.filterSetLevel(3, '|')
        param.filterSetField('=purchaseOrderPlan.status', PurchaseOrderStatus.OnGoing, 3)
        param.filterSetField('=purchaseOrderPlan.status', 'null', 3)
    }
    else if(value === unitStatus.Pending_Removal){
        param.filterSetLevel(2, '&')
        param.filterSetField('vehicle.id', 'null', 2)
        param.filterSetField('device.id', 'null', 2)
        param.filterSetField('simCard.id', 'null', 2)
        param.filterSetField('=tasks.type', TaskType.Removal, 2)
        param.filterSetField('=tasks.status', TaskStatus.Pending, 2)
        param.filterSetField('simCard.status', `!${SimStatus.Deactivated}`, 2)
        param.filterSetField('org.status', `!${OrganizationStatus.Not_Active}`, 2)
        param.filterSetLevel(3, '|')
        param.filterSetField('=purchaseOrderPlan.status', PurchaseOrderStatus.OnGoing, 3)
        param.filterSetField('=purchaseOrderPlan.status', 'null', 3)
    }
    else if(value === unitStatus.To_Be_Approved_Removal){
        param.filterSetLevel(2, '&')
        param.filterSetField('vehicle.id', 'null', 2)
        param.filterSetField('device.id', 'null', 2)
        param.filterSetField('simCard.id', 'null', 2)
        param.filterSetField('=tasks.type', TaskType.Removal, 2)
        param.filterSetField('=tasks.status', TaskStatus.ToBeApproved, 2)
        param.filterSetField('simCard.status', `!${SimStatus.Deactivated}` , 2)
        param.filterSetField('org.status', `!${OrganizationStatus.Not_Active}` , 2)
        param.filterSetLevel(3, '|')
        param.filterSetField('=purchaseOrderPlan.status', PurchaseOrderStatus.OnGoing, 3)
        param.filterSetField('=purchaseOrderPlan.status', 'null', 3)
        
    }else if(value === unitStatus.Active){
        param.filterSetLevel(2, '&')
        param.filterSetField('vehicle.id', 'null', 2)
        param.filterSetField('device.id', 'null', 2)
        param.filterSetField('simCard.id', 'null', 2)
        param.filterSetField('simCard.status', `!${SimStatus.Deactivated}` , 2)
        param.filterSetField('org.status', `!${OrganizationStatus.Not_Active}` , 2)
        param.filterSetField('device.status', `!${DeviceStatus.Broken}`, 2)
        param.filterSetField('=device.status', `!${DeviceStatus.Lost}`, 2)
        param.filterSetField('=device.status', `!${DeviceStatus.Not_Active}`, 2)
        param.filterSetLevel(3, '&')
        param.filterSetField('unit.id', `SQL<>(select unit.id where tasks.type = 'removal' AND tasks.status = 'pending')`, 3)
        param.filterSetField('unit.id', `SQL<>(select unit.id where tasks.type = 'removal' AND tasks.status = 'to be approved')`, 3)
        // param.filterSetField('unit.id', `SQL<>(select unit.id where tasks.type = 'installation' AND tasks.status = 'pending')`, 3)
        // param.filterSetField('unit.id', `SQL<>(select unit.id where tasks.type = 'installation' AND tasks.status = 'to be approved')`, 3)
        param.filterSetLevel(4, '|')
        param.filterSetField('=purchaseOrderPlan.status', PurchaseOrderStatus.OnGoing, 4)
        param.filterSetField('=purchaseOrderPlan.status', 'null', 4)
    }
    return param
}

export function getUnitsWithStatus(data: IfullUnit[]){
    return data.map(unit => {
        if((!unit.device || !unit.vehicle || !unit.simCard) || (unit.device?.status === DeviceStatus.Not_Active || unit.device?.status === DeviceStatus.Broken || unit.device?.status === DeviceStatus.Lost) || (unit.simCard && unit.simCard?.status === SimStatus.Deactivated) || (unit.vehicle?.organization?.status && unit.vehicle?.organization?.status === OrganizationStatus.Not_Active) ||  unit.purchaseOrderPlan?.status === PurchaseOrderStatus.Cancelled || unit.purchaseOrderPlan?.status === PurchaseOrderStatus.Paid){
            if((unit.simCard && unit.simCard?.status === SimStatus.Deactivated) || (unit.vehicle?.organization?.status && unit.vehicle?.organization?.status === OrganizationStatus.Not_Active) ||  unit.purchaseOrderPlan?.status === PurchaseOrderStatus.Cancelled || unit.purchaseOrderPlan?.status === PurchaseOrderStatus.Paid){
                unit.status = unitStatus.Not_Active
            }else if(unit.device?.status === DeviceStatus.Not_Active || unit.device?.status === DeviceStatus.Broken || unit.device?.status === DeviceStatus.Lost){
                unit.status = unitStatus.Not_Active
            }
            else if(unit.tasks && unit.tasks.filter(task => task.type === TaskType.Installation && task.status === TaskStatus.Pending).length){
                unit.status = unitStatus.Pending_Installation
            }
            else if(unit.tasks && unit.tasks.filter(task => task.type === TaskType.Installation && task.status === TaskStatus.ToBeApproved).length){
                unit.status = unitStatus.To_Be_Approved_Installation
            }else{
                unit.status = unitStatus.Not_Active
            }
        }else {
            if(unit.tasks && unit.tasks.filter(task => task.type === TaskType.Removal && task.status === TaskStatus.Pending).length){
                unit.status = unitStatus.Pending_Removal
            }
            else if(unit.tasks && unit.tasks.filter(task => task.type === TaskType.Removal && task.status === TaskStatus.ToBeApproved).length){
                unit.status = unitStatus.To_Be_Approved_Removal
            }
            else if(unit.tasks && unit.tasks.filter(task => task.type === TaskType.DeviceChange && task.status === TaskStatus.Pending).length){
                unit.status = 'pending device Change'
            }
            else if(unit.tasks && unit.tasks.filter(task => task.type === TaskType.DeviceChange && task.status === TaskStatus.ToBeApproved).length){
                unit.status = 'TBA device Change'
            }
            else if(unit.tasks && unit.tasks.filter(task => task.type === TaskType.ReInstallation && task.status === TaskStatus.Pending).length){
                unit.status = 'pending ReInstallation'
            }
            else if(unit.tasks && unit.tasks.filter(task => task.type === TaskType.ReInstallation && task.status === TaskStatus.ToBeApproved).length){
                unit.status = 'TBA ReInstallation'
            }
            else {
                
                unit.status = unitStatus.Active
            } 
        }

        return unit
    })
}