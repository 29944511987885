import api from '../../common/api/api';
import { IapproveTaskInput, Task } from '../types/types';

interface myResApproveTaskPatch {
  data: Task;
}

export const patchApproveTask = (props: { id: number; data: IapproveTaskInput }): Promise<myResApproveTaskPatch> => {
  return api.patch(`tasks/job/approve/${props.id}`, props.data);
};
