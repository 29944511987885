  
  import Layout from '../../../components/layout/Layout';
import { NewOrganizationForm } from '../components/forms/NewOrganizationForm';
    
    export function NewOrganizationFormPage() {
      return (
        <Layout>
          <NewOrganizationForm/>
        </Layout>
      );
    }
    