import { Badge, Button, Card, Container, createStyles, Grid, Group, Image, Modal, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { IcollectionLink, useImageCollection } from "../../modules/common"

const useStyles = createStyles({
   cardContainer: {
      width: 360,
      minHeight: 380,
      height: '100%'
   },

   textHeight: {
      minHeight: 60,
      height: '100%'
   }
})



export function BaseCard({ name, discription, to, img, label, color, updateModalContent }: { name: string, discription: string, to: string, img: string | number | null, label?: string, color?: string | null, updateModalContent?: JSX.Element }) {
   const [opened, { open, close }] = useDisclosure(false);

   const { classes } = useStyles()
   const navigate = useNavigate()
   const [imageLink, setImageLink] = useState<IcollectionLink>()
   const data = typeof img === 'number' ? useImageCollection(img) : undefined
   const collection = data?.data?.data;

   useEffect(() => {
      if (collection) {
         setImageLink(collection.collection.find((image) => image.id === collection.previewId))
      }
   }, [collection])

   return (
      <Container p={0} className={classes.cardContainer}>
         <Card withBorder shadow="sm" radius="md" m={15} >
            <Card.Section>
               <Image
                  src={imageLink?.path ? `${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${imageLink?.path}` : ''}
                  height={180}
                  fit="contain"
                  withPlaceholder
               />
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
               <Text weight={500}>{name}</Text>
               {label && color && 
               <Badge 
               c={color} 
               styles={{root:{backgroundColor: color+40}}}
               variant="light">
                  {label}
               </Badge>}
            </Group>

            <Text size="sm" color="dimmed" className={classes.textHeight}>
               {discription}
            </Text>
            <Grid>
               <Grid.Col span={updateModalContent ? 7 : 12}>
                  <Button variant="light" fullWidth color="green" mt="md" radius="md" onClick={() => navigate(to)}>
                     Open full details
                  </Button>
               </Grid.Col>
               {updateModalContent && <Grid.Col span={5}>

                  <Button variant="light" fullWidth color="blue" mt="md" radius="md" onClick={open}>
                     Edit
                  </Button>
               </Grid.Col>}
            </Grid>
         </Card>
         <Modal opened={opened} onClose={close} size="60%">
            {updateModalContent}
         </Modal>
      </Container>
   )
}
