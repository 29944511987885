import { Center, Container, SimpleGrid, Text, UnstyledButton, createStyles } from '@mantine/core';
import { IconType } from 'react-icons';
import { TbAntennaBarsOff, TbChecklist, TbDeviceIpadCog, TbLiveView, TbTicket, TbWallpaper } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { useAuthContext } from '../context/AuthContext';
import { UserRole } from '../modules/users';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.md,
    height: 145,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: `${theme.shadows.md} !important`,
      transform: 'scale(1.05)',
    },
  },
}));

export function Services() {
  const { classes, theme } = useStyles();
  const { user } = useAuthContext();

  const navigate = useNavigate();

  const servicesButtons: {
    title: string;
    icon: IconType;
    color: string;
    link: string;
    noDisplay: UserRole[];
    Display: UserRole[];
  }[] = [
    {
      title: 'Live Tracking',
      icon: TbLiveView,
      color: 'teal',
      link: '/live',
      noDisplay: [],
      Display: [],
    },
    {
      title: 'Attendence',
      icon: TbChecklist,
      color: 'violet',
      link: '/attendance-report',
      noDisplay: [],
      Display: [UserRole.Admin, UserRole.Master],
    },
    {
      title: 'Export Active Units',
      icon: TbWallpaper,
      color: 'orange',
      link: '/active-units',
      noDisplay: [],
      Display: [UserRole.Admin, UserRole.Master, UserRole.Support],
    },
    {
      title: 'Non Reporting',
      icon: TbAntennaBarsOff,
      color: 'blue',
      link: '/non-reporting',
      noDisplay: [],
      Display: [UserRole.Master, UserRole.Support],
    },
    {
      title: 'Tickets',
      icon: TbTicket,
      color: 'red',
      link: user?.role !== UserRole.Client ? '/supporttickets' : '/tickets',
      noDisplay: [UserRole.Technician, UserRole.Finance],
      Display: [],
    },
    {
      title: 'Issue Devices',
      icon: TbDeviceIpadCog,
      color: 'yellow',
      link: '/issue-devices',
      noDisplay: [],
      Display: [UserRole.Master, UserRole.Support],
    },
    //  { title: 'Refunds', icon: FaExpandArrowsAlt, color: 'green', link: '', noDisplay: [], Display: [] },
    //  { title: 'Receipts', icon: FaExpandArrowsAlt, color: 'teal', link: '', noDisplay: [], Display: [] },
    //  { title: 'Taxes', icon: FaExpandArrowsAlt, color: 'cyan', link: '', noDisplay: [], Display: [] },
  ];

  const items = servicesButtons.map((item) => {
    if (user && item.noDisplay.includes(user?.role)) return null;
    if (item.Display.length === 0 || (user && item.Display.includes(user?.role)))
      return (
        <UnstyledButton key={item.title} className={classes.item} onClick={() => navigate(item.link)}>
          <item.icon color={theme.colors[item.color][6]} size={40} />
          <Text size="ms" mt={7}>
            {item.title}
          </Text>
        </UnstyledButton>
      );
    else return null;
  });

  return (
    <Layout>
      <Container>
        {items.filter((item) => item !== null).filter((item) => item !== undefined).length > 0 ? (
          <SimpleGrid cols={3} mt="md">
            {items}
          </SimpleGrid>
        ) : (
          <Center h="100%" w="100%">
            No Services
          </Center>
        )}
      </Container>
    </Layout>
  );
}
