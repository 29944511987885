import api from '../../common/api/api';
import { IPurchasePlanInput, IpurchasePlan } from '../types/type';


interface myResPurchasePlanPatch {
  data: IpurchasePlan;
}

export const patchPurchaseOrderPlan = (props: { id: number; data: Partial<IPurchasePlanInput> }): Promise<myResPurchasePlanPatch> => {
  return api.patch(`purchase-order-plan/${props.id}`, props.data);
};


export const patchRemoveUnit = (props: { id: number; data: Partial<IPurchasePlanInput> }): Promise<myResPurchasePlanPatch> => {
return api.patch(`purchase-order-plan/unit/${props.id}`, props.data);
};
export const patchRestoreUnit = (props: { id: number; data: Partial<IPurchasePlanInput> }): Promise<myResPurchasePlanPatch> => {
  return api.patch(`purchase-order-plan/restoreunit/${props.id}`, props.data);
};

