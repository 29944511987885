import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Contacts } from '../types/types';

interface myResContacts {
  data: {
    items: Contacts[];
    meta: metaData,
  };
}

export const getContacts = (query = ''): Promise<myResContacts> => {
  return api.get(`contacts${query}`);
};

export const useContacts = (options?: { params?: string; then?: (x: myResContacts) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Contacts, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getContacts(options?.params).then((contacts) => {
      options && options.then && options.then(contacts);
      return contacts;
    }),
  );
  return query;
};
