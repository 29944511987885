import { Group } from '@mantine/core';

import { TbSearch } from 'react-icons/tb';
import { TableInstanceProps } from '../../../types';
import KeyboardInput from '../../inputs/KeyboardInput';
import { useGlobalFilter } from '../hooks';
import { GlobalFilterChips } from './GlobalFilterChips';

export function GlobalSearchInput<Entity extends object>({
  instance,
  globalFilterData
}: TableInstanceProps<Entity> & {globalFilterData?: (data: string, headers: string[]) => void }) {
  const { addGlobalFilter, globalFilter, clearGlobalFilter } =
    useGlobalFilter(instance, globalFilterData);

  return (
    <Group>
      <KeyboardInput icon={<TbSearch />} label="Search" handleValue={addGlobalFilter} />
      <GlobalFilterChips
        globalFilter={globalFilter}
        clearGlobalFilter={clearGlobalFilter}
      />
    </Group>
  );
}
