import { URLParams } from "./URLParams"

function handleFilter(param: URLParams, col: string, val:any, type:string, level: number){
  param.filterRemoveField(col)
  param.filterRemoveField('='+col)
  if(type === 'date'){
    if(Array.isArray(val)){
      param.filterRemoveDate(col)
      if(val[0] && val[1]){
        param.date(val[0], val[1], col)
      }
    }else {
      const date = new Date(val)
      param.filterSetField(col, `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`)
    }
  }else if(type === 'null'){
    param.filterSetField(val === '=' ? val+col : col, 'null', level)
  }
  else{
    param.filterSetField(col, val, level)
  }
}

export const setTableParams = (column:string, value:any, type: string, setParams: React.Dispatch<React.SetStateAction<URLParams>>) => {
  
  if(value){
    setParams((param) => {
      param.filterRemovePages()
      param.page(10)
      if(column.includes('|')){
        const columns = column.split('|')
        columns.forEach((col) => {
          param.filterSetLevel(1, '|')
          handleFilter(param, col, value, type, 1)
        })
      }else{
        handleFilter(param, column, value, type, 0)
      }
      return param
    })
  }else{
        setParams((param) => {
          if(column.includes('|')){
            const columns = column.split('|')
            columns.forEach((col) => {
              param.filterRemoveDate(col)
              param.filterRemoveField(col)
              param.filterRemoveField('='+col)
            })
          }else{
            param.filterRemoveDate(column)
            param.filterRemoveField(column)
            param.filterRemoveField('='+column)
          }
          return param
        })
      }
}