import {
   Avatar,
   Badge,
   Group,
   Text,
   UnstyledButton,
   UnstyledButtonProps,
   createStyles,
} from '@mantine/core';
import { forwardRef } from 'react';
import { TbChevronRight } from 'react-icons/tb';
import { UserRole } from '../../modules/users';

const useStyles = createStyles((theme) => ({
   user: {
      display: 'block',
      width: '100%',
      padding: theme.spacing.md,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

      '&:hover': {
         backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
      },
   },
}));

interface UserButtonProps extends UnstyledButtonProps {
   image: string;
   name: string;
   role: string;
   attendance?: number
   icon?: React.ReactNode;
}

export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(({ image, name, role, icon, attendance, ...others }: UserButtonProps, ref) => {
   const { classes } = useStyles();

   return (
      <UnstyledButton<'button'> className={classes.user} ref={ref} {...others}>
         <Group>
            <Avatar src={image} radius="xl" />

            <div style={{ flex: 1 }}>
               <Text size="sm" weight={500}>
                  {name}
               </Text>

               <Text color="dimmed" size="xs">
                  {role}
               </Text>
            </div>
            {!attendance && role === UserRole.Technician && <Badge size='xs' style={{ flex: 1 }}>1</Badge>}
            {icon || <TbChevronRight size={14} />}
         </Group>
      </UnstyledButton>
   );
})