import { Button, LoadingOverlay } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useMutation, useQueryClient } from 'react-query';
import { types } from '../../../constants';
import { IauthUser } from '../../../context/AuthContext';
import { IaxiosError } from '../../common';
import { patchAttendance } from '../api/patchAttendance';
import { IattendanceInput } from '../types/types';
import { useLocation } from '../../../utils';
import { notifications } from '@mantine/notifications';

type timeOut = Omit<IattendanceInput, 'timeIn' | 'timeInSubmittedAt' | 'day' | 'status' | 'remarks' | 'timeInLatitude' | 'timeInLongitude'>;

export function FormTimeOut({
  id,
  user,
  timeOut,
  timeOutSubmittedAt,
}: {
  id: number;
  user: IauthUser | null;
  timeOut: Date;
  timeOutSubmittedAt: Date;
}) {
  const { location, error, getLocation } = useLocation();
  const formTimeOut = useForm<timeOut>({
    initialValues: {
      id: id,
      user: user ? { id: user.id } : { id: 0 },
      timeOut: timeOut === null ? new Date() : timeOut,
      timeOutSubmittedAt: timeOutSubmittedAt === null ? new Date() : timeOutSubmittedAt,
      timeOutLatitude: null,
      timeOutLongitude: null,
    },
  });

  const queryClient = useQueryClient();
  const { mutate: PatchMutate, isLoading } = useMutation(patchAttendance, {
    onSuccess: () => {
      queryClient.invalidateQueries([types.Attendance, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      console.log(data.response.data.message);
    },
  });
  function handleSubmit(values: timeOut) {
    if (values) {
      values.timeOutSubmittedAt = new Date();
      if (location) {
        values.timeOutLatitude = `${location.lat}`;
        values.timeOutLongitude = `${location.lng}`;
      }
      if (error) {
        notifications.show({
          title: 'Error',
          message: `${error}`,
          color: 'red',
          autoClose: 10000,
        });
      }
      PatchMutate({ id: values.id, data: values });
    }
  }

  return (
    <>
      <form onSubmit={formTimeOut.onSubmit((values) => handleSubmit(values))}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        <TimeInput required label="Time Out: " {...formTimeOut.getInputProps('timeOut')} onClick={getLocation} />

        <Button disabled={id === 0} fullWidth mt="sm" type="submit">
          Submit
        </Button>
      </form>
    </>
  );
}
