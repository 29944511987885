import {
  Alert,
  Button,
  Checkbox,
  Container,
  Grid,
  List,
  LoadingOverlay,
  Paper,
  Select,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
  
  import dayjs from 'dayjs';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { DatePickerInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, removeNotEdited, selectColumns } from '../../../utils';
import { IaxiosError } from '../../common';
import { useUnits } from '../../unit';
import { patchCertificate, useCertificate } from '../api';
import { AsateelCertificateColumnsMode } from '../constants';
import { IcertificateInput } from '../types/types';

type certificateInput = Omit<IcertificateInput,'certificateID' | 'vehicle'>;
  export function UpdateAsateelCertificateForm(
    {id}
    :
    {
      id:number,
    }) {
    const {data} = useCertificate(id, {
      joins: ['device', 'simCard'],
      selects: [...selectColumns(AsateelCertificateColumnsMode), 'device.id', 'simCard.id'],
      then: (certificate) => {
        form.setValues({
          activationDate: new Date(certificate.data.activationDate|| new Date()),
          expiryDate: new Date(certificate?.data.expiryDate || new Date()),
          withDate: certificate ? certificate.data.withDate : false,
          withPlate: certificate ? certificate.data.withPlate : true,
          withDevice: certificate ? certificate.data.withDevice : true,
          withSimCard: certificate ? certificate.data.withSimCard : true,
          withParent: certificate ? certificate.data.withParent : false,
          device: {id : certificate?.data.device?.id || 0},
          simCard: {id : certificate?.data.simCard?.id || 0},  
        })
        form.resetDirty({
          activationDate: new Date(certificate.data.activationDate|| new Date()),
          expiryDate: new Date(certificate?.data.expiryDate || new Date()),
          withDate: certificate ? certificate.data.withDate : false,
          withPlate: certificate ? certificate.data.withPlate : true,
          withDevice: certificate ? certificate.data.withDevice : true,
          withSimCard: certificate ? certificate.data.withSimCard : true,
          withParent: certificate ? certificate.data.withParent : false,
          device: {id : certificate?.data.device?.id || 0},
          simCard: {id : certificate?.data.simCard?.id || 0},  
        })
      },
    })
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();
    const [unitsSelect, setUnitsSelect] = useState([{ value: '', label: 'Loading...' }])
    const [unitId, setUnitID] = useState(0)
    const unitParam = new URLParams();
    unitParam.includes('device', 'simCard')
    unitParam.select('id', 'device.id', 'device.imei', 'simCard.id', 'simCard.number')
    unitParam.filterSetField('device.id', 'null')
    unitParam.filterSetField('simCard.id', 'null')
    const { data: unitData } = useUnits({params: unitParam.toString()})
    useEffect(() => {
    if (unitData) {
      const tempArr = unitData.data.items.map((unit) => {
        return { value: `${unit.id}`, label: `${unit.device.imei}, ${unit.simCard.number}` }
      })
      tempArr.unshift({ value: '0', label: 'No Imei' })
      setUnitsSelect(tempArr)
      const foundUnit = unitData.data.items.find(unit => unit.device?.id === data?.data.device?.id)
      if (foundUnit) {
        setUnitID(+foundUnit.id)
      }
    }
  }, [unitData])

    const form = useForm<certificateInput>({
      initialValues: {
        activationDate: new Date(data?.data.activationDate|| new Date()),
        expiryDate: new Date(data?.data.expiryDate || new Date()),
        withDate: data ? data.data.withDate : false,
        withPlate: data ? data.data.withPlate : true,
        withDevice: data ? data.data.withDevice : true,
        withSimCard: data ? data.data.withSimCard : true,
        withParent: data ? data.data.withParent : false,
        device: {id : data?.data.device?.id || 0},
        simCard: {id : data?.data.simCard?.id || 0},
      },
    });

    const {
      mutate: patchMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(patchCertificate, {
      onSuccess: (data) => {
        setAlert(null);
        queryClient.invalidateQueries([types.AsateelCertificate, types.ALL]);
        queryClient.invalidateQueries([types.History, types.AsateelCertificate, { id: data.data.id }]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });

  
    function handleSubmit(values: certificateInput) {
      setAlert(null);
      const newValues = removeNotEdited(values,form)
      if( newValues.device && newValues.device.id === 0) newValues.device = null
      if( newValues.simCard && newValues.simCard.id === 0) newValues.simCard = null
      patchMutate({ id: id, data: newValues })
    }
  
    return (
        <Container pt={10}>
          <Title
            order={3}
            pl={5}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            update asateel certificate:
          </Title>
          <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
            <LoadingOverlay visible={newLoading} overlayBlur={2} />
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
              >
                Successfully added to the database!
              </Alert>
            )}
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Select 
            clearable
            label="Unit" 
            placeholder="Search Here"
            required 
            onChange={(v) => {
              const foundUnit = unitData?.data.items.find(unit => `${unit.id}` === v)
              if(foundUnit){
                form.setFieldValue("device.id", foundUnit.device.id)
                form.setFieldValue("simCard.id", foundUnit.simCard.id)
                setUnitID(foundUnit.id)
              }
            }} 
            searchable 
            nothingFound="Not found" 
            data={unitsSelect} 
            mt="md"
            value={`${unitId}`} 
            />
            <DatePickerInputEditable
            dirty={form.isDirty('activationDate')}
            placeholder="Search Date"
            label="activation Date"
            {...form.getInputProps('activationDate')}
            onChange={(v) => {
              form.setFieldValue('activationDate', v)
              form.setFieldValue('expiryDate', new Date(dayjs(dayjs(v).add((1), 'year').toDate()).subtract(1, 'day').toDate()))
            }}
            required
            popoverProps={{withinPortal: true}}
            />
            <DatePickerInputEditable
            dirty={form.isDirty('expiryDate')}
            placeholder="Search Date"
            label="expiry Date"
            {...form.getInputProps('expiryDate')}
            mt="md"
            />
            <Grid  mt='sm'>
              <Grid.Col span={3}>
                <Checkbox label='with Date?'  checked={form.values.withDate} {...form.getInputProps('withDate')} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Checkbox label='with Plate?'  checked={form.values.withPlate} {...form.getInputProps('withPlate')} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Checkbox label='with Device?'  checked={form.values.withDevice} {...form.getInputProps('withDevice')} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Checkbox label='with SimCard?'  checked={form.values.withSimCard} {...form.getInputProps('withSimCard')} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Checkbox label='with Parent?'  checked={form.values.withParent} {...form.getInputProps('withParent')} />
              </Grid.Col>
            </Grid>
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    );
  }
  