import api from '../../common/api/api';
import { Task, TaskInput } from '../types/types';

export interface myResTaskPost {
  data: Task;
}

export const postTask = (data: Partial<TaskInput>): Promise<myResTaskPost> => {
  return api.post(`tasks`, data);
};

export const postTasks = (data: Record<string, any>[]): Promise<myResTaskPost[]> => {
  return api.post(`tasks/multi`, data);
};
