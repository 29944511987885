import { ActionIcon, Select, SelectProps, createStyles } from "@mantine/core";
import { useState } from "react";
import { TbEdit } from "react-icons/tb";

const useStyles = createStyles((theme) => ({
	inputEditableDirty: {
		input: {
			borderColor: theme.colors.violet
		}
	},
	buttonBody: {
		minWidth: 36,
		minHeight: 36,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	}
}))


export function SelectEditable({ editable, dirty, ...props }: SelectProps & { editable?: boolean, dirty?: boolean }) {
	const { classes } = useStyles()
	const [locked, setLocked] = useState(editable)

	return (
		<Select
			rightSection={editable && <ActionIcon className={classes.buttonBody} variant="default" onClick={() => setLocked(!locked)} ><TbEdit size={16} /></ActionIcon>}
			{...props}
			className={dirty ? classes.inputEditableDirty : undefined}
			disabled={locked}
		/>
	)
}