import {
  Alert,
  Container,
  createStyles,
  LoadingOverlay,
  Paper,
  Table
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { useVehicleType } from '../api';

const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));

export function VehicleTypeCard({
  id,
  setImage,
}: {
  id: number;
  setImage?: React.Dispatch<React.SetStateAction<number | null>>;
}) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);

  const { isLoading, isError, data, refetch } = useVehicleType(id);

  useEffect(() => {
    if (setImage && data) {
      setImage(data.data.imageRepositoryId);
    }
  }, [data, setImage]);

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);

  return (
    <Paper>
      <Container
        className={classes.detailsContainer}
        m={0}
        p={0}
        pos="relative"
      >
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        {data && data.data && (
          <Table mt={12} p={5}>
            <tbody>
              <tr>
                <td className={classes.leftSideTable}>id:</td>
                <td>{data.data.id}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Brand:</td>
                <td>{data.data.brand}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Model:</td>
                <td>{data.data.model}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Year:</td>
                <td>{data.data.year}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Type:</td>
                <td>{data.data.type}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Category:</td>
                <td>{data.data.category ?? ''}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {isError && (
          <Alert
            icon={<TbAlertOctagon size={16} />}
            title="Error!"
            color="red"
            mb={10}
          >
            Error Loading data from the server!!
          </Alert>
        )}
      </Container>
    </Paper>
  );
}
