export enum BackGroundTheme {
  Light = 'Light',
  Dark = 'Dark',
}

export enum UserLocation {
  Office = 'office',
  Car1 = 'car 1',
  Car2 = 'car 2',
}
