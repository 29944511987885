import {
  Alert,
  Container,
  createStyles,
  Grid,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { groupBy, URLGroupParams } from '../../../../utils';
import { useAnalyticsUnits } from '../../../unit';
import { OrgUnitsBillsSummary } from './OrgUnitsBillsSummary';
  
interface unitsCount {
  count: string,
  platform: string,
  secondaryPlatform: string | null,
  status: string,
} 
enum unitStatus {
	BilledActive = "active in plan",
	activeNotInPlan = "active not in plan",
	NotActiveInPlan = "not active in plan",
}

  const useStyles = createStyles(() => ({
    detailsContainer: {
      width: '100%',
      maxWidth: 'inherit',
      overflow: 'never',
    },
    leftSideTable: {
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
  }));
  
  
  export function OrgUnitsAnalysis({
    id,
    cardHei,
  }: {
    id: number;
    cardHei: number;
  }) {
    const { classes } = useStyles();
    const countUnitsInPlanOptions = new URLGroupParams()
    countUnitsInPlanOptions.joins('device', 'deviceConfig', 'vehicle', 'vehicle.organization', 'simCard', 'purchaseOrderPlan')
    countUnitsInPlanOptions.filterSetField('device','null')
    countUnitsInPlanOptions.filterSetField('vehicle','null')
    countUnitsInPlanOptions.filterSetField('=vehicle.organization.id', id)
    countUnitsInPlanOptions.filterSetField('simCard','null')
    countUnitsInPlanOptions.filterSetField('purchaseOrderPlan','null')
    countUnitsInPlanOptions.group('deviceConfig.platform', 'deviceConfig.secondaryPlatform')
    countUnitsInPlanOptions.select('deviceConfig.platform', 'deviceConfig.secondaryPlatform')
    const { data: unitInPlan, isLoading: unitInPlanLoading, isError: unitInPlanError } = useAnalyticsUnits({params: countUnitsInPlanOptions.toString()})

    const countUnitsNotInPlanOptions = new URLGroupParams()
    countUnitsNotInPlanOptions.joins('device', 'deviceConfig', 'vehicle', 'vehicle.organization', 'simCard')
    countUnitsNotInPlanOptions.filterSetField('device','null')
    countUnitsNotInPlanOptions.filterSetField('vehicle','null')
    countUnitsNotInPlanOptions.filterSetField('=vehicle.organization.id', id)
    countUnitsNotInPlanOptions.filterSetField('simCard','null')
    countUnitsNotInPlanOptions.filterSetField('=purchaseOrderPlan','null')
    countUnitsNotInPlanOptions.group('deviceConfig.platform', 'deviceConfig.secondaryPlatform')
    countUnitsNotInPlanOptions.select('deviceConfig.platform', 'deviceConfig.secondaryPlatform')
    const { data: unitNotInPlan, isLoading: unitNotInPlanLoading, isError: unitNotInPlanError } = useAnalyticsUnits({params: countUnitsNotInPlanOptions.toString()})
    
    const countnotActiveUnitInPlanOptions = new URLGroupParams()
    countnotActiveUnitInPlanOptions.joins('deviceConfig', 'vehicle', 'vehicle.organization', 'purchaseOrderPlan')
    countnotActiveUnitInPlanOptions.filterSetField('=device','null')
    countnotActiveUnitInPlanOptions.filterSetField('=simCard','null')
    countnotActiveUnitInPlanOptions.filterSetField('vehicle','null')
    countnotActiveUnitInPlanOptions.filterSetField('=vehicle.organization.id', id)
    countnotActiveUnitInPlanOptions.filterSetField('purchaseOrderPlan','null')
    countnotActiveUnitInPlanOptions.group('deviceConfig.platform', 'deviceConfig.secondaryPlatform')
    countnotActiveUnitInPlanOptions.select('deviceConfig.platform', 'deviceConfig.secondaryPlatform')
    const { data: notActiveUnitInPlan, isLoading: notActiveUnitInPlanLoading , isError: notActiveUnitInPlanError } = useAnalyticsUnits({params: countnotActiveUnitInPlanOptions.toString()})

    const [total, setTotal] = useState<{activeInPlan: number, notActiveInPlan: number, activeNotInPlan: number}>()
    const [ selectsUnits, setsSelectUnits ] = useState<Record<string, unitsCount[]>>()
    useEffect(() => {
      if( unitInPlan && unitNotInPlan && notActiveUnitInPlan ){
        const temp = [...unitInPlan.data.map((active => {return {...active, status: 'active in plan'}})), ...unitNotInPlan.data.map((notbilled => {return {...notbilled, status: 'active not in plan'}})), ...notActiveUnitInPlan.data.map((notActive => {return {...notActive, status: 'not active in plan'}}))]

        const groupData = groupBy(temp as unknown as unitsCount[], (i) => `${i.platform}${i.secondaryPlatform ? `-${i.secondaryPlatform}`: ''}`)
        setsSelectUnits({...groupData})
        
        let totalActive = 0
        unitInPlan.data.forEach(active => {
          totalActive = totalActive + (+active.count) 
        })
        
        let totalNotActive = 0
        notActiveUnitInPlan.data.forEach(notactive => {
          totalNotActive = totalNotActive + (+notactive.count) 
        })
        
        let totalActiveNotInPlan = 0
        unitNotInPlan.data.forEach(notInPlan => {
          totalActiveNotInPlan = totalActiveNotInPlan + (+notInPlan.count) 
        })

        setTotal({activeInPlan: totalActive, notActiveInPlan: totalNotActive, activeNotInPlan: totalActiveNotInPlan})
      }
    }, [unitInPlan, unitNotInPlan, notActiveUnitInPlan])
    const planSummaryCards = Object.entries(selectsUnits ?? {}).map(([key, value]) => {
      const activeInPlan = value.find(item => item.status === unitStatus.BilledActive)
      const activeNotInPlan = value.find(item => item.status === unitStatus.activeNotInPlan)
      const NotactiveInPlan = value.find(item => item.status === unitStatus.NotActiveInPlan)
      let total = 0
      if(activeInPlan) total = total + (+activeInPlan.count)
      if(activeNotInPlan) total = total + (+activeNotInPlan.count)
      if(NotactiveInPlan) total = total + (+NotactiveInPlan.count)
      return(
            <Grid.Col span={6} key={key}>
              <OrgUnitsBillsSummary title={key} totalPDs={total} billedActivePD={activeInPlan?.count ? +activeInPlan.count :  0} billedNotActivePD={NotactiveInPlan?.count ? +NotactiveInPlan.count :  0} notBilledActivePD={activeNotInPlan?.count ? +activeNotInPlan.count :  0} notes={false}></OrgUnitsBillsSummary>
            </Grid.Col>
          )
    })


  

  
    return (
      <Paper h={cardHei} p={10} withBorder radius="md" pos="relative">
        <Title order={3} py={10} style={{ fontWeight: 700 }}>
            Organization Billing
        </Title>
        <Container className={classes.detailsContainer} m={0} p={0}>
          <LoadingOverlay visible={unitInPlanLoading || unitNotInPlanLoading || notActiveUnitInPlanLoading} overlayBlur={2} />
            <ScrollArea h={cardHei - 70}>
              <Grid m={0}>
                  {total && <Grid.Col span={6}>
                    <OrgUnitsBillsSummary title={"Units"} totalPDs={total.activeInPlan + total.activeNotInPlan + total.notActiveInPlan} billedActivePD={total.activeInPlan} billedNotActivePD={total.notActiveInPlan} notBilledActivePD={total.activeNotInPlan} notes={false}></OrgUnitsBillsSummary>
                  </Grid.Col>}
                  {planSummaryCards}
              </Grid>
            </ScrollArea>
            {(unitInPlanError || unitNotInPlanError || notActiveUnitInPlanError) &&
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              Error Loading data from the server!!
            </Alert>
          }
        </Container>
      </Paper>
    );
  }
  