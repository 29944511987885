import { Avatar, Indicator, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IcollectionLink, useImageCollection } from '../../modules/common';

export function BaseAvatar({ name, img, online }: { name: string; img: string | number | null; online: boolean }) {
  const [imageLink, setImageLink] = useState<IcollectionLink>();
  const data = typeof img === 'number' ? useImageCollection(img) : undefined;
  const collection = data?.data?.data;

  useEffect(() => {
    if (collection) {
      setImageLink(collection.collection.find((image) => image.id === collection.previewId));
    }
  }, [collection]);

  return (
    <Stack align="center">
      <Indicator offset={5} position="bottom-end" color={online ? 'green' : 'red'} processing={online}>
        <Avatar
          src={imageLink?.path ? `${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${imageLink?.path}` : null}
          radius="xl"
          size="lg"
        />
      </Indicator>
      <Text weight={500}>{name}</Text>
    </Stack>
  );
}
