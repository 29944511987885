import {
  Alert, Container,
  createStyles, Grid,
  LoadingOverlay,
  Paper, ScrollArea, Tabs
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { getUnitsWithStatus, selectColumns, URLParams } from '../../../utils';
import { DeviceColumnsMode } from '../../devices';
import { SimCardColumnsMode } from '../../sim-cards';
import { IfullUnit, unitStatus, useUnits } from '../../unit';
import { useVehicles } from '../../vehicles';
import { OrgUnitsSummary } from './OrgUnitsSummary';
import { OrgVehicles } from './OrgVehicles';


const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },
  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));


export function OrgVehiclesCard({
  id,
  cardHei,
}: {
  id: number;
  cardHei: number;
}) {
  const { classes } = useStyles();
  const [units, setUnits] = useState<IfullUnit[]>([]);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const param = new URLParams()
  param.includes(
    'deviceConfig',
    'device',
    'simCard',
    'vehicle',
    'vehicle.organization as org',
    'tasks',
    'purchaseOrderPlan'
  );
  param.filterSetField('=org.id', id);
  param.select(
    'id', 'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform', 
  'vehicle.id','vehicle.vin', 'vehicle.plateNumber', 'vehicle.category', 'vehicle.placeOfIssue',
  ...selectColumns(SimCardColumnsMode, {join: 'simCard', exclude: ['purchaseDate', 'serial']}),
  ...selectColumns(DeviceColumnsMode, {join: 'device', exclude: ['purchaseDate']}), 
  'org.id', 'org.name', 'org.status', 
  'purchaseOrderPlan.id', 'purchaseOrderPlan.status',
  'tasks.id', 'tasks.type', 'tasks.status'
  )

  const { data: unitsData, isLoading, isError } = useUnits({params: param.toString()});

  const vehicleParam = new URLParams()
  vehicleParam.includes(
    'organization', 'unit',
  );
  vehicleParam.filterSetField('=organization.id', id);
  vehicleParam.filterSetField('=unit.id', 'null');
  vehicleParam.select('id', 
  'vehicle.id','vehicle.vin', 'vehicle.plateNumber', 'vehicle.category', 'vehicle.placeOfIssue',
  'organization.id', 'organization.name', 'organization.status', 'unit.id'
  )

  const { data: vehiclesData } = useVehicles({params: vehicleParam.toString()});

  useEffect(() => {
    if(unitsData){
      const unitWithStatus = getUnitsWithStatus(unitsData.data.items)
      const platforms = unitWithStatus.reduce((platforms, item) => {
        const platform = `${item.deviceConfig?.platform ?? ''}` + `${item.deviceConfig?.secondaryPlatform ? `-${item.deviceConfig?.secondaryPlatform}` :  ''}`
        if(platforms.includes(platform)){
          return platforms
        }
        platforms.push(platform)
        return platforms
      }, [] as string[])
      setPlatforms(platforms)
      setUnits(unitWithStatus)
    }
  }, [unitsData])

  const status = platforms.map(platform => {
    const displayUnits = units.filter(unit => platform === `${unit.deviceConfig?.platform ?? ''}${unit.deviceConfig?.secondaryPlatform ? '-' + unit.deviceConfig?.secondaryPlatform : ''}`)
    return (
      <Grid.Col key={platform} span={6}>
          <OrgUnitsSummary title={platform} totalUnits={displayUnits.length} activeUnits={displayUnits?.filter(unit => unit.status === unitStatus.Active).length ?? 0} notActiveUnits={displayUnits?.filter(unit => unit.status === unitStatus.Not_Active).length ?? 0} pendingInstalledUnits={displayUnits?.filter(unit => unit.status === unitStatus.Pending_Installation).length ?? 0} pendingRemovalUnits={displayUnits?.filter(unit => unit.status === unitStatus.Pending_Removal).length ?? 0} toBeApprovedInstalledUnits={displayUnits?.filter(unit => unit.status === unitStatus.To_Be_Approved_Installation).length ?? 0} toBeApprovedRemovalUnits={displayUnits?.filter(unit => unit.status === unitStatus.To_Be_Approved_Removal).length ?? 0} pendingDeviceChangeUnits={displayUnits?.filter(unit => unit.status === unitStatus.Pending_DeviceChange).length ?? 0} toBeApprovedDeviceChangeUnits={displayUnits?.filter(unit => unit.status === unitStatus.To_Be_Approved_DeviceChange).length ?? 0} pendingReinstallationUnits={displayUnits?.filter(unit => unit.status === unitStatus.Pending_Reinstallation).length ?? 0} toBeApprovedReinstallationUnits={displayUnits?.filter(unit => unit.status === unitStatus.To_Be_Approved_DeviceChange).length ?? 0} />
      </Grid.Col>
    )
  })

  return (
    <Paper h={cardHei} p={10} withBorder radius="md" pos="relative">
      <Container className={classes.detailsContainer} m={0} p={0}>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <Tabs defaultValue="summary">
            <Tabs.List>
               <Tabs.Tab value="summary" >Summary</Tabs.Tab>
               <Tabs.Tab value="active" >Active</Tabs.Tab>
               <Tabs.Tab value="not_active" >Not Active</Tabs.Tab>
               <Tabs.Tab value="pending_installation" >Pending Installation</Tabs.Tab>
               <Tabs.Tab value="to_be_approved_installation" >To Be Approved Installation</Tabs.Tab>
               <Tabs.Tab value="pending_removal" >Pending Removal</Tabs.Tab>
               <Tabs.Tab value="to_be_approved_removal" >To Be Approved Removal</Tabs.Tab>
               <Tabs.Tab value="pending_DeviceChange" >Pending Device Change</Tabs.Tab>
               <Tabs.Tab value="to_be_approved_DeviceChange" >To Be Approved Device Change</Tabs.Tab>
               <Tabs.Tab value="pending_Reinstallation" >Pending Reinstllation</Tabs.Tab>
               <Tabs.Tab value="to_be_approved_Reinstallation" >To Be Approved Reinstllation</Tabs.Tab>
               <Tabs.Tab value="not_in_unit" >Not In Unit</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="summary" pt="xs">
              <ScrollArea h={cardHei - 70}>
                <Grid m={0}>
                  <Grid.Col span={6}>

                  <OrgUnitsSummary title={'Units'} totalUnits={units.length + (vehiclesData?.data.items.length ?? 0)} activeUnits={units?.filter(unit => unit.status === unitStatus.Active).length ?? 0} notActiveUnits={units?.filter(unit => unit.status === unitStatus.Not_Active).length ?? 0} pendingInstalledUnits={units?.filter(unit => unit.status === unitStatus.Pending_Installation).length ?? 0} pendingRemovalUnits={units?.filter(unit => unit.status === unitStatus.Pending_Removal).length ?? 0} toBeApprovedInstalledUnits={units?.filter(unit => unit.status === unitStatus.To_Be_Approved_Installation).length ?? 0} toBeApprovedRemovalUnits={units?.filter(unit => unit.status === unitStatus.To_Be_Approved_Removal).length ?? 0} notInUnit={vehiclesData?.data.items.length} pendingDeviceChangeUnits={units?.filter(unit => unit.status === unitStatus.Pending_DeviceChange).length ?? 0} toBeApprovedDeviceChangeUnits={units?.filter(unit => unit.status === unitStatus.To_Be_Approved_DeviceChange).length ?? 0} pendingReinstallationUnits={units?.filter(unit => unit.status === unitStatus.Pending_Reinstallation).length ?? 0} toBeApprovedReinstallationUnits={units?.filter(unit => unit.status === unitStatus.To_Be_Approved_DeviceChange).length ?? 0} />
                  </Grid.Col>
                  {status}
                </Grid>
              </ScrollArea>
            </Tabs.Panel>

            <Tabs.Panel value="active" pt="xs">
               <OrgVehicles units={units.filter(unit => unit.status === unitStatus.Active)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="not_active" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.Not_Active)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="pending_installation" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.Pending_Installation)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="to_be_approved_installation" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.To_Be_Approved_Installation)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="pending_removal" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.Pending_Removal)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="to_be_approved_removal" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.To_Be_Approved_Removal)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="pending_DeviceChange" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.Pending_DeviceChange)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="to_be_approved_DeviceChange" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.To_Be_Approved_DeviceChange)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="pending_Reinstallation" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.Pending_Reinstallation)} cardHei={cardHei - 45} />
            </Tabs.Panel>

            <Tabs.Panel value="to_be_approved_Reinstallation" pt="xs">
              <OrgVehicles units={units.filter(unit => unit.status === unitStatus.To_Be_Approved_Reinstallation)} cardHei={cardHei - 45} />
            </Tabs.Panel>
            <Tabs.Panel value="not_in_unit" pt="xs">
              <OrgVehicles vehicles={vehiclesData?.data.items} cardHei={cardHei - 45} />
            </Tabs.Panel>
         </Tabs>
        {isError &&
          <Alert
            icon={<TbAlertOctagon size={16} />}
            title="Error!"
            color="red"
            mb={10}
          >
            Error Loading data from the server!!
          </Alert>
        }
    </Container>
  </Paper>
  );
}
