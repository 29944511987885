import { Badge, Checkbox, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { ExportOrganizations } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, selectColumns, setTableParams } from '../../../utils';
import { useOrganizations } from '../api';
import { OrganizationCard } from '../components';
import { OrganizationColumnsMode, OrganizationStatus, OrganizationStatusColor } from '../constants/constants';
import { Iorganization } from '../types/types';

export function OrganizationTable() {
  const [page, setPage] = useState(1)
  const [rowSelection, setRowSelection] = useState({})
  const [data, setData] = useState<Iorganization[]>([]);
  const [ids, setIds] = useState<number[]>([])
  // const [opened, { open, close }] = useDisclosure(false);
  
  //Todo: fix the edit Multiple not csv one then add this to sidebar
  // const customActions = [
    //   { multi: true, title: 'Edit Multiple', icon: FaEdit, color: 'orange', handler: open, noDisplay: ['record'] },
    // ];
    
    const [params, setParams] = useState<URLParams>(() => {
      const param = new URLParams()
        param.includes('parent', 'vehicles')
        param.sort(('-organization.id'))
        param.select(...selectColumns(OrganizationColumnsMode), 'parent.id', 'parent.name')
        param.page(10, page-1)
      return param
    })
  const { data: allOrganizations, refetch, isLoading } = useOrganizations({params: params.toString()});
  useEffect(() => {
    if (allOrganizations) {
      setData(allOrganizations.data.items);
      const selectedRows = allOrganizations.data.items.map((item) => {
        if(ids.includes(item.id)) return {index: true}
      })
      setRowSelection(selectedRows)
    }
  }, [allOrganizations]);

  const columnHelper = useMemo(() => createColumnHelper<Iorganization>(), []);
  const columns: ColumnDef<Iorganization, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        
        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allOrganizations?.data.meta.ids.length
                if(allSelected){
                  setIds([])
                }else setIds(allOrganizations?.data.meta.ids ? allOrganizations?.data.meta.ids : ids)
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex(id => id === row.original.id)
                  if(rowId === -1){
                    setIds([...ids, row.original.id])
                  }else {
                    setIds(ids => {
                      ids.splice(rowId,1)
                      return ids
                    })
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.id, { header: 'ID', id:'id' }),
      columnHelper.accessor('name', { header: () => 'Name', id: 'name' }),
      columnHelper.accessor('type', { header: () => 'Type', id: 'type' }),
      columnHelper.accessor('status', { header: 'Status', cell: (props) => 
      <Badge 
      variant="light" 
      c={OrganizationStatusColor[props.getValue().split(' ').join('_') as keyof typeof OrganizationStatusColor]} 
      styles={{root:{backgroundColor: `${OrganizationStatusColor[props.getValue().split(' ').join('_') as keyof typeof OrganizationStatusColor]}40`,}}}
      >{props.getValue()}</Badge>}),
      columnHelper.accessor('tradeLicense', { header: () => 'Trade License', id:'tradeLicense' }),
      columnHelper.accessor('trn', { header: () => 'TRN', id:'trn' }),
      columnHelper.accessor('trafficFile', { header: () => 'Traffic File', id:'trafficFile' }),
      columnHelper.accessor('abbreviation', { header: () => 'abbreviation', id: 'abbreviation' }),
      columnHelper.accessor((row) => (row.parent ? row.parent.name : ''), { header: 'Parent', id: 'parent.name' }),
      // TODO: maybe show coloumns with links to that organizations' vehicles, subClients and purchase orders
    ],
    [columnHelper, data, ids],
  );

  // const columnTypes = {
  //   "Active Units": "NA",
  // }

  const toSelect = {
    Status: OrganizationStatus,
  }


  return (
    <Layout>
      <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
        Organizations:
      </Title>
      <BaseTable<Iorganization> data={data} columns={columns} setIds={setIds} defaultVisibleColumns={{ tradeLicense: false, trn: false, trafficFile: false }} 
      loading={isLoading}
      toSelect={toSelect}
      page={page}
      ids={ids}
      setPage={(value) => {
        setParams((param) => {
          param.filterRemovePages()
          param.page(10, value - 1)
          return param
        })
        setPage(value)
      }}
      globalFilterData={(data, headers) => {
        const newHeaders = headers.filter(header => header && header !== 'select' && header !== 'actions' && header !== 'expander').join('|')
        setTableParams(newHeaders, data, '', setParams)
        refetch()
        setPage(1)
      }}
      csvExport={<ExportOrganizations ids={ids} />}
      pages={allOrganizations?.data.meta.ids.length ? Math.ceil(allOrganizations?.data.meta.ids.length/10): 1}
      filterData={(column, value, type)=> {
        setTableParams(column, value, type, setParams)
        refetch()
        setPage(1)
      }}
      setRowSelection={setRowSelection} rowSelection={rowSelection} />
      <SideBar id={ids} type={types.Organization} >
        <OrganizationCard id={ids[0]} />
      </SideBar>
      {/* <Modal opened={opened} onClose={close} size="90%" title={<Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}` ,fontWeight: 800, fontSize:'1.625rem' })}>Edit Multiple Sim Cards</Text>} >
         <EditMultipleOrganizations ids={ids} />
         </Modal> */}
    </Layout>
  );
}
