import {
  Alert,
  Button,
  Container,
  List,
  LoadingOverlay,
  Paper,
  Select,
  Table
} from '@mantine/core';
import { useEffect, useState } from 'react';
  
  import { useForm } from '@mantine/form';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { SelectEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, enumToSelectData } from '../../../utils';
import { IaxiosError } from '../../common';
import { useDevices } from '../../devices';
import { useSimCards } from '../../sim-cards';
import { useVehicles } from '../../vehicles';
import { patchUnits, useUnits } from '../api';
import { DevicePlatforms } from '../constants/constants';
import { editMultipleUnits } from '../types/types';
  
  export function EditMultipleUnits({ids}:{ids:number[]}) {
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();

    const params = new URLParams()
    params.filterSetLevel(0, '|')
    params.includes(
        'deviceConfig',
        'device',
        'simCard',
        'vehicle',
        // 'deviceConfig.rfidList'
      );
      ids.forEach((id) => params.filterSetField('=id',id, 0))
      params.select('id', 'vehicle.id', 'device.id', 'simCard.id', 'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform')
    const {data:allUnits } = useUnits({ params: params.toString() });
    useEffect(() => {
        if (allUnits) {
            const temp = allUnits.data.items.filter(item => {
                if(ids.find(id => id === item.id))
                    return item
            }).map(item => {
                const obj =  {
                    id: item.id,
                    vehicle: item.vehicle ? item.vehicle.id : null,
                    device: item.device? item.device.id : null,
                    simCard: item.simCard? item.simCard.id : null,
                    deviceConfig: {
                      id: item.deviceConfig.id,
                      platform: item.deviceConfig.platform,
                      secondaryPlatform: item.deviceConfig.secondaryPlatform
                    }
                }
                return obj
            })
            form.setValues(temp)
        }
    }, [allUnits]);

    const [vehicleSelect, setVehicleSelect] = useState([
        { value: '0', label: '' },
      ]);


    const vehicleParams = new URLParams()
    vehicleParams.select('id', 'vehicle.vin', 'vehicle.plateNumber')
    const { data: allVehiclesData } = useVehicles();
    useEffect(() => {
        if (allVehiclesData) {
        const tempArr = allVehiclesData.data.items.map((vehicle) => {
            return {
            value: `${vehicle.id}`,
            label: `${vehicle.vin}, ${vehicle.plateNumber}`,
            };
        });
        setVehicleSelect(tempArr);
        }
    }, [allVehiclesData]);

    const [deviceSelect, setDeviceSelect] = useState([
        { value: '0', label: '' },
      ]);

    const deviceParams = new URLParams()
    deviceParams.includes('unit')
    deviceParams.filterSetLevel(0, '|')
    deviceParams.filterSetField('=unit.id', 'null', 0)
    ids.forEach(id => {
      deviceParams.filterSetField('=unit.id',id, 0)
    });
    deviceParams.select('id', 'imei')
    const { data: allDeviceData } = useDevices({params: deviceParams.toString()});
    useEffect(() => {
        if (allDeviceData) {
        const tempArr = allDeviceData.data.items.map((device) => {
            return {
            value: `${device.id}`,
            label: `${device.imei}`,
            };
        });
        setDeviceSelect(tempArr);
        }
    }, [allDeviceData]);

    
    const [simSelect, setSimSelect] = useState([
        { value: '0', label: '' },
      ]);

    
    const simCardParams = new URLParams()
    simCardParams.includes('unit')
    simCardParams.filterSetLevel(0, '|')
    simCardParams.filterSetField('=unit.id', 'null', 0)
    ids.forEach(id => {
      simCardParams.filterSetField('=unit.id',id, 0)
    });
    simCardParams.select('id', 'number')
    const { data: allSimsData } = useSimCards({params: simCardParams.toString()});
    useEffect(() => {
        if (allSimsData) {
        const tempArr = allSimsData.data.items.map((sims) => {
            return {
            value: `${sims.id}`,
            label: `${sims.number}`,
            };
        });
        setSimSelect(tempArr);
        }
    }, [allSimsData]);

    const form = useForm<editMultipleUnits[]>({
        initialValues: 
          [{id:0,vehicle:0,device:0,simCard:0}]
        ,
        
      });


  
  
    const {
      mutate: newMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(patchUnits, {
      onSuccess: () => {
        setAlert(null);
        queryClient.invalidateQueries([types.Unit, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });
  
    function handleSubmit(values: editMultipleUnits[]) {
        newMutate(Object.values(values))
    }

    const rows = Object.values(form.values).map((row, i) => {
        return(
            <tr key={'r' + i}>
                <td>{row.id}</td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.vehicle`)}
                onChange={(v) => {
                    if(v)
                    form.setFieldValue(`${i}.vehicle`, parseInt(v));
                    }}
                    data={vehicleSelect}
                    value={`${form.values[i].vehicle? form.values[i].vehicle as number : ''}`}
                />
                </td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.device`)}
                onChange={(v) => {
                    if(v)
                    form.setFieldValue(`${i}.device`, parseInt(v));
                    }}
                    data={deviceSelect}
                    value={`${form.values[i].device? form.values[i].device as number : ''}`}
                />
                </td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.simCard`)}
                onChange={(v) => {
                    if(v)
                    form.setFieldValue(`${i}.simCard`, parseInt(v));
                    }}
                    data={simSelect}
                    value={`${form.values[i].simCard? form.values[i].simCard as number : ''}`}
                />
                </td>
                <td>
                <SelectEditable
                  withinPortal
                  placeholder="Search here"
                  {...form.getInputProps(`${i}.deviceConfig.platform`)}
                  data={enumToSelectData(DevicePlatforms)}
                />
                </td>
                <td>
                <SelectEditable
                  withinPortal
                  placeholder="Search here"
                  {...form.getInputProps(`${i}.deviceConfig.secondaryPlatform`)}
                  data={enumToSelectData(DevicePlatforms)}
                />
                </td>
            </tr>
          )
    });
  
  
    return (
        <Container pt={10} fluid>
          <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
                mt="md"
              >
                Successfully added to the database!
              </Alert>
            )}
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                mt="md"
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item key={errMsg}>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>

            
            {form.values && (
            <Table striped highlightOnHover mt="md">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Vin</th>
                  <th>Imei</th>
                  <th>Sim Number</th>
                  <th>Platform</th>
                  <th>Secondary Platform</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Button
              fullWidth
              type='submit'
              mt="md"
            >
              Submit
            </Button>
          </form>

            
          </Paper>
        </Container>
    );
  }
  