
import Layout from '../../../components/layout/Layout';
import { UpdateDeviceTypeForm } from '../components';

export function UpdateDeviceTypeFormPage() {
  return (
    <Layout>
      <UpdateDeviceTypeForm idM={0}/>
    </Layout>
  );
}
