import { Alert, Button, Container, List, LoadingOverlay, NumberInput, Paper, Select, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { types } from '../../../constants';
import { enumToSelectData, noEmptyString } from '../../../utils';
import { IaxiosError, UploadButton, useUploadImage } from '../../common';
import { postVehicleType } from '../api';
import { VehicleCategory, VehicleType } from '../constants/constants';
import { IvehicleTypeInput } from '../types/types';

export function NewVehicleTypeForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { upload, progress } = useUploadImage();

  const queryClient = useQueryClient();
  const form = useForm<IvehicleTypeInput>({
    initialValues: {
      model: '',
      brand: '',
      year: '',
      type: '',
      category: '',
    },
  });

  const {
    mutate: newMutateVehicleType,
    isLoading: newLoadingVehicleType,
    isSuccess: newSuccessVehicleType,
  } = useMutation(postVehicleType, {
    onSuccess: (data) => {
      setAlert(null);
      const imageRepositoryId = data.data.imageRepositoryId;
      if (imageRepositoryId && file) {
        upload(imageRepositoryId, file, `${data.data.brand}_${data.data.model}`, types.VehicleType, types.VehicleType, data.data.id);
      }
      form.reset();
      queryClient.invalidateQueries([types.VehicleType, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IvehicleTypeInput) {
    setAlert(null);
    const newValues = noEmptyString(values);
    newMutateVehicleType(newValues);
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Vehicle Type:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoadingVehicleType} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : null}
              {alert}!
            </Alert>
          )}
          {newSuccessVehicleType && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput label="Brand" placeholder="Car Brand" {...form.getInputProps('brand')} required />
            <TextInput label="Model" placeholder="Car Model" {...form.getInputProps('model')} required mt="md" />
            <NumberInput label="Year" placeholder="Year" {...form.getInputProps('year')} mt="md" hideControls />
            <Select
              label="Vehicle Type"
              placeholder="Vehicle Type"
              {...form.getInputProps('type')}
              mt="md"
              data={enumToSelectData(VehicleType)}
              required
            />
            <Select
              label="Vehicle Category"
              placeholder="Vehicle Category"
              {...form.getInputProps('category')}
              mt="md"
              data={enumToSelectData(VehicleCategory)}
              required
            />
            <UploadButton file={file} onChange={setFile} progress={progress}>
              {(props) => (
                <Button {...props} mt="md">
                  Select vehicle image
                </Button>
              )}
            </UploadButton>
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
}
