import api from "../../common/api/api";
import { IrfidList, IrfidListInput } from "../types/types";

interface myResRfidListPatch {
	data: IrfidList;
}

export const patchRfidList = (props: { id: number; data: IrfidListInput }): Promise<myResRfidListPatch> => {
	return api.patch(`rfid-list/${props.id}`, props.data);
};
