import api from "../../common/api/api";
import { IAccessoriesType, IAccessoriesTypeInput } from "../types";

interface myResAccessoryType {
	data: IAccessoriesType;
}

export const postAccessoryType = (data: IAccessoriesTypeInput): Promise<myResAccessoryType> => {
	return api.post(`accessories-type`, data);
};

export const postAccessoriesType = (data: Record<string, any>[]): Promise<myResAccessoryType[]> => {
	return api.post(`accessories-type/multi`, data);
};
