import { Group, LoadingOverlay, Paper, Text, UnstyledButton, createStyles, rem } from '@mantine/core';
import dayjs from 'dayjs';
import { useState } from 'react';
import { TbChecklist, TbChevronDown, TbChevronUp, TbNotes } from 'react-icons/tb';
import { WeekDays } from '../../constants/weekDays';
import { useCountTasks } from '../../modules/tasks';
import { URLParams } from '../../utils';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
      theme.colors[theme.primaryColor][7]
    } 100%)`,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    display: 'flex',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing.lg,
    color: theme.colors[theme.primaryColor][6],
  },

  stat: {
    minWidth: rem(98),
    paddingTop: theme.spacing.xl,
    minHeight: rem(140),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.white,
  },

  label: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.colors.gray[6],
    lineHeight: 1.2,
  },

  value: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 700,
    color: theme.black,
  },

  count: {
    color: theme.colors.gray[6],
  },

  day: {
    fontSize: rem(44),
    fontWeight: 700,
    color: theme.white,
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 5,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  month: {
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    lineHeight: 1,
    textAlign: 'center',
  },

  controls: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 0,
      marginBottom: theme.spacing.xl,
    },
  },

  date: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  control: {
    height: rem(28),
    width: '100%',
    color: theme.colors[theme.primaryColor][2],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    transition: 'background-color 50ms ease',

    [theme.fn.smallerThan('xs')]: {
      height: rem(34),
      width: rem(34),
    },

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][5],
      color: theme.white,
    },
  },

  controlIcon: {
    [theme.fn.smallerThan('xs')]: {
      transform: 'rotate(-90deg)',
    },
  },
}));




export function DailyTasksWidget() {
  const { classes } = useStyles();
  const [date, setDate] = useState(new Date());

  
  const createdParams = new URLParams()
  createdParams.filterSetField('createdAt', `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`)
  const { data: createdTasks, isLoading: createdTasksLoading } = useCountTasks({ params: createdParams.toString() });

  const completedParams = new URLParams()
  completedParams.filterSetField('status', 'done');
  completedParams.filterSetField('completionDate', `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`)
  const { data: completedTasks, isLoading: completedTasksLoading } = useCountTasks({ params: completedParams.toString() });


  return ( 
        <div className={classes.root} >
          <LoadingOverlay visible={createdTasksLoading || completedTasksLoading} />
        <div className={classes.controls}>
            <UnstyledButton
            className={classes.control}
            onClick={() => setDate((current) => dayjs(current).add(1, 'day').toDate())}
            >
            <TbChevronUp size="1rem" className={classes.controlIcon} />
            </UnstyledButton>

            <div className={classes.date}>
            <Text className={classes.month}>{WeekDays[date.getDay()]}</Text>
            <Text className={classes.day}>{date.getDate().toString().padStart(2, '0')}</Text>
            <Text className={classes.month}>{dayjs(date).format('MMMM')}</Text>
            </div>

            <UnstyledButton
            className={classes.control}
            onClick={() => setDate((current) => dayjs(current).subtract(1, 'day').toDate())}
            >
            <TbChevronDown size="1rem" className={classes.controlIcon} />
            </UnstyledButton>
        </div>
        <Group sx={{ flex: 1 }}>
            <Paper className={classes.stat} radius="md" shadow="md" p="xs">
                <TbNotes size={32} className={classes.icon} />
                <div>
                    <Text className={classes.label}>Created</Text>
                    <Text fz="xs" className={classes.count}>
                    <span className={classes.value}>{createdTasks?.data}</span> Tasks
                    </Text>
                </div>
            </Paper>
            <Paper className={classes.stat} radius="md" shadow="md" p="xs">
                <TbChecklist size={32} className={classes.icon} />
                <div>
                    <Text className={classes.label}>Completed</Text>
                    <Text fz="xs" className={classes.count}>
                    <span className={classes.value}>{completedTasks?.data}</span> Tasks
                    </Text>
                </div>
            </Paper>
        </Group>
        </div>
  );
}