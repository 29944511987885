
import Layout from '../../../components/layout/Layout';
import { UpdateAccessoryTypeForm } from '../components/UpdateAccessoryTypeForm';

export function UpdateAccessoryTypeFormPage() {

  return (
    <Layout>
      <UpdateAccessoryTypeForm idM={0}/>
    </Layout>
  );
}
