import Layout from "../components/layout/Layout";
import { useAuthContext } from "../context/AuthContext";
import { UserRole } from "../modules/users";
import { ClientDashboard } from "../views/client/ClientDashboard";
import { TechnicianDashboard } from "../views/technician/TechnicianDashboard";
import { AnalyticsDashboard } from "./AnalyticsDashboard";

export function Dashboard() {
  const { user } = useAuthContext()
  
  
  if(!user) return null
  if(user.role === UserRole.Client) return (<Layout> <ClientDashboard /> </Layout>)
  if(user?.role === UserRole.Technician){
    return (<Layout> <TechnicianDashboard /> </Layout>)
  }
  return(<AnalyticsDashboard user={user} />)
  //FIXME: some times the chart take more width than it should, maybe its the Layout 
  
}