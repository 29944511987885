  
  import Layout from '../../../components/layout/Layout';
import { UpdateSimCardTypeForm } from '../components/UpdateSimCardTypeForm';
    
    export function UpdateSimCardTypeFormPage() {
      return (
        <Layout>
          <UpdateSimCardTypeForm idM={0}/>
        </Layout>
      );
    }
    