import { useEffect, useState } from "react"
import { useSimCards } from "../../modules/sim-cards"
import { DownloadAsCsv, Format, URLParams, dateToNewDate, getAllKeysWithPath, getKeysWithPath } from "../../utils"
import { ExportToCsvButton } from "../table/components"

export function ExportSimCards({
    ids,
}:{
    ids: number[]
}){
    const sim = {
        id: 0,
        number: "",
        serial: "",
        status: "",
        imsi: "",
        purchaseDate: new Date(),
        simCardType: {
            id: 0,
            supplier: "",
            type: "",
        },
        unit: {
            id: 0,
            device: {
                id: 0,
                imei: 0,
                status: "",
            },
            vehicle: {
                id: 0,
                category: "",
                plateNumber: 0,
                placeOfIssue: "",
                vin: "",
                vehicleType: {
                    id: 0,
                    model: "",
                    brand: "",
                    year: 0,
                    type: "",
                } ,
                organization: {
                    id: 0,
                    name: "",
                },
            },
            deviceConfig: {
                id: 0,
                platform: "",
                secondaryPlatform: "",
            },
        }
    }

    const [ fetch, setFetch ] = useState(false)
    const [ fileName, setFileName ] = useState('')
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
        'simCardType',
        'unit',
        'unit.vehicle as unitVehicle',
        'unitVehicle.vehicleType as unitVehicleType',
        'unitVehicle.organization as unitOrganization',
        'unit.deviceConfig as unitConfig',
        'unit.device as unitDevice',
        );
        
        param.sort(('-sim_card.id'))
        return param
      })
    // params.se
    const { data: allSimCards, refetch } = useSimCards({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (allSimCards) {
          DownloadAsCsv(dateToNewDate(allSimCards.data.items, ['purchaseDate']), fileName, getKeysWithPath(getAllKeysWithPath(allSimCards.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [allSimCards]);

    return(
        <ExportToCsvButton keys={getKeysWithPath(sim,[], ':')} onSubmit={(v => {
            setFileName(v.fileName)
            const paths = Object.values(v.columns).reduce((cols, item) => {
                    const path = handleReplacePath(item.path, [{originalPath: 'unit.deviceConfig', replaceTo: 'unitConfig'}, {originalPath: 'unit.device', replaceTo: 'unitDevice'},{originalPath: 'unit.vehicle.vehicleType', replaceTo: 'unitVehicleType'},{originalPath: 'unit.vehicle.organization', replaceTo: 'unitOrganization'}, {originalPath: 'unit.vehicle', replaceTo: 'unitVehicle'}, {originalPath: 'vehicle.vehicleType', replaceTo: 'vehicleType'}])
                    
                    if (item?.checked) cols.push(path)
                    return cols
                  }, [] as string[])
            setParams(param => {
                param.select(...paths)
                param.resetSearchMany()
                param.searchMany('id', ids.map(id => `${id}`))
                // param.filterRemoveSelects()
                // param.filterRemoveAllField('=id')
                // ids.forEach(id => {
                //     param.filterSetLevel(1 , '|')
                //     param.filterSetField('=id', id, 1)
                // })
                return param
            })
            
            if(!fetch){
                setFetch(true)
            } else refetch()
        })}/>
    )
}
function handleReplacePath(path: string, replaces:{originalPath: string, replaceTo: string}[]){
    const found = replaces.find(item => path.includes(item.originalPath))
    const paths = path.split('.')
    const column = paths[paths.length-1]
    if(found) return found.replaceTo+'.'+column
    else return path
}