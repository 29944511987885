import { Alert, Button, Container, Grid, List, LoadingOverlay, Paper, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { SelectEditable, TextInputEditable } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { useAuthContext } from '../../../context/AuthContext';
import { URLParams, enumToSelectData, noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { UserRole, useUsers } from '../../users';
import { postTicket } from '../api';
import { TicketStatus, TicketType } from '../constants/constants';
import { IticketInput } from '../types/types';

export function NewSupportTicket() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();
  const { user } = useAuthContext();

  const [userSelect, setUserSelect] = useState([{ value: '', label: '' }]);

  const form = useForm<IticketInput>({
    initialValues: {
      title: '',
      body: '',
      type: '',
      client: { id: 0 },
      status: TicketStatus.New,
      // unit: null,
    },
  });

  const userParams = new URLParams();
  userParams.includes('profiles');
  userParams.filterSetField('profiles.role', UserRole.Client);
  const { data: userData } = useUsers({ params: userParams.toString() });
  useEffect(() => {
    if (userData) {
      const tempArr = userData.data.items.map((parent) => {
        return {
          value: `${parent.id}`,
          label: `${parent.firstName} ${parent.lastName}`,
        };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '',
          label: 'No users, Please add the user first',
        });
      }
      setUserSelect(tempArr);
    }
  }, [userData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postTicket, {
    onSuccess: () => {
      setAlert(null);
      form.reset();
      queryClient.invalidateQueries([types.Ticket, types.ALL]);
      //   queryClient.invalidateQueries([types.History, types.ALL], {
      //     exact: true,
      //   });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IticketInput) {
    if (user) {
      const newValues = noEmptyString({ ...values });
      newMutate({ ...newValues, assignedToUser: { id: user.id }, assignedOn: new Date() });
    } else setAlert('something went wrong please refresh');
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Ticket:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Grid>
              <Grid.Col span={8}>
                <SelectEditable
                  label="Client"
                  placeholder="client"
                  {...form.getInputProps('client.id')}
                  data={userSelect}
                  searchable
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectEditable
                  label="Status"
                  placeholder="Search here"
                  {...form.getInputProps('status')}
                  data={enumToSelectData(TicketStatus)}
                  required
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInputEditable label="Title" placeholder="Title" {...form.getInputProps('title')} required />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectEditable
                  label="Type"
                  placeholder="Search here"
                  {...form.getInputProps('type')}
                  data={enumToSelectData(TicketType)}
                  required
                />
              </Grid.Col>
            </Grid>
            <Textarea label="Description" placeholder="Description" minRows={8} {...form.getInputProps('body')} mt="md" required />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </Layout>
  );
}
