import { Button, Container, Grid, Group, Indicator, LoadingOverlay, Paper, Stack, Text, Title } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { TbCircleFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import { useAuthContext } from '../../../context/AuthContext';
import { URLParams, selectColumns } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { useAttendances } from '../api/getAttendances';
import { AttendanceColumnsMode, AttendanceStatus } from '../constants/constants';
import { NewAttendanceCard } from '../forms/NewAttendanceCard';
import { IattendanceInput } from '../types/types';

export function MyAttendance() {
  const navigate = useNavigate()
  const largeScreen = useMediaQuery('(min-width: 768px)');
  const today = new Date();
  const [month, setMonth] = useState(`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}`)
  const [day, setDay] = useState(new Date())
  const [data, setData] = useState<IattendanceInput[]>([]);
  const { user } = useAuthContext();
  const dataParams = new URLParams();
  if (user) {
    dataParams.filterSetField('=userId', user.id);
    dataParams.includes('user');
    dataParams.select(...selectColumns(AttendanceColumnsMode), 'user.id');
  }
  dataParams.filterSetField('day', month);
  dataParams.sort('-day');
  const { data: allAttendance, isLoading } = useAttendances({
    params: dataParams.toString(),
  });


  const userParams = new URLParams();
  if (user) {
    userParams.filterSetField('=id', user.id);
  }
  useEffect(() => {
    if (allAttendance && user) {
      const temp = allAttendance.data.items.map(item => {
        const obj = JSON.parse(JSON.stringify({...item, user: {id: item.user.id}}));
        return obj
      });
      if(user) {
        temp.push({
          id: 0,
          user: { id: user.id },
          day: today,
          timeIn: null,
          timeInChanged: false,
          timeInSubmittedAt: today,
          timeOut: null,
          timeOutChanged: false,
          timeOutSubmittedAt: today,
          status: AttendanceStatus.Present,
          remarks: null
        });
      }
      setData(temp)
    }
  }, [allAttendance, user]);
  let attendanceCard;
  if (data.length > 0) {
    attendanceCard = data.filter(item => !item.timeOut && item.status === AttendanceStatus.Present).map((item) => {
      return <NewAttendanceCard key={`at-${item.id}`} item={item} user={user} day={day} setDay={setDay} />;
    });
  }


  return (
    <Layout>
       <Group px={10} mt={10} mb={20} position="apart">
        <Title
          order={2}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Attendance:
        </Title>
        <Button onClick={() =>  navigate('/edit/attendance')}>Update Attendance</Button>
      </Group>
      <Container>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
        <Paper withBorder p='sm'>
        <Grid  align='center'>
          <Grid.Col offset={largeScreen ? 2 : 0}  span='auto' >
            <Calendar
              withCellSpacing
              onDateChange={(v) => setMonth(`${v.getFullYear()}-${(v.getMonth()+1).toString().padStart(2, '0')}`)}
              static
              renderDay={(date) => {
                const showIndecator = data.filter(item => simpleDateFormat(item.day) === simpleDateFormat(date) )
                
                if(showIndecator[0])
                  return (
                    <Indicator zIndex={8} size={6} color={showIndecator[0].status === AttendanceStatus.Present && showIndecator[0].timeIn && showIndecator[0].timeOut ? 'green' : showIndecator[0].status !== AttendanceStatus.Present ? 'red' : new Date().toDateString() === date.toDateString() ? 'blue' : 'rgba(245, 132, 27, 1)'} offset={-2} >
                      <div>{date.getDate()}</div>
                    </Indicator>
                  );
                  else  return <div onClick={() => setDay(date)}>{date.getDate()}</div>
              }}
            />
          </Grid.Col>
          <Grid.Col span='auto' >
            <Stack >
              <Group><TbCircleFilled color='blue' /><Text>today</Text></Group>
              <Group><TbCircleFilled color='rgba(245, 132, 27, 1)' /><Text>missing time out</Text></Group>
              <Group><TbCircleFilled color='red' /><Text>Leave / Rest Day / Holiday</Text></Group>
              <Group><TbCircleFilled color='green' /><Text>complete attendance</Text></Group>
            </Stack>
          </Grid.Col>
        </Grid>
        </Paper>
      </Container>
      {attendanceCard}
    </Layout>
  );
}
