import { ActionIcon, Tooltip } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { TbPlus } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { types } from "../../../../constants";
import { IaxiosError } from "../../../common";
import { patchRestoreUnit } from "../../api/patchPurchasePlan";

export function RestoreUnit(
    {
        planID,
        unitID,
        status,
    }:{
        planID:number,
        unitID:number,
        status:boolean,
    }){
        const queryClient = useQueryClient();
        
    const {
        mutate: pathMutate,
     } = useMutation(patchRestoreUnit, {
        onSuccess: () => {
            notifications.show({
                title: 'Successfull',
                message: `unit was Added successfully`,
                color: 'green',
                autoClose: 10000,
                })
            queryClient.invalidateQueries([types.PurchaseOrder, types.ALL]);
            queryClient.invalidateQueries([types.PurchasePlan, types.ALL]);
           queryClient.invalidateQueries([types.History, types.ALL], {
              exact: true,
           });
        },
        onError: (data: IaxiosError) => {
            notifications.show({
                title: 'Error',
                message: `${data.response.data.message}`,
                color: 'red',
                autoClose: 10000,
              })
        },
     });

     function handleSubmit(){
        pathMutate({id: planID, data: {units: [{id: unitID}]}})
     }

     return (
        <Tooltip  label={'Restore unit'}><ActionIcon disabled={status} mt={5} size={15} onClick={handleSubmit}><TbPlus /></ActionIcon></Tooltip>
     )
}