import { Badge, Checkbox, Modal, Text, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { FaEdit, FaPlug } from 'react-icons/fa';
import { ExportUnits } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { deviceStatusColor, simCardStatusColor, types } from '../../../constants';
import { URLParams, getUnitStatusParam, getUnitsWithStatus, selectColumns, setTableParams } from '../../../utils';
import { DeviceColumnsMode, DeviceOwnership, DeviceStatus } from '../../devices';
import { SimCardColumnsMode, SimStatus } from '../../sim-cards';
import { VehicleCategory, VehicleTypeColumnsMode } from '../../vehicle-types';
import { useUnits } from '../api';
import { UnitCard, UnitMiniTasksTable, WialonExternalId } from '../components';
import { DevicePlatforms, PlatformColor, unitStatus } from '../constants/constants';
import { EditMultipleUnits } from '../forms/EditMultipleUnits';
import { IfullUnit, UnitWithAllExtras } from '../types/types';

export function UnitsTable() {
  const [data, setData] = useState<IfullUnit[]>([]);
  const [page, setPage] = useState(1);
  const [rowSelection, setRowSelection] = useState({});
  const [ids, setIds] = useState<number[]>([]);
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes(
      'deviceConfig',
      'device',
      'device.deviceType as deviceType',
      'simCard',
      'simCard.simCardType as simType',
      'vehicle',
      'vehicle.vehicleType as vehicleType',
      'vehicle.organization as org',
      'org.parent as orgParent',
      'tasks',
      'purchaseOrderPlan',
    );
    param.select(
      'id',
      'remarks',
      'deviceConfig.id',
      'deviceConfig.platform',
      'deviceConfig.secondaryPlatform',
      'deviceConfig.externalId',
      'vehicle.id',
      'vehicle.vin',
      'vehicle.plateNumber',
      'vehicle.category',
      'vehicle.placeOfIssue',
      ...selectColumns(SimCardColumnsMode, { join: 'simCard', exclude: ['purchaseDate'] }),
      ...selectColumns(DeviceColumnsMode, { join: 'device', exclude: ['purchaseDate'] }),
      ...selectColumns(VehicleTypeColumnsMode, { join: 'vehicleType', exclude: ['imageRepositoryId'] }),
      'org.id',
      'org.name',
      'org.status',
      'orgParent.id',
      'orgParent.name',
      'purchaseOrderPlan.id',
      'purchaseOrderPlan.status',
      'deviceType.id',
      'deviceType.supplier',
      'deviceType.type',
      'simType.id',
      'simType.supplier',
      'simType.type',
      'tasks.id',
      'tasks.type',
      'tasks.status',
    );
    param.sort('-unit.id');
    param.page(10, page - 1);
    return param;
  });
  const {
    data: allUnits,
    refetch,
    isLoading,
  } = useUnits({
    params: params.toString(),
  });
  useEffect(() => {
    if (allUnits) {
      setData(getUnitsWithStatus(allUnits.data.items));
      const selectedRows = allUnits.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allUnits]);

  const [opened, { open, close }] = useDisclosure(false);
  const [openedWialon, { open: openWialon, close: closeWialon }] = useDisclosure(false);

  const customActions = [
    { id: 'multiEdit', multi: true, title: 'Edit Multiple', icon: FaEdit, color: 'orange', handler: open, noDisplay: ['record'] },
    {
      id: 'wialon',
      multi: false,
      title: 'Connect Wialon',
      icon: FaPlug,
      color: 'blue',
      handler: openWialon,
      noDisplay: [],
    },
  ];

  const columnHelper = useMemo(() => createColumnHelper<IfullUnit>(), []);
  const columns: ColumnDef<IfullUnit, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allUnits?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allUnits?.data.meta.ids ? allUnits?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
      columnHelper.accessor( (row) => row.deviceConfig?.id ?? '', { header: 'Config id', id: 'deviceConfig.id' }),
      columnHelper.accessor(
        (row) => `${row.deviceConfig?.platform ?? ''} ${row.deviceConfig?.secondaryPlatform ? ', ' + row.deviceConfig?.secondaryPlatform : ''}`,
        { header: 'Platforms', id: 'deviceConfig.platform|deviceConfig.secondaryPlatform' },
      ),

      columnHelper.accessor( (row) => row.deviceConfig?.platform ?? '', {
        header: 'Platform',
        id: 'deviceConfig.platform',
        cell: (props) => {
          if (props.getValue())
          return (
        <Badge
        variant="light"
        c={PlatformColor[props.getValue().split(' ').join('_') as keyof typeof PlatformColor]}
        styles={{
          root: { backgroundColor: `${PlatformColor[props.getValue().split(' ').join('_') as keyof typeof PlatformColor]}40` },
        }}
        >
                {props.getValue()}
              </Badge>
            );
          },
        }),
        columnHelper.accessor((row) => row.deviceConfig?.secondaryPlatform ?? '', {
          header: 'Secondary Platform',
          id: 'deviceConfig.secondaryPlatform',
          cell: (props) => {
            if (props.getValue())
            return (
              <Badge
                variant="light"
                c={PlatformColor[props.getValue().split(' ').join('_') as keyof typeof PlatformColor]}
                styles={{
                  root: { backgroundColor: `${PlatformColor[props.getValue().split(' ').join('_') as keyof typeof PlatformColor]}40` },
                }}
                >
                {props.getValue()}
              </Badge>
            );
          },
        }),
      columnHelper.accessor((row) => row.device?.id, { header: 'Device id', id: 'device.id' }),
      columnHelper.accessor((row) => (row.device ? row.device.imei : ''), { header: 'Device imei', id: 'device.imei' }),
      columnHelper.accessor((row) => (row.device ? row.device.status : ''), {
        header: 'Device Status',
        id: 'device.status',
        cell: (props) => {
          if (props.getValue())
          return (
              <Badge
                variant="light"
                c={deviceStatusColor[props.getValue().split(' ').join('_') as keyof typeof deviceStatusColor]}
                styles={{
                  root: {
                    backgroundColor: `${deviceStatusColor[props.getValue().split(' ').join('_') as keyof typeof deviceStatusColor]}40`,
                  },
                }}
                >
                {props.getValue()}
              </Badge>
            );
          },
        }),
        columnHelper.accessor((row) => (row.device ? row.device.ownership : ''), { header: 'Device Ownership', id: 'device.ownership' }),
        columnHelper.accessor((row) => row.device?.deviceType.id, { header: 'DeviceType id', id: 'deviceType.id' }),
      columnHelper.accessor((row) => (row.device ? row.device.deviceType.supplier : ''), {
        header: 'DeviceType Supplier',
        id: 'deviceType.supplier',
      }),
      columnHelper.accessor((row) => (row.device ? row.device.deviceType.type : ''), { header: 'DeviceType Type', id: 'deviceType.type' }),
      columnHelper.accessor((row) => row.simCard?.id, { header: 'Sim id', id: 'simCard.id' }),
      columnHelper.accessor((row) => (row.simCard ? row.simCard.number : ''), { header: 'Sim number', id: 'simCard.number' }),
      columnHelper.accessor((row) => (row.simCard ? row.simCard.serial : ''), { header: 'Sim serial', id: 'simCard.serial' }),
      columnHelper.accessor((row) => (row.simCard ? row.simCard.imsi ?? '' : ''), { header: 'Sim imsi', id: 'simCard.imsi' }),
      columnHelper.accessor((row) => (row.simCard ? row.simCard.status : ''), {
        header: 'Sim status',
        id: 'simCard.status',
        cell: (props) => {
          if (props.getValue())
          return (
        <Badge
        variant="light"
        c={simCardStatusColor[props.getValue().split(' ').join('_') as keyof typeof simCardStatusColor]}
        styles={{
                  root: {
                    backgroundColor: `${simCardStatusColor[props.getValue().split(' ').join('_') as keyof typeof simCardStatusColor]}40`,
                  },
                }}
              >
                {props.getValue()}
              </Badge>
            );
          },
        }),
        columnHelper.accessor((row) => row.simCard?.simCardType.id, { header: 'SimType id', id: 'simType.id' }),
        columnHelper.accessor((row) => (row.simCard ? row.simCard.simCardType.supplier : ''), {
          header: 'Sim Supplier',
          id: 'simType.supplier',
        }),
        columnHelper.accessor((row) => (row.simCard ? row.simCard.simCardType.type : ''), { header: 'Sim Type', id: 'simType.type' }),
        columnHelper.accessor((row) => row.vehicle?.organization?.name ?? '', { header: 'Organization', id: 'org.name' }),
        // columnHelper.accessor((row) => row.vehicle?.organization?.parent?.name ?? '', { header: 'Parent', id: 'orgParent.name' }),
        columnHelper.accessor((row) => row.vehicle?.organization?.parent?.name ? row.vehicle.organization?.name + ' / ' + row.vehicle?.organization?.parent?.name : row.vehicle?.organization?.name ?? '', { header: 'Organization & Parent', id: 'org.name|orgParent.name' }),
      columnHelper.accessor((row) => row.vehicle?.id, { header: 'Vehicle id', id: 'vehicle.id' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vin : ''), { header: 'Vehicle vin', id: 'vehicle.vin' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.category : ''), { header: 'Vehicle Category', id: 'vehicle.category' }),
      columnHelper.accessor((row) => row.vehicle?.plateNumber, { header: 'Vehicle Plate', id: 'vehicle.plateNumber' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.placeOfIssue : ''), {
        header: 'Vehicle Place issue',
        id: 'vehicle.placeOfIssue',
      }),
      columnHelper.accessor((row) => row.vehicle?.vehicleType.id, { header: 'VehicleType id', id: 'vehicleType.id' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vehicleType.brand : ''), {
        header: 'Vehicle brand',
        id: 'vehicleType.brand',
      }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vehicleType.model : ''), {
        header: 'Vehicle model',
        id: 'vehicleType.model',
      }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vehicleType.type : ''), { header: 'Vehicle type', id: 'vehicleType.type' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vehicleType.category ?? '' : ''), { header: 'Vehicle Category', id: 'vehicleType.category' }),
      columnHelper.accessor((row) => row.vehicle?.vehicleType.year, { header: 'Vehicle Year', id: 'vehicleType.year' }),
      columnHelper.accessor((row) => row.remarks, { header: 'Remarks', id: 'remarks' }),
      columnHelper.accessor((row) => row.deviceConfig?.externalId ?? '', { header: 'wialonID', id: 'deviceConfig.externalId' }),
      columnHelper.accessor((row) => row.status ?? '', { header: 'status', id: 'noID' }),
    ],
    [columnHelper, data, ids],
    );
    
    const toSelect = {
    status: unitStatus,
    Platforms: DevicePlatforms,
    Platform: DevicePlatforms,
    'Secondary Platform': DevicePlatforms,
    'Device Status': DeviceStatus,
    'Sim status': SimStatus,
    'Vehicle Category': VehicleCategory,
    'Device Ownership': DeviceOwnership,
  };

  return (
    <Layout>
      <Title
        order={2}
        align="left"
        mb="md"
        sx={(theme: any) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 800,
        })}
      >
        Units:
      </Title>
      <BaseTable<IfullUnit>
        data={data}
        columns={columns}
        loading={isLoading}
        page={page}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'status')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          // refetch()
          setPage(1);
        }}
        pages={allUnits?.data.meta.ids.length ? Math.ceil(allUnits?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          if (column === 'noID') {
            // find better way to get the cols
            setParams((param) => {
              param.page(10, 0);
              return getUnitStatusParam(
                value,
                param,
                'vehicle.id|device.id|simCard.id|simCard.status|org.status|device.status|device.status|device.status|purchaseOrderPlan.status|purchaseOrderPlan.status|tasks.type||tasks.status|unit.id|unit.id',
              );
            });
          } else {
            setTableParams(column, value, type, setParams);
          }
          refetch();
          setPage(1);
        }}
        toSelect={toSelect}
        csvExport={<ExportUnits ids={ids} />}
        ids={ids}
        setIds={setIds}
        defaultVisibleColumns={{
          id: false,
          remarks: false,
          'deviceConfig.platform': false,
          'org.name': false,
          'deviceConfig.secondaryPlatform': false,
          'deviceConfig.externalId': false,
          'deviceConfig.id': false,
          'simCard.serial': false,
          'device.id': false,
          'deviceType.id': false,
          'deviceType.supplier': false,
          'simCard.id': false,
          'simCard.status': false,
          'simCard.imsi': false,
          'simType.id': false,
          'simType.supplier': false,
          'simType.type': false,
          'vehicle.id': false,
          'vehicle.category': false,
          'vehicle.placeOfIssue': false,
          'vehicleType.id': false,
          'vehicleType.brand': false,
          'vehicleType.model': false,
          'vehicleType.type': false,
          'vehicleType.year': false,
          'vehicleType.category': false,
          'device.ownership': false,
        }}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
      />
      <SideBar id={ids} type={types.Unit} customActions={customActions} extraSection={<UnitMiniTasksTable id={ids[0]} />}>
        <UnitCard id={ids[0]} />
      </SideBar>
      <Modal
        opened={opened}
        onClose={close}
        size="90%"
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Edit Multiple units
          </Text>
        }
      >
        {ids.length < 100 && <EditMultipleUnits ids={ids} />}
        {ids.length >= 100 && (
          <Text align="center" fw={500} fz="lg">
            Please Select less than 100 Unit to perform Edit Multiple
          </Text>
        )}
      </Modal>
      <Modal opened={openedWialon} onClose={closeWialon} title="Connect With Wialon">
        {ids.length === 1 && data.filter((unit) => unit.id === ids[0])[0]?.vehicle && <WialonExternalId unit={data.filter((unit) => unit.id === ids[0])[0] as UnitWithAllExtras} />}
      </Modal>
    </Layout>
  );
}
