import { Button, Checkbox, Group, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { TbDownload } from "react-icons/tb";

interface coloumnProps {
   checked: boolean,
   path: string,
   alias: string
}

export function ExportToCsvButton({ keys, onSubmit, setDefaultColumns }: {
   keys: string[], onSubmit: (values: {
      columns: Record<string, coloumnProps>;
      fileName: string;
   }) => any,
   setDefaultColumns?: string[]
}) {
   const [opened, { open, close }] = useDisclosure(false);
   const form = useForm({
      initialValues: {
         columns: keys.reduce((acc, curr) => {
            acc[curr] = {
               checked: curr === 'id' ? true : false,
               path: curr.replaceAll(':', '.'),
               alias: curr.replaceAll(':', '.')
            }
            return acc
         }, {} as Record<string, coloumnProps>),
         fileName: 'CSVfile'
      }
   })
   const allPaths:string[] = []

   const items = Object.keys(form.values.columns).map(key => {
      const label = key.slice(key.lastIndexOf(':') !== -1 ? key.lastIndexOf(':') + 1 : 0)
      const path = key.split(':').slice(0,-1).join(':')
      let newPath = false
      let check = true
      if(!allPaths.includes(path) && path) {
         allPaths.push(key.split(':').slice(0,-1).join(':'))
         newPath = true
      } 

      Object.keys(form.values.columns).filter(key => key.includes(path)).forEach(key => {
         if(!form.values.columns[key].checked) check = false
      })
      return (
         <div key={key}>
            {newPath && <Checkbox label={path} mt='sm' checked={check} onChange={(event) => {
               Object.keys(form.values.columns).filter(key => key.includes(path)).forEach(key => {
                  form.setFieldValue(`columns.${key}.checked`, event.currentTarget.checked)
                  path.split(':').forEach((item,index) => {
                     const level = path.split(':').slice(0, index + 1).join(':')
                     let checked = false
                     if(level !== path){
                        Object.keys(form.values.columns).filter(key => key.startsWith(level) && key.split(':').slice(0, -1).join(':') !== path).forEach(key => {
                           if((form.values.columns[key].checked && !key.endsWith(':id')) || event.currentTarget.checked) checked = true
                           
                        })
                        form.setFieldValue(`columns.${level+':id'}.checked`, checked)
                     }else {
                        form.setFieldValue(`columns.${level+':id'}.checked`, event.currentTarget.checked)
                     }
                  })
               })
            }} />}

            <Checkbox mt="sm" pl='sm' label={label} disabled={label === 'id'} checked={form.values.columns[key].checked}  
            onChange={(event)=> {
               form.setFieldValue(`columns.${key}.checked`, event.currentTarget.checked)
               
               if(event.currentTarget.checked && key.includes(':')){
                  path.split(':').forEach((item,index) => {
                     const level = path.split(':').slice(0, index + 1).join(':')
                     form.setFieldValue(`columns.${level+':id'}.checked`, event.currentTarget.checked)
                  })
               }else if(key.includes(':')) {
                  form.setFieldValue(`columns.${path+':'+label}.checked`, event.currentTarget.checked)
                  path.split(':').forEach((item,index) => {
                     const level = path.split(':').slice(0, index + 1).join(':')
                     let checked = false
                     Object.keys(form.values.columns).filter(key => key.startsWith(level)).forEach(key => {
                        if(key !== path+':'+label && !key.endsWith(':id')){
                           if(form.values.columns[key].checked) checked = true
                        }
                     })
                     form.setFieldValue(`columns.${level+':id'}.checked`, checked)
                  })
               }else {
                  form.setFieldValue(`columns.${key}.checked`, event.currentTarget.checked)
               }
            }} />
         </div>
      )
   })

   function handleAllCheckBoxes(value:boolean){
      keys.filter(key => key !== 'id').forEach((path) => {
         form.setFieldValue(`columns.${path}.checked`,value)
      })
   }

   return (
      <>
         <Modal opened={opened} onClose={close} title="Export To CSV" size="700px">
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Group  position="apart" mt="sm" pl='sm'>

               <Checkbox label={'all'} defaultChecked={false} onChange={(event => handleAllCheckBoxes(event.currentTarget.checked))} />
               {setDefaultColumns && <Button onClick={() => {
                  handleAllCheckBoxes(false)
                  setDefaultColumns.forEach(column => {
                     // console.log(`column ${column}`)
                     // console.log(`form ${form.values.columns[column].checked}`)
                     form.setFieldValue(`columns.${column}.checked`, true)
                  })
               }} >set default columns</Button>}
            </Group>

               {items}
               <TextInput label="filename" mt="sm" {...form.getInputProps('fileName')} />
               <Button mt="md" type="submit">Export</Button>
            </form>

         </Modal>
         <Button size="xs" onClick={open} rightIcon={<TbDownload />}>Export To CSV</Button>
      </>
   )
}