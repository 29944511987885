import { Button, Container, Group, Text, Title, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
   root: {
      paddingTop: 80,
      paddingBottom: 80,
   },

   inner: {
      position: 'relative',
   },

   image: {
      position: 'absolute',
      top: -240,
      right: 0,
      left: -90,
      zIndex: 0,
      opacity: 0.15,
      fontSize: 600,
      fontWeight: 800,

      [theme.fn.smallerThan('lg')]: {
         top: -100,
         left: 0,
         fontSize: 400,
         fontWeight: 750,
      },

      [theme.fn.smallerThan('sm')]: {
         top: -100,
         left: 0,
         fontSize: 350,
         fontWeight: 700,
      },

      [theme.fn.smallerThan('xs')]: {
         top: -70,
         left: 0,
         fontSize: 300,
         fontWeight: 650,
      },
   },

   content: {
      paddingTop: 220,
      position: 'relative',
      zIndex: 1,

      [theme.fn.smallerThan('sm')]: {
         paddingTop: 120,
      },
   },

   title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      textAlign: 'center',
      fontWeight: 900,
      fontSize: 38,

      [theme.fn.smallerThan('sm')]: {
         fontSize: 32,
      },
   },

   description: {
      maxWidth: 540,
      margin: 'auto',
      marginTop: theme.spacing.xl,
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
   },
}));

export function NotFound() {
   const { classes } = useStyles();
   const navigate = useNavigate()

   return (
      <Container className={classes.root}>
         <div className={classes.inner}>
            <Text className={classes.image} color="dimmed" align="center" >404</Text>
            <div className={classes.content}>
               <Title className={classes.title}>Nothing to see here</Title>
               <Text color="dimmed" size="lg" align="center" className={classes.description}>
                  Page you are trying to open does not exist. You may have mistyped the address, or the
                  page has been moved to another URL. If you think this is an error contact support.
               </Text>
               <Group position="center">
                  <Button size="md" onClick={() => navigate('/')}>Take me back to home page</Button>
               </Group>
            </div>
         </div>
      </Container>
   );
}