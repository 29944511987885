import { ScrollArea, Table, createStyles } from '@mantine/core';
import { Task } from '../../tasks';

const useStyles = createStyles((theme) => ({
  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },

  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
}));

export function ViewUserTasks({ tasks }: { tasks: Task[] | undefined }) {
  const { classes } = useStyles();

  const rows = tasks?.map((task) => {
    return (
      <tr key={task.id}>
        <td className={classes.leftSideTable}>{task.id}</td>
        <td>{task.vehicle?.plateNumber || task.unit?.vehicle?.plateNumber}</td>
        <td>{task.vehicle?.vin || task.unit?.vehicle?.vin}</td>
        <td>{task.type}</td>
        <td>{task.completionDate ? new Date(task.completionDate).toDateString() : 'Not Completed'}</td>
      </tr>
    );
  });

  return (
    <ScrollArea h={400}>
      <Table p={5}>
        <thead className={classes.header}>
          <tr>
            <th>taskId</th>
            <th>plateNumber</th>
            <th>vin</th>
            <th>task type</th>
            <th>completion Date</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}
