import { Alert, Button, Container, Grid, Input, List, LoadingOverlay, Modal, Paper, Title, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { NumberInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { RichTextEditorComponent } from '../../../components/richTextEditor/RichTextEditorComponent';
import { types } from '../../../constants';
import { enumToSelectData, noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { PresetsType } from '../../preSets';
import { postQuotation } from '../api/postQuotation';
import { CountryOfOrigin, QuotationPlanType, QuotationStatus } from '../constants/constants';
import { IquotationInput, IquotationPlanInput } from '../types/types';
import { NewQuotationContentForm } from './NewQuotationContentForm';

export function NewQuotationTemplete() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [planType, setPlanType] = useState(QuotationPlanType.FirstPayment);

  const queryClient = useQueryClient();

  const quotationPlan: IquotationPlanInput = {
    massage: '',
    title: '',
    type: '',
    quotationContent: [],
  };

  const form = useForm<IquotationInput>({
    initialValues: {
      name: '',
      title: '',
      status: QuotationStatus.Complete,
      organization: null,
      contact: null,
      qautationDate: null,
      countryOfOrigin: CountryOfOrigin.Europe,
      validity: 15,
      termsConditions: '',
      templete: true,
      discount: '',
      quotationPlan: [],
    },
  });

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postQuotation, {
    onSuccess: () => {
      setAlert(null);
      form.reset();
      queryClient.invalidateQueries([types.Quotation, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IquotationInput) {
    let valid = true;
    const newValues = noEmptyString(values);
    newValues.quotationPlan.forEach((item: IquotationPlanInput) => {
      if (!item.quotationContent.filter((item) => item.description && item.quantity).length) valid = false;
    });
    if (newValues.quotationPlan.length && valid) {
      setAlert(null);
      newMutate(newValues);
    } else setAlert('a quotation needs at least to one 1 content to be created');
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Templete:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              required
              mt="md"
              withAsterisk={false}
              label={
                <Tooltip label={'The title at the top of the pdf above the client details'}>
                  <Input.Label required>Title</Input.Label>
                </Tooltip>
              }
              placeholder="title"
              {...form.getInputProps('title')}
            />
            <Grid>
              <Grid.Col span={6}>
                <NumberInputEditable
                  label="Discount"
                  placeholder="discount"
                  precision={2}
                  {...form.getInputProps('discount')}
                  hideControls
                  mt="md"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <SelectEditable
                  label="Country Of Origin"
                  placeholder="Search Here"
                  {...form.getInputProps('countryOfOrigin')}
                  required
                  data={enumToSelectData(CountryOfOrigin)}
                  mt="md"
                />
                <NumberInputEditable
                  label="Validity"
                  placeholder="contact validity days"
                  {...form.getInputProps('validity')}
                  required
                  hideControls
                  mt="md"
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Button
                  fullWidth
                  onClick={() => {
                    if (!form.values.quotationPlan.find((item) => item.type === QuotationPlanType.FirstPayment))
                      form.insertListItem('quotationPlan', { ...quotationPlan, type: QuotationPlanType.FirstPayment });
                    setPlanType(QuotationPlanType.FirstPayment);
                    open();
                  }}
                >
                  First Year Charge
                </Button>
              </Grid.Col>
              <Grid.Col span={4}>
                <Button
                  fullWidth
                  onClick={() => {
                    if (!form.values.quotationPlan.find((item) => item.type === QuotationPlanType.Renwal))
                      form.insertListItem('quotationPlan', { ...quotationPlan, type: QuotationPlanType.Renwal });
                    setPlanType(QuotationPlanType.Renwal);
                    open();
                  }}
                >
                  Renewal Charge
                </Button>
              </Grid.Col>
              <Grid.Col span={4}>
                <Button
                  fullWidth
                  onClick={() => {
                    if (!form.values.quotationPlan.find((item) => item.type === QuotationPlanType.OptionalAddOns))
                      form.insertListItem('quotationPlan', { ...quotationPlan, type: QuotationPlanType.OptionalAddOns });
                    setPlanType(QuotationPlanType.OptionalAddOns);
                    open();
                  }}
                >
                  Optional Items
                </Button>
              </Grid.Col>
            </Grid>
            <RichTextEditorComponent
              label={'Terms and Conditions'}
              value={form.values.termsConditions}
              setValue={(data) => form.setFieldValue('termsConditions', data)}
              withPreset={PresetsType.TermsAndConditions}
            />

            <TextInputEditable
              required
              withAsterisk={false}
              label={
                <Tooltip label={'Consider it as a file name that you can search for in the future'}>
                  <Input.Label required>File Name</Input.Label>
                </Tooltip>
              }
              placeholder="file Name"
              {...form.getInputProps('name')}
            />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Modal opened={opened} onClose={close} size="90%">
        <NewQuotationContentForm
          values={form.values.quotationPlan.filter((item) => item.type === planType)[0]}
          setValues={(data, column) => {
            const index = form.values.quotationPlan.findIndex((item) => item.type === planType);
            form.setFieldValue(`quotationPlan.${index}.${column}`, data);
          }}
          insertContent={(data) => {
            const index = form.values.quotationPlan.findIndex((item) => item.type === planType);
            form.insertListItem(`quotationPlan.${index}.quotationContent`, data);
          }}
        />
      </Modal>
    </Layout>
  );
}
