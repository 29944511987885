import { Flex, Paper, ScrollArea } from "@mantine/core";
import { DailyTasksWidget } from "./DailyTasksWidget";
import { PeriodlyTasksWidget } from "./PeriodlyTasksWidget";

export function TasksWidgets(){
    return(
        <Paper withBorder h={450} radius="md" px='md'>
                <Flex mih={450} justify={'center'}  direction={'column'}>
            <ScrollArea.Autosize mah={430}>
                    <DailyTasksWidget />
                    <PeriodlyTasksWidget />
            </ScrollArea.Autosize>
                </Flex>
        </Paper>
    )
}