import { Button, Checkbox, Group, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, setTableParams } from '../../../utils';
import { useAccessoriesType } from '../api';
import { IAccessoriesType } from '../types/types';

export function AccessoryTypeTable() {
  const [data, setData] = useState<IAccessoriesType[]>([]);
  const [ids, setIds] = useState<number[]>([])
  const [page, setPage] = useState(1)
  const [rowSelection, setRowSelection] = useState({})
  const navigate = useNavigate()
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams()
      param.sort(('-accessories_type.id'))
      param.page(10, page-1)
    return param
  })
  const { data: allAccessoriesType, refetch, isLoading } = useAccessoriesType({ params: params.toString() });
  useEffect(() => {
    if (allAccessoriesType) {
      setData(allAccessoriesType.data.items);
      const selectedRows = allAccessoriesType.data.items.map((item) => {
        if(ids.includes(item.id)) return {index: true}
      })
      setRowSelection(selectedRows)
    }
  }, [allAccessoriesType]);

  const columnHelper = useMemo(() => createColumnHelper<IAccessoriesType>(), []);
  const columns: ColumnDef<IAccessoriesType, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        
        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allAccessoriesType?.data.meta.ids.length
                if(allSelected){
                  setIds([])
                }else setIds(allAccessoriesType?.data.meta.ids ? allAccessoriesType?.data.meta.ids : ids)
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex(id => id === row.original.id)
                  if(rowId === -1){
                    setIds([...ids, row.original.id])
                  }else {
                    setIds(ids => {
                      ids.splice(rowId,1)
                      return ids
                    })
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => row.name, { header: 'Name', id: 'name' }),
      columnHelper.accessor((row) => row.brand, { header: 'Brand', id: 'brand' }),
      columnHelper.accessor((row) => row.model, { header: 'Model', id: 'model' }),
      columnHelper.accessor((row) => row.totalQuantity, { header: 'total quontity', id: 'totalQuantity' }),
      columnHelper.accessor((row) => row.usedQuantity, { header: 'used quontity', id: 'usedQuantity' }),
    ],
    [columnHelper, data, ids],
  );
  return (
    <Layout>
      <Group px={10} mt={10} mb={20} position="apart">
          <Title
              order={2}
              align="left"
              sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}
          >
              Accessory Types:
          </Title>
          <Button onClick={() => navigate('/new/accessories_type')} >Add new</Button>
        </Group>
      <BaseTable<IAccessoriesType> data={data} columns={columns} setIds={setIds} setRowSelection={setRowSelection} rowSelection={rowSelection} 
      loading={isLoading}
      page={page}
      ids={ids}
      setPage={(value) => {
        setParams((param) => {
          param.filterRemovePages()
          param.page(10, value - 1)
          return param
        })
        setPage(value)
       
      }}
      pages={allAccessoriesType?.data.meta.ids.length ? Math.ceil(allAccessoriesType?.data.meta.ids.length/10): 1}
      filterData={(column, value, type)=> {
        setTableParams(column, value, type, setParams)
        refetch()
        setPage(1)
      }}
      globalFilterData={(data, headers) => {
        const newHeaders = headers.filter(header => header && header !== 'select' && header !== 'actions').join('|')
        setTableParams(newHeaders, data, '', setParams)
        refetch()
        setPage(1)
      }}
      />
      <SideBar id={ids} type={types.AccessoriesType} withCard={false}>
        <></>
      </SideBar>
    </Layout>
  );
}
