import api from "../../common/api/api";
import { IupdatePassword } from "../types/types";

interface MyResUpdatePassord {
	data: IupdatePassword;
}

export const patchPassord = (props: { id: number; data: IupdatePassword }): Promise<MyResUpdatePassord> => {
	return api.patch(`users/password/${props.id}`, props.data);
};
