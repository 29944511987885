import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IrfidList } from '../types/types';

interface myResRfidList {
  data: IrfidList;
  meta: metaData;
}

export const getDetailedRfidList = (id: number, query = ''): Promise<myResRfidList> => {
  return api.get(`rfid-list/details/${id}${query}`);
};

export const useDetailedRfidList = (id: number, options?: { params?: string; then?: (x: myResRfidList) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.RFIDList, { id }, types.RFIDListDetailed];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      getDetailedRfidList(id, options?.params).then((detailedRfidList) => {
        options && options.then && options.then(detailedRfidList);
        return detailedRfidList;
      }),
    { enabled: id !== 0 },
  );
  return query;
};

interface myResCountRFIDListsNumber {
  data: number;
}

export const countRFIDLists = (query = ''): Promise<myResCountRFIDListsNumber> => {
  return api.get(`rfid-list/count${query}`);
};

export const useCountRFIDLists = (options?: { params?: string; then?: (x: myResCountRFIDListsNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.RFIDList, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countRFIDLists(options?.params).then((rfidList) => {
      options && options.then && options.then(rfidList);
      return rfidList;
    }),
  );
  return query;
};