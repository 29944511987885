import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Container,
  Group,
  Input,
  List,
  LoadingOverlay,
  Paper,
  Stepper,
  Table,
  Text,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbReload } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DatePickerInputEditable, SelectEditable } from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { URLParams } from '../../../../utils';
import { IaxiosError } from '../../../common';
import { NewDeliveryNoteForm } from '../../../deliveryNote';
import { SimStatus, useSimCards } from '../../../sim-cards';
import { patchUnitTechnicianTask } from '../../../unit/api/patchUnitTaskBased';
import { useTask } from '../../api';
import { Task } from '../../types/types';

export function TaskSimCardChangeApprovalFrom() {
  const { id } = useParams();
  const [idN] = useState(id && !isNaN(+id) ? +id : 0);
  const [task, setTask] = useState<Task>();
  const [alert, setAlert] = useState<string | string[] | null>(null);

  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));

  const [simCards, setSims] = useState([{ value: '', label: '' }]);

  const simParams = new URLParams();
  simParams.includes('unit');
  simParams.select('id', 'number');
  simParams.filterSetField('status', SimStatus.Available);
  simParams.filterSetField('=unit.id', 'null');
  const { data: simCardsData, refetch } = useSimCards({ params: simParams.toString() });
  useEffect(() => {
    if (simCardsData) {
      const tempArr = simCardsData.data.items.map((simCard) => {
        return {
          value: `${simCard.id}`,
          label: `${simCard.number}`,
        };
      });
      setSims(tempArr);
    }
  }, [simCardsData]);

  const form = useForm({
    initialValues: {
      simCard: { id: 0 },
      jobRemarks: '',
      completionDate: new Date(),
    },
  });
  const { data: taskData } = useTask(idN, {
    joins: ['unit', 'unit.device as device', 'unit.vehicle as vehicle', 'unit.simCard as simCard', 'simCard as newSim'],
    selects: [
      'id',
      'jobRemarks',
      'unit.id',
      'completionDate',
      'device.id',
      'device.imei',
      'vehicle.id',
      'vehicle.vin',
      'vehicle.plateNumber',
      'simCard.id',
      'simCard.number',
      'newSim.id',
    ],
    then: (task) => {
      form.setValues({
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : new Date(),
        simCard: { id: task.data.simCard ? task.data.simCard.id : 0 },
      });
    },
  });

  useEffect(() => {
    if (taskData) {
      setTask(taskData.data);
    }
  }, [taskData]);

  const queryClient = useQueryClient();
  const { mutate: patchMutate, isLoading: patchLoading } = useMutation(patchUnitTechnicianTask, {
    onSuccess: () => {
      setAlert(null);
      notifications.show({
        title: 'Success',
        message: `Successfully Created Please Fill Delivery Note`,
        color: 'green',
        autoClose: 10000,
      });
      nextStep();
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: { simCard: { id: number }; jobRemarks: string; completionDate: Date }) {
    setAlert(null);
    const newValues = { ...values, jobRemarks: values.jobRemarks ? values.jobRemarks : null };

    if (taskData) {
      patchMutate({ id: taskData.data.unit.id, data: { ...newValues, taskId: taskData.data.id, taskStatus: 'done' } });
    }
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Sim Card Change Form:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos={'relative'}>
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg, i) => (
                    <List.Item key={`E-${i}`}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {task && task.unit && (
            <Stepper active={active} onStepClick={setActive}>
              <Stepper.Step label="Change Sim Card">
                <Table mt={12} p={5}>
                  <tbody>
                    <tr>
                      <td>Plate No.:</td>
                      <td>{task.unit.vehicle?.plateNumber}</td>
                    </tr>
                    <tr>
                      <td>Vin:</td>
                      <td>{task.unit.vehicle?.vin}</td>
                    </tr>
                    <tr>
                      <td>Imei:</td>
                      <td>{task.unit.device?.imei}</td>
                    </tr>
                    <tr>
                      <td>Sim Card Number:</td>
                      <td>{task.unit.simCard?.number}</td>
                    </tr>
                  </tbody>
                </Table>
                <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                  <SelectEditable
                    {...form.getInputProps('simCard.id')}
                    label={
                      <Group>
                        <Input.Label required>Sim Card</Input.Label>
                        <ActionIcon onClick={() => refetch()}>
                          <TbReload />
                        </ActionIcon>
                      </Group>
                    }
                    required
                    withAsterisk={false}
                    placeholder="Search here"
                    onChange={(v) => {
                      v && form.setFieldValue('simCard.id', parseInt(v));
                    }}
                    value={`${form.values.simCard.id}`}
                    searchable
                    limit={20}
                    nothingFound="Not found"
                    data={simCards}
                    clearable
                    mt="md"
                  />
                  <DatePickerInputEditable
                    placeholder="Search Date"
                    label="completion Date"
                    {...form.getInputProps('completionDate')}
                    required
                    mt="md"
                  />
                  <Textarea label="Unit Remarks" placeholder="remarks..." mt="md" {...form.getInputProps('jobRemarks')} />
                  <Center mt="lg">
                    <Button type="submit" fullWidth>
                      Confirm Sim Card Change
                    </Button>
                  </Center>
                </form>
              </Stepper.Step>

              <Stepper.Step label="Delivery Note">
                <NewDeliveryNoteForm taskID={idN} />
              </Stepper.Step>
            </Stepper>
          )}
          {task && !task.unit && (
            <Center>
              <Text> Please Contact Techinical Support To Add Unit For Task</Text>
            </Center>
          )}
        </Paper>
      </Container>
    </Layout>
  );
}
