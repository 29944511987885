import {
  ActionIcon,
  Alert,
  Button,
  Code,
  Container,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  TbAlertOctagon,
  TbAlertTriangle,
  TbCircleCheck,
  TbDownload,
  TbPlus,
} from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { URLParams, duplicatesFinder, handleDates, useCsv } from '../../../utils';
import { IaxiosError } from '../../common';
import { IdeviceType, useDeviceTypes } from '../../device-types';
import { postDevices, useDevices } from '../api';

export function AddMultipleDevices() {
  const [file, setFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [duplicateAlert, setDuplicateAlert] = useState<string[] | null>(null);

  const queryClient = useQueryClient();
  const [deviceTypes, setDeviceTypes] = useState<IdeviceType[]>([])
  const viewSubmit = document.getElementById('submit')
  const viewTopOfPage = document.getElementById('TopOfPage')

  const deviceParams = new URLParams();
  deviceParams.select('id', 'imei');
  const {data: devices} = useDevices({params: deviceParams.toString()})
  const deviceTypeParams = new URLParams();
  deviceTypeParams.select('id', 'type');
  const { data: deviceTypeData } = useDeviceTypes({params: deviceTypeParams.toString()})
  useEffect(() => {
    if (deviceTypeData) setDeviceTypes(deviceTypeData.data.items)
  }, [deviceTypeData])


  const requirments = {
    imei: /^\d{15}$/,
    status: /\b(available|ready|in use|broken|lost|sold)\b/,
    ownership: /\b(sold|leased|client Device)\b/,
    deviceType: /[A-Za-z0-9+]$/,
    purchaseDate: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    issuedTo: /^$|^\d+$/,
    organization: /^$|^\d+$/,
    remarks: /^$|^[\s\S]*$/,
  };
  const templateText = `${Object.keys(requirments).join(',')}\r\n`;

  const { csvParser, data, headers, errorRows, validate, setItem, addRow, removeRow } =
    useCsv(requirments, Object.keys(requirments));

  function handleSelectFile(value: File | null) {
    setFile(value);
    if (value) {
      csvParser(value);
    }
  }

  useEffect(() => {
    if(errorRows.length === 0){
      viewSubmit?.scrollIntoView()
    }
  }, [data])

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postDevices, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Device, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit() {
    setAlert(null);
    setDuplicateAlert(null)
    viewTopOfPage?.scrollIntoView()
    
    if (data && devices) {
      const duplicates = duplicatesFinder(devices.data.items,data,'imei','imei')
      const tempAlert: string[] = []
      if(duplicates.length === 0){
        const newValues = data.map((item) => {
          const obj = Object.assign({}, item)
          if (!deviceTypes.some((deviceType) => {
            if (deviceType.type.toUpperCase() === obj.deviceType.toUpperCase()) {
              obj.deviceType = `${deviceType.id}`
              return true
            }
          })) tempAlert.push('device with deviceType ' + item.deviceType + ' does not exist in the database')
          obj.purchaseDate = handleDates(obj.purchaseDate)
          obj.issuedTo = obj.issuedTo ? {id: obj.issuedTo} : null
          obj.organization = obj.organization ? {id: obj.organization} : null
          obj.remarks = obj.remarks ?? null
          return obj
        })
        if (tempAlert.length === 0) {
          newMutate(newValues);
        } else setAlert(tempAlert)
      }else setDuplicateAlert(duplicates)
    } else {
      setAlert(
        'something went wrong while preparing the data, please reload the page and try again.',
      );
    }
  }

  const rows = data?.map((row, i) => (
    <tr key={'r' + i}>
      {headers.map((key, index) => (
        <td key={key + index}>
          <TextInput
            value={row[key]}
            error={errorRows[index]?.includes(i + 1)}
            variant="unstyled"
            onChange={(v) => setItem(i, key, v.target.value)}
          />
        </td>
      ))}
    </tr>
  ));

  return (
    <Layout>
      <Container pt={10} fluid>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add Multiple devices:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <Group position="apart">
            <UploadCSVButton file={file} onChange={handleSelectFile}>
              {(props) => (
                <Button {...props} mt="md">
                  Upload CSV
                </Button>
              )}
            </UploadCSVButton>
            <Button
              mt="md"
              component="a"
              href={`data:text/csv;charset=utf-8,${templateText}`}
              download={'newDevicesTemplate.csv'}
              leftIcon={<TbDownload size={16} />}
            >
              Download a template
            </Button>
          </Group>
          <Alert
            icon={<TbAlertTriangle size={16} />}
            title="Important!"
            withCloseButton
            color="blue"
            mt="md"
          >
            <List id={'TopOfPage'}>
              <List.Item>
                Currently only accepts CSV files, file must include the headers.
              </List.Item>
              <List.Item>imei: must be 15 number and NOT empty.</List.Item>
              <List.Item>
                status: must be one of these valuses: (<Code>available</Code>,{' '}
                <Code>ready</Code>, <Code>in use</Code>, <Code>broken</Code>,{' '}
                <Code>lost</Code>, <Code>sold</Code>).
              </List.Item>
              <List.Item>
                ownership: must be one of these valuses: (<Code>sold</Code>,{' '}
                <Code>leased</Code>, <Code>client Device</Code>).
              </List.Item>
              <List.Item>
                deviceType: deviceType name Must be registered in the system
              </List.Item>
              <List.Item>
                purchaseDate:  must be NOT empty and its format is dd/mm/yyyy.
              </List.Item>
              <List.Item>
              issuedTo:   its user ID.
              </List.Item>
              <List.Item>
              organization:   its organization ID.
              </List.Item>
              <List.Item>remarks: optional.</List.Item>

            </List>
          </Alert>
          {data && Object.keys(requirments).length !== headers.length && (
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Warning!"
              color="orange"
              mt="md"
            >
              The number of column uploaded is different than expected!
            </Alert>
          )}
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {validate() && (
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Error!"
              color="red"
              mt="md"
            >
              <List>
                <List.Item>Errors in the following rows:</List.Item>
                {errorRows.map((errorRow, i) => (
                  <List.Item key={`error-${headers[i]}`}>
                    {headers[i]}: {errorRow.join(', ')}
                  </List.Item>
                ))}
              </List>
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
              mt="md"
            >
              Successfully added to the database!
            </Alert>
          )}
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
              mt="md"
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg, i) => (
                    <List.Item key={`E-${i}`}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {duplicateAlert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
              mt="md"
            >
              <Group position='apart' align='center'>
              {Array.isArray(duplicateAlert) ? (
                <List>
                  {duplicateAlert.map((errMsg) => (
                    <List.Item key={errMsg}>vehicle with {errMsg} already exist in System!</List.Item>
                    ))}
                </List>
              ) : (
                duplicateAlert + '!'
                )}
                <Button onClick={() => {
                  duplicateAlert.forEach(element => {
                    removeRow('imei',element)
                  }
                  );
                  setDuplicateAlert(null)
                }}>Remove Duplicates</Button>
              </Group>
            </Alert>
          )}
          {data && (
            <Table striped highlightOnHover mt="md">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Group position="right" mt="md">
            <ActionIcon size="lg" variant="subtle">
              <TbPlus size={28} onClick={() => addRow()} />
            </ActionIcon>
          </Group>
          <Button
            id='submit'
            fullWidth
            disabled={(!data && !file) || validate() || newLoading}
            onClick={handleSubmit}
            mt="md"
          >
            Submit
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
}
