import { ActionIcon, Button, TextInput } from '@mantine/core';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { TbX } from 'react-icons/tb';
import { DeepFilterOptions } from './useDeepFilter';

//global for now
export function CardsFilterWithButton({ setFilter, filter }: Pick<DeepFilterOptions, 'setFilter' | 'filter'>) {
  const [filterInput, setFilterInput] = useState(filter);
  return (
    <>
      <TextInput
        my={15}
        px={13}
        label="Search"
        placeholder="Search here..."
        value={filterInput}
        onChange={(e) => setFilterInput(e.target.value)}
        icon={<FaSearch size={16} />}
        rightSection={
          <ActionIcon onClick={() => setFilter('')}>
            <TbX />
          </ActionIcon>
        }
      />
      <Button mt="xl" onClick={() => setFilter(filterInput)}>
        Search
      </Button>
    </>
  );
}
