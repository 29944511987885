import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { IdeviceType } from "../types";

interface myResDeviceType {
	data: IdeviceType;
}

export const getDeviceType = (id: number, selects?: string[], joins?: string[]): Promise<myResDeviceType> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`device-types/${id}${params}`);
};

export const useDeviceType = (id: number, options?: {joins?: string[]; selects?: string[] ; then?: (x: myResDeviceType) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.DeviceType, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
			getDeviceType(id, options?.selects, options?.joins).then((deviceType) => {
				options && options.then && options.then(deviceType);
				return deviceType;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};
