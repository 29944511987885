import api from "../../common/api/api";
import { IAccessories, IAccessoriesInput } from "../types";

interface myResAccessory {
	data: IAccessories;
}

export const postAccessory = (data: IAccessoriesInput): Promise<myResAccessory> => {
	return api.post(`accessories`, data);
};

export const postAccessories = (data: Record<string, any>[]): Promise<myResAccessory[]> => {
	return api.post(`accessories/multi`, data);
};
