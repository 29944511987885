
import Layout from '../../../components/layout/Layout';
import { UpdateAccessoryForm } from '../components/UpdateAccessoryForm';

export function UpdateAccessoryFormPage() {

  return (
    <Layout>
      <UpdateAccessoryForm idM={0}/>
    </Layout>
  );
}
