import { Container, Grid } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { BarChart, useBar } from "../components/charts";
import Layout from "../components/layout/Layout";
import { DeviceWidget, Platforms, SimCardWidgets, TasksWidgets } from "../components/widgets";
import { types } from "../constants";
import { IauthUser } from "../context/AuthContext";
import { HistoryCard } from "../modules/history";
import { DevicePlatforms, useAnalyticsUnits } from "../modules/unit";
import { UserRole } from "../modules/users";
import { URLGroupParams } from "../utils";

export function AnalyticsDashboard({user}: {user: IauthUser | null}){
  const { prepareBarData } = useBar()
  const screenSize = useMediaQuery('(min-width: 56.25em)');

  const countUnitsPlatFormsOptions = new URLGroupParams()
  countUnitsPlatFormsOptions.joins('device', 'deviceConfig', 'vehicle', 'simCard')
  countUnitsPlatFormsOptions.filterSetField('device','null')
  countUnitsPlatFormsOptions.filterSetField('vehicle','null')
  countUnitsPlatFormsOptions.filterSetField('simCard','null')
  countUnitsPlatFormsOptions.filterSetField('=deviceConfig.secondaryPlatform','null')
  countUnitsPlatFormsOptions.select('device')
  countUnitsPlatFormsOptions.group('deviceConfig.platform')
  const { data: unitplatformsData, isSuccess: isSuccessUnitPlatform } = useAnalyticsUnits({params: countUnitsPlatFormsOptions.toString()})
    
  const countUnitsSecondaryPlatFormsOptions = new URLGroupParams()
  countUnitsSecondaryPlatFormsOptions.joins('device', 'deviceConfig', 'vehicle', 'simCard')
  countUnitsSecondaryPlatFormsOptions.filterSetField('device','null')
  countUnitsSecondaryPlatFormsOptions.filterSetField('vehicle','null')
  countUnitsSecondaryPlatFormsOptions.filterSetField('simCard','null')
  countUnitsSecondaryPlatFormsOptions.group('deviceConfig.secondaryPlatform')
  countUnitsSecondaryPlatFormsOptions.joins('device', 'deviceConfig')
  const { data: unitSecondaryPlatformsData, isSuccess: isSuccessUnitSecondaryPlatform } = useAnalyticsUnits({params: countUnitsSecondaryPlatFormsOptions.toString()})

  return (
    <Layout>
      {user?.role !== UserRole.Technician && <Container p={0} m={0} fluid>
        <Grid >
          <Grid.Col span={screenSize ? 6 : 12}>

            <HistoryCard historySearch={[{id: 0, type: types.ALL}]} cardHei={450} />
          </Grid.Col>
          <Grid.Col span={screenSize ? 6 : 12}>
            <TasksWidgets />
          </Grid.Col>
          <Grid.Col span={12}>
            <DeviceWidget />
          </Grid.Col>
          <Grid.Col span={12}>
            <SimCardWidgets />
          </Grid.Col>
          {screenSize && 
          <>
            <Grid.Col span={6} >
              {isSuccessUnitPlatform && <BarChart title="Platforms" data={prepareBarData(unitplatformsData.data,'deviceConfig', 'platform', 'platform')} keys={Object.values(DevicePlatforms)} indexBy="deviceConfig" secondKey="number" />}
            </Grid.Col>
            <Grid.Col span={6} >
              {isSuccessUnitSecondaryPlatform && <BarChart title="Secondary Platforms" data={prepareBarData(unitSecondaryPlatformsData.data.filter(item => item.secondaryPlatform),'deviceConfig', 'secondaryPlatform', 'secondaryPlatform')} keys={Object.values(DevicePlatforms)} indexBy="deviceConfig" secondKey="number" />}
            </Grid.Col>
          </> 
          }
          {!screenSize && unitplatformsData && unitSecondaryPlatformsData && <Platforms platforms={unitplatformsData.data} secondaryPlatforms={unitSecondaryPlatformsData.data} />}
        </Grid>
      </Container>}
    </Layout>
    )
}