import { createStyles, ScrollArea, Table } from '@mantine/core';
import { remarksHandler } from '../../../utils';
import { Task } from '../../tasks';

const useStyles = createStyles((theme) => ({
  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },

  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
}));

export function VehicleTaskTable({ tasks, cardHei }: { tasks: Task[]; cardHei: number }) {
  const { classes } = useStyles();

  const rows = tasks.map((task) => {
    return (
      <tr key={task.id}>
        <td className={classes.leftSideTable}>{task.id}</td>
        <td>{task.title}</td>
        <td>{task.type}</td>
        <td>{task.performedBy ? task.performedBy?.firstName + ' ' + task.performedBy?.lastName : ''}</td>
        <td>{task.issuedBy?.firstName + ' ' + task.issuedBy?.lastName}</td>
        <td>{task.taskRemarks}</td>
        <td>{remarksHandler(task.jobRemarks ? task.jobRemarks : '')}</td>
      </tr>
    );
  });

  return (
    <ScrollArea h={cardHei - 100}>
      <Table p={5}>
        <thead className={classes.header}>
          <tr>
            <th>taskId</th>
            <th>Title</th>
            <th>Type</th>
            <th>performedBy</th>
            <th>issuedBy</th>
            <th>task Remarks</th>
            <th>job Remarks</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}
