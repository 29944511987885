import { Badge, Button, Card, Container, Divider, Grid, Modal, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import Layout from "../../../components/layout/Layout";
import { useAuthContext } from "../../../context/AuthContext";
import { URLParams } from "../../../utils";
import { useTickets } from "../api";
import { Iticket } from "../types/types";
import { TicketCard } from "./TicketCard";

export function TicketNotification(){
    const { user } = useAuthContext();
    const [data, setData] = useState<Iticket[]>([]);
    const [id, setID] = useState(0)
    
    const [opened, { open, close }] = useDisclosure(false);
    
    const params = new URLParams();
    params.filterSetLevel(0, '|');
    params.filterSetField('=status', 'new', 0);
    params.filterSetField('=status', 'pending', 0);
        params.includes(
        'client',
        'assignedToUser',
    )
    params.sort(('-ticket.id'))
    const { data: allTickets } = useTickets({ params: params.toString()});
    
    useEffect(() => {
        if (allTickets) {
            setData(allTickets.data.items);
        }
    }, [allTickets]);

    

    const tickets = data.map(item => {
        return(
            <Card withBorder key={item.id} mt='md'>
                    <Grid>
                        <Grid.Col span={4}>
                            <Text >
                                #{item.id}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={4} style={{textAlign: "center"}}>
                            <Badge>{item.status}</Badge>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text align="right" fz='sm' fw={220}>
                                {item.assignedToUser ? item.assignedToUser.firstName + ' ' + item.assignedToUser.lastName: 'not assigned yet'}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text >
                                {item.client.firstName} {item.client.lastName} 
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={4} style={{textAlign: "center"}}>
                            <Badge >{item.type}</Badge>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text align="right" fz='sm' fw={220}>
                                {item.assignedOn ? new Date(item.assignedOn).toDateString(): 'not assigned yet'}
                            </Text>
                        </Grid.Col>
                        <Grid.Col p={0} >
                            <Divider />
                        </Grid.Col>
                        <Grid.Col >
                            <Text>{item.title}</Text>
                        </Grid.Col>
                        <Grid.Col p={0} >
                            <Divider />
                        </Grid.Col>
                        <Grid.Col >
                            <Text>{item.body}</Text>
                        </Grid.Col>
                    </Grid>
                    <Button fullWidth mt='md' onClick={()=>{
                        setID(item.id),
                        open()
                    }}>View Chat</Button>
                </Card>
        )
    })

    return(
        <Layout>
            <Title order={1}>Tickets: </Title>
            <Container mt='md'>
                {tickets}
            </Container>
            <Modal size={'70%'} opened={opened} onClose={close} title={<Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}` ,fontWeight: 800, fontSize:'1.625rem' })}>Chat</Text>}>
                <TicketCard id={id} userID={user?.id || 0} role={user?.role}/>
            </Modal>
        </Layout>
    )
}