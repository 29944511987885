import { ActionIcon, Button, createStyles, Group, Menu } from '@mantine/core';
import { TbBoxMultiple, TbChevronDown, TbFilePencil, TbPlus } from 'react-icons/tb';

const useStyles = createStyles((theme) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
  },
}));

export function AddMultipleButton({
  add,
  addMany,
  updateMany,
  others,
}: {
  add: () => any;
  addMany?: () => any;
  updateMany?: () => any;
  others?: { onClickEvent: () => any; title: string }[];
}) {
  const { classes, theme } = useStyles();
  const menuIconColor = theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 5 : 6];

  return (
    <Group noWrap spacing={0}>
      <Button className={addMany ? classes.button : undefined} onClick={() => add()} leftIcon={<TbPlus size={16} />}>
        Add new
      </Button>
      {addMany && (
        <Menu transitionProps={{ transition: 'pop' }} position="bottom-end">
          <Menu.Target>
            <ActionIcon variant="filled" color={theme.primaryColor} size={36} className={classes.menuControl}>
              <TbChevronDown size={16} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<TbBoxMultiple size={16} color={menuIconColor} />} onClick={() => addMany()}>
              Add Multiple items
            </Menu.Item>
            {updateMany && (
              <Menu.Item icon={<TbFilePencil size={16} color={menuIconColor} />} onClick={() => updateMany()}>
                Update Multiple items
              </Menu.Item>
            )}

            {others?.map(({ onClickEvent, title }) => (
              <Menu.Item key={title} icon={<TbFilePencil size={16} color={menuIconColor} />} onClick={() => onClickEvent()}>
                {title}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
}
