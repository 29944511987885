import { Button, Modal, Text, TransferList } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { URLParams, getUnitsWithStatus } from "../../../utils";
import { SelectMultipleUnits } from "../../finance";
import { IfullUnit, useUnits } from "../../unit";

type listType = [{ value: string; label: string }[], { value: string; label: string }[]]
export function UnitsTransferList({ planIDs, values,setValues, withCSV }
    :
    {
        planIDs: number[],
        values: number[],
        setValues: (value: {id: number, platform: string, secondaryPlatform: string | null}[]) => void,
        withCSV?: true,
    }) {
    const [unitsList, setUnitsList] = useState<IfullUnit[]>([]);
    const [units, setUnits] = useState<listType>([[{ value: '0', label: 'loading...' }], [{ value: '0', label: 'loading...' }]]);
    
    const [opened, { open, close }] = useDisclosure(false);
    const unitsParams = new URLParams();
    unitsParams.includes(
        'deviceConfig',
        'device',
        'simCard',
        'vehicle',
        'vehicle.organization as org',
        'tasks',
        'purchaseOrderPlan'
      );
    unitsParams.select('id','remarks', 'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform', 
    'vehicle.id','vehicle.vin','vehicle.plateNumber', 'simCard.id', 'simCard.status', 'device.id', 'device.status', 'device.imei',
    'org.id', 'org.name', 'org.status',
    'purchaseOrderPlan.id',
    'tasks.id', 'tasks.type', 'tasks.status'
    )
    unitsParams.searchMany('purchaseOrderPlanId', planIDs.map(id => `${id}`))
    const { data: unitsData } = useUnits({params: unitsParams.toString()});
    useEffect(() => {
        
        const select: { value: string; label: string }[] = []
        const notSelect: { value: string; label: string }[] = []
        if (unitsData) {
            const tempUnits =  getUnitsWithStatus(unitsData.data.items)
            tempUnits.forEach(unit => {
                if(values.includes(unit.id)) select.push({value: `${unit.id}`, label: `${unit.vehicle?.vin} - ${unit.vehicle?.plateNumber ?? ''} - ${unit.device?.imei ?? 'No Device'}, ${unit.status}, ${unit.deviceConfig.platform}, ${unit.deviceConfig.secondaryPlatform ?? ''}`})
                else notSelect.push({value: `${unit.id}`, label: `${unit.vehicle?.vin}  ${unit.vehicle?.plateNumber ? `- ${unit.vehicle?.plateNumber}` : ''} - ${unit.device?.imei ?? 'No Device'}, ${unit.status}, ${unit.deviceConfig.platform}, ${unit.deviceConfig.secondaryPlatform ?? ''}`})
            })
            setUnitsList(tempUnits)
        }
        setUnits([select,notSelect])
    }, [unitsData,values]);
    
    return (
        <>
        {withCSV && <Button mt='md' onClick={open}>Select multiple units</Button>}
            <TransferList
                    value={units}
                    onChange={(v: listType) => {
                        setUnits(v);
                        const tempSelectedUnits = unitsList.filter(unit => {
                            const foundUnit = v[0].find(value => `${unit.id}` === value.value)
                            if(foundUnit) return true
                        }).map(unit => {
                            return {
                                id: unit.id,
                                platform: unit.deviceConfig.platform,
                                secondaryPlatform: unit.deviceConfig.secondaryPlatform
                            }
                        })
                        setValues(tempSelectedUnits)
                    }}
    
                    searchPlaceholder="Search..."
                    nothingFound="Nothing here"
                    titles={['selected units', 'not selected units']}
                    breakpoint="sm"
                    mt={withCSV ? 'sm' : 'md'}
                />
                {withCSV &&
                <Modal size='90%' opened={opened} onClose={close} title={<Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}` ,fontWeight: 800, fontSize:'1.625rem' })}>Edit Multiple Sim Cards</Text>}>
                    <SelectMultipleUnits  setValuesCSV={(data, setAlert, setSuccess) => {
                        const alert: string[] = []
                        const tempData = unitsList.filter(unit => data.includes(`${unit.id}`))
                        data.forEach(id => {
                            const foundUnit = tempData.find(unit => `${unit.id}` === id)
                            if(!foundUnit) alert.push(`unit with ${id} not found`)
                        })
                        const tempValues = tempData.map(unit => {
                            return {
                                id: unit.id,
                                platform: unit.deviceConfig.platform,
                                secondaryPlatform: unit.deviceConfig.secondaryPlatform
                            }
                        })
                        setValues(tempValues)
                        if(alert.length){
                            setAlert(alert)
                        }else{
                            setAlert(null)
                            setSuccess(true)
                        }
                        
                    }} />
                </Modal>
                }
        </>
    )
}