import {
  ActionIcon,
  Alert,
  Button,
  Code,
  Container,
  createStyles,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Table,
  TextInput,
  Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  TbAlertOctagon,
  TbAlertTriangle,
  TbCircleCheck,
  TbDownload,
  TbPlus
} from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { noEmptyString, URLParams, useCsv } from '../../../utils';
import { IaxiosError } from '../../common';
import { useDevices } from '../../devices';
import { useSimCards } from '../../sim-cards';
import { useVehicles } from '../../vehicles';
import { postUnits, useUnits } from '../api';

const useStyles = createStyles(() => ({
  row: {
    backgroundColor: '#d6151524',
  },
}));

export function AddMultipleUnits() {
  const { classes } = useStyles();
  const [file, setFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();
  const viewSubmit = document.getElementById('submit')
  const viewTopOfPage = document.getElementById('TopOfPage')

  const requirments = {
    device: /^$|^[0-9]+$/,
    simCard: /^$|^[0-9]+$/,
    vehicle: /^$|[A-Za-z0-9]$/,
    platform:/\b(wialon|asateel|securepath|securepath premuim|softwareag|aiflux|custom)\b/,
    secondaryPlatform:/^$|\b(wialon|asateel|securepath|securepath premuim|softwareag|aiflux|custom)\b/,
    externalId: /^$|^[a-zA-Z0-9]*$/,
    rfidList: /^$|^\d+$/,
    remarks: /^$|^[\s\S]*$/,
  };


  const unitParams = new URLParams();
  unitParams.includes(
    'device',
    'simCard',
    'vehicle',
  );
  unitParams.select('id', 'vehicle.id', 'vehicle.vin', 'device.id', 'device.imei', 'simCard.id', 'simCard.number')
  const { data: unitData } = useUnits({params: unitParams.toString()});

  const deviceParams = new URLParams();
  deviceParams.includes('deviceType', 'unit');
  const { data: deviceData } = useDevices({ params: deviceParams.toString() });

  const simCardParams = new URLParams();
  simCardParams.includes('simCardType', 'unit');
  const { data: simCardsData } = useSimCards({params: simCardParams.toString()});

  const { data: vehiclesData } = useVehicles()

  const [duplicatedRows, setDuplicatedRow] = useState<number[]>([]);

  const templateText = `${Object.keys(requirments).join(',')}\r\n`;
  const { csvParser, data, headers, errorRows, validate, setItem, addRow } =
    useCsv(requirments, Object.keys(requirments));

  function handleSelectFile(value: File | null) {
    setFile(value);
    if (value) {
      csvParser(value);
    }
  }

  useEffect(() => {
    if(errorRows.length === 0){
      viewSubmit?.scrollIntoView()
    }
  }, [data])

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postUnits, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Unit, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit() {
    const newData: Record<string, any>[] = JSON.parse(JSON.stringify(data));
    viewTopOfPage?.scrollIntoView();
    const { uniqueDuplicate: duplicatedDevices, dpRowNumberErr1 } =
      findDuplicate(newData, 'device');

    const {
      uniqueDuplicate: duplicatedSimCard,
      dpRowNumberErr1: dpRowNumberErr2,
    } = findDuplicate(newData, 'simCard');

    const RowNumberErr = dpRowNumberErr1.concat(dpRowNumberErr2);
    const keys = ['platform', 'secondaryPlatform', 'externalId', 'rfidList'];


    newData.forEach((item) => {
      let obj = {};
      keys.forEach((key) => {
        obj = Object.assign(obj, { [key]: item[key] });
        delete item[key];
      });
      item = Object.assign(item, { deviceConfig: obj });
    });

    if (newData && unitData && vehiclesData && simCardsData && deviceData) {
      setAlert(null);

      if (duplicatedDevices.length === 0 && duplicatedSimCard.length === 0) {
        const tempAlert:string[] = []
        newData.forEach((item) => {

          ['device','simCard','vehicle'].forEach((key) => {
            if(key === 'device') {
              if(unitData.data.items.some((unit) => unit.device?.imei === item[key]))tempAlert.push('device with imei ' + item[key] + ' alraedy exist')
              else if(item[key])
                if(!deviceData.data.items.some((device) => {
                  if(device.imei === item[key]){
                    item[key] = `${device.id}`
                    return true
                  }
                }))tempAlert.push('device with imei ' + item[key] + ' does not exist in the database')
            }
            if(key === 'simCard') {
              if(unitData.data.items.some((unit) => unit.simCard?.number === item[key]))tempAlert.push('simCard with number ' + item[key] + ' alraedy exist')
              if(item[key])
                if(!simCardsData.data.items.some((sim) => {
                  if(sim.number === item[key]){
                    item[key] = `${sim.id}`
                    return true
                  }
                }))tempAlert.push('sim with number ' + item[key] + ' does not exist in the database')
            }
            if(key === 'vehicle') {
              if(item[key])
                if(!vehiclesData.data.items.some((vehicle) => {
                  if(vehicle.vin === item[key]){
                    item[key] = `${vehicle.id}`
                    return true
                  }
                }))tempAlert.push('vehicle with vin ' + item[key] + ' does not exist in the database')
            }
          })
        })
        if(tempAlert.length === 0){
          const newValues = newData.map((item) => noEmptyString(item))
          newMutate(newValues);
        }else setAlert(tempAlert)
      } else {
        setDuplicatedRow(RowNumberErr);
        setAlert(
          (duplicatedSimCard.length !== 0
            ? duplicatedDevices + ' devices are duplicated '
            : '') +
          (duplicatedSimCard.length !== 0
            ? duplicatedSimCard + ' simCards are duplicated'
            : ''),
        );
      }
    } else {
      setAlert(
        'something went wrong while preparing the data, please reload the page and try again.',
      );
    }
  }

  function findDuplicate(newData: Record<string, any>[], arrKey: string) {
    const dpRowNumberErr1: number[] = [];
    const uniqueArr: string[] = [];
    const duplicatedArr: string[] = [];

    newData.forEach((item, index) => {
      if(item[arrKey]){
        const itemPushed = uniqueArr.includes(item[arrKey]);
        if (!itemPushed) {
          uniqueArr.push(item[arrKey]);
        } else {
          duplicatedArr.push(item[arrKey]);
          dpRowNumberErr1.push(index);
        }
      }
    });
    const uniqueDuplicate = duplicatedArr.filter(
      (value, index, array) => array.indexOf(value) === index,
    );
    return { uniqueDuplicate, dpRowNumberErr1 };
  }

  const rows = data?.map((row, i) => (
    <tr
      key={'r' + i}
      className={duplicatedRows.includes(i) ? classes.row : undefined}
    >
      {headers.map((key, index) => (
        <td key={key + index}>
          <TextInput
            value={row[key]}
            error={errorRows[index]?.includes(i + 1)}
            variant="unstyled"
            onChange={(v) => setItem(i, key, v.target.value)}
          />
        </td>
      ))}
    </tr>
  ));

  return (
    <Layout>
      <Container pt={10} fluid>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add Multiple Units:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <Group position="apart">
            <UploadCSVButton file={file} onChange={handleSelectFile}>
              {(props) => (
                <Button {...props} mt="md">
                  Upload CSV
                </Button>
              )}
            </UploadCSVButton>
            <Button
              mt="md"
              component="a"
              href={`data:text/csv;charset=utf-8,${templateText}`}
              download={'newUnitsTemplate.csv'}
              leftIcon={<TbDownload size={16} />}
            >
              Download a template
            </Button>
          </Group>
          <Alert
            icon={<TbAlertTriangle size={16} />}
            title="Important!"
            withCloseButton
            color="blue"
            mt="md"
          >
            <List id='TopOfPage'>
              <List.Item>
                Currently only accepts CSV files, file must include the headers.
              </List.Item>
              <List.Item>
                device: optional, use imei.
              </List.Item>
              <List.Item>
                simCard: optional, use sim number.
              </List.Item>
              <List.Item>
                vehicle: optional, use vin/chassis.
              </List.Item>
              <List.Item>
                platform: must have one of the following values: (<Code>wialon</Code>,{' '}
                <Code>asateel</Code>, <Code>securepath</Code>,{' '}
                <Code>securepath premuim</Code> <Code>softwareag</Code>,{' '}
                <Code>aiflux</Code>, <Code>custom</Code>).
              </List.Item>
              <List.Item>
                secondaryPlatform: optional, could have one of the following values: (<Code>wialon</Code>,{' '}
                <Code>asateel</Code>, <Code>securepath</Code>,{' '}
                <Code>securepath premuim</Code> <Code>softwareag</Code>,{' '}
                <Code>aiflux</Code>, <Code>custom</Code>).
              </List.Item>
              <List.Item>
                externalId: optional.
              </List.Item>
              <List.Item>rfidList: must be a number.</List.Item>
              <List.Item>remarks: can be empty.</List.Item>
            </List>
          </Alert>
          {data && Object.keys(requirments).length !== headers.length && (
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Warning!"
              color="orange"
              mt="md"
            >
              The number of column uploaded is different than expected!
            </Alert>
          )}
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {validate() && (
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Error!"
              color="red"
              mt="md"
            >
              <List>
                <List.Item>Errors in the following rows:</List.Item>
                {errorRows.map((errorRow, i) => (
                  <List.Item key={`error-${headers[i]}`}>
                    {headers[i]}: {errorRow.join(', ')}
                  </List.Item>
                ))}
              </List>
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
              mt="md"
            >
              Successfully added to the database!
            </Alert>
          )}
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
              mt="md"
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item key={errMsg}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {data && (
            <Table mt="md">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Group position="right" mt="md">
            <ActionIcon size="lg" variant="subtle">
              <TbPlus size={28} onClick={() => addRow()} />
            </ActionIcon>
          </Group>
          <Button
            id="submit"
            fullWidth
            disabled={(!data && !file) || validate() || newLoading}
            onClick={handleSubmit}
            mt="md"
          >
            Submit
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
}
