import api from '../../common/api/api';
import { Task, TaskInput } from '../types/types';

interface myResTaskPatch {
  data: Task;
}

export const patchTask = (props: { id: number; data: Partial<TaskInput> }): Promise<myResTaskPatch> => {
  return api.patch(`tasks/${props.id}`, props.data);
};

export const patchTasks = (data: Record<string, any>[]): Promise<myResTaskPatch[]> => {
	return api.patch('tasks/multi', data);
};

