import {
  ActionIcon,
  Alert,
  Button,
  Container,
  Grid,
  Group,
  List,
  LoadingOverlay,
  Modal,
  Paper,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { useDisclosure } from '@mantine/hooks';
import { useSearchParams } from 'react-router-dom';
import { DetailsCard } from '../../../components/detailsCards';
import { DatePickerInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { useAuthContext } from '../../../context/AuthContext';
import { URLParams, enumToSelectData, noEmptyString, taskTitleFormater } from '../../../utils';
import { IaxiosError, SelectData } from '../../common';
import { NewContactsForm, OrganizationCard, useContacts, useOrganizations } from '../../organizations';
import { useTaskGroups } from '../../task-groups/api';
import { useUnit } from '../../unit';
import { UserRole, useUsers } from '../../users';
import { postTask, useTasks } from '../api';
import { SelectInstallationUnit } from '../components';
import { TaskStatus, TaskType } from '../constants/constants';
import { TaskInput } from '../types/types';
import { ConfirmationModal } from './ConfirmationModal';

type createTask = Omit<TaskInput, 'performedBy' | 'approvedBy' | 'status' | 'jobRemarks'>;

type vehicledetails = {
  plate: string,
  platform: string,
  secondaryPlatform: string | null,
  orgID: number
}
export function NewTaskForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedConfirmationModal, { open: openConfirmationModal, close: closeConfirmationModal }] = useDisclosure(false);
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid');
  const [targetReciever, setTargetReciever] = useState('recipientGroup');
  const [targetContact, setTargetContact] = useState('selectContact');
  const [targetTaskGroup, setTargetTaskGroup] = useState<'addTaskGroup' | 'selectTaskGroup'>('addTaskGroup');
  const [contactsSelect, setContactsSelect] = useState<SelectData>([{ value: '', label: '' }]);
  const [taskGroupsSelect, setTaskGroupsSelect] = useState<SelectData>([{ value: '', label: '' }]);
  const [organizationsSelect, setOrganizationsSelect] = useState([{ value: '', label: '' }]);
  const [organizationId, setOrganizationId] = useState(0);
  const [taskGroupSuggestion, setTaskGroupSuggestion] = useState('');
  const [userSelect, setUserSelect] = useState([{ value: '', label: '' }]);
  const [vehicle, setVehicle] = useState<vehicledetails>({
    plate: '',
    platform: '',
    secondaryPlatform: '',
    orgID: 0,
  });
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const tasksParams = new URLParams();
  tasksParams.includes('vehicle', 'unit');
  const { data: allTasks } = useTasks({ params: tasksParams.toString() });

  const form = useForm<createTask>({
    initialValues: {
      title: '',
      type: searchParams.get('type') ?? '',
      recipient: {
        id: 0,
      },
      recipientGroup: UserRole.Technician,
      organization: {
        id: 0,
      },
      targetDate: new Date(),
      completionDate: null,
      address: '',
      location: '',
      contactName: '',
      contactNumber: '',
      contacts: {
        id: 0,
      },
      vehicle: {
        id: 0,
      },
      unit: {
        id: uid ? +uid : 0,
      },
      installationUnit: {
        id: 0,
      },
      issuedBy: {
        id: 0,
      },
      taskGroup: {
        id: undefined,
        name: undefined,
      },
      taskRemarks: '',
    },
  });

  const { data: unitData } = useUnit(uid ? +uid : 0, {
    joins: ['vehicle', 'deviceConfig', 'vehicle.organization as org'],
    selects: [
      'id',
      'vehicle.id',
      'vehicle.plateNumber',
      'deviceConfig.id',
      'deviceConfig.platform',
      'deviceConfig.secondaryPlatform',
      'org.id',
    ],
    then: (data) => {
      setVehicle({
        plate: `${data.data.vehicle?.plateNumber}`,
        platform: data.data.deviceConfig.platform,
        secondaryPlatform: data.data.deviceConfig.secondaryPlatform,
        orgID: data.data.vehicle?.organization.id ?? 0,
      });
      form.setFieldValue('organization.id', data.data.vehicle?.organization.id);
    },
  });

  useEffect(() => {
    if (unitData) {
      setVehicle({
        plate: `${unitData.data.vehicle?.plateNumber}`,
        platform: unitData.data.deviceConfig.platform,
        secondaryPlatform: unitData.data.deviceConfig.secondaryPlatform,
        orgID: unitData.data.vehicle?.organization.id ?? 0,
      });
      form.setFieldValue(
        'title',
        taskTitleFormater(
          organizationsData?.data.items.filter((org) => org.id === form.values.organization.id)[0]?.abbreviation || '',
          `${unitData.data.vehicle?.plateNumber}`,
          form.values.type,
          userData?.data.items.filter((user) => user.id === form.values.recipient?.id)[0]?.firstName || '',
          unitData.data.deviceConfig.platform,
          unitData.data.deviceConfig.secondaryPlatform || '',
        ),
      );
      form.setFieldValue('organization.id', unitData.data.vehicle?.organization.id);
    }
  }, [unitData]);

  const userParams = new URLParams();
  const { data: userData } = useUsers({ params: userParams.toString() });
  useEffect(() => {
    if (userData) {
      const tempArr = userData.data.items.map((parent) => {
        return {
          value: `${parent.id}`,
          label: `${parent.firstName} ${parent.lastName}`,
        };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '',
          label: 'No users, Please add the user first',
        });
      }
      tempArr.unshift({ value: '', label: 'No Recipient' });
      setUserSelect(tempArr);
    }
  }, [userData]);

  const orgParams = new URLParams();
  orgParams.includes('parent');
  orgParams.select('name', 'id', 'imageRepositoryId', 'abbreviation', 'parent.id', 'parent.name');
  const { data: organizationsData } = useOrganizations({ params: orgParams.toString() });
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((org) => {
        return { value: `${org.id}`, label: `${org.name}, ${org.parent ? org.parent.name : ''}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '',
          label: 'No Clients, Please add the organization first',
        });
      }
      uid &&
        form.setFieldValue(
          'title',
          taskTitleFormater(
            organizationsData?.data.items.filter((org) => org.id === unitData?.data.vehicle.organization.id)[0]?.abbreviation || '',
            `${unitData?.data.vehicle?.plateNumber}`,
            form.values.type,
            userData?.data.items.filter((user) => user.id === form.values.recipient?.id)[0]?.firstName || '',
            unitData?.data.deviceConfig.platform,
            unitData?.data.deviceConfig.secondaryPlatform || '',
          ),
        );
      setOrganizationsSelect(tempArr);
    }
  }, [organizationsData]);

  const { data: contactsData } = useContacts();
  useEffect(() => {
    if (contactsData) {
      const tempArr: SelectData = contactsData.data.items.map((contact) => {
        return { value: `${contact.id}`, label: `${contact.name}, ${contact.phone}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '',
          label: 'No Contacts, Please add the contact first',
          disabled: true,
        });
      }
      setContactsSelect(tempArr);
    }
  }, [contactsData]);

  const { data: taskGroupsData } = useTaskGroups();
  useEffect(() => {
    if (taskGroupsData) {
      const tempArr: SelectData = taskGroupsData.data.items.map((group) => {
        return { value: `${group.id}`, label: `${group.name}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: '0',
          label: 'No Groups, Please add a group first',
          disabled: true,
        });
      }
      setTaskGroupsSelect(tempArr);
    }
  }, [taskGroupsData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postTask, {
    onSuccess: () => {
      setOrganizationId(0);
      setContactsSelect([{ value: '', label: '' }]);
      setAlert(null);
      // form.reset();
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  useEffect(() => {
    console.log('effect running');
    const formattedDate = `${form.values.targetDate?.getDate()}-${form.values.targetDate?.getMonth()}-${form.values.targetDate?.getFullYear()}`;
    setTaskGroupSuggestion(
      `${organizationsData?.data.items.find((org) => org.id === form.values.organization.id)?.abbreviation}-${formattedDate}`,
    );
  }, [form.values.targetDate, form.values.organization.id]);

  function handleSubmit(values: createTask) {
    const newValues = noEmptyString({ ...values });
    if (targetTaskGroup === 'addTaskGroup' && newValues.taskGroup?.id && newValues.taskGroup?.name) newValues.taskGroup.id = undefined;
    if (targetTaskGroup === 'selectTaskGroup' && newValues.taskGroup?.name && newValues.taskGroup?.id) newValues.taskGroup.name = undefined;
    if(newValues.taskGroup?.id === undefined && newValues.taskGroup?.name === undefined) newValues.taskGroup = null;
    if (newValues.unit?.id === 0 || [TaskType.Installation].includes(TaskType[newValues.type as keyof typeof TaskType])) {
      newValues.unit = null;
    }
    if (newValues.recipient?.id === 0) {
      newValues.recipient = null;
    }
    if (newValues.vehicle?.id === 0) {
      newValues.vehicle = null;
    }

    if (newValues.installationUnit?.id === 0) {
      newValues.installationUnit = null;
    }

    if (newValues.contacts?.id === 0) {
      newValues.contacts = null;
    }

    newValues.targetDate = values.targetDate;

    if (newValues.targetDate === null) form.setFieldError('targetDate', 'target date can not be empty');
    if (newValues.type === TaskType.Upgrade && newValues.recipientGroup === UserRole.Technician)
      form.setFieldError('recipientGroup', 'technicians can not recieve upgrade task');

    if (newValues.vehicle !== null || newValues.taskRemarks !== null || newValues.unit !== null)
      if (newValues.recipient !== null || newValues.recipientGroup !== null) {
        if (newValues.targetDate !== null) {
          if (user && allTasks) {
            newValues.issuedBy.id = user.id;
            if (
              (newValues.unit &&
                allTasks.data.items.filter((task) => task.unit?.id === newValues.unit.id && task.status === TaskStatus.Pending)[0])
            ) {
              openConfirmationModal();
            } else {
              if (newValues.targetDate !== null) {
                setAlert(null);
                newMutate(newValues);
                // console.log(newValues);
              }
            }
          }
        }
      } else setAlert('recipient or recipient group should not be empty');
    else setAlert('vehicle or unit or remarks should not be empty');
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new task:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput label="Title" placeholder="Title" disabled={true} {...form.getInputProps('title')} />
            <SelectEditable
              label="Type"
              placeholder="Search here"
              {...form.getInputProps('type')}
              data={enumToSelectData(TaskType)}
              onChange={(v) => {
                v && form.setFieldValue('type', v);
                if (v === 'upgrade') form.setFieldValue('recipientGroup', UserRole.Support);
                form.setFieldValue(
                  'title',
                  taskTitleFormater(
                    organizationsData?.data.items.filter((org) => org.id === form.values.organization.id)[0]?.abbreviation || '',
                    vehicle.plate,
                    v,
                    userData?.data.items.filter((user) => form.values.recipient && user.id === form.values.recipient.id)[0]?.firstName ||
                      '',
                    vehicle.platform,
                    vehicle.secondaryPlatform,
                  ),
                );
              }}
              required
              mt="md"
            />
            <Paper mt="sm" p="md" withBorder>
              <SegmentedControl
                value={targetReciever}
                data={[
                  { label: 'Reciepent', value: 'reciepent' },
                  { label: 'Recipient Group', value: 'recipientGroup' },
                ]}
                onChange={(v) => setTargetReciever(v)}
              />
              {targetReciever === 'reciepent' && (
                <SelectEditable
                  searchable
                  label="Recipient"
                  placeholder="Search here"
                  {...form.getInputProps('recipient.id')}
                  onChange={(v) => {
                    v && form.setFieldValue('recipient.id', parseInt(v));
                    form.setFieldValue(
                      'title',
                      taskTitleFormater(
                        organizationsData?.data.items.filter((org) => org.id === form.values.organization.id)[0]?.abbreviation || '',
                        vehicle.plate,
                        form.values.type,
                        userData?.data.items.filter((user) => v && user.id === parseInt(v))[0]?.firstName || '',
                        vehicle.platform,
                        vehicle.secondaryPlatform,
                      ),
                    );
                  }}
                  value={`${form.values.recipient?.id}`}
                  data={userSelect}
                />
              )}

              {targetReciever === 'recipientGroup' && (
                <SelectEditable
                  searchable
                  label="Recipient Group"
                  placeholder="Search here"
                  {...form.getInputProps('recipientGroup')}
                  data={enumToSelectData(UserRole)}
                />
              )}
            </Paper>

            <TextInputEditable label="Address" placeholder="Search here" {...form.getInputProps('address')} mt="md" />
            <TextInputEditable label="Google Map Link" placeholder="Search here" {...form.getInputProps('location')} mt="md" />
            <DatePickerInputEditable
              placeholder="Search Date"
              label="Target date"
              {...form.getInputProps('targetDate')}
              minDate={new Date()}
              required
              mt="md"
            />
            <DatePickerInputEditable
              placeholder="completion Date"
              label="Completion Date"
              clearable
              {...form.getInputProps('completionDate')}
              mt="md"
            />
            <SelectEditable
              label="Client"
              placeholder="Search here"
              {...form.getInputProps('organization.id')}
              onChange={(v) => {
                v && form.setFieldValue('organization.id', parseInt(v));
                setOrganizationId(parseInt(v || '0'));
                form.setFieldValue(
                  'title',
                  taskTitleFormater(
                    organizationsData?.data.items.filter((org) => v && org.id === parseInt(v))[0]?.abbreviation || '',
                    vehicle.plate,
                    form.values.type,
                    userData?.data.items.filter((user) => form.values.recipient && user.id === form.values.recipient.id)[0]?.firstName ||
                      '',
                    vehicle.platform,
                    vehicle.secondaryPlatform,
                  ),
                );
              }}
              value={`${form.values.organization.id}`}
              searchable
              nothingFound="Not found"
              data={organizationsSelect}
              required
              mt="md"
            />
            <Paper withBorder mt="sm" p="md">
              <SegmentedControl
                value={targetContact}
                data={[
                  { label: 'Add Contact', value: 'addContact' },
                  { label: 'Select Contact', value: 'selectContact' },
                ]}
                onChange={(v) => setTargetContact(v)}
              />

              {targetContact === 'addContact' && (
                <>
                  <TextInputEditable label="Contact Name" placeholder="Contact Name" {...form.getInputProps('contactName')} />
                  <TextInputEditable mt="sm" label="Contact Number" placeholder="Contact Number" {...form.getInputProps('contactNumber')} />
                </>
              )}
              {targetContact === 'selectContact' && (
                <Grid>
                  <Grid.Col span={8}>
                    <SelectEditable
                      label="Contact"
                      placeholder="Search here"
                      {...form.getInputProps('contacts.id')}
                      onChange={(v) => {
                        v && form.setFieldValue('contacts.id', parseInt(v));
                      }}
                      value={`${form.values.contacts?.id}`}
                      searchable
                      nothingFound="Not found"
                      data={contactsSelect}
                    />
                  </Grid.Col>
                  <Grid.Col span={4} mt="xl">
                    <Button fullWidth onClick={open}>
                      Add New Contact
                    </Button>
                  </Grid.Col>
                </Grid>
              )}
            </Paper>
            <SelectInstallationUnit
              type={form.values.type ?? ''}
              value={`${form.values.unit?.id}` ?? ''}
              setValues={(vehicle) => {
                form.setFieldValue('unit.id', parseInt(vehicle.value));
                form.setFieldValue('organization.id', vehicle.orgID);
                // kept the vehicle in order to update vehicle throw approval task may change this process later
                form.values.type === TaskType.Installation && form.setFieldValue('vehicle.id', parseInt(vehicle.vehicleid));
                setVehicle({
                  plate: vehicle.plate,
                  platform: vehicle.platform,
                  secondaryPlatform: vehicle.secondaryplatform,
                  orgID: vehicle.orgID,
                });
                form.setFieldValue(
                  'title',
                  taskTitleFormater(
                    organizationsData?.data.items.filter((org) => org.id === vehicle.orgID)[0]?.abbreviation || '',
                    vehicle.plate,
                    form.values.type,
                    userData?.data.items.filter((user) => user.id === form.values.recipient?.id)[0]?.firstName || '',
                    vehicle?.platform,
                    vehicle?.secondaryplatform,
                  ),
                );
              }}
            />
            <Paper withBorder mt="sm" p="md">
              <SegmentedControl
                value={targetTaskGroup}
                data={[
                  { label: 'Add TaskGroup', value: 'addTaskGroup' },
                  { label: 'Select TaskGroup', value: 'selectTaskGroup' },
                ]}
                onChange={(v: typeof targetTaskGroup) => setTargetTaskGroup(v)}
              />

              {targetTaskGroup === 'addTaskGroup' && (
                <TextInput
                  label="Task Group"
                  placeholder="Task Group"
                  {...form.getInputProps('taskGroup.name')}
                  description={
                    <Group spacing={0}>
                      <Text>{`suggeted name: ${taskGroupSuggestion}`}</Text>
                      <ActionIcon onClick={() => form.setFieldValue('taskGroup.name', taskGroupSuggestion)}>
                        <TbCircleCheck />
                      </ActionIcon>
                    </Group>
                  }
                />
              )}
              {targetTaskGroup === 'selectTaskGroup' && (
                <Select
                  label="Task Group"
                  data={taskGroupsSelect}
                  {...form.getInputProps('taskGroup.id')}
                  value={`${form.values.taskGroup?.id}` || ''}
                  onChange={(v) => {
                    if (v) {
                      form.setFieldValue('taskGroup.id', +v);
                    }
                  }}
                />
              )}
            </Paper>
            <Textarea label="Remarks" placeholder="Remarks" {...form.getInputProps('taskRemarks')} mt="md" />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {organizationId !== 0 && (
            <DetailsCard
              id={organizationId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter((org) => org.id === organizationId)[0]?.imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
      <Modal
        opened={opened}
        size={'60%'}
        onClose={close}
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Add New Contact
          </Text>
        }
      >
        <NewContactsForm orgID={organizationId} />
      </Modal>
      <Modal
        opened={openedConfirmationModal}
        size={'60%'}
        onClose={closeConfirmationModal}
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Add New Contact
          </Text>
        }
      >
        <ConfirmationModal form={form} newMutate={newMutate} targetTaskGroup={targetTaskGroup} />
      </Modal>
    </Layout>
  );
}
