import { createStyles, ScrollArea, Table } from '@mantine/core';
import { IfullUnit } from '../../unit';
import { Ivehicle } from '../../vehicles';
  
  
  const useStyles = createStyles((theme) => ({
    leftSideTable: {
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
  
    header: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  
    },
  }));
  
  export function OrgVehicles({
    units,
    cardHei,
    vehicles,
  }: {
    cardHei: number,
    units?: IfullUnit[],
    vehicles?: Ivehicle[],
  }) {
    const { classes } = useStyles();

    const rows = units ? units?.map((unit) =>{
        return (
          <tr key={unit.id}>
            <td>{unit.vehicle?.vin}</td>
            <td>{unit.vehicle?.plateNumber}</td>
            <td>{unit.deviceConfig?.platform}</td>
            <td>{unit.deviceConfig?.secondaryPlatform}</td>
            <td>{unit.status}</td>
          </tr>
        )
      }) : vehicles?.map((vehicle) =>{
        return (
          <tr key={vehicle.id}>
            <td>{vehicle?.vin}</td>
            <td>{vehicle?.plateNumber}</td>
            <td>Not In Unit</td>
          </tr>
        )
      });

      
    return (
        <ScrollArea h={cardHei-50}>
        <Table p={5} >
        <thead className={classes.header}>
          <tr>
            <th>vin</th>
            <th>plateNumber</th>
            {units ? <th>platform</th> : undefined}
            {units ? <th>secondaryPlatform</th> : undefined}
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        </Table>
        </ScrollArea>    
    );
  }
  