import {
  ActionIcon,
  Affix,
  Card,
  Center,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
  Tooltip,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbBrandGoogleMaps, TbMap, TbPlus } from 'react-icons/tb';
import { SelectEditable } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { ExportToCsvButton } from '../../../components/table/components';
import { DownloadAsCsv, Format, URLParams, enumToSelectData, selectColumns } from '../../../utils';
import { UserStatus, useUsers } from '../../users';
import { useAttendances } from '../api';
import { AttendanceColumnsMode, AttendanceStatus } from '../constants/constants';
import { IattendanceReport } from '../types';
import { useDisclosure } from '@mantine/hooks';
import { MapContainer } from 'react-leaflet';
import { BaseMap } from '../../../components';

export function MonthlyReportForm() {
  const [month, setMonth] = useState<Date>(new Date());
  const [user, setUser] = useState<number>(0);
  const theme = useMantineTheme();

  const [opened, { open, close }] = useDisclosure(false);
  const [coords, setCoords] = useState<[number, number]>([0, 0]);

  function TotalHoursCount(timeIn: string | null, TimeOut: string | null) {
    if (timeIn && TimeOut) {
      const timeInValue = timeIn.split(':');
      const timeOutValue = TimeOut.split(':');
      const BorrowHoursToMin = +timeOutValue[1] < +timeInValue[1];
      const mins = BorrowHoursToMin ? +timeOutValue[1] + 60 - +timeInValue[1] : +timeOutValue[1] - +timeInValue[1];
      if (+timeOutValue[0] < +timeInValue[0]) {
        const hours = BorrowHoursToMin ? 24 + +timeOutValue[0] - +timeInValue[0] - 1 : 24 + +timeOutValue[0] - +timeInValue[0];
        return { hours, mins };
      } else {
        const hours = BorrowHoursToMin ? +timeOutValue[0] - +timeInValue[0] - 1 : +timeOutValue[0] - +timeInValue[0];
        return { hours, mins };
      }
    }
  }

  const params = new URLParams();
  params.includes('user');
  params.sort('day');
  params.filterSetField('=user.id', user);
  params.filterSetField('day', `${month.getFullYear()}-${(month.getMonth() + 1).toString().padStart(2, '0')}`);
  params.select(...selectColumns(AttendanceColumnsMode), 'user.id');
  const { data: allAttendance, isLoading } = useAttendances({ params: params.toString() });
  useEffect(() => {
    if (allAttendance) {
      const temp = Array(new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate())
        .fill(null)
        .map((item, index) => {
          const found = allAttendance.data.items.find(
            (item) =>
              item.day.toString() ===
              `${month.getFullYear()}-${(month.getMonth() + 1).toString().padStart(2, '0')}-${(index + 1).toString().padStart(2, '0')}`,
          );
          const totalHours =
            found && found.timeIn && found.timeOut ? TotalHoursCount(found.timeIn.toString(), found.timeOut.toString()) : undefined;
          return {
            id: found ? found.id : null,
            day: found ? new Date(found.day).toDateString() : new Date(month.getFullYear(), month.getMonth(), index + 1).toDateString(),
            timeIn: found && found.timeIn ? found.timeIn.toString() : null,
            timeInSubmittedAt: found
              ? `${new Date(found.timeInSubmittedAt).toDateString()} ${new Date(found.timeInSubmittedAt).toLocaleTimeString()}`
              : null,
            timeOut: found && found.timeOut ? found.timeOut.toString() : null,
            timeOutSubmittedAt:
              found && found.timeOutSubmittedAt
                ? `${new Date(found.timeOutSubmittedAt).toDateString()} ${new Date(found.timeOutSubmittedAt).toLocaleTimeString()}`
                : null,
            TotalHours: totalHours ? `${totalHours.hours} Hours ${totalHours.mins} Min` : undefined,
            status: found ? found.status : AttendanceStatus.Rest_Day,
            remarks: '',
            timeInLatitude: found ? found.timeInLatitude : null,
            timeInLongitude: found ? found.timeInLongitude : null,
            timeOutLatitude: found ? found.timeOutLatitude : null,
            timeOutLongitude: found ? found.timeOutLongitude : null,
            timeInGoogleMap:
              found && found.timeInLatitude && found.timeInLongitude
                ? `"https://www.google.com/maps?q=${found.timeInLatitude},${found.timeInLongitude}"`
                : null,
            timeOutGoogleMap:
              found && found.timeOutLatitude && found.timeOutLongitude
                ? `"https://www.google.com/maps?q=${found.timeOutLatitude},${found.timeOutLongitude}"`
                : null,
          };
        });
      form.setValues(temp);
    }
  }, [allAttendance]);
  const [userSelect, setUsersSelect] = useState([{ value: '', label: '' }]);

  const userParams = new URLParams();
  userParams.includes('profiles');
  userParams.filterSetField('status', UserStatus.Active);
  const { data: usersData } = useUsers({ params: userParams.toString() });
  useEffect(() => {
    if (usersData) {
      const tempArr = usersData.data.items.map((user) => {
        return { value: `${user.id}`, label: `${user.firstName} ${user.lastName}` };
      });
      setUsersSelect(tempArr);
    }
  }, [usersData]);

  const form = useForm<IattendanceReport[]>({
    initialValues: [],
  });

  function simpleTimeFormat(time: string) {
    const displayTime = time.split(':');
    return +displayTime[0] > 12 ? `${+displayTime[0] - 12}:${displayTime[1]} PM` : `${+displayTime[0]}:${displayTime[1]} AM`;
  }

  const rows = Object.values(form.values).map((value, index) => {
    const TotalHours = TotalHoursCount(value.timeIn, value.timeOut);
    return (
      <Container key={index} my="md">
        <Card withBorder>
          <Text w={'100%'} align="center" fz="lg" fw={700}>
            {value.day}
          </Text>
          <Grid align="center" mt="md">
            {value.timeIn && (
              <>
                <Grid.Col span={6}>
                  <Stack align="center">
                    <Text>Time in: {simpleTimeFormat(value.timeIn)}</Text>
                    <Text fz="xs">System Time in: {value.timeInSubmittedAt}</Text>
                  </Stack>
                </Grid.Col>
                <Grid.Col span={6}>
                  {value.timeOut && (
                    <Stack align="center">
                      <Text>Time Out: {simpleTimeFormat(value.timeOut)}</Text>
                      <Text fz="xs">System Time Out: {value.timeOutSubmittedAt}</Text>
                    </Stack>
                  )}
                </Grid.Col>
              </>
            )}
            <Grid.Col span={5}>
              <Card withBorder>
                <Group position="apart">
                  <Group>
                    <Text fz="sm" color="dimmed">
                      Total Working Hours:
                    </Text>
                    {TotalHours && (
                      <Text fz="sm" c={TotalHours.hours >= 8 ? theme.colors.lime[4] : 'red'}>
                        {TotalHours.hours} Hours {TotalHours.mins} Min
                      </Text>
                    )}
                    {!TotalHours && (
                      <Text fz="sm" c={value.status !== AttendanceStatus.Rest_Day ? 'red' : undefined}>
                        No Time In Or Time Out
                      </Text>
                    )}
                  </Group>
                  <Tooltip label="add to remarks" withinPortal>
                    <ActionIcon
                      onClick={() => {
                        const massage = TotalHours ? `${TotalHours.hours} Hours ${TotalHours.mins} Min` : 'No Time In Or Time Out';
                        form.setFieldValue(`${index}.remarks`, massage);
                      }}
                    >
                      <TbPlus />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </Card>
            </Grid.Col>
            <Grid.Col span={1}>
              {value.timeInLatitude && value.timeInLongitude ? (
                <Group noWrap spacing={0}>
                  <ActionIcon
                    onClick={() => {
                      open();
                      if (value.timeInLatitude && value.timeInLongitude) setCoords([+value.timeInLatitude, +value.timeInLongitude]);
                    }}
                  >
                    <TbMap />
                  </ActionIcon>
                  <ActionIcon
                    component="a"
                    href={'https://www.google.com/maps?q='.concat([value.timeInLatitude, value.timeInLongitude].join())}
                    target="_blank"
                  >
                    <TbBrandGoogleMaps />
                  </ActionIcon>
                </Group>
              ) : (
                <Text fz="sm">No Location</Text>
              )}
            </Grid.Col>
            <Grid.Col span={5}>
              <Select
                withinPortal
                placeholder="Status"
                {...form.getInputProps(`${index}.status`)}
                data={enumToSelectData(AttendanceStatus)}
                styles={() => ({
                  input: {
                    color: form.values[index].status === AttendanceStatus.Present ? 'lime' : 'red',
                  },
                })}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              {value.timeOutLatitude && value.timeOutLongitude ? (
                <Group noWrap spacing={0}>
                  <ActionIcon
                    onClick={() => {
                      open();
                      if (value.timeOutLatitude && value.timeOutLongitude) setCoords([+value.timeOutLatitude, +value.timeOutLongitude]);
                    }}
                  >
                    <TbMap />
                  </ActionIcon>
                  <ActionIcon
                    component="a"
                    href={'https://www.google.com/maps?q='.concat([value.timeOutLatitude, value.timeOutLongitude].join())}
                    target="_blank"
                  >
                    <TbBrandGoogleMaps />
                  </ActionIcon>
                </Group>
              ) : (
                <Text fz="sm">No Location</Text>
              )}
            </Grid.Col>
            {
              //     (timeIn || systemTimeIn || timeOut || systemTimeOut) &&
              //     <Grid.Col>
              //     <SimpleGrid mt='md' cols={4}>
              //         {
              //             timeIn &&
              //             <Card withBorder>
              //                 <Stack>
              //                     <Text size="xs" color="dimmed">Morning:</Text>
              //                     <Group position="apart">
              //                         <Text weight={500} size="sm" c={timeIn && +timeIn > 15 ? 'red': undefined}>{+timeIn >= 0 ? `${timeIn} Min Late`: `${+timeIn* (-1)} Min Early`}</Text>
              //                         <Tooltip label='add to remarks'>
              //                             <ActionIcon
              //                             onClick={() => {
              //                                 if(!form.values[index].remarks.includes(`Morning: ${timeIn} Min Late`) && !form.values[index].remarks.includes(`Morning: ${timeIn} Min Early`))
              //                                 form.setFieldValue(`${index}.remarks`, form.values[index].remarks + ` ${+timeIn >= 0 ? `Morning: ${timeIn} Min Late`: `Morning: ${+timeIn* (-1)} Min Early`}`)}}
              //                             ><TbPlus />
              //                             </ActionIcon>
              //                         </Tooltip>
              //                     </Group>
              //                 </Stack>
              //             </Card>
              //         }
              //         {
              //             systemTimeIn &&
              //             <Card withBorder>
              //                 <Stack>
              //                     <Text size="xs" color="dimmed">Time In Difference:</Text>
              //                     <Group position="apart">
              //                         <Text weight={500} size="sm" c={systemTimeIn && (+systemTimeIn > 15 || +systemTimeIn < -15) ? 'red': undefined}>{+systemTimeIn >= 0 ? `${systemTimeIn} Min Late`: `${+systemTimeIn* (-1)} Min Early`}</Text>
              //                         <Tooltip label='add to remarks'>
              //                             <ActionIcon
              //                             onClick={() => {
              //                                 if(!form.values[index].remarks.includes(`Time In Difference: ${systemTimeIn} Min Late`) && !form.values[index].remarks.includes(`Time In Difference: ${systemTimeIn} Min Early`))
              //                                 form.setFieldValue(`${index}.remarks`, form.values[index].remarks + ` ${+systemTimeIn >= 0 ? `Time In Difference: ${systemTimeIn} Min Late`: `Time In Difference: ${+systemTimeIn* (-1)} Min Early`}`)}}
              //                             ><TbPlus />
              //                             </ActionIcon>
              //                         </Tooltip>
              //                     </Group>
              //                 </Stack>
              //             </Card>
              //         }
              //         {
              //             timeOut &&
              //             <Card withBorder>
              //                 <Stack>
              //                     <Text size="xs" color="dimmed">Evening:</Text>
              //                     <Group position="apart">
              //                         <Text weight={500} size="sm" c={timeOut && +timeOut > 15 ? 'red': undefined}>{+timeOut >= 0 ? `${timeOut} Min Early`: `${+timeOut* (-1)} Min Late`}</Text>
              //                         <Tooltip label='add to remarks'>
              //                             <ActionIcon
              //                             onClick={() => {
              //                                 if(!form.values[index].remarks.includes(`Evening: ${timeOut} Min Late`) && !form.values[index].remarks.includes(`Evening: ${timeOut} Min Early`))
              //                                 form.setFieldValue(`${index}.remarks`, form.values[index].remarks + ` ${+timeOut >= 0 ? `Evening: ${timeOut} Min Early`: `Evening: ${+timeOut* (-1)} Min Late`}`)}}
              //                             ><TbPlus />
              //                             </ActionIcon>
              //                         </Tooltip>
              //                     </Group>
              //                 </Stack>
              //             </Card>
              //         }
              //         {
              //             systemTimeOut &&
              //             <Card withBorder>
              //                 <Stack>
              //                     <Text size="xs" color="dimmed">Time Out Difference:</Text>
              //                     <Group position="apart">
              //                         <Text weight={500} size="sm" c={systemTimeOut && (+systemTimeOut > 15 || +systemTimeOut < -15) ? 'red': undefined}>{+systemTimeOut >= 0 ? `${systemTimeOut} Min Early`: `${+systemTimeOut* (-1)} Min Late`}</Text>
              //                         <Tooltip label='add to remarks'>
              //                             <ActionIcon
              //                             onClick={() => {
              //                                 if(!form.values[index].remarks.includes(`Time Out Difference: ${systemTimeOut} Min Late`) && !form.values[index].remarks.includes(`Time Out Difference: ${systemTimeOut} Min Early`))
              //                                 form.setFieldValue(`${index}.remarks`, form.values[index].remarks + ` ${+systemTimeOut >= 0 ? `Time Out Difference: ${systemTimeOut} Min Early`: `Time Out Difference: ${+systemTimeOut* (-1)} Min Late`}`)}}
              //                             ><TbPlus />
              //                             </ActionIcon>
              //                         </Tooltip>
              //                     </Group>
              //                 </Stack>
              //             </Card>
              //         }
              //     </SimpleGrid>
              // </Grid.Col>
            }
          </Grid>
          <TextInput mt="md" label="Remarks" {...form.getInputProps(`${index}.remarks`)} />
        </Card>
      </Container>
    );
  });

  return (
    <Layout>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <Group>
        <SelectEditable
          searchable
          label="Recipient"
          placeholder="Search here"
          value={`${user}`}
          onChange={(v) => {
            v && setUser(parseInt(v));
          }}
          data={userSelect}
          required
          withinPortal
        />
        <MonthPickerInput
          label="Pick Month"
          placeholder="Pick Month"
          value={month}
          onChange={(v) => v && setMonth(v)}
          popoverProps={{ withinPortal: true }}
        />
      </Group>
      {user !== 0 ? (
        rows
      ) : (
        <Center h={'300px'}>
          <Text fz="30px" weight={500}>
            Select a User
          </Text>
        </Center>
      )}
      {user !== 0 ? (
        <Affix position={{ bottom: rem(20), right: rem(20) }}>
          {Object.values(form.values)[0] && (
            <ExportToCsvButton
              keys={[...selectColumns(AttendanceColumnsMode), 'TotalHours', 'timeInGoogleMap', 'timeOutGoogleMap']}
              onSubmit={(v) => {
                DownloadAsCsv<IattendanceReport>(
                  Object.values(form.values),
                  v.fileName,
                  Object.values(v.columns).reduce((cols, item) => {
                    if (item.checked) cols.push({ path: item.path, alias: item.alias });
                    return cols;
                  }, [] as Format[]),
                );
              }}
            />
          )}
        </Affix>
      ) : undefined}
      <Modal opened={opened} onClose={close}>
        <MapContainer center={coords} style={{ height: 450 }}>
          <BaseMap coords={coords} marker />
        </MapContainer>
      </Modal>
    </Layout>
  );
}
