import { Code, Collapse, Group, LoadingOverlay, Paper, ScrollArea, Text, Timeline, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbChevronDown } from 'react-icons/tb';
import { types } from '../../../constants';
import { URLParams, useDisclosureList } from '../../../utils';
import { useDevices } from '../../devices';
import { useSimCards } from '../../sim-cards';
import { useUsers } from '../../users';
import { useVehicles } from '../../vehicles';
import { useHistories } from '../api';
import { Ihistory } from '../types/types';

// const genericSomething = (text: string) => {
//    const colorLookup

//    return text.split(".").map(item => (item).toUpperCase()).join(" ")
// }

export function HistoryCard({
  historySearch,
  cardHei,
}: {
  historySearch: {
    id: number;
    type: types;
  }[];
  cardHei: number;
}) {
  const { data: deviceData } = useDevices();
  const { data: usersData } = useUsers();
  const { data: simCardsData } = useSimCards();
  const { data: vehiclesData } = useVehicles();
  const [hitsories, setHistories] = useState<Ihistory[]>([]);
  const [opened, { toggle }] = useDisclosureList(Array(hitsories.length).fill(false));
  const params = new URLParams('|');
  params.sort('-history.id');
  params.includes('performedBy');
  historySearch.forEach((item, index) => {
    params.filterSetLevel(index, '&');
    if (item.type !== 'all') {
      params.filterSetField('=type', item.type, index);
    } else {
      params.filterSetField('type', '');
    }
    if (item.id !== 0) params.filterSetField('=typeId', `${item.id}`, index);
  });
  params.page(200);
  const { isLoading: isHistoriesLoading, data: historiesData } = useHistories(
    historySearch.map((item) => item.type),
    {
      params: params.toString(),
      entityId: historySearch.map((item) => item.id),
    },
  );

  useEffect(() => {
    if (historiesData) {
      const temp = historiesData.map((item) => item).reverse();
      setHistories(temp);
    }
  }, [historiesData]);
  function discriptionFormatter(id: number, disc: string | null) {
    if (disc === null) return 'No more information about this item';
    const discJson: [Record<string, string | number | { id: number }>, Record<string, string | number | { id: number }>] = JSON.parse(disc);
    const prev = discJson[0];
    const curr = discJson[1];
    return Object.keys(prev).map((key) => {
      let prevValue = prev[key];
      let currVal = curr[key];
      if (key === 'device') {
        const prevDevice = deviceData?.data.items.find((item) => prev[key] === item.id);
        if (prevDevice) prevValue = prevDevice.imei;
        const currDevice = deviceData?.data.items.find((item) => curr[key] === item.id);
        if (currDevice) currVal = currDevice.imei;
      }
      if (key === 'vehicle') {
        const prevVehicle = vehiclesData?.data.items.find((item) => prev[key] === item.id);
        if (prevVehicle) prevValue = prevVehicle.vin;
        const currVehicle = vehiclesData?.data.items.find((item) => curr[key] === item.id);
        if (currVehicle) currVal = currVehicle.vin;
      }
      if (key === 'simCard') {
        const prevSim = simCardsData?.data.items.find((item) => prev[key] === item.id);
        if (prevSim) prevValue = prevSim.number;
        const currSim = simCardsData?.data.items.find((item) => curr[key] === item.id);
        if (currSim) currVal = currSim.number;
      }
      if (key === 'performedBy' || key === 'issuedBy' || key === 'recipient') {
        const prevUser = usersData?.data.items.find((item) => prev[key] === item.id);
        if (prevUser) prevValue = prevUser.firstName ? prevUser.firstName : prevUser.lastName ? prevUser.lastName : prevUser.id;
        const currUser = usersData?.data.items.find((item) => curr[key] === item.id);
        if (currUser) currVal = currUser.firstName ? currUser.firstName : currUser.lastName ? currUser.lastName : currUser.id;
      }
      return (
        <Group position="left" key={id + key}>
          <Code>{key}: </Code>
          <Code td="line-through">{prevValue as string}</Code> to <Code>{`${currVal}`}</Code>
        </Group>
      );
    });
  }

  //TODO: pagination should be implemented from backend
  const bullets = hitsories.map((item, index) => {
    return (
      <Timeline.Item
        key={item.id}
        title={
          <Text fw={600} color={'lime.6'}>
            {historySearch.length === 1 && historySearch[0].type === 'all'
              ? `${item.alias.split('.').reverse().join(' ')} id: ${item.typeId}`
              : item.alias.split('.')[1]}
          </Text>
        }
        bulletSize={24}
      >
        {item.performedBy && (
          <Text color="dimmed" size="sm">
            Performed By: {item.performedBy.username}
          </Text>
        )}
        <Text color="dimmed" size="sm">
          {new Date(item.createdAt).toDateString()}
        </Text>
        <Group onClick={() => toggle(index)} mb="sm" position="left">
          <Text fw={500}>{item.shortDescription}</Text>
          <TbChevronDown size={16} />
        </Group>

        <Collapse in={opened[index]}>{discriptionFormatter(item.id, item.longDescription)}</Collapse>
      </Timeline.Item>
    );
  });

  return (
    <Paper h={cardHei} px={10} withBorder radius="md">
      <Title order={3} py={10} style={{ fontWeight: 700 }}>
        History
      </Title>
      <ScrollArea style={{ height: cardHei - 60 }} pos="relative">
        <LoadingOverlay visible={isHistoriesLoading} />
        {hitsories.length === 0 && (
          <Text color="dimmed" size="sm">
            No history found for this item
          </Text>
        )}
        {hitsories.length !== 0 && (
          <Timeline ml={5} my={5} active={0}>
            {bullets}
          </Timeline>
        )}
      </ScrollArea>
    </Paper>
  );
}
