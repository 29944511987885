export enum SimStatus {
	Available = "available",
	Ready = "ready",
	InUse = "in use",
	Broken = "broken",
	Lost = "lost",
	Deactivated = "deactivated",
}

export enum SimCardColumnsMode {
    ID = "id",
    Number = "number",
    Serial = "serial",
	IMSI = "imsi",
    Status = "status",
    PurchaseDate = "purchaseDate",
	Remarks = "remarks",
}

  
  