
import Layout from '../../../components/layout/Layout';
import { UpdateVehicleForm } from '../components/UpdateVehicleForm';

export function UpdateVehicleFormPage() {

  return (
    <Layout>
      <UpdateVehicleForm  idM={0}/>
    </Layout>
  );
}
