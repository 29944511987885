import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IAccessories } from '../types';


interface myResAccessories {
  data: {
    items: IAccessories[];
    meta: metaData,
  };
}

export const getAccessories = (query = ''): Promise<myResAccessories> => {
  return api.get(`accessories${query}`);
};

export const useAccessories = (options?: { params?: string; then?: (x: myResAccessories) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Accessories, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getAccessories(options?.params).then((accessories) => {
      options && options.then && options.then(accessories);
      return accessories;
    }),
  );
  return query;
};

interface myResCountAccessoriesNumber {
  data: number;
}

export const countAccessories = (query = ''): Promise<myResCountAccessoriesNumber> => {
  return api.get(`accessories/count${query}`);
};

export const useCountAccessories = (options?: { params?: string; then?: (x: myResCountAccessoriesNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Accessories, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countAccessories(options?.params).then((accessories) => {
      options && options.then && options.then(accessories);
      return accessories;
    }),
  );
  return query;
};


