import { ActionIcon, Alert, Button, Code, Container, Group, List, LoadingOverlay, Paper, Table, TextInput, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbAlertTriangle, TbCircleCheck, TbDownload, TbPlus } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { duplicatesFinder, handleDates, noEmptyString, useCsv } from '../../../utils';
import { IaxiosError } from '../../common';
import { useOrganizations } from '../../organizations';
import { Task, TaskType, postTasks } from '../../tasks';
import { Iunit, deviceConfig, postUnits } from '../../unit';
import { UserRole } from '../../users';
import { postVehicles, useVehicles } from '../api';
import { Ivehicle } from '../types/types';

export function AddMultipleVehicles() {
  const [file, setFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [duplicateAlert, setDuplicateAlert] = useState<string[] | null>(null);
  const queryClient = useQueryClient();
  const [vehicles, setVehicles] = useState<Ivehicle[]>([]);
  const [taskGroupSuggestion, setTaskGroupSuggestion] = useState('');
  const viewSubmit = document.getElementById('submit');
  const viewTopOfPage = document.getElementById('TopOfPage');

  const { data: vehiclesData } = useVehicles();
  useEffect(() => {
    if (vehiclesData) setVehicles(vehiclesData.data.items);
  }, [vehiclesData]);

  const requirments = {
    vin: /^[A-Za-z0-9]{4,17}$/,
    category: /^$|[A-Za-z0-9]{0,2}$/,
    placeOfIssue: /^$|\b(AUH|AJM|DXB|FUJ|RAK|SHJ|UAQ)\b/,
    plateNumber: /^$|^([0-9]{4,5})$/,
    vehicleType: /^\d+$/,
    organization: /^\d+$/,
    odometerKM: /^$|^\d+$/,
    odometerHours: /^$|^\d+$/,
    licenseExpiryDate: /^$|^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    licenseIssueDate: /^$|^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    color: /^$|[A-Za-z]*$/,
    platform: /^$|\b(wialon|asateel|securepath|securepath premuim|softwareag|aiflux|custom)\b/,
    secondaryPlatform: /^$|\b(wialon|asateel|securepath|securepath premuim|softwareag|aiflux|custom)\b/,
    targetDate: /^$|^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    taskGroup: /^$|^[\s\S]*$/,
  };
  const templateText = `${Object.keys(requirments).join(',')}\r\n`;

  const { csvParser, data, headers, errorRows, validate, setItem, addRow, removeRow } = useCsv(requirments, Object.keys(requirments));

  function handleSelectFile(value: File | null) {
    setFile(value);
    if (value) {
      csvParser(value);
    }
  }

  useEffect(() => {
    if (errorRows.length === 0) {
      viewSubmit?.scrollIntoView();
    }
  }, [data]);

  const { data: OrgsData } = useOrganizations();

  useEffect(() => {
    console.log('effect running');
    const formattedDate = data ? data[0].targetDate.split('/').join('-') : '';
    if (data)
      setTaskGroupSuggestion(`${OrgsData?.data.items.find((org) => org.id === +data[0].organization)?.abbreviation}-${formattedDate}`);
  }, [data]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postVehicles, {
    onSuccess: (data) => {
      const newUnits = data.data
        .filter((vehicleData) => {
          const datawithUnits = vehicleData as unknown as Ivehicle & deviceConfig;
          if (datawithUnits.platform) return true;
        })
        .map((vehicleData) => {
          return {
            device: null,
            simCard: null,
            vehicle: vehicleData.id,
            deviceConfig: {
              platform: (vehicleData as unknown as Ivehicle & deviceConfig).platform,
              secondaryPlatform: (vehicleData as unknown as Ivehicle & deviceConfig).secondaryPlatform ?? null,
            },
            targetDate: (vehicleData as unknown as Ivehicle & deviceConfig & { targetDate: string }).targetDate ?? null,
            taskGroup: (vehicleData as unknown as Ivehicle & deviceConfig & { taskGroup: { name: string } }).taskGroup ?? null,
            plateNumber: vehicleData.plateNumber,
            organization: vehicleData.organization,
            platform: (vehicleData as unknown as Ivehicle & deviceConfig).platform,
            secondaryPlatform: (vehicleData as unknown as Ivehicle & deviceConfig).secondaryPlatform ?? null,
          };
        });
      if (newUnits.length) {
        newMutateUnits(newUnits);
      }
      setAlert(null);
      queryClient.invalidateQueries([types.Vehicle, types.ALL], {
        exact: true,
      });
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  const { mutate: newMutateUnits, isLoading: newLoadingUnits } = useMutation(postUnits, {
    onSuccess: (data) => {
      const newTasks = data.data
        .filter((unitData) => {
          const datawithUnits = unitData as unknown as Iunit & Task;
          if (datawithUnits.targetDate) return true;
        })
        .map((unitsData) => {
          const taskfromUnit = unitsData as unknown as Iunit &
            Task & { plateNumber: number; targetDate: string; organization: string; platform: string; secondaryPlatform: string };
          const title = `${taskfromUnit.plateNumber} installation - ${taskfromUnit.organization.abbreviation ?? taskfromUnit.organization.name} - ${taskfromUnit.platform}${
            taskfromUnit.secondaryPlatform ? ' - ' + taskfromUnit.secondaryPlatform : ''
          }`;
          return {
            title: title,
            unit: unitsData.id,
            type: TaskType.Installation,
            targetDate: handleDates(taskfromUnit.targetDate),
            taskGroup: taskfromUnit.taskGroup,
            recipientGroup: UserRole.Technician,
            organization: { id: +taskfromUnit.organization },
          };
        });
      newMutateTasks(newTasks);
      setAlert(null);
      queryClient.invalidateQueries([types.Unit, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  const { mutate: newMutateTasks, isLoading: newLoadingTasks } = useMutation(postTasks, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(data: Record<string, any>[] | null) {
    setAlert(null);
    setDuplicateAlert(null);
    viewTopOfPage?.scrollIntoView();
    if (data) {
      const duplicates = duplicatesFinder(vehicles, data, 'vin', 'vin');
      if (duplicates.length === 0) {
        const newValues = data.map((item) => {
          if (item.plateNumber) {
            item.plateNumber = +item.plateNumber;
          }
          if (item.odometerKM) {
            item.odometerKM = +item.odometerKM;
          }
          if (item.odometerHours) {
            item.odometerHours = +item.odometerHours;
          }
          if (item.licenseIssueDate) item.licenseIssueDate = handleDates(item.licenseIssueDate);
          if (item.licenseExpiryDate) item.licenseExpiryDate = handleDates(item.licenseExpiryDate);
          return noEmptyString({ ...item, taskGroup: item.taskGroup ? { name: item.taskGroup } : null });
        });

        newMutate(newValues);
      } else {
        setDuplicateAlert(duplicates);
      }
    } else {
      setAlert('something went wrong while preparing the data, please reload the page and try again.');
    }
  }

  const rows = data?.map((row, i) => (
    <tr key={'r' + i}>
      {headers.map((key, index) => (
        <td key={key + index}>
          <TextInput
            value={row[key]}
            error={errorRows[index]?.includes(i + 1)}
            variant="unstyled"
            onChange={(v) => setItem(i, key, v.target.value)}
          />
        </td>
      ))}
    </tr>
  ));

  return (
    <Layout>
      <Container pt={10} fluid>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add Multiple Vehicles:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <Group position="apart">
            <UploadCSVButton file={file} onChange={handleSelectFile}>
              {(props) => (
                <Button {...props} mt="md">
                  Upload CSV
                </Button>
              )}
            </UploadCSVButton>
            <Button
              mt="md"
              component="a"
              href={`data:text/csv;charset=utf-8,${templateText}`}
              download={'newVehiclesTemplate.csv'}
              leftIcon={<TbDownload size={16} />}
            >
              Download a template
            </Button>
          </Group>
          <Alert icon={<TbAlertTriangle size={16} />} title="Important!" withCloseButton color="blue" mt="md">
            <List id={'TopOfPage'}>
              <List.Item>Currently only accepts CSV files, file must include the headers.</List.Item>
              <List.Item>vin: must be 17 characters and NOT empty.</List.Item>
              <List.Item>category: up to 2 characters.</List.Item>
              <List.Item>plateNumber: must be numbers only up to 5.</List.Item>
              <List.Item>
                placeOfIssue: must be one of these values(<Code>AUH</Code>,<Code>AJM</Code>, <Code>DXB</Code>, <Code>FUJ</Code>,
                <Code>RAK</Code>, <Code>SHJ</Code>, <Code>UAQ</Code>).
              </List.Item>
              <List.Item>vehicleType: the id number of the vehicle type, must be registered in the system.</List.Item>
              <List.Item>organization: the id number of the organization (client), must be registered in the system.</List.Item>
              <List.Item>odometerKM: must be numbers only.</List.Item>
              <List.Item>odometerHours: must be numbers only.</List.Item>
              <List.Item>licenseIssueDate: can be empty and its format is dd/mm/yyyy.</List.Item>
              <List.Item>licenseExpiryDate: can be empty and its format is dd/mm/yyyy.</List.Item>
              <List.Item>color: a written color.</List.Item>
              <List.Item>platform: unit main platform.</List.Item>
              <List.Item>secondaryPlatform: unit secondary platform.</List.Item>
              <List.Item>targetDate: format is dd/mm/yyyy.</List.Item>
              <List.Item>
                <Group spacing={0}>
                  taskGroup: optional. suggested name: {taskGroupSuggestion}{' '}
                  <ActionIcon
                    onClick={() => {
                      if (data) {
                        data.forEach((_, i) => {
                          setItem(i, 'taskGroup', taskGroupSuggestion);
                        });
                      }
                    }}
                  >
                    <TbCircleCheck />
                  </ActionIcon>
                </Group>
              </List.Item>
            </List>
          </Alert>
          {data && Object.keys(requirments).length !== headers.length && (
            <Alert icon={<TbAlertTriangle size={16} />} title="Warning!" color="orange" mt="md">
              The number of column uploaded is different than expected!
            </Alert>
          )}
          <LoadingOverlay visible={newLoading || newLoadingUnits || newLoadingTasks} overlayBlur={2} />
          {validate() && (
            <Alert icon={<TbAlertTriangle size={16} />} title="Error!" color="red" mt="md">
              <List>
                <List.Item>Errors in the following rows:</List.Item>
                {errorRows.map((errorRow, i) => (
                  <List.Item key={`error-${headers[i]}`}>
                    {headers[i]}: {errorRow.join(', ')}
                  </List.Item>
                ))}
              </List>
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10} mt="md">
              Successfully added to the database!
            </Alert>
          )}
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10} mt="md">
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item key={errMsg}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {duplicateAlert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10} mt="md">
              <Group position="apart" align="center">
                {Array.isArray(duplicateAlert) ? (
                  <List>
                    {duplicateAlert.map((errMsg) => (
                      <List.Item key={errMsg}>vehicle with {errMsg} already exist in System!</List.Item>
                    ))}
                  </List>
                ) : (
                  duplicateAlert + '!'
                )}
                <Button
                  onClick={() => {
                    duplicateAlert.forEach((element) => {
                      removeRow('vin', element);
                    });
                    setDuplicateAlert(null);
                  }}
                >
                  Remove Duplicates
                </Button>
              </Group>
            </Alert>
          )}
          {data && (
            <Table striped highlightOnHover mt="md">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Group position="right" mt="md">
            <ActionIcon size="lg" variant="subtle">
              <TbPlus size={28} onClick={() => addRow()} />
            </ActionIcon>
          </Group>
          <Button id="submit" fullWidth disabled={!data || validate() || newLoading} onClick={() => handleSubmit(data)} mt="md">
            Submit
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
}
