import {
  Alert,
  Button,
  Container,
  List,
  LoadingOverlay,
  Paper,
  Select,
  Table,
  TextInput
} from '@mantine/core';
import { useEffect, useState } from 'react';
  
  import { useForm } from '@mantine/form';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { DatePickerInputEditable, SelectEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, enumToSelectData, selectColumns } from '../../../utils';
import { IaxiosError } from '../../common';
import { useDeviceTypes } from '../../device-types';
import { useOrganizations } from '../../organizations';
import { useUsers } from '../../users';
import { patchDevices, useDevices } from '../api';
import { DeviceColumnsMode, DeviceOwnership, DeviceStatus } from '../constants/constants';
import { IdeviceInputEditMultiple } from '../types/types';
  
  export function EditMultipleDevices({ids}:{ids:number[]}) {
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const params = new URLParams()
    params.includes('deviceType', 'issuedTo', 'organization')
    params.searchMany('id', ids.map(id => `${id}`))
    params.select(...selectColumns(DeviceColumnsMode), 'deviceType.id', 'issuedTo.id', 'organization.id', 'organization.name')
    const {data:allDevices } = useDevices({ params: params.toString() });
    useEffect(() => {
        if (allDevices) {
            const temp = allDevices.data.items.map(item => {
                const obj =  Object.assign({},{id: item.id, imei: item.imei, status: item.status, ownership: item.ownership, purchaseDate: item.purchaseDate? new Date(item.purchaseDate) : null, deviceType: item.deviceType.id, issuedTo: item.issuedTo !== null ? item.issuedTo.id : 0, organization: item.organization !== null ? item.organization.id : 0})
                return obj
            })
            form.setValues(temp)
        }
    }, [allDevices]);

    const [userSelect, setUserSelect] = useState([{ value: '', label: '' }]);
    const userParams = new URLParams();
    userParams.select('id', 'firstName', 'lastName');
    const { data: userData } = useUsers({ params: userParams.toString() });
    useEffect(() => {
      if (userData) {
        const tempArr = userData.data.items.map((user) => {
          return {
            value: `${user.id}`,
            label: `${user.firstName} ${user.lastName}`,
          };
        });
        if (tempArr.length === 0) {
          tempArr.push({
            value: '',
            label: 'No users, Please add the user first',
          });
        }
        tempArr.unshift({ value: '', label: 'No Recipient' });
        setUserSelect(tempArr);
      }
    }, [userData]);

    const [organizationsSelect, setOrganizationsSelect] = useState([{ value: '', label: '' }]);
  const organizationParams = new URLParams();
  organizationParams.select('id', 'name');
    const { data: organizationData } = useOrganizations({ params: organizationParams.toString() });
    useEffect(() => {
      if (organizationData) {
        const tempArr = organizationData.data.items.map((org) => {
          return {
            value: `${org.id}`,
            label: `${org.name}, ${org.parent?.name ?? ''}`,
          };
        });
        if (tempArr.length === 0) {
          tempArr.push({
            value: '',
            label: 'No organizations, Please add the organization first',
          });
        }
        tempArr.unshift({ value: '', label: 'No Organization' });
        setOrganizationsSelect(tempArr);
      }
    }, [organizationData]);

    const [deviceTypesSelect, setDeviceTypesSelect] = useState([
        { value: '', label: '' },
      ]);
    const queryClient = useQueryClient();

    const deviceTypeParams = new URLParams();
    deviceTypeParams.select('id', 'supplier', 'type');
    const { data: allDeviceTypesData } = useDeviceTypes({params: deviceTypeParams.toString()});
    useEffect(() => {
        if (allDeviceTypesData) {
        const tempArr = allDeviceTypesData.data.items.map((simCard) => {
            return {
            value: `${simCard.id}`,
            label: `${simCard.supplier}, ${simCard.type}`,
            };
        });
        setDeviceTypesSelect(tempArr);
        }
    }, [allDeviceTypesData]);

    const form = useForm<IdeviceInputEditMultiple[]>({
        initialValues: 
          [{id:0,imei:0,deviceType:0,status:'',ownership: '',purchaseDate: null, issuedTo: 0, organization: 0}]
        ,
        
      });


  
  
    const {
      mutate: newMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(patchDevices, {
      onSuccess: () => {
        setAlert(null);
        queryClient.invalidateQueries([types.Device, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });
  
    function handleSubmit(values: IdeviceInputEditMultiple[]) {
        const newValues = Object.values(values).map(value => {
          if(value.organization === 0) value.organization = undefined
          if(value.issuedTo === 0) value.issuedTo = undefined
          return value
        })
        newMutate(newValues)
    }

    const rows = Object.values(form.values).map((row, i) => {
        return(
            <tr key={'r' + i}>
                <td>{row.id}</td>
                <td>
                <TextInput
                {...form.getInputProps(`${i}.imei`)}
                onChange={(v) => {
                    form.setFieldValue(`${i}.imei`, v.currentTarget.value);
                    }}
                />
                </td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.status`)}
                data={enumToSelectData(DeviceStatus)}
                />
                </td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.ownership`)}
                data={enumToSelectData(DeviceOwnership)}
                />
                </td>
                <td>
                <DatePickerInputEditable
                  popoverProps={{withinPortal:true}}
                  placeholder="Search Date"
                  {...form.getInputProps(`${i}.purchaseDate`)}
                />
                </td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.deviceType`)}
                onChange={(v) => {
                    if(v)
                    form.setFieldValue(`${i}.deviceType`, parseInt(v));
                    }}
                    data={deviceTypesSelect}
                    value={`${form.values[i].deviceType}`}
                />
                </td>
                <td>
                <SelectEditable
                withinPortal
                searchable
                placeholder="Search here"
                {...form.getInputProps(`${i}.issuedTo`)}
                onChange={(v) => {
                  v && form.setFieldValue(`${i}.issuedTo`, parseInt(v));
                }}
                value={`${form.values[i].issuedTo}`}
                data={userSelect}
                />
                </td>
                <td>
                <SelectEditable
                withinPortal
                searchable
                placeholder="Search here"
                {...form.getInputProps(`${i}.organization`)}
                onChange={(v) => {
                  form.setFieldValue(`${i}.organization`, parseInt(v ?? '0'));
                }}
                value={`${form.values[i].organization}`}
                data={organizationsSelect}
                />
                </td>
            </tr>
          )
    });
  
  
    return (
        <Container pt={10} fluid>
          <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
                mt="md"
              >
                Successfully added to the database!
              </Alert>
            )}
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                mt="md"
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item key={errMsg}>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>

            
            {form.values && (
            <Table highlightOnHover mt="md">
              <thead>
                <tr>
                  {Object.keys(form.values[0]).map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Button
              fullWidth
              type='submit'
              mt="md"
            >
              Submit
            </Button>
          </form>

            
            
          </Paper>
        </Container>
    );
  }
  