import api from "../../common/api/api";
import { IpaymentInput, Ipayments } from "../types/types";

interface myResPayment {
	data: Ipayments;
}

export const postPayment = (data: IpaymentInput): Promise<myResPayment> => {
	return api.post(`payments`, data);
};

export const postPayments = (data: Record<string, any>[]): Promise<myResPayment[]> => {
	return api.post(`payments/multi`, data);
};
