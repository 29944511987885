import api from '../../common/api/api';
import { TaskGroup } from '../types/types';

interface myResTaskGroupPatch {
  data: TaskGroup;
}

export const upsertTaskGroup = (data: Partial<TaskGroup>): Promise<myResTaskGroupPatch> => {
  return api.patch(`task-groups/upsert`, data);
};
