import api from '../../common/api/api';
import { Iorganization, SendNonReportingEmail } from '../types/types';

interface myResOrganization {
  data: Iorganization;
}

export const postSendNonReportingEmail = (data: SendNonReportingEmail): Promise<myResOrganization> => {
  return api.post(`organizations/sendNonReportingEmail`, data);
};
