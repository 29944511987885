import api from "../../common/api/api";
import { IdeviceType, IdeviceTypeInput } from "../types/types";

interface myResDevice {
	data: IdeviceType;
}

export const patchDeviceType = (props: { id: number; data: Partial<IdeviceTypeInput> }): Promise<myResDevice> => {
	return api.patch(`device-types/${props.id}`, props.data);
};

