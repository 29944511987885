export const noEmptyString = <T extends Record<string, any>>(values: T) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null && !(Object.prototype.toString.call(value) === '[object Date]') && !Array.isArray(value)) {
      acc[key as keyof T] = noEmptyString(value);
    } else if (Array.isArray(value)) {
      acc[key as keyof T] = value.map((item) => noEmptyString(item));
    } else {
      if (value === '') {
        acc[key as keyof T] = null;
      } else {
        acc[key as keyof T] = value;
      }
    }
    return acc;
  }, {} as Record<keyof T, any>);
};