import { createStyles, Button, Progress } from '@mantine/core';

const useStyles = createStyles(() => ({
   button: {
      position: 'relative',
      transition: 'background-color 150ms ease',
   },

   progress: {
      position: 'absolute',
      bottom: -1,
      right: -1,
      left: -1,
      top: -1,
      height: 'auto',
      backgroundColor: 'transparent',
      zIndex: 0,
   },

   label: {
      position: 'relative',
      zIndex: 1,
   },
}));

export function UploadProfileImage({ progress, file, click }: { progress: number, file: File | null, click: () => any }) {
   const { classes, theme } = useStyles();
   return (
      <Button
         fullWidth
         className={classes.button}
         onClick={() => click()}
         color={file ? 'teal' : theme.primaryColor}
      >
         <div className={classes.label}>
            {progress !== 0 && progress !== 100 ? 'Uploading files' : progress === 100 ? 'Files uploaded' : 'Upload files'}
         </div>
         {progress !== 0 && (
            <Progress
               value={progress}
               className={classes.progress}
               color={theme.fn.rgba(theme.colors[theme.primaryColor][2], 0.35)}
               radius="sm"
            />
         )}
      </Button>
   );
}