export const colorSelect = [
  { value: 'beige', label: 'Beige' },
  { value: 'black', label: 'Black' },
  { value: 'blue', label: 'Blue' },
  { value: 'brown', label: 'Brown' },
  { value: 'charcoal', label: 'Charcoal' },
  { value: 'cream', label: 'Cream' },
  { value: 'diamond', label: 'Diamond' },
  { value: 'gold', label: 'Gold' },
  { value: 'gray', label: 'Gray' },
  { value: 'green', label: 'Green' },
  { value: 'indigo', label: 'Indigo' },
  { value: 'lead', label: 'Lead' },
  { value: 'magenta', label: 'Magenta' },
  { value: 'orange', label: 'Orange' },
  { value: 'pearl', label: 'Pearl' },
  { value: 'pink', label: 'Pink' },
  { value: 'purple', label: 'Purple' },
  { value: 'red', label: 'Red' },
  { value: 'rose', label: 'Rose' },
  { value: 'silver', label: 'Silver' },
  { value: 'sky blue', label: 'Sky Blue' },
  { value: 'teal', label: 'Teal' },
  { value: 'violet', label: 'Violet' },
  { value: 'white', label: 'White' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'diamond', label: 'Diamond' },
  { value: 'unknown', label: 'Unknown' },
];
