import { Badge, Checkbox, Modal, Text, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { FaEdit } from 'react-icons/fa';
import { ExportSimCards } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { simCardStatusColor, types } from '../../../constants';
import { URLParams, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { useSimCards } from '../api';
import { SimCardCard } from '../components';
import { SimStatus } from '../constants';
import { EditMultipleSimCards } from '../forms/EditMultipleSimCards';
import { ISimUnit } from '../types/types';

export function SimCardTable() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<ISimUnit[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes('simCardType', 'unit');
    param.sort('-sim_card.id');
    param.page(10, page - 1);
    return param;
  });
  const { data: allSimCards, refetch, isLoading } = useSimCards({ params: params.toString() });
  const [opened, { open, close }] = useDisclosure(false);
  useEffect(() => {
    if (allSimCards) {
      const temp = allSimCards.data.items.map((item) => item);
      setData(temp as unknown as ISimUnit[]);
      const selectedRows = allSimCards.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allSimCards]);
  const customActions = [
    { id: 'MultiEdit', multi: true, title: 'Edit Multiple', icon: FaEdit, color: 'orange', handler: open, noDisplay: ['record'] },
  ];

  const columnHelper = useMemo(() => createColumnHelper<ISimUnit>(), []);
  const columns: ColumnDef<ISimUnit, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allSimCards?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allSimCards?.data.meta.ids ? allSimCards?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => row.number, { header: 'number', id: 'number' }),
      columnHelper.accessor((row) => row.serial, { header: 'serial', id: 'serial' }),
      columnHelper.accessor((row) => row.imsi, { header: 'IMSI', id: 'imsi' }),
      columnHelper.accessor('status', {
        header: 'Status',
        id: 'status',
        cell: (props) => {
          if (props.getValue())
            return (
              <Badge
                variant="light"
                c={simCardStatusColor[props.getValue().split(' ').join('_') as keyof typeof simCardStatusColor]}
                styles={{
                  root: {
                    backgroundColor: `${simCardStatusColor[props.getValue().split(' ').join('_') as keyof typeof simCardStatusColor]}40`,
                  },
                }}
              >
                {props.getValue()}
              </Badge>
            );
        },
      }),
      columnHelper.accessor('simCardType.id', { header: 'Sim Card id', id: 'simCardType.id' }),
      columnHelper.accessor('simCardType.supplier', { header: 'Sim Card Supplier', id: 'simCardType.supplier' }),
      columnHelper.accessor('simCardType.type', { header: 'Sim Card Type', id: 'simCardType.type' }),
      columnHelper.accessor((row) => (row.purchaseDate ? simpleDateFormat(row.purchaseDate) : ''), {
        header: 'Purchase Date',
        id: 'purchaseDate',
      }),
      columnHelper.accessor((row) => (row.remarks ?? ''), { header: 'Remarks', id: 'remarks' }),
      columnHelper.accessor((row) => (row.unit ? `${row.unit.id}` : ''), { header: 'Unit ID', id: 'unit.id' }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    'Purchase Date': 'date',
  };

  const toSelect = {
    Status: SimStatus,
  };

  return (
    <Layout>
      <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
        Sim Cards:
      </Title>
      <BaseTable<ISimUnit>
        data={data}
        columns={columns}
        loading={isLoading}
        page={page}
        ids={ids}
        csvExport={<ExportSimCards ids={ids} />}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        toSelect={toSelect}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'expander')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        pages={allSimCards?.data.meta.ids.length ? Math.ceil(allSimCards?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        setIds={setIds}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        defaultVisibleColumns={{ 'simCardType.id': false, 'simCardType.supplier': false, remarks: false }}
        columnTypes={columnTypes}
      />
      <SideBar id={ids} type={types.SimCard} customActions={customActions}>
        <SimCardCard id={ids[0]} />
      </SideBar>
      <Modal
        opened={opened}
        onClose={close}
        size="90%"
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Edit Multiple Sim Cards
          </Text>
        }
      >
        {ids.length < 100 && <EditMultipleSimCards ids={ids} />}
        {ids.length >= 100 && (
          <Text align="center" fw={500} fz="lg">
            Please Select less than 100 Sim Card to perform Edit Multiple
          </Text>
        )}
      </Modal>
    </Layout>
  );
}
