import { ActionIcon, Checkbox, Group, Modal, Text, Title, Tooltip } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { FaEdit } from 'react-icons/fa';
import { TbDownload, TbEye, TbPencil } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';
import { ExportCertificates } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, selectColumns, setTableParams, useAsateelActions } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { useCertificates } from '../api';
import { RenewCerticficates } from '../components';
import { AsateelCertificateColumnsMode } from '../constants';
import { UpdateAsateelCertificateForm } from '../forms';
import { Icertificate } from '../types';

export function AsateelCertificateTable() {
  const [data, setData] = useState<Icertificate[]>([]);
  const [page, setPage] = useState(1)
  const [ids, setIds] = useState<number[]>([])
  const navigate = useNavigate();
  
  const [rowSelection, setRowSelection] = useState({})
  const [opened, { open, close }] = useDisclosure(false);
  const [openedRenew, { open: openRenewCertificates, close: closeRenewCertifcates }] = useDisclosure(false);
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams()
      param.includes(
        'vehicle',
        'vehicle.organization as vehicleOrg',
        'vehicleOrg.parent as orgParent',
        'device',
        'device.deviceType as deviceType',
        'simCard as simCard',
        );
      param.sort(('-asateel_certificate.certificateID'))
      param.select(...selectColumns(AsateelCertificateColumnsMode),
      'vehicle.id', 'vehicle.vin', 'vehicle.plateNumber', 'vehicle.licenseIssueDate', 'vehicle.licenseExpiryDate', 'vehicle.category', 'vehicle.placeOfIssue',
      'vehicleOrg.id', 'vehicleOrg.name', 'orgParent.id', 'orgParent.name',
      'device.id', 'device.imei', 'deviceType.id', 'deviceType.type', 'simCard.id', 'simCard.number',
      )
      param.page(10, page-1)
    return param
  })

  const customActions = [
    { id: 'renewMany', multi: true, title: 'Renew Certificates', icon: FaEdit, color: 'orange', handler: openRenewCertificates, noDisplay: ['record'] },
  ];
  
  const { data: allCertificates, refetch, isLoading } = useCertificates({ params: params.toString() });

  useEffect(() => {
    if (allCertificates) {
      setData(allCertificates.data.items);
      const selectedRows = allCertificates.data.items.map((item) => {
        if(ids.includes(item.id)) return {index: true}
      })
      setRowSelection(selectedRows)
    }
  }, [allCertificates]);

  

  

  const columnHelper = useMemo(() => createColumnHelper<Icertificate>(), []);

  const columns: ColumnDef<Icertificate, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        
        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allCertificates?.data.meta.ids.length
                if(allSelected){
                  setIds([])
                }else setIds(allCertificates?.data.meta.ids ? allCertificates?.data.meta.ids : ids)
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex(id => id === row.original.id)
                  if(rowId === -1){
                    setIds([...ids, row.original.id])
                  }else {
                    setIds(ids => {
                      ids.splice(rowId,1)
                      return ids
                    })
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => `${row.id}` , { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => row.certificateID, { header: 'certificate ID', id: 'certificateID' }),
      columnHelper.accessor((row) => row.vehicle.organization.name, { header: 'Client', id:'vehicleOrg.name' }),
      columnHelper.accessor((row) => simpleDateFormat(row.activationDate), { header: 'Activation Date', id: 'activationDate' }),
      columnHelper.accessor((row) => simpleDateFormat(row.expiryDate), { header: 'Expiry Date', id:'expiryDate' }),
      columnHelper.accessor((row) => row.vehicle.licenseIssueDate ? simpleDateFormat(row.vehicle.licenseIssueDate) : '', { header: 'license Issue Date', id: 'vehicle.licenseIssueDate' }),
      columnHelper.accessor((row) => row.vehicle.licenseExpiryDate ? simpleDateFormat(row.vehicle.licenseExpiryDate) : '', { header: 'license Expiry Date', id:'vehicle.licenseExpiryDate' }),
      columnHelper.accessor((row) => `${row.device?.imei ?? 'No Device'}`, { header: 'imei', id:'device.imei' }),
      columnHelper.accessor((row) => row.vehicle.vin , { header: 'vin', id: `vehicle.vin` }),
      columnHelper.accessor((row) => row.vehicle?.plateNumber ?? '' , { header: 'plate Number', id: `vehicle.plateNumber` }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => {
          const {downloadPdf ,openPdfNewTab} = useAsateelActions()
          return (
             <Group>
                <Tooltip label="View">
                   <ActionIcon variant="subtle" onClick={() => openPdfNewTab(row.original)} ><TbEye size="1rem" /></ActionIcon>
                </Tooltip>
                <Tooltip label="Edit">
                   <ActionIcon variant="subtle" onClick={() => {
                     setIds([row.original.id])
                     open()
                  }}><TbPencil size="1rem" /></ActionIcon>
                </Tooltip>
                <Tooltip label="Download">
                   <ActionIcon variant="subtle" onClick={() => downloadPdf(row.original)}><TbDownload size="1rem" /></ActionIcon>
                </Tooltip>
             </Group>
          )
        } 
     })
    ],
    [columnHelper, data, ids],
  );
  const columnTypes = {
    ID: 'number',
    "Activation Date": "date",
    "Expiry Date": "date",
    "license Issue Date": "date",
    "license Expiry Date": "date",
  }

  return (
    <Layout>
      <Group px={10} mt={10} mb={20} position="apart">
        <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
          Certificates:
        </Title>
        <AddMultipleButton
          add={() => navigate('/new/certificate')}
          addMany={() => navigate('/new/certificates')}
          updateMany={() => navigate('/edit/certificates')}
        />
      </Group>
      <SideBar id={ids} customActions={customActions} type={types.AsateelCertificate}>
        <></>
      </SideBar>
      <BaseTable<Icertificate> data={data} columns={columns} setIds={setIds}  columnTypes={columnTypes} setRowSelection={setRowSelection} rowSelection={rowSelection}
      loading={isLoading}
      csvExport={<ExportCertificates ids={ids} />}
      ids={ids}
      page={page}
      setPage={(value) => {
        setParams((param) => {
          param.filterRemovePages()
          param.page(10, value - 1)
          return param
        })
        setPage(value)
      }}
      globalFilterData={(data, headers) => {
        const newHeaders = headers.filter(header => header && header !== 'select' && header !== 'actions').join('|')
        setTableParams(newHeaders, data, '', setParams)
        refetch()
        setPage(1)
      }}
      pages={allCertificates?.data.meta.ids.length ? Math.ceil(allCertificates?.data.meta.ids.length/10): 1}
      filterData={(column, value, type)=> {
        setTableParams(column, value, type, setParams)
        refetch()
        setPage(1)
      }}
      />
      <Modal opened={opened} onClose={close} size="auto" >
         {ids.length === 1 &&  ids[0] && <UpdateAsateelCertificateForm id={ids[0]}  />}
         </Modal>

         <Modal
        opened={openedRenew}
        onClose={closeRenewCertifcates}
        size="90%"
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Renew Certificates
          </Text>
        }
      >
         <RenewCerticficates ids={ids} />
      </Modal>
    </Layout>
  );
}
