import {
    Alert,
    Button,
    Container,
    Grid,
    Group,
    Input,
    List,
    LoadingOverlay,
    Modal,
    Paper,
    Stepper,
    Title,
    Tooltip
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { DatePickerInputEditable, NumberInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { RichTextEditorComponent } from '../../../components/richTextEditor/RichTextEditorComponent';
import { types } from '../../../constants';
import { URLParams, enumToSelectData, noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { useOrganizations } from '../../organizations';
import { PresetsType } from '../../preSets';
import { useQuotations } from '../api';
import { postQuotation } from '../api/postQuotation';
import { SelectContacts } from '../components/SelectContacts';
import { CountryOfOrigin, QuotationPlanType, QuotationStatus } from '../constants/constants';
import { IquotationInput, IquotationPlanInput } from '../types/types';
import { NewQuotationContentForm } from './NewQuotationContentForm';
  
export function NewQuotationForm() {
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 1 ? current + 1 : current));
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const [opened, { open, close }] = useDisclosure(false);
    const [planType, setPlanType] = useState(QuotationPlanType.FirstPayment)
    const [organizationsSelect, setOrganizationsSelect] = useState([
        { value: '', label: '' },
      ]);
    const [templete, setTemplete] = useState([
        { value: '', label: '' },
      ]);

    const orgParams = new URLParams();
    orgParams.includes('parent')
    orgParams.select('id', 'name', 'parent.id', 'parent.name')
    const { data: organizationsData } = useOrganizations({params: orgParams.toString()});
    useEffect(() => {
    if (organizationsData) {
        const tempArr = organizationsData.data.items.map((org) => {
        return { value: `${org.id}`, label: `${org.name}, ${org.parent? org.parent.name: ''}` };
        });
        if (tempArr.length === 0) {
        tempArr.push({
            value: '',
            label: 'No Clients, Please add the organization first',
        });
        }
        setOrganizationsSelect(tempArr);
    }
    }, [organizationsData]);

    const quotationParams = new URLParams();
    quotationParams.includes('organization', 'contact', 'quotationPlan', 'quotationPlan.quotationContent as quotationContent')
    quotationParams.filterSetField('templete', 1)
    const { data: quotationData } = useQuotations({params: quotationParams.toString()});

    useEffect(() => {
        if(quotationData){
            const temp = quotationData.data.items.map(item => {
                return {value: `${item.id}`, label: `${item.name}`}
            })
            temp.push({value: '0', label: 'custom'})
            setTemplete(temp)
        }
    }, [quotationData])

    const queryClient = useQueryClient();


    const quotationPlan: IquotationPlanInput = {
        massage: '',
        title: '',
        type: '',
        quotationContent: []
    }



    
    const form = useForm<IquotationInput>({
      initialValues: {
        name: '',
        title: '',
        status: QuotationStatus.New,
        organization: { id: 0 },
        contact: { id: 0 },
        qautationDate: new Date(),
        countryOfOrigin: CountryOfOrigin.Europe,
        validity: 15,
        termsConditions: '',
        templete: false,
        discount: '',
        quotationPlan: [],
      },
    });
  
    const {
      mutate: newMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(postQuotation, {
      onSuccess: () => {
        setAlert(null);
        form.reset();
        queryClient.invalidateQueries([types.Quotation, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });

    function handleSubmit(values: IquotationInput) {
        let valid = true
        const newValues = noEmptyString(values)
        newValues.quotationPlan.forEach((item: IquotationPlanInput) => {
            if(!item.quotationContent.filter(item => item.description && item.quantity).length) valid = false
        })
        if(!newValues.qautationDate) {
            form.setFieldError('qautationDate', 'date is required')
            valid = false
        }
        if(!newValues.organization?.id) {
            form.setFieldError('organization.id', 'date is required')
            valid = false
        }
        if(!newValues.organization?.id) {
            form.setFieldError('title', 'date is required')
            valid = false
        }
        if(newValues.quotationPlan.length){
            if(valid) {
                setAlert(null)
                newMutate(newValues);
            }
        } else setAlert('a quotation needs at least to one 1 content to be created')
    }

    function handlePreSet(value: string){
        const values = quotationData?.data.items.find(item => `${item.id}` === value )
        if(values){
            form.setValues({
                name: values.name,
                title: values.title,
                status: values.status,
                organization: {id: values.organization?.id ?? 0},
                contact: values.contact?.id ? {id: values.contact.id} : null,
                qautationDate: values.qautationDate ? new Date(values.qautationDate) : null,
                countryOfOrigin: values.countryOfOrigin,
                discount: values.discount ?? '',
                validity: values.validity,
                templete: false,
                quotationPlan: values.quotationPlan ? values.quotationPlan.map(item => {
                    return {
                        massage: item.massage ? item.massage : null,
                        title: item.title ? item.title : null,
                        type: item.type,
                        quotationContent: item.quotationContent.filter(item => item.description && item.quantity).map(item => {
                            return {
                                description: item.description,
                                price: item.price,
                                quantity: item.quantity,
                            }
                        })
                    }
                }) : [],
                termsConditions: values.termsConditions,
            })
        }else {
            form.reset()
        }

        nextStep()
    }
  
    return (
        <Layout>
            <Container pt={10}>
            <Title
                order={2}
                pl={5}
                align="left"
                sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 800,
                })}
            >
                Add a new Quotation:
            </Title>
            <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
                <Stepper active={active} onStepClick={setActive}>
                    <Stepper.Step label="quotation Type">
                        <SelectEditable data={templete} onChange={(v) => {
                            v !== null && handlePreSet(v)
                        }}
                        value={'0'} 
                        // withinPortal={true}
                        />
                        <Group position='center'>
                            <Button 
                            
                            mt='sm'
                            onClick={() => {
                                handlePreSet('0')
                                
                            }}>Next</Button>
                        </Group>
                    </Stepper.Step>
                    <Stepper.Step label="Quotation Details">
                    
                        <LoadingOverlay visible={newLoading} overlayBlur={2} />
                        {alert && (
                        <Alert
                            icon={<TbAlertOctagon size={16} />}
                            title="Error!"
                            color="red"
                            mb={10}
                        >
                            {Array.isArray(alert) ? (
                            <List>
                                {alert.map((errMsg) => (
                                <List.Item>{errMsg}!</List.Item>
                                ))}
                            </List>
                            ) : (
                            alert + '!'
                            )}
                        </Alert>
                        )}
                        {newSuccess && (
                        <Alert
                            icon={<TbCircleCheck size={16} />}
                            title={'Created!'}
                            color="green"
                            mb={10}
                        >
                            Successfully added to the database!
                        </Alert>
                        )}
                        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                            <TextInputEditable
                            required
                            mt='md'
                            withAsterisk={false}
                            label={<Tooltip label={'The title at the top of the pdf above the client details'}><Input.Label required>Title</Input.Label></Tooltip>}
                            placeholder="title"
                            {...form.getInputProps('title')}
                            />
                            <Grid>
                                <Grid.Col span={6}>
                                    <SelectEditable
                                    label="Client"
                                    placeholder="Search here"
                                    {...form.getInputProps('organization.id')}
                                    onChange={(v) => {
                                        v && form.setFieldValue('organization.id', parseInt(v));
                                    }}
                                    value={`${form.values.organization?.id || 0}`}
                                    searchable
                                    nothingFound="Not found"
                                    data={organizationsSelect}
                                    required
                                    mt="md"
                                    />
                                <SelectContacts orgID={form.values.organization?.id || 0} value={form.values.contact?.id || 0} setValue={(data) => form.setFieldValue('contact.id', data)} />
                                <NumberInputEditable
                                    label="Discount"
                                    placeholder="discount"
                                    precision={2}
                                    {...form.getInputProps('discount')}
                                    hideControls
                                    mt='md'
                                />
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <DatePickerInputEditable
                                    placeholder="Search Date"
                                    label="Qoutation Date"
                                    {...form.getInputProps('qautationDate')}
                                    required
                                    mt="md"
                                    />
                                    <SelectEditable
                                    label="Country Of Origin"
                                    placeholder="Search Here"
                                    {...form.getInputProps('countryOfOrigin')}
                                    required
                                    data={enumToSelectData(CountryOfOrigin)}
                                    mt="md"
                                    />
                                    <NumberInputEditable
                                        label="Validity"
                                        placeholder="contact validity days"
                                        {...form.getInputProps('validity')}
                                        required
                                        hideControls
                                        mt='md'
                                    />
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <Button fullWidth onClick={() => {
                                        if(!form.values.quotationPlan.find(item => item.type === QuotationPlanType.FirstPayment)) form.insertListItem('quotationPlan', {...quotationPlan, type: QuotationPlanType.FirstPayment})
                                        setPlanType(QuotationPlanType.FirstPayment)
                                        open()
                                }}>First Year Charge</Button>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <Button fullWidth onClick={() => {
                                        if(!form.values.quotationPlan.find(item => item.type === QuotationPlanType.Renwal)) form.insertListItem('quotationPlan', {...quotationPlan, type: QuotationPlanType.Renwal})
                                        setPlanType(QuotationPlanType.Renwal)
                                        open()
                                }}>Renewal Charge</Button>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <Button fullWidth onClick={() => {
                                        if(!form.values.quotationPlan.find(item => item.type === QuotationPlanType.OptionalAddOns)) form.insertListItem('quotationPlan', {...quotationPlan, type: QuotationPlanType.OptionalAddOns})
                                        setPlanType(QuotationPlanType.OptionalAddOns)
                                        open()
                                    }}>Optional Items</Button>
                                </Grid.Col>
                            </Grid>
                        <RichTextEditorComponent label={'Terms and Conditions'} value={form.values.termsConditions} setValue={(data) => form.setFieldValue('termsConditions', data)} withPreset={PresetsType.TermsAndConditions}/>

                        <TextInputEditable
                            required
                            withAsterisk={false}
                            label={<Tooltip label={'Consider it as a file name that you can search for in the future'}><Input.Label required>File Name</Input.Label></Tooltip>}
                            placeholder="file Name"
                            {...form.getInputProps('name')}
                            />
                        <Button fullWidth mt="xl" type="submit">
                            Submit
                        </Button>
                        </form>
                    </Stepper.Step>
                </Stepper>
            </Paper>
            </Container>
            <Modal opened={opened} onClose={close} size="90%">
                <NewQuotationContentForm 
                values={
                    form.values.quotationPlan.filter(item => item.type === planType)[0]
                } 
                setValues={(data, column) => {
                    const index = form.values.quotationPlan.findIndex(item => item.type === planType)
                    form.setFieldValue(`quotationPlan.${index}.${column}`, data)
                }}
                insertContent={(data) =>{
                    const index = form.values.quotationPlan.findIndex(item => item.type === planType)
                    form.insertListItem(`quotationPlan.${index}.quotationContent`, data)
                }} 
                />
         </Modal>
        </Layout>
    );
  }
  