import { Badge, Button, Group, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import BaseTable from '../../../components/table/BaseTable';
import { URLParams, selectColumns, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { UserRole, UserRoleColor } from '../../users';
import { useTasks } from '../api';
import { TaskColumnsMode, TaskType, TaskTypeColor } from '../constants';
import { Task } from '../types/types';

export function TBApprovedTasksTable() {
  const [page, setPage] = useState(1);
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState<Task[]>([]);
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.filterSetField('status', 'to_be_approved');
    param.includes(
      'unit',
      'taskGroup',
      'installationUnit',
      'vehicle',
      'organization',
      'organization.parent as orgParent',
      'installationUnit.device as newUnitDevice',
      'unit.device as unitDevice',
      'unit.vehicle as unitVehicle',
      'unit.simCard as unitSimCard',
      'unitVehicle.vehicleType as vehicleType',
      'recipient',
      'issuedBy',
      'performedBy',
    );
    param.select(
      ...selectColumns(TaskColumnsMode),
      'unit.id',
      'taskGroup.id',
      'taskGroup.name',
      'unitSimCard.id',
      'unitSimCard.number',
      'unitSimCard.imsi',
      'unitSimCard.serial',
      'unitVehicle.id',
      'unitVehicle.vin',
      'unitVehicle.plateNumber',
      'vehicleType.id',
      'vehicleType.category',
      'vehicle.id',
      'vehicle.vin',
      'vehicle.plateNumber',
      'organization.id',
      'organization.name',
      'orgParent.id',
      'orgParent.name',
      'unitDevice.id',
      'unitDevice.imei',
      'newUnitDevice.id',
      'recipient.id',
      'recipient.firstName',
      'issuedBy.id',
      'issuedBy.firstName',
      'performedBy.id',
      'performedBy.firstName',
    );
    param.sort('-task.id');
    param.page(10, page - 1);
    return param;
  });
  const { data: allTasks, refetch, isLoading } = useTasks({ params: params.toString() });
  const navigate = useNavigate();

  useEffect(() => {
    if (allTasks) {
      setData(allTasks.data.items);
    }
  }, [allTasks]);

  const columnHelper = useMemo(() => createColumnHelper<Task>(), []);
  const columns: ColumnDef<Task, any>[] = useMemo(
    () => [
      columnHelper.accessor((row) => `${row.id}`, { header: 'ID', id: 'id' }),
      columnHelper.accessor('title', { header: () => 'Title', id: 'title' }),
      columnHelper.accessor('type', {
        header: () => 'Type',
        id: 'type',
        cell: (props) => (
          <Badge
            variant="light"
            c={TaskTypeColor[props.getValue().split(' ').join('_') as keyof typeof TaskTypeColor]}
            styles={{
              root: { backgroundColor: `${TaskTypeColor[props.getValue().split(' ').join('_') as keyof typeof TaskTypeColor]}40` },
            }}
          >
            {props.getValue()}
          </Badge>
        ),
      }),
      columnHelper.accessor((row) => row.organization.name, { header: 'Organization', id: 'organization.name' }),
      // columnHelper.accessor((row) => row.organization?.parent?.name ?? '', { header: 'Parent', id: 'orgParent.name' }),
      columnHelper.accessor((row) => row.organization?.parent?.name ? row.organization?.name + ' / ' + row.organization?.parent?.name : row.organization?.name, { header: 'Organization & Parent', id: 'organization.name|orgParent.name' }),
      columnHelper.accessor((row) => (row.targetDate ? simpleDateFormat(row.targetDate) : ''), {
        header: 'Request Date',
        id: 'targetDate',
      }),
      columnHelper.accessor((row) => (row.completionDate ? simpleDateFormat(row.completionDate) : ''), {
        header: 'Completion Date',
        id: 'completionDate',
      }),
      columnHelper.accessor('address', { header: () => 'Address', id: 'address' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.plateNumber : row.unit ? row.unit.vehicle?.plateNumber : ''), {
        header: 'Plate No.',
        id: 'unitVehicle.plateNumber|vehicle.plateNumber',
      }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vin : row.unit ? row.unit.vehicle?.vin : ''), {
        header: 'Vehicle vin',
        id: 'unitVehicle.vin|vehicle.vin',
      }),
      columnHelper.accessor((row) => (row.vehicle?.vehicleType?.category ??  row.unit?.vehicle?.vehicleType?.category ?? ''), {
        header: 'Vehicle Category',
        id: 'vehicleType.category',
      }),
      columnHelper.accessor((row) => row.unit?.device?.imei ?? row.installationUnit?.device?.imei, {
        header: 'Device imei',
        id: 'unitDevice.imei|newUnitDevice.imei',
      }),
      columnHelper.accessor((row) => row.unit?.simCard?.number ?? 'NA', {
        header: 'Sim Number',
        id: 'unitSimCard.number',
      }),
      columnHelper.accessor((row) => row.unit?.simCard?.imsi ?? 'NA', {
        header: 'Sim IMSI',
        id: 'unitSimCard.imsi',
      }),
      columnHelper.accessor((row) => row.unit?.simCard?.serial ?? 'NA', {
        header: 'Sim Serial',
        id: 'unitSimCard.serial',
      }),
      columnHelper.accessor((row) => row.taskGroup, {
        header: 'Task Group',
        id: 'taskGroup.name',
      }),
      columnHelper.accessor((row) => row.recipient?.firstName, {
        header: 'recipient',
        id: 'recipient.firstName',
      }),
      columnHelper.accessor((row) => row.performedBy?.firstName, {
        header: 'performed By',
        id: 'performedBy.firstName',
      }),
      columnHelper.accessor((row) => row.taskRemarks, {
        header: 'support remarks',
        id: 'taskRemarks',
      }),
      columnHelper.accessor((row) => row.location, {
        header: 'location',
        id: 'location',
      }),
      columnHelper.accessor((row) => row.issuedBy?.firstName, {
        header: 'issued By',
        id: 'issuedBy.firstName',
      }),
      columnHelper.accessor('recipientGroup', {
        header: 'recipient Group',
        id: 'recipientGroup',
        cell: (props) => {
          if (props.getValue())
            return (
              <Badge
                variant="light"
                c={UserRoleColor[props.getValue().split(' ').join('_') as keyof typeof UserRoleColor]}
                styles={{
                  root: { backgroundColor: `${UserRoleColor[props.getValue().split(' ').join('_') as keyof typeof UserRoleColor]}40` },
                }}
              >
                {props.getValue()}
              </Badge>
            );
        },
      }),
      columnHelper.display({
        id: 'action',
        header: () => '',
        cell: ({ row }) => (
          <div className="px-1">
            {row.original.type == TaskType.Installation && (
              <Button onClick={() => navigate(`/tasks/approveInstallation/${row.original.id}`)}>review</Button>
            )}
            {row.original.type == TaskType.ReInstallation && (
              <Button onClick={() => navigate(`/tasks/approveReinstallation/${row.original.id}`)}>review</Button>
            )}
            {row.original.type == TaskType.Removal && (
              <Button onClick={() => navigate(`/tasks/approveRemoval/${row.original.id}`)}>review</Button>
            )}
            {row.original.type == TaskType.Inspection && (
              <Button onClick={() => navigate(`/tasks/approveInspection/${row.original.id}`)}>review</Button>
            )}
            {row.original.type == TaskType.DeviceChange && (
              <Button onClick={() => navigate(`/tasks/approveDeviceChange/${row.original.id}`)}>review</Button>
            )}
            {row.original.type == TaskType.SimCardChange && (
              <Button onClick={() => navigate(`/tasks/approveSimCardChange/${row.original.id}`)}>review</Button>
            )}
            {row.original.type == TaskType.AccessoryInstallation && (
              <Button onClick={() => navigate(`/tasks/approveAccessoryInstallation/${row.original.id}`)}>review</Button>
            )}
          </div>
        ),
      }),
    ],
    [columnHelper, navigate],
  );

  const columnTypes = {
    ID: 'number',
    'Request Date': 'date',
    'Completion Date': 'date',
  };
  const toSelect = {
    Type: TaskType,
    'recipient Group': UserRole,
  };

  return (
    <Layout>
      <Group px={10} mt={10} mb={20} position="apart">
        <Title order={2} align="left" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
          Tasks to be approved:
        </Title>
        {/* <Button onClick={() => navigate('/new/rfid-list')} >Add new</Button> */}
      </Group>
      <BaseTable<Task>
        data={data}
        columns={columns}
        columnTypes={columnTypes}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        loading={isLoading}
        page={page}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        toSelect={toSelect}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'expander')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        pages={allTasks?.data.meta.ids.length ? Math.ceil(allTasks?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        defaultVisibleColumns={{
          id: false,
          'recipient.firstName': false,
          'vehicleType.category': false,
          address: false,
          jobRemarks: false,
          taskRemarks: false,
          'organization.name': false,
          'unitSimCard.number': false,
          'unitSimCard.imsi': false,
          'unitSimCard.serial': false,
          location: false,
          status: false,
        }}
      />
    </Layout>
  );
}
