import { Flex, rem } from '@mantine/core';
import { useResizableContext } from './Resizable.context';
import { useResize } from '../../utils';

interface ResizableCoreProps {
  // children: JSX.Element | JSX.Element[];
  children: JSX.Element[];
  setSideSize: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
  sideMinSize?: number;
  sideMaxSize?: number;
  vertical?: boolean;
}

export function ResizableCore({ children, setSideSize, sideMinSize, sideMaxSize }: ResizableCoreProps) {
  const ctx = useResizableContext();
  const { refArea, refTarget } = useResize(({ x, y }) => {
    if (sideMinSize && (ctx.vertical ? y < sideMinSize : x < sideMinSize))
      setSideSize(ctx.vertical ? { x: ctx.sideSize.x, y: sideMinSize } : { x: sideMinSize, y: ctx.sideSize.y });
    else if (sideMaxSize && (ctx.vertical ? y > sideMaxSize : x > sideMaxSize))
      setSideSize(ctx.vertical ? { x: ctx.sideSize.x, y: sideMaxSize } : { x: sideMaxSize, y: ctx.sideSize.y });
    else setSideSize(ctx.vertical ? { x: ctx.sideSize.x, y } : { x, y: ctx.sideSize.y });
  });

  return (
    <Flex gap={0} ref={refArea} h="100%" w="100%" wrap="nowrap" direction={ctx.vertical ? 'column' : 'row'}>
      {children.find((child) => child?.type?.displayName === 'ResizableFirst')}
      {!ctx.hideFirst && !ctx.hideSecond && (
        <div
          ref={refTarget}
          style={{
            left: ctx.vertical ? 0 : `calc(${ctx.sideSize.x * 100}% - ${rem(2)})`,
            top: ctx.vertical ? `calc(${ctx.sideSize.y * 100}% - ${rem(2)})` : 0,
            zIndex: 300,
            width: ctx.vertical ? '100%' : rem(8),
            height: ctx.vertical ? rem(8) : '100%',
            cursor: ctx.vertical ? 'row-resize' : 'col-resize',
          }}
        />
      )}
      {children.find((child) => child?.type?.displayName === 'ResizableSecond')}
    </Flex>
  );
}
