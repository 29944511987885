import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { Icertificate } from "../types/types";

interface myResCertificate {
	data: Icertificate;
}

export const getCertificate = (id: number, joins?: string[], selects?: string[]): Promise<myResCertificate> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`asateel-certificates/${id}${params}`);
};
export const getLastCertificate = (): Promise<myResCertificate> => {
	return api.get(`asateel-certificates/last`);
};

export const useCertificate = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResCertificate) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.AsateelCertificate, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
		getCertificate(id, options?.joins, options?.selects).then((certificate) => {
				options && options.then && options.then(certificate);
				return certificate;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};


export const useLastCertificate = (options?: { then?: (x: myResCertificate) => any }) => {
	const query = useQuery(
		[],
		() =>
		getLastCertificate().then((certificate) => {
				options && options.then && options.then(certificate);
				return certificate;
			}),
	);
	return query;
};
