import { Button, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { types } from "../../../constants";
import { DownloadAsCsv, URLParams } from "../../../utils";
import { Ihistory, useHistories } from "../../history";
import { useUsers } from "../../users";
import { useDevices } from "../api";

export function DeviceHistoryReport(
    {
        ids,
    }:
    {
        ids: number[],
    }){
        const deviceParams = new URLParams();
        deviceParams.resetSearchMany()
        deviceParams.searchMany('id', ids.map(id => `${id}`))
        const { data: deviceData } = useDevices();
        const { data: usersData } = useUsers();
        const [hitsories, setHistories] = useState<Ihistory[]>([])
            
        const params = new URLParams();
        params.resetSearchMany()
        params.searchMany('typeId', ids.map(id => `${id}`))
        params.filterSetField('longDescription', 'issuedTo')
        const {data: allHistories } = useHistories([types.Device], {params: params.toString(), then: (history) => {
            setHistories(history.data.items)
        }});
        useEffect(() => {
            if(allHistories){
                setHistories(allHistories)
            }
        }, [allHistories])

          
        function discriptionFormatter(disc: string | null) {
            if (disc === null) return {from: '', to: ''};
            const discJson: [
              Record<string, string | number | { id: number }>,
              Record<string, string | number | { id: number }>,
            ] = JSON.parse(disc);
            const prev = discJson[0];
            const curr = discJson[1];
            let prevValue = 'Not Assigned'
            let currVal = 'Not assigned'
            const prevUser = usersData?.data.items.find(item => prev.issuedTo === item.id)
            if(prevUser){
                prevValue = prevUser.firstName
            }
            const currUser = usersData?.data.items.find(item => curr.issuedTo === item.id)
            if(currUser) {
                currVal = currUser.firstName
            }

            return {from: prevValue, to: currVal}
          }

        const deviceHistory = hitsories.map((item) => {
            const device = deviceData?.data.items.find(device => item.typeId === device.id) || {imei: 0}
            const desc = discriptionFormatter(item.longDescription)
            return {device: device?.imei, ...desc, date: new Date(item.createdAt)}
        })
        function handleSubmit(){
            if(deviceHistory.length !== 0){
            DownloadAsCsv(Object.values(deviceHistory).map(item => item), 'Device Issueing Report', [{path: 'device'}, {path: 'from'}, {path: 'to'}, {path: 'date'}])
            }
        }

        const rows = deviceHistory.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.device}</td>
                    <td>{item.from}</td>
                    <td>{item.to}</td>
                    <td>{item.date.toDateString()}</td>
                </tr>
            )
        })
        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <th>imei</th>
                            <th>from</th>
                            <th>to</th>
                            <th>date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
                <Button my='sm' fullWidth onClick={handleSubmit}>Download</Button>
            </>
        )
} 