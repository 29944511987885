
import Layout from '../../../components/layout/Layout';
import { NewSimCardForm } from '../components/NewSimCardForm';

export function NewSimCardFormPage() {

  return (
    <Layout>
      <NewSimCardForm/>
    </Layout>
  );
}
