import { Center, Container, Grid, Group, Loader, Pagination, Select, Title } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';
import { BaseCard, CardsFilter, useDeepFilter } from '../../../components/card';
import Layout from '../../../components/layout/Layout';
import { URLParams } from '../../../utils';
import { PageSize } from '../../common';
import { useVehicles } from '../api';
import { UpdateVehicleForm } from '../components';
import { Ivehicle } from '../types/types';

export function VehicleCards() {
  const params = new URLParams();
  params.includes('vehicleType', 'organization', 'organization.parent');
  const { isSuccess, data } = useVehicles({ params: params.toString() });
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const navigate = useNavigate();
  const { filter, setFilter, formattedData } = useDeepFilter(data ? data.data.items : []);
  let cardsLoop;
  if (isSuccess) {
    cardsLoop = formattedData
      .slice((page - 1) * pagesize, pagesize * page)
      .map((item: Ivehicle) => (
        <BaseCard
          key={`card-${item.id}`}
          name={item.vin.toString()}
          discription={`Plate: ${item.plateNumber}, property of: ${item.organization?.name}${
            item.organization?.parent ? ' - ' + item.organization?.parent.name : ''
          }, brand: ${item.vehicleType.brand}, vehicleType Id: ${item.vehicleType.id}`}
          to={`/vehicle/${item.id}`}
          img={item.vehicleType.imageRepositoryId}
          updateModalContent={<UpdateVehicleForm idM={item.id} />}
        />
      ));
  }

  return (
    <Layout>
      <Container fluid>
        <Group px={13} mt={10} position="apart">
          <Title order={2} align="left" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
            Vehicles:
          </Title>
          <AddMultipleButton
            add={() => navigate('/new/vehicle')}
            addMany={() => navigate('/new/vehicles')}
            updateMany={() => navigate('/edit/vehicles')}
          />
        </Group>
        <CardsFilter setFilter={setFilter} filter={filter} />
        <Grid>
          {formattedData && cardsLoop ? (
            cardsLoop
          ) : (
            <Container p={25}>
              <Loader />
            </Container>
          )}
        </Grid>
        <Center my={15}>
          <Pagination
            total={Math.ceil(formattedData.length / pagesize)}
            siblings={2}
            boundaries={2}
            onChange={(n) => setPage(n)}
            value={page}
          />
          <Select placeholder="Page Size" size="sm" ml={10} data={Object.values(PageSize)} onChange={(e) => setPagesize(Number(e))} />
        </Center>
      </Container>
    </Layout>
  );
}
