import { Alert, Container, createStyles, LoadingOverlay, Paper, Table, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { commonConstants } from '../../common';
import { useUser } from '../api';

const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));

export function UserCard({ id, setImage }: { id: number; setImage?: React.Dispatch<React.SetStateAction<number | null>> }) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);

  const { isLoading, isError, data, refetch } = useUser(id, {
    joins: ['profiles', 'profiles.organization'],
  });
  useEffect(() => {
    if (data && setImage) {
      const profileN = data.data.profiles.find((profile) => profile.app === commonConstants.AiosApp);
      if (profileN) setImage(profileN?.imageRepositoryId); // || 'https://media.istockphoto.com/id/476085198/photo/businessman-silhouette-as-avatar-or-default-profile-picture.jpg?s=612x612&w=0&k=20&c=GVYAgYvyLb082gop8rg0XC_wNsu0qupfSLtO7q9wu38=')
    }
  }, [data, setImage]);

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);

  return (
    <Paper pos="relative">
      <Container className={classes.detailsContainer} m={0} p={0}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        <Tabs defaultValue="user">
          <Tabs.List>
            <Tabs.Tab value="user">User's Details</Tabs.Tab>
            {data &&
              data.data &&
              data.data.profiles.map((profile) => (
                <Tabs.Tab key={profile.app} value={profile.app}>
                  {profile.app.charAt(0).toUpperCase() + profile.app.slice(1)}
                </Tabs.Tab>
              ))}
          </Tabs.List>

          <Tabs.Panel value="user" pt="xs">
            {data && data.data && (
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSideTable}>id:</td>
                    <td>{data.data.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Username:</td>
                    <td>{data.data.username}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Email:</td>
                    <td>{data.data.email}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>First Name:</td>
                    <td>{data.data.firstName}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Last Name:</td>
                    <td>{data.data.lastName}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Status:</td>
                    <td>{data.data.status}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Tabs.Panel>
          {data &&
            data.data &&
            data.data.profiles.map((profile) => (
              <Tabs.Panel key={profile.app} value={profile.app} pt="xs">
                <Table>
                  <tbody>
                    <tr>
                      <td className={classes.leftSubtitleTable}>Profile:</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className={classes.leftSideTable}>Employee ID:</td>
                      <td>{profile.employeeId}</td>
                    </tr>
                    <tr>
                      <td className={classes.leftSideTable}>Role:</td>
                      <td>{profile.role}</td>
                    </tr>
                    <tr>
                      <td className={classes.leftSideTable}>Department:</td>
                      <td>{profile.department}</td>
                    </tr>
                    <tr>
                      <td className={classes.leftSideTable}>Organization:</td>
                      <td>{profile.organization.name}</td>
                    </tr>
                    <tr>
                      <td className={classes.leftSideTable}>Phone:</td>
                      <td>{profile.phone}</td>
                    </tr>
                    <tr>
                      <td className={classes.leftSideTable}>Profile status:</td>
                      <td>{profile.status}</td>
                    </tr>
                  </tbody>
                </Table>
              </Tabs.Panel>
            ))}
        </Tabs>
        {isError && (
          <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
            Error Loading data from the server!!
          </Alert>
        )}
      </Container>
    </Paper>
  );
}
