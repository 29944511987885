import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { IsimCard } from "../types/types";

interface myResSimCard {
	data: IsimCard;
}

export const getSimCard = (id: number, joins?: string[], selects?: string[]): Promise<myResSimCard> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`sim-cards/${id}${params}`);
};

export const useSimCard = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResSimCard) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.SimCard, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
			getSimCard(id, options?.joins, options?.selects).then((simCard) => {
				options && options.then && options.then(simCard);
				return simCard;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};
