import { Badge, Grid, Paper, Stack, Text } from "@mantine/core"
import { selectColumns } from "../../../utils"
import { PlatformColor } from "../../unit"
import { usePurchasePlan } from "../api/getPurchasePlan"
import { DisplayUnits } from "../components"
import { PlanColumnsMode, PurchaseOrderStatusColors } from "../constants"

export function PlanDetails({
    planID,
    index,
}: 
{
    planID: number,
    index: number,
}){
    const { data: purchasePlanData } = usePurchasePlan(planID, {
        joins: ['units', 'removedUnits'],
        selects: [...selectColumns(PlanColumnsMode), 'units.id', 'removedUnits.id'],
    })
    return (
        <>
            {purchasePlanData && <Paper withBorder p="sm" mt={index !== 0 ? "sm" : undefined}>
                <Text>plan id: {planID}</Text>
                <Grid p="sm">
                <Grid.Col span={4}>
                    <Stack>
                    {purchasePlanData.data.platform && <Badge 
                        variant="light" 
                        c={PlatformColor[purchasePlanData.data.platform as keyof typeof PlatformColor]} 
                        styles={{root:{backgroundColor: `${PlatformColor[purchasePlanData.data.platform as keyof typeof PlatformColor]}40`,}}}
                        >{purchasePlanData.data.platform}</Badge> }
                    {purchasePlanData.data.secondaryPlatform && 
                    <Badge 
                    variant="light" 
                    c={PlatformColor[purchasePlanData.data.secondaryPlatform as keyof typeof PlatformColor]} 
                    styles={{root:{backgroundColor: `${PlatformColor[purchasePlanData.data.secondaryPlatform as keyof typeof PlatformColor]}40`,}}}
                    >{purchasePlanData.data.secondaryPlatform}</Badge> 
                    }
                    </Stack>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Stack>
                        <Text>Type: {purchasePlanData.data.type}</Text>
                        <Text>{purchasePlanData.data.quantity ?? purchasePlanData.data.units.length} devices installed</Text>
                    </Stack>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Stack>
                        <Text>Interval: {purchasePlanData.data.interval}</Text>
                        {purchasePlanData.data.startDate && <Text>start Date: {new Date(purchasePlanData.data.startDate).toDateString()}</Text>}
                        {purchasePlanData.data.endDate && <Text>end Date: {new Date(purchasePlanData.data.endDate).toDateString()}</Text>}
                        <Text>Status: <Badge color={PurchaseOrderStatusColors[purchasePlanData.data.status as keyof typeof PurchaseOrderStatusColors]}>{purchasePlanData.data.status}</Badge></Text>
                        <Text>Remarks: {purchasePlanData.data.remarks}</Text>
                    </Stack>
                </Grid.Col>
                </Grid>
                <Paper withBorder p="xs">
                    <DisplayUnits unitIds={purchasePlanData.data.units.map((unit) => unit.id)} planID={purchasePlanData.data.id} type={'Remove'} />
                </Paper>
                <Paper withBorder p="xs" mt='sm'>
                    <DisplayUnits unitIds={purchasePlanData.data.removedUnits.map((unit) => unit.id)} planID={purchasePlanData.data.id} type={'Restore'} />
                </Paper>
            </Paper>}
        </>
    )
}