import {
  ActionIcon,
  Alert,
  Container,
  createStyles,
  Group,
  LoadingOverlay,
  Paper,
  Table,
  Tabs
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { TbAlertOctagon } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '../api';

const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));

export function OrganizationCard({
  id,
  setImage,
}: {
  id: number;
  setImage?: React.Dispatch<React.SetStateAction<number | null>>;
}) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);
  const navigate = useNavigate();

  const { isLoading, isError, data, refetch } = useOrganization(id, {joins: ['contacts', 'parent']});
  useEffect(() => {
    if (setImage && data) {
      setImage(data.data.imageRepositoryId);
    }
  }, [data, setImage]);


  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);


  const orgPanels = data?.data.contacts.map((item) => {
    return(
      <tbody key={item.id}>
        <tr>
          <td className={classes.leftSideTable}>id:</td>
          <td>{item.id}</td>
        </tr>
        <tr>
          <td className={classes.leftSideTable}>name:</td>
          <td>{item.name}</td>
        </tr>
        <tr>
          <td className={classes.leftSideTable}>position:</td>
          <td>{item.position}</td>
        </tr>
        <tr>
          <td className={classes.leftSideTable}>phone:</td>
          <td>{item.phone}</td>
        </tr>
        <tr>
          <td className={classes.leftSideTable}>email:</td>
          <td>{item.email}</td>
        </tr>
      </tbody>
    )
  })

  return (
    <Paper pos="relative">
      <Container className={classes.detailsContainer} m={0} p={0}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        <Tabs defaultValue="organization">
          <Tabs.List>
            <Tabs.Tab value="organization">Organization</Tabs.Tab>
            <Tabs.Tab value="contacts">contacts</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="organization">
            {data && data.data && (
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSideTable}>id:</td>
                    <td>{data.data.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Name:</td>
                    <td>{data.data.name}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Type:</td>
                    <td>{data.data.type}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Status:</td>
                    <td>{data.data.status}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Parent:</td>
                    <td>
                      {data.data.parent ? (
                        <Group position="apart">
                          {data.data.parent.name}
                          <ActionIcon
                            onClick={() =>
                              navigate(`/organization/${data.data.parent?.id}`)
                            }
                          >
                            <MdOpenInNew size={18} />
                          </ActionIcon>
                        </Group>
                      ) : (
                        'No parent'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Trade License:</td>
                    <td>{data.data.tradeLicense}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Traffic File:</td>
                    <td>{data.data.trafficFile}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>TRN:</td>
                    <td>{data.data.trn}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>abbreviation:</td>
                    <td>{data.data.abbreviation}</td>
                  </tr>
                </tbody>
              </Table>
            )}
            {isError && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
              >
                Error Loading data from the server!!
              </Alert>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="contacts">
            <Table>
                {orgPanels}
            </Table>
          </Tabs.Panel>

        </Tabs>
      </Container>
    </Paper>
  );
}
