import { ActionIcon, Badge, Modal, Table, Text, Tooltip, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { TbEye } from "react-icons/tb";
import { URLParams, selectColumns } from "../../../utils";
import { TaskColumnsMode, TaskTypeColor, useTasks } from "../../tasks";

const useStyles = createStyles((theme) => ({
  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },

  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,

  },
}));

export function UnitsRecords({id}:{id: number}){
  const { classes } = useStyles();
  const params = new URLParams()
  params.includes(
    'unit',
  );
  params.filterSetField('=unit.id', id)
  params.select(...selectColumns(TaskColumnsMode), 'unit.id')
  params.sort('-targetDate')
  const {data} = useTasks({params: params.toString()})

  const [opened , {open: open, close:close}] = useDisclosure(false)

  const rows = data?.data.items.map((task) =>{
    return (
      <tr key={task.id}>
        <td><Badge
        variant="light" 
        c={TaskTypeColor[task.type.split(' ').join('_') as keyof typeof TaskTypeColor]} 
        styles={{root:{backgroundColor: `${TaskTypeColor[task.type.split(' ').join('_') as keyof typeof TaskTypeColor]}40`,}}}
        >{task.type}</Badge></td>
        <td>{task.targetDate? new Date(task.targetDate).toDateString(): ''}</td>
        <td>{task.completionDate? new Date(task.completionDate).toDateString(): ''}</td>
      </tr>
    )
  });

  return(
    <>
    <Tooltip label={'view Tasks'}><ActionIcon onClick={open}><TbEye size={10} /></ActionIcon></Tooltip>
      <Modal opened={opened} onClose={close} size={'65%'} title={<Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}` ,fontWeight: 800, fontSize:'1.625rem' })}>Unit Tasks</Text>}>
        <Table>
          <thead className={classes.header}>
            <tr>
              <th>Job</th>
              <th>Request Date</th>
              <th>Completion Date</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </Modal>
    </>
  )
}