import { DatePickerInput, DatePickerType } from "@mantine/dates";
import { useState } from "react";
import { TableFilterMenu } from "./TableFilterMenu";

function DateRangeInput({
  label,
  filterData,
}: {
  label?: string;
  filterData?: (column: string, data: Date | Date[] | undefined, type:string) => void,
}) {
  const [ type, setType ] = useState<DatePickerType>('default')
  const [date, setDate] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

  const handleChange = (value: Date[] | Date) => {
    if(Array.isArray(value) && label){
      filterData && filterData(label, [value[0], new Date(value[1].getFullYear(), value[1].getMonth(), value[1].getDate(), 19,59,59)], 'date');
    }else if(label){
      filterData && filterData(label, value, 'date');
    }
  };

  return (
    <div style={{width: '100%'}}>
    {type === 'default' && 
        <DatePickerInput
        value={date}
        rightSection={<TableFilterMenu 
          column={label as string} 
          columnType={'date'} 
          setValue={(type) => {
            if(type === ''){
              setDate(null)
              setDateRange([null, null])
            }else setType(type as unknown as DatePickerType) 
            }
          }
          filterData={filterData}
          setDate={setDate}
          setDateRange={setDateRange}
          />}
          type='default'
        popoverProps={{withinPortal: true}} 
        onChange={(val) => {
          setDate(val)
          val && handleChange(val)
          }}
          
        />
    }
    {type === 'range' && 
        <DatePickerInput
        value={dateRange}
        rightSection={<TableFilterMenu 
          column={label as string} 
          columnType={'date'} 
          setValue={(type) => {
          setType(type as unknown as DatePickerType)}}
          filterData={filterData}
          setDate={setDate}
          setDateRange={setDateRange}
          />}
          type='range'
        popoverProps={{withinPortal: true}} 
        onChange={(val) => {
          setDateRange(val)
          val && val[0] && val[1] && handleChange(val as Date[])
          }}
          
        />
    }
    </div>
  );
}

export default DateRangeInput;
