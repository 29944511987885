import { Alert, Button, Container, List, LoadingOverlay, Paper } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { TbAlertOctagon, TbCircleCheck } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { TextInputEditable } from "../../../../../components/form";
import { types } from "../../../../../constants";
import { IaxiosError } from "../../../../common";
import { postContacts } from "../../../api";
import { ContactsInput } from "../../../types/types";

export function NewContactsForm({orgID}:{orgID:number}){
  const [alert, setAlert] = useState<string | string[] | null>(null);

  
    const form = useForm({
        initialValues: {
            name:'',
            position:'',
            phone:'',
            email:'',
        }
    })

    const queryClient = useQueryClient();

    const {
        mutate: newMutate,
        isLoading: newLoading,
        isSuccess: newSuccess,
      } = useMutation(postContacts, {
        onSuccess: () => {
          setAlert(null);
          form.reset();
          queryClient.invalidateQueries([types.Contacts, types.ALL]);
          queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
          });
        },
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });

    function handleSubmit(values:ContactsInput){
        if(orgID !== 0){
            const newValues = {...values, organization: orgID}
            newMutate(newValues)
        }else {
            setAlert('choose Organization Before Adding Contact')
        }
    }
    return (
        <Container mt='md'>
            <Paper withBorder p='md'>
            <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <TextInputEditable
                label="Name"
                placeholder="name"
                {...form.getInputProps('name')}
                />
                <TextInputEditable
                label="position"
                placeholder="position"
                {...form.getInputProps('position')}
                mt="md"
                />
                <TextInputEditable
                label="phone"
                placeholder="phone"
                {...form.getInputProps('phone')}
                mt="md"
                />
                <TextInputEditable
                label="email"
                placeholder="email"
                {...form.getInputProps('email')}
                mt="md"
                />
                <Button fullWidth mt='md' type="submit">Save</Button>
            </form>
            </Paper>
        </Container>
    )
}