import { LoadingOverlay } from "@mantine/core";
import { flexRender, Row, Table } from "@tanstack/react-table";
import { Fragment } from "react";

function TableRows<T extends object>({ instance, renderSubComponent, loading }: { instance: Table<T>, renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement; loading: boolean }) {
  return (
    <tbody style={{position: 'relative'}}>
      {instance.getRowModel().rows.map((row) => {
        return (
          <Fragment key={row.id}>
            <tr>
              {/* first row is a normal row */}
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    <LoadingOverlay visible={loading} />
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
            {row.getIsExpanded() && renderSubComponent && (
              <tr>
                {/* 2nd row is a custom 1 cell row */}
                <td colSpan={row.getVisibleCells().length}>
                  {renderSubComponent({ row })}
                </td>
              </tr>
            )}
          </Fragment>
        );
      })}
    </tbody>
  );
}

export default TableRows;
