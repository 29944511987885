import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Ihistory } from '../types/types';

interface myResHistories {
  data: {
    items: Ihistory[];
    meta: metaData,
  };
}

export const getHistories = (query = ''): Promise<myResHistories> => {
  return api.get(`history${query}`);
};
export const useHistories = (type: types[], options: { params?: string; entityId?: number[]; then?: (x: myResHistories) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.History, ...type];
  if (options.entityId) options.entityId.forEach(id => cacheKey.push({ id: id }));
  if (options && options.params) cacheKey.push(options.params);
  // const cachedId = `${type}.${types.History}`;
  // const cacheKey = options.entityId ? `${cachedId}.${options.entityId}` : cachedId;

  const query = useQuery(cacheKey, () =>
    getHistories(options.params).then((histories) => {
      options.then && options.then(histories);
      return histories.data.items.reverse();
    }), { enabled: options.enabled ?? true },
  );
  return query;
};

// export const useHistoryMutation = (cacheId: HistoryCacheKeys[] = [...HISTORY_CACHE_KEYS], params?: string) => {
// 	const query = useQuery(cacheId || ALL_HISTORIES, () =>
// 		getHistories(params).then((histories) => {
// 			console.log(histories);
// 			return histories;
// 		})
// 	);

// 	return query;
// };
