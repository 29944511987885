import { useState } from 'react';
//TODO: use it in history card
export function useDisclosureList(intialValue: boolean[] = []) {
  const [opened, setOpened] = useState<boolean[]>(intialValue);

  const toggle = (index: number) => {
    const clone = [...opened];
    clone[index] = !opened[index];
    setOpened(clone);
  };

  return [opened, { toggle, setOpened }] as const;
}
