import {
  Alert,
  Button,
  Container,
  Grid,
  List,
  LoadingOverlay, Modal, Paper,
  SegmentedControl,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DetailsCard } from '../../../components/detailsCards';
import { DatePickerInputEditable, NumberInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import { colorSelect, types } from '../../../constants';
import { URLParams, enumToSelectData, removeNotEdited } from '../../../utils';
import { IaxiosError } from '../../common';
import { OrganizationCard, useOrganizations } from '../../organizations';
import { NewVehicleTypeForm, useVehicleTypes } from '../../vehicle-types';
import { patchVehicle, useVehicle } from '../api';
import { placeOfIssue } from '../constants';
import { IvehicleInput } from '../types/types';

export function UpdateVehicleForm({ idM }: { idM: number }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [odometer, setOdometer] = useState('odometerKM')


  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [organizationParentsSelect, setOrganizationParentsSelect] = useState([
    { value: '0', label: '' },
  ]);
  const [organizationParentId, setOrganizationParentId] = useState(0);
  const [vehicleTypeSelect, setVehicleTypeSelect] = useState([
    { value: '0', label: '' },
  ]);
  const queryClient = useQueryClient();

  const { data: vehicleTypesData } = useVehicleTypes();
  useEffect(() => {
    if (vehicleTypesData) {
      const tempArr = vehicleTypesData.data.items.map((type) => {
        return {
          value: `${type.id}`,
          label: `${type.model}, ${type.brand}, ${type.year}`,
        };
      });
      setVehicleTypeSelect(tempArr);
    }
  }, [vehicleTypesData]);

  const orgParams = new URLParams()
  orgParams.includes('parent')
  const { data: organizationsData } = useOrganizations({params: orgParams.toString()});
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((organization) => {
        return { value: `${organization.id}`, label: `${organization.name}, ${organization.parent ? organization.parent.name : ''}` };
      });
      setOrganizationParentsSelect(tempArr);
    }
  }, [organizationsData]);

  const { data: vehicleData } = useVehicle(idN, 
    {
      joins: ['organization', 'vehicleType'],
    then: (vehicle) => {
      form.setValues({
        category: vehicle.data.category,
        plateNumber: vehicle.data.plateNumber ? vehicle.data.plateNumber : '',
        vin: vehicle.data.vin,
        placeOfIssue: vehicle.data.placeOfIssue,
        vehicleType: vehicle.data.vehicleType ? vehicle.data.vehicleType.id : 0,
        organization: vehicle.data.organization.id,
        odometerKM: vehicle.data.odometerKM ? vehicle.data.odometerKM : '',
        odometerHours: vehicle.data.odometerHours ? vehicle.data.odometerHours : '',
        color: vehicle.data.color,
        licenseExpiryDate: vehicle.data.licenseExpiryDate?  new Date(vehicle.data.licenseExpiryDate) : null,
        licenseIssueDate: vehicle.data.licenseIssueDate?  new Date(vehicle.data.licenseIssueDate) : null,
      });
      form.resetDirty({
        category: vehicle.data.category,
        plateNumber: vehicle.data.plateNumber ? vehicle.data.plateNumber : '',
        vin: vehicle.data.vin,
        placeOfIssue: vehicle.data.placeOfIssue,
        vehicleType: vehicle.data.vehicleType ? vehicle.data.vehicleType.id : 0,
        organization: vehicle.data.organization.id,
        odometerKM: vehicle.data.odometerKM ? vehicle.data.odometerKM : '',
        odometerHours: vehicle.data.odometerHours ? vehicle.data.odometerHours : '',
        color: vehicle.data.color,
        licenseExpiryDate: vehicle.data.licenseExpiryDate?  new Date(vehicle.data.licenseExpiryDate) : null,
        licenseIssueDate: vehicle.data.licenseIssueDate?  new Date(vehicle.data.licenseIssueDate) : null,
        
      })
      setOrganizationParentId(
        vehicle.data.organization ? vehicle.data.organization.id : 0,
      );
    },
  });

  const form = useForm<IvehicleInput>({
    initialValues: {
      category: vehicleData?.data.category || '',
      plateNumber: vehicleData?.data.plateNumber || '',
      placeOfIssue: vehicleData?.data.placeOfIssue || '',
      vin: vehicleData?.data.vin || '',
      vehicleType: vehicleData?.data.vehicleType?.id || 0,
      organization: vehicleData?.data.organization?.id || 0,
      odometerKM: vehicleData?.data.odometerKM || '',
      odometerHours: vehicleData?.data.odometerHours || '',
      color: vehicleData?.data.color || '',
      licenseExpiryDate: vehicleData?.data.licenseExpiryDate?  new Date(vehicleData?.data.licenseExpiryDate) : null,
      licenseIssueDate: vehicleData?.data.licenseIssueDate?  new Date(vehicleData?.data.licenseIssueDate) : null,
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchVehicle, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Vehicle, types.ALL]);
      queryClient.invalidateQueries([types.Vehicle, { id: idN }]);
      queryClient.invalidateQueries([
        types.History,
        types.Vehicle,
        { id: idN },
      ]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IvehicleInput) {
    setAlert(null);
    const newValues = removeNotEdited(values, form);

    if (idN !== 0 && !Number.isNaN(idN)) {
      patchMutate({ id: idN, data: newValues });
    } else {
      setAlert(['The id provided is incorrect!']);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit a Vehicle:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {patchSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Updated!'}
              color="green"
              mb={10}
            >
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              dirty={form.isDirty('vin')}
              label="Vin"
              placeholder="Vin"
              {...form.getInputProps('vin')}
            />
            <TextInputEditable
              dirty={form.isDirty('category')}
              label="Category"
              placeholder="Category"
              {...form.getInputProps('category')}
              mt='md'
            />
            <SelectEditable
              dirty={form.isDirty('status')}
              label="Place Of Issue"
              placeholder="Search Here"
              {...form.getInputProps('placeOfIssue')}
              data={enumToSelectData(placeOfIssue)}
              mt="md"
            />
            <NumberInputEditable
              dirty={form.isDirty('plateNumber')}
              label="PlateNumber"
              placeholder="PlateNumber"
              {...form.getInputProps('plateNumber')}
              hideControls
              mt="md"
            />
              <DatePickerInputEditable
            dirty={form.isDirty('licenseIssueDate')}
            placeholder="Search Date"
            label="license Issue Date"
            {...form.getInputProps('licenseIssueDate')}
            mt="md"
            />
            <DatePickerInputEditable
            dirty={form.isDirty('licenseExpiryDate')}
            placeholder="Search Date"
            label="license Expiry Date"
            {...form.getInputProps('licenseExpiryDate')}
            mt="md"
            />
            <Grid>
              <Grid.Col span={9}>
                <SelectEditable
                  dirty={form.isDirty('vehicleType')}
                  label="Vehicle Type"
                  placeholder="Search here"
                  {...form.getInputProps('vehicleType')}
                  onChange={(v) => {
                    form.setFieldValue('vehicleType', parseInt(v || '0'));
                  }}
                  value={`${form.values.vehicleType}`}
                  searchable
                  nothingFound="Not found"
                  data={vehicleTypeSelect}
                  mt="md"
                />
              </Grid.Col>
              <Grid.Col span={3} mt={40}>
                <Button onClick={open} fullWidth>add vehicle type</Button>
              </Grid.Col>
            </Grid>
            <SelectEditable
              dirty={form.isDirty('organization')}
              label="Property Of"
              placeholder="Search here"
              {...form.getInputProps('organization')}
              onChange={(v) => {
                form.setFieldValue('organization', parseInt(v || '0'));
                setOrganizationParentId(parseInt(v || '0'));
              }}
              searchable
              nothingFound="Not found"
              value={`${form.values.organization}`}
              data={organizationParentsSelect}
              mt="md"
            />
            <SegmentedControl 
            mt='lg' 
            defaultValue='selectContact' 
            data={[{ label: 'Odometer by Kilometers', value: 'odometerKM' },{ label: 'Odometer by Hours', value: 'odometerHours' }]} 
            onChange={(v) => setOdometer(v)} />
            {odometer === 'odometerKM' &&
            <NumberInputEditable
              dirty={form.isDirty('odometerKM')}
              label="KM"
              placeholder="KM"
              {...form.getInputProps('odometerKM')}
              hideControls
              mt="md"
            />
            }

            {odometer === 'odometerHours' &&
            <NumberInputEditable
              dirty={form.isDirty('odometerHours')}
              label="Hours"
              placeholder="Hours"
              {...form.getInputProps('odometerHours')}
              hideControls
              mt="md"
            />
            }

            <SelectEditable
              dirty={form.isDirty('color')}
              label="Color"
              placeholder="Search here"
              {...form.getInputProps('color')}
              searchable
              nothingFound="Not found"
              data={colorSelect}
              mt="md"
            />
            <Button fullWidth mt="xl" type="submit" disabled={!form.isDirty()}>
              Submit
            </Button>
          </form>
        </Paper>
      </Container>

      <Grid>
        <Grid.Col lg={6}>
          {organizationParentId !== 0 && (
            <DetailsCard
              id={organizationParentId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter(
                  (org) => org.id === organizationParentId,
                )[0].imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationParentId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
      <Modal opened={opened} onClose={close} size="60%">
            <NewVehicleTypeForm/>
      </Modal>
    </>
  );
}
