import { useQuery } from 'react-query';
import { types } from '../../../constants';
import api from '../../common/api/api';
import { Iorganization } from '../types/types';

interface myResOrganization {
  data: Iorganization;
}

export const getOrganization = (id: number, joins?: string[], selects?: string[]): Promise<myResOrganization> => {
  let params = '';
  if (joins) params = '?joins='.concat(joins.join());
  if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
  return api.get(`organizations/${id}${params}`);
};

export const useOrganization = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResOrganization) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Organization, { id }];
  if (options && options.joins) cacheKey.push(...options.joins);
  const query = useQuery(
    cacheKey,
    () =>
      getOrganization(id, options?.joins, options?.selects).then((organization) => {
        options && options.then && options.then(organization);
        return organization;
      }),
    { enabled: id !== 0 },
  );
  return query;
};
