import api from "../../common/api/api";
import { Icertificate, IcertificateInput } from "../types/types";

interface myResCertificate {
	data: Icertificate;
}

export const postCertificate = (data: IcertificateInput): Promise<myResCertificate> => {
	return api.post(`asateel-certificates`, data);
};

export const postCertificates = (data: Record<string, any>[]): Promise<myResCertificate[]> => {
	return api.post(`asateel-certificates/multi`, data);
};
