export enum commonConstants {
  Profile = 'profile',
  AiosApp = 'aios',
  NA = 'Not Available',
}

export enum PageSize {
  Ten = '10',
  Twenty = '20',
  Thirty = '30',
  Forty = '40',
  Fifty = '50',
}
