import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Ivehicle } from '../types/types';

export const ALL_VEHICLES = 'all.vehicle';

interface myResVehicles {
  data: {
    items: Ivehicle[];
    meta: metaData,
  };
}

export const getVehicles = (query = ''): Promise<myResVehicles> => {
  return api.get(`vehicles${query}`);
};

export const useVehicles = (options?: { params?: string; then?: (x: myResVehicles) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Vehicle, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getVehicles(options?.params).then((vehicles) => {
      options && options.then && options.then(vehicles);
      return vehicles;
    }), {enabled: options?.enabled ?? true }
  );
  return query;
};

interface myResCountVehiclesNumber {
  data: number;
}

export const countVehicles = (query = ''): Promise<myResCountVehiclesNumber> => {
  return api.get(`vehicles/count${query}`);
};

export const useCountVehicles = (options?: { params?: string; then?: (x: myResCountVehiclesNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Vehicle, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countVehicles(options?.params).then((vehicles) => {
      options && options.then && options.then(vehicles);
      return vehicles;
    }),
  );
  return query;
};