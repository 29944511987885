import { Button, Flex, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { UseMutateFunction } from 'react-query';
import { useAuthContext } from '../../../context/AuthContext';
import { noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { UserRole } from '../../users';
import { myResTaskPost } from '../api';
import { TaskType } from '../constants';
import { TaskInput } from '../types/types';

type createTask = Omit<TaskInput, 'performedBy' | 'approvedBy' | 'status' | 'jobRemarks'>;

export function ConfirmationModal({
  form,
  newMutate,
  targetTaskGroup,
}: {
  form: UseFormReturnType<createTask, (values: createTask) => createTask>;
  newMutate: UseMutateFunction<myResTaskPost, IaxiosError, Partial<TaskInput>, unknown>;
  targetTaskGroup: string,
}) {
  const { user } = useAuthContext();


  function handleSubmit() {
    const newValues = noEmptyString({ ...form.values });
    if (targetTaskGroup === 'addTaskGroup' && newValues.taskGroup?.id && newValues.taskGroup?.name) newValues.taskGroup.id = undefined;
    if (targetTaskGroup === 'selectTaskGroup' && newValues.taskGroup?.name && newValues.taskGroup?.id) newValues.taskGroup.name = undefined;
    if(newValues.taskGroup?.id === undefined && newValues.taskGroup?.name === undefined) newValues.taskGroup = null;
    if (newValues.unit?.id === 0 || [TaskType.Installation].includes(TaskType[newValues.type as keyof typeof TaskType])) {
      newValues.unit = null;
    }
    if (newValues.recipient?.id === 0) {
      newValues.recipient = null;
    }
    if (newValues.vehicle?.id === 0) {
      newValues.vehicle = null;
    }

    if (newValues.installationUnit?.id === 0) {
      newValues.installationUnit = null;
    }

    if (newValues.contacts?.id === 0) {
      newValues.contacts = null;
    }

    newValues.targetDate = form.values.targetDate;

    if (newValues.targetDate === null) form.setFieldError('targetDate', 'target date can not be empty');
    if (newValues.type === TaskType.Upgrade && newValues.recipientGroup === UserRole.Technician)
      form.setFieldError('recipientGroup', 'technicians can not recieve upgrade task');

    if (user) {
      newValues.issuedBy.id = user.id;
        
        if (newValues.targetDate !== null) {
          newMutate(newValues);
          // console.log(newValues);
        
        }
      } 
  }
  //todo fix this
  return (
    <Flex direction="column" gap="md">
      <Text>the vehicle or unit has another pending task are you sure you want to process</Text>
      <Text>you will not be able to create new task for the same unit for now. This feature will return soon</Text>

      <Button fullWidth onClick={handleSubmit}>
        Submit
      </Button>
    </Flex>
  );
}
