import api from "../../common/api/api";
import { Ivehicle, IvehicleInput } from "../types/types";

interface myResVehiclePatch {
	data: Ivehicle;
}

export const patchVehicle = (props: { id: number; data: Partial<IvehicleInput> }): Promise<myResVehiclePatch> => {
	return api.patch(`vehicles/${props.id}`, props.data);
};

export const patchVehicles = (data: Record<string, any>[]): Promise<myResVehiclePatch[]> => {
	return api.patch('vehicles/multi', data);
};
