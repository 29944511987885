import api from "../../common/api/api";
import { IsimCard, IsimCardInput } from "../types/types";

interface myResSim {
	data: IsimCard;
}

export const patchSimCard = (props: { id: number; data: Partial<IsimCardInput> }): Promise<myResSim> => {
	return api.patch(`sim-cards/${props.id}`, props.data);
};

export const patchSimCards = (data: Record<string, any>[]): Promise<myResSim[]> => {
	return api.patch('sim-cards/multi', data);
};

