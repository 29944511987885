import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { Ihistory } from "../types/types";

interface myResHistory {
	data: Ihistory;
}

export const getHistory = (id: number, joins?: string[], selects?: string[]): Promise<myResHistory> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`history/${id}${params}`);
};

export const useHistory = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResHistory) => any }) => {
	const casheKey: (string | Record<string, number | string | Date>)[] = [types.History, { id }];
	if (options && options.joins) casheKey.push(...options.joins);
	const query = useQuery(
		casheKey,
		() =>
			getHistory(id, options?.joins, options?.selects).then((history) => {
				options && options.then && options.then(history);
				return history;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};
