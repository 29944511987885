import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IsimCard } from '../types/types';

export const ALL_SIMCARDS = 'all.simCard';

interface myResSimCards {
  data: {
    items: IsimCard[];
    meta: metaData,
  };
}

export const getSimCards = (query = ''): Promise<myResSimCards> => {
  return api.get(`sim-cards${query}`);
};

export const useSimCards = (options?: { params?: string; then?: (x: myResSimCards) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.SimCard, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getSimCards(options?.params).then((simCards) => {
      options && options.then && options.then(simCards);
      return simCards;
    }),{enabled: options?.enabled ?? true }
  );
  return query;
};


interface myResCountSimCards {
  data: Record<string, string | number>[];
}

export const analyticsSimCards = (query = ''): Promise<myResCountSimCards> => {
  return api.get(`sim-cards/analytics${query}`);
};

export const useAnalyticsSimCards = (options?: { params?: string; then?: (x: myResCountSimCards) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.SimCard, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      analyticsSimCards(options?.params).then((counts) => {
        options && options.then && options.then(counts);
        return counts;
      }),
    // { staleTime: 1000 * 30 },
  );
  return query;
};

interface myResCountSimCardsNumber {
  data: number;
}

export const countSimCards = (query = ''): Promise<myResCountSimCardsNumber> => {
  return api.get(`sim-cards/count${query}`);
};

export const useCountSimCards = (options?: { params?: string; then?: (x: myResCountSimCardsNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.SimCard, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countSimCards(options?.params).then((simCard) => {
      options && options.then && options.then(simCard);
      return simCard;
    }),
  );
  return query;
};