import { Badge, Checkbox, Group, Modal, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { TbPlaylistAdd, TbTrash, TbX } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, selectColumns, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { AddMultipleTasksToGroup } from '../../task-groups';
import { UserRole, UserRoleColor } from '../../users';
import { useTasks } from '../api';
import { TaskCard } from '../components';
import { TaskColumnsMode, TaskType, TaskTypeColor } from '../constants/constants';
import { Task } from '../types/types';
import { DeleteModal } from './DeleteModal';
import { StatusUpdateModal } from './StatusUpdateModal';

export function PendingTasksTable() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Task[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [ids, setIds] = useState<number[]>([]);
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.filterSetField('status', 'pending');
    param.includes(
      'unit',
      'taskGroup',
      'vehicle',
      'organization',
      'organization.parent as orgParent',
      'unit.device as unitDevice',
      'unit.vehicle as unitVehicle',
      'recipient',
      'issuedBy',
      'performedBy',
      'approvedBy',
    );
    param.select(
      ...selectColumns(TaskColumnsMode),
      'unit.id',
      'taskGroup.id',
      'taskGroup.name',
      'unitVehicle.id',
      'unitVehicle.vin',
      'unitVehicle.plateNumber',
      'vehicle.id',
      'vehicle.vin',
      'vehicle.plateNumber',
      'organization.id',
      'organization.name',
      'orgParent.id',
      'orgParent.name',
      'unitDevice.id',
      'unitDevice.imei',
      'recipient.id',
      'recipient.firstName',
      'issuedBy.id',
      'issuedBy.firstName',
      'performedBy.id',
      'performedBy.firstName',
      'approvedBy.id',
      'approvedBy.firstName',
      'approvedBy.lastName',
    );
    param.page(10, page - 1);
    param.sort('-task.targetDate');
    return param;
  });
  const { data: allTasks, refetch, isLoading } = useTasks({ params: params.toString() });
  const navigate = useNavigate();

  const [opened, { open, close }] = useDisclosure(false);
  const [openedcancelTask, { open: openCancelTask, close: closeCancelTask }] = useDisclosure(false);
  const [openedAddGroup, { open: openAddGroup, close: closeAddGroup }] = useDisclosure(false);
  const customActions = [
    { id: 'delete', multi: false, title: 'Delete', icon: TbTrash, color: 'red', handler: open, noDisplay: [] },
    { id: 'updateStatus', multi: false, title: 'Update Status', icon: TbX, color: 'orange', handler: openCancelTask, noDisplay: [] },
    {
      id: 'groupAdd',
      multi: true,
      title: 'Add to group',
      icon: TbPlaylistAdd,
      color: 'grape',
      handler() {
        openAddGroup();
      },
      noDisplay: [],
    },
  ];

  useEffect(() => {
    if (allTasks) {
      setData(allTasks.data.items);
      const selectedRows = allTasks.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allTasks]);

  const columnHelper = useMemo(() => createColumnHelper<Task>(), []);
  const columns: ColumnDef<Task, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allTasks?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allTasks?.data.meta.ids ? allTasks?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => `${row.id}`, { header: 'ID', id: 'id' }),
      columnHelper.accessor('title', { header: () => 'Title', id: 'title' }),
      columnHelper.accessor('type', {
        header: 'Type',
        id: 'type',
        cell: (props) => (
          <Badge
            variant="light"
            c={TaskTypeColor[props.getValue().split(' ').join('_') as keyof typeof TaskTypeColor]}
            styles={{
              root: { backgroundColor: `${TaskTypeColor[props.getValue().split(' ').join('_') as keyof typeof TaskTypeColor]}40` },
            }}
          >
            {props.getValue()}
          </Badge>
        ),
      }),
      columnHelper.accessor((row) => row.organization.name, { header: 'Organization', id: 'organization.name' }),
      // columnHelper.accessor((row) => row.organization?.parent?.name ?? '', { header: 'Parent', id: 'orgParent.name' }),
      columnHelper.accessor((row) => row.organization?.parent?.name ? row.organization?.name + ' / ' + row.organization?.parent?.name : row.organization?.name, { header: 'Organization & Parent', id: 'organization.name|orgParent.name' }),
      columnHelper.accessor((row) => (row.targetDate ? simpleDateFormat(row.targetDate) : ''), {
        header: 'Request Date',
        id: 'targetDate',
      }),
      columnHelper.accessor('address', { header: () => 'Address', id: 'address' }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.plateNumber : row.unit ? row.unit.vehicle?.plateNumber : ''), {
        header: 'Plate No.',
        id: 'unitVehicle.plateNumber|vehicle.plateNumber',
      }),
      columnHelper.accessor((row) => (row.vehicle ? row.vehicle.vin : row.unit ? row.unit.vehicle?.vin : ''), {
        header: 'Vehicle vin',
        id: 'unitVehicle.vin|vehicle.vin',
      }),
      columnHelper.accessor((row) => row.unit?.device?.imei, {
        header: 'Device imei',
        id: 'unitDevice.imei',
      }),
      columnHelper.accessor((row) => row.taskGroup?.name, {
        header: 'Task Group',
        id: 'taskGroup.name',
      }),
      columnHelper.accessor((row) => row.recipient?.firstName, {
        header: 'recipient',
        id: 'recipient.firstName',
      }),
      columnHelper.accessor((row) => row.taskRemarks, {
        header: 'support remarks',
        id: 'taskRemarks',
      }),
      columnHelper.accessor((row) => row.location, {
        header: 'location',
        id: 'location',
      }),
      columnHelper.accessor((row) => row.issuedBy?.firstName, {
        header: 'issued By',
        id: 'issuedBy.firstName',
      }),
      columnHelper.accessor('recipientGroup', {
        header: 'recipient Group',
        id: 'recipientGroup',
        cell: (props) => {
          if (props.getValue())
            return (
              <Badge
                variant="light"
                c={UserRoleColor[props.getValue().split(' ').join('_') as keyof typeof UserRoleColor]}
                styles={{
                  root: { backgroundColor: `${UserRoleColor[props.getValue().split(' ').join('_') as keyof typeof UserRoleColor]}40` },
                }}
              >
                {props.getValue()}
              </Badge>
            );
        },
      }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    'Request Date': 'date',
  };

  const toSelect = {
    Type: TaskType,
    'recipient Group': UserRole,
  };

  return (
    <Layout>
      <Group px={10} mt={10} mb={20} position="apart">
        <Title
          order={2}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Pending Tasks:
        </Title>
        <AddMultipleButton add={() => navigate('/new/task')} addMany={() => navigate('/new/tasks')} />
      </Group>
      <BaseTable<Task>
        data={data}
        columns={columns}
        setIds={setIds}
        columnTypes={columnTypes}
        ids={ids}
        loading={isLoading}
        toSelect={toSelect}
        defaultVisibleColumns={{
          id: false,
          'issuedBy.firstName': false,
          'organization.name': false,
          recipientGroup: false,
          address: false,
          taskRemarks: false,
          location: false,
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'expander')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        page={page}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        pages={allTasks?.data.meta.ids.length ? Math.ceil(allTasks?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
      />
      {ids.length === 1 && (
        <SideBar id={ids} type={types.Task} customActions={customActions} hideCustomActions={['view']}>
          <TaskCard id={ids[0]} />
        </SideBar>
      )}
      <Modal opened={opened} onClose={close}>
        <DeleteModal id={ids[0]} close={close} setIds={setIds} setRowSelection={setRowSelection} />
      </Modal>
      <Modal opened={openedcancelTask} onClose={closeCancelTask}>
        <StatusUpdateModal close={closeCancelTask} id={ids[0]} setIds={setIds} setRowSelection={setRowSelection} />
      </Modal>
      <Modal opened={openedAddGroup} onClose={closeAddGroup}>
        <AddMultipleTasksToGroup ids={ids} />
      </Modal>
    </Layout>
  );
}
