import { Alert, Button, Container, Divider, Grid, List, LoadingOverlay, Paper, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { useParams } from 'react-router-dom';
import { DetailsCard } from '../../../components/detailsCards';
import { SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { enumToSelectData, noEmptyString, removeNotEdited } from '../../../utils';
import { UploadButton, commonConstants, useUploadImage } from '../../common';
import { IaxiosError } from '../../common';
import { OrganizationCard, useOrganizations } from '../../organizations';
import { IuserProfileInput, patchUserProfile } from '../../user-profiles';
import { patchUser, useUser } from '../api';
import { UserDepartment, UserRole, UserStatus } from '../constants/constants';

interface IuserNewInput {
  username: string;
  email: string;
  password?: string;
  firstName: string | null;
  lastName: string | null;
  status: string;
  profiles: {
    role: string;
    app: string;
    department: string;
    organization: {
      id: number;
    };
    phone: string;
    employeeId: string;
    status: string;
  };
}

//TODO: maybe separate the profile and user, the new relation is OneToMany
export function UpdateUserForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [organizationSelect, setOrganizationSelect] = useState([{ value: '0', label: '' }]);
  const [organizationId, setOrganizationId] = useState(0);
  const [profileId, setProfileId] = useState(0);
  const profileN = useRef<IuserProfileInput | null>(null);
  const queryClient = useQueryClient();

  const [file, setFile] = useState<File | null>(null);
  const { upload, progress, uploadCompleted } = useUploadImage();

  const { data: organizationsData } = useOrganizations();
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((organization) => {
        return { value: `${organization.id}`, label: `${organization.name}` };
      });
      setOrganizationSelect(tempArr);
    }
  }, [organizationsData]);

  const { data: userData } = useUser(idN, { joins: ['profiles', 'profiles.organization'] });

  useEffect(() => {
    if (userData) {
      const profile = userData.data.profiles.find((profile) => profile.app === commonConstants.AiosApp);
      if (profile) {
        form.setValues({
          username: userData.data.username,
          email: userData.data.email,
          firstName: userData.data.firstName || '',
          lastName: userData.data.lastName || '',
          status: userData.data.status,
          profiles: {
            role: profile.role,
            app: profile.app,
            department: profile.department,
            organization: profile.organization,
            status: profile.status,
            phone: profile.phone ? profile.phone : '',
            employeeId: profile.employeeId ? profile.employeeId : '',
          },
        });
        form.resetDirty({
          username: userData.data.username,
          email: userData.data.email,
          firstName: userData.data.firstName || '',
          lastName: userData.data.lastName || '',
          status: userData.data.status,
          profiles: {
            role: profile.role,
            app: profile.app,
            department: profile.department,
            organization: profile.organization,
            status: profile.status,
            phone: profile.phone ? profile.phone : '',
            employeeId: profile.employeeId ? profile.employeeId : '',
          },
        });
        setProfileId(profile.id);
      }
    }
  }, [userData]);

  const form = useForm<IuserNewInput>({
    initialValues: {
      username: userData?.data.username || '',
      email: userData?.data.email || '',
      firstName: userData?.data.firstName || '',
      lastName: userData?.data.lastName || '',
      status: userData?.data.status || 'pending',
      profiles: {
        role: userData?.data.profiles.find((profile) => profile.app === commonConstants.AiosApp)?.role || 'technical_support',
        app: commonConstants.AiosApp,
        department: userData?.data.profiles.find((profile) => profile.app === commonConstants.AiosApp)?.department || 'client',
        organization: {
          id: userData?.data.profiles.find((profile) => profile.app === commonConstants.AiosApp)?.organization?.id || 0,
        },
        phone: userData?.data.profiles.find((profile) => profile.app === commonConstants.AiosApp)?.phone || '',
        employeeId: userData?.data.profiles.find((profile) => profile.app === commonConstants.AiosApp)?.employeeId || '',
        status: userData?.data.profiles.find((profile) => profile.app === commonConstants.AiosApp)?.status || 'pending',
      },
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchUser, {
    onSuccess: (data) => {
      setAlert(null);
      if (profileN.current) {
        patchUserProfile({ id: profileId, data: profileN.current }).then((profile) => {
          const imageRepositoryId = profile.data.imageRepositoryId;
          if (imageRepositoryId && file) {
            upload(
              imageRepositoryId,
              file,
              `${data.data.username}_${commonConstants.Profile}`,
              types.UserProfile,
              types.UserProfile,
              profile.data.id,
            );
            queryClient.invalidateQueries([types.Images, { id: profile.data.imageRepositoryId }]);
          }
        });
      }
      queryClient.invalidateQueries([types.User, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.User, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IuserNewInput) {
    setAlert(null);
    profileN.current = { ...values.profiles, user: { id: idN } };
    const newValues = noEmptyString(removeNotEdited(values, form));
    const finalPatchValues = {
      ...newValues,
      profiles: [newValues.profiles],
    };

    if (idN !== 0 && !Number.isNaN(idN)) {
      if (Object.keys(newValues).length) {
        patchMutate({ id: idN, data: finalPatchValues });
      }
      if (userData && file) {
        const profile = userData.data.profiles.find((profile) => profile.app === commonConstants.AiosApp);
        if (profile?.imageRepositoryId)
          upload(
            profile.imageRepositoryId,
            file,
            `${userData.data.username}_${commonConstants.Profile}`,
            types.User,
            types.UserProfile,
            profile.id,
          );
      }
    } else {
      setAlert(['This id is incorrect!']);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Edit a User:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {(patchSuccess || uploadCompleted) && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Title order={4}>User's Details</Title>
            <Divider size="md" />
            <TextInputEditable
              dirty={form.isDirty('username')}
              label="Username"
              placeholder="Username"
              {...form.getInputProps('username')}
              required
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('email')}
              label="E-mail"
              placeholder="E-mail"
              {...form.getInputProps('email')}
              required
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('firstName')}
              label="First Name"
              placeholder="First Name"
              {...form.getInputProps('firstName')}
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('lastName')}
              label="Last Name"
              placeholder="Last Name"
              {...form.getInputProps('lastName')}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('status')}
              label="User Status"
              placeholder="Search here"
              {...form.getInputProps('status')}
              data={enumToSelectData(UserStatus)}
              required
              mt="md"
            />
            <Title order={4} mt="md">
              User's Profile Information
            </Title>
            <Divider size="md" />
            <SelectEditable
              dirty={form.isDirty('profiles.role')}
              label="Role"
              placeholder="Search here"
              {...form.getInputProps('profiles.role')}
              data={enumToSelectData(UserRole)}
              required
              mt="md"
            />
            <UploadButton file={file} onChange={setFile} progress={progress}>
              {(props) => (
                <Button {...props} mt="md">
                  Select profile image
                </Button>
              )}
            </UploadButton>
            <SelectEditable
              dirty={form.isDirty('profiles.department')}
              label="Department"
              placeholder="Search here"
              {...form.getInputProps('profiles.department')}
              data={enumToSelectData(UserDepartment)}
              required
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('profiles.organization.id')}
              label="Organization"
              placeholder="Search here"
              {...form.getInputProps('profiles.organization.id')}
              onChange={(v) => {
                v && form.setFieldValue('profiles.organization.id', parseInt(v));
                setOrganizationId(parseInt(v || '0'));
              }}
              searchable
              nothingFound="Not found"
              value={`${form.values.profiles.organization.id}`}
              data={organizationSelect}
              mt="md"
            />
            <SelectEditable
              dirty={form.isDirty('profiles.status')}
              label="Profile Status"
              placeholder="Search here"
              {...form.getInputProps('profiles.status')}
              data={enumToSelectData(UserStatus)}
              required
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('profiles.phone')}
              label="Phone Number"
              placeholder="Phone Number"
              {...form.getInputProps('profiles.phone')}
              mt="md"
            />
            <TextInputEditable
              dirty={form.isDirty('profiles.employeeId')}
              label="Employee ID"
              placeholder="Employee ID"
              {...form.getInputProps('profiles.employeeId')}
              mt="md"
            />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {organizationId !== 0 && (
            <DetailsCard
              id={organizationId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter((org) => org.id === organizationId)[0].imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
}
