import { Button, Center, Container, Grid, Group, Loader, Pagination, Select, Title } from '@mantine/core';
import { useState } from 'react';
import { BaseCard, CardsFilter, useDeepFilter } from '../../../components/card';

import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import { PageSize } from '../../common';
import { useSimCardTypes } from '../api';
import { UpdateSimCardTypeForm } from '../components/UpdateSimCardTypeForm';
import { IsimCardType } from '../types/types';

export function SimCardTypeCards() {
  const { isSuccess, data } = useSimCardTypes();

  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const navigate = useNavigate();
  const { filter, setFilter, formattedData } = useDeepFilter(data ? data.data.items : []);

  let cardsLoop;
  if (isSuccess) {
    cardsLoop = formattedData
      .slice((page - 1) * pagesize, pagesize * page)
      .map((item: IsimCardType) => (
        <BaseCard
          key={`card-${item.id}`}
          name={item.type.toString()}
          discription={`Supplier: ${item.supplier}`}
          to={`/sim-card-type/${item.id}`}
          img={item.imageRepositoryId}
          updateModalContent={<UpdateSimCardTypeForm idM={item.id} />}
        />
      ));
  }

  return (
    <Layout>
      <Container fluid pt={10}>
        <Group px={13} mt={10} position="apart">
          <Title order={2} align="left" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
            Sim Card types:
          </Title>
          <Button onClick={() => navigate('/new/sim-card-type')}>Add new</Button>
        </Group>
        <CardsFilter setFilter={setFilter} filter={filter} />
        <Grid>
          {formattedData && cardsLoop ? (
            cardsLoop
          ) : (
            <Container p={25}>
              <Loader />
            </Container>
          )}
        </Grid>
        <Center my={15}>
          <Pagination
            total={Math.ceil(formattedData.length / pagesize)}
            siblings={2}
            boundaries={2}
            onChange={(n) => setPage(n)}
            value={page}
          />
          <Select placeholder="Page Size" size="sm" ml={10} data={Object.values(PageSize)} onChange={(e) => setPagesize(Number(e))} />
        </Center>
      </Container>
    </Layout>
  );
}
