import {
  ColumnDef,
  Row,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

type TableInstanceProps<T extends object> = {
  data: T[];
  columns: ColumnDef<T>[];
  rowSelection: {};
  setRowSelection: React.Dispatch<React.SetStateAction<{}>>;
  getRowCanExpand: (row: Row<T>) => boolean;
  columnVisibility: Record<string, boolean>;
  setColumnVisibility: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
};

export const useTableInstance = <Entity extends { id?: number }>({
  data,
  columns,
  rowSelection,
  setRowSelection,
  getRowCanExpand,
  columnVisibility,
  setColumnVisibility
}: TableInstanceProps<Entity>) => {
  const instance = useReactTable<Entity>({
    data,
    columns,
    state: {
      rowSelection,
      columnVisibility,
    },
    enableRowSelection: true,
    
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return useMemo(
    () => ({
      instance,
    }),
    [instance]
  );
};
