import api from "../../common/api/api";
import { Idevice, IdeviceInput } from "../types/types";

interface myResDevice {
	data: Idevice;
}

export const postDevice = (data: IdeviceInput): Promise<myResDevice> => {
	return api.post(`devices`, data);
};

export const postDevices = (data: Record<string, any>[]): Promise<myResDevice[]> => {
	return api.post(`devices/multi`, data);
};
