import { Container, Paper, Tabs, Title } from '@mantine/core';
import Layout from '../../../components/layout/Layout';
import { GroupTasks, UserTasks } from '../components';

export function MyTasks() {
  return (
    <Layout>
      <Title order={1}>Tasks: </Title>
      <Container pt={10}>
        <Paper withBorder>
          <Tabs defaultValue="userTasks">
            <Tabs.List>
              <Tabs.Tab value="userTasks">User Task</Tabs.Tab>
              <Tabs.Tab value="groupTasks">Group Tasks</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="userTasks" p="sm">
              <UserTasks />
            </Tabs.Panel>

            <Tabs.Panel value="groupTasks" p="sm">
              <GroupTasks />
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </Container>
    </Layout>
  );
}
