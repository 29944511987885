import { Image, Paper, ScrollArea, SimpleGrid, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IcollectionLink, getImageCollection } from '../../modules/common';

export function DetailsCard({
  cardHei,
  withImage,
  image,
  children,
  label = 'Details'
}: {
  cardHei: number;
  id: number;
  label?: string
  withImage?: boolean;
  image?: number | null;
  children: JSX.Element;
}) {
  const [imageLink, setImageLink] = useState<IcollectionLink>();

  useEffect(() => {
    if (image) {
      getImageCollection(image).then((collection) => {
        setImageLink(collection.data.collection.find((img) => img.id === collection.data.previewId) || undefined);
        return collection;
      });
    }
  }, [image]);

  return (
    <Paper px={10} radius={'md'} withBorder style={{ height: cardHei }}>
      <Text pt={10} style={{ fontWeight: 700 }}>
        {label}
      </Text>
      <SimpleGrid cols={withImage ? 2 : 1}>
        {withImage && (
          <Image
            mt={10}
            fit="contain"
            height={cardHei - 60}
            src={imageLink?.path ? `${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${imageLink?.path}` : ''}
            withPlaceholder
          />
        )}
        <ScrollArea style={{ height: cardHei - 37 }}>{children}</ScrollArea>
      </SimpleGrid>
    </Paper>
  );
}
