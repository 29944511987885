type Operator = '&' | '|';

interface Param {
  field: string;
  value: string | number | boolean;
  level: number;
}

interface Level {
  level: number;
  operator: Operator;
}

export class URLGroupParams {
  private search: Param[] = [];
  private levels: Level[] = [];
  private relations: string[] = [];
  private selects: string[] = [];
  private groups: string[] = [];
  private pages: string[] = [];
  private sorts: string[] = [];
  private ranges: string[] = [];
  private periods: string[] = [];
  private dates: string[] = [];
  /**
   * Default Operator is '&',
   * cann't have multiple operators per level (each level one operator)
   */
  constructor(private operatorSymbol: Operator = '&') {}

  private parentOperator = this.operatorSymbol === '|' ? '%7C' : '%26';

  /**
   *
   * @param field column name in the database
   * @param value search value for that column, add = at the start to make it strict
   * @param level 0 is the default and increment by 1 for nested search. the operator is '&' by default, you can use setLevel() to set it
   */
  filterSetField(field: string, value: string | number | boolean, level = 0) {
    const levelIndex = this.levels.findIndex((item) => item.level === level);
    if (levelIndex === -1) {
      this.levels.push({ level, operator: '&' });
    }
    const fieldParam = {
      field,
      value,
      level,
    };
    this.search.push(fieldParam);
  }

  filterRemoveField(field: string) {
    const fieldIndex = this.search.findIndex((item) => item.field === field);
    if (fieldIndex !== -1) {
      this.search.splice(fieldIndex, 1);
    }
  }

  filterGetValue(field: string) {
    return this.search.find((item) => item.field === field);
  }

  /**
   *
   * @param level level number to set
   * @param operator '&' or '|'
   */
  filterSetLevel(level: number, operator: Operator) {
    const levelIndex = this.levels.findIndex((item) => item.level === level);
    if (levelIndex !== -1) {
      this.levels[levelIndex].operator = operator;
    } else {
      this.levels.push({ level, operator });
    }
  }

  filterGetLevels() {
    return this.levels;
  }

  filterResetFilter() {
    this.search = [];
  }

  filterValues() {
    return this.search.map((item) => item.value);
  }

  filterKeys() {
    return this.search.map((item) => item.field);
  }

  /**
   *
   * @returns an array of available key, value and their level
   */
  filterEntries() {
    return this.search;
  }

  /**
   * sets the relations, calling this function again deletes the old relations and set using the new call
   * @param relations relations to include
   */
  joins(...relations: string[]) {
    this.relations = relations;
  }

  /**
   * sets the groups, calling this function again deletes the old groups and set using the new call
   * @param groups columns to group by
   */
  group(...groups: string[]) {
    this.groups = groups;
  }

  /**
   * sets the selects, calling this function again deletes the old selects and set using the new call
   * @param selects columns to select
   */
  select(...selects: string[]) {
    this.selects = selects;
  }

  /**
   * sets the dates, calling this function again deletes the old dates and set using the new call
   * @param dates  Accepts: `YEAR`, `MONTH`, `DAY`, `HOUR` and group by each
   */
  groupByDate(...dates: (`YEAR` | `MONTH` | `DAY` | `HOUR`)[]) {
    dates.filter(unique => unique)
    this.dates = dates;
  }



  /**
   * set the createdAt range in the database
   * @param from start date
   * @param to end date
   */
  range(from: Date | number, to: Date | number, col: string) {
    this.ranges = [`range[from]=${from}`, `range[to]=${to}`, `range[col]=${col}`];
  }

  /**
   * set the col period in the database
   * @param value days ago
   * @param col col name
   */
  period(value: number, col: string) {
    this.periods = [`period[value]=${value}`, `period[col]=${col}`];
  }

  /**
   *
   * @returns a string formated includes all param search options ready to be added to the API URL
   */
  toString() {
    const filter: string[] = [];
    const result: string[] = [];
    if (this.search.length >= 1) {
      if (this.levels.length > 1) {
        this.levels.forEach((level) => {
          const levelOperator = level.operator === '|' ? '%7C' : '%26';
          this.search.forEach((item) => {
            if (level.level === item.level) {
              let filterItem = 'filter';
              filterItem = filterItem.concat(
                '[',
                this.parentOperator,
                '][',
                `${level.level}`,
                '][',
                levelOperator,
                '][',
                item.field,
                ']=',
                `${item.value}`,
              );
              filter.push(filterItem);
            }
          });
        });
      } else {
        this.search.forEach((item) => {
          let filterItem = 'filter';
          filterItem = filterItem.concat('[', this.parentOperator, '][', item.field, ']=', `${item.value}`);
          filter.push(filterItem);
        });
      }
    }
    if (this.search.length) result.push(filter.join('&'));
    if (this.relations.length) result.push('joins='.concat(this.relations.join(',')));
    if (this.groups.length) result.push('groups='.concat(this.groups.join(',')));
    if (this.selects.length) result.push('selects='.concat(this.selects.join(',')));
    if (this.dates.length) result.push('dates='.concat(this.dates.join(',')));
    if (this.pages.length) result.push(this.pages.join('&'));
    if (this.sorts.length) result.push('sort='.concat(this.sorts.join(',')));
    if (this.ranges.length) result.push(this.ranges.join('&'));
    if (this.periods.length) result.push(this.periods.join('&'));
    return '?'.concat(result.join('&'));
  }
}
