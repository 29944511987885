import { ActionIcon, Tooltip } from "@mantine/core"
import { useEffect, useState } from "react"
import { TbDownload } from "react-icons/tb"
import { Iinvoice, InvoiceColumnsMode, useInvoice } from "../../modules/invoices"
import { DownloadAsCsv, Format, getAllKeysWithPath, getKeysWithPath, selectColumns } from "../../utils"

export function ExportInvoice({
    id,
}:{
    id: number
}){
    const [invoice, setInvoice] = useState<Iinvoice>()

    const { data: allVehicles } = useInvoice(id, {
        joins: ['units', 'units.vehicle as unitVehicle', 'units.deviceConfig as unitDeviceConfig'],
        selects: [
            ...selectColumns(InvoiceColumnsMode), 'units.id', 'unitVehicle.id', 'unitVehicle.vin', 'unitVehicle.plateNumber',
            'unitDeviceConfig.id', 'unitDeviceConfig.platform', 'unitDeviceConfig.secondaryPlatform'
        ]
    });
    useEffect(() => {
        if (allVehicles) {
            setInvoice(allVehicles.data)
        }
      }, [allVehicles]);

    function handleDownload(){
        if(invoice){
            const vehicles = invoice.units.map(unit => {
                return {
                    vehicle: unit.vehicle ? {
                        vin: unit.vehicle.vin,
                        plateNo: unit.vehicle?.plateNumber ?? null,
                    } : null,
                    deviceConfig: {
                        platform: unit.deviceConfig?.platform,
                        secondaryPlatform: unit.deviceConfig?.secondaryPlatform,
                    } 
                }
            })
            DownloadAsCsv(vehicles, 'invoice vehicles', getKeysWithPath(getAllKeysWithPath(vehicles)).map(key => {
                return {path: key, alias: key}
              }, [] as Format[]))
        }
    }

    return(
        <Tooltip label={'Download Vehicles'} ><ActionIcon onClick={handleDownload}><TbDownload /></ActionIcon></Tooltip>
    )
}