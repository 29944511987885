import { Alert, Button, List, LoadingOverlay, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { TbAlertOctagon, TbCircleCheck } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { DatePickerInputEditable, NumberInputEditable } from "../../../components/form";
import { types } from "../../../constants";
import { noEmptyString } from "../../../utils";
import { IaxiosError } from "../../common";
import { postPayment } from "../api";
import { IpaymentInput } from "../types/types";

export function NewPaymentForm({id}:{id:number}){
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();

    const form = useForm<IpaymentInput>({
        initialValues: {
            amount: '',
            invoice: {id: id},
            date: new Date(),
            remarks: '',
        }
    })

    const {
        mutate: newMutate,
        isLoading: newLoading,
        isSuccess: newSuccess,
      } = useMutation(postPayment, {
        onSuccess: () => {
          setAlert(null);
          form.reset();
          queryClient.invalidateQueries([types.Payment, types.ALL]);
          queryClient.invalidateQueries([types.Invoice, types.ALL]);
        },
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });

    function handleSubmit(values: IpaymentInput){
      const newValues = noEmptyString(values);
        newMutate(newValues)
    }
    return(
        <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
            <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
            <NumberInputEditable
            label="Amount"
            placeholder="Amount"
            {...form.getInputProps('amount')}
            precision={2}
            required
            />
            <DatePickerInputEditable
              popoverProps={{withinPortal: true}}
                placeholder="Payment Date"
                label="Payment Date"
                {...form.getInputProps(`date`)}
                mt="md"
            />
            <Textarea
              label="Remarks"
              placeholder="Remarks"
              {...form.getInputProps('remarks')}
              mt="md"
            />
            <Button type="submit" fullWidth mt='md'>Submit</Button>
        </form>
    )
}