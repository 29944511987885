import { Card, createStyles, Grid, RingProgress, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  inner: {
    display: 'flex',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  ring: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-around',

    [theme.fn.smallerThan('xs')]: {
      justifyContent: 'center',
      marginTop: theme.spacing.md,
    },
  },
}));

interface StatsRingCardProps {
  title: string;
  totalUnits:number,
  activeUnits:number,
  notActiveUnits:number,
  pendingInstalledUnits:number,
  toBeApprovedInstalledUnits:number,
  pendingRemovalUnits:number,
  toBeApprovedRemovalUnits:number,
  pendingDeviceChangeUnits:number,
  toBeApprovedDeviceChangeUnits:number,
  pendingReinstallationUnits:number,
  toBeApprovedReinstallationUnits:number,
  notInUnit?: number,
  // notes: boolean
}

export function OrgUnitsSummary({ title, totalUnits,activeUnits,notActiveUnits,pendingInstalledUnits,toBeApprovedInstalledUnits, pendingRemovalUnits, toBeApprovedRemovalUnits, notInUnit, pendingDeviceChangeUnits, toBeApprovedDeviceChangeUnits, pendingReinstallationUnits, toBeApprovedReinstallationUnits }: StatsRingCardProps,) {
    const { classes, theme } = useStyles();
    

  return (
    <Card withBorder p='sm' radius="md" className={classes.card} >
      <Grid className={classes.inner}>
        <Grid.Col span={7}>
          
          <Text fz="xl" className={classes.label} mt='sm'>{title}</Text>
          
          <Grid>
            {/* <Grid.Col span={6}>
              <Text fz="xs" color="dimmed">Total</Text>
            </Grid.Col> */}
            <Grid.Col span={6} mt='md'>
                <Text className={classes.label}>{totalUnits}</Text>
                <Text size="xs" color="dimmed">Total</Text>
            </Grid.Col>
            <Grid.Col span={6} mt='md'>
                <Text className={classes.label}>{activeUnits}</Text>
                <Text size="xs" color="dimmed">Active</Text>
            </Grid.Col>

            <Grid.Col span={6} mt='xs'>
                <Text className={classes.label}>{notActiveUnits}</Text>
                <Text size="xs" color="dimmed">not Active</Text>
            </Grid.Col>
            { pendingInstalledUnits !== 0 &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{pendingInstalledUnits}</Text>
                  <Text size="xs" color="dimmed">pending installation</Text>
              </Grid.Col>
            }
            { toBeApprovedInstalledUnits !== 0 &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{toBeApprovedInstalledUnits}</Text>
                  <Text size="xs" color="dimmed">To be Approved installation</Text>
              </Grid.Col>
            }
            { pendingRemovalUnits !== 0 &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{pendingRemovalUnits}</Text>
                  <Text size="xs" color="dimmed">Pending Removal</Text>
              </Grid.Col>
            }
            { toBeApprovedRemovalUnits !== 0 &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{toBeApprovedRemovalUnits}</Text>
                  <Text size="xs" color="dimmed">To be Approved Removal</Text>
              </Grid.Col>
            }
            { pendingReinstallationUnits !== 0 &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{pendingReinstallationUnits}</Text>
                  <Text size="xs" color="dimmed">Pending Removal</Text>
              </Grid.Col>
            }
            { toBeApprovedReinstallationUnits !== 0 &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{toBeApprovedReinstallationUnits}</Text>
                  <Text size="xs" color="dimmed">To be Approved Removal</Text>
              </Grid.Col>
            }
            { pendingDeviceChangeUnits !== 0 &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{pendingDeviceChangeUnits}</Text>
                  <Text size="xs" color="dimmed">Pending Device Change</Text>
              </Grid.Col>
            }
            { toBeApprovedDeviceChangeUnits !== 0 &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{toBeApprovedDeviceChangeUnits}</Text>
                  <Text size="xs" color="dimmed">To be Approved Device Change</Text>
              </Grid.Col>
            }
            { notInUnit !== 0 && notInUnit &&
              <Grid.Col span={6} mt='xs'>
                  <Text className={classes.label}>{notInUnit}</Text>
                  <Text size="xs" color="dimmed">No In a Unit</Text>
              </Grid.Col>
            }

          </Grid>
        </Grid.Col>
        <Grid.Col span={5} className={classes.ring}>
        <RingProgress
              roundCaps
              thickness={6}
              size={130}
              sections={[{ value: !isNaN((activeUnits / (totalUnits)) * 100) ?
              (activeUnits / (totalUnits)) * 100 : 0, color: theme.primaryColor }]}
              label={
                <div>
                  <Text ta="center" fz="lg" className={classes.label}>
                    {!isNaN((activeUnits / (totalUnits)) * 100) ?
                    ((activeUnits / (totalUnits)) * 100).toFixed(0) : 0}%
                  </Text>
                  <Text ta="center" fz="xs" c="dimmed">
                    Units
                  </Text>
                </div>
              }
            />
        </Grid.Col>
      </Grid>

    </Card>
  );
}