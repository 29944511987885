import api from "../../common/api/api";
import { Iattendance, IattendanceInput } from './../types/types';

interface myResattendancePatch {
	data: Iattendance;
}

export const patchAttendance = (props: { id: number; data: Partial<IattendanceInput> }): Promise<myResattendancePatch> => {
	return api.patch(`attendance/${props.id}`, props.data);
};
