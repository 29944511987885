import {
  ActionIcon,
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  MultiSelect,
  NumberInput,
  Paper,
  Select,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import Layout from '../components/layout/Layout';
import { TbAlarmSnooze, TbAlertCircle, TbEye, TbListSearch } from 'react-icons/tb';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { Contacts, postSendNonReportingEmail, useOrganizations } from '../modules/organizations';
import { URLParams, ago, groupBy } from '../utils';
import { UnitWithAllExtras, useUnits } from '../modules/unit';
import { useDisclosure } from '@mantine/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { types } from '../constants';
import { IaxiosError } from '../modules/common';
import { notifications } from '@mantine/notifications';

function NonReportingModal({ id, units, days }: { id: number; units: UnitWithAllExtras[]; days: number }) {
  const queryClient = useQueryClient();

  const params = new URLParams();
  params.searchMany('id', [`${id}`, `${1}`]);
  params.includes('contacts');
  const { data } = useOrganizations({
    params: params.toString(),
  });

  const form = useForm({
    initialValues: {
      id,
      email: '',
      subMails: [] as string[],
      unitsIds: units.map((u) => u.id),
      days,
      includeTimeNotReporting: false,
    },
  });

  const { mutate: newMutate, isLoading: newLoading } = useMutation(postSendNonReportingEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries([types.Organization, { id }]);
      queryClient.invalidateQueries([types.Organization, types.ALL]);
      queryClient.invalidateQueries([types.History, types.Organization, { id }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
      notifications.show({
        title: 'Success',
        message: `A non reporting email has been sent for ${form.values.unitsIds.length} unit${form.values.unitsIds.length > 1 ? 's' : ''}`,
        color: 'green',
        autoClose: 10000,
      });
    },
    onError: (data: IaxiosError) => {
      notifications.show({
        title: 'Error',
        message: `${data.response.data.message}`,
        color: 'red',
        autoClose: false,
      });
    },
  });

  function handleSubmit(values: typeof form.values) {
    if (values.unitsIds.length > 0 && values.email) {
      newMutate(values);
    } else if (values.unitsIds.length === 0) {
      notifications.show({
        title: 'Error',
        message: `Must have at least one unit selected`,
        color: 'red',
        autoClose: 10000,
      });
    } else if (!values.email) {
      notifications.show({
        title: 'Error',
        message: `Must Select an email`,
        color: 'red',
        autoClose: 10000,
      });
    }
  }

  return (
    <>
      <LoadingOverlay visible={newLoading} overlayBlur={2} />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Select
          label="contact"
          {...form.getInputProps('email')}
          data={
            data?.data.items
              .filter((org) => org.id === id)[0]
              ?.contacts.filter((contact) => contact.email)
              .map((contact) => ({ value: contact.email, label: `${contact.name} - ${contact.email}` })) || []
          }
          withinPortal
        />
        <MultiSelect
          label="cc"
          {...form.getInputProps('subMails')}
          data={
            data?.data.items
              .reduce((acc, org) => {
                acc.push(...org.contacts);
                return acc;
              }, [] as Contacts[])
              .filter((contact) => contact.email)
              .map((contact) => ({
                value: contact.email,
                label: `${contact.name} - ${contact.email}`,
                disabled: contact.email === form.values.email,
              })) || []
          }
          withinPortal
        />
        <Checkbox my="sm" label="Include Non Reporting Ago" {...form.getInputProps('includeTimeNotReporting')} />
        <Divider />
        <Stack my="sm" spacing={'xs'}>
          {units.map((unit, i) => (
            <Paper px="sm" h={40} key={`unit-${i}`} withBorder>
              <Flex h="100%" justify="space-between" align="center">
                <Checkbox
                  label={`Unit ${unit.vehicle.plateNumber ?? unit.vehicle.vin} - ${ago(
                    unit.deviceConfig.unitLastMessage?.messageTime || 0,
                  )}`}
                  checked={form.values.unitsIds.includes(unit.id)}
                  onChange={(v) => {
                    v.currentTarget.checked
                      ? form.setFieldValue('unitsIds', [...form.values.unitsIds, unit.id])
                      : form.setFieldValue('unitsIds', [...form.values.unitsIds.filter((u) => u !== unit.id)]);
                  }}
                />
                <ActionIcon>
                  <TbListSearch />
                </ActionIcon>
              </Flex>
            </Paper>
          ))}
        </Stack>
        <Group position="right">
          <Button type="submit">Send</Button>
        </Group>
      </form>
    </>
  );
}

function NonReportingPaper({
  id,
  units,
  type,
  days,
}: {
  id: string;
  units: UnitWithAllExtras[];
  type: 'warn' | 'not-reporting';
  days: number;
}) {
  const [opened, { close, open }] = useDisclosure(false);
  const name = units.filter((u) => u.vehicle.organization.name)[0].vehicle.organization.name || 'No name found';

  return (
    <Paper mih={40} px="sm" withBorder>
      <Flex h="100%" mih={40} justify="space-between" align="center">
        <Group>
          <ThemeIcon variant="light" color={type === 'not-reporting' ? 'red' : 'orange'}>
            {type === 'not-reporting' ? <TbAlertCircle size="1rem" /> : <TbAlarmSnooze size="1rem" />}
          </ThemeIcon>
          <Text>{`${name}: ${units.length} unit${units.length > 1 ? 's' : ''}, last Non Reporting Email sent on: ${
            units[0].vehicle?.organization?.lastNonReportingEmailSent
              ? new Date(units[0].vehicle?.organization?.lastNonReportingEmailSent).toDateString()
              : 'Never'
          }`}</Text>
        </Group>
        <ActionIcon onClick={open} variant="subtle">
          <TbEye size={16} />
        </ActionIcon>
      </Flex>
      <Modal opened={opened} onClose={close} title={`${name} - Non reporting: ${units.length} unit${units.length > 1 ? 's' : ''}`}>
        <NonReportingModal id={+id} units={units} days={days} />
      </Modal>
    </Paper>
  );
}

export function NonReporting() {
  const [organizationSelect, setOrganizationSelect] = useState([{ value: '0', label: '' }]);
  const [fetchenabled, setFetchenabled] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);
  const [unitsParams, setUnitsParams] = useState('');
  const [sort, setSort] = useState({
    by: 'last-email-sent',
    order: 'accending',
  });

  const params = new URLParams();
  params.includes('vehicle', 'vehicle.organization as organization', 'deviceConfig', 'deviceConfig.unitLastMessage as unitLastMessage');
  params.filterSetField('vehicle', 'null');
  params.filterSetField('device', 'null');
  params.filterSetField('simCard', 'null');
  params.filterSetField('deviceConfig.unitLastMessage', 'null');
  const { data: units, refetch } = useUnits({
    params: unitsParams,
    enabled: fetchenabled,
    then(data) {
      setFetchenabled(false);
      setFirstSearch(true);
      return data;
    },
  });

  const form = useForm({
    initialValues: {
      organization: {
        id: 0,
      },
      days: 3,
      hours: 0,
    },
  });

  const { data: organizationsData } = useOrganizations();
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((organization) => {
        return { value: `${organization.id}`, label: `${organization.name}` };
      });
      if (tempArr.length) tempArr.unshift({ value: '0', label: 'All' });
      setOrganizationSelect(tempArr);
    }
  }, [organizationsData]);

  function handleSubmit(values: any) {
    const unixTimeStamp = Math.floor(Date.now() / 1000) - values.days * 86400 - values.hours * 3600;
    if (values.organization.id !== 0) params.filterSetField('organization.id', values.organization.id);
    params.filterSetField('=unitLastMessage.messageTime', `<${unixTimeStamp}`);
    setUnitsParams(params.toString());
    setFetchenabled(true);
    refetch();
  }

  const res = Object.entries(groupBy(units?.data.items.filter((unit) => unit.vehicle) || [], (unit) => unit.vehicle.organization.id))
    .sort(([, unitsPrev], [, unitsNext]) => {
      const prevLastDate = new Date(unitsPrev[0].vehicle?.organization?.lastNonReportingEmailSent || 0).getTime();
      const nextLastDate = new Date(unitsNext[0].vehicle?.organization?.lastNonReportingEmailSent || 0).getTime();
      if (sort.by === 'last-email-sent') {
        if (sort.order === 'accending') {
          return prevLastDate - nextLastDate;
        } else if (sort.order === 'descending') {
          return nextLastDate - prevLastDate;
        }
      } else if (sort.by === 'units-count') {
        if (sort.order === 'accending') {
          return unitsPrev.length - unitsNext.length;
        } else if (sort.order === 'descending') {
          return unitsNext.length - unitsPrev.length;
        }
      }
      return 0;
    })
    .map(([id, units], i) => (
      <NonReportingPaper id={id} units={units as UnitWithAllExtras[]} days={form.values.days} type="not-reporting" key={i} />
    ));

  return (
    <Layout>
      <Container py="xl" pos="relative">
        <LoadingOverlay visible={fetchenabled} overlayBlur={2} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Flex justify="space-between" align="end" mb="md">
            <Group>
              <Select
                label="Organization"
                placeholder="Client"
                {...form.getInputProps('organization.id')}
                onChange={(v) => {
                  v && form.setFieldValue('organization.id', parseInt(v));
                }}
                searchable
                nothingFound="Not found"
                value={`${form.values.organization.id}`}
                data={organizationSelect}
                description="client"
              />
              <Group>
                <NumberInput label="Non Reporting threshold" description="days" min={0} {...form.getInputProps('days')} />
                <NumberInput description="hours" label=" " max={24} min={0} {...form.getInputProps('hours')} />
              </Group>
            </Group>
            <ActionIcon mt="xl" variant="outline" size="lg" type="submit">
              <TbListSearch size="1.7rem" />
            </ActionIcon>
          </Flex>
        </form>
        <Divider />
        <Flex justify="end" my="xs" gap="sm">
          <Select
            label="Sort By"
            value={sort.by}
            data={[
              { value: 'last-email-sent', label: 'Last Email Sent' },
              { value: 'units-count', label: 'Units Count' },
            ]}
            onChange={(v) => {
              if (v) setSort({ ...sort, by: v });
            }}
          />
          <Select
            label="Order"
            value={sort.order}
            data={[
              { value: 'accending', label: 'Accending' },
              { value: 'descending', label: 'Descending' },
            ]}
            onChange={(v) => {
              if (v) setSort({ ...sort, order: v });
            }}
          />
        </Flex>
        {/* <Stack>
          {Array(20)
            .fill('')
            .map((_, i) => (
              <NonReportingPaper type="warn" key={i} />
            ))}
        </Stack>
        <Divider my="xl" /> */}
        <Stack>
          {res.length > 0 ? (
            res
          ) : firstSearch ? (
            <>
              <Divider mt="md" />
              <Stack w="100%" align="center">
                <Text>No units found</Text>
              </Stack>
            </>
          ) : null}
        </Stack>
      </Container>
    </Layout>
  );
}
