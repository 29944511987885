
import Layout from '../../../components/layout/Layout';
import { NewUserForm } from '../components/NewUserForm';


export function NewUserFormPage() {

  return (
    <Layout>
      <NewUserForm/>
    </Layout>
  );
}
