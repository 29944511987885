import { Button, LoadingOverlay } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { TextInputEditable } from "../../../components/form";
import { types } from "../../../constants";
import { IaxiosError } from "../../common";
import { postPreSet } from "../api";

export function NewPreSetForm({
    content,
    type,
}:{
    content:string,
    type:string
}){

    const queryClient = useQueryClient();
    const [ title, setTitle ] = useState('')
    
    const {
    mutate: newMutate,
    isLoading: newLoading,
    } = useMutation(postPreSet, {
    onSuccess: () => {
        notifications.show({
            title: 'Successfull',
            message: `templete was created successfully`,
            color: 'green',
            autoClose: 10000,
            })
        queryClient.invalidateQueries([types.PreSet, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
        });
    },
    onError: (data: IaxiosError) => {
        notifications.show({
            title: 'Invalid',
            message: `${data.response.data.message}`,
            color: 'red',
            autoClose: 10000,
            })
    },
    });

    function handleSubmit() {
        if(content && title){
            newMutate({title: title, content: content, type: type});
        } else {
        notifications.show({
            title: 'Invalid',
            message: `please add some content first`,
            color: 'red',
            autoClose: 10000,
            })
        }
    }

    return(
        <>
            <LoadingOverlay visible={newLoading} overlayBlur={2} />
            <TextInputEditable
              label="Title"
              placeholder="Search here"
              value={title}
              onChange={(v) => setTitle(v.currentTarget.value)}
              required
              mt="md"
            />
          <Button onClick={handleSubmit} fullWidth mt='md'>Submit</Button>
        </>
    )
}