  
  import Layout from '../../../components/layout/Layout';
import { UpdateUnitForm } from '../components/UpdateUnitForm';
    
    export function UpdateUnitFormPage() {
      return (
        <Layout>
          <UpdateUnitForm idM={undefined}/>
        </Layout>
      );
    }
    