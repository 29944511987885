import { Alert, Button, Container, List, LoadingOverlay, Paper, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { DatePickerInputEditable, SelectEditable, TextInputEditable } from '../../../../components/form';
import { types } from '../../../../constants';
import { URLParams, enumToSelectData } from '../../../../utils';
import { simpleDateFormat } from '../../../../utils/simpleDateFormat';
import { IaxiosError, UploadButtonMultiple, useUploadImage } from '../../../common';
import { useOrganizations } from '../../../organizations';
import { postPurchaseOrder } from '../../api';
import { PurchaseOrderInterval, PurchaseOrderStatus, PurchaseOrderType } from '../../constants/constants';
import { IPurchaseOrderInput } from '../../types/type';
import { SelectUnits } from '../plansComponents/SelectUnits';

interface orderInput extends Omit<IPurchaseOrderInput, 'purchaseOrderPlan'> {
  units: { id: number; platform: string; secondaryPlatform: string | null }[];
}
export function NewOrderForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [organizationsSelect, setOrganizationsSelect] = useState([{ value: '', label: '' }]);
  const [organizationId, setOrganizationId] = useState(0);
  const [subClientIds, setsubClientIds] = useState<number[]>([]);

  const [files, setFiles] = useState<File[]>([]);
  const { progress } = useUploadImage();

  const queryClient = useQueryClient();

  const form = useForm<orderInput>({
    initialValues: {
      title: '',
      status: PurchaseOrderStatus.OnGoing,
      remarks: '',
      date: new Date(),
      organization: {
        id: 0,
      },
      units: [],
    },
  });

  const orgParams = new URLParams();
  orgParams.includes('subClients', 'parent');
  orgParams.select('id', 'name', 'subClients.id', 'parent.id', 'parent.name');
  const { data: organizationsData } = useOrganizations({ params: orgParams.toString() });
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((org) => {
        return { value: `${org.id}`, label: `client:${org.name}, parent: ${org.parent?.name ?? ''}` };
      });
      if (tempArr.length === 0) {
        tempArr.push({
          value: `0`,
          label: 'No Clients, Please add the organization first',
        });
      }
      setOrganizationsSelect(tempArr);
    }
  }, [organizationsData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postPurchaseOrder, {
    onSuccess: () => {
      setAlert(null);
      setOrganizationId(0);
      form.reset();
      queryClient.invalidateQueries([types.PurchaseOrder, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: orderInput) {
    setAlert(null);
    const platforms = values.units.reduce((platforms, item) => {
      const platform = Object.assign({}, item);
      if (
        platforms.filter((exist) => exist.platform === platform.platform && exist.secondaryPlatform === platform.secondaryPlatform).length >
        0
      ) {
        return platforms;
      }
      platforms.push(platform);
      return platforms;
    }, [] as { id: number; platform: string; secondaryPlatform: string | null }[]);

    const newValues: IPurchaseOrderInput = {
      title: values.title,
      status: values.status,
      remarks: values.remarks,
      organization: {
        id: values.organization.id,
      },
      date: values.date,
      purchaseOrderPlan: platforms.map((item) => {
        return {
          platform: item.platform,
          secondaryPlatform: item.secondaryPlatform,
          remarks: null,
          type: PurchaseOrderType.Lease,
          interval: PurchaseOrderInterval.Annual,
          units: values.units
            .filter((unit) => unit.platform === item.platform && unit.secondaryPlatform === item.secondaryPlatform)
            .map((unit) => {
              return {
                id: unit.id,
              };
            }),
          quantity: values.units.filter((unit) => unit.platform === item.platform && unit.secondaryPlatform === item.secondaryPlatform)
            .length,
          status: PurchaseOrderStatus.OnGoing,
          endDate: null,
          startDate: null,
        };
      }),
    };
    if (newValues.purchaseOrderPlan.length) {
      newMutate(newValues);
    } else setAlert('Please select at least one unit');
  }

  return (
    <Container pt={10}>
      <Title
        order={2}
        pl={5}
        align="left"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 800,
        })}
      >
        Add a new Purchase Order:
      </Title>
      <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
        <LoadingOverlay visible={newLoading} overlayBlur={2} />
        {alert && (
          <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
            {Array.isArray(alert) ? (
              <List>
                {alert.map((errMsg, index) => (
                  <List.Item key={index}>{errMsg}!</List.Item>
                ))}
              </List>
            ) : (
              alert + '!'
            )}
          </Alert>
        )}
        {newSuccess && (
          <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
            Successfully added to the database!
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInputEditable label="Title" placeholder="Order Title" {...form.getInputProps('title')} disabled required />
          <SelectEditable
            label="Status"
            placeholder="Search Here"
            {...form.getInputProps('status')}
            data={enumToSelectData(PurchaseOrderStatus)}
            mt="md"
          />
          <SelectEditable
            label="Organization"
            placeholder="Search for Organization"
            required
            {...form.getInputProps('organization.id')}
            onChange={(v) => {
              form.setFieldValue('organization.id', v);
              if (v) {
                const foundOrg = organizationsData?.data.items.find((org) => org.id === parseInt(v));
                if (foundOrg) {
                  setsubClientIds(foundOrg.subClients.map((item) => item.id));
                  form.setFieldValue('title', `${foundOrg.name} - ${simpleDateFormat(form.values.date)}`);
                }
              }
              setOrganizationId(parseInt(v || '0'));
            }}
            searchable
            nothingFound="Not found"
            data={organizationsSelect}
            mt="md"
          />
          <DatePickerInputEditable
            placeholder="Order Date"
            label="Order Date"
            required
            {...form.getInputProps(`date`)}
            onChange={(v) => {
              form.setFieldValue('date', v);
              if (v) {
                const foundOrg = organizationsData?.data.items.find((org) => org.id === organizationId);
                if (foundOrg) form.setFieldValue('title', `${foundOrg.name} - ${simpleDateFormat(v)}`);
              }
            }}
            mt="md"
          />
          {form.values.organization.id !== 0 && organizationsData && (
            <SelectUnits
              orgIDs={[form.values.organization.id, ...subClientIds]}
              values={form.values.units.map((item) => item.id)}
              setValues={(units) => {
                form.setFieldValue('units', units);
              }}
              withCSV
            />
          )}
          <Textarea label="remarks" mt="md" {...form.getInputProps('remarks')} />
          <UploadButtonMultiple files={files} onChange={setFiles} progress={progress}>
            {(props) => (
              <Button {...props} mt="md">
                Select any additional documents (images)
              </Button>
            )}
          </UploadButtonMultiple>
          <Button fullWidth mt="xl" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
