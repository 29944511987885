  
  import Layout from '../../../components/layout/Layout';
import { NewRfidForm } from '../components/NewRfidForm';
    
    export function NewRfidFormPage() {
      return (
        <Layout>
          <NewRfidForm/>
        </Layout>
      );
    }
    