
import Layout from '../../../components/layout/Layout';
import { useAuthContext } from '../../../context/AuthContext';
import { UserRole } from '../../users';
import { NewOrderForm } from '../components';

export function NewOrderFormPage() {
   const { user } = useAuthContext()

   if (user?.role !== UserRole.Master && user?.role !== UserRole.Finance) return null


   return (
      <Layout>
         <NewOrderForm />
      </Layout>
   );
}
