export enum VehicleType {
  Car = 'car',
  Bus = 'bus',
  PickUp = 'pickup',
  Truck = 'truck',
  MiniBusVan = 'minibus/van',
  HeavyTruck = 'heavy_truck',
  TankTrailer = 'tank_trailer',
  Loader = 'loader',
  SemiTrailers = 'trailers_semi',
  BucketTruck = 'bucket_truck',
  Forklift = 'forklift',
  StreetCleaningMachine = 'street_cleaning_machine',
  RoadRoller = 'road_roller',
  Crane = 'crane',
  Excavator = 'excavator',
  Motorcycle = 'motorcycle',
  Machinery = 'machinery',
}
export enum VehicleCategory {
  Bus = 'bus',
  Big = 'big',
  Small = 'small',
}

export enum VehicleTypeColumnsMode {
  ID = "id",
  Model = "model",
  Brand = "brand",
  Year = "year",
  Category = "category",
  Type = "type",
  ImageRepositoryId = "imageRepositoryId",
}