import { useEffect } from 'react';
import { Marker, TileLayer, useMap } from 'react-leaflet';

export function BaseMap({
  coords,
  zoom,
  marker,
  setMapStats,
}: {
  coords: [number, number];
  zoom?: number;
  marker?: boolean;
  setMapStats?: React.Dispatch<
    React.SetStateAction<{
      center: [number, number];
      zoom: number;
    }>
  >;
}) {
  const map = useMap();
  console.log('child map rendered');

  map.addEventListener('zoomend', () => {
    if (setMapStats) setMapStats({ center: coords, zoom: map.getZoom() });
  });

  useEffect(() => {
    map.setView(coords, zoom ?? 13, { animate: true });
  }, [coords]);
  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {marker && <Marker position={coords} />}
    </>
  );
}
