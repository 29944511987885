import { Box } from '@mantine/core';
import { useResizableContext } from './Resizable.context';

interface ResizableFirstProps {
  children: React.ReactNode;
  hide?: boolean;
}

export function ResizableFirst({ children, hide = false }: ResizableFirstProps) {
  const ctx = useResizableContext();
  // ctx.setHideFirst(hide);
  if (hide) return null;
  return (
    <Box
      h={ctx.vertical ? (ctx.hideSecond ? '100%' : `${ctx.sideSize.y * 100}%`) : '100%'}
      w={ctx.vertical ? '100%' : ctx.hideSecond ? '100%' : `${ctx.sideSize.x * 100}%`}
    >
      {children}
    </Box>
  );
}

ResizableFirst.displayName = 'ResizableFirst';
