import { Alert, Button, List, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { TbAlertOctagon, TbCircleCheck } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { SelectEditable } from "../../../components/form";
import { types } from "../../../constants";
import { useAuthContext } from "../../../context/AuthContext";
import { enumToSelectData, noEmptyString } from "../../../utils";
import { IaxiosError } from "../../common";
import { DevicePlatforms, patchUnit, useUnit } from "../../unit";
import { patchTask } from "../api";

interface upgradeUnit {
    deviceConfig: {
        id: number;
        platform: string;
        secondaryPlatform: string;
    };
}
export function UpgradeConfirmModal(
{
    unitId,
    taskId
}:
{
    unitId: number,
    taskId: number
}){
    const { user } = useAuthContext();
    const [alert, setAlert] = useState<string | string[] | null>(null);
    
    const { data: unitData } = useUnit(unitId, {
      joins: ['deviceConfig'],
        then: (unit) => {
          form.setValues({
            deviceConfig: {
              id: unit.data.deviceConfig.id,
              platform: unit.data.deviceConfig.platform ? unit.data.deviceConfig.platform : '',
              secondaryPlatform: unit.data.deviceConfig.secondaryPlatform ? unit.data.deviceConfig.secondaryPlatform : '',
            },
          });
          form.resetDirty({
            deviceConfig: {
              id: unit.data.deviceConfig.id,
              platform: unit.data.deviceConfig.platform ? unit.data.deviceConfig.platform : '',
              secondaryPlatform: unit.data.deviceConfig.secondaryPlatform ? unit.data.deviceConfig.secondaryPlatform : '',
            },
          })
        },
      });

      const form = useForm<upgradeUnit>({
        initialValues: {
          deviceConfig: {
            id:unitData?.data.deviceConfig.id || 0,
            platform: unitData?.data.deviceConfig.platform || '',
            secondaryPlatform:
              unitData?.data.deviceConfig.secondaryPlatform || '',
          },
        },
      });
      const queryClient = useQueryClient()
      const { mutate: patchTaskMutate } = useMutation(patchTask, {
        onSuccess: () => {
          notifications.show({
            title: 'Success',
            message: `Successfully Completed`,
            color: 'Green',
            autoClose: 10000,
          })
          queryClient.invalidateQueries([types.Task, types.ALL]);
          queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
          });
        },
        onError: (data: IaxiosError) => {
          notifications.show({
            title: 'Error',
            message: `${data.response.data.message}`,
            color: 'red',
            autoClose: 10000,
          })
        },
      });

      const {
        mutate: patchMutate,
        isLoading: patchLoading,
        isSuccess: patchSuccess,
      } = useMutation(patchUnit, {
        onSuccess: () => {
          patchTaskMutate({id: taskId, data: {
            status: 'done',
            performedBy: { id: user ? user.id : 0 },
            approvedBy: { id: user ? user.id : 0 },
            completionDate: new Date(),
          },})
          setAlert(null);
          queryClient.invalidateQueries([types.Unit, types.ALL]);
          queryClient.invalidateQueries([types.Unit, { id: unitId }]);
          queryClient.invalidateQueries([
            types.History,
            types.Unit,
            { id: unitId },
          ]);
          queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
          });
        },
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });
      
      function handleSubmit(values: upgradeUnit){
        if(values.deviceConfig.platform === values.deviceConfig.secondaryPlatform){
          form.setFieldError('deviceConfig.platform', 'platform and secondaryplatforms can not be the same')
          form.setFieldError('deviceConfig.secondaryPlatform', 'platform and secondaryplatforms can not be the same')
        }else{
          const newValues = noEmptyString(values)
          patchMutate({ id: unitId, data: newValues });
        }
      }
      return(
        <form onSubmit={form.onSubmit(value => handleSubmit(value))}>
        <LoadingOverlay visible={patchLoading} overlayBlur={2} />
        {alert && (
        <Alert
            icon={<TbAlertOctagon size={16} />}
            title="Error!"
            color="red"
            mb={10}
        >
            {Array.isArray(alert) ? (
            <List>
                {alert.map((errMsg) => (
                <List.Item>{errMsg}!</List.Item>
                ))}
            </List>
            ) : (
            alert + '!'
            )}
        </Alert>
        )}
        {patchSuccess && (
        <Alert
            icon={<TbCircleCheck size={16} />}
            title={'Updated!'}
            color="green"
            mb={10}
        >
            Successfully updated in the database!
        </Alert>
        )}
        <SelectEditable
            withinPortal
            dirty={form.isDirty('deviceConfig.platform')}
            label="Platform"
            placeholder="Search here"
            {...form.getInputProps('deviceConfig.platform')}
            data={enumToSelectData(DevicePlatforms)}
            withAsterisk
        />
        <SelectEditable
            withAsterisk
            dirty={form.isDirty('deviceConfig.secondaryPlatform')}
            label="secondaryPlatform"
            placeholder="Search here"
            clearable
            {...form.getInputProps('deviceConfig.secondaryPlatform')}
            data={enumToSelectData(DevicePlatforms)}
            mt="md"
            withinPortal
        />
        <Button type="submit" fullWidth mt='md'>Submit</Button>
        </form>
      )
}