import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Iticket } from '../types/types';

interface myResTicket {
  data: {
    items: Iticket[];
    meta: metaData,
  };
}

export const getTickets = (query = ''): Promise<myResTicket> => {
  return api.get(`tickets${query}`);
};

export const useTickets = (options?: { params?: string; then?: (x: myResTicket) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Ticket, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getTickets(options?.params).then((ticket) => {
      options && options.then && options.then(ticket);
      return ticket;
    }),
  );
  return query;
};

interface myResCountTicketsNumber {
  data: number;
}

export const countTickets = (query = ''): Promise<myResCountTicketsNumber> => {
  
  return api.get(`tickets/count${query}`);
};

export const useCountTickets = (options?: { params?: string; then?: (x: myResCountTicketsNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Ticket, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countTickets(options?.params).then((tickets) => {
      options && options.then && options.then(tickets);
      return tickets;
    }),
  );
  return query;
};

interface myResCountTickets {
  data: Record<string, string | number>[];
}

export const analyticsTickets = (query = ''): Promise<myResCountTickets> => {
  return api.get(`tickets/analytics${query}`);
};

export const useAnalyticsTickets = (options?: { params?: string; then?: (x: myResCountTickets) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Ticket, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      analyticsTickets(options?.params).then((counts) => {
        options && options.then && options.then(counts);
        return counts;
      }),
    // { staleTime: 1000 * 30 },
  );
  return query;
};