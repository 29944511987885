import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { ipreSets } from '../types/types';

interface myResPreSets {
  data: {
    items: ipreSets[];
    meta: metaData,
  };
}

export const getPreSets = (query = ''): Promise<myResPreSets> => {
  return api.get(`presets${query}`);
};

export const usePreSets = (options?: { params?: string; then?: (x: myResPreSets) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.PreSet, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getPreSets(options?.params).then((presets) => {
      options && options.then && options.then(presets);
      return presets;
    }),
  );
  return query;
};
