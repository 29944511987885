import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IsimCardType } from '../types/types';

interface myResSimCardTypes {
  data: {
    items: IsimCardType[];
    meta: metaData,
  };
}

export const getSimCardTypes = (query = ''): Promise<myResSimCardTypes> => {
  return api.get(`sim-card-types${query}`);
};

export const useSimCardTypes = (options?: { params?: string; then?: (x: myResSimCardTypes) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.SimCareType, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getSimCardTypes(options?.params).then((simCardTypes) => {
      options && options.then && options.then(simCardTypes);
      return simCardTypes;
    }),
  );
  return query;
};
