import { flexRender } from '@tanstack/react-table';

import { TableInstanceProps } from '../../../types';
import { ColumnSearchFilter } from '../components';

function TableHeaders<T extends { id?: number }>({
  instance,
  withColumnFilters,
  filterData,
  columnTypes,
  toSelect
}: TableInstanceProps<T> & { withColumnFilters?: boolean, columnTypes?: {}, filterData?: (column: string, data: any, type: string) => void, toSelect?: Record<string, Record<string, string>>}) {
  
  return (
    <thead>
      {instance.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const columnEnum = toSelect ? Object.entries(toSelect).find(([key,value]) => {
              if(header.column.columnDef.header === key)
               return value
            })?.[1] as Record<string, string> : undefined
            return (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <>
                    {!columnEnum ? <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div> : <div style={{marginBottom: '20px'}}></div>}
                    {withColumnFilters && header.column.getCanFilter() && (
                      <ColumnSearchFilter
                        toSelect={toSelect}
                        columnTypes={columnTypes}
                        column={header.column}
                        filterData={filterData}
                      />
                    )}
                  </>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}

export default TableHeaders;
