import { Alert, Button, Container, Grid, List, LoadingOverlay, Paper, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { DetailsCard } from '../../../components/detailsCards';
import { DatePickerInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { enumToSelectData, noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { SimCardTypeCard, useSimCardTypes } from '../../sim-card-types';
import { postSimCard } from '../api';
import { SimStatus } from '../constants/constants';
import { IsimCardInput } from '../types/types';

export function NewSimCardForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [simCardTypesSelect, setSimCardTypesSelect] = useState([{ value: '', label: '' }]);
  const [simCardTypeId, setSimCardTypeId] = useState(0);
  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      number: '',
      serial: '',
      imsi: '',
      simCardType: NaN,
      status: '',
      purchaseDate: new Date(),
      remarks: ''
    },
  });

  const { data: allSimCardTypesData } = useSimCardTypes();
  useEffect(() => {
    if (allSimCardTypesData) {
      const tempArr = allSimCardTypesData.data.items.map((simCard) => {
        return {
          value: `${simCard.id}`,
          label: `${simCard.supplier}, ${simCard.type}`,
        };
      });
      setSimCardTypesSelect(tempArr);
    }
  }, [allSimCardTypesData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postSimCard, {
    onSuccess: () => {
      setAlert(null);
      setSimCardTypeId(0);
      form.reset();
      queryClient.invalidateQueries([types.SimCard, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IsimCardInput) {
    setAlert(null);
    const newValues = noEmptyString({ ...values });
    newMutate(newValues);
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Sim Card:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable label="Number" placeholder="Sim Number" {...form.getInputProps('number')} required />
            <TextInputEditable label="Serial" placeholder="Serial No." {...form.getInputProps('serial')} mt="md" />
            <TextInputEditable label="imsi" placeholder="IMSI No." {...form.getInputProps('imsi')} mt="md" />
            <SelectEditable
              label="Sim Card Type"
              placeholder="Search here"
              {...form.getInputProps('simCardType')}
              onChange={(v) => {
                form.setFieldValue('simCardType', parseInt(v || '0'));
                setSimCardTypeId(parseInt(v || '0'));
              }}
              value={`${form.values.simCardType}`}
              searchable
              nothingFound="Not found"
              data={simCardTypesSelect}
              required
              mt="md"
            />
            <SelectEditable
              label="status"
              placeholder="Search here"
              {...form.getInputProps('status')}
              required
              data={enumToSelectData(SimStatus)}
              mt="md"
            />

            <DatePickerInputEditable
              placeholder="Search Date"
              label="Purchase Date"
              {...form.getInputProps('purchaseDate')}
              clearable
              mt="md"
            />

            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {simCardTypeId !== 0 && (
            <DetailsCard
              id={simCardTypeId}
              cardHei={400}
              image={
                allSimCardTypesData &&
                allSimCardTypesData.data &&
                allSimCardTypesData.data.items.filter((cardtype) => cardtype.id === simCardTypeId)[0].imageRepositoryId
              }
              withImage
            >
              <SimCardTypeCard id={simCardTypeId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
}
