import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IdeviceType } from '../types';

interface myResDeviceTypes {
  data: {
    items: IdeviceType[];
    meta: metaData,
  };
}

export const getDeviceTypes = (query = ''): Promise<myResDeviceTypes> => {
  return api.get(`device-types${query}`);
};

export const useDeviceTypes = (options?: { params?: string; then?: (x: myResDeviceTypes) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.DeviceType, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getDeviceTypes(options?.params).then((deviceTypes) => {
      options && options.then && options.then(deviceTypes);
      return deviceTypes;
    }),
  );
  return query;
};
