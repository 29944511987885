import {
  Alert,
  Container,
  createStyles,
  LoadingOverlay,
  Paper,
  Table,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { useSimCard } from '../api';

const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));

export function SimCardCard({
  id,
  setImage,
}: {
  id: number;
  setImage?: React.Dispatch<React.SetStateAction<number | null>>;
}) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);

  const { isLoading, isError, data, refetch } = useSimCard(id, {joins: ['simCardType']});
  useEffect(() => {
    if (setImage && data) {
      setImage(data.data.simCardType.imageRepositoryId);
    }
  }, [data, setImage]);

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);

  return (
    <Paper pos="relative">
      <Container className={classes.detailsContainer} m={0} p={0}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        {data && data.data && (
          <Table mt={12} p={5}>
            <tbody>
              <tr>
                <td className={classes.leftSideTable}>id:</td>
                <td>{data.data.id}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Number:</td>
                <td>{data.data.number}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Serial:</td>
                <td>{data.data.serial}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>IMSI:</td>
                <td>{data.data.imsi}</td>
              </tr>
              <tr>
                <td className={classes.leftSubtitleTable}>Sim Card Type:</td>
                <td></td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Sim Card Type id:</td>
                <td>{data.data.simCardType.id}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Supplier:</td>
                <td>{data.data.simCardType.supplier}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Type:</td>
                <td>{data.data.simCardType.type}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Status:</td>
                <td>{data.data.status}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Purchase Date:</td>
                <td>{new Date(data.data.purchaseDate).toDateString()}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Remarks:</td>
                <td>{data.data.remarks ?? ''}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {isError && (
          <Alert
            icon={<TbAlertOctagon size={16} />}
            title="Error!"
            color="red"
            mb={10}
          >
            Error Loading data from the server!!
          </Alert>
        )}
      </Container>
    </Paper>
  );
}
