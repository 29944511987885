import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Irfid } from '../types/types';

interface myResRfids {
  data: {
    items: Irfid[];
    meta: metaData,
  };
}

export const getRfids = (query = ''): Promise<myResRfids> => {
  return api.get(`rfids${query}`);
};

export const useRfids = (options?: { params?: string; then?: (x: myResRfids) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.RFID, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getRfids(options?.params).then((rfids) => {
      options && options.then && options.then(rfids);
      return rfids;
    }),
  );
  return query;
};

interface myResCountRFIDNumber {
  data: number;
}

export const countRFIDs = (query = ''): Promise<myResCountRFIDNumber> => {
  return api.get(`rfids/count${query}`);
};

export const useCountRFIDs = (options?: { params?: string; then?: (x: myResCountRFIDNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.RFID, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countRFIDs(options?.params).then((rfid) => {
      options && options.then && options.then(rfid);
      return rfid;
    }),
  );
  return query;
};