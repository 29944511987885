import { Card, Group, Paper } from "@mantine/core"

export function Platforms({platforms, secondaryPlatforms}:{platforms:Record<string, string | number>[], secondaryPlatforms:Record<string, string | number>[],}){
    const data = platforms.map((platform) => {
        return (
            <Paper withBorder>{platform.platform}: {platform.count}</Paper>
        )
    })
    const data2 = secondaryPlatforms.filter(item => item.secondaryPlatform).map((platform) => {
        return (
            <div>{platform.secondaryPlatform}: {platform.count}</div>
        )
    })
    return (
        <Card shadow="sm" padding="lg" radius="md">
            <Card.Section>
                <Group align="space-between">{data}</Group>
            </Card.Section>
            <Card.Section>
                <Group align="space-between">{data2}</Group>
            </Card.Section>
        </Card>
    )
}