import {
  Badge,
  createStyles,
  Group,
  Navbar,
  NavLink,
  ScrollArea,
  Text
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
  
  import {
  TbNote,
  TbNotebook
} from 'react-icons/tb';
  
  import { useMediaQuery } from '@mantine/hooks';
import { useAuthContext } from '../../../context/AuthContext';
import { UserButton } from '../UserButton';
  
  const useStyles = createStyles((theme) => ({
    navbar: {
      paddingTop: 0,
    },
  
    section: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
      marginBottom: theme.spacing.md,
  
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
          }`,
      },
    },
  
    searchCode: {
      fontWeight: 700,
      fontSize: 10,
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
        }`,
    },
  
    mainLinks: {
      paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
      paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
      paddingBottom: theme.spacing.md,
    },
  
    mainLink: {
      display: 'flex',
      textAlign: 'center',
      width: '100%',
      fontSize: theme.fontSizes.xs,
      padding: theme.spacing.xs,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[0]
          : theme.colors.gray[7],
  
      '&:hover': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  
    mainLinkInner: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
  
    mainLinkIcon: {
      marginRight: theme.spacing.sm,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
    },
  
    mainLinkBadge: {
      padding: 0,
      width: 40,
      height: 20,
      pointerEvents: 'none',
      
    },
  
    collections: {
      paddingLeft: `calc(${theme.spacing.md} - 6)`,
      paddingRight: `calc(${theme.spacing.md} - 6)`,
      paddingBottom: theme.spacing.md,
    },
  
    collectionsHeader: {
      paddingLeft: theme.spacing.md + 2,
      paddingRight: theme.spacing.md,
      marginBottom: 5,
    },
  
    collectionLink: {
      display: 'block',
      padding: `8px ${theme.spacing.xs}px`,
      textDecoration: 'none',
      borderRadius: theme.radius.sm,
      fontSize: theme.fontSizes.xs,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[0]
          : theme.colors.gray[7],
      lineHeight: 1,
      fontWeight: 500,
  
      '&:hover': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  }));
  
  interface InavLinks {
    roles: string[];
    emoji: JSX.Element;
    to: string;
    label: string;
    notifications?: number | null
  }
  
  interface ISideNav {
    opened: boolean;
  }
  
  export function ClientSideNav({ opened }: ISideNav) {
    const { classes } = useStyles();
    const navigate = useNavigate();
    
    const { user, close } = useAuthContext();
    const screenSize = useMediaQuery('(max-width: 56.25em)');
    
    
    const collections: InavLinks[] = [
      {
        roles: [],
        emoji: <TbNote size={20} />,
        to: '/new/ticket',
        label: 'New Ticket',
        notifications: null,
      },
      {
        roles: [],
        emoji: <TbNotebook size={20} />,
        to: '/tickets',
        label: 'My Tickets',
        notifications: null,
      },
    ];
    
    const collectionLinks = collections.map(
      (collection) =>
        user &&
        (collection.roles.length === 0 ||
          collection.roles.includes(user?.role)) && (
          <NavLink
            pl={18}
            key={collection.label}
            icon={collection.emoji}
            label={
            <Group position='apart'>
              <Text>{collection.label}</Text>
              {collection.notifications && (
                <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
                  {collection.notifications}
                </Badge>
              )}
            </Group>}
            onClick={() => {navigate(collection.to); screenSize && close() }}
          />
        ),
    );
  
  
    if (!opened) return null;
    return (
      <Navbar
        hiddenBreakpoint="sm"
        width={{ sm: 200, lg: 300 }}
        className={classes.navbar}
      >
        {user && (
          <Navbar.Section
            className={classes.section}
            onClick={() => navigate('/profile')}
          >
            <UserButton image={user.img} name={user.username} role={user.role} />
          </Navbar.Section>
        )}
        <Navbar.Section className={classes.section} grow component={ScrollArea}>
          <Group className={classes.collectionsHeader} position="apart">
            <Text size="xs" weight={500} color="dimmed" pl='md'>
              Collections
            </Text>
          </Group>
          {collectionLinks}
        </Navbar.Section>
      </Navbar>
    );
  }
  