import { ActionIcon, Alert, Button, Code, Container, Group, List, LoadingOverlay, Paper, Table, TextInput, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbAlertTriangle, TbCircleCheck, TbDownload, TbPlus } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { useAuthContext } from '../../../context/AuthContext';
import { URLParams, handleDates, noEmptyString, useCsv } from '../../../utils';
import { IaxiosError } from '../../common';
import { Iorganization, useOrganizations } from '../../organizations';
import { useUnits } from '../../unit';
import { postTasks } from '../api';

export function AddMultipleTasks() {
  const [file, setFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [organization, setOrganization] = useState<Iorganization[]>([]);
  const [taskGroupSuggestion, setTaskGroupSuggestion] = useState('');
  const viewSubmit = document.getElementById('submit');
  const viewTopOfPage = document.getElementById('TopOfPage');

  const queryClient = useQueryClient();
  const { user } = useAuthContext();

  const requirments = {
    title: /^[\s\S]*$/,
    type: /\b(installation|removal|retrival|device change|simCard change|inspection|re Installation|upgrade|other)\b/,
    recipient: /^$|^\d+$/,
    recipientGroup: /^$|^\b(admin|technician|technical support|finance|client)+$/,
    address: /^$|^[\s\S]*$/,
    location: /^$|^[\s\S]*$/,
    targetDate: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    organization: /^\d+$/,
    contactName: /^$|^[\s\S]*$/,
    contactNumber: /^$|^[\s\S]*$/,
    unit: /^$|^\d+$/,
    taskGroup: /^$|^[\s\S]*$/,
    taskRemarks: /^$|^[\s\S]*$/,
  };
  const templateText = `${Object.keys(requirments).join(',')}\r\n`;

  const { csvParser, data, headers, errorRows, validate, setItem, addRow } = useCsv(requirments, Object.keys(requirments));

  function handleSelectFile(value: File | null) {
    setFile(value);
    if (value) {
      csvParser(value);
    }
  }

  useEffect(() => {
    if (errorRows.length === 0) {
      viewSubmit?.scrollIntoView();
    }
  }, [data]);

  const unitParams = new URLParams();
  unitParams.includes('device');
  unitParams.select('id');
  const { data: unitData } = useUnits({ params: unitParams.toString() });

  const { data: OrgsData } = useOrganizations();
  useEffect(() => {
    if (OrgsData) setOrganization(OrgsData.data.items);
  }, [OrgsData]);

  useEffect(() => {
    console.log('effect running');
    const formattedDate = data ? data[0].targetDate.split('/').join('-') : '';
    if (data)
      setTaskGroupSuggestion(`${OrgsData?.data.items.find((org) => org.id === +data[0].organization)?.abbreviation}-${formattedDate}`);
  }, [data]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postTasks, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit() {
    setAlert(null);
    viewTopOfPage?.scrollIntoView();
    if (data && user) {
      const tempAlert: string[] = [];
      const newValues = data
        .map((item) => noEmptyString(item))
        .map((item) => ({ ...item, taskGroup: item.taskGroup ? { name: item.taskGroup } : null } as typeof item));
      //TODO: fix add multiple installation task
      const finalValues = newValues
        .map((task) => {
          task.issuedBy = user.id;
          if (!task.recipient && !task.recipientGroup)
            tempAlert.push('task with title ' + task.title + ' has to have one of two recepient or recipientGroup');
          if (!task.unit && !task.taskRemarks)
            tempAlert.push('task with title ' + task.title + ' has to have one of three vehicle or unit or taskRemarks');
          if (task.unit) {
            const foundUnit = unitData?.data.items.find((unit) => unit.id === +task.unit);
            if (foundUnit) {
              task.unit = { id: foundUnit.id };
            } else {
              tempAlert.push('task with title ' + task.title + ' has wrong unit id ' + task.unit + ' for the selected unit');
            }
          }
          if (!organization.some((org) => org.id === +task.organization))
            tempAlert.push('organization with ID ' + task.organization + ' was not found');
          task.organization = { id: task.organization };
          task.targetDate = handleDates(task.targetDate);
          return task;
        });

      if (tempAlert.length === 0) {
        newMutate(finalValues);
        // console.log(finalValues);
      } else setAlert(tempAlert);
    } else {
      setAlert('something went wrong while preparing the data, please reload the page and try again.');
    }
  }

  const rows = data?.map((row, i) => (
    <tr key={'r' + i}>
      {headers.map((key, index) => (
        <td key={key + index}>
          <TextInput
            value={row[key]}
            error={errorRows[index]?.includes(i + 1)}
            variant="unstyled"
            onChange={(v) => setItem(i, key, v.target.value)}
          />
        </td>
      ))}
    </tr>
  ));

  return (
    <Layout>
      <Container pt={10} fluid>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add Multiple Tasks:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <Group position="apart">
            <UploadCSVButton file={file} onChange={handleSelectFile}>
              {(props) => (
                <Button {...props} mt="md">
                  Upload CSV
                </Button>
              )}
            </UploadCSVButton>
            <Button
              mt="md"
              component="a"
              href={`data:text/csv;charset=utf-8,${templateText}`}
              download={'newDevicesTemplate.csv'}
              leftIcon={<TbDownload size={16} />}
            >
              Download a template
            </Button>
          </Group>
          <Alert icon={<TbAlertTriangle size={16} />} title="Important!" withCloseButton color="blue" mt="md">
            <List id="TopOfPage">
              <List.Item>Currently only accepts CSV files, file must include the headers.</List.Item>
              <List.Item>title: must be NOT empty and does not include special characters.</List.Item>
              <List.Item>
                type: must be one of these values(<Code>installation</Code>, <Code>re Installation</Code>, <Code>removal</Code>,{' '}
                <Code>retrival</Code>, <Code>device change</Code>, <Code>simCard change</Code>, <Code>inspection</Code>,{' '}
                <Code>upgrade</Code>, <Code>other</Code>).
              </List.Item>
              <List.Item>recipient: optional, must be any number.</List.Item>
              <List.Item>
                recipientGroup: optional, must be one of these values(<Code>admin</Code>, <Code>technician</Code>,{' '}
                <Code>technical support</Code>, <Code>finance</Code>, <Code>client</Code>
                ).
              </List.Item>
              <List.Item>address: optional, Any characters.</List.Item>
              <List.Item>location: optional, google maps link.</List.Item>
              <List.Item>targetDate: must be NOT empty and its format is dd/mm/yyyy.</List.Item>
              <List.Item>organization: The id of the client, must be a number.</List.Item>
              <List.Item>contactName: optional, Any characters.</List.Item>
              <List.Item>contactNumber: optional, Any characters.</List.Item>
              <List.Item>unit: not empty, unit id</List.Item>
              <List.Item>
                <Group spacing={0}>
                  taskGroup: optional. suggested name: {taskGroupSuggestion}{' '}
                  <ActionIcon
                    onClick={() => {
                      if (data) {
                        data.forEach((_, i) => {
                          setItem(i, 'taskGroup', taskGroupSuggestion);
                        });
                      }
                    }}
                  >
                    <TbCircleCheck />
                  </ActionIcon>
                </Group>
              </List.Item>
              <List.Item>taskRemarks: optional.</List.Item>
            </List>
          </Alert>
          {data && Object.keys(requirments).length !== headers.length && (
            <Alert icon={<TbAlertTriangle size={16} />} title="Warning!" color="orange" mt="md">
              The number of column uploaded is different than expected!
            </Alert>
          )}
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {validate() && (
            <Alert icon={<TbAlertTriangle size={16} />} title="Error!" color="red" mt="md">
              <List>
                <List.Item>Errors in the following rows:</List.Item>
                {errorRows.map((errorRow, i) => (
                  <List.Item key={`error-${headers[i]}`}>
                    {headers[i]}: {errorRow.join(', ')}
                  </List.Item>
                ))}
              </List>
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10} mt="md">
              Successfully added to the database!
            </Alert>
          )}
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10} mt="md">
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item key={errMsg}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {data && (
            <Table striped highlightOnHover mt="md">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Group position="right" mt="md">
            <ActionIcon size="lg" variant="subtle">
              <TbPlus size={28} onClick={() => addRow()} />
            </ActionIcon>
          </Group>
          <Button id="submit" fullWidth disabled={(!data && !file) || validate() || newLoading} onClick={handleSubmit} mt="md">
            Submit
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
}
