import { Checkbox, Group, Text, TransferList, TransferListItem, TransferListItemComponentProps } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { RFID_LIST_SINGLE_ALL_RFIDS, getRfidListWithAllRfids } from '../../modules/rfid-lists';
import { useFormContext } from './formContext';

type selectType = { value: string; label: string; bceValue: string; teltonikaValue: string }[];
type myTrListItem = { value: string; label: string; bceValue: string; teltonikaValue: string };

const ItemComponent = ({ data, selected, value }: TransferListItemComponentProps & { value: string }) => (
  <Group noWrap>
    <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: 'none' }} />
    <div style={{ flex: 1 }}>
      <Text size="sm" weight={400}>
        {data[value === 'Printed' ? 'label' : value === 'Bce' ? 'bceValue' : value === 'Teltonika' ? 'teltonikaValue' : 'label']}
      </Text>
    </div>
  </Group>
);

export function TransferListRfid({
  id,
  value,
  reset,
  setReset,
}: {
  id: number;
  value: string;
  reset: boolean;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [data, setData] = useState<[TransferListItem[], TransferListItem[]]>([
    [{ value: '0', label: 'loading...', bceValue: 'loading...', teltonikaValue: 'loading...' }],
    [{ value: '0', label: 'loading...', bceValue: 'loading...', teltonikaValue: 'loading...' }],
  ]);
  useEffect(() => {
    if (reset) setData([[], [...data[0], ...data[1]]]);
    setReset(false);
  });
  const form = useFormContext();

  const { data: customData } = useQuery(RFID_LIST_SINGLE_ALL_RFIDS, () =>
    getRfidListWithAllRfids(id).then(({ singleList, allRfids }) => {
      const rfidIds: number[] = (singleList.rfidLists || []).map((rfid) => {
        return rfid.id;
      });

      const { selected, notSelected } = allRfids.reduce<{
        selected: selectType;
        notSelected: selectType;
      }>(
        (acc, rfid) => {
          const isSelected = rfidIds.includes(rfid.id);
          acc[isSelected ? 'selected' : 'notSelected'].push({
            value: `${rfid.id}`,
            label: rfid.printedValue,
            bceValue: rfid.bceValue,
            teltonikaValue: rfid.teltonikaValue,
          });
          return acc;
        },
        {
          selected: [],
          notSelected: [],
        },
      );
      setData([selected, notSelected]);

      form.setFieldValue('rfids', [selected, notSelected]);
      return { singleList, allRfids };
    }),
  );

  const CustomItemComponent = useMemo(() => (props: any) => ItemComponent({ ...props, value }), [value]);

  return (
    <TransferList
      {...form.getInputProps('rfids')}
      value={data}
      onChange={(v) => {
        form.setFieldValue('rfids', v);
        setData(v);
      }}
      searchPlaceholder="Search..."
      nothingFound="Nothing here"
      titles={['Rfids in this list', 'All Rfids']}
      breakpoint="sm"
      mt="md"
      listHeight={300}
      itemComponent={CustomItemComponent}
    />
  );
}
