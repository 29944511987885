import api from "../../common/api/api";
import { IAccessoriesType, IAccessoriesTypeInput } from "../types";

interface myResAccessoryType {
	data: IAccessoriesType;
}

export const patchAccessoryType = (props: { id: number; data: Partial<IAccessoriesTypeInput> }): Promise<myResAccessoryType> => {
	return api.patch(`accessories-type/${props.id}`, props.data);
};

export const patchAccessoriesType = (data: Record<string, any>[]): Promise<myResAccessoryType[]> => {
	return api.patch('accessories-type/multi', data);
};

