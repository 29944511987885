import { useEffect, useState } from 'react';
import { commonConstants } from '../../modules/common';
import { useUsers } from '../../modules/users';
import { DownloadAsCsv, Format, URLParams, getAllKeysWithPath, getKeysWithPath } from '../../utils';
import { ExportToCsvButton } from '../table/components';

export function ExportUsers({ ids }: { ids: number[] }) {
  const user = {
    id: 0,
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    status: '',
    profiles: {
      id: 0,
      role: '',
      department: '',
      phone: '',
      employeeId: '',
      status: '',
      organization: {
        id: 0,
        name: '',
      },
    },
  };

  const [fetch, setFetch] = useState(false);
  const [fileName, setFileName] = useState('');
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes('profiles', 'profiles.organization as org');

    param.sort('-user.id');
    return param;
  });
  // params.se
  const { data: allUsers, refetch } = useUsers({ params: params.toString(), enabled: fetch });
  useEffect(() => {
    if (allUsers) {
      const data = allUsers.data.items.map((user) => {
        return { ...user, profiles: user.profiles.find((profile) => profile.app === commonConstants.AiosApp) };
      });
      DownloadAsCsv(
        data,
        fileName,
        getKeysWithPath(getAllKeysWithPath(data)).map((key) => {
          return { path: key, alias: key };
        }, [] as Format[]),
      );
    }
  }, [allUsers]);

  return (
    <ExportToCsvButton
      keys={getKeysWithPath(user, [], ':')}
      onSubmit={(v) => {
        setFileName(v.fileName);
        const paths = Object.values(v.columns).reduce((cols, item) => {
          const path = handleReplacePath(item.path, [{ originalPath: 'profiles.organization', replaceTo: 'org' }]);

          if (item?.checked) cols.push(path);
          return cols;
        }, [] as string[]);
        setParams((param) => {
          param.select(...paths, 'profiles.app');
          param.resetSearchMany();
          param.searchMany(
            'id',
            ids.map((id) => `${id}`),
          );
          // param.filterRemoveSelects()
          // param.filterRemoveAllField('=id')
          // ids.forEach(id => {
          //     param.filterSetLevel(1 , '|')
          //     param.filterSetField('=id', id, 1)
          // })
          return param;
        });

        if (!fetch) {
          setFetch(true);
        } else refetch();
      }}
    />
  );
}
function handleReplacePath(path: string, replaces: { originalPath: string; replaceTo: string }[]) {
  const found = replaces.find((item) => path.includes(item.originalPath));
  const paths = path.split('.');
  const column = paths[paths.length - 1];
  if (found) return found.replaceTo + '.' + column;
  else return path;
}
