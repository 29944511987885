import api from "../../common/api/api";
import { Irecord, IrecordUpdate } from "../types/types";

interface myResRecordPatch {
	data: Irecord;
}

export const patchRecord = (props: { id: number; data: Partial<IrecordUpdate> }): Promise<myResRecordPatch> => {
	return api.patch(`records/${props.id}`, props.data);
};
