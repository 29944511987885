import { useParams } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import { useAuthContext } from '../../../context/AuthContext';
import { TicketCard } from './TicketCard';

export function TicketDetails() {
  const { user } = useAuthContext();

  const { id } = useParams();
  let idN = 0;
  if (typeof id === 'string') {
    idN = parseInt(id);
  }

  return <Layout>{user && <TicketCard id={idN} userID={user.id} role={user?.role} />}</Layout>;
}
