import api from "../../common/api/api";
import { IsimCardType, IsimCardTypeInput } from "../types/types";

interface myResDevice {
	data: IsimCardType;
}

export const patchSimCardType = (props: { id: number; data: Partial<IsimCardTypeInput> }): Promise<myResDevice> => {
	return api.patch(`sim-card-types/${props.id}`, props.data);
};
