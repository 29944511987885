import {
  ActionIcon,
  Alert,
  Button,
  Container,
  Divider,
  Grid,
  List,
  LoadingOverlay,
  Paper,
  Select,
  Textarea,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck, TbX } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { DetailsCard } from '../../../components/detailsCards';
import { SelectEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, enumToSelectData, noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { DeviceCard, useDevices } from '../../devices';
import { useRfidLists } from '../../rfid-lists';
import { SimCardCard, useSimCards } from '../../sim-cards';
import { VehicleCard, useVehicles } from '../../vehicles';
import { postUnit } from '../api';
import { DevicePlatforms } from '../constants/constants';
import { IunitInput } from '../types/types';
import { NewAccessoriesTransferList } from './NewAccessoriesTransferList';


export function NewUnitForm({vehicleID}: {vehicleID?:number}) {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [deviceSelect, setDeviceSelect] = useState([
    { value: '', label: 'Loading...' },
  ]);
  const [deviceId, setDeviceId] = useState(0);
  const [simCardSelect, setSimCardSelect] = useState([
    { value: '', label: 'Loading...' },
  ]);
  const [simCardId, setSimCardId] = useState(0);
  const [vehicleSelect, setVehicleSelect] = useState([{ value: '', label: 'Loading...' }])
  const [vehicleId, setVehicleId] = useState(vehicleID ?? 0)
  const [rfidListSelect, setRfidListSelect] = useState([
    { value: '', label: 'Loading...' },
  ]);
  const queryClient = useQueryClient();

  const form = useForm<IunitInput>({
    initialValues: {
      device: {
        id: 0,
      },
      simCard: {
        id: 0,
      },
      vehicle: {
        id: vehicleID ?? 0
      },
      deviceConfig: {
        platform: '',
        secondaryPlatform: '',
        externalId: '',
        rfidList: {
          id: NaN,
        },
      },
      accessories: [],
      accessoriesTypes: [],
      remarks: '',
    },
  });

  const deviceParams = new URLParams();
  deviceParams.includes('deviceType', 'unit');
  deviceParams.filterSetField('=unit.id', 'null')
  const { data: deviceData } = useDevices({ params: deviceParams.toString() });
  useEffect(() => {
    if (deviceData) {
      const tempArr = deviceData.data.items
        .map((device) => {
          return { value: `${device.id}`, label: `${device.imei}` };
        });
      setDeviceSelect(tempArr);
    }
  }, [deviceData]);

  const simCardParams = new URLParams();
  simCardParams.includes('simCardType', 'unit');
  simCardParams.filterSetField('=unit.id', 'null')
  const { data: simCardsData } = useSimCards({
    params: simCardParams.toString(),
  });
  useEffect(() => {
    if (simCardsData) {
      const tempArr = simCardsData.data.items.map((simCard) => {
          return { value: `${simCard.id}`, label: `${simCard.number}` };
        });
      setSimCardSelect(tempArr);
    }
  }, [simCardsData]);

  const { data: rfidListsData } = useRfidLists();
  useEffect(() => {
    if (rfidListsData) {
      const tempArr = rfidListsData.data.items.map((rfidList) => {
        return { value: `${rfidList.id}`, label: `${rfidList.name}` };
      });
      tempArr.unshift({ value: '', label: 'No RfidList' });
      setRfidListSelect(tempArr);
    }
  }, [rfidListsData]);


  const { data: vehiclesData } = useVehicles()
  useEffect(() => {
    if (vehiclesData) {
      const tempArr = vehiclesData.data.items.map((vehicle) => {
        return { value: `${vehicle.id}`, label: `${vehicle.vin}, ${vehicle.plateNumber}` }
      })
      tempArr.unshift({ value: '0', label: 'No vehicle' })
      setVehicleSelect(tempArr)
    }
  }, [vehiclesData])

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postUnit, {
    onSuccess: () => {
      setAlert(null);
      setDeviceId(0);
      setSimCardId(0);
      setVehicleId(0)
      form.reset();
      queryClient.invalidateQueries([types.Unit, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IunitInput) {
    setAlert(null);
    const newValues = noEmptyString(values);
    if (newValues.deviceConfig.rfidList?.id === 0) {
      newValues.deviceConfig.rfidList = null;
    }
    if (newValues.vehicle?.id === 0) {
      newValues.vehicle = null;
    }
    if (newValues.device?.id === 0) {
      newValues.device = null;
    }
    if (newValues.simCard?.id === 0) {
      newValues.simCard = null;
    }
    if ( values.device || values.simCard  || values.vehicle ) {
      if(Object.values(newValues.accessories).length !== 0){
        const accessories = Object.values(newValues.accessories).map((id) => {
          const obj = { id: 0 };
          obj['id'] = ((id as {id: number}).id);
          return obj;
        });
        newValues.accessories = accessories
      } else newValues.accessories = null
      if(Object.values(newValues.accessoriesTypes).length !== 0){
        const accessoriesType = Object.values(newValues.accessoriesTypes).map((id) => {
          const obj = { id: 0 };
          obj['id'] = ((id as {id: number}).id);
          return obj;
        });
        newValues.accessoriesTypes = accessoriesType
      }else {
        newValues.accessoriesTypes = null
      }
      newMutate(newValues);
    } else {
      setAlert(['one of Device and Sim Card or Vehicle can not be empty!']);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Unit:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Title order={4}>Unit</Title>
            <Divider size="md" />
              <Select
                label="Device"
                placeholder="Search Here"
                rightSection={form.values.device?.id !== 0  && <ActionIcon onClick={() => form.setFieldValue('device.id', 0)}><TbX /></ActionIcon>}
                {...form.getInputProps('device.id')}
                onChange={(v) => {
                  v && form.setFieldValue('device.id', parseInt(v));
                  setDeviceId(parseInt(v || '0'));
                }}
                searchable
                value={`${form.values.device?.id}`}
                nothingFound="Not found"
                data={deviceSelect}
                disabled={vehicleID ? true : false}

                mt="md"
              />
            {/*  */}
            <Select
              label="Sim Card"
              placeholder="Search here"
              disabled={vehicleID ? true : false}
              {...form.getInputProps('simCard.id')}
              rightSection={form.values.simCard?.id !== 0 &&  <ActionIcon onClick={() => form.setFieldValue('simCard.id', 0)}><TbX /></ActionIcon>}
              onChange={(v) => {
                v && form.setFieldValue('simCard.id', parseInt(v));
                setSimCardId(parseInt(v || '0'));
              }}
              value={`${form.values.simCard?.id}`}
              searchable
              nothingFound="Not found"
              data={simCardSelect}
              mt="md"
            />
            <Select 
            label="Vehicle" 
            placeholder="Search Here" 
            rightSection={form.values.vehicle?.id && form.values.vehicle.id !== 0 && !isNaN(form.values.vehicle.id) && <ActionIcon onClick={() => form.setFieldValue('vehicle.id', 0)}><TbX /></ActionIcon>}
            {...form.getInputProps('vehicle.id')} 
            onChange={(v) => {
              v && form.setFieldValue("vehicle.id", parseInt(v))
              setVehicleId(parseInt(v || '0'))
            }} 
            searchable 
            nothingFound="Not found" 
            data={vehicleSelect} 
            mt="md"
            value={`${form.values.vehicle?.id}`} 
            />
            <Title order={4} mt="md">
              Device Config
            </Title>
            <Divider size="md" />
            <SelectEditable
              label="Platform"
              placeholder="Search here"
              {...form.getInputProps('deviceConfig.platform')}
              data={enumToSelectData(DevicePlatforms)}
              mt="md"
            />
            <SelectEditable
              label="Secondary Platform"
              placeholder="Search here"
              {...form.getInputProps('deviceConfig.secondaryPlatform')}
              data={enumToSelectData(DevicePlatforms)}
              mt="md"
            />
            <TextInputEditable
              label="External Id"
              placeholder="External Id"
              {...form.getInputProps('deviceConfig.externalId')}
              mt="md"
            />
            <SelectEditable
              label="Rfid List"
              placeholder="Search here"
              {...form.getInputProps('deviceConfig.rfidList.id')}
              onChange={(v) => {
                v && form.setFieldValue('deviceConfig.rfidList.id', parseInt(v));
              }}
              value={`${form.values.deviceConfig.rfidList?.id}`}
              searchable
              nothingFound="Not found"
              data={rfidListSelect}
              mt="md"
            />
            <NewAccessoriesTransferList form={form} />
            <Textarea
              label="Remarks"
              placeholder="Remarks"
              {...form.getInputProps('remarks')}
              mt="md"
            />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {deviceId !== 0 && (
            <DetailsCard
              id={deviceId}
              cardHei={400}
              image={
                deviceData &&
                deviceData.data &&
                deviceData.data.items.filter((org) => org.id === deviceId)[0]
                  .deviceType.imageRepositoryId
              }
              withImage
            >
              <DeviceCard id={deviceId} />
            </DetailsCard>
          )}
        </Grid.Col>
        <Grid.Col lg={6}>
          {simCardId !== 0 && (
            <DetailsCard
              id={simCardId}
              cardHei={400}
              image={
                simCardsData &&
                simCardsData.data &&
                simCardsData.data.items.filter((org) => org.id === simCardId)[0]
                  .simCardType.imageRepositoryId
              }
              withImage
            >
              <SimCardCard id={simCardId} />
            </DetailsCard>
          )}
        </Grid.Col>
        <Grid.Col lg={6} >
          {vehicleId !== 0 &&
            <DetailsCard id={vehicleId} cardHei={400} image={vehiclesData && vehiclesData.data && vehiclesData.data.items.filter((org) => org.id === vehicleId)[0].imageRepositoryId} withImage>
              <VehicleCard id={vehicleId} />
            </DetailsCard>
          }
        </Grid.Col>
      </Grid>
    </>
  );
}
