import { useEffect, useState } from "react"
import { useVehicles } from "../../modules/vehicles"
import { DownloadAsCsv, Format, URLParams, dateToNewDate, getAllKeysWithPath, getKeysWithPath } from "../../utils"
import { ExportToCsvButton } from "../table/components"

export function ExportVehicles({
    ids,
}:{
    ids: number[]
}){
    const vehicle = {
        id: 0,
        category: "",
        plateNumber: 0,
        placeOfIssue: "",
        vin: "",
        vehicleType: {
            id: 0,
            model: "",
            brand: "",
            year: 0,
            type: "",
            category: "",
        },
        organization: {
            id: 0,
            name: "",
            parent: {
                id: 0,
                name: "",
            },
        },
        color: "",
        odometerKM: 0,
        odometerHours: 0,
        licenseIssueDate: new Date(),
        licenseExpiryDate: new Date(),
    }

    const [ fetch, setFetch ] = useState(false)
    const [ fileName, setFileName ] = useState('')
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
        'vehicleType',
        'organization',
        'organization.parent as orgParent',
        );
        
        param.sort(('-vehicle.id'))
        return param
      })
    // params.se
    const { data: allVehicles, refetch } = useVehicles({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (allVehicles) {
          DownloadAsCsv(dateToNewDate(allVehicles.data.items, ['licenseIssueDate', 'licenseExpiryDate']), fileName, getKeysWithPath(getAllKeysWithPath(allVehicles.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [allVehicles]);

    return(
        <ExportToCsvButton keys={getKeysWithPath(vehicle,[], ':')} onSubmit={(v => {
            setFileName(v.fileName)
            const paths = Object.values(v.columns).reduce((cols, item) => {
                    const path = handleReplacePath(item.path, [{originalPath: 'organization.parent', replaceTo: 'orgParent'}])
                    
                    if (item?.checked) cols.push(path)
                    return cols
                  }, [] as string[])
            setParams(param => {
                param.select(...paths)
                param.resetSearchMany()
                param.searchMany('id', ids.map(id => `${id}`))
                // param.filterRemoveSelects()
                // param.filterRemoveAllField('=id')
                // ids.forEach(id => {
                //     param.filterSetLevel(1 , '|')
                //     param.filterSetField('=id', id, 1)
                // })
                return param
            })
            
            if(!fetch){
                setFetch(true)
            } else refetch()
        })}/>
    )
}
function handleReplacePath(path: string, replaces:{originalPath: string, replaceTo: string}[]){
    const found = replaces.find(item => path.includes(item.originalPath))
    const paths = path.split('.')
    const column = paths[paths.length-1]
    if(found) return found.replaceTo+'.'+column
    else return path
}