export function duplicatesFinder<T extends Record<string, any>, R extends Record<string, any>>(
	dataSource: T[],
	dataTarget: R[],
	keySource: keyof T,
	keyTarget: keyof R
) {
	const duplicates: R[typeof keyTarget][] = [];
	dataTarget.forEach((itemTarget) => {
		dataSource.forEach((itemSource) => {
			if (itemTarget[keyTarget] === (itemSource[keySource] as unknown) && !duplicates.includes(itemTarget[keyTarget])) {
				duplicates.push(itemTarget[keyTarget]);
			}
		});
	});
	return duplicates;
}