import api from "../../api/api";
import { IsignUpUser, IsignUpUserInput } from "../types/types";


interface myRes {
	data: IsignUpUser;
}

export const signUp = (data: IsignUpUserInput): Promise<myRes> => {
	return api.post(`auth/signup`, data);
};
