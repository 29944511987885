import { useQuery } from "react-query";
import api from "../../common/api/api";
import { types } from "../../../constants";
import { IrfidList } from "../types/types";
import { Irfid } from "../../rfids";

export const RFID_LIST_SINGLE_ALL_RFIDS = "rfid.list.single.all.rfids";
export const RFID_LIST_SINGLE_ALL_RFIDLISTS = "rfid.list.single.all.rfidLists";

interface myResRfidList {
	data: IrfidList;
}

export const getRfidList = (id: number): Promise<myResRfidList> => {
	return api.get(`rfid-list/${id}`);
};

export const useRfidList = (id: number, options?: { then?: (x: myResRfidList) => any }) => {
	const query = useQuery(
		[types.RFIDList, { id }],
		() =>
			getRfidList(id).then((rfidList) => {
				options && options.then && options.then(rfidList);
				return rfidList;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};

// export const getRfidListWithAllRfids = (id: number): Promise<MyResRfidListWithRfids> => {
// 	return api.get(`rfid-list/${id}`).then((rfidList: IrfidList) => {
// 		return api.get(`rfids`).then((rfids: Irfid[]) => ({ rfidList, rfids }))
// 	});
// };

export const getRfidListWithAllLists = (id: number) => {
	const singleList = api.get<IrfidList>(`rfid-list/${id}`);
	const allLists = api.get<Record<"items", IrfidList[]>>(`rfid-list`);

	return Promise.all([singleList, allLists]).then(([singleList, allLists]) => ({
		singleList: singleList.data,
		allLists: allLists.data.items,
	}));
};

export const getRfidListWithAllRfids = (id: number) => {
	const singleList = api.get<IrfidList>(`rfid-list/${id}`);
	const allRfids = api.get<Record<"items", Irfid[]>>(`rfids`);

	return Promise.all([singleList, allRfids]).then(([singleList, allRfids]) => ({
		singleList: singleList.data,
		allRfids: allRfids.data.items,
	}));
};
