import {
  ActionIcon,
  Alert,
  Container,
  createStyles,
  Group,
  LoadingOverlay,
  Paper,
  Table,
  Tabs
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { AiFillCar } from 'react-icons/ai';
import { MdOpenInNew } from 'react-icons/md';
import { TbAlertOctagon } from 'react-icons/tb';
import { VscVmActive } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { useVehicle } from '../api';

const useStyles = createStyles({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
});

export function VehicleCard({
  id,
  setImage,
  setVehicleImage,
}: {
  id: number;
  setImage?: React.Dispatch<React.SetStateAction<number | null>>,
  setVehicleImage?: React.Dispatch<React.SetStateAction<number | null>>
}) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);
  const navigate = useNavigate();

  const { isLoading, isError, data, refetch } = useVehicle(id, {
    joins: ['unit', `unit.deviceConfig`, 'vehicleType', 'organization']
  });
  useEffect(() => {
    if (data && setImage) {
      setImage(data.data.vehicleType.imageRepositoryId);
    }
  }, [data, setImage]);

  useEffect(() => {
    if (data && setVehicleImage) {
      setVehicleImage(data.data.imageRepositoryId);
    }
  }, [data, setVehicleImage]);

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);

  const displayUnitTab = data?.data.unit?.map((item, index) => {
    if (item.device && item.simCard)
      return (
        <Tabs.Tab value={'unit' + index} icon={<VscVmActive size="0.8rem" />} key={index}>unit {index + 1}</Tabs.Tab>
      )
  }).filter(item => item)
  const displayUnitPanel = data?.data.unit?.map((item, index) => {
    if (item.device && item.simCard)
      return (
        <Tabs.Panel value={'unit' + index} key={index}>

          <Table mt={12} p={5}>
            <tbody>
              <tr>
                <td className={classes.leftSideTable}>id:</td>
                <td>{item.id}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Device imei:</td>
                <td>{item.device.imei}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Sim Card Number:</td>
                <td>{item.simCard.number}</td>
              </tr>
              <tr>
                <td className={classes.leftSubtitleTable}>Device Config:</td>
                <td></td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Platform:</td>
                <td>{item.deviceConfig.platform}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>External Id:</td>
                <td>{item.deviceConfig.externalId}</td>
              </tr>
              {/* <tr>
                <td className={classes.leftSideTable}>Rfid List:</td>
                <td>{item.deviceConfig.rfidList?.name}</td>
              </tr> */}
              <tr>
                <td className={classes.leftSideTable}>remarks:</td>
                <td>{item.remarks}</td>
              </tr>
            </tbody>
          </Table>
        </Tabs.Panel>
      )
  }).filter(item => item)
  return (
    <Paper pos="relative">
      <Container className={classes.detailsContainer} m={0} p={0}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        {data && data.data && (
          <Tabs defaultValue="vehicle">
            <Tabs.List>
              <Tabs.Tab value="vehicle" icon={<AiFillCar size="0.8rem" />}>vehicle</Tabs.Tab>
              {displayUnitTab}
            </Tabs.List>


            <Tabs.Panel value='vehicle'>
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSideTable}>id:</td>
                    <td>{data.data.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Vin:</td>
                    <td>{data.data.vin}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Place of Issue:</td>
                    <td>{data.data.placeOfIssue}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Plate Number:</td>
                    <td>{data.data.plateNumber}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Category:</td>
                    <td>{data.data.category}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Odometer by KM:</td>
                    <td>{data.data.odometerKM}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Odometer by Hours:</td>
                    <td>{data.data.odometerHours}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Color:</td>
                    <td>{data.data.color}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>license Issue Date:</td>
                    <td>{data.data.licenseIssueDate ? new Date(data.data.licenseIssueDate).toDateString() : ''}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>license Expiry Date:</td>
                    <td>{data.data.licenseExpiryDate ? new Date(data.data.licenseExpiryDate).toDateString() : ''}</td>
                  </tr>
                  {data.data.vehicleType && (
                    <>
                      <tr>
                        <td className={classes.leftSubtitleTable}>Vehicle Type:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Brand:</td>
                        <td>{data.data.vehicleType.brand}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Model:</td>
                        <td>{data.data.vehicleType.model}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Type:</td>
                        <td>{data.data.vehicleType.type}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Category:</td>
                        <td>{data.data.vehicleType.category}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Year:</td>
                        <td>{data.data.vehicleType.year}</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td className={classes.leftSubtitleTable}>Property Of:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Name:</td>
                    <td>
                      <Group position="apart">
                        {data.data.organization.name}
                        <ActionIcon
                          onClick={() =>
                            navigate(`/organization/${data.data.organization.id}`)
                          }
                        >
                          <MdOpenInNew size={18} />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Tabs.Panel>

            {displayUnitPanel}
          </Tabs>
        )}
        {isError && (
          <Alert
            icon={<TbAlertOctagon size={16} />}
            title="Error!"
            color="red"
            mb={10}
          >
            Error Loading data from the server!!
          </Alert>
        )}
      </Container>
    </Paper>
  );
}
