import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { TbEye } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import { InvoicesMiniTable, NewInvoiceForm } from "../../invoices"

export function OrderActions({orderID, planIDs}:{orderID: number, planIDs: number[]}){
    const [openedInvoices , {open: openInvoices, close:closeInvoices}] = useDisclosure(false)
    const navigate = useNavigate()
    const [openedNewInvoice , {open: openNewInvoice, close:closeNewInvoice}] = useDisclosure(false)
    return(
        <>
            <Tooltip label="View Invoices" ><ActionIcon onClick={openInvoices}><TbEye size={15} /></ActionIcon></Tooltip>
            <Modal.Root opened={openedInvoices} onClose={closeInvoices} size={'65%'}>
                <Modal.Overlay />
                <Modal.Content>
                <Modal.Header>
                    <Modal.Title>Invoices</Modal.Title>
                    <Group>
                        <Button onClick={openNewInvoice}>Add Invoice</Button>
                        <Button onClick={() => navigate(`/finance/detailedinvoice/${orderID}`)}>Veiw Details</Button>
                        <Modal.CloseButton />
                    </Group>
                </Modal.Header>
                <Modal.Body><InvoicesMiniTable orderID={orderID} /></Modal.Body>
                </Modal.Content>
            </Modal.Root>
            <Modal opened={openedNewInvoice} onClose={closeNewInvoice} size={'80%'} title={<Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}` ,fontWeight: 800, fontSize:'1.625rem' })}>New Invoice</Text>}>
                    <NewInvoiceForm planIds={planIDs} orderID={orderID} />
            </Modal>
        </>
    )
}