import jsPDF from "jspdf";
import { useCallback } from "react";
import logo from "../assets/ats_logo.png";
import contact from '../assets/contact.png';
import signature from '../assets/signature.png';
import { Iquotation, QuotationPlanType } from "../modules/quotations";
import { displayText, square, table } from "./jsPDFSquare";
import { simpleDateFormat } from "./simpleDateFormat";
export function quotationPDF(
    quotation: Iquotation,
){
    const firstChargeTable = quotation.quotationPlan.findIndex(item => item.type === QuotationPlanType.FirstPayment)
    const renwalPaymentTable = quotation.quotationPlan.findIndex(item => item.type === QuotationPlanType.Renwal)
    const addOnsTable = quotation.quotationPlan.findIndex(item => item.type === QuotationPlanType.OptionalAddOns)

    function handleHTML(value: string, fontStyles: string[] = [], color:string): {value: string, fontStyle: string[], color:string}[] {
        if(value.includes('<')){
            const start = value.indexOf('<')
            const end = value.indexOf('>')
            const tagTypeWithStyles = value.slice(start+1, end)
            const closingTag = tagTypeWithStyles.split(' ')[0]
            const closingTagIndex = value.indexOf(`</${closingTag}>`)
            const styles = tagTypeWithStyles.slice(closingTag.length)
            const rest = value.slice(closingTagIndex +`</${closingTag}>`.length)
            const FinalValue = value.slice(end + 1, closingTagIndex)
            if(rest){
                if(FinalValue.indexOf('<') !== -1){
                    const restValues =  [...handleHTML(rest, [...fontStyles], color? color :styles)]
                    return [...handleHTML(FinalValue, [...fontStyles, closingTag], color? color :styles), ...restValues]
                } 
                else{
                    const restValues =  [...handleHTML(rest, [...fontStyles], color? color :styles)]
                    return([...handleHTML(FinalValue,[...fontStyles, closingTag], color? color :styles), ...restValues])        
                }
            }
            else{
                return [...handleHTML(FinalValue,[...fontStyles, closingTag], color? color :styles)]
            }
        }
        else{
            return([getValue(value, fontStyles, color ? color : '')])        
        }
    }
        
    function getValue(value:string, fontStyles:string[], color:string){
        const finalColor = color ? handleColor(color) : '#000000'
            let fontStyle = ['']
            if(fontStyles.includes('strong') && fontStyles.includes('em')){
                fontStyle = ['times','bolditalic']  
            }else if(fontStyles.includes('strong')){
                fontStyle = ['times','bold']
            }else if(fontStyles.includes('em')){
                fontStyle = ['times','italic']
            }else {
                fontStyle = ['times','normal']
            }
            return({value: value, fontStyle: fontStyle, color: finalColor})
    }
    function handleColor(color: string){
        const colorRegex = /rgb\([0-9]+, [0-9]+, [0-9]+\)/g
        const finalColor =  color.match(colorRegex)
        if(finalColor){
            const colorArr = finalColor[0].slice(4,-1).split(',')
            return `#${covertNumber(colorArr[0].trim())}${covertNumber(colorArr[1].trim())}${covertNumber(colorArr[2].trim())}`
        }else return  'black'
    }

    function covertNumber(num: string){
        const firstVal = parseInt(`${+num/16}`)  
        const secondVal = parseInt(`${+num%16}`)

        return `${numToHex(firstVal)}${numToHex(secondVal)}`
    }
    function numToHex(num: number){
        if(num === 15) return 'F'
        if(num === 14) return 'E'
        if(num === 13) return 'D'
        if(num === 12) return 'C'
        if(num === 11) return 'B'
        if(num === 10) return 'A'
        else return num
    }

    function addNewPage(currentHeight: number, maxHeight: number, doc: jsPDF, height: number, width: number){
        if(currentHeight > maxHeight){
            doc.addPage(undefined, 'p')
            doc.addImage(logo, "JPEG", width - 60, 2, 50, 35);
            doc.addImage(contact, "JPEG", 5, height - 25, width - 10, 20);
            return 35
        }else return currentHeight
    }
    

    

    const tetsDoc = new jsPDF();
    type docType = typeof tetsDoc;

    const createPdf = useCallback(
    () => {
        const doc:docType = new jsPDF({ orientation: 'portrait' });
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();
        let textWidth = 0;

        doc.setProperties({
        title: quotation.name
        });
        doc.viewerPreferences({DisplayDocTitle: true});
        doc.addImage(logo, "JPEG", width - 60, 2, 50, 35);
    //   doc.setFont("Times", "Bold");
    //   doc.setTextColor('#62e317')
        displayText(doc, {text: quotation.title, x: width/2.3, y: 35, color:'#62e317', font: ['Times', 'Bold']}, {maxWidth: 100, align:'center', direct: true})
    //   doc.text(quotation.title, width/2.3, 35, {maxWidth: 30, align: 'center'});
        doc.addImage(contact, "JPEG", 5, height - 25, width - 10, 20);

        
        table(doc, 5, 55,5,2, 97.5,10, [{rowNo: 2, columnNo:1, removeBorder: ['top']}, {rowNo: 3, columnNo:1, removeBorder: ['top']}, {rowNo: 4, columnNo:1, removeBorder: ['top']},{rowNo: 5, columnNo:1, removeBorder: ['top']}, {rowNo: 2, columnNo:2, removeBorder: ['top']}, {rowNo: 3, columnNo:2, removeBorder: ['top']}, {rowNo: 4, columnNo:2, removeBorder: ['top']}, {rowNo: 5, columnNo:2, removeBorder: ['top']}])
        doc.setFont("Times", "Normal");
        doc.setFontSize(9);
        doc.text(`Customer: ${quotation.organization.name}`, 10, 60, {maxWidth: 68});
        doc.text(`Attn: ${quotation.contact?.name ?? ''}`, 10, 70, {maxWidth: 68});
        doc.text(`Title: ${quotation.contact?.position ?? ''}`, 10, 80, {maxWidth: 68});
        doc.text(`Phone: ${quotation.contact?.phone ?? ''}`, 10, 90, {maxWidth: 68});
        doc.text(`Email: ${quotation.contact?.email ?? ''}`, 10, 100, {maxWidth: 68});
        
        doc.text(`Reference Number: ${quotation.referenceNo}`, 105, 60, {maxWidth: 68});
        doc.text(`Date: ${simpleDateFormat(quotation.qautationDate)}`, 105, 70, {maxWidth: 68});
        doc.text(`Country Of Origin: ${quotation.countryOfOrigin}`, 105, 80, {maxWidth: 68});
        doc.text(`Quotation Validity: ${quotation.validity} Days`, 105, 90, {maxWidth: 68});
        //   doc.
        let startPoint = 115
        let widthMoreThan1Line = 0
        let widthLessThan1Line = 0
        if(firstChargeTable !== -1){
            if(quotation.quotationPlan[firstChargeTable].massage !== null){
                const massages = handleHTML(quotation.quotationPlan[firstChargeTable].massage as string, [], '')
                massages.forEach((massage) => {
                    displayText(doc, {text: massage.value, x: 5, y: startPoint, color: massage.color, font: [massage.fontStyle[0], massage.fontStyle[1]]}, {maxWidth: 200, direct: true})
                    startPoint = startPoint + 4
            })
            doc.setFont('times', 'normal');
            doc.setTextColor('black')
        }
        
        startPoint = startPoint + 8
        if(quotation.quotationPlan[firstChargeTable].title) displayText(doc, {text: quotation.quotationPlan[firstChargeTable].title as string, x: 5, y: startPoint - 1.5}, {direct: true})
        square(doc, {x1: 5, x2: 15, y1: startPoint, y2: startPoint + 10}, 'Item', {fontSize: 8})
        square(doc, {x1: 15, x2: 140, y1: startPoint, y2: startPoint + 10}, 'Description', {fontSize: 8})
        square(doc, {x1: 140, x2: 155, y1: startPoint, y2: startPoint + 10}, 'Quantity', {fontSize: 8})
        square(doc, {x1: 155, x2: 180, y1: startPoint, y2: startPoint + 10}, 'Price/Per Vehicle', {fontSize: 8})
        square(doc, {x1: 180, x2: 200, y1: startPoint, y2: startPoint + 10},'Total (AED)', {fontSize: 8})
        // let pushTax = 0 
        let totalAmount = 0
        startPoint = startPoint + 10
        quotation.quotationPlan[firstChargeTable].quotationContent.forEach((item, itemIndex) => {
            const description = handleHTML(item.description, [], '')
            description.forEach((desc, index) => {
                displayText(doc, {text: desc.value, color: desc.color, font: desc.fontStyle, x:17, y: startPoint + 3 + (index *4) + (3*itemIndex)}, {direct: true})
            })
            square(doc, {x1: 5, x2: 15, y1: startPoint + (3*itemIndex), y2: startPoint + (description.length*4) + (3*(itemIndex +1))}, `${itemIndex + 1}`)
            square(doc, {x1: 15, x2: 140, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))})
            square(doc, {x1: 140, x2: 155, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.quantity}`)
            square(doc, {x1: 155, x2: 180, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.price}`)
            square(doc, {x1: 180, x2: 200, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.price*item.quantity}`)
            // square(doc, {x1: 10, x2: 20, y1: })
            startPoint = startPoint + (description.length*4)
            totalAmount = totalAmount + (item.price*item.quantity)
        })
        startPoint = startPoint + (quotation.quotationPlan[firstChargeTable].quotationContent.length*3)
        square(doc, {x1: 5, x2: 180, y1: startPoint, y2: startPoint + 10 }, 'Sub-Total', {postions: 'right'})
        square(doc, {x1: 180, x2: 200, y1: startPoint, y2: startPoint + 10 }, `${totalAmount}`)
        startPoint = startPoint + 10
        if(quotation.discount){
            totalAmount = totalAmount-quotation.discount
            square(doc, {x1: 5, x2: 180, y1: startPoint, y2: startPoint + 10 }, 'Discount (AED)', {postions: 'right'})
            square(doc, {x1: 180, x2: 200, y1: startPoint, y2: startPoint + 10 }, `${quotation.discount}`)
            startPoint = startPoint + 10
            square(doc, {x1: 5, x2: 180, y1: startPoint, y2: startPoint + 10 }, 'Total(After Discount)', {postions: 'right', xOffset: 3})
            square(doc, {x1: 180, x2: 200, y1: startPoint, y2: startPoint + 10 }, `${totalAmount}`)
            startPoint = startPoint + 10
        }
        square(doc, {x1: 5, x2: 180, y1: startPoint, y2: startPoint + 10 }, `5% VAT as per the UAE Law (ATS TRN: 100520220300003)`, {postions: 'right'})
        square(doc, {x1: 180, x2: 200, y1: startPoint, y2: startPoint + 10 }, `${totalAmount*0.05}`)
        startPoint = startPoint + 10
        square(doc, {x1: 5, x2: 180, y1: startPoint, y2: startPoint + 10 }, 'Total Amount (AED)', {postions: 'right'})
        square(doc, {x1: 180, x2: 200, y1: startPoint, y2: startPoint + 10 }, `${totalAmount+(totalAmount*0.05)}`)
        startPoint = startPoint + 20
        }


        if(renwalPaymentTable !== -1){
        widthMoreThan1Line = 0
        widthLessThan1Line = 0
        let massageLength = 0
        if(quotation.quotationPlan[renwalPaymentTable].massage !== null){
            massageLength = handleHTML(quotation.quotationPlan[renwalPaymentTable].massage as string, [], '').length * 4
        }
        quotation.quotationPlan[renwalPaymentTable].quotationContent.forEach((item) => {
            const description = handleHTML(item.description, [], '')
            massageLength = massageLength + (description.length*4)
        })
        massageLength = massageLength + (quotation.quotationPlan[renwalPaymentTable].quotationContent.length*3)
        startPoint = addNewPage(startPoint + 12 + massageLength, height - 35, doc, height, width) === 35 ? 35 : startPoint
        if(quotation.quotationPlan[renwalPaymentTable].massage !== null){
            const massages = handleHTML(quotation.quotationPlan[renwalPaymentTable].massage as string, [], '')
            massages.forEach((massage) => {
                displayText(doc, {text: massage.value, x: 5, y: startPoint, color: massage.color, font: [massage.fontStyle[0], massage.fontStyle[1]]}, {maxWidth: 200, direct: true})
                startPoint = startPoint + 4
            })
            doc.setFont('times', 'normal');
            doc.setTextColor('black')
        }
        startPoint = startPoint + 2
        if(quotation.quotationPlan[renwalPaymentTable].title) displayText(doc, {text: quotation.quotationPlan[renwalPaymentTable].title as string, x: 5, y: startPoint - 1.5}, {direct: true})
        square(doc, {x1: 5, x2: 15, y1: startPoint, y2: startPoint + 10}, 'Item', {fontSize: 8})
        square(doc, {x1: 15, x2: 140, y1: startPoint, y2: startPoint + 10}, 'Description', {fontSize: 8})
        square(doc, {x1: 140, x2: 155, y1: startPoint, y2: startPoint + 10}, 'Quantity', {fontSize: 8})
        square(doc, {x1: 155, x2: 180, y1: startPoint, y2: startPoint + 10}, 'Price/Per Vehicle', {fontSize: 8})
        square(doc, {x1: 180, x2: 200, y1: startPoint, y2: startPoint + 10},'Total (AED)', {fontSize: 8})
        let totalAmount = 0
        startPoint = startPoint + 10
        quotation.quotationPlan[renwalPaymentTable].quotationContent.forEach((item, itemIndex) => {
            const description = handleHTML(item.description, [], '')
            description.forEach((desc, index) => {
                displayText(doc, {text: desc.value, color: desc.color, font: desc.fontStyle, x:17, y: startPoint + 3 + (index *4) + (3*itemIndex)}, {direct: true})
            })
            square(doc, {x1: 5, x2: 15, y1: startPoint + (3*itemIndex), y2: startPoint + (description.length*4) + (3*(itemIndex +1))}, `${itemIndex + 1}`)
            square(doc, {x1: 15, x2: 140, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))})
            square(doc, {x1: 140, x2: 155, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.quantity}`)
            square(doc, {x1: 155, x2: 180, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.price}`)
            square(doc, {x1: 180, x2: 200, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.price*item.quantity}`)
            startPoint = startPoint + (description.length*4)
            totalAmount = totalAmount + (item.price*item.quantity)
        })
        startPoint = startPoint + (quotation.quotationPlan[renwalPaymentTable].quotationContent.length*3)
        }
        if(addOnsTable !== -1){
        widthMoreThan1Line = 0
        widthLessThan1Line = 0
        let massageLength = 0
        if(quotation.quotationPlan[addOnsTable].massage !== null){
            massageLength = handleHTML(quotation.quotationPlan[addOnsTable].massage as string, [], '').length * 4
        }
        quotation.quotationPlan[addOnsTable].quotationContent.forEach((item) => {
            const description = handleHTML(item.description, [], '')
            massageLength = massageLength + (description.length*4)
        })
        massageLength = massageLength + (quotation.quotationPlan[addOnsTable].quotationContent.length*3)
        startPoint = addNewPage(startPoint + 12 + massageLength, height - 35, doc, height, width) === 35 ? 35 : startPoint
        if(quotation.quotationPlan[addOnsTable].massage !== null){
            const massages = handleHTML(quotation.quotationPlan[addOnsTable].massage as string, [], '')
            massages.forEach((massage) => {
                displayText(doc, {text: massage.value, x: 5, y: startPoint, color: massage.color, font: [massage.fontStyle[0], massage.fontStyle[1]]}, {maxWidth: 200})
                startPoint = startPoint + 4
            })
            doc.setFont('times', 'normal');
            doc.setTextColor('black')
        }
        startPoint = startPoint + 2
        if(quotation.quotationPlan[addOnsTable].title) displayText(doc, {text: quotation.quotationPlan[addOnsTable].title as string, x: 5, y: startPoint - 1.5}, {direct: true})
        square(doc, {x1: 5, x2: 15, y1: startPoint, y2: startPoint + 10}, 'Item', {fontSize: 8})
        square(doc, {x1: 15, x2: 140, y1: startPoint, y2: startPoint + 10}, 'Description', {fontSize: 8})
        square(doc, {x1: 140, x2: 155, y1: startPoint, y2: startPoint + 10}, 'Quantity', {fontSize: 8})
        square(doc, {x1: 155, x2: 180, y1: startPoint, y2: startPoint + 10}, 'Price/Per Vehicle', {fontSize: 8})
        square(doc, {x1: 180, x2: 200, y1: startPoint, y2: startPoint + 10},'Total (AED)', {fontSize: 8})
        let totalAmount = 0
        startPoint = startPoint + 10
        quotation.quotationPlan[addOnsTable].quotationContent.forEach((item, itemIndex) => {
            const description = handleHTML(item.description, [], '')
            description.forEach((desc, index) => {
                displayText(doc, {text: desc.value, color: desc.color, font: desc.fontStyle, x:17, y: startPoint + 3 + (index *4) + (3*itemIndex)}, {direct: true})
            })
            square(doc, {x1: 5, x2: 15, y1: startPoint + (3*itemIndex), y2: startPoint + (description.length*4) + (3*(itemIndex +1))}, `${itemIndex + 1}`)
            square(doc, {x1: 15, x2: 140, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))})
            square(doc, {x1: 140, x2: 155, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.quantity}`)
            square(doc, {x1: 155, x2: 180, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.price}`)
            square(doc, {x1: 180, x2: 200, y1: startPoint  + (3*itemIndex) , y2: startPoint + (description.length*4) + (3*(itemIndex+1))}, `${item.price*item.quantity}`)
            startPoint = startPoint + (description.length*4)
            totalAmount = totalAmount + (item.price*item.quantity)
        })
        startPoint = startPoint + (quotation.quotationPlan[addOnsTable].quotationContent.length*3)
        }
        
        const termsAndConds = handleHTML(quotation.termsConditions as string, [], '')
        widthMoreThan1Line = 0
        widthLessThan1Line = 0
        startPoint = startPoint + 10
        termsAndConds.forEach((term) => {
        textWidth = doc.getTextWidth(term.value)
        widthMoreThan1Line = textWidth > width-25 ? widthMoreThan1Line + 1 : widthMoreThan1Line 
        widthLessThan1Line = textWidth < width-25 ? widthLessThan1Line + 1 : widthLessThan1Line 
    })
        startPoint = addNewPage(startPoint + 60 + (5*widthLessThan1Line) + (10*widthMoreThan1Line), height - 35, doc, height, width) === 35 ? 35 : startPoint
        displayText(doc, {text: 'Terms and Conditions:', x: 10, y: startPoint, font: ['times', 'bold']}, {direct: true})
        doc.setFont('times', 'normal')
        startPoint = startPoint + 5
        termsAndConds.forEach((term,     index) => {
        displayText(doc, {text: `${index + 1}`, x: 15, y: startPoint}, {direct: true})
        displayText(doc, {text: term.value, font: term.fontStyle, color: term.color, x: 20, y: startPoint}, {maxWidth: width - 25, direct: true})
        textWidth = doc.getTextWidth(term.value)
        startPoint = textWidth > width-25 ? startPoint + 10 : startPoint + 5
    })
    startPoint = startPoint + 5
    displayText(doc, {text: 'We trust the above offer will meet with your satisfaction and final approval, looking forward to receiving your valued LPO and commencement of a long and mutually beneficial relationship.', x: 15, y: startPoint}, {maxWidth: width - 20, direct: true})
    startPoint = startPoint + 10
    displayText(doc, {text: 'For Advance Telematics Solutions (ATS):', x: 15, y: startPoint}, {direct: true})
    startPoint = startPoint + 5
    doc.addImage(signature, "JPEG", 20, startPoint, 50, 35);
    return doc;
        },
        [],
    );
          
    const openPdfNewTab = useCallback(
    () => {
        const finalDoc = createPdf();
        window.open(finalDoc.output("bloburl"));
    },
    [createPdf],
    );
    const downloadPdf = useCallback(
        () => {
          const finalDoc = createPdf();
          finalDoc.save(`${quotation.name}`);
        },
        [createPdf],
      );

    return { openPdfNewTab, downloadPdf };
}