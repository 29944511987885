import api from "../../common/api/api";
import { Iticket, IticketInput } from "../types/types";

interface myResTicketPost {
	data: Iticket;
}

export const postTicket = (data: IticketInput): Promise<myResTicketPost> => {
	return api.post(`tickets`, data);
};

export const postTickets = (data: Record<string, any>[]): Promise<myResTicketPost[]> => {
	return api.post(`tickets/multi`, data);
};