import { Grid, Modal } from '@mantine/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionButtonsCard, DetailsCard } from '../../../components/detailsCards';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { HistoryCard } from '../../history';
import { UnitCard, WialonExternalId } from '../components';
import { UnitTaskCard } from '../components/UnitTaskCard';
import { UnitWithAllExtras } from '../types/types';
import { MapCard } from '../../../components';
import { FaPlug } from 'react-icons/fa';
import { useDisclosure } from '@mantine/hooks';

const Height = 400;

export function UnitDetails() {
  const [data, setData] = useState<UnitWithAllExtras>();
  const [image, setImage] = useState<number | null>(null);
  const { id } = useParams();
  const [opened, { close, open }] = useDisclosure(false);

  let idN = 0;
  if (typeof id === 'string') {
    idN = parseInt(id);
  }

  return (
    <Layout>
      <Grid>
        <Grid.Col lg={7} md={12}>
          <DetailsCard cardHei={Height} id={idN} image={image} withImage>
            <UnitCard setImage={setImage} id={idN} setData={setData} />
          </DetailsCard>
        </Grid.Col>
        <Grid.Col lg={5} md={12}>
          <ActionButtonsCard
            id={[idN]}
            type={types.Unit}
            customActions={
              data?.vehicle
                ? [
                    {
                      id: 'wialon',
                      multi: false,
                      title: 'Connect Wialon',
                      icon: FaPlug,
                      color: 'blue',
                      handler: open,
                      noDisplay: [],
                    },
                  ]
                : undefined
            }
          />
        </Grid.Col>

        <Grid.Col lg={12} md={12}>
          <UnitTaskCard id={idN} cardHei={Height} />
        </Grid.Col>
        {data && (
          <Grid.Col lg={6} md={12}>
            <HistoryCard
              historySearch={[
                { id: idN, type: types.Unit },
                { id: data.deviceConfig.id, type: types.DeviceConfig },
              ]}
              cardHei={Height}
            />
          </Grid.Col>
        )}
        {data && (
          <Grid.Col lg={6} md={12}>
            <MapCard
              cardHei={Height}
              // id={data.deviceConfig.unitLastMessage?.id}
              externalId={data.deviceConfig.unitLastMessage?.externalId}
            />
          </Grid.Col>
        )}
      </Grid>
      <Modal opened={opened} onClose={close} title="Connect With Wialon">
        {data && data.vehicle && <WialonExternalId unit={data} />}
      </Modal>
    </Layout>
  );
}
