import {
  Alert,
  Button,
  Checkbox,
  Container,
  Grid,
  List,
  LoadingOverlay, Modal, Paper,
  SegmentedControl,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DetailsCard } from '../../../components/detailsCards';
import { DatePickerInputEditable, NumberInputEditable, SelectEditable, TextInputEditable } from '../../../components/form';
import { colorSelect, types } from '../../../constants';
import { enumToSelectData, noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { OrganizationCard, useOrganizations } from '../../organizations';
import { TaskType } from '../../tasks';
import { DevicePlatforms, IunitInput, postUnit } from '../../unit';
import { NewVehicleTypeForm, useVehicleTypes } from '../../vehicle-types';
import { postVehicle } from '../api';
import { placeOfIssue } from '../constants';
import { IvehicleInput } from '../types/types';


export function NewVehicleForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [openedVehicleType, { open:openVehicleType, close:closeVehicleType }] = useDisclosure(false);
  const [odometer, setOdometer] = useState('odometerKM')
  const largeScreen = useMediaQuery('(min-width: 768px)');
  const navigate = useNavigate()
  
  type ivehicleWithUnit =  
  {
    vehicle: IvehicleInput,
    withUnit: boolean,
    unit: IunitInput ,
  }
  const [organizationParentsSelect, setOrganizationParentsSelect] = useState([
    { value: '0', label: '' },
  ]);
  const [organizationParentId, setOrganizationParentId] = useState(0);
  const [vehicleTypeSelect, setVehicleTypeSelect] = useState([
    { value: '0', label: '' },
  ]);
  const queryClient = useQueryClient();

  const form = useForm<ivehicleWithUnit>({
    initialValues: {
      vehicle: {
        vin: '',
        category: '',
        placeOfIssue: '',
        plateNumber: '',
        vehicleType: 0,
        organization: 0,
        odometerKM: '',
        odometerHours: '',
        color: '',
        licenseExpiryDate: new Date(),
        licenseIssueDate: new Date(),
      },
      withUnit: true,
      unit: {
        device: null,
        simCard: null,
        vehicle: {
          id: 0
        },
        deviceConfig: {
          platform: '',
          secondaryPlatform: '',
          externalId: null,
          rfidList: null,
        },
        accessories: [],
        accessoriesTypes: [],
        remarks: null,
      }
    },
  });

  const { data: vehicleTypseData } = useVehicleTypes();
  useEffect(() => {
    if (vehicleTypseData) {
      const tempArr = vehicleTypseData.data.items.map((type) => {
        return {
          value: `${type.id}`,
          label: `${type.model}, ${type.brand}, ${type.year}`,
        };
      });
      setVehicleTypeSelect(tempArr);
    }
  }, [vehicleTypseData]);

  const { data: organizationsData } = useOrganizations();
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((organization) => {
        return { value: `${organization.id}`, label: `${organization.name}` };
      });
      setOrganizationParentsSelect(tempArr);
    }
  }, [organizationsData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postVehicle, {
    onSuccess: (data) => {
      // setAlert('vehicle create with ou');
      setOrganizationParentId(0);
      queryClient.invalidateQueries([types.Vehicle, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
      if(form.values.withUnit){
        const newValues = noEmptyString(form.values.unit.deviceConfig)
        newUnitMutate({...form.values.unit, deviceConfig: newValues, vehicle:{id: data.data.id}})
      }else{
        form.reset()
      }
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });
  
  const {
    mutate: newUnitMutate,
  } = useMutation(postUnit, {
    onSuccess: (data) => {
      navigate(`/new/task?uid=${data.data.id}&type=${TaskType.Installation}`)

      setAlert(null);
      queryClient.invalidateQueries([types.Unit, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: ivehicleWithUnit) {
    setAlert(null);
    const newValues = noEmptyString(values)
    if(newValues.withUnit){
      if(newValues.unit.deviceConfig.platform){
        newMutate(newValues.vehicle);
      }else form.setFieldError('unit.deviceConfig.platform', 'Please Select the platform first')
    }else {
      newMutate(newValues.vehicle);
    }
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Vehicle:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              label="Vin"
              placeholder="Vin"
              {...form.getInputProps('vehicle.vin')}
              required
            />
            <TextInputEditable
              label="Category"
              placeholder="Category"
              {...form.getInputProps('vehicle.category')}
              mt='md'
            />
            <SelectEditable
              label="Place Of Issue"
              placeholder="Place Of Issue"
              {...form.getInputProps('vehicle.placeOfIssue')}
              data={enumToSelectData(placeOfIssue)}
              mt="md"
            />
            <NumberInputEditable
              label="PlateNumber"
              placeholder="PlateNumber"
              {...form.getInputProps('vehicle.plateNumber')}
              hideControls
              mt="md"
            />
            <DatePickerInputEditable
            placeholder="Search Date"
            label="license Issue Date"
            {...form.getInputProps('vehicle.licenseIssueDate')}
            clearable
            mt="md"
            />
            <DatePickerInputEditable
            placeholder="Search Date"
            label="license Expiry Date"
            {...form.getInputProps('vehicle.licenseExpiryDate')}
            clearable
            mt="md"
            />
            {largeScreen &&
            <>
            <Grid>
              <Grid.Col span={9}>
                <SelectEditable
                  label="Vehicle Type"
                  placeholder="Search here"
                  {...form.getInputProps('vehicle.vehicleType')}
                  onChange={(v) => {
                    form.setFieldValue('vehicle.vehicleType', parseInt(v || '0'));
                  }}
                  searchable
                  nothingFound="Not found"
                  required
                  data={vehicleTypeSelect}
                  value={`${form.values.vehicle.vehicleType}`}
                  mt="md"
                  />
              </Grid.Col>
              <Grid.Col span={3} mt={40}>
                  <Button onClick={openVehicleType} fullWidth>add vehicle type</Button>
              </Grid.Col>
              </Grid>
              <SelectEditable
                label="Property Of"
                placeholder="Search here"
                {...form.getInputProps('vehicle.organization')}
                onChange={(v) => {
                  form.setFieldValue('vehicle.organization', parseInt(v || '0'));
                  setOrganizationParentId(parseInt(v || '0'));
                }}
                searchable
                required
                nothingFound="Not found"
                value={`${form.values.vehicle.organization}`}
                data={organizationParentsSelect}
                mt="md"
              />
            </>
            }
            {
              !largeScreen && 
               <SelectEditable
                  label="Vehicle Type"
                  placeholder="Search here"
                  {...form.getInputProps('vehicle.vehicleType')}
                  onChange={(v) => {
                    form.setFieldValue('vehicle.vehicleType', parseInt(v || '0'));
                  }}
                  value={`${form.values.vehicle.vehicleType}`}
                  searchable
                  nothingFound="Not found"
                  data={vehicleTypeSelect}
                  required
                  mt="md"
                />

            }
            {
              !largeScreen && 
              <SelectEditable
                  label="Property Of"
                  placeholder="Search here"
                  {...form.getInputProps('organization')}
                  onChange={(v) => {
                    form.setFieldValue('vehicle.organization', parseInt(v || '0'));
                    setOrganizationParentId(parseInt(v || '0'));
                  }}
                  searchable
                  value={`${form.values.vehicle.organization}`}
                  nothingFound="Not found"
                  data={organizationParentsSelect}
                  required
                  mt="md"
                />

            }

            <SegmentedControl 
            mt='lg' 
            size='xs'
            defaultValue='odometerKM' 
            data={[{ label: 'Odometer by Kilometers', value: 'odometerKM' },{ label: 'Odometer by Hours', value: 'odometerHours' }]} 
            onChange={(v) => setOdometer(v)} />
            {odometer === 'odometerKM' &&
            <NumberInputEditable
              label="KM"
              placeholder="KM"
              {...form.getInputProps('vehicle.odometerKM')}
              hideControls
              mt="md"
            />
            }

            {odometer === 'odometerHours' &&
            <NumberInputEditable
              label="Hours"
              placeholder="Hours"
              {...form.getInputProps('vehicle.odometerHours')}
              hideControls
              mt="md"
            />
            }
            
            <SelectEditable
              label="Color"
              placeholder="Search here"
              {...form.getInputProps('vehicle.color')}
              searchable
              nothingFound="Not found"
              data={colorSelect}
              mt="md"
            />
            <Checkbox label={'With Unit'} checked={form.values.withUnit} {...form.getInputProps('withUnit')} mt='md' />
            {form.values.withUnit && <>
              <SelectEditable
              label="Platform"
              placeholder="Search here"
              {...form.getInputProps('unit.deviceConfig.platform')}
              data={enumToSelectData(DevicePlatforms)}
              mt="sm"
              required
            />
            <SelectEditable
              label="Secondary Platform"
              placeholder="Search here"
              {...form.getInputProps('unit.deviceConfig.secondaryPlatform')}
              data={enumToSelectData(DevicePlatforms)}
              mt="md"
            />
            </>}
            {/* <TextInputEditable label="Image" placeholder="Image" {...form.getInputProps('img')} required mt="md" /> */}
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {organizationParentId !== 0 && (
            <DetailsCard
              id={organizationParentId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter(
                  (org) => org.id === organizationParentId,
                )[0].imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationParentId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
      <Modal opened={openedVehicleType} onClose={closeVehicleType} size="60%">
            <NewVehicleTypeForm/>
      </Modal>
    </>
  );
}
