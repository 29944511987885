import { useEffect, useState } from "react"
import { useCertificates } from "../../modules/asateelCertificate"
import { DownloadAsCsv, Format, URLParams, dateToNewDate, getAllKeysWithPath, getKeysWithPath } from "../../utils"
import { ExportToCsvButton } from "../table/components"

export function ExportCertificates({
    ids,
}:{
    ids: number[]
}){
    const certificate = {
        id: 0,
        certificateID: "",
        expiryDate: new Date(),
        activationDate: new Date(),
        
        device: {
            id: 0,
            imei: 0,
            status: "",
        },
        simCard: {
            id: 0,
            number: "",
            serial: "",
            status: "",
        },
        vehicle: {
            id: 0,
            category: "",
            plateNumber: 0,
            placeOfIssue: "",
            vin: "",
            licenseIssueDate: new Date(),
            licenseExpiryDate: new Date(),
            vehicleType: {
                id: 0,
                model: "",
                brand: "",
                year: 0,
                type: "",
            } ,
            organization: {
                id: 0,
                name: "",
            } ,
        },
    }

    const [ fetch, setFetch ] = useState(false)
    const [ fileName, setFileName ] = useState('')
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
            'device',
            'simCard',
            'vehicle',
            'vehicle.organization as vehicleOrg',
            'vehicle.vehicleType as vehicleType',
        );
        
        param.sort(('-id'))
        return param
      })
    // params.se
    const { data: allCertificates, refetch } = useCertificates({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (allCertificates) {
          DownloadAsCsv(dateToNewDate(allCertificates.data.items, ['expiryDate', 'activationDate', 'licenseIssueDate', 'licenseExpiryDate']), fileName, getKeysWithPath(getAllKeysWithPath(allCertificates.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [allCertificates]);

    return(
        <ExportToCsvButton keys={getKeysWithPath(certificate,[], ':')} onSubmit={(v => {
            setFileName(v.fileName)
            const paths = Object.values(v.columns).reduce((cols, item) => {
                    const path = handleReplacePath(item.path, [{originalPath: 'vehicle.vehicleType', replaceTo: 'vehicleType'},{originalPath: 'vehicle.organization', replaceTo: 'vehicleOrg'}])
                    if (item?.checked) cols.push(path)
                    return cols
                  }, [] as string[])
            setParams(param => {
                param.select(...paths)
                param.resetSearchMany()
                param.searchMany('id', ids.map(id => `${id}`))
                return param
            })
            
            if(!fetch){
                setFetch(true)
            } else refetch()
        })}/>
    )
}
function handleReplacePath(path: string, replaces:{originalPath: string, replaceTo: string}[]){
    const found = replaces.find(item => path.includes(item.originalPath))
    const paths = path.split('.')
    const column = paths[paths.length-1]
    if(found) return found.replaceTo+'.'+column
    else return path
}