  
  import Layout from '../../../components/layout/Layout';
import { UpdateOrganizationForm } from '../components/forms/UpdateOrganizationForm';
    
    export function UpdateOrganizationFormPage() {
      return (
        <Layout>
          <UpdateOrganizationForm idM={0}/>
        </Layout>
      );
    }
    