import api from "../../common/api/api";
import { IvehicleInput } from "../types/types";
import { Ivehicle } from './../types/types';

interface myResVehiclePost {
	data: Ivehicle;
}

export const postVehicle = (data: IvehicleInput): Promise<myResVehiclePost> => {
	return api.post(`vehicles`, data);
};

export const postVehicles = (data: Record<string, any>[]): Promise<{data: Ivehicle[]}> => {
	return api.post(`vehicles/multi`, data);
};
