
export enum CountryOfOrigin {
    Europe = 'Europe'
}

export enum QuotationPlanType {
    FirstPayment = 'first payment',
    Renwal = 'renewal',
    OptionalAddOns = 'add ons',
}

export enum QuotationStatus {
    InProgress = 'in progress',
    New = 'new',
    Complete = 'complete',
    Lost = 'lost',
}

export enum QuotationStatusColor {
    new= '#5c8f43',
	in_progress= '#ccb825',
	lost= '#bf5858',
	complete= '#3e25cc',
}

export enum QuotationColumnsMode {
	ID = "id",
	Title = "title",
	Name = "name",
	ReferenceNo = "referenceNo",
	Status = "status",
	QautationDate = "qautationDate",
	CountryOfOrigin = "countryOfOrigin",
	Validity = "validity",
	TermsConditions = "termsConditions",
	Templete = "templete",
	Discount = "discount",
}

export enum QuotationContentColumnsMode {
	ID = "id",
	Description = "description",
	Quantity = "quantity",
	Price = "price",
}

export enum QuotationPlanColumnsMode {
	ID = "id",
	Title = "title",
	Massage = "massage",
	Type = "type",
}