import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { IsettingsInput } from '../types/types';

// interface myResSetting {
// 	data: IsettingsInput;
// }

export const getSetting = (): Promise<IsettingsInput> => {
	return api.get(`setting`);
};

export const useSetting = (options?: { then?: (x: IsettingsInput) => any }) => {
	const query = useQuery(
		[types.Settings],
		() =>
			getSetting().then((settings) => {
				options && options.then && options.then(settings);
				return settings;
			}),
	);
	return query;
};
