import {
  Alert,
  Container,
  createStyles,
  LoadingOverlay,
  Paper,
  Table,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { selectColumns } from '../../../utils';
import { useTask } from '../api';
import { TaskColumnsMode } from '../constants';

const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));

export function TaskCard({ id }: { id: number }) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);

  const { isLoading, isError, data, refetch } = useTask(id, {
    joins: ['organization', 'vehicle', 'unit', 'unit.device as device', 'unit.simCard as sim'],
    selects: [...selectColumns(TaskColumnsMode), 'organization.id','organization.name', 'vehicle.id','vehicle.vin', 'vehicle.plateNumber', 'unit.id', 'device.id','device.imei', 'sim.id', 'sim.number'],
  });

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);

  return (
    <Paper pos="relative">
      <Container className={classes.detailsContainer} m={0} p={0}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        {data && data.data && (
          <Table mt={12} p={5}>
            <tbody>
              <tr>
                <td className={classes.leftSideTable}>id:</td>
                <td>{data.data.id}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Title:</td>
                <td>{data.data.title}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Address:</td>
                <td>{data.data.address}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Status:</td>
                <td>{data.data.status}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Date:</td>
                <td>
                  {data.data.targetDate &&
                    new Date(data.data.targetDate).toString().slice(0, 16)}
                </td>
              </tr>
              <tr>
                <td className={classes.leftSubtitleTable}>Client:</td>
                <td></td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>name:</td>
                <td>{data.data.organization.name}</td>
              </tr>
              <tr>
                <td className={classes.leftSubtitleTable}>Vehicle:</td>
                <td></td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Plate Number:</td>
                <td>{data.data.vehicle?.plateNumber}</td>
              </tr>
              <tr>
                <td className={classes.leftSideTable}>Chassis:</td>
                <td>{data.data.vehicle?.vin}</td>
              </tr>
              {data.data.unit && (
                <>
                  <tr>
                    <td className={classes.leftSubtitleTable}>
                      Unit:
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>id:</td>
                    <td>{data.data.unit.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>imei:</td>
                    <td>{data.data.unit.device?.imei}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Number:</td>
                    <td>{data.data.unit.simCard?.number}</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        )}
        {isError && (
          <Alert
            icon={<TbAlertOctagon size={16} />}
            title="Error!"
            color="red"
            mb={10}
          >
            Error Loading data from the server!!
          </Alert>
        )}
      </Container>
    </Paper>
  );
}
