import { useQuery } from 'react-query';
import { types } from '../../../constants';
import api from '../../common/api/api';
import { IpurchaseOrder } from '../types/type';


interface myResPurchaseOrder {
  data: IpurchaseOrder;
}

export const getPurchaseOrder = (id: number, joins?: string[], selects?: string[]): Promise<myResPurchaseOrder> => {
  let params = '';
  if (joins) params = '?joins='.concat(joins.join());
  if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
  return api.get(`purchase-orders/${id}${params}`);
};


export const usePurchaseOrder = (id: number, options?: { joins?: string[]; selects?: string[] ;then?: (x: myResPurchaseOrder) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.PurchaseOrder, { id }];
  if (options && options.joins) cacheKey.push(...options.joins);
  const query = useQuery(
    cacheKey,
    () =>
    getPurchaseOrder(id, options?.joins, options?.selects).then((purchaseOrder) => {
        options && options.then && options.then(purchaseOrder);
        return purchaseOrder;
      }),
    { enabled: id !== 0 },
  );
  return query;
};
