import { ActionIcon, Button, Group, Modal, Paper, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RichTextEditor } from '@mantine/tiptap';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { TbPlus } from 'react-icons/tb';
import { PreSetValue } from '../../modules/preSets';
import { NewPreSetForm } from '../../modules/preSets/forms/NewPreSetForm';

export function RichTextEditorComponent({
  value,
  setValue,
  label,
  submittable = false,
  withPreset,
}:{
  value: string | null,
  setValue:  (data: string) => void,
  label?: string,
  submittable?: boolean
  withPreset?: string,
}) {
  const [opened, { open, close }] = useDisclosure(false);
  
  function handleSubmit(){
    if(editor){
      setValue(editor.getText() ? editor.getHTML() : '')
    }
  }
    const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Color,
      TextStyle,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    onUpdate: () => {
      !submittable && handleSubmit();
    },
    content: value ?? '',
  });

  function handlePreSet(preset:string){
    editor?.commands.setContent(preset)
  }

  return (
    <Paper p='sm'>
      {label && <Text>{label}</Text>}
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <Group position='apart' w={'100%'}>

            <Group>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ColorPicker
                colors={[
                  '#25262b',
                  '#868e96',
                  '#fa5252',
                  '#e64980',
                  '#be4bdb',
                  '#7950f2',
                  '#4c6ef5',
                  '#228be6',
                  '#15aabf',
                  '#12b886',
                  '#40c057',
                  '#82c91e',
                  '#fab005',
                  '#fd7e14',
                ]}
              />

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Color color="#F03E3E" />
                <RichTextEditor.Color color="#7048E8" />
                <RichTextEditor.Color color="#1098AD" />
                <RichTextEditor.Color color="#37B24D" />
                <RichTextEditor.Color color="#F59F00" />
              </RichTextEditor.ControlsGroup>
              <RichTextEditor.UnsetColor />
            </Group>
            <Group>
              {withPreset && <PreSetValue type={withPreset} setValue={handlePreSet}  />}
              {withPreset && <Tooltip label={'Add Content to presets'}><ActionIcon onClick={open}><TbPlus /></ActionIcon></Tooltip>}
            </Group>
          </Group>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content  />
      </RichTextEditor>
      {submittable && <Button onClick={handleSubmit} fullWidth>done</Button>}
      {withPreset && 
        <Modal opened={opened} onClose={close} size="50%">
                <NewPreSetForm content={editor?.getText() ? editor?.getHTML() : ''} type={withPreset} />
         </Modal>
      }
    </Paper>
  );
}