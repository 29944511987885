import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { IAccessories } from "../types";

interface myResAccessories {
	data: IAccessories;
}

export const getAccessory = (id: number, joins?: string[], selects?: string[]): Promise<myResAccessories> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`accessories/${id}${params}`);
  };

export const useAccessory = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResAccessories) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Accessories, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
	  cacheKey,
	  () =>
	  	getAccessory(id, options?.joins, options?.selects).then((accessory) => {
		  options && options.then && options.then(accessory);
		  return accessory;
		}),
	  { enabled: id !== 0 },
	);
	return query;
  };