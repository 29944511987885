import { Group, LoadingOverlay, Paper, Title, createStyles } from '@mantine/core';
import { useEffect, useState } from 'react';
import { SimStatus, useAnalyticsSimCards } from '../../modules/sim-cards';
import { URLGroupParams, groupBy, sortObjectByOrder } from '../../utils';
import { SimsCount } from './Components';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
      theme.colors[theme.primaryColor][7]
    } 100%)`,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    display: 'flex',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },
}));

export function SimCardWidgets() {
  const { classes } = useStyles();
  
  const countSimsOptions = new URLGroupParams()
  countSimsOptions.group('status', 'simCardType')
  countSimsOptions.joins('simCardType')
  const { data: simCardData, isLoading: isLoadingSimCard } = useAnalyticsSimCards({params: countSimsOptions.toString()})
  const [ data, setData ] = useState<Record<string | number, Record<string, string | number>[]>>({})
  useEffect(() => {
    if(simCardData){
      const temp = groupBy(simCardData?.data, i => i.status)
      const result = sortObjectByOrder(temp, [SimStatus.Available, SimStatus.Ready, SimStatus.InUse, SimStatus.Broken, SimStatus.Lost, SimStatus.Deactivated])
      setData(result)
    }
  }, [simCardData])
  const status = Object.entries(data).map(([key, value], index) => (<SimsCount title={key} value={value} key={index} />))

  return ( 
    <Paper withBorder  radius="md" p='md'>
      <Title order={3} py={10} style={{ fontWeight: 700 }}>
          Sim Cards
      </Title>
        <div className={classes.root} >
          <LoadingOverlay visible={isLoadingSimCard} />
            <Group sx={{ flex: 1 }}>
                {status}
            </Group>
        </div>
    </Paper>
  );
}