import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { IpurchaseOrder } from '../types/type';


interface myResPurchaseOrders {
  data: {
    items: IpurchaseOrder[];
    meta: metaData,
  };
}

export const getPurchaseOrders = (query = ''): Promise<myResPurchaseOrders> => {
  return api.get(`purchase-orders${query}`);
};

export const usePurchaseOrders = (options?: { params?: string; then?: (x: myResPurchaseOrders) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.PurchaseOrder, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getPurchaseOrders(options?.params).then((purchaseOrders) => {
      options && options.then && options.then(purchaseOrders);
      return purchaseOrders;
    }),
  );
  return query;
};
interface myResCountPurchaseOrdersNumber {
  data: number;
}

export const countPurchaseOrders = (query = ''): Promise<myResCountPurchaseOrdersNumber> => {
  return api.get(`purchase-orders/count${query}`);
};

export const useCountPurchaseOrders = (options?: { params?: string; then?: (x: myResCountPurchaseOrdersNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.PurchaseOrder, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countPurchaseOrders(options?.params).then((purchaseOrder) => {
      options && options.then && options.then(purchaseOrder);
      return purchaseOrder;
    }),
  );
  return query;
};