
import Layout from '../../../components/layout/Layout';
import { NewVehicleForm } from '../components/NewVehicleForm';

export function NewVehicleFormPage() {

  return (
    <Layout>
      <NewVehicleForm/>
    </Layout>
  );
}
