
export enum deviceStatusColor {
    available= '#5c8f43',
	ready= '#bf5858',
	in_use= '#be58bf',
	broken= '#bf9658',
	lost= '#6a71bd',
	sold = '#8838cf',
	not_active = '#d7d899',
}

