  
  import Layout from '../../../components/layout/Layout';
import { NewSimCardTypeForm } from '../components';
    
    export function NewSimCardTypeFormPage() {
      return (
        <Layout>
          <NewSimCardTypeForm/>
        </Layout>
      );
    }
    