import api from "../../common/api/api";
import { IsettingsInput } from './../types/types';

interface myResSetting {
	data: IsettingsInput;
}

export const postSetting = (data: IsettingsInput): Promise<myResSetting> => {
	return api.post(`setting`, data);
};
