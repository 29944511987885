
export enum DevicePlatforms {
  Wialon = 'wialon',
  Asateel = 'asateel',
  Securepath = 'securepath',
  SecurepathPremuim = 'securepath premuim',
  SoftwareAG = 'softwareag',
  Aiflux = 'aiflux',
  WialonLocal = 'wialon local',
  Custom = 'custom',
}
export enum PlatformColor {
  wialon= '#5c8f43',
  asateel= '#bf5858',
  securepath= '#be58bf',
  securepath_premuim= '#bf9658',
  softwareag= '#5abfbd',
  aiflux= '#a65abf',
  wialon_local= '#6458bf',
}


export enum DeviceConfigColumnsMode {
  ID = "id",
  Platform = "platform",
  SecondaryPlatform = "secondaryPlatform",
  ExternalId = "externalId",
  RfidList = "rfidList",
}

export enum unitStatus {
	Active = "active",
	Not_Active = "not active",
	Pending_Installation = "Pending Installation",
	To_Be_Approved_Installation = "to be approved installatiion",
	Pending_Removal = "Pending Removal",
	To_Be_Approved_Removal = "to be approved Removal",
	Pending_DeviceChange = "pending device Change",
	To_Be_Approved_DeviceChange = "TBA device Change",
	Pending_Reinstallation = "pending ReInstallation",
	To_Be_Approved_Reinstallation = "TBA ReInstallation",
}