import { useQuery } from 'react-query';
import { types } from '../../../../constants';
import api from '../api';
import { UnitLastMessage } from '../../../unit/types/types';

interface myResUnit {
  data: UnitLastMessage;
}

export const getUnitLastMessage = (externalId: number): Promise<myResUnit> => {
  return api.get(`unit-last-message/${externalId}`);
};

export const useUnitLastMessage = (externalId: number, options?: { then?: (x: myResUnit) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.UnitLastMessage, { externalId }];
  const query = useQuery(
    cacheKey,
    () =>
      getUnitLastMessage(externalId).then((unit) => {
        options && options.then && options.then(unit);
        return unit;
      }),
    { enabled: externalId !== 0, staleTime: 1000 * 10 },
  );
  return query;
};
