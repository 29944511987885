import { useState } from 'react';

export function useDeepFilter<T extends Record<string, any>>(data: T[], fields?: string[]) {
  const [filter, setFilter] = useState('');

  const nestedSome = (item: any, path: string) => {
    if (item !== null && typeof item === 'object' && !(Object.prototype.toString.call(item) === '[object Date]')) {
      const boolArr: boolean[] = [];
      Object.keys(item).forEach((key) => {
        if (item[key] !== null && typeof item[key] === 'object' && !(Object.prototype.toString.call(item[key]) === '[object Date]')) {
          boolArr.push(nestedSome(item[key], path ? `${path}.${key}` : key));
        } else {
          if (filter !== '' && fields && fields.length > 0) {
            if (fields.includes(path.concat('.' + key)))
              boolArr.push(String(item[key]).toLowerCase().includes(filter.toLowerCase().trim()));
          } else {
            boolArr.push(String(item[key]).toLowerCase().includes(filter.toLowerCase().trim()));
          }
        }
      });
      return boolArr.some((item) => item);
    } else {
      if (fields && fields.length > 0) {
        if (filter !== '' && fields.includes(path)) return String(item).toLowerCase().includes(filter.toLowerCase().trim());
        else return false;
      } else {
        return String(item).toLowerCase().includes(filter.toLowerCase().trim());
      }
    }
  };

  const formattedData = data.filter((item) => {
    return Object.keys(item || {}).some((key) => nestedSome(item[key], key));
  });

  return { formattedData, filter, setFilter };
}

export type DeepFilterOptions = ReturnType<typeof useDeepFilter>;
