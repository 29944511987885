import { Text } from '@mantine/core';

export function DisplayTime({ time, title }: { time: Date; title: string }) {
  const displayTime = time.toString().split(':')
  const completeTime = +displayTime[0] > 12 ? `${+displayTime[0] - 12}:${displayTime[1]} PM` : `${+displayTime[0]}:${displayTime[1]} AM`
  return (
    <>
      <Text>{title}</Text>
      <Text>{completeTime}</Text>
    </>
  );
}
