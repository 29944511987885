import { useState } from 'react';

export const useCsv = (requirements: Record<string, RegExp>, defaultColomns: string[], initialData?: Record<string, any>[]) => {
  const [initial, setInitial] = useState(true);
  const [headers, setHeaders] = useState<string[]>([]);
  const [data, setData] = useState<Record<string, any>[] | null>(null);
  const [errorRows] = useState<number[][]>([]);

  /**
   * convert a csv file to 'Record<string, any>' format
   * - cannot handle nested objects
   * @param file csv file
   */
  const csvParser = (file: File) => {
    //TODO: setErrorRows([]);
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = function (event) {
      let csvOutput = event.target?.result;
      if (typeof csvOutput === 'string') {
        const endline = csvOutput.includes('\r\n') ? '\r\n' : '\n';
        if (csvOutput.endsWith(endline)) {
          csvOutput = csvOutput.slice(0, -endline.length);
        }
        const headers = csvOutput.slice(0, csvOutput.indexOf(endline)).split(',');
        setHeaders(headers);
        const rows = csvOutput.slice(csvOutput.indexOf(endline) + endline.length).split(endline);
        const res = rows.reduce((acc, item, rIndex) => {
          const temp: Record<string, any> = {};
          item.split(',').forEach((v, cIndex) => {
            if (v.startsWith('"') && v.endsWith('"')) v = v.slice(1, -1);
            if (requirements[headers[cIndex]] && !requirements[headers[cIndex]].test(v)) {
              updateErrors(rIndex, cIndex);
            }
            temp[headers[cIndex]] = v;
          });
          acc.push(temp);
          return acc;
        }, [] as Record<string, any>[]);
        setData(res);
      }
    };
  };

  const updateErrors = (rIndex: number, cIndex: number) => {
    if (!errorRows[cIndex]) errorRows[cIndex] = [];
    if (!errorRows[cIndex]?.includes(rIndex + 1)) errorRows[cIndex].push(rIndex + 1);
  };

  const setItem = (i: number, key: string, value: string) => {
    if (data) {
      const items = [...data];
      const item = items[i];
      item[key] = value;
      items[i] = item;
      setData([...items]);
      headers.forEach((_, cIndex) => {
        if (requirements[headers[cIndex]] && headers[cIndex] === key && !requirements[headers[cIndex]].test(value)) {
          updateErrors(i, cIndex);
        } else if (
          errorRows[cIndex] &&
          requirements[headers[cIndex]] &&
          headers[cIndex] === key &&
          requirements[headers[cIndex]].test(value)
        ) {
          const index = errorRows[cIndex].indexOf(i + 1);
          if (index > -1) errorRows[cIndex].splice(index, 1);
        }
      });
    }
  };

  const addRow = (item: Record<string, any> = {}, then?: () => void) => {
    // const item: Record<string, any> = {};
    if (data) {
      headers.map((key) => (item[key] = ''));
      setData([...data, item]);
    } else {
      defaultColomns.map((key) => (item[key] = ''));
      setHeaders(defaultColomns);
      setData([item]);
    }
    if (then) then();
  };

  const removeRow = (key: string, value: string) => {
    const index = data?.findIndex((item) => item[key] === value);
    if (index && index !== 0) {
      data?.splice(index, index);
    } else if (index === 0) data?.shift();
  };

  const validate = () => {
    return errorRows.some((errorIds) => errorIds.length > 0);
  };

  if (initial && initialData) {
    setInitial(false);
    initialData.forEach((row) => addRow(row));
  }

  return { csvParser, data, headers, errorRows, validate, setItem, addRow, removeRow };
};
