import api from "../../common/api/api";
import { Iinvoice, IupdateInvoiceInput } from "../types/types";

interface myResInvoices {
	data: Iinvoice;
}

export const patchInvoice = (props: { id: number; data: Partial<IupdateInvoiceInput> }): Promise<myResInvoices> => {
	return api.patch(`invoices/${props.id}`, props.data);
};

export const patchInvoices = (data: Record<string, any>[]): Promise<myResInvoices[]> => {
	return api.patch('invoices/multi', data);
};