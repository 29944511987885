import { Paper, Text, useMantineTheme } from '@mantine/core'
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { getChartTheme } from '../themes'

export function BarChart({ data, keys, secondKey, indexBy, title }: { data: BarDatum[], keys: string[], secondKey: string, indexBy: string, title: string }) {
   const theme = useMantineTheme()
   return (
      <Paper withBorder h={450} pl={10} radius="md" >
         <Text py={10} style={{ fontWeight: 700 }}>
            {title}
         </Text>
         <ResponsiveBar
            data={data}
            keys={keys}
            indexBy={indexBy}
            margin={{ top: 50, right: 130, bottom: 100, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            theme={getChartTheme(theme)}
            defs={[
               {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true
               },
               {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
               }
            ]}
            fill={[
               {
                  match: {
                     id: keys[4]
                  },
                  id: 'dots'
               },
               {
                  match: {
                     id: keys[2]
                  },
                  id: 'lines'
               }
            ]}
            borderColor={{
               from: 'color',
               modifiers: [
                  [
                     'darker',
                     1.6
                  ]
               ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: indexBy,
               legendPosition: 'middle',
               legendOffset: 32
            }}
            axisLeft={{
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: secondKey,
               legendPosition: 'middle',
               legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
               from: 'color',
               modifiers: [
                  [
                     'darker',
                     1.6
                  ]
               ]
            }}
            legends={[
               {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                     {
                        on: 'hover',
                        style: {
                           itemOpacity: 1
                        }
                     }
                  ]
               }
            ]}
         />
      </Paper>
   )
}