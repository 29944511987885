import { ActionIcon, Avatar, Button, Center, Container, createStyles, Grid, Loader, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { TbEdit, TbPhoto } from 'react-icons/tb';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { commonConstants, getImageCollection, IcollectionLink, UploadButton, useUploadImage } from '../../common';
import { useProfile } from '../api';
import { UploadProfileImage } from '../components';
import { UpdateUserProfileForm } from '../forms';
import { UpdatePasswordForm } from '../forms/UpdatePasswordForm';
import { UserTasks } from './UserTasks';

const useStyles = createStyles((theme) => ({
  avatarContainer: {
    position: 'relative',
  },
}));

export function Profile() {
  const [opened, setOpened] = useState(false);
  const [openedImage, setOpenedImage] = useState(false);
  // const [textHide, setTextHide] = useState(false);

  const [file, setFile] = useState<File | null>(null);
  const { upload, progress } = useUploadImage();
  const [imageLink, setImageLink] = useState<IcollectionLink>();
  const size = 200;
  const { classes } = useStyles();
  const { isLoading: profileIsLoading, data: profile } = useProfile();

  const [openedEdit, { open: openEdit, close: closeEdit }] = useDisclosure(false);

  useEffect(() => {
    if (profile && profile.data.profile.imageRepositoryId) {
      getImageCollection(profile.data.profile.imageRepositoryId).then((collection) => {
        setImageLink(collection.data.collection.find((image) => image.id === collection.data.previewId) || undefined);
        return collection;
      });
    }
  }, [profile]);

  function handleUpload() {
    if (file && profile && profile.data.profile.imageRepositoryId) {
      upload(
        profile.data.profile.imageRepositoryId,
        file,
        `${profile?.data.username}_${commonConstants.Profile}`,
        types.UserProfile,
        types.UserProfile,
        profile.data.id
      );
    }
  }

  return (
    <Layout>
      <Grid my="md" gutter={25}>
        {!profile && profileIsLoading && (
          <Grid.Col>
            <Center h={500}>
              <Loader />
            </Center>
          </Grid.Col>
        )}
        {profile && (
          <>
            <Grid.Col md={4} sm={12}>
              <Stack align={'center'} spacing={0}>
                <div className={classes.avatarContainer}>
                  <Avatar
                    src={imageLink?.path ? `${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${imageLink?.path}` : ''}
                    radius={size / 2}
                    size={size}
                  ></Avatar>
                  <ActionIcon
                    onClick={() => setOpenedImage(!openedImage)}
                    style={{ position: 'absolute', right: size / 8 - size / 15, bottom: size / 8 - size / 15, zIndex: 10 }}
                    radius={16}
                    size={32}
                    variant="filled"
                  >
                    <TbPhoto size={20} />
                  </ActionIcon>
                </div>
                <Text mt="md">@{profile.data.username}</Text>
                <Text color="dimmed">{profile.data.profile.role}</Text>
                <Button mt="md" onClick={() => setOpened(!opened)}>
                  Change Password
                </Button>
              </Stack>
            </Grid.Col>
            <Grid.Col md={8} sm={12}>
              <Grid gutter={25}>
                <Grid.Col span={12}>
                  <Container mx="md">
                    <Grid>
                      <Grid.Col span={6}>
                        <Text fw={500}>Personal Information: </Text>
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <ActionIcon onClick={openEdit}>
                          <TbEdit />
                        </ActionIcon>
                      </Grid.Col>
                    </Grid>
                    <Grid gutter={20} mt="lg">
                      <Grid.Col md={3} span={4}>
                        <Text mt="sm" fw={500}>
                          First name:{' '}
                        </Text>
                        <Text mt="sm" fw={500}>
                          Last name:{' '}
                        </Text>
                        <Text mt="sm" fw={500}>
                          Email:{' '}
                        </Text>
                        <Text mt="sm" fw={500}>
                          Phone No.:{' '}
                        </Text>
                        <Text mt="sm" fw={500}>
                          Organization:{' '}
                        </Text>
                      </Grid.Col>
                      <Grid.Col md={9} span={8}>
                        <Text mt="sm">{profile.data.firstName || commonConstants.NA}</Text>
                        <Text mt="sm">{profile.data.lastName || commonConstants.NA}</Text>
                        <Text mt="sm">{profile.data.email}</Text>
                        <Text mt="sm">{profile.data.profile.phone || commonConstants.NA}</Text>
                        <Text mt="sm">{profile.data.profile.organization.name}</Text>
                      </Grid.Col>
                    </Grid>
                  </Container>
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <UserTasks userId={profile.data.id} name={profile.data.firstName + ' ' + profile.data.lastName} />
                </Grid.Col>
              </Grid>
            </Grid.Col>
            {/* <Grid.Col md={4} sm={12}>
              <Divider size="md" orientation="vertical" />
            </Grid.Col> */}
            {/* <Grid.Col md={8} sm={12}> */}
            {/* maybe a 2 colom grid  */}

            {/* </Grid.Col> */}
            {/* <Grid.Col span={6}></Grid.Col> */}
            {/* <Grid.Col offset={4} md={4} sm={12}> */}
            {/* <Skeleton height={300} /> */}
            {/* </Grid.Col> */}
          </>
        )}
      </Grid>
      <Modal opened={opened} onClose={() => setOpened(false)} title={'Update Password'}>
        <UpdatePasswordForm />
      </Modal>
      <Modal opened={openedImage} onClose={() => setOpenedImage(false)} title={'Upload Profile picture'}>
        <Stack align="center" spacing="sm">
          <UploadButton file={file} onChange={setFile} progress={progress}>
            {(props) => (
              <Button
                {...props}
                mt="md"
                radius="xl"
                size="md"
                styles={{
                  root: { height: 48 },
                }}
                variant="gradient"
                gradient={{ from: 'teal', to: 'lime', deg: 105 }}
              >
                Select profile image
              </Button>
            )}
          </UploadButton>
          <UploadProfileImage file={file} click={() => handleUpload()} progress={progress} />
        </Stack>
      </Modal>
      <Modal opened={openedEdit} onClose={closeEdit} size={'30%'} title="Update Profile">
        {profile && <UpdateUserProfileForm profile={profile.data} />}
      </Modal>
    </Layout>
  );
}
