import { Center, Container, Grid, Group, Loader, Pagination, Select, Title } from '@mantine/core';
import { useState } from 'react';
import { BaseCard, CardsFilter, useDeepFilter } from '../../../components/card';

import { useNavigate } from 'react-router-dom';
import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';
import Layout from '../../../components/layout/Layout';
import { simCardStatusColor } from '../../../constants/simCardStatusColor.enum';
import { URLParams } from '../../../utils';
import { PageSize } from '../../common';
import { useSimCards } from '../api';
import { UpdateSimCardForm } from '../components';
import { IsimCard } from '../types/types';

export function SimCardCards() {
  const params = new URLParams();
  params.includes('simCardType');
  const { isSuccess, data } = useSimCards({ params: params.toString() });
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const navigate = useNavigate();
  const { filter, setFilter, formattedData } = useDeepFilter(data ? data.data.items : []);
  let cardsLoop;
  if (isSuccess) {
    cardsLoop = formattedData
      .slice((page - 1) * pagesize, pagesize * page)
      .map((item: IsimCard) => (
        <BaseCard
          key={`card-${item.id}`}
          name={item.number.toString()}
          discription={`serial: ${item.serial}, type: ${item.simCardType.type}`}
          to={`/sim-card/${item.id}`}
          img={item.simCardType.imageRepositoryId}
          label={item.status}
          color={simCardStatusColor[item.status.split(' ').join('_') as keyof typeof simCardStatusColor]}
          updateModalContent={<UpdateSimCardForm idM={item.id} />}
        />
      ));
  }

  return (
    <Layout>
      <Container fluid>
        <Group px={13} mt={10} position="apart">
          <Title
            order={2}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Sim Cards:
          </Title>
          <AddMultipleButton
            add={() => navigate('/new/sim-card')}
            addMany={() => navigate('/new/sim-cards')}
            updateMany={() => navigate('/edit/sim-cards')}
          />
        </Group>
        <CardsFilter setFilter={setFilter} filter={filter} />
        <Grid>
          {formattedData && cardsLoop ? (
            cardsLoop
          ) : (
            <Container p={25}>
              <Loader />
            </Container>
          )}
        </Grid>
        <Center my={15}>
          <Pagination
            total={Math.ceil(formattedData.length / pagesize)}
            siblings={2}
            boundaries={2}
            onChange={(n) => setPage(n)}
            value={page}
          />
          <Select placeholder="Page Size" size="sm" ml={10} data={Object.values(PageSize)} onChange={(e) => setPagesize(Number(e))} />
        </Center>
      </Container>
    </Layout>
  );
}
