export enum deviceGenerationColor {
    '2G' = '#5c8f43',
	'3G'= '#bf5858',
	'4G' = '#be58bf',
	'5G' = '#bf9658',
}

export enum DeviceGeneration {
	G2 = '2G',
	G3 = '3G',
	G4 = '4G',
	G5 = '5G',
  }

export enum DeviceTypeColumnsMode {
	ID = "id",
	Supplier = "supplier",
	Type = "type",
	Generation = "generation",
	ImageRepositoryId = "imageRepositoryId",
}