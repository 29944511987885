import {
  ActionIcon,
  Alert,
  Button,
  Code,
  Container,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Table,
  TextInput,
  Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  TbAlertOctagon,
  TbAlertTriangle,
  TbCircleCheck,
  TbDownload,
  TbPlus
} from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { duplicatesFinder, handleDates, noEmptyString, useCsv } from '../../../utils';
import { IaxiosError } from '../../common';
import { IsimCardType, useSimCardTypes } from '../../sim-card-types';
import { postSimCards, useSimCards } from '../api';

export function AddMultipleSimCards() {
  const [file, setFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [duplicateAlert, setDuplicateAlert] = useState<string[] | null>(null);

  const queryClient = useQueryClient();
  const [simCardTypes,setSimCardTypes] = useState<IsimCardType[]>([])
  const viewSubmit = document.getElementById('submit')
  const viewTopOfPage = document.getElementById('TopOfPage')

  const {data: simCards } = useSimCards()
  const { data: simCardTypeData } = useSimCardTypes()
  useEffect(() => {
    if(simCardTypeData) setSimCardTypes(simCardTypeData.data.items)
  },[simCardTypeData])

  const requirments = {
    number: /[0-9]$/,
    serial: /^$|[0-9]$/,
    imsi: /^$|[0-9]$/,
    status: /\b(available|ready|in use|broken|lost|deactivated)\b/,
    simCardType: /[A-Za-z0-9]$/,
    purchaseDate: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    remarks: /^$|^[\s\S]*$/,

  };
  const templateText = `${Object.keys(requirments).join(',')}\r\n`;

  const { csvParser, data, headers, errorRows, validate, setItem, addRow, removeRow } =
    useCsv(requirments, Object.keys(requirments));

  function handleSelectFile(value: File | null) {
    setFile(value);
    if (value) {
      csvParser(value);
    }
  }
  useEffect(() => {
    if(errorRows.length === 0){
      viewSubmit?.scrollIntoView()
    }
  }, [data])

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postSimCards, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.SimCard, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit() {
    setAlert(null);
    setDuplicateAlert(null)

    viewTopOfPage?.scrollIntoView();
      if (data && simCards) {
      const duplicates = duplicatesFinder(simCards.data.items,data,'number','number')
      if(duplicates.length === 0){
        const newValues = data.map((item) => noEmptyString(item));
        const tempAlert:string[] = []
  
        newValues.forEach((item)=> {
          if(!simCardTypes.some((simCardType) => {
            if(simCardType.type.toUpperCase() === item.simCardType.toUpperCase()){
              item.simCardType = `${simCardType.id}`
              return true
            }
          })) tempAlert.push('simCard with simCardType ' + item.simCardType + ' does not exist in the database')
          item.purchaseDate = handleDates(item.purchaseDate)
          item.remarks = item.remarks ?? null

        })
        if(tempAlert.length === 0){
          newMutate(newValues);
        }else setAlert(tempAlert)
      }else setDuplicateAlert(duplicates)
    } else {
      setAlert(
        'something went wrong while preparing the data, please reload the page and try again.',
      );
    }
  }

  const rows = data?.map((row, i) => (
    <tr key={'r' + i}>
      {headers.map((key, index) => (
        <td key={key + index}>
          <TextInput
            value={row[key]}
            error={errorRows[index]?.includes(i + 1)}
            variant="unstyled"
            onChange={(v) => setItem(i, key, v.target.value)}
          />
        </td>
      ))}
    </tr>
  ));

  return (
    <Layout>
      <Container pt={10} fluid>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add Multiple Sim Cards:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <Group position="apart">
            <UploadCSVButton file={file} onChange={handleSelectFile}>
              {(props) => (
                <Button {...props} mt="md">
                  Upload CSV
                </Button>
              )}
            </UploadCSVButton>
            <Button
              mt="md"
              component="a"
              href={`data:text/csv;charset=utf-8,${templateText}`}
              download={'newSimCardsTemplate.csv'}
              leftIcon={<TbDownload size={16} />}
            >
              Download a template
            </Button>
          </Group>
          <Alert
            icon={<TbAlertTriangle size={16} />}
            title="Important!"
            withCloseButton
            color="blue"
            mt="md"
          >
            <List id='TopOfPage'>
              <List.Item>
                Currently only accepts CSV files, file must include the headers.
              </List.Item>
              <List.Item>number: must be numbers only and NOT empty.</List.Item>
              <List.Item>serial: must be numbers only and can be empty.</List.Item>
              <List.Item>imsi: must be numbers only and can be empty.</List.Item>
              <List.Item>
                status: must be one of these valuses: (<Code>available</Code>,{' '}
                <Code>ready</Code>, <Code>in use</Code>, <Code>broken</Code>,{' '}
                <Code>lost</Code>, <Code>deactivated</Code>).
              </List.Item>
              <List.Item>
                simCardType: sim card Type, must be registered
                in the system.
              </List.Item>
              <List.Item>
                purchaseDate:  must be NOT empty and its format is dd/mm/yyyy.
              </List.Item>
              <List.Item>remarks: optional.</List.Item>
            </List>
          </Alert>
          {data && Object.keys(requirments).length !== headers.length && (
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Warning!"
              color="orange"
              mt="md"
            >
              The number of column uploaded is different than expected!
            </Alert>
          )}
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {validate() && (
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Error!"
              color="red"
              mt="md"
            >
              <List>
                <List.Item>Errors in the following rows:</List.Item>
                {errorRows.map((errorRow, i) => (
                  <List.Item key={`error-${headers[i]}`}>
                    {headers[i]}: {errorRow.join(', ')}
                  </List.Item>
                ))}
              </List>
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
              mt="md"
            >
              Successfully added to the database!
            </Alert>
          )}
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
              mt="md"
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item key={errMsg}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {duplicateAlert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
              mt="md"
            >
              <Group position='apart' align='center'>
              {Array.isArray(duplicateAlert) ? (
                <List>
                  {duplicateAlert.map((errMsg) => (
                    <List.Item key={errMsg}>vehicle with {errMsg} already exist in System!</List.Item>
                    ))}
                </List>
              ) : (
                duplicateAlert + '!'
                )}
                <Button onClick={() => {
                  duplicateAlert.forEach(element => {
                    removeRow('number',element)
                  }
                  );
                  setDuplicateAlert(null)
                }}>Remove Duplicates</Button>
              </Group>
            </Alert>
          )}
          {data && (
            <Table striped highlightOnHover mt="md">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Group position="right" mt="md">
            <ActionIcon size="lg" variant="subtle">
              <TbPlus size={28} onClick={() => addRow()} />
            </ActionIcon>
          </Group>
          <Button
            id="submit"
            fullWidth
            disabled={!file || validate() || newLoading}
            onClick={handleSubmit}
            mt="md"
          >
            Submit
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
}
