import {
  ActionIcon,
  Alert,
  Button,
  Code,
  Container,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Table,
  TextInput,
  Title
} from '@mantine/core';
import { useState } from 'react';
import {
  TbAlertOctagon,
  TbAlertTriangle,
  TbCircleCheck,
  TbDownload,
  TbPlus
} from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { noEmptyString, useCsv } from '../../../utils';
import { IaxiosError } from '../../common';
import { postVehicleTypes } from '../api';

export function AddMultipleVehicleTypes() {
  const [file, setFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();

  const requirments = {
    brand: /^[\s\S]*$/,
    model: /^[\s\S]*$/,
    type: /\b(car|bus|pickup|truck|minibus\/van|crane|heavy_truck|tank_trailer|loader|trailers_semi|bucket_truck|forklift|street_cleaning_machine|road_roller|excavator|motorcycle)\b/,
    category: /\b(bus|small|big)\b/,
    year: /^$|[0-9]{4}$/,
  };

  const templateText = `${Object.keys(requirments).join(',')}\r\n`;

  const { csvParser, data, headers, errorRows, validate, setItem, addRow } =
    useCsv(requirments, Object.keys(requirments));

  function handleSelectFile(value: File | null) {
    setFile(value);
    if (value) {
      csvParser(value);
    }
  }

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postVehicleTypes, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.VehicleType, types.ALL], {
        exact: true,
      });
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit() {
    setAlert(null);
    if (data) {
      const newValues = data.map((item) => noEmptyString(item));
      newMutate(newValues);
    } else {
      setAlert(
        'something went wrong while preparing the data, please reload the page and try again.',
      );
    }
  }

  const rows = data?.map((row, i) => (
    <tr key={'r' + i}>
      {headers.map((key, index) => (
        <td key={key + index}>
          <TextInput
            value={row[key]}
            error={errorRows[index]?.includes(i + 1)}
            variant="unstyled"
            onChange={(v) => setItem(i, key, v.target.value)}
          />
        </td>
      ))}
    </tr>
  ));

  return (
    <Layout>
      <Container pt={10} fluid>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add Multiple Vehicle Types:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <Group position="apart">
            <UploadCSVButton file={file} onChange={handleSelectFile}>
              {(props) => (
                <Button {...props} mt="md">
                  Upload CSV
                </Button>
              )}
            </UploadCSVButton>
            <Button
              mt="md"
              component="a"
              href={`data:text/csv;charset=utf-8,${templateText}`}
              download={'newVehiclesTemplate.csv'}
              leftIcon={<TbDownload size={16} />}
            >
              Download a template
            </Button>
          </Group>
          <Alert
            icon={<TbAlertTriangle size={16} />}
            title="Important!"
            withCloseButton
            color="blue"
            mt="md"
          >
            <List>
              <List.Item>
                Currently only accepts CSV files, file must include the headers.
              </List.Item>
              <List.Item>model: must be any string.</List.Item>
              <List.Item>brand: must be any string.</List.Item>
              <List.Item>
                type: must be one of these values(<Code>car</Code>,{' '}
                <Code>bus</Code>, <Code>pickup</Code>, <Code>truck</Code>,
                <Code>tank_trailer</Code>, <Code>loader</Code>, <Code>trailers_semi</Code>,
                <Code>bucket_truck</Code>, <Code>forklift</Code>, <Code>street_cleaning_machine</Code>,
                <Code>minivan/bus</Code>, <Code>crane</Code>, <Code>heavy_truck</Code>,
                <Code>road_roller</Code>, <Code>excavator</Code>, <Code>motorcycle</Code>).
              </List.Item>
              <List.Item>
                category: must be one of these values(<Code>bus</Code>, <Code>small</Code>, <Code>big</Code>).
              </List.Item>
              <List.Item>
                year: can be empty or number bigger than 1970.
              </List.Item>
            </List>
          </Alert>
          {data && Object.keys(requirments).length !== headers.length && (
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Warning!"
              color="orange"
              mt="md"
            >
              The number of column uploaded is different than expected!
            </Alert>
          )}
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {validate() && (
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Error!"
              color="red"
              mt="md"
            >
              <List>
                <List.Item>Errors in the following rows:</List.Item>
                {errorRows.map((errorRow, i) => (
                  <List.Item key={`error-${headers[i]}`}>
                    {headers[i]}: {errorRow.join(', ')}
                  </List.Item>
                ))}
              </List>
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
              mt="md"
            >
              Successfully added to the database!
            </Alert>
          )}
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
              mt="md"
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {data && (
            <Table striped highlightOnHover mt="md">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Group position="right" mt="md">
            <ActionIcon size="lg" variant="subtle">
              <TbPlus size={28} onClick={() => addRow()} />
            </ActionIcon>
          </Group>
          <Button
            fullWidth
            disabled={!file || validate() || newLoading}
            onClick={handleSubmit}
            mt="md"
          >
            Submit
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
}
