import { useQuery } from 'react-query';
import api from '../api';
import { types } from '../../../../constants';
import { WialonSearchByNameResults } from '../types';

interface myResUnit {
  data: WialonSearchByNameResults;
}

export const getSearchWialonByName = (search: string): Promise<myResUnit> => {
  return api.get(`wialon/search-by-name/${search}`);
};

export const useSearchWialonByName = (search: string, options?: { enabled?: boolean; then?: (x: myResUnit) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.WialonSearch, { search }];
  const query = useQuery(
    cacheKey,
    () =>
      getSearchWialonByName(search).then((unit) => {
        options && options.then && options.then(unit);
        return unit;
      }),
    { enabled: search !== '' && options?.enabled, staleTime: 1000 * 5 },
  );
  return query;
};
