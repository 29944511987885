import { Alert, Button, List, LoadingOverlay, NumberInput, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { TbAlertOctagon, TbCircleCheck } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { DatePickerInputEditable, NumberInputEditable, SelectEditable } from "../../../components/form";
import { types } from "../../../constants";
import { enumToSelectData, removeNotEdited, selectColumns } from "../../../utils";
import { IaxiosError } from "../../common";
import { patchInvoice, useInvoice } from "../api";
import { InvoiceColumnsMode, InvoiceType } from "../constants/constants";
import { IupdateInvoiceInput } from "../types/types";

export function UpdateInvoiceForm({id}:{id:number}){
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();
    const {data: invoiceData} = useInvoice(id, {
        joins:['units'],
        selects:[...selectColumns(InvoiceColumnsMode), 'units.id'],
        then: (invoice) => {
            form.setValues({
                invoiceNo: invoice.data.invoiceNo,
                type: invoice.data.type,
                amount: invoice.data.amount,
                vat: invoice.data.vat,
                date: new Date(invoice.data.date),
                remarks: invoice.data.remarks? invoice.data.remarks : '',
                units: invoice.data.units.map(unit => {
                  return {id: unit.id}
                }) || []
              })
              form.resetDirty({    
                invoiceNo: invoice.data.invoiceNo,
                type: invoice.data.type,
                amount: invoice.data.amount,
                vat: invoice.data.vat,
                date: new Date(invoice.data.date),
                remarks: invoice.data.remarks? invoice.data.remarks : '',
                units: invoice.data.units.map(unit => {
                  return {id: unit.id}
                }) || []
            })
        }
    })
    const form = useForm<IupdateInvoiceInput>({
        initialValues: {
            invoiceNo: invoiceData?.data.invoiceNo || '',
            type: invoiceData?.data.type || '',
            amount: invoiceData?.data.amount || 0,
            vat: invoiceData?.data.vat || false,
            date: invoiceData?.data.date ? new Date(invoiceData.data.date) : new Date(),
            remarks: invoiceData?.data.remarks || '',
            units: invoiceData?.data.units.map(unit => {
              return {id: unit.id}
            }) || []
        }
    })

    const {
        mutate: patchMutate,
        isLoading: patchLoading,
        isSuccess: patchSuccess,
      } = useMutation(patchInvoice, {
        onSuccess: () => {
          setAlert(null);
          queryClient.invalidateQueries([types.Invoice, types.ALL]);
        },
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });

    function handleSubmit(values: IupdateInvoiceInput){
        const patchValues = removeNotEdited(values,form)
        patchMutate({id: id, data: patchValues})
    }
    return(
        <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
            <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {patchSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
            <TextInput 
            label="invoice" 
            placeholder='invoice number' 
            {...form.getInputProps(`invoiceNo`)}
            />

            <SelectEditable
            required
            label= 'Invoice Type'
            placeholder="Invoice Type"
            {...form.getInputProps('type')}
            data={enumToSelectData(InvoiceType)}
            mt='sm'
             />

            <NumberInputEditable
            label="Amount"
            placeholder="Amount"
            precision={2}
            {...form.getInputProps('amount')}
            required
            mt="md"
            />

            <NumberInput
                label="Quantity of Vehicles"
                placeholder="quantity"
                required
                value={form.values.units.length}
                disabled
                mt="md"
            />

            <DatePickerInputEditable
                placeholder="Due Date"
                label="Due Date"
                {...form.getInputProps(`date`)}
                mt="md"
            />

            <Textarea
              label="Remarks"
              placeholder="Remarks"
              {...form.getInputProps('remarks')}
              mt="md"
            />
            <Button type="submit" fullWidth mt='md'>Submit</Button>
        </form>
    )
}