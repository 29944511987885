
import Layout from '../../../components/layout/Layout';
import { NewVehicleTypeForm } from '../components';

export function NewVehicleTypeFormPage() {

  return (
    <Layout>
      <NewVehicleTypeForm/>
    </Layout>
  );
}
