import api from "../../common/api/api";
import { IAccessories, IAccessoriesInput } from "../types";

interface myResAccessory {
	data: IAccessories;
}

export const patchAccessory = (props: { id: number; data: Partial<IAccessoriesInput> }): Promise<myResAccessory> => {
	return api.patch(`accessories/${props.id}`, props.data);
};

export const patchAccessories = (data: Record<string, any>[]): Promise<myResAccessory[]> => {
	return api.patch('accessories/multi', data);
};

