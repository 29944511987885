import api from "../../common/api/api";
import { IsimCard, IsimCardInput } from "../types/types";

interface myResSimCard {
	data: IsimCard;
}

export const postSimCard = (data: IsimCardInput): Promise<myResSimCard> => {
	return api.post(`sim-cards`, data);
};

export const postSimCards = (data: Record<string, any>[]): Promise<myResSimCard[]> => {
	return api.post(`sim-cards/multi`, data);
};
