import { Affix, Alert, Button, Card, Checkbox, Container, Group, List, LoadingOverlay, Paper, Text, Title, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { TbAlertOctagon, TbCircleCheck } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { SelectEditable } from "../../components/form";
import Layout from "../../components/layout/Layout";
import { types } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";
import { IaxiosError } from "../../modules/common";
import { patchDevices, useDevices } from "../../modules/devices";
import { useUsers } from "../../modules/users";
import { URLParams } from "../../utils";

type transferListForm = {
    user: {id: number},
    devices: {id: number, checked: boolean, imei: number}[] | []
}

export function TechnicianTransferDevice(){
    const { user } = useAuthContext();
    const largeScreen = useMediaQuery('(min-width: 768px)');
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();

    const [userSelect, setUserSelect] = useState([{ value: '', label: '' }]);
    const userParams = new URLParams();
    userParams.includes('profiles');
    const { data: userData } = useUsers({ params: userParams.toString() });
    useEffect(() => {
        if (userData) {
        const tempArr = userData.data.items.map((parent) => {
            return {
            value: `${parent.id}`,
            label: `${parent.firstName} ${parent.lastName}`,
            };
        });
        if (tempArr.length === 0) {
            tempArr.push({
            value: '',
            label: 'No users, Please add the user first',
            });
        }
        tempArr.unshift({ value: '', label: 'No Recipient' });
        setUserSelect(tempArr);
        }
    }, [userData]);

    // const [devices, setDevices] = useState<Idevice []>([]);
    const deviceParams = new URLParams();
    if(user)
    deviceParams.filterSetField('=issuedTo', user.id);
    deviceParams.includes('issuedTo');
    const {data: devicesData} = useDevices({params: deviceParams.toString()})
    useEffect(() => {
        // const select: { value: string; label: string }[] = []
        if(devicesData){
            const temp = devicesData.data.items.map((item) => {
                return {id: item.id, checked: false, imei: item.imei}
            })
            // setDevices(temp)
            form.setFieldValue('devices', temp)
        }
        
    }, [devicesData])
    
    const form = useForm<transferListForm>({
        initialValues: {
            user: {id: 0},
            devices: []
        }
    })
    const cards = form.values.devices.map((device,index) => {
        return (
            <Card key={device.id} mb='sm' onClick={() => form.setFieldValue(`devices.${index}.checked`, !form.values.devices[index].checked)}>
            <Group>
                <Checkbox checked={form.values.devices[index].checked} onChange={() => form.setFieldValue(`devices.${index}.checked`, !form.values.devices[index].checked)} />
                <Text>{device.imei}</Text>
            </Group>
        </Card>
        )
    })

    const {
        mutate: patchMutate,
        isLoading: newLoading,
        isSuccess: newSuccess,
      } = useMutation(patchDevices, {
        onSuccess: () => {
          setAlert(null);
          queryClient.invalidateQueries([types.Device, types.ALL]);
          queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
          });
        },
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });
    
      function handleSubmit(values: transferListForm) {
        const newValues = values.devices.filter(device => device.checked).map(device => {
            return {id: device.id, issuedTo: values.user.id}
        })
        patchMutate(newValues)
        // patchMutate(newValues)
      }

    return (
        <Layout>
            <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
                Devices:
            </Title>
            <Container>
                <Paper withBorder p='sm'>
                <LoadingOverlay visible={newLoading} overlayBlur={2} />
                {alert && (
                    <Alert
                    icon={<TbAlertOctagon size={16} />}
                    title="Error!"
                    color="red"
                    mb={10}
                    >
                    {Array.isArray(alert) ? (
                        <List>
                        {alert.map((errMsg) => (
                            <List.Item>{errMsg}!</List.Item>
                        ))}
                        </List>
                    ) : (
                        alert + '!'
                    )}
                    </Alert>
                )}
                {newSuccess && (
                    <Alert
                    icon={<TbCircleCheck size={16} />}
                    title={'Created!'}
                    color="green"
                    mb={10}
                    >
                    Successfully added to the database!
                    </Alert>
                )}
                <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                    <SelectEditable
                        mb='sm'
                        required
                        withinPortal
                        searchable
                        label="User"
                        placeholder="Search here"
                        {...form.getInputProps('user.id')}
                        onChange={(v) => {
                        v && form.setFieldValue('user.id', parseInt(v));
                        }}
                        value={`${form.values.user.id}`}
                        data={userSelect}
                    />
          
                    {cards}
                    {largeScreen && 
                    <Button fullWidth disabled={form.values.user.id === 0} type="submit">Submit</Button>
                    }
                    
                    {form.values.user.id !== 0 && !largeScreen ? 
                    <Affix position={{ bottom: rem(20), right: rem(20) }}>
                        <Button type="submit">Submit</Button>
                    </Affix> : undefined
                    }
                </form>
                </Paper>
            </Container>
        </Layout>
    )
}