import {
  Alert,
  Button,
  Container,
  List,
  LoadingOverlay,
  NumberInput,
  Paper,
  Select,
  Table,
  TextInput
} from '@mantine/core';
import { useEffect, useState } from 'react';
  
  import { useForm } from '@mantine/form';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { types } from '../../../constants';
import { URLParams, enumToSelectData } from '../../../utils';
import { IaxiosError } from '../../common';
import { useOrganizations } from '../../organizations';
import { useVehicleTypes } from '../../vehicle-types';
import { patchVehicles, useVehicles } from '../api';
import { placeOfIssue } from '../constants';
import { IvehicleEditMultiple } from '../types/types';
  

  export function EditMultipleVehicles({ids}:{ids:number[]}) {
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();

    const params = new URLParams()
    params.includes('vehicleType','organization')
    params.filterSetLevel(0, '|')
    ids.forEach((id) => params.filterSetField('=id',id, 0))
    const {data:allVehicles } = useVehicles({ params: params.toString() });
    useEffect(() => {
        if (allVehicles) {
            const temp: IvehicleEditMultiple[] = allVehicles.data.items.map(item => {
                const obj: IvehicleEditMultiple =  {
                    id: item.id,
                    vin:item.vin,
                    placeOfIssue: item.placeOfIssue ? item.placeOfIssue : '' ,
                    plateNumber: item.plateNumber ? item.plateNumber : "",
                    category: item.category ? item.category : '',
                    vehicleType: item.vehicleType.id,
                    organization: item.organization.id,
                    odometerKM: item.odometerKM ? item.odometerKM : "",
                    odometerHours: item.odometerHours ? item.odometerHours :  "" ,
                    color: item.color,
                    licenseExpiryDate: item.licenseExpiryDate?  new Date(item.licenseExpiryDate) : null,
                  licenseIssueDate: item.licenseIssueDate?  new Date(item.licenseIssueDate) : null,
                }
                return obj
            })
            form.setValues(temp)
        }
    }, [allVehicles]);

    const [vehicleTypesSelect, setVehicleTypesSelect] = useState([
        { value: '0', label: '' },
      ]);

    const { data: allVehiclesTypesData } = useVehicleTypes();
    useEffect(() => {
        if (allVehiclesTypesData) {
        const tempArr = allVehiclesTypesData.data.items.map((vehicle) => {
            return {
            value: `${vehicle.id}`,
            label: `${vehicle.brand} ${vehicle.model}`,
            };
        });
        setVehicleTypesSelect(tempArr);
        }
    }, [allVehiclesTypesData]);

    const [organizationSelect, setOrganizationSelect] = useState([
        { value: '0', label: '' },
      ]);

    const orgParams = new URLParams()
    orgParams.includes('parent')
    const { data: allOrganizationData } = useOrganizations({params: orgParams.toString()});
    useEffect(() => {
        if (allOrganizationData) {
        const tempArr = allOrganizationData.data.items.map((organization) => {
            return {
            value: `${organization.id}`,
            label: `${organization.parent ? organization.name + ' - ' + organization.parent.name : organization.name}`,
            };
        });
        setOrganizationSelect(tempArr);
        }
    }, [allOrganizationData]);

    const form = useForm<IvehicleEditMultiple[]>({
        initialValues: 
          [{id:0,vin:'',plateNumber:'',category:'',placeOfIssue:'',vehicleType:0,organization:0,odometerHours:'',odometerKM:'',color:'', licenseExpiryDate: new Date(), licenseIssueDate: new Date()}]
        ,
        
      });


  
  
    const {
      mutate: newMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(patchVehicles, {
      onSuccess: () => {
        setAlert(null);
        queryClient.invalidateQueries([types.Vehicle, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });
  
    function handleSubmit(values: IvehicleEditMultiple[]) {
      Object.values(values).forEach(item => {
        item.odometerHours = item.odometerHours === '' ? null : item.odometerHours
        item.plateNumber = item.plateNumber === '' ? null : item.plateNumber
        item.odometerKM = item.odometerKM === '' ? null : item.odometerKM
        item.placeOfIssue = item.placeOfIssue === '' ? null : item.placeOfIssue
        item.category = item.category === '' ? null : item.category
      })
        newMutate(Object.values(values))
    }
//todo: if you are finishing this page add odometer to frontend in this page it is not added
    const rows = Object.values(form.values).map((row, i) => {
        return(
            <tr key={'r' + i}>
                <td>{row.id}</td>
                <td>
                <TextInput
                {...form.getInputProps(`${i}.vin`)}
                onChange={(v) => {
                    form.setFieldValue(`${i}.vin`, v.currentTarget.value);
                    }}
                />
                </td>
                <td>
                <Select
                withinPortal
                {...form.getInputProps(`${i}.placeOfIssue`)}
                data={enumToSelectData(placeOfIssue)}
                />
                </td>
                <td>
                <NumberInput 
                {...form.getInputProps(`${i}.plateNumber`)}
                onChange={(v) => {
                    if(v)
                    form.setFieldValue(`${i}.plateNumber`, v);
                    }}
                    value={form.values[i].plateNumber ? form.values[i].plateNumber as number : ''}
                    hideControls
                    />
                </td>
                <td>
                <TextInput
                {...form.getInputProps(`${i}.category`)}
                onChange={(v) => {
                    form.setFieldValue(`${i}.category`, v.currentTarget.value);
                    }}
                />
                </td>
                <td>
                <Select
                withinPortal
                searchable
                {...form.getInputProps(`${i}.vehicleType`)}
                onChange={(v) => {
                    if(v)
                    form.setFieldValue(`${i}.vehicleType`, parseInt(v));
                    }}
                    data={vehicleTypesSelect}
                    value={`${form.values[i].vehicleType}`}
                />
                </td>
                <td>
                <Select
                withinPortal
                searchable
                {...form.getInputProps(`${i}.organization`)}
                onChange={(v) => {
                    if(v)
                    form.setFieldValue(`${i}.organization`, parseInt(v));
                    }}
                    data={organizationSelect}
                    value={`${form.values[i].organization}`}
                />
                </td>
            </tr>
          )
    });
  
  
    return (
        <Container pt={10} fluid>
          <Paper withBorder shadow="md" p={30} my={30} radius="md">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
                mt="md"
              >
                Successfully added to the database!
              </Alert>
            )}
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                mt="md"
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item key={errMsg}>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>

            
            {form.values && (
            <Table highlightOnHover mt="md">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Vin</th>
                  <th>Place Of Issue</th>
                  <th>plate No</th>
                  <th>Category</th>
                  <th>Vehicle Type</th>
                  <th>Organization</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          <Button
              fullWidth
              type='submit'
              mt="md"
            >
              Submit
            </Button>
          </form>

            
          </Paper>
        </Container>
    );
  }
  