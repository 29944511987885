import { Alert, Button, Container, List, LoadingOverlay, Paper, Table, Textarea, Title } from '@mantine/core';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { useForm } from '@mantine/form';
import { useParams } from 'react-router-dom';
import { DatePickerInputEditable, SelectEditable } from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { enumToSelectData } from '../../../../utils';
import { IaxiosError } from '../../../common';
import { DeviceStatus } from '../../../devices';
import { patchUnitTechnicianTask } from '../../../unit';
import { useTask } from '../../api';
import { TaskStatus } from '../../constants/constants';

export function TaskRemovalApprovalForm() {
  const { id } = useParams();
  const [idN] = useState(+(id || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);

  const { data } = useTask(idN, {
    joins: ['unit', 'unit.vehicle as unitVehicle', 'unit.device as unitDevice', 'unit.simCard as unitSim'],
    selects: [
      'id',
      'completionDate',
      'jobRemarks',
      'unit.id',
      'unitVehicle.id',
      'unitVehicle.plateNumber',
      'unitVehicle.vin',
      'unitDevice.id',
      'unitDevice.imei',
      'unitSim.id',
      'unitSim.number',
    ],
    then: (task) => {
      form.setValues({
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : new Date(),
      });
    },
  });
  const form = useForm({
    initialValues: {
      completionDate: new Date(),
      deviceStatus: DeviceStatus.Not_Active,
    },
  });

  const queryClient = useQueryClient();

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchUnitTechnicianTask, {
    onSuccess: () => {
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(value: { completionDate: Date, deviceStatus: string }) {
    setAlert(null);
    if (data && data.data.unit) {
      patchMutate({
        id: data.data.unit.id,
        data: { taskId: data.data.id, taskStatus: TaskStatus.Done, simCard: null, device: null, completionDate: value.completionDate, deviceStatus: value.deviceStatus},
      });
    } else {
      setAlert(['The id provided is incorrect']);
    }
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Approve removal job:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {patchSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title="Updated!" color="green" mb={10}>
              Successfully updated in the database!
            </Alert>
          )}
          {data && data.data.unit && (
            <>
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td>Plate No.:</td>
                    <td>{data.data.unit.vehicle?.plateNumber}</td>
                  </tr>
                  <tr>
                    <td>Vin:</td>
                    <td>{data.data.unit.vehicle?.vin}</td>
                  </tr>
                  <tr>
                    <td>Imei:</td>
                    <td>{data.data.unit.device?.imei}</td>
                  </tr>
                  <tr>
                    <td>Sim Card Number:</td>
                    <td>{data.data.unit.simCard?.number}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <DatePickerInputEditable
              placeholder="Search Date"
              label="completion Date"
              {...form.getInputProps('completionDate')}
              required
              mt="md"
            />

            <SelectEditable
                dirty={form.isDirty('deviceStatus')}
                label="removed Device Status"
                placeholder="Search Here"
                {...form.getInputProps('deviceStatus')}
                required
                data={enumToSelectData(DeviceStatus)}
                mt="md"
              />

            {data && data.data.jobRemarks && (
              <Textarea mt="md" label="technician Remarks">
                {data.data.jobRemarks.split('/>').join('\n').split('<?').join('')}
              </Textarea>
            )}

            <Button type="submit" fullWidth>
              Confirm Removal
            </Button>
          </form>
        </Paper>
      </Container>
    </Layout>
  );
}
