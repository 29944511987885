import { LoadingOverlay, Select, SelectProps } from "@mantine/core";
import { useEffect, useState } from "react";
import { URLParams } from "../../../utils";
import { useUnits } from "../../unit";
import { TaskStatus, TaskType } from "../constants/constants";

export function SelectInstallationUnit({
    type,
    value,
    setValues,
    status,
    ...props
} : {
    type: string | '',
    value:string,
    setValues: (vehicle: {
        value: string,
        label: string,
        vehicleid:string,
        plate: string,
        platform:string,
        secondaryplatform: string,
        orgID: number,
      }) => void,
      status?: string,
      props?: Partial<SelectProps>
      
}){

    const [unit, setUnit] = useState([
        { value: '', label: '', vehicleid: '', plate: '', platform: '', secondaryplatform: '', orgID: 0 },
      ]);
    const unitParams = new URLParams();
    if(status && status !== TaskStatus.Pending){
        unitParams.includes('device', 'simCard','vehicle','vehicle.organization as org', 'deviceConfig');
        unitParams.filterSetField('vehicle','null')
        unitParams.select('unit.id','device.id', 'device.imei', 'simCard.id', 'simCard.number', 'vehicle.id', 'vehicle.vin', 'vehicle.plateNumber', 'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform', 'org.id')
    }
    else if(type === TaskType.Installation){
        unitParams.includes('device', 'simCard', 'vehicle','vehicle.organization as org','vehicle.vehicleType', 'deviceConfig');
        unitParams.filterSetField('=device','null')
        unitParams.filterSetField('vehicle','null')
        unitParams.filterSetField('=simCard','null')
        unitParams.select('unit.id','deviceId', 'simCardId', 'vehicle.id', 'vehicle.vin', 'vehicle.plateNumber', 'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform', 'org.id')
    }else {
        unitParams.includes('device', 'simCard', 'vehicle','vehicle.vehicleType','vehicle.organization as org', 'deviceConfig');
        unitParams.filterSetField('device','null')
        unitParams.filterSetField('vehicle','null')
        unitParams.filterSetField('simCard','null')
        unitParams.select('unit.id','device.id', 'device.imei', 'simCard.id', 'simCard.number', 'vehicle.id', 'vehicle.vin', 'vehicle.plateNumber', 'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform', 'org.id')
    }
    const { data: unitsData, isLoading } = useUnits({
        params: unitParams.toString(),
    });
    useEffect(() => {
        if (unitsData) {
            const tempArr = unitsData.data.items.map((unit) => {
                return {
                value: `${unit.id}`,
                label: type !== TaskType.Installation || (status && status !== TaskStatus.Pending) ? `${unit.device?.imei}, ${unit.simCard?.number}, ${unit.vehicle?.vin}, ${unit.vehicle?.plateNumber ?? ''}, ${unit.deviceConfig.platform}, ${unit.deviceConfig?.secondaryPlatform || ''}`
                : 
                `${unit.vehicle?.vin}, ${unit.vehicle?.plateNumber || ''}, ${unit.deviceConfig.platform}, ${unit.deviceConfig?.secondaryPlatform || ''}`,
                vehicleid: `${unit.vehicle?.id}`,
                plate: `${unit.vehicle?.plateNumber ?? ''}`,
                platform: `${unit.deviceConfig.platform}`,
                secondaryplatform: `${unit.deviceConfig?.secondaryPlatform ?? ''}`,
                orgID: unit?.vehicle?.organization?.id ?? 0,
                };
            });
            setUnit(tempArr);
        }
    }, [unitsData]);

    return(
        <>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <Select
            {...props}
            disabled={(status && status !== TaskStatus.Pending) ? true : false}
            label= {type === TaskType.Installation ? "Vehicles" : "Unit"}
            placeholder="Search here"
            onChange={(v) => {
                const vehicle = unit.find(unit => v && v === unit.value)
                if(vehicle){
                    setValues(vehicle)
                }
            }}
            searchable
            nothingFound="Not found"
            data={unit}
            required
            value={value}
            />
        </>
    )
}