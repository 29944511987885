import api from "../../api/api";
import { useQuery } from "react-query";
import { IrefreshProfile } from "../types/types";

export const GET_REFRESH = "get.refresh";

interface myResRefreshProfile {
	data: IrefreshProfile;
}

export const getRefresh = (): Promise<myResRefreshProfile> => {
	return api.get(`auth/refresh`);
};

export const useRefresh = (options?: { then?: (x: myResRefreshProfile) => any }) => {
	const query = useQuery(
		[GET_REFRESH],
		() =>
			getRefresh().then((refresh) => {
				options && options.then && options.then(refresh);
				return refresh;
			}),
		{ staleTime: 0, cacheTime: 0 }
	);
	return query;
};
