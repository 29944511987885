export enum PresetsType {
    TermsAndConditions = 'terms and conditions',
    Content = 'content',
    Massages = 'massages',
}

export enum PresetColumnsMode {
	ID = "id",
	Title = "title",
	Content = "content",
	Type = "type",
}