import api from "../../common/api/api";
import { Icertificate, IcertificateInput } from "../types/types";

interface myResCertificate {
	data: Icertificate;
}

export const patchCertificate = (props: { id: number; data: Partial<IcertificateInput> }): Promise<myResCertificate> => {
	return api.patch(`asateel-certificates/${props.id}`, props.data);
};

export const patchCertificates = (data: Record<string, any>[]): Promise<myResCertificate[]> => {
	return api.patch('asateel-certificates/multi', data);
};