import { Button, Select } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { types } from "../../../constants";
import { enumToSelectData } from "../../../utils";
import { IaxiosError } from "../../common";
import { patchTicket, useTicket } from "../api";
import { TicketStatus } from "../constants/constants";

export function UpdateTicketStatus(
{
    id,
    userID
}:{
    id: number,
    userID: number,
}){
    const [status, setStatus] = useState('')
    const queryClient = useQueryClient();

    const { data } = useTicket(id);
    
    const {
        mutate: patchMutate,
      } = useMutation(patchTicket, {
        onSuccess: (data) => {
            notifications.show({
                title: 'Success',
                message: `status succesfully changed`,
                color: 'green',
                autoClose: 10000,
            })
          queryClient.invalidateQueries([types.Ticket, types.ALL]);
          queryClient.invalidateQueries([types.Ticket, { id: data.data.id }]);
        },
        onError: (data: IaxiosError) => {
            notifications.show({
                title: 'Error',
                message: data.response.data.message,
                color: 'red',
                autoClose: 10000,
            })
        },
      });
    
    function handleSubmit() {
        if(data?.data.assignedOn){
            if(status) patchMutate({id: id, data: {status: status}})
        }
        else if(status) patchMutate({id: id, data: {status: status, assignedToUser: {id: userID}, assignedOn: new Date()}})
        else notifications.show({
            title: 'Error',
            message: 'Please Enter Something.',
            color: 'red',
            autoClose: 10000,
        })
        }

    return(
        <>
            <Select 
            withinPortal
            data={enumToSelectData(TicketStatus)} 
            mt='md'
            onChange={(v) => v && setStatus(v)}
            value={status}
            label='Ticket Status'
            required
            />
            <Button fullWidth mt="xl" onClick={handleSubmit}>
            Submit
            </Button>
        </>
    )
}