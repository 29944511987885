import { Alert, Button, Checkbox, Container, List, LoadingOverlay, Paper, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { NumberInputEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { removeNotEdited } from '../../../utils';
import { IaxiosError } from '../../common';
import { patchAccessoryType, useAccessoryType } from '../api';
import { IAccessoriesTypeInput } from '../types/types';

export function UpdateAccessoryTypeForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();

  const { data: accessoriesTypeData } = useAccessoryType(idN, {
    then: (accessoryType) => {
      form.setValues({
        name: accessoryType.data.name,
        brand: accessoryType.data.brand || '',
        model: accessoryType.data.model || '',
        totalQuantity: accessoryType.data.totalQuantity,
        usedQuantity: accessoryType.data.usedQuantity,
        hasAccessory: accessoryType.data.hasAccessory !== undefined ? accessoryType.data.hasAccessory : true,
      });
      form.resetDirty({
        name: accessoryType.data.name,
        brand: accessoryType.data.brand || '',
        model: accessoryType.data.model || '',
        totalQuantity: accessoryType.data.totalQuantity,
        usedQuantity: accessoryType.data.usedQuantity,
        hasAccessory: accessoryType.data.hasAccessory !== undefined ? accessoryType.data.hasAccessory : true,
      });
    },
  });

  const form = useForm<IAccessoriesTypeInput>({
    initialValues: {
      name: accessoriesTypeData?.data.name || '',
      brand: accessoriesTypeData?.data.brand || '',
      model: accessoriesTypeData?.data.model || '',
      totalQuantity: accessoriesTypeData?.data.totalQuantity || 0,
      usedQuantity: accessoriesTypeData?.data.usedQuantity || 0,
      hasAccessory: accessoriesTypeData?.data.hasAccessory !== undefined ? accessoriesTypeData?.data.hasAccessory : true,
    },
  });

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchAccessoryType, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.AccessoriesType, types.ALL]);
      queryClient.invalidateQueries([types.AccessoriesType, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.AccessoriesType, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IAccessoriesTypeInput) {
    setAlert(null);
    if (values.hasAccessory) {
      values.totalQuantity = 0;
      values.usedQuantity = 0;
    }
    const newValues = removeNotEdited(values, form);
    if (idN !== 0 && !Number.isNaN(idN)) {
      patchMutate({ id: idN, data: newValues });
    } else {
      setAlert(['The id provided is incorrect']);
    }
  }

  return (
    <Container pt={10}>
      <Title
        order={2}
        pl={5}
        align="left"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 800,
        })}
      >
        Edit a Accessory Type:
      </Title>
      <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
        <LoadingOverlay visible={patchLoading} overlayBlur={2} />
        {alert && (
          <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
            {Array.isArray(alert) ? (
              <List>
                {alert.map((errMsg) => (
                  <List.Item>{errMsg}!</List.Item>
                ))}
              </List>
            ) : (
              alert + '!'
            )}
          </Alert>
        )}
        {patchSuccess && (
          <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
            Successfully updated in the database!
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInputEditable
            dirty={form.isDirty('name')}
            label="Name of Accessory"
            placeholder="name"
            {...form.getInputProps('name')}
            required
          />

          <NumberInputEditable
            label="Total Quantity"
            placeholder="total quantity"
            {...form.getInputProps(`totalQuantity`)}
            required
            mt="md"
          />

          <NumberInputEditable label="Used Quantity" placeholder="used quantity" {...form.getInputProps(`usedQuantity`)} required mt="md" />

          <Checkbox mt="sm" label="has serial Number?" checked={form.values.hasAccessory} {...form.getInputProps('hasAccessory')} />

          <Button fullWidth mt="xl" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
