import { TransferList } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useEffect, useState } from "react";
import { URLParams } from "../../../utils";
import { useAccessories } from "../../accessories";
import { useAccessoriesType } from "../../accessoriesType";
import { IUpdateUnitInput } from "../types/types";

type listType = [{ value: string; label: string }[], { value: string; label: string }[]]
export function UpdateAccessoriesTransferList({ form, id }
    :
    {
        id: number,
        form: UseFormReturnType<IUpdateUnitInput, (values: IUpdateUnitInput) => IUpdateUnitInput>,
    }){
    const [accessories, setAccessories] = useState<listType>([[{ value: '0', label: 'loading...' }], [{ value: '0', label: 'loading...' }]]);
    const [accessoryTypes, setAccessoryTypes] = useState<listType>([[{ value: '0', label: 'loading...' }], [{ value: '0', label: 'loading...' }]]);
    const accessoriesParams = new URLParams();
    accessoriesParams.includes('accessoriesType', 'unit');
    const { data: accessoriesData } = useAccessories({params: accessoriesParams.toString()});
    useEffect(() => {
        // const tempArr = deviceData.data.items.filter((item) => {
        //     const device = (item as unknown as Idevice & { unit: Iunit })
        //     return device.unit === null || device.unit.id === idN
        //   })
        //     .map((device) => {
        //       return { value: `${device.id}`, label: `${device.imei}` };
        //     });
        const select: { value: string; label: string }[] = []
        const notSelect: { value: string; label: string }[] = []
        if (accessoriesData) {
            accessoriesData.data.items.filter(item => item.unit?.id === id || !item.unit).forEach((item) => {
                if(form.values.accessories?.some((element) => element.id === item.id)){
                    select.push({value:`${item.id}`,label:`serialNo: ${item.serialNo}, name: ${item.accessoriesType.name}`})
                }else{
                    notSelect.push({value:`${item.id}`,label:`serialNo: ${item.serialNo}, name: ${item.accessoriesType.name}`})
                }
                
            })
        }
        setAccessories([select,notSelect])
    }, [accessoriesData,form.values.accessories]);
    
    const accessoriesTypesParams = new URLParams();
    accessoriesTypesParams.filterSetField('hasAccessory', 0);
    const { data: accessoryTypesData } = useAccessoriesType({params: accessoriesTypesParams.toString()});
    useEffect(() => {
        const select: { value: string; label: string }[] = []
        const notSelect: { value: string; label: string }[] = []
        if (accessoryTypesData) {
            accessoryTypesData.data.items.forEach((item) => {
                if(form.values.accessoriesTypes?.some((element) => element.id === item.id)){
                    select.push({value:`${item.id}`,label:`${item.name}`})
                }else{
                    notSelect.push({value:`${item.id}`,label:`${item.name}`})
                }
            })
        }
        setAccessoryTypes([select,notSelect])
    }, [accessoryTypesData,form.values.accessoriesTypes]);
    return (
        <>
        <TransferList
                value={accessories}
                onChange={(v: listType) => {
                    setAccessories(v);
                    v[0]
                    form.setFieldValue(`accessories`, v[0].map((item) => { return { id: +item.value} }));
                }}

                searchPlaceholder="Search..."
                nothingFound="Nothing here"
                titles={['accessories selected', 'accessories not selected']}
                breakpoint="sm"
                mt='md'
            />
        <TransferList
                value={accessoryTypes}
                onChange={(v: listType) => {
                    setAccessoryTypes(v);
                    v[0]
                    form.setFieldValue(`accessoriesTypes`, v[0].map((item) => { return { id: +item.value} }));
                }}

                searchPlaceholder="Search..."
                nothingFound="Nothing here"
                titles={['extra accessories selected', 'extra accessories not selected']}
                breakpoint="sm"
                mt='md'
            />
        </>
    )
}