  
  import Layout from '../../../components/layout/Layout';
import { UpdateDeviceForm } from '../components/UpdateDeviceForm';
  
  export function UpdateDeviceFormPage() {
    return (
      <Layout>
        <UpdateDeviceForm idM={0}/>
      </Layout>
    );
  }
  