import {
   Container,
   Divider,
   Paper, Text,
   Title
} from '@mantine/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { selectColumns } from '../../../../utils';
import { simpleDateFormat } from '../../../../utils/simpleDateFormat';
import { usePurchaseOrder } from '../../api';
import { PurchaseOrderColumnsMode } from '../../constants/constants';
import { UpdatePlanForm } from './UpdatePlanForm';
import { UpdatePlanSummary } from './UpdatePlanSummary';



export function UpdateOrderForm({ idM }: { idM: number }) {
   const { id } = useParams();
   const [idN] = useState(+(id || idM || 0));
   const [openEdit, setOpenEdited] = useState(-1)

   const { data: purchaseOrderData } = usePurchaseOrder(idN, {
      joins: ['purchaseOrderPlan', 'organization'],
      selects: [...selectColumns(PurchaseOrderColumnsMode), 'purchaseOrderPlan.id', 'organization.id', 'organization.name']
   })

   const plans = purchaseOrderData?.data.purchaseOrderPlan.map((plan, index) => {
      return openEdit === index ? <UpdatePlanForm key={index} planId={plan.id} setOpenEdited={setOpenEdited} />
         : <UpdatePlanSummary key={index} planId={plan.id} setOpenEdited={setOpenEdited}  planIndex={index} />
   })


   return (
      <Container pt={10}>
         <Title
            order={2}
            pl={5}
            align="left"
            sx={(theme) => ({
               fontFamily: `Greycliff CF, ${theme.fontFamily}`,
               fontWeight: 800,
            })}
         >
            Update Purchase Order:
         </Title>
         <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
            <Text mt='lg'>title: {purchaseOrderData?.data.title}</Text>
            <Text mt='lg'>status: {purchaseOrderData?.data.status}</Text>
            <Text mt='lg'>Order Date: {simpleDateFormat(purchaseOrderData?.data.date ?? null)}</Text>
            <Text mt='lg'>Organization: {purchaseOrderData?.data.organization.name}</Text>
            <Text mt='lg'>Remarks: {purchaseOrderData?.data.remarks}</Text>

               <Title order={4} mt="md">
                  Purchase Order Plan:
               </Title>
               <Divider size="md" />
               {plans}
         </Paper>
      </Container>
   );
}
