import { ActionIcon, Group, Paper, ScrollArea, Tabs, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbReload } from 'react-icons/tb';
import { Task, useTasks } from '../../../modules/tasks';
import { UserRole } from '../../../modules/users';
import { URLParams, groupBy } from '../../../utils';
import { CardsFilterWithButton, useDeepFilter } from '../../card';
import { TaskCard } from '../taskCard/TaskCard';

function renderGroups(groups: Record<string, Task[]>) {
  return Object.entries(groups)
    .sort((a, b) => a[0].localeCompare(b[0], undefined, { numeric: true }))
    .map((group) => {
      return (
        <Paper
          key={group[0]}
          withBorder
          p={5}
          my={10}
          sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          })}
        >
          <Text size="md" weight={700}>
            {group[0]}
          </Text>
          {group[1].map((item) => (
            <TaskCard
              key={item.id}
              id={item.id}
              type={item.type}
              client={item.organization}
              location={item.location}
              vehicle={item.vehicle}
              unit={item.unit}
              remarks={item.taskRemarks}
              address={item.address}
              contactName={item.contacts ? item.contacts.name : item.contactName}
              contactNumber={item.contacts ? item.contacts.phone : item.contactNumber}
              title={item.title}
            />
          ))}
        </Paper>
      );
    });
}

export function TasksCard({ cardHei }: { cardHei: number }) {
  const [tasksData, setTasksData] = useState<Task[]>([]);
  const params = new URLParams();
  const { filter, setFilter, formattedData } = useDeepFilter(tasksData);

  params.filterSetField('status', 'pending');
  params.filterSetField('recipientGroup', UserRole.Technician);
  params.includes(
    'unit',
    'vehicle',
    'organization',
    'vehicle.vehicleType',
    'unit.device',
    'unit.simCard',
    'unit.vehicle as unitvehicle',
    'contacts',
  );
  const { data: allTasks, refetch } = useTasks({ params: params.toString() });
  useEffect(() => {
    if (allTasks) {
      setTasksData(allTasks.data.items);
    }
  }, [allTasks]);

  function clientGr() {
    const results = groupBy(formattedData.slice(0, 25), (i) => i.organization.name);
    return renderGroups(results);
  }

  function locationGr() {
    const results = groupBy(formattedData.slice(0, 25), (i) => i.address);
    return renderGroups(results);
  }

  function dateGr() {
    const results = groupBy(formattedData.slice(0, 25), (i) =>
      i.completionDate ? new Date(i.completionDate).toString().slice(4, 16) : 'No date assigned',
    );
    return renderGroups(results);
  }

  return (
    <Paper style={{ height: cardHei + 92 }} px={10} withBorder radius="md">
      {/* <Group position={'apart'}> */}
      <Group>
        <Text py={10} style={{ fontWeight: 900 }}>
          Tasks:{' '}
        </Text>
        <ActionIcon onClick={() => refetch()}>
          <TbReload />
        </ActionIcon>
      </Group>
      {/* <Button onClick={() => navigate('/new/technicianTask')}>Add New Task</Button> */}
      {/* </Group> */}
      <Group>
        <CardsFilterWithButton setFilter={setFilter} filter={filter} />
      </Group>
      <Tabs defaultValue="client">
        <Tabs.List>
          <Tabs.Tab value="client">Client</Tabs.Tab>
          <Tabs.Tab value="location">Location</Tabs.Tab>
          <Tabs.Tab value="date">Date</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="client" pt="xs">
          <ScrollArea style={{ height: cardHei - 93 }}>{clientGr()}</ScrollArea>
        </Tabs.Panel>

        <Tabs.Panel value="location" pt="xs">
          <ScrollArea style={{ height: cardHei - 93 }}>{locationGr()}</ScrollArea>
        </Tabs.Panel>

        <Tabs.Panel value="date" pt="xs">
          <ScrollArea style={{ height: cardHei - 93 }}>{dateGr()}</ScrollArea>
        </Tabs.Panel>
      </Tabs>
    </Paper>
  );
}
