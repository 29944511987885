import api from '../../common/api/api';
import { IattendanceInput } from "../types/types";
import { Iattendance } from './../types/types';

interface myResAttendancePost {
	data: Iattendance;
}

export const postAttendance = (data: Partial<IattendanceInput>): Promise<myResAttendancePost> => {
	return api.post(`attendance`, data);
};