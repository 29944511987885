import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Irecord } from '../types/types';

interface myResRecords {
  data: {
    items: Irecord[];
    meta: metaData,
  };
}

export const getRecords = (query = ''): Promise<myResRecords> => {
  return api.get(`records${query}`);
};

export const useRecords = (options?: { params?: string; then?: (x: myResRecords) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Record, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getRecords(options?.params).then((records) => {
      options && options.then && options.then(records);
      return records;
    }), {enabled: options?.enabled ?? true },
  );
  return query;
};

interface myResCountRecordsNumber {
  data: number;
}

export const countRecords = (query = ''): Promise<myResCountRecordsNumber> => {
  return api.get(`records/count${query}`);
};

export const useCountRecords = (options?: { params?: string; then?: (x: myResCountRecordsNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Record, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countRecords(options?.params).then((records) => {
      options && options.then && options.then(records);
      return records;
    }),
  );
  return query;
};