import { ActionIcon, Card, Group, Modal, Text, Tooltip, createStyles, rem } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { TbEye } from "react-icons/tb";
import { URLParams } from "../../../utils";
import { useTasks } from "../../tasks";
import { ViewUserTasks } from "./ViewUserTasks";

const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
      borderTop: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      lineHeight: 1,
    },
  }));
  
export function UserTasks({userId, name}:{userId: number, name: string}){
    const params = new URLParams()
    params.filterSetLevel(0, '|')
    params.includes('issuedBy', 'approvedBy', 'performedBy', 'recipient', 'unit', 'unit.vehicle as unitVehicle', 'vehicle')
    params.filterSetField('performedBy.id', userId, 0)
    params.filterSetField('issuedBy.id', userId, 0)
    params.filterSetField('approvedBy.id', userId, 0)
    params.filterSetField('recipient.id', userId, 0)
    const { data } = useTasks({params: params.toString()})
    const { classes } = useStyles();

    const [opened, { open, close }] = useDisclosure()
    const [ view, setView ] = useState('')
    const issuedByUser = data?.data.items.filter(item => item.issuedBy?.id === userId) 
    const performedByUser = data?.data.items.filter(item => item.performedBy?.id === userId) 
    const approvedByUser = data?.data.items.filter(item => item.approvedBy?.id === userId) 
    
    return(
        <Card withBorder padding="lg" className={classes.card}>
            <Text fz="sm" fw={700} className={classes.title} mb='md'>
                {name} Tasks
            </Text>
            <Card.Section className={classes.footer}>
                <div>
                    <Group position="apart">
                        <Text size="xs" color="dimmed">
                            created:
                        </Text>
                        <ActionIcon onClick={() => {
                            setView('created')
                            open()
                        }}><TbEye /></ActionIcon>
                    </Group>
                    <Text weight={500} size="sm">
                    {issuedByUser && issuedByUser.length}
                    </Text>
                </div>
                <div>
                    <Group position="apart">
                        <Text size="xs" color="dimmed">
                            performed:
                        </Text>
                        <ActionIcon onClick={() => {
                            setView('performed')
                            open()
                        }}><TbEye /></ActionIcon>
                    </Group>
                    <Text weight={500} size="sm">
                        {performedByUser && performedByUser.length}
                    </Text>
                </div>
                <div>
                    <Group position="apart">
                        <Text size="xs" color="dimmed">
                            approved:
                        </Text>
                        <Tooltip label={'view'}>
                            <ActionIcon onClick={() => {
                                setView('approved')
                                open()
                            }}><TbEye /></ActionIcon>
                        </Tooltip>
                    </Group>
                    <Text weight={500} size="sm">
                    {approvedByUser && approvedByUser.length}
                    </Text>
                </div>
            </Card.Section>
            <Modal opened={opened} onClose={close} size={'50%'}>
                <ViewUserTasks tasks={view === 'created' ? issuedByUser : view === 'performed' ? performedByUser : approvedByUser } />
            </Modal>
        </Card>
    )
}