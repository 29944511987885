import { useEffect, useState } from "react"
import { useDevices } from "../../modules/devices"
import { DownloadAsCsv, Format, URLParams, dateToNewDate, getAllKeysWithPath, getKeysWithPath } from "../../utils"
import { ExportToCsvButton } from "../table/components"

export function ExportDevices({
    ids,
}:{
    ids: number[]
}){
    // const devi: IdeviceType =
    const device = {
        id: 0,
        imei: 0,
        status: "",
        ownership: "",
        deviceType: {
            id: 0,
            supplier: "",
            type: "",
            generation: "",
        },
        purchaseDate: new Date(),
        issuedTo: {
            id: 0,
            firstName: "",
            lastName: "",
        },
        unit: {
            id: 0,
            simCard: {
                id: 0,
                number: "",
                serial: "",
                status: "",
            },
            vehicle: {
                id: 0,
                category: "",
                plateNumber: 0,
                placeOfIssue: "",
                vin: "",
                vehicleType: {
                    id: 0,
                    model: "",
                    brand: "",
                    year: 0,
                    type: "",
                } ,
                organization: {
                    id: 0,
                    name: "",
                } ,
            },
            deviceConfig: {
                id: 0,
                platform: "",
                secondaryPlatform: "",
            },
        }
    }

    const [ fetch, setFetch ] = useState(false)
    const [ fileName, setFileName ] = useState('')
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
            'deviceType',
            'unit',
            'unit.vehicle as unitVehicle',
            'unitVehicle.vehicleType as unitVehicleType',
            'unitVehicle.organization as unitOrganization',
            'unit.simCard as unitSimCard',
            'unit.deviceConfig as unitConfig',
            'issuedTo'
        );
        
        param.sort(('-device.id'))
        return param
      })
    // params.se
    const { data: allDevices, refetch } = useDevices({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (allDevices) {
            
          DownloadAsCsv(dateToNewDate(allDevices.data.items, ['purchaseDate']), fileName, getKeysWithPath(getAllKeysWithPath(allDevices.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [allDevices]);

    return(
        <ExportToCsvButton keys={getKeysWithPath(device,[], ':')} onSubmit={(v => {
            setFileName(v.fileName)
            const paths = Object.values(v.columns).reduce((cols, item) => {
                    const path = handleReplacePath(item.path, [{originalPath: 'unit.deviceConfig', replaceTo: 'unitConfig'}, {originalPath:'unit.simCard', replaceTo: 'unitSimCard'},{originalPath: 'unit.vehicle.vehicleType', replaceTo: 'unitVehicleType'},{originalPath: 'unit.vehicle.organization', replaceTo: 'unitOrganization'}, {originalPath: 'unit.vehicle', replaceTo: 'unitVehicle'}])
                    
                    if (item?.checked) cols.push(path)
                    return cols
                  }, [] as string[])
            setParams(param => {
                param.select(...paths)
                param.resetSearchMany()
                param.searchMany('id', ids.map(id => `${id}`))
                // param.filterRemoveSelects()
                // param.filterRemoveAllField('=id')
                // ids.forEach(id => {
                //     param.filterSetLevel(1 , '|')
                //     param.filterSetField('=id', id, 1)
                // })
                return param
            })
            
            if(!fetch){
                setFetch(true)
            } else refetch()
        })}/>
    )
}
function handleReplacePath(path: string, replaces:{originalPath: string, replaceTo: string}[]){
    const found = replaces.find(item => path.includes(item.originalPath))
    const paths = path.split('.')
    const column = paths[paths.length-1]
    if(found) return found.replaceTo+'.'+column
    else return path
}