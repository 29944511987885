import { useQuery } from 'react-query';
import { types } from '../../../constants';
import api from '../../common/api/api';
import { IOrgVehicles } from '../types/types';

interface myResVehicle {
  data: IOrgVehicles;
}

export const getVehicle = (id: number, joins?: string[], selects?: string[]): Promise<myResVehicle> => {
  let params = '';
  if (joins) params = '?joins='.concat(joins.join());
  if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
  return api.get(`vehicles/${id}${params}`);
};

export const useVehicle = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResVehicle) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Vehicle, { id }];
  if (options && options.joins) cacheKey.push(...options.joins);
  const query = useQuery(
    cacheKey,
    () =>
      getVehicle(id, options?.joins, options?.selects).then((vehicle) => {
        options && options.then && options.then(vehicle);
        return vehicle;
      }),
    { enabled: id !== 0 },
  );
  return query;
};
