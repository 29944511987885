
import Layout from '../../../components/layout/Layout';
import { useAuthContext } from '../../../context/AuthContext';
import { UserRole } from '../../users';
import { UpdateOrderForm } from '../components';

export function UpdateOrderFormPage() {
   const { user } = useAuthContext()

   if (user?.role !== UserRole.Master && user?.role !== UserRole.Finance) return null

   return (
      <Layout>
         <UpdateOrderForm idM={0} />
      </Layout>
   );
}
