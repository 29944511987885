import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Task } from '../types/types';

interface myResTasks {
  data: {
    items: Task[];
    meta: metaData;
  };
}

export const getTasks = (query = ''): Promise<myResTasks> => {
  return api.get(`tasks${query}`);
};

export const useTasks = (options?: { params?: string; then?: (x: myResTasks) => any; enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Task, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      getTasks(options?.params).then((tasks) => {
        options && options.then && options.then(tasks);
        return tasks;
      }),
    { enabled: options?.enabled ?? true },
  );
  return query;
};
interface myResCountTasksNumber {
  data: number;
}

export const countTasks = (query = ''): Promise<myResCountTasksNumber> => {
  return api.get(`tasks/count${query}`);
};

export const useCountTasks = (options?: { params?: string; then?: (x: myResCountTasksNumber) => any; enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Task, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      countTasks(options?.params).then((tasks) => {
        options && options.then && options.then(tasks);
        return tasks;
      }),
    { enabled: options?.enabled ?? true },
  );
  return query;
};

interface myResCountTasks {
  data: Record<string, string | number>[];
}

export const analyticsTasks = (query = ''): Promise<myResCountTasks> => {
  return api.get(`tasks/analytics${query}`);
};

export const useAnalyticsTasks = (options?: { params?: string; then?: (x: myResCountTasks) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Task, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      analyticsTasks(options?.params).then((counts) => {
        options && options.then && options.then(counts);
        return counts;
      }),
    // { staleTime: 1000 * 30 },
  );
  return query;
};
