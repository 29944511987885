import { Button, Checkbox, Group, Title } from "@mantine/core";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import { SideBar } from "../../../components/sideBar";
import BaseTable from "../../../components/table/BaseTable";
import { types } from "../../../constants";
import { URLParams, setTableParams } from "../../../utils";
import { useRfids } from "../api";
import { RfidCard } from "../components";
import { Irfid } from "../types/types";

export function RfidTable() {
   const [page, setPage] = useState(1)
   const [data, setData] = useState<Irfid[]>([])
   const [ids, setIds] = useState<number[]>([])
   const [rowSelection, setRowSelection] = useState({})
   const [params, setParams] = useState<URLParams>(() => {
      const param = new URLParams()
        param.page(10, page-1)
      return param
    })
   const { data: allRfids, refetch, isLoading } = useRfids({params: params.toString()})
   const navigate = useNavigate()

   useEffect(() => {
      if (allRfids) {
         setData(allRfids.data.items)
         const selectedRows = allRfids.data.items.map((item) => {
            if(ids.includes(item.id)) return {index: true}
          })
          setRowSelection(selectedRows)
      }
   }, [allRfids])

   const columnHelper = useMemo(() => createColumnHelper<Irfid>(), [])
   const columns: ColumnDef<Irfid, any>[] = useMemo(() => [
      columnHelper.display({
         id: 'select',
         
         header: ({ table }) => (
           <Checkbox
             mt={'md'}
             {...{
               checked: table.getIsAllRowsSelected(),
               indeterminate: table.getIsSomeRowsSelected(),
               onChange: (event) => {
                 const allSelected = ids.length === allRfids?.data.meta.ids.length
                 if(allSelected){
                   setIds([])
                 }else setIds(allRfids?.data.meta.ids ? allRfids?.data.meta.ids : ids)
                 table.getToggleAllRowsSelectedHandler()(event);
               },
             }}
           />
         ),
         cell: ({ row }) => (
           <div className="px-1">
             <Checkbox
               {...{
                 checked: row.getIsSelected(),
                 indeterminate: row.getIsSomeSelected(),
                 onChange: (event) => {
                   const rowId = ids.findIndex(id => id === row.original.id)
                   if(rowId === -1){
                     setIds([...ids, row.original.id])
                   }else {
                     setIds(ids => {
                       ids.splice(rowId,1)
                       return ids
                     })
                   }
                   row.getToggleSelectedHandler()(event);
                 },
               }}
             />
           </div>
         ),
       }),
      columnHelper.accessor((row) => `${row.id}`, { header: 'ID' }),
      columnHelper.accessor('printedValue', { header: () => 'Printed Value' }),
      columnHelper.accessor('bceValue', { header: () => 'bce Value' }),
      columnHelper.accessor('teltonikaValue', { header: () => 'Teltonika Value' }),
      columnHelper.accessor((row) => '' + row.isUsed, { header: "Used" }),
   ], [columnHelper, data, ids])

   return (
      <Layout>
         <Group px={10} mt={10} mb={20} position="apart">
            <Title
               order={2}
               align="left"
               sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}
            >
               Rfids:
            </Title>
            <Button onClick={() => navigate('/new/rfid')} >Add new</Button>
         </Group>
         <BaseTable<Irfid>
            data={data}
            loading={isLoading}
            page={page}
            ids={ids}
            setPage={(value) => {
               setParams((param) => {
                 param.filterRemovePages()
                 param.page(10, value - 1)
                 return param
               })
               setPage(value)
              
             }}
             pages={allRfids?.data.meta.ids.length ? Math.ceil(allRfids?.data.meta.ids.length/10): 1}
             filterData={(column, value, type)=> {
               setTableParams(column, value, type, setParams)
               refetch()
               setPage(1)
             }}
            columns={columns}
            
            setIds={setIds}
            setRowSelection={setRowSelection} rowSelection={rowSelection} 
         />
         <SideBar id={ids} type={types.RFID}>
            <RfidCard id={ids[0]} />
         </SideBar>
      </Layout>
   );
}
