import {
  Alert,
  Button,
  Container,
  Grid,
  List,
  LoadingOverlay,
  Paper,
  Textarea,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { DetailsCard } from '../../../components/detailsCards';
import { DatePickerInputEditable, NumberInputEditable, SelectEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, enumToSelectData, noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { DeviceTypeCard, useDeviceTypes } from '../../device-types';
import { useOrganizations } from '../../organizations';
import { useUsers } from '../../users';
import { postDevice } from '../api';
import { DeviceOwnership, DeviceStatus } from '../constants/constants';
import { IdeviceInput } from '../types/types';

export function NewDeviceForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [deviceTypesSelect, setDeviceTypesSelect] = useState([
    { value: '', label: 'loading...' },
  ]);
  const [deviceTypeId, setDeviceTypeId] = useState(0);
  const queryClient = useQueryClient();
  
  const [userSelect, setUserSelect] = useState([{ value: '', label: '' }]);
  const userParams = new URLParams();
  userParams.select('id', 'firstName', 'lastName');
    const { data: userData } = useUsers({ params: userParams.toString() });
    useEffect(() => {
      if (userData) {
        const tempArr = userData.data.items.map((parent) => {
          return {
            value: `${parent.id}`,
            label: `${parent.firstName} ${parent.lastName}`,
          };
        });
        if (tempArr.length === 0) {
          tempArr.push({
            value: '',
            label: 'No users, Please add the user first',
          });
        }
        tempArr.unshift({ value: '', label: 'No Recipient' });
        setUserSelect(tempArr);
      }
    }, [userData]);
    
  const [organizationsSelect, setOrganizationsSelect] = useState([{ value: '', label: '' }]);
  const organizationParams = new URLParams();
  organizationParams.includes('parent');
  organizationParams.select('id', 'name', 'parent.id', 'parent.name');
    const { data: organizationData } = useOrganizations({ params: organizationParams.toString() });
    useEffect(() => {
      if (organizationData) {
        const tempArr = organizationData.data.items.map((org) => {
          return {
            value: `${org.id}`,
            label: `${org.name}, ${org.parent?.name ?? ''}`,
          };
        });
        if (tempArr.length === 0) {
          tempArr.push({
            value: '',
            label: 'No organizations, Please add the organization first',
          });
        }
        tempArr.unshift({ value: '', label: 'No Organization' });
        setOrganizationsSelect(tempArr);
      }
    }, [organizationData]);

  const typeParams = new URLParams();
  typeParams.select('id', 'supplier', 'type');
  const { data: allDeviceTypes } = useDeviceTypes({params: typeParams.toString()});
  useEffect(() => {
    if (allDeviceTypes) {
      const tempArr = allDeviceTypes.data.items.map((type) => {
        return { value: `${type.id}`, label: `${type.supplier}, ${type.type}` };
      });
      setDeviceTypesSelect(tempArr);
    }
  }, [allDeviceTypes]);

  const form = useForm<IdeviceInput>({
    initialValues: {
      imei: 0,
      deviceType: NaN,
      status: '',
      purchaseDate: new Date(),
      issuedTo: {id: 0},
      organization: {id: 0},
      ownership: '',
      remarks: ''
    },
  });

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postDevice, {
    onSuccess: () => {
      setAlert(null);
      setDeviceTypeId(0);
      form.reset();
      queryClient.invalidateQueries([types.Device, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IdeviceInput) {
    setAlert(null);
    const newValues = noEmptyString({ ...values });
    if(newValues.issuedTo?.id === 0) newValues.issuedTo = null
    if(newValues.organization?.id === 0) newValues.organization = null
    newMutate(newValues);
  }

  return (
    <>

      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new device:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <NumberInputEditable
              label="imei"
              placeholder="imei"
              {...form.getInputProps('imei')}
              required
              hideControls
            />
            <SelectEditable
              label="Device Type"
              placeholder="Search Here"
              {...form.getInputProps('deviceType')}
              onChange={(v) => {
                form.setFieldValue('deviceType', parseInt(v || '0'));
                setDeviceTypeId(parseInt(v || '0'));
              }}
              searchable
              nothingFound="Not found"
              data={deviceTypesSelect}
              value={`${form.values.deviceType}`}
              required
              mt="md"
            />
            <SelectEditable
              label="status"
              placeholder="Search Here"
              {...form.getInputProps('status')}
              required
              data={enumToSelectData(DeviceStatus)}
              mt="md"
            />
            <SelectEditable
              label="ownership"
              placeholder="Search Here"
              {...form.getInputProps('ownership')}
              required
              data={enumToSelectData(DeviceOwnership)}
              mt="md"
            />
            <DatePickerInputEditable
              placeholder="Search Date"
              label="Purchase Date"
              {...form.getInputProps('purchaseDate')}
              clearable
              mt="md"
            />
            <SelectEditable
                searchable
                label="Issued To"
                placeholder="Search here"
                {...form.getInputProps(`issuedTo.id`)}
                onChange={(v) => {
                  v && form.setFieldValue(`issuedTo.id`, parseInt(v));
                }}
                value={`${form.values.issuedTo?.id}`}
                mt='md'
                data={userSelect}
                />
            <SelectEditable
                searchable
                clearable
                label="Organization"
                placeholder="Search here"
                {...form.getInputProps(`organization.id`)}
                onChange={(v) => {
                  v && form.setFieldValue(`organization.id`, parseInt(v));
                }}
                value={`${form.values.organization?.id}`}
                mt='md'
                data={organizationsSelect}
                />
            <Textarea
              label="Remarks"
              placeholder="Remarks"
              {...form.getInputProps('remarks')}
              mt="md"
            />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {deviceTypeId !== 0 && (
            <DetailsCard
              id={deviceTypeId}
              cardHei={400}
              image={
                allDeviceTypes &&
                allDeviceTypes.data &&
                allDeviceTypes.data.items.filter(
                  (devicetype) => devicetype.id === deviceTypeId,
                )[0].imageRepositoryId
              }
              withImage
            >
              <DeviceTypeCard id={deviceTypeId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
}
