import { Alert, Button, List, LoadingOverlay, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import { SelectEditable } from '../../../components/form';
import { types } from '../../../constants';
import { URLParams, removeNotEdited } from '../../../utils';
import { IaxiosError } from '../../common';
import { useSimCards } from '../../sim-cards';
import { patchRecord, useRecord } from '../api';
import { IrecordUpdate } from '../types/types';

export function UpdateRecordModal({ recordID }: { recordID: number }) {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();

  const [simCardSelect, setSimCardSelect] = useState([{ value: '', label: 'Loading...' }]);

  const { data: recordData } = useRecord(recordID, {
    joins: ['newSimCard', 'oldSimCard'],
    selects: ['id', 'newSimCard.id', 'oldSimCard.id'],
    then: (task) => {
      form.setValues({
        newSimCard: { id: task.data.newSimCard.id || 0 },
        oldSimCard: { id: task.data.oldSimCard.id || 0 },
      });
    },
  });

  const form = useForm<IrecordUpdate>({
    initialValues: {
      newSimCard: { id: recordData?.data.newSimCard.id || 0 },
      oldSimCard: { id: recordData?.data.oldSimCard.id || 0 },
    },
  });

  const presetParams = new URLParams();
  presetParams.select('id', 'number');
  const { data: simCardsData } = useSimCards();
  useEffect(() => {
    if (simCardsData) {
      const tempArr = simCardsData.data.items.map((simCard) => {
        return { value: `${simCard.id}`, label: `${simCard.number}` };
      });

      tempArr.unshift({ value: '0', label: 'No sim card' });
      setSimCardSelect(tempArr);
    }
  }, [simCardsData]);

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchRecord, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.Record, types.ALL]); //, { exact: true } TODO: add this when needed
      queryClient.invalidateQueries([types.Record, { id: recordID }]);
      queryClient.invalidateQueries([types.History, types.Task, { id: recordID }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IrecordUpdate) {
    const newValues = removeNotEdited(values, form);
    if (newValues?.oldSimCard?.id === 0) {
      newValues.oldSimCard = null;
    }
    if (newValues?.newSimCard?.id === 0) {
      newValues.newSimCard = null;
    }
    setAlert(null);
    if (recordID !== 0 && !Number.isNaN(recordID)) {
      patchMutate({ id: recordID, data: newValues });
    } else {
      setAlert(['The id provided is incorrect!']);
    }
  }

  return (
    <>
      <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
        <LoadingOverlay visible={patchLoading} overlayBlur={2} />
        {alert && (
          <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
            {Array.isArray(alert) ? (
              <List>
                {alert.map((errMsg) => (
                  <List.Item>{errMsg}!</List.Item>
                ))}
              </List>
            ) : (
              alert + '!'
            )}
          </Alert>
        )}
        {patchSuccess && (
          <Alert icon={<TbCircleCheck size={16} />} title={'Updated!'} color="green" mb={10}>
            Successfully updated in the database!
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <SelectEditable
            dirty={form.isDirty('newSimCard.id')}
            label="new Sim Card"
            placeholder="Search here"
            {...form.getInputProps('newSimCard.id')}
            onChange={(v) => {
              v && form.setFieldValue('newSimCard.id', parseInt(v));
            }}
            searchable
            nothingFound="Not found"
            data={simCardSelect}
            value={`${form.values.newSimCard?.id}`}
            mt="md"
          />
          <SelectEditable
            dirty={form.isDirty('oldSimCard.id')}
            label="old Sim Card"
            placeholder="Search here"
            {...form.getInputProps('oldSimCard.id')}
            onChange={(v) => {
              v && form.setFieldValue('oldSimCard.id', parseInt(v));
            }}
            searchable
            nothingFound="Not found"
            value={`${form.values.oldSimCard?.id}`}
            data={simCardSelect}
            mt="md"
          />
          <Button fullWidth mt="xl" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </>
  );
}
