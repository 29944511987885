import { Image } from "@mantine/core";
import logo from '../../assets/ats_logo.png';

export function ClientDashboard(){
     
    return(
        <Image
        src={logo}
        height={180}
        fit="contain"
        withPlaceholder
        />
    )
}