import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Idevice } from '../types/types';

interface myResDevices {
  data: {
    items: Idevice[];
    meta: metaData,
  };
}

export const getDevices = (query = ''): Promise<myResDevices> => {
  return api.get(`devices${query}`);
};

export const useDevices = (options?: { params?: string; then?: (x: myResDevices) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Device, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getDevices(options?.params).then((devices) => {
      options && options.then && options.then(devices);
      return devices;
    }), {enabled: options?.enabled ?? true }
  );
  return query;
};

interface myResCountDevices {
  data: Record<string, string | number>[];
}

export const analyticsDevices = (query = ''): Promise<myResCountDevices> => {
  return api.get(`devices/analytics${query}`);
};

export const useAnalyticsDevices = (options?: { params?: string; then?: (x: myResCountDevices) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Device, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(
    cacheKey,
    () =>
      analyticsDevices(options?.params).then((counts) => {
        options && options.then && options.then(counts);
        return counts;
      }),
  );
  return query;
};



interface myResCountDevicesNumber {
  data: number;
}

export const countDevices = (query = ''): Promise<myResCountDevicesNumber> => {
  return api.get(`devices/count${query}`);
};

export const useCountDevices = (options?: { params?: string; then?: (x: myResCountDevicesNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Device, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countDevices(options?.params).then((devices) => {
      options && options.then && options.then(devices);
      return devices;
    }),
  );
  return query;
};