import { Badge, Button, Center, Container, Grid, LoadingOverlay, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from 'react-query';
import { DatePickerInputEditable, SelectEditable } from '../../../../components/form';
import { types } from '../../../../constants';
import { enumToSelectData, selectColumns } from '../../../../utils';
import { IaxiosError } from '../../../common';
import { PlatformColor } from '../../../unit';
import { usePurchasePlan } from '../../api/getPurchasePlan';
import { patchPurchaseOrderPlan } from '../../api/patchPurchasePlan';
import { PlanColumnsMode, PurchaseOrderInterval, PurchaseOrderStatus, PurchaseOrderType } from '../../constants/constants';
import { IPurchasePlanInput } from '../../types/type';

type updateplan = Omit<IPurchasePlanInput, 'platform' | 'secondaryPlatform'  | 'units' | 'quantity'>;
export function UpdatePlanForm(
    { setOpenEdited, planId }
        :
        {
            planId: number
            setOpenEdited: React.Dispatch<React.SetStateAction<number>>, 

        }
        ) {
    const queryClient = useQueryClient();
    const { data: purchasePlanData } = usePurchasePlan(planId, {
           selects: [...selectColumns(PlanColumnsMode)],
           then: (purchasePlan) => {
              form.setValues({
                type: purchasePlan.data.type,
                interval: purchasePlan.data.interval,
                status: purchasePlan.data.status,
                startDate: purchasePlan.data.startDate ? new Date(purchasePlan.data.startDate) : null,
                endDate: purchasePlan.data.endDate ? new Date(purchasePlan.data.endDate) : null,
                remarks: purchasePlan.data.remarks? purchasePlan.data.remarks : '',
              });
              form.resetDirty({
                  type: purchasePlan.data.type,
                  interval: purchasePlan.data.interval,
                  status: purchasePlan.data.status,
                  startDate: purchasePlan.data.startDate ? new Date(purchasePlan.data.startDate) : null,
                  endDate: purchasePlan.data.endDate ? new Date(purchasePlan.data.endDate) : null,
                  remarks: purchasePlan.data.remarks? purchasePlan.data.remarks : '',
               });
              
           },
           
        });
    const form = useForm<updateplan>({
           initialValues: {
               type: purchasePlanData?.data.type || '',
               interval: purchasePlanData?.data.interval || '',
               status: purchasePlanData?.data.status || '',
               startDate: purchasePlanData?.data.startDate ? new Date(purchasePlanData?.data.startDate) : null,
               endDate: purchasePlanData?.data.endDate ? new Date(purchasePlanData?.data.endDate) : null,
               remarks: purchasePlanData?.data.remarks || '',
           },
        });

    const {
    mutate: patchMutate,
    isLoading: patchLoading,
    } = useMutation(patchPurchaseOrderPlan, {
    onSuccess: () => {
        notifications.show({
            title: 'Success',
            message: `Plan Updated Sucessfully`,
            color: 'green',
            autoClose: 10000,
      
          })
        setOpenEdited(-1)
        queryClient.invalidateQueries([types.PurchasePlan, types.ALL]);
    },
    onError: (data: IaxiosError) => {
        notifications.show({
            title: 'Error',
            message: data.response.data.message,
            color: 'red',
            autoClose: 10000,
      
          })
    },
    });

    function handleSubmit(values: updateplan) {
        patchMutate({ id: planId, data: values });
    }

    

    return (
        <Container mt="md">
            <LoadingOverlay visible={patchLoading} overlayBlur={2} />
            <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
                <Grid>
                    <Grid.Col span={4}>
                        {purchasePlanData?.data.platform && <Badge 
                                variant="light" 
                                c={PlatformColor[purchasePlanData?.data.platform as keyof typeof PlatformColor]} 
                                styles={{root:{backgroundColor: `${PlatformColor[purchasePlanData?.data.platform as keyof typeof PlatformColor]}40`,}}}
                                >{purchasePlanData?.data.platform}</Badge> 
                        }
                    </Grid.Col>
                    <Grid.Col span={4}>
                        {purchasePlanData?.data.secondaryPlatform && <Badge 
                                variant="light" 
                                c={PlatformColor[purchasePlanData?.data.secondaryPlatform as keyof typeof PlatformColor]} 
                                styles={{root:{backgroundColor: `${PlatformColor[purchasePlanData?.data.secondaryPlatform as keyof typeof PlatformColor]}40`,}}}
                                >{purchasePlanData?.data.secondaryPlatform}</Badge> 
                        }
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text >Quantity: {purchasePlanData?.data.quantity}</Text>
                    </Grid.Col>
                </Grid>
                <SelectEditable
                    dirty={form.isDirty(`type`)}
                    required
                    label="type"
                    placeholder="Search Here"
                    {...form.getInputProps(`type`)}
                    data={enumToSelectData(PurchaseOrderType)}
                    mt="md"
                />
                <SelectEditable
                    label="interval"
                    dirty={form.isDirty(`interval`)}
                    required
                    placeholder="Search Here"
                    {...form.getInputProps(`interval`)}
                    data={enumToSelectData(PurchaseOrderInterval)}
                    mt="md"
                />
                <SelectEditable
                    label="status"
                    dirty={form.isDirty(`status`)}
                    required
                    placeholder="Search Here"
                    {...form.getInputProps(`status`)}
                    data={enumToSelectData(PurchaseOrderStatus)}
                    mt="md"
                />

                <DatePickerInputEditable
                    placeholder="Start Date"
                    label="Start Date"
                    required
                    dirty={form.isDirty(`startDate`)}
                    {...form.getInputProps(`startDate`)}
                    mt="md"
                />

                <DatePickerInputEditable
                    placeholder="End Date"
                    label="End Date"
                    required
                    dirty={form.isDirty(`endDate`)}
                    {...form.getInputProps(`endDate`)}
                    mt="md"
                />

                <Textarea
                    label="remarks"
                    mt="md"
                    {...form.getInputProps(`remarks`)}
                />
                <Center>
                    <Button mt='md' type='submit'>Save</Button>
                </Center>
            </form>
        </Container>
    );
}
