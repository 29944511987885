import api from "../../common/api/api";
import { IdeviceType, IdeviceTypeInput } from "../types/types";

interface myResDeviceType {
	data: IdeviceType;
}

export const postDeviceType = (data: IdeviceTypeInput): Promise<myResDeviceType> => {
	return api.post(`device-types`, data);
};
