import { Alert, Container, LoadingOverlay, Paper, Title } from "@mantine/core";
import { TbAlertOctagon } from "react-icons/tb";
import { useVehicle } from "../api";
import { VehicleTaskTable } from "./VehicleTaskTable";

export function VehicleTaskCard({id, cardHei}:{id:number; cardHei: number;}){

    const { isLoading, isError, data, refetch } = useVehicle(id, {
        joins: ['unit', 'tasks', 'tasks.issuedBy', 'tasks.performedBy']
      });


      return (
        <Paper pos="relative" h={cardHei} withBorder>
          <Container  m={0} >
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            {isError && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
              >
                Error Loading data from the server!!
              </Alert>
            )}
            <Title
                mt='sm'
                order={2}
                pl={5}
                align="left"
                sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 800,
                })}
            >
          Task History:
        </Title>
            {data && data.data &&
            <VehicleTaskTable tasks={data.data.tasks?data.data.tasks:[]} cardHei={cardHei} />
            }
          </Container>
        </Paper>
        )
}