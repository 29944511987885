import { Button, Group, Paper, ScrollArea, SegmentedControl, SimpleGrid, Table, Tabs, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { CardsFilter, useDeepFilter } from '../../../components/card';
import Layout from '../../../components/layout/Layout';
import BaseTable from '../../../components/table/BaseTable';
import { URLParams, setTableParams } from '../../../utils';
import { Irfid } from '../../rfids';
import { useDetailedRfidList, useRfidLists } from '../api';
import { IrfidList } from '../types/types';

export function RfidListCards() {
  const [page, setPage] = useState(1);
  const [id, setId] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [oldId, setOldId] = useState<number | null>(null);
  const [value, setValue] = useState('Printed');
  const { isSuccess, data: allRfidLists } = useRfidLists();
  const navigate = useNavigate();
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes('rfids', 'rfidLists');
    param.page(10, page - 1);
    return param;
  });
  const {
    isSuccess: isDetailedRfidListSuccess,
    data: detailedRfidListData,
    refetch,
    isLoading,
  } = useDetailedRfidList(id, { params: params.toString() });

  useEffect(() => {
    if (detailedRfidListData && id !== oldId && id !== 0) {
      setOldId(id);
      refetch();
    }
  }, [refetch, detailedRfidListData, id, oldId]);

  let data2: IrfidList[] = useMemo(() => [], []);
  if (isSuccess && allRfidLists) {
    data2 = allRfidLists.data.items;
  }
  const { filter, setFilter, formattedData } = useDeepFilter(detailedRfidListData ? detailedRfidListData.data.rfids : []);

  const data: IrfidList[] = useMemo(() => data2, [data2]);
  const columnHelper = useMemo(() => createColumnHelper<IrfidList>(), []);
  const columns: ColumnDef<IrfidList, any>[] = useMemo(
    () => [
      // columnHelper.display({
      //    id: "select",
      //    header: () => (
      //       ''
      //    ),
      //    cell: ({ row, table }) => (
      //       <div className='px-1'>
      //          <Checkbox
      //             {...{
      //                checked: row.getIsSelected(),
      //                indeterminate: row.getIsSomeSelected(),
      //                onChange: (event) => {
      //                   setId(event.target.checked ? row.original.id : 0)
      //                   setFilter('')
      //                   if (event.target.checked) {
      //                      table.toggleAllRowsSelected(false)
      //                      row.toggleSelected()
      //                   }
      //                   row.getToggleSelectedHandler()(event)
      //                },
      //             }}
      //          />
      //       </div>
      //    ),
      // }),
      columnHelper.accessor((row) => `${row.id}`, { header: 'ID' }),
      columnHelper.accessor('name', { header: () => 'name' }),
    ],
    [columnHelper, setFilter],
  );

  return (
    <Layout>
      <Group px={10} mt={10} mb={20} position="apart">
        <Title order={2} align="left" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
          Rfid Lists:
        </Title>
        <Button onClick={() => navigate('/new/rfid-list')}>Add new</Button>
      </Group>
      <SimpleGrid cols={2}>
        <BaseTable<IrfidList>
          data={data}
          loading={isLoading}
          page={page}
          setPage={(value) => {
            setParams((param) => {
              param.filterRemovePages();
              param.page(10, value - 1);
              return param;
            });
            setPage(value);
          }}
          pages={detailedRfidListData?.meta.ids.length ? Math.ceil(detailedRfidListData?.meta.ids.length / 10) : 1}
          filterData={(column, value, type) => {
            setTableParams(column, value, type, setParams);
            refetch();
            setPage(1);
          }}
          columns={columns}
          setRowSelection={setRowSelection}
          rowSelection={rowSelection}
        />
        {id !== 0 && isDetailedRfidListSuccess && formattedData && (
          <Paper withBorder>
            <Tabs defaultValue="rfids" mt="md">
              <Tabs.List>
                <Tabs.Tab value="rfids">Rfids</Tabs.Tab>
                <Tabs.Tab value="rfidLists">Rfid Lists</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="rfids" pt="xs">
                <Group position="apart" px={10}>
                  <SegmentedControl value={value} onChange={(v) => setValue(v)} data={['Printed', 'Bce', 'Teltonika']} />
                  <Button onClick={() => navigate(`/edit/rfid-list/${id}`)}>Edit</Button>
                </Group>
                <CardsFilter setFilter={setFilter} filter={filter} />
                <ScrollArea style={{ height: 400 }}>
                  <Table striped highlightOnHover mt="md" p="md">
                    <tbody>
                      {formattedData.map((rfid) => (
                        <tr key={rfid.id}>
                          <td>
                            {value === 'Printed'
                              ? rfid.printedValue
                              : value === 'Bce'
                              ? rfid.bceValue
                              : value === 'Teltonika'
                              ? rfid.teltonikaValue
                              : rfid.printedValue}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </ScrollArea>
              </Tabs.Panel>

              <Tabs.Panel value="rfidLists" pt="xs">
                <Group position="right" px={10}>
                  <Button mt={2} onClick={() => navigate(`/edit/rfid-list/${id}`)}>
                    Edit
                  </Button>
                </Group>
                <ScrollArea style={{ height: 400 }}>
                  <Table striped highlightOnHover mt="md" p="md">
                    <tbody>
                      {detailedRfidListData.data.rfidLists.map((rfidList) => (
                        <tr key={'L' + rfidList.id}>
                          <td>{rfidList.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </ScrollArea>
              </Tabs.Panel>
            </Tabs>
          </Paper>
        )}
      </SimpleGrid>
    </Layout>
  );
}
