import { Alert, Button, LoadingOverlay } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { TbAlertOctagon } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { types } from "../../../constants";
import { deleteTask } from "../api";

export function DeleteModal({id, close, setIds, setRowSelection}: {id: number, close: () => void, setIds: React.Dispatch<React.SetStateAction<number[]>>, setRowSelection: React.Dispatch<React.SetStateAction<{}>>}){

    const queryClient = useQueryClient();
    const {
        mutate: deleteMutate,
        isLoading: deleteLoading,
    } = useMutation(deleteTask, {
        onSuccess: () => {
        notifications.show({
          title: 'Success',
          message: `task with Id ${id} correctly deleted`,
          color: 'green',
          autoClose: 10000,
        })
        setRowSelection({})
        setIds([])
        close
        queryClient.invalidateQueries([types.Task, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
        });
        },
        onError: () => {
        },
    });

    function handleSubmit(){
        deleteMutate({id: id})
    }
    return(
        <>
            <LoadingOverlay visible={deleteLoading} overlayBlur={2} />
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Warning!"
              color="red"
              mb={10}
            >Are you sure you want to delete this task!!!
            </Alert>
          <Button onClick={handleSubmit} fullWidth>Delete</Button>
        </>
    )
}