import { ActionIcon, Center, Group, Input, Table, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbReload } from 'react-icons/tb';
import { SelectEditable } from '../../../../../components/form';
import { URLParams, enumToSelectData } from '../../../../../utils';
import { DeviceStatus } from '../../../../devices';
import { useUnits } from '../../../../unit';
import { Task, technicianTask } from '../../../types/types';

export function DeviceChangeStepForm({
  form,
  task,
}: {
  form: UseFormReturnType<technicianTask, (values: technicianTask) => technicianTask>;
  task: Task | undefined;
}) {
  const [units, setUnits] = useState([{ value: '', label: '' }]);

  const unitParams = new URLParams();
  unitParams.includes('device', 'simCard', 'vehicle', 'deviceConfig');
  unitParams.filterSetField('device.id', 'null');
  unitParams.filterSetField('simCard.id', 'null');
  unitParams.filterSetField('=vehicle.id', 'null');
  unitParams.select(
    'id',
    'device.id',
    'device.imei',
    'simCard.id',
    'simCard.number',
    'deviceConfig.id',
    'deviceConfig.platform',
    'deviceConfig.secondaryPlatform',
  );
  const { data: unitsData, refetch } = useUnits({ params: unitParams.toString() });
  useEffect(() => {
    if (unitsData) {
      const tempArr = unitsData.data.items
        .filter((unit) => !unit.vehicle)
        .map((unit) => {
          return {
            value: `${unit.id}`,
            label: `${unit.device?.imei}, ${unit.simCard?.number}`,
          };
        });
      setUnits(tempArr);
    }
  }, [unitsData]);

  return (
    <>
      {task && task.unit && (
        <>
          <Table>
            <tbody>
              <tr>
                <td>Plate No.:</td>
                <td>{task.unit.vehicle?.plateNumber}</td>
              </tr>
              <tr>
                <td>Vin:</td>
                <td>{task.unit.vehicle?.vin}</td>
              </tr>
              <tr>
                <td>Imei:</td>
                <td>{task.unit.device?.imei}</td>
              </tr>
              <tr>
                <td>Sim Number:</td>
                <td>{task.unit.simCard?.number}</td>
              </tr>
            </tbody>
          </Table>
          <SelectEditable
            {...form.getInputProps('newUnit')}
            required
            withAsterisk={false}
            label={
              <Group>
                <Input.Label required>Device</Input.Label>
                <ActionIcon onClick={() => refetch()}>
                  <TbReload />
                </ActionIcon>
              </Group>
            }
            placeholder="Search here"
            onChange={(v) => {
              if (v) form.setFieldValue('newUnit', parseInt(v));

              const found = unitsData?.data.items.find((unit) => v && unit.id === parseInt(v));
              if (found) {
                form.setFieldValue('platform', found.deviceConfig.platform);
                form.setFieldValue('secondaryPlatform', found.deviceConfig.secondaryPlatform || '');
              }
            }}
            value={`${form.values.newUnit}`}
            searchable
            limit={20}
            nothingFound="Not found"
            data={units}
            clearable
            mt="md"
          />
           <SelectEditable
                dirty={form.isDirty('deviceStatus')}
                label="removed Device Status"
                placeholder="Search Here"
                {...form.getInputProps('deviceStatus')}
                required
                data={enumToSelectData(DeviceStatus)}
                mt="md"
              />
        </>
      )}
      {task && !task.unit && (
        <Center>
          <Text> Please Contact Techinical Support To Add Unit For Task</Text>
        </Center>
      )}
    </>
  );
}
