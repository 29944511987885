
export enum DeviceStatus {
	Available = "available",
	Ready = "ready",
	InUse = "in use",
	Broken = "broken",
	Lost = "lost",
	Not_Active = 'not active',
}

export enum DeviceColumnsMode {
    ID = "id",
    Imei = "imei",
    Status = "status",
    PurchaseDate = "purchaseDate",
    Ownership = "ownership",
    Remarks = "remarks",
}


export enum DeviceOwnership {
	Sold = 'sold',
	Leased = 'leased',
	ClientDevice = 'client Device',
  }
  