import { Alert, Button, Container, List, LoadingOverlay, Paper, TextInput, Title, TransferList, TransferListData } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';

import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { URLParams } from '../../../utils';
import { IaxiosError, SelectData } from '../../common';
import { useTasks } from '../../tasks';
import { postTaskGroup } from '../api';
import { TaskGroupInput } from '../types/types';

export function NewTaskGroupForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [data, setData] = useState<TransferListData>([[], []]);

  const queryClient = useQueryClient();

  const form = useForm<TaskGroupInput>({
    initialValues: {
      name: '',
      tasks: [],
    },
  });

  const tasksParams = new URLParams();
  tasksParams.includes('taskGroup');
  tasksParams.filterSetField('=taskGroup', 'null');
  tasksParams.select('id', 'title', 'taskGroup.id');

  const { data: tasksData, isLoading } = useTasks({ params: tasksParams.toString() });
  useEffect(() => {
    if (tasksData) {
      const tempArr: SelectData = tasksData.data.items.map((task) => {
        return { value: `${task.id}`, label: `${task.title}` };
      });
      setData([[], tempArr]);
    }
  }, [tasksData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postTaskGroup, {
    onSuccess: () => {
      setAlert(null);
      // form.reset();
      queryClient.invalidateQueries([types.TaskGroup, types.ALL]);
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: TaskGroupInput) {
    // const newValues = noEmptyString({ ...values });
    // console.log(values);
    // console.log(newValues);
    // newMutate(newValues);
    newMutate(values);
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          New task group:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput label="Name" placeholder="Name" {...form.getInputProps('name')} required />
            <LoadingOverlay visible={isLoading} />
            <TransferList
              searchPlaceholder="Search..."
              nothingFound="Nothing here"
              titles={['Tasks in this group', 'Available tasks']}
              mt="md"
              {...form.getInputProps('tasks')}
              value={data}
              onChange={(value) => {
                form.setFieldValue(
                  'tasks',
                  value[0].map((item) => ({ id: +item.value })),
                );
                setData(value);
              }}
            />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </Layout>
  );
}
