import { Checkbox, Group, Text, TransferList, TransferListData, TransferListItemComponent, TransferListItemComponentProps } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RFID_LIST_SINGLE_ALL_RFIDLISTS, getRfidListWithAllLists } from '../../modules/rfid-lists';
import { useFormContext } from './formContext';

type selectType = { value: string; label: string }[];
type myTrListData = { value: string; label: string }[][];

const ItemComponent: TransferListItemComponent = memo(({ data, selected }: TransferListItemComponentProps) => {
  return (
    <Group noWrap>
      <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: 'none' }} />
      <div style={{ flex: 1 }}>
        <Text size="sm" weight={400}>
          {data.label}
        </Text>
      </div>
    </Group>
  );
});

export function TransferListRfidList({
  id,
  reset,
  setReset,
}: {
  id: number;
  reset: boolean;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const form = useFormContext();
  const [data, setData] = useState<TransferListData>([[{ value: '0', label: 'loading...' }], [{ value: '0', label: 'loading...' }]]);

  useEffect(() => {
    if (reset) setData([[], [...data[0], ...data[1]]]);
    setReset(false);
  });

  const { data: customData } = useQuery(RFID_LIST_SINGLE_ALL_RFIDLISTS, () =>
    getRfidListWithAllLists(id).then(({ singleList, allLists }) => {
      const rfidIds: number[] = (singleList.rfidLists || []).map((rfid) => {
        return rfid.id;
      });

      const { selected, notSelected } = allLists.reduce<{
        selected: selectType;
        notSelected: selectType;
      }>(
        (acc, rfidList) => {
          const isSelected = rfidIds.includes(rfidList.id);
          acc[isSelected ? 'selected' : 'notSelected'].push({ value: `${rfidList.id}`, label: rfidList.name });
          return acc;
        },
        {
          selected: [],
          notSelected: [],
        },
      );
      setData([selected, notSelected]);

      form.setFieldValue('rfidLists', [selected, notSelected]);
      return { singleList, allLists };
    }),
  );

  return (
    <TransferList
      {...form.getInputProps('rfidLists')}
      value={data}
      onChange={(v) => {
        form.setFieldValue('rfidLists', v);
        setData(v);
      }}
      searchPlaceholder="Search..."
      nothingFound="Nothing here"
      titles={['Include these lists', 'All lists']}
      breakpoint="sm"
      mt="md"
      listHeight={300}
      itemComponent={ItemComponent}
    />
  );
}
