export enum UserStatus {
	Pending = "pending",
	Active = "active",
	Disabled = "disabled",
	Removed = "removed",
}

export enum UserRole {
	Master = 'master',
	Admin = "admin",
	Technician = "technician",
	Support = "technical support",
	Finance = "finance",
	Client = "client",
}

export enum UserRoleColor {
	technician= '#5c8f43',
	master= '#bf5858',
	admin= '#be58bf',
	technical_support= '#bf9658',
	finance= '#6a71bd'
}

export enum UserDepartment {
	Master = 'master',
	Admin = "admin",
	Technician = "technician",
	Operations = "operations",
	Support = "support",
	Finance = "finance",
	Client = "client",
}

export enum UserColumnsMode {
	ID = "id",
	Username = "username",
	Email = "email",
	FirstName = "firstName",
	LastName = "lastName",
	Password = "password",
	Status = "status",
	Remarks = "remarks",
	Date = "date",
	Profiles = "profiles",
}

export enum ProfileColumnsMode {
	ID = "id",
	Role = "role",
	App = "app",
	Department = "department",
	ImageRepositoryId = "imageRepositoryId",
	Organization = "organization",
	Phone = "phone",
	EmployeeId = "employeeId",
	Status = "status",
}