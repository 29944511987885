import { Table, createStyles } from '@mantine/core';

import { URLParams, selectColumns } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { useInvoices } from '../api';
import { InvoiceActions } from '../components/InvoiceActions';
import { InvoiceColumnsMode } from '../constants';

const useStyles = createStyles((theme) => {
    const colors = theme.fn.variant({ variant: 'light', color: 'red' })
    return {
    row: {
        backgroundColor: colors.background,
        color: colors.color,
    }
}})

export function InvoicesMiniTable({orderID}:{orderID:number}) {
    const {classes} = useStyles()
    const params = new URLParams();
    params.includes('payments', 'units')
    params.filterSetField('purchaseOrderId', orderID)
    params.sort(('-invoice.id'))
    params.select(...selectColumns(InvoiceColumnsMode), 'units.id', 'payments.id', 'payments.amount');
    const { data: invoices } = useInvoices({ params: params.toString() });
    const rows = invoices?.data.items.map(item => {
        const totalPaid = item.payments.reduce((acc, item) => {
            return acc + item.amount
        }, 0)
        return(
            <tr key={item.id} className={totalPaid < item.amount ? classes.row : undefined} >
                <td>{item.id}</td>
                <td>{item.invoiceNo}</td>
                <td>{item.type}</td>
                <td>{item.amount}</td>
                <td>{!item.vat ? item.amount * 0.05 : ''}</td>
                <td>{!item.vat ? item.amount * 1.05 : ''}</td>
                <td>{item.units.length}</td>
                <td>{simpleDateFormat(item.date)}</td>
                <td>{totalPaid}</td>
                <td>{item.remarks ? item.remarks : ''}</td>
                <td><InvoiceActions id={item.id}/></td>
                
            </tr>
        )
    })
          
    return (
        <Table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Invoice</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>VAT</th>
                    <th>Total</th>
                    <th>Qauntity</th>
                    <th>Date</th>
                    <th>Amount Recieved</th>
                    <th>Remarks</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody >
                {rows}
            </tbody>
        </Table>
        );
}
