import { Button, Checkbox, Group, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, setTableParams } from '../../../utils';
import { useQuotations } from '../api/getQuotations';
import { Iquotation } from '../types/types';

export function TempleteTable() {
  const [page, setPage] = useState(1);
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState<Iquotation[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const navigate = useNavigate();

  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.sort('-quotation.id');
    param.filterSetField('templete', 1);
    param.page(10, page - 1);
    param.select('id', 'name', 'countryOfOrigin', 'validity', 'discount');
    return param;
  });
  const { data: allQuotations, refetch, isLoading } = useQuotations({ params: params.toString() });
  useEffect(() => {
    if (allQuotations) {
      setData(allQuotations.data.items);
      const selectedRows = allQuotations.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allQuotations]);

  const columnHelper = useMemo(() => createColumnHelper<Iquotation>(), []);
  const columns: ColumnDef<Iquotation, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allQuotations?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allQuotations?.data.meta.ids ? allQuotations?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),

      columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => row.name, { header: 'File', id: 'name' }),
      columnHelper.accessor('countryOfOrigin', { header: () => 'Country Of Origin', id: 'countryOfOrigin' }),
      columnHelper.accessor((row) => `${row.validity} Days`, { header: 'Validity', id: 'validity' }),
      columnHelper.accessor((row) => row.discount ?? '', { header: 'discount', id: 'discount' }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    'Quotation Date': 'date',
  };

  return (
    <Layout>
      <Group px={13} mt={10} position="apart">
        <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
          Templetes:
        </Title>
        <Button onClick={() => navigate('/new/quotationtemplete')}>Add new</Button>
      </Group>
      <BaseTable<Iquotation>
        data={data}
        columns={columns}
        setIds={setIds}
        loading={isLoading}
        page={page}
        ids={ids}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'expander' && header !== 'Quotation')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        pages={allQuotations?.data.meta.ids.length ? Math.ceil(allQuotations?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        columnTypes={columnTypes}
      />
      <SideBar id={ids} type={types.Templete} withCard={false}>
        <></>
      </SideBar>
    </Layout>
  );
}
