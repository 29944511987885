import { Alert, Button, Checkbox, Container, List, LoadingOverlay, Paper, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { NumberInputEditable, TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { noEmptyString } from '../../../utils';
import { IaxiosError } from '../../common';
import { postAccessoryType } from '../api';
import { IAccessoriesTypeInput } from '../types/types';

export function NewAccessoryTypeForm({ idM }: { idM: number }) {
  const { id } = useParams();
  const [idN] = useState(+(id || idM || 0));
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();

  const form = useForm<IAccessoriesTypeInput>({
    initialValues: {
      name: '',
      brand: '',
      model: '',
      totalQuantity: 0,
      usedQuantity: 0,
      hasAccessory: true,
    },
  });

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postAccessoryType, {
    onSuccess: () => {
      setAlert(null);
      queryClient.invalidateQueries([types.AccessoriesType, types.ALL]);
      queryClient.invalidateQueries([types.AccessoriesType, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.AccessoriesType, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IAccessoriesTypeInput) {
    setAlert(null);
    const newValue = noEmptyString(values);
    if (values.hasAccessory) {
      newValue.totalQuantity = 0;
      newValue.usedQuantity = 0;
    }
    newMutate(newValue);
  }

  return (
    <Container pt={10}>
      <Title
        order={2}
        pl={5}
        align="left"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 800,
        })}
      >
        New Accessory Type:
      </Title>
      <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
        <LoadingOverlay visible={newLoading} overlayBlur={2} />
        {alert && (
          <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
            {Array.isArray(alert) ? (
              <List>
                {alert.map((errMsg) => (
                  <List.Item>{errMsg}!</List.Item>
                ))}
              </List>
            ) : (
              alert + '!'
            )}
          </Alert>
        )}
        {newSuccess && (
          <Alert icon={<TbCircleCheck size={16} />} title={'Created!'} color="green" mb={10}>
            Successfully Created in the database!
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInputEditable label="Name of Accessory" placeholder="name" {...form.getInputProps('name')} required />

          <TextInputEditable label="Brand" placeholder="brand" {...form.getInputProps('brand')} required mt="sm" />

          <TextInputEditable label="Model" placeholder="model" {...form.getInputProps('model')} required mt="sm" />

          <NumberInputEditable
            label="Total Quantity"
            placeholder="total quantity"
            {...form.getInputProps(`totalQuantity`)}
            required
            mt="md"
          />

          <NumberInputEditable label="Used Quantity" placeholder="used quantity" {...form.getInputProps(`usedQuantity`)} required mt="md" />

          <Checkbox label="has serial Number?" checked={form.values.hasAccessory} {...form.getInputProps('hasAccessory')} />

          <Button fullWidth mt="xl" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
