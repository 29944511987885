import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Iorganization, IorganizationNames } from '../types/types';

interface myResOrganizations {
  data: {
    items: Iorganization[];
    meta: metaData,
  };
}

export const getOrganizations = (query = ''): Promise<myResOrganizations> => {
  return api.get(`organizations${query}`);
};

export const useOrganizations = (options?: { params?: string; then?: (x: myResOrganizations) => any, enabled?: boolean }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Organization, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getOrganizations(options?.params).then((organizations) => {
      options && options.then && options.then(organizations);
      return organizations;
    }),{enabled: options?.enabled ?? true },
  );
  return query;
};
interface myResOrganizationNames {
  data: IorganizationNames[];
}

export const getOrganizationsNames = (): Promise<myResOrganizationNames> => {
  return api.get(`organizations/names`);
};

export const useOrganizationsNames = (options?: { params?: string; then?: (x: myResOrganizationNames) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Organization, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
  getOrganizationsNames().then((organizations) => {
      options && options.then && options.then(organizations);
      return organizations;
    }),
  );
  return query;
};

interface myResCountOrganizationsNumber {
  data: number;
}

export const countOrganizations = (query = ''): Promise<myResCountOrganizationsNumber> => {
  return api.get(`organizations/count${query}`);
};

export const useCountOrganizations = (options?: { params?: string; then?: (x: myResCountOrganizationsNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Organization, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countOrganizations(options?.params).then((organizations) => {
      options && options.then && options.then(organizations);
      return organizations;
    }),
  );
  return query;
};