import { useInterval } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';

export function ago(time: number) {
  if (time === 0) {
    return 'Loading...';
  }
  const now = Math.floor(Date.now() / 1000);
  const diff = now - time;
  if (diff > 0) {
    const days = Math.floor(diff / (60 * 60 * 24));
    const hours = Math.floor((diff - days * 24 * 60 * 60) / (60 * 60));
    const minutes = Math.floor((diff - days * 24 * 60 * 60 - hours * 60 * 60) / 60);
    const seconds = diff - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    let ago = 'ago';
    if (days > 0) {
      ago = `${days} days ${hours}h ${ago}`;
    } else {
      if (hours > 0) {
        ago = `${hours}h ${minutes}m ${seconds}s ${ago}`;
      } else if (minutes > 0) {
        ago = `${minutes}m ${seconds}s ${ago}`;
      } else {
        ago = `${seconds}s ${ago}`;
      }
    }
    return ago;
  } else return 'now';
}

export function useTimeAgo(initUnixTime: number) {
  const unixTime = useRef(initUnixTime);
  const [time, setTime] = useState('');
  const interval = useInterval(() => setTime(ago(unixTime.current)), 1000);

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  return { time, unixTime };
}
