import { simpleDateFormat } from "./simpleDateFormat"

export function dateToNewDate(data: Record<string, any>[], datesKeys: string[]){
    return data.map(item => {
        item = getAllKeys(item, datesKeys)
        return item
    })
}
  
  function getAllKeys(newItem: Record<string, any>, datesKeys: string[]) {
    return  Object.keys(newItem).reduce((acc, key) => {
        
        if(datesKeys.includes(key)) acc[key] = simpleDateFormat(newItem[key]) 
        else if(typeof newItem[key] !== 'object' || newItem[key] === null) acc[key] = newItem[key] 
        else if(typeof newItem[key] === 'object' ) acc[key] = getAllKeys(newItem[key], datesKeys)
        return acc
    }, newItem as Record<string, any>)
  }
