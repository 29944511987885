import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Iinvoice } from '../types/types';

interface myResInvoices {
  data: {
    items: Iinvoice[];
    meta: metaData,
  };
}

export const getInvoices = (query = ''): Promise<myResInvoices> => {
  return api.get(`invoices${query}`);
};

export const useInvoices = (options?: { params?: string; then?: (x: myResInvoices) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Invoice, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getInvoices(options?.params).then((invoices) => {
      options && options.then && options.then(invoices);
      return invoices;
    }),
  );
  return query;
};

