import { Button, Checkbox, Group, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, selectColumns, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { AccessoryTypeColumnsMode } from '../../accessoriesType';
import { useAccessories } from '../api';
import { AccessoryColumnsMode } from '../constants';
import { IAccessories } from '../types/types';

export function AccessoryTable() {
  const [data, setData] = useState<IAccessories[]>([]);
  const [ids, setIds] = useState<number[]>([])
  const [page, setPage] = useState(1)
  const [rowSelection, setRowSelection] = useState({})
  const navigate = useNavigate()
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams()
    param.includes(
        'accessoriesType'
      );
    param.sort(('-accessory.id'))
    param.select(...selectColumns(AccessoryColumnsMode), ...selectColumns(AccessoryTypeColumnsMode, {exclude: [AccessoryTypeColumnsMode.HasAccessory, AccessoryTypeColumnsMode.TotalQuantity, AccessoryTypeColumnsMode.UsedQuantity], join: 'accessoriesType'}))
    param.page(10, page-1)
    return param
  })
  const { data: allAccessories, refetch, isLoading } = useAccessories({ params: params.toString() });
  useEffect(() => {
    if (allAccessories) {
      setData(allAccessories.data.items);
      const selectedRows = allAccessories.data.items.map((item) => {
        if(ids.includes(item.id)) return {index: true}
      })
      setRowSelection(selectedRows)
    }
  }, [allAccessories]);
//   const customActions = [
//     { multi: true, title: 'Edit Multiple', icon: FaEdit, color: 'orange', handler: open, noDisplay: ['record'] },
//  ];

  const columnHelper = useMemo(() => createColumnHelper<IAccessories>(), []);
  const columns: ColumnDef<IAccessories, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        
        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allAccessories?.data.meta.ids.length
                if(allSelected){
                  setIds([])
                }else setIds(allAccessories?.data.meta.ids ? allAccessories?.data.meta.ids : ids)
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex(id => id === row.original.id)
                  if(rowId === -1){
                    setIds([...ids, row.original.id])
                  }else {
                    setIds(ids => {
                      ids.splice(rowId,1)
                      return ids
                    })
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => row.serialNo, { header: 'Serial Number', id: 'serialNo' }),
      columnHelper.accessor((row) => simpleDateFormat(row.purchaseDate), { header: 'Purchase Date', id: 'purchaseDate' }),
      columnHelper.accessor((row) => row.accessoriesType.name, { header: 'Accessory Type', id:'accessoriesType.name' }),
      columnHelper.accessor((row) => row.accessoriesType.brand, { header: 'Accessory Brand', id:'accessoriesType.brand' }),
      columnHelper.accessor((row) => row.accessoriesType.model, { header: 'Accessory Model', id:'accessoriesType.model' }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    'Purchase Date': "date",
  }

  return (
    <Layout>
      <Group px={10} mt={10} mb={20} position="apart">
          <Title
              order={2}
              align="left"
              sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}
          >
              Accessories:
          </Title>
          <Button onClick={() => navigate('/new/accessories')} >Add new</Button>
        </Group>
      <BaseTable<IAccessories> data={data} columns={columns} setIds={setIds} setRowSelection={setRowSelection} rowSelection={rowSelection}
      ids={ids}
      loading={isLoading}
      columnTypes={columnTypes}
      
      page={page}
      setPage={(value) => {
        setParams((param) => {
          param.filterRemovePages()
          param.page(10, value - 1)
          return param
        })
        setPage(value)
      }}
      globalFilterData={(data, headers) => {
        const newHeaders = headers.filter(header => header && header !== 'select' && header !== 'actions').join('|')
        setTableParams(newHeaders, data, '', setParams)
        refetch()
        setPage(1)
      }}
      pages={allAccessories?.data.meta.ids.length ? Math.ceil(allAccessories?.data.meta.ids.length/10): 1}
      filterData={(column, value, type)=> {
        setTableParams(column, value, type, setParams)
        refetch()
        setPage(1)
      }}
      />
      <SideBar id={ids} type={types.Accessories} withCard={false}>
        <></>
      </SideBar>
    </Layout>
  );
}
