import { Checkbox, Grid, Group, List, Paper, RingProgress, Text, Title, UnstyledButton } from '@mantine/core';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, groupBy, setTableParams } from '../../../utils';
import { TaskStatusColor } from '../../tasks';
import { useTaskGroups } from '../api';
import { TaskGroupCard } from '../components';
import { TaskGroup } from '../types/types';

export function TaskGroupTable() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<TaskGroup[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [ids, setIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes('tasks');
    param.sort('-id');
    param.page(10, page - 1);
    return param;
  });

  const renderSubComponent = ({ row }: { row: Row<TaskGroup> }) => {
    return (
      <Paper withBorder p="sm">
        <Grid>
          {Object.entries(groupBy(row.original.tasks, (i) => i.status)).map(([status, tasks]) => (
            <Grid.Col span={4}>
              <Paper withBorder p="sm">
                <Title order={6} mb="xs">
                  {status[0].toUpperCase() + status.slice(1)}
                </Title>
                <List>
                  {tasks.map((task) => (
                    <List.Item key={task.id}>
                      <Text>
                        {task.title} - {task.type}
                      </Text>
                    </List.Item>
                  ))}
                </List>
              </Paper>
            </Grid.Col>
          ))}
        </Grid>
      </Paper>
    );
  };

  const { data: allTaskGroups, refetch, isLoading } = useTaskGroups({ params: params.toString() });
  useEffect(() => {
    if (allTaskGroups) {
      setData(allTaskGroups.data.items);
      const selectedRows = allTaskGroups.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allTaskGroups]);

  const columnHelper = useMemo(() => createColumnHelper<TaskGroup>(), []);
  const columns: ColumnDef<TaskGroup, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allTaskGroups?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allTaskGroups?.data.meta.ids ? allTaskGroups?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.display({
        id: 'expander',
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <UnstyledButton
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
              }}
            >
              {row.getIsExpanded() ? <TbChevronUp size={16} /> : <TbChevronDown size={16} />}
            </UnstyledButton>
          ) : (
            ''
          );
        },
      }),
      columnHelper.accessor((row) => `${row.id}`, { header: 'ID', id: 'id' }),
      columnHelper.accessor('name', { header: () => 'Name', id: 'name' }),
      columnHelper.display({
        id: 'progress',
        header: 'Progress',
        cell: ({ row }) => {
          return (
            <Group position="center">
              <RingProgress
                size={80}
                thickness={16}
                sections={Object.entries(groupBy(row.original.tasks, (i) => i.status)).map(([status, tasks]) => ({
                  value: (tasks.length / row.original.tasks.length) * 100,
                  color: TaskStatusColor[status.split(' ').join('_') as keyof typeof TaskStatusColor],
                tooltip: `${status} - ${(tasks.length / row.original.tasks.length) * 100}% - ${tasks.length} tasks`,
                }))}
              />
            </Group>
          );
        },
      }),
      columnHelper.display({
        id: 'total',
        header: 'Total',
        cell: ({ row }) => {
          return (
            <Text>
                  {row.original.tasks.length}
            </Text>
          );
        },
      }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    'Request Date': 'date',
    'Completion Date': 'date',
  };
  //   const toSelect = {
  //     Status: TaskGroupStatus,
  //     Type: TaskGroupType,
  //     'recipient Group': UserRole,
  //   };

  return (
    <Layout>
      <Group px={10} mt={10} mb={20} position="apart">
        <Title
          order={2}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          TaskGroups:
        </Title>
        <AddMultipleButton add={() => navigate('/new/task-group')} />
      </Group>
      <BaseTable<TaskGroup>
        withExpandepleRows={true}
        renderSubComponent={renderSubComponent}
        data={data}
        loading={isLoading}
        ids={ids}
        columns={columns}
        setIds={setIds}
        page={page}
        //   csvExport={<ExportTaskGroups ids={ids} />}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        pages={allTaskGroups?.data.meta.ids.length ? Math.ceil(allTaskGroups?.data.meta.ids.length / 10) : 1}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'expander')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        //   toSelect={toSelect}
        defaultVisibleColumns={{
          id: false,
          'organization.parent.name': false,
          'recipient.firstName': false,
          'approvedBy.firstName': false,
          'issuedBy.firstName': false,
          'performedBy.firstName': false,
          recipientGroup: false,
          address: false,
          jobRemarks: false,
          taskGroupRemarks: false,
          location: false,
        }}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        columnTypes={columnTypes}
      />
      {ids.length === 1 && (
        <SideBar id={ids} type={types.TaskGroup}>
          <TaskGroupCard id={ids[0]} />
        </SideBar>
      )}
    </Layout>
  );
}
