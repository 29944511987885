import { Button, Container, Group, LoadingOverlay, Paper, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import Layout from "../../../components/layout/Layout";
import { DownloadAsCsv, Format, URLParams, getAllKeysWithPath, getKeysWithPath } from "../../../utils";
import { DeviceStatus } from "../../devices";
import { PurchaseOrderStatus } from "../../finance";
import { OrganizationStatus } from "../../organizations";
import { SimStatus } from "../../sim-cards";
import { TaskStatus } from "../../tasks";
import { useUnits } from "../api";

export interface ClientsActiveUnitsCount {
    name: string;
    parentName: string | null;
    platformCounts: {
        name: string;
        count: number;
    }[];
}

export function ExportActiveUnits(){
    const [allActiveUnits, setAllActiveUnits] = useState<any>([]);
    const [data, setData] = useState<ClientsActiveUnitsCount[]>([]);
    const params = new URLParams();
    params.includes(
        'deviceConfig',
        'device',
        'simCard',
        'vehicle',
        'vehicle.organization as org',
        'org.parent as orgParent',
        'tasks',
        'purchaseOrderPlan',
    );
    params.select(
        'id',
        'deviceConfig.id',
        'deviceConfig.platform',
        'deviceConfig.secondaryPlatform',
        'vehicle.id',
        'vehicle.plateNumber',
        'vehicle.vin',
        'simCard.id',
        'simCard.status',
        'simCard.number',
        'device.id',
        'device.status',
        'device.imei',
        'org.id',
        'org.name',
        'org.status',
        'orgParent.id',
        'orgParent.name',
        'purchaseOrderPlan.id',
        'purchaseOrderPlan.status',
        'tasks.id',
        'tasks.type',
        'tasks.status',
    );
    params.filterSetLevel(2, '&')
    params.filterSetField('vehicle.id', 'null', 2)
    params.filterSetField('device.id', 'null', 2)
    params.filterSetField('simCard.id', 'null', 2)
    params.filterSetField('simCard.status', `!${SimStatus.Deactivated}` , 2)
    params.filterSetField('org.status', `!${OrganizationStatus.Not_Active}` , 2)
    params.filterSetField('device.status', `!${DeviceStatus.Broken}`, 2)
    params.filterSetField('=device.status', `!${DeviceStatus.Lost}`, 2)
    params.filterSetField('=device.status', `!${DeviceStatus.Not_Active}`, 2)
    params.filterSetLevel(3, '&')
    params.filterSetField('unit.id', `SQL<>(select unit.id where tasks.type = 'removal' AND tasks.status = 'pending')`, 3)
    params.filterSetField('unit.id', `SQL<>(select unit.id where tasks.type = 'removal' AND tasks.status = 'to be approved')`, 3)
    params.filterSetLevel(4, '|')
    params.filterSetField('=purchaseOrderPlan.status', PurchaseOrderStatus.OnGoing, 4)
    params.filterSetField('=purchaseOrderPlan.status', 'null', 4)
    const {
    data: allUnits,
    isLoading,
    } = useUnits({
    params: params.toString(),
    });
    useEffect(() => {
    if (allUnits) {
        const platformsList = allUnits.data.items.reduce((acc, unit) => {
            if(!acc.find(i => i === `${unit.deviceConfig?.platform}${unit.deviceConfig?.secondaryPlatform ? `_${unit.deviceConfig?.secondaryPlatform}` : ''}`)) acc.push(`${unit.deviceConfig?.platform}${unit.deviceConfig?.secondaryPlatform ? `_${unit.deviceConfig?.secondaryPlatform}` : ''}`)
            return acc
        }, [] as string[])


        const organizationsList = allUnits.data.items.reduce((acc, unit) => {
            if(!acc.find(i => i.name === unit.vehicle?.organization.name)) {
                // console.log(platformsList)
                const obj: Record<string,number> = {}
                let total = 0
                platformsList.forEach(i => {
                    const units = allUnits.data.items.filter(j => (`${j.deviceConfig?.platform}${j.deviceConfig?.secondaryPlatform ? `_${j.deviceConfig?.secondaryPlatform}` : ''}` === i) && j.vehicle?.organization.name === unit.vehicle?.organization.name).length
                    total += units
                    obj[i] = units
                })
                
                acc.push({name: unit.vehicle?.organization.name, parentName: unit.vehicle?.organization.parent?.name ?? null,  ...obj, total: total})
            } 
            return acc
        }, [] as any[])
        // console.log(organizationsList)
        setData(organizationsList)
        setAllActiveUnits(allUnits.data.items.reduce((acc, unit) => {
            const completionDate = unit.tasks?.reverse().find(i => i.type === 'installation' && i.status === TaskStatus.Done)?.completionDate
            const obj: any = {
                id: unit.id,
                device: unit.device,
                vehicle: unit.vehicle,
                simCard: unit.simCard,
                deviceConfig: unit.deviceConfig,
                remarks: unit.remarks,
                lastcompletedinstallationDate:  completionDate ? new Date(completionDate) : ''
            }
            acc.push(obj)
            return acc
        }, [] as any[])
        )
        // console.log(allUnits.data.items.length, allUnits.data.items);
    }
    }, [allUnits]);
    
    return (
        <Layout>
             <Container >
             <Title
                order={2}
                align="left"
                mb="md"
                sx={(theme: any) => ({
                fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                fontWeight: 800,
                })}
            >
                Active Clients Units:
            </Title>
                <Paper withBorder shadow="md" p={30} my={30} radius="md" >
                    <LoadingOverlay visible={isLoading} overlayBlur={2} />
                    <Group position='center' w={'100%'}>
                        <Button onClick={() => DownloadAsCsv(data, 'Active Clients Units', getKeysWithPath(getAllKeysWithPath(data)).map(key => {
                            return {path: key, alias: key}
                        }, [] as Format[]))}>Download Count</Button>
                        <Button onClick={() => DownloadAsCsv(allActiveUnits, 'Active Clients Units', getKeysWithPath(getAllKeysWithPath(allActiveUnits)).map(key => {
                            return {path: key, alias: key}
                        }, [] as Format[]))}>Download Active Units</Button>
                        
                    </Group>
                </Paper>
             </Container>
        </Layout>
    )
}