
import Layout from '../../../components/layout/Layout';
import { UpdateVehicleTypeForm } from '../components';


export function UpdateVehicleTypeFormPage() {

  return (
    <Layout>
      <UpdateVehicleTypeForm  idM={0}/>
    </Layout>
  );
}
