import api from "../../api/api";
import { ISuser, LoginFormInput } from "../types/types";

export const AUTH_SIGN_IN = "sign.in";

interface myRes {
	data: ISuser;
}

export const signIn = (data: LoginFormInput): Promise<myRes> => {
	return api.post(`auth/signin`, data);
};
