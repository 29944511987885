import { Container, Paper, ScrollArea, Table, createStyles } from '@mantine/core';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { useUnit } from '../api';
  
  
  const useStyles = createStyles((theme) => ({
    header: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
    detailsContainer: {
      width: '100%',
      maxWidth: 'inherit',
      overflow: 'never',
    },
  }));

  export function UnitMiniTasksTable({
    id,
  }: {
    id:number,
  }) {
    const { classes } = useStyles();

    const {  data } = useUnit(id, {
      joins: ['tasks'],
      selects: ['id','tasks.id', 'tasks.completionDate', 'tasks.status', 'tasks.type'], 
    });
    
    const rows = data?.data.tasks?.reverse().map((task) => {
        return(

            <tr key={task.id}>
                <td>{task.type}</td>
                <td>{task.status}</td>
                <td>{simpleDateFormat(task.completionDate) ?? 'Not Finished'}</td>
           </tr>
        )
    })

      
    return (
      <Paper withBorder mt='sm'>
        <Container 
        className={classes.detailsContainer}
        m={0}
        p={0}
        pos="relative"
        >
          <ScrollArea h={100}>
              <Table p={5} >
                  <thead className={classes.header}>
                      <tr>
                          <th>Type</th>
                          <th>Status</th>
                          <th>CompletionDate</th>
                      </tr>
                  </thead>
                  <tbody>
                      {rows}
                  </tbody>
              </Table>
          </ScrollArea>    
        </Container>
      </Paper>
    );
  }
  