import { useEffect, useState } from "react"
import { Iunit, useUnits } from "../../modules/unit"
import { DownloadAsCsv, Format, URLParams, dateToNewDate, getAllKeysWithPath, getKeysWithPath } from "../../utils"
import { ExportToCsvButton } from "../table/components"

export function ExportUnits({
    ids,
}:{
    ids: number[]
}){
    const defaultColumns = ['id' ,'remarks','device:id', 'device:imei', 'simCard:id', 'simCard:number', 'vehicle:id', 'vehicle:vin', 'vehicle:plateNumber', 'vehicle:organization:id', 'vehicle:organization:name', 'deviceConfig:id', 'deviceConfig:platform', 'deviceConfig:secondaryPlatform']
    const unit = {
        id: 0,
        remarks: null,
        device: {
            id: 0,
            imei: 0,
            ownership: "",
            status: "",
            purchaseDate: new Date(),
            deviceType: {
                id: 0,
                supplier: "",
                type: "",
                generation: "",
            },
        },
        simCard: {
            id: 0,
            number: "",
            serial: "",
            imsi: "",
            status: "",
            purchaseDate: new Date(),
            simCardType: {
                id: 0,
                supplier: "",
                type: "",
            },
        },
        vehicle: {
            id: 0,
            category: "",
            plateNumber: 0,
            placeOfIssue: "",
            vin: "",
            color: "",
            odometerKM: 0,
            odometerHours: 0,
            licenseIssueDate: new Date(),
            licenseExpiryDate: new Date(),
            vehicleType: {
                id: 0,
                model: "",
                brand: "",
                year: 0,
                type: "",
                category: "",
            },
            organization: {
                id: 0,
                name: "",
                abbreviation: "",
                parent: {
                    id: 0,
                    name: "",
                },
            },
        },
        deviceConfig: {
            id: 0,
            platform: "",
            secondaryPlatform: "",
        },
    }

    const [ fetch, setFetch ] = useState(false)
    const [ fileName, setFileName ] = useState('')
    const [params, setParams] = useState<URLParams>(() => {
        const param = new URLParams()
        param.includes(
        'deviceConfig',
        'device',
        'device.deviceType as deviceType',
        'simCard',
        'simCard.simCardType as simType',
        'vehicle',
        'vehicle.vehicleType as vehicleType',
        'vehicle.organization as org',
        'org.parent as orgParent',
        );
        
        param.sort(('-unit.id'))
        return param
      })
    // params.se
    const { data: allUnits, refetch } = useUnits({params: params.toString(), enabled: fetch});
    useEffect(() => {
        if (allUnits) {
          DownloadAsCsv(dateToNewDate(allUnits.data.items as Iunit[], ['purchaseDate', 'licenseIssueDate', 'licenseExpiryDate']), fileName, getKeysWithPath(getAllKeysWithPath(allUnits.data.items)).map(key => {
            return {path: key, alias: key}
          }, [] as Format[]))
        }
      }, [allUnits]);

    return(
        <ExportToCsvButton keys={getKeysWithPath(unit,[], ':')} setDefaultColumns={defaultColumns} onSubmit={(v => {
            setFileName(v.fileName)
            const paths = Object.values(v.columns).reduce((cols, item) => {
                    const path = handleReplacePath(item.path, [{originalPath: 'device.deviceType', replaceTo: 'deviceType'}, {originalPath: 'simCard.simCardType', replaceTo: 'simType'}, {originalPath: 'vehicle.organization.parent', replaceTo: 'orgParent'}, {originalPath: 'vehicle.organization', replaceTo: 'org'}, {originalPath: 'vehicle.vehicleType', replaceTo: 'vehicleType'}])
                    
                    if (item?.checked) cols.push(path)
                    return cols
                  }, [] as string[])
            setParams(param => {
                param.select(...paths)
                param.resetSearchMany()
                param.searchMany('id', ids.map(id => `${id}`))
                // param.filterRemoveSelects()
                // param.filterRemoveAllField('=id')
                // ids.forEach(id => {
                //     param.filterSetLevel(1 , '|')
                //     param.filterSetField('=id', id, 1)
                // })
                return param
            })
            
            if(!fetch){
                setFetch(true)
            } else refetch()
        })}/>
    )
}
function handleReplacePath(path: string, replaces:{originalPath: string, replaceTo: string}[]){
    const found = replaces.find(item => path.includes(item.originalPath))
    const paths = path.split('.')
    const column = paths[paths.length-1]
    if(found) return found.replaceTo+'.'+column
    else return path
}