import { useEffect, useState } from "react";
import { SelectEditable } from "../../../components/form";
import { URLParams, selectColumns } from "../../../utils";
import { usePreSets } from "../api";
import { PresetColumnsMode } from "../constants";

export function PreSetValue(
    {
        type,
        setValue,
    }:
    {
        type:string
        setValue: (data: string) => void
    }){
    const [preSetsSelect, setPreSetsSelect] = useState([
        { value: '', label: '' },
        ]);
    
        const presetParams = new URLParams();
        presetParams.filterSetField('=type', type)
        presetParams.select(...selectColumns(PresetColumnsMode))
        const { data: presetsData } = usePreSets({params: presetParams.toString()});
        useEffect(() => {
        if (presetsData) {
            const tempArr = presetsData.data.items.map((preset) => {
            return { value: `${preset.content}`, label: `${preset.title}}` };
            });
            tempArr.push({
                value: '',
                label: 'No Preset',
            });
            setPreSetsSelect(tempArr);
        }
        }, [presetsData]);
        return(
            <SelectEditable withinPortal data={preSetsSelect} onChange={(v) => {
                v && setValue(v)
            }} />
        )
}