import { TextInput, TextInputProps } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useEffect } from "react";
import { TableFilterMenu } from "./TableFilterMenu";

function DebouncedInput({
  label,
  value,
  setValue,
  filterData,
  ...props
}: TextInputProps & {
  label: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>
  filterData?: (column: string, data: string, type: string) => void,
}) {
  
  const [debounced] = useDebouncedValue(value, 200, {leading:false});

  useEffect(() => {
      filterData && filterData(label, debounced, 'string')
  }, [debounced])

  return (
      <TextInput
      rightSection={<TableFilterMenu 
        column={label} 
        columnType={''} 
        filterData={filterData}
        setValue={(value) => {
          setValue(value)
        }}
        />
      } 
        value={value}
        onChange={(event) => {
          setValue(event.currentTarget.value)
        } }
        {...props}
      />
  );
}

export default DebouncedInput;
