import { Checkbox, Modal, Text, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { FaEdit } from 'react-icons/fa';
import { ExportVehicles } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, setTableParams } from '../../../utils';
import { simpleDateFormat } from '../../../utils/simpleDateFormat';
import { useVehicles } from '../api';
import { VehicleCard } from '../components';
import { placeOfIssue } from '../constants';
import { EditMultipleVehicles } from '../forms/EditMultipleVehicles';
import { IVehicleUnits, IVehicleWithUnitsString } from '../types/types';

export function VehicleTable() {
  const [page, setPage] = useState(1);
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState<IVehicleWithUnitsString[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams();
    param.includes('vehicleType', 'organization', 'organization.parent as orgParent', 'unit', 'unit.device as unitDevice');
    param.sort('-vehicle.id');
    param.page(10, page - 1);
    return param;
  });
  const { data: allVehicles, refetch, isLoading } = useVehicles({ params: params.toString() });

  useEffect(() => {
    if (allVehicles) {
      const temp = (allVehicles.data.items as unknown as IVehicleUnits[]).map((item) => {
        const obj = JSON.parse(JSON.stringify(item));
        delete obj.unit;
        obj.unit = item.unit
          ? Object.values(item.unit)
              .filter((item) => item.device)
              .map((u) => `${u.device?.imei}`)
              .join(' - ')
          : '';
        return obj;
      });
      setData(temp);
      const selectedRows = allVehicles.data.items.map((item) => {
        if (ids.includes(item.id)) return { index: true };
      });
      setRowSelection(selectedRows);
    }
  }, [allVehicles]);

  const [opened, { open, close }] = useDisclosure(false);

  const customActions = [
    { id: 'multiEdit', multi: true, title: 'Edit Multiple', icon: FaEdit, color: 'orange', handler: open, noDisplay: ['record'] },
  ];

  const columnHelper = useMemo(() => createColumnHelper<IVehicleWithUnitsString>(), []);
  const columns: ColumnDef<IVehicleWithUnitsString, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',

        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allVehicles?.data.meta.ids.length;
                if (allSelected) {
                  setIds([]);
                } else setIds(allVehicles?.data.meta.ids ? allVehicles?.data.meta.ids : ids);
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex((id) => id === row.original.id);
                  if (rowId === -1) {
                    setIds([...ids, row.original.id]);
                  } else {
                    setIds((ids) => {
                      ids.splice(rowId, 1);
                      return ids;
                    });
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.id, { header: 'ID', id: 'id' }),
      columnHelper.accessor((row) => row.vin, { header: 'vin', id: 'vin' }),
      columnHelper.accessor((row) => row.organization.name, { header: 'Organization', id: 'organization.name' }),
      columnHelper.accessor((row) => row.organization.parent?.name, { header: 'Parent', id: 'orgParent.name' }),
      columnHelper.accessor((row) => row.category, { header: 'Category', id: 'category' }),
      columnHelper.accessor((row) => row.plateNumber, { header: 'Plate', id: 'plateNumber' }),
      columnHelper.accessor((row) => row.placeOfIssue, { header: 'Place of Issue', id: 'placeOfIssue' }),
      columnHelper.accessor((row) => row.vehicleType.id, { header: 'VehicleType id', id: 'vehicleType.id' }),
      columnHelper.accessor((row) => row.vehicleType.brand, { header: 'brand', id: 'vehicleType.brand' }),
      columnHelper.accessor((row) => row.vehicleType.model, { header: 'model', id: 'vehicleType.model' }),
      columnHelper.accessor((row) => row.vehicleType.type, { header: 'type', id: 'vehicleType.type' }),
      columnHelper.accessor((row) => row.vehicleType.year, { header: 'Year', id: 'vehicleType.year' }),
      columnHelper.accessor((row) => (row.odometerKM ? row.odometerKM : ''), { header: 'Odometer KM', id: 'odometerKM' }),
      columnHelper.accessor((row) => (row.odometerHours ? row.odometerHours : ''), { header: 'Odometer Hours', id: 'odometerHours' }),
      columnHelper.accessor('color', { header: () => 'Color', id: 'color' }),
      columnHelper.accessor((row) => row.unit, { header: 'unit', id: 'unitDevice.imei' }),
      columnHelper.accessor((row) => (row.licenseIssueDate ? simpleDateFormat(row.licenseIssueDate) : ''), {
        header: 'license Issue Date',
        id: 'licenseIssueDate',
      }),
      columnHelper.accessor((row) => (row.licenseExpiryDate ? simpleDateFormat(row.licenseExpiryDate) : ''), {
        header: 'license Expiry Date',
        id: 'licenseExpiryDate',
      }),
    ],
    [columnHelper, data, ids],
  );

  const columnTypes = {
    'license Issue Date': 'date',
    'license Expiry Date': 'date',
  };

  const toSelect = {
    'Place of Issue': placeOfIssue,
  };

  return (
    <Layout>
      <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
        Vehicles:
      </Title>
      <BaseTable<IVehicleWithUnitsString>
        data={data}
        ids={ids}
        loading={isLoading}
        columns={columns}
        toSelect={toSelect}
        csvExport={<ExportVehicles ids={ids} />}
        page={page}
        setPage={(value) => {
          setParams((param) => {
            param.filterRemovePages();
            param.page(10, value - 1);
            return param;
          });
          setPage(value);
        }}
        pages={allVehicles?.data.meta.ids.length ? Math.ceil(allVehicles?.data.meta.ids.length / 10) : 1}
        filterData={(column, value, type) => {
          setTableParams(column, value, type, setParams);
          refetch();
          setPage(1);
        }}
        globalFilterData={(data, headers) => {
          const newHeaders = headers
            .filter((header) => header && header !== 'select' && header !== 'actions' && header !== 'Active')
            .join('|');
          setTableParams(newHeaders, data, '', setParams);
          refetch();
          setPage(1);
        }}
        setIds={setIds}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        defaultVisibleColumns={{
          'vehicleType.id': false,
          color: false,
          odometerHours: false,
          odometerKM: false,
          year: false,
          licenseIssueDate: false,
          licenseExpiryDate: false,
        }}
        columnTypes={columnTypes}
      />
      <SideBar id={ids} type={types.Vehicle} customActions={customActions}>
        <VehicleCard id={ids[0]} />
      </SideBar>
      <Modal
        opened={opened}
        onClose={close}
        size="90%"
        title={
          <Text sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800, fontSize: '1.625rem' })}>
            Edit Multiple Sim Cards
          </Text>
        }
      >
        {ids.length < 100 && <EditMultipleVehicles ids={ids} />}
        {ids.length >= 100 && (
          <Text align="center" fw={500} fz="lg">
            Please Select less than 100 Vehicles to perform Edit Multiple
          </Text>
        )}
      </Modal>
    </Layout>
  );
}
