import { Checkbox, Title } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { ExportVehicleTypes } from '../../../components/exportToCSV';
import Layout from '../../../components/layout/Layout';
import { SideBar } from '../../../components/sideBar';
import BaseTable from '../../../components/table/BaseTable';
import { types } from '../../../constants';
import { URLParams, setTableParams } from '../../../utils';
import { useVehicleTypes } from '../api';
import { VehicleTypeCard } from '../components';
import { IvehicleType } from '../types/types';

export function VehicleTypeTable() {
  const [page, setPage] = useState(1)
  const [rowSelection, setRowSelection] = useState({})
  const [data, setData] = useState<IvehicleType[]>([]);
  const [ids, setIds] = useState<number[]>([])
  const [params, setParams] = useState<URLParams>(() => {
    const param = new URLParams()
    param.page(10, page-1)
    return param
  })
  const { data: allVehicleTypes, refetch, isLoading } = useVehicleTypes({params: params.toString()});

  useEffect(() => {
    if (allVehicleTypes) {
      setData(allVehicleTypes.data.items);
      const selectedRows = allVehicleTypes.data.items.map((item) => {
        if(ids.includes(item.id)) return {index: true}
      })
      setRowSelection(selectedRows)
    }
  }, [allVehicleTypes]);

  const columnHelper = useMemo(() => createColumnHelper<IvehicleType>(), []);
  const columns: ColumnDef<IvehicleType, any>[] = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        
        header: ({ table }) => (
          <Checkbox
            mt={'md'}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (event) => {
                const allSelected = ids.length === allVehicleTypes?.data.meta.ids.length
                if(allSelected){
                  setIds([])
                }else setIds(allVehicleTypes?.data.meta.ids ? allVehicleTypes?.data.meta.ids : ids)
                table.getToggleAllRowsSelectedHandler()(event);
              },
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const rowId = ids.findIndex(id => id === row.original.id)
                  if(rowId === -1){
                    setIds([...ids, row.original.id])
                  }else {
                    setIds(ids => {
                      ids.splice(rowId,1)
                      return ids
                    })
                  }
                  row.getToggleSelectedHandler()(event);
                },
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.id, { header: 'ID', id:'id' }),
      columnHelper.accessor((row) => row.brand, { header: 'Brand', id: 'brand' }),
      columnHelper.accessor((row) => row.model, { header: "Model", id: 'model' }),
      columnHelper.accessor((row) => row.type, { header: "Type", id: 'type' }),
      columnHelper.accessor((row) => row.category, { header: "Category", id: 'category' }),
      columnHelper.accessor((row) => row.year, { header: "Year", id: 'year' }),
    ],
    [columnHelper, data, ids],
  );


  return (
    <Layout>
      <Title order={2} align="left" mb="md" sx={(theme: any) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 800 })}>
        Vehicle Types:
      </Title>
      <BaseTable<IvehicleType> data={data} 
      loading={isLoading}
      csvExport={<ExportVehicleTypes ids={ids} />}
      page={page}
      ids={ids}
      setPage={(value) => {
        setParams((param) => {
          param.filterRemovePages()
          param.page(10, value - 1)
          return param
        })
        setPage(value)
       
      }}
      pages={allVehicleTypes?.data.meta.ids.length ? Math.ceil(allVehicleTypes?.data.meta.ids.length/10): 1}
      filterData={(column, value, type)=> {
        setTableParams(column, value, type, setParams)
        refetch()
        setPage(1)
      }}
      globalFilterData={(data, headers) => {
        const newHeaders = headers.filter(header => header && header !== 'select' && header !== 'actions' && header !== 'Active').join('|')
        setTableParams(newHeaders, data, '', setParams)
        refetch()
        setPage(1)
      }}
      columns={columns} setIds={setIds} setRowSelection={setRowSelection} rowSelection={rowSelection} />
      <SideBar id={ids} type={types.VehicleType} >
        <VehicleTypeCard id={ids[0]} />
      </SideBar>
    </Layout>
  );
}
