import {
  ActionIcon,
  Alert,
  Button,
  Container,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Table,
  TextInput,
  Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  TbAlertOctagon,
  TbAlertTriangle,
  TbCircleCheck,
  TbDownload,
  TbPlus
} from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
  
  import { UploadCSVButton } from '../../../components/form';
import Layout from '../../../components/layout/Layout';
import { types } from '../../../constants';
import { URLParams, handleDates, noEmptyString, useCsv } from '../../../utils';
import { IaxiosError } from '../../common';
import { useDevices } from '../../devices';
import { useSimCards } from '../../sim-cards';
import { patchCertificates, useCertificates } from '../api';
import { Icertificate } from '../types';
  
  export function EditMultipleAsateelCertificates() {
    const [file, setFile] = useState<File | null>(null);
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const queryClient = useQueryClient();
    // const [units, setUnits] = useState<IfullUnit[]>([])
    const [certificates, setCertificates] = useState<Icertificate[]>([])
    const viewSubmit = document.getElementById('submit')
    const viewTopOfPage = document.getElementById('TopOfPage')
  
    const deviceParams = new URLParams();
  deviceParams.select('id', 'imei');
  const { data: deviceData } = useDevices({ params: deviceParams.toString() });

  const simCardParams = new URLParams();
  simCardParams.select('id', 'number');
  const { data: simCardsData } = useSimCards({params: simCardParams.toString()});
  
  const certificatesParams = new URLParams();
  certificatesParams.includes('vehicle');
  certificatesParams.select('id', 'vehicle.id', 'vehicle.vin')
  const { data: certificateData } = useCertificates({params: certificatesParams.toString()});
    useEffect(() => {
      if (certificateData) setCertificates(certificateData.data.items)
    }, [certificateData])

    const requirments = {
        vehicle: /[A-Za-z0-9]{4,17}$/,
        activationDate: /^^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
        expiryDate: /$|^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
        device: /$|^\d+$/,
        simCard: /$|^\d+$/,
        withDevice: /^$|([Tt][Rr][Uu][Ee]|[Ff][Aa][Ll][Ss][Ee])$/,
        withDate: /^$|([Tt][Rr][Uu][Ee]|[Ff][Aa][Ll][Ss][Ee])$/,
        withPlate: /^$|([Tt][Rr][Uu][Ee]|[Ff][Aa][Ll][Ss][Ee])$/,
        withSimCard: /^$|([Tt][Rr][Uu][Ee]|[Ff][Aa][Ll][Ss][Ee])$/,
        withParent: /^$|([Tt][Rr][Uu][Ee]|[Ff][Aa][Ll][Ss][Ee])$/,
      };
    const templateText = `${Object.keys(requirments).join(',')}\r\n`;
  
    const { csvParser, data, headers, errorRows, validate, setItem, addRow } =
      useCsv(requirments, Object.keys(requirments));
  
    function handleSelectFile(value: File | null) {
      setFile(value);
      if (value) {
        csvParser(value);
      }
    }
  
    useEffect(() => {
      if(errorRows.length === 0){
        viewSubmit?.scrollIntoView()
      }
    }, [data])
  
  
    const {
      mutate: newMutate,
      isLoading: newLoading,
      isSuccess: newSuccess,
    } = useMutation(patchCertificates, {
      onSuccess: () => {
        setAlert(null);
        queryClient.invalidateQueries([types.AsateelCertificate, types.ALL]);
        queryClient.invalidateQueries([types.History, types.ALL], {
          exact: true,
        });
      },
      onError: (data: IaxiosError) => {
        setAlert(data.response.data.message);
      },
    });
  
    function handleSubmit() {
      setAlert(null);
      viewTopOfPage?.scrollIntoView();
      if (data) {
        const tempAlerts: string[] = []
        const newValues = data.map((item) => noEmptyString(item));
        const finalValues = newValues.map((item) => {
          //todo check if need to switch it to number
          const foundCertificate = certificates.find(certificate =>  certificate.vehicle.vin === item.vehicle)
          // if(foundCertificate) tempAlerts.push('vehicle with vin ' + item.vin + ' already has a certifcate')
          if(!foundCertificate) tempAlerts.push('Certificate with vin ' + item.vehicle + ' does not exist in the database')
          if(item.activationDate){
            item.expiryDate = item.expiryDate !== null ? handleDates(item.expiryDate) : handleDates(item.activationDate, 1)
            item.activationDate = handleDates(item.activationDate)
          }
          const foundDevice = deviceData?.data.items.find(device => device.imei === item.device)
          if(!foundDevice) tempAlerts.push('device with imei ' + item.device + ' is not found in data base')
          const foundSim = simCardsData?.data.items.find(simCard => simCard.number === item.simCard)
          if(!foundSim) tempAlerts.push('simCard with number ' + item.simCard + ' is not found in data base')
          return {
            id: foundCertificate?.id,
            activationDate: item.activationDate,
            expiryDate: item.expiryDate,
            device: foundDevice?.id ? { id: foundDevice?.id } : null,
            simCard: foundSim?.id ? { id: foundSim?.id } : null,
          }
        })
        if (tempAlerts.length === 0) {
          newMutate(finalValues);
        } else setAlert(tempAlerts)
      } else {
        setAlert(
          'something went wrong while preparing the data, please reload the page and try again.',
        );
      }
    }

    const rows = data?.map((row, i) => (
      <tr key={'r' + i}>
        {headers.map((key, index) => (
          <td key={key + index}>
            <TextInput
              value={row[key]}
              error={errorRows[index]?.includes(i + 1)}
              variant="unstyled"
              onChange={(v) => setItem(i, key, v.target.value)}
            />
          </td>
        ))}
      </tr>
    ));
  
    return (
      <Layout>
        <Container pt={10} fluid>
          <Title
            order={2}
            pl={5}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Edit Multiple Asateel Certificates:
          </Title>
          <Paper withBorder shadow="md" p={30} my={30} radius="md">
            <Group position="apart">
              <UploadCSVButton file={file} onChange={handleSelectFile}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload CSV
                  </Button>
                )}
              </UploadCSVButton>
              <Button
                mt="md"
                component="a"
                href={`data:text/csv;charset=utf-8,${templateText}`}
                download={'newSimCardsTemplate.csv'}
                leftIcon={<TbDownload size={16} />}
              >
                Download a template
              </Button>
            </Group>
            <Alert
              icon={<TbAlertTriangle size={16} />}
              title="Important!"
              withCloseButton
              color="blue"
              mt="md"
            >
              <List id='TopOfPage'>
                <List.Item>
                  Currently only accepts CSV files, file must include the headers.
                </List.Item>
                <List.Item>vehicle: use vin to know which certificate to update.</List.Item>
                <List.Item>activationDate: must be NOT empty and its format is dd/mm/yyyy.</List.Item>
                <List.Item>expiryDate: can be empty automatically will be one year from activationDate and its format is dd/mm/yyyy.</List.Item>
                <List.Item>device: update device using imei.</List.Item>
                <List.Item>simCard: update simCard using number.</List.Item>
                <List.Item>withDate: true or false.</List.Item>
                <List.Item>withPlate: true or false.</List.Item>
                <List.Item>withDevice: true or false.</List.Item>
                <List.Item>withSimCard: true or false.</List.Item>
                <List.Item>withParent: true or false.</List.Item>
              </List>
            </Alert>
            {data && Object.keys(requirments).length !== headers.length && (
              <Alert
                icon={<TbAlertTriangle size={16} />}
                title="Warning!"
                color="orange"
                mt="md"
              >
                The number of column uploaded is different than expected!
              </Alert>
            )}
            <LoadingOverlay visible={newLoading} overlayBlur={2} />
            {validate() && (
              <Alert
                icon={<TbAlertTriangle size={16} />}
                title="Error!"
                color="red"
                mt="md"
              >
                <List>
                  <List.Item>Errors in the following rows:</List.Item>
                  {errorRows.map((errorRow, i) => (
                    <List.Item key={`error-${headers[i]}`}>
                      {headers[i]}: {errorRow.join(', ')}
                    </List.Item>
                  ))}
                </List>
              </Alert>
            )}
            {newSuccess && (
              <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Created!'}
                color="green"
                mb={10}
                mt="md"
              >
                Successfully added to the database!
              </Alert>
            )}
            {alert && (
              <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                mt="md"
              >
                {Array.isArray(alert) ? (
                  <List>
                    {alert.map((errMsg) => (
                      <List.Item key={errMsg}>{errMsg}!</List.Item>
                    ))}
                  </List>
                ) : (
                  alert + '!'
                )}
              </Alert>
            )}
            {data && (
              <Table striped highlightOnHover mt="md">
                <thead>
                  <tr>
                    {headers.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            )}
            <Group position="right" mt="md">
              <ActionIcon size="lg" variant="subtle">
                <TbPlus size={28} onClick={() => addRow()} />
              </ActionIcon>
            </Group>
            <Button
              id="submit"
              fullWidth
              disabled={!data || validate() || newLoading}
              onClick={handleSubmit}
              mt="md"
            >
              Submit
            </Button>
          </Paper>
        </Container>
      </Layout>
    );
  }
  
  