import { ActionIcon, Center, Group, Input, Paper, Table, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect, useState } from 'react';
import { TbReload } from 'react-icons/tb';
import { SelectEditable } from '../../../../../components/form';
import { URLParams } from '../../../../../utils';
import { SimStatus, useSimCards } from '../../../../sim-cards';
import { Task, technicianTask } from '../../../types/types';

export function SimChangeStepForm({
  form,
  task,
}: {
  form: UseFormReturnType<technicianTask, (values: technicianTask) => technicianTask>;
  task: Task | undefined;
}) {
  const [simCards, setSims] = useState([{ value: '', label: '' }]);

  const simParams = new URLParams();
  simParams.includes('unit');
  simParams.select('id', 'number');
  simParams.filterSetField('status', SimStatus.Available);
  simParams.filterSetField('=unit.id', 'null');
  const { data: simCardsData, refetch } = useSimCards({ params: simParams.toString() });
  useEffect(() => {
    if (simCardsData) {
      const tempArr = simCardsData.data.items.map((simCard) => {
        return {
          value: `${simCard.id}`,
          label: `${simCard.number}`,
        };
      });
      setSims(tempArr);
    }
  }, [simCardsData]);

  return (
    <Paper withBorder shadow="md" p={30} my={30} radius="md" pos={'relative'}>
      {task && task.unit && (
        <>
          <Table mt={12} p={5}>
            <tbody>
              <tr>
                <td>Plate No.:</td>
                <td>{task.unit.vehicle?.plateNumber}</td>
              </tr>
              <tr>
                <td>Vin:</td>
                <td>{task.unit.vehicle?.vin}</td>
              </tr>
              <tr>
                <td>Imei:</td>
                <td>{task.unit.device?.imei}</td>
              </tr>
              <tr>
                <td>Sim Card Number:</td>
                <td>{task.unit.simCard?.number}</td>
              </tr>
            </tbody>
          </Table>
          <SelectEditable
            {...form.getInputProps('simCard.id')}
            required
            label={
              <Group>
                <Input.Label required>Sim Card</Input.Label>
                <ActionIcon onClick={() => refetch()}>
                  <TbReload />
                </ActionIcon>
              </Group>
            }
            placeholder="Search here"
            withAsterisk={false}
            onChange={(v) => {
              v && form.setFieldValue('simCard.id', parseInt(v));
            }}
            searchable
            value={`${form.values.simCard?.id}`}
            limit={20}
            nothingFound="Not found"
            data={simCards}
            clearable
            mt="md"
          />
        </>
      )}
      {task && !task.unit && (
        <Center>
          <Text> Please Contact Techinical Support To Add Unit For Task</Text>
        </Center>
      )}
    </Paper>
  );
}
