import axios from 'axios';
import { useQuery } from 'react-query';
import { types } from '../../../../constants';
import { IimageCollection } from '../types/types';

interface myResImageCollection {
  data: IimageCollection;
}

export const getImageCollection = (id: number): Promise<myResImageCollection> => {
  return axios.get(`${process.env.REACT_APP_IMAGE_REPOSITORY_API_URL}images/${process.env.REACT_APP_IMAGE_REPOSITORY_PATH}/collection/${id}`);
};

export const useImageCollection = (id: number, options?: { then?: (x: myResImageCollection) => any }) => {
  const query = useQuery(
    [types.Images, { id }],
    () =>
      getImageCollection(id).then((collection) => {
        options && options.then && options.then(collection);
        return collection;
      }),
    { enabled: id !== 0 },
  );
  return query;
};
