import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { IvehicleType } from "../types/types";

interface myResVehicleType {
	data: IvehicleType;
}

export const getVehicleType = (id: number, joins?: string[], selects?: string[]): Promise<myResVehicleType> => {
	let params = "";
	if (joins) params = "?joins=".concat(joins.join());
	if (selects) params = params ? params.concat("&selects=".concat(selects.join())) : "?selects=".concat(selects.join());
	return api.get(`vehicle-types/${id}${params}`);
};

export const useVehicleType = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResVehicleType) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.VehicleType, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
			getVehicleType(id, options?.joins, options?.selects).then((vehicleType) => {
				options && options.then && options.then(vehicleType);
				return vehicleType;
			}),
		{ enabled: id !== 0 }
	);
	return query;
};
