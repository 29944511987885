import { useQuery } from "react-query";
import { types } from "../../../constants";
import api from "../../common/api/api";
import { Iticket } from "../types/types";

interface myResTicket {
	data: Iticket
}

export const getTicket = (id: number, joins?: string[], selects?: string[]): Promise<myResTicket> => {
	let params = '';
	if (joins) params = '?joins='.concat(joins.join());
	if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
	return api.get(`tickets/${id}${params}`);
};

export const useTicket = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResTicket) => any }) => {
	const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Ticket, { id }];
	if (options && options.joins) cacheKey.push(...options.joins);
	const query = useQuery(
		cacheKey,
		() =>
		getTicket(id, options?.joins, options?.selects).then((ticket) => {
			options && options.then && options.then(ticket);
			return ticket;
		}),
		{ enabled: id !== 0 },
	);
	return query;
};
