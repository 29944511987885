import api from "../../common/api/api";
import { Iinvoice, IinvoiceInput } from "../types/types";

interface myResInvoice {
	data: Iinvoice;
}

export const postInvoice = (data: IinvoiceInput): Promise<myResInvoice> => {
	return api.post(`invoices`, data);
};

export const postInvoices = (data: Record<string, any>[]): Promise<myResInvoice[]> => {
	return api.post(`invoices/multi`, data);
};
