export function getKeysWithPath(item: Record<string, any>, path: string[] = [], separator = '.') {
  return Object.entries(item).reduce<string[]>((acc, [key, value]) => {
    if (value !== null && typeof value === 'object' && !(Object.prototype.toString.call(value) === '[object Date]')) {
      const temp = getKeysWithPath(value, [...path, key], separator);
      acc.push(...temp);
    } else {
      acc.push(path.length > 0 ? `${path.join(separator)}${separator}${key}` : key);
    }
    return acc;
  }, []);
}

export function getAllKeysWithPath(items: Record<string, any>[]) {
  return items.reduce((acc, item) => {
    acc = getAllKeys(acc, item)
    return acc
  }, {} as Record<string, any>)
}

export function getAllKeys(item: Record<string, any>, newItem: Record<string, any>) {
  return  Object.keys(newItem).reduce((acc, key) => {
    if(!acc[key] && ( typeof newItem[key] !== 'object' || newItem[key] === null || Object.prototype.toString.call(newItem[key]) === '[object Date]')) acc[key] = newItem[key]
    else if((!acc[key] && typeof newItem[key] === 'object' )){
      acc[key] = getAllKeys({}, newItem[key])
    }
    else if(acc[key] && ( typeof newItem[key] !== 'object'  || Object.prototype.toString.call(newItem[key]) === '[object Date]')) acc[key] = newItem[key]
    else if((acc[key] && typeof newItem[key] === 'object' && newItem[key] !== null )){
      acc[key] = getAllKeys(acc[key], newItem[key])
    }
    return acc
  }, item as Record<string, any>)
  // return keys
}

export interface Format {
  path: string;
  alias?: string;
}

export function DownloadAsCsv<T extends Record<keyof T, any>>(data: T[], fileName = 'export', format?: Format[], afterDownload?: () => void) {
  const arr: string[] = [];
  const headers: string[] = [];
  format?.forEach((item) => {
    if (item.alias) {
      headers.push(item.alias);
    } else {
      headers.push(item.path);
    }
  });
  arr.push(headers.join());
  data.forEach((item) => {
    const line: string[] = [];
    //FIXME: not type safe
    format?.forEach((formatItem) => {
      const x: any = formatItem.path.split('.').reduce((obj, prop) => {

        return obj !== null ? obj[prop as keyof T] : obj;
      }, item);
      //Removed "" from headers and elements in lines, it is back!!! and removed .replaceAll(''', '"') for now
      if (x) {
        line.push(x.toString().split(' ').length > 1?`"${x.toString()}"`:x.toString());
      } else {
        line.push('');
      }
    });
    arr.push(line.join());
  });

  const blob = new Blob([arr.join('\r\n')], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', `${fileName}.csv`);
  pom.click();
  if(afterDownload) afterDownload()
}
