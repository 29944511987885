import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Container,
  Group,
  Input,
  List,
  LoadingOverlay,
  Paper,
  Stepper,
  Table,
  Text,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbReload } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DatePickerInputEditable, SelectEditable } from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { useAuthContext } from '../../../../context/AuthContext';
import { URLParams, noEmptyString } from '../../../../utils';
import { IaxiosError } from '../../../common';
import { NewDeliveryNoteForm } from '../../../deliveryNote';
import { useVehicles } from '../../../vehicles';
import { patchTask, useTask } from '../../api';
import { TaskStatus } from '../../constants/constants';
import { Task, TaskInput } from '../../types/types';

export function ReinstallationTaskForm() {
  const { id } = useParams();
  const [idN] = useState(id && !isNaN(+id) ? +id : 0);
  const [task, setTask] = useState<Task>();
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const { user } = useAuthContext();
  const [vehicles, setVehicles] = useState([{ value: '', label: '' }]);

  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));

  const vehicleParams = new URLParams();
  vehicleParams.select('id', 'vin', 'plateNumber');
  const { data: vehiclesData, refetch } = useVehicles({ params: vehicleParams.toString() });
  useEffect(() => {
    if (vehiclesData) {
      const tempArr = vehiclesData.data.items.map((vehicle) => {
        return {
          value: `${vehicle.id}`,
          label: `${vehicle.vin}, ${vehicle.plateNumber ? vehicle.plateNumber : ''}`,
        };
      });
      setVehicles(tempArr);
    }
  }, [vehiclesData]);

  const { data: taskData } = useTask(idN, {
    joins: ['unit', 'unit.deviceConfig as config', 'unit.device as device', 'unit.vehicle as unitVehicle', 'unit.simCard', 'vehicle'],
    selects: [
      'id',
      'vehicle.id',
      'jobRemarks',
      'unit.id',
      'completionDate',
      'config.id',
      'config.platform',
      'config.secondaryPlatform',
      'device.id',
      'device.imei',
      'unitVehicle.id',
      'unitVehicle.vin',
      'unitVehicle.plateNumber',
      'simCard.id',
      'simCard.number',
    ],
    then: (task) => {
      form.setValues({
        vehicle: { id: task.data.vehicle ? task.data.vehicle.id : 0 },
        jobRemarks: task.data.jobRemarks ? task.data.jobRemarks : '',
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : null,
      });
    },
  });

  useEffect(() => {
    if (taskData) {
      setTask(taskData.data);
    }
  }, [taskData]);
  const form = useForm<Partial<TaskInput>>({
    initialValues: {
      vehicle: { id: 0 },
      jobRemarks: '',
      completionDate: new Date(),
    },
  });

  const queryClient = useQueryClient();
  const { mutate: patchMutate, isLoading: patchLoading } = useMutation(patchTask, {
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: `Successfully Updated the task`,
        color: 'green',
        autoClose: 10000,
      });
      nextStep();
      setAlert(null);
      queryClient.invalidateQueries([types.Task, { id: idN }]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: Partial<TaskInput>) {
    setAlert(null);
    if (taskData && task && user) {
      const finalValues = noEmptyString(values);
      patchMutate({ id: idN, data: { ...finalValues, status: TaskStatus.ToBeApproved, performedBy: { id: user.id } } });
    }
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Reinstallation Form:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos={'relative'}>
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg, i) => (
                    <List.Item key={`E-${i}`}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {task && task.unit && (
            <Stepper active={active} onStepClick={setActive}>
              <Stepper.Step label="Reinstallation Task">
                <Table mt={12} p={5}>
                  <tbody>
                    <tr>
                      <td>Plate No.:</td>
                      <td>{task.unit.vehicle?.plateNumber}</td>
                    </tr>
                    <tr>
                      <td>Vin:</td>
                      <td>{task.unit.vehicle?.vin}</td>
                    </tr>
                    <tr>
                      <td>Imei:</td>
                      <td>{task.unit.device?.imei}</td>
                    </tr>
                    <tr>
                      <td>Sim Card Number:</td>
                      <td>{task.unit.simCard?.number}</td>
                    </tr>
                    <tr>
                      <td>Platform:</td>
                      <td>{task.unit.deviceConfig.platform}</td>
                    </tr>
                    <tr>
                      <td>secondary Platform:</td>
                      <td>{task.unit.deviceConfig.secondaryPlatform}</td>
                    </tr>
                  </tbody>
                </Table>
                <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                  <SelectEditable
                    {...form.getInputProps('vehicle.id')}
                    required
                    withAsterisk={false}
                    label={
                      <Group>
                        <Input.Label required>vehicle</Input.Label>
                        <ActionIcon onClick={() => refetch()}>
                          <TbReload />
                        </ActionIcon>
                      </Group>
                    }
                    placeholder="Search here"
                    onChange={(v) => {
                      if (v) form.setFieldValue('vehicle.id', parseInt(v));
                    }}
                    value={`${form.values.vehicle?.id}`}
                    searchable
                    limit={20}
                    nothingFound="Not found"
                    data={vehicles}
                    clearable
                    mt="md"
                  />
                  <DatePickerInputEditable
                    placeholder="Search Date"
                    label="completion Date"
                    {...form.getInputProps('completionDate')}
                    required
                    mt="md"
                  />
                  <Textarea label="Unit Remarks" placeholder="remarks..." mt="md" {...form.getInputProps('jobRemarks')} />
                  <Center mt="lg">
                    <Button type="submit" fullWidth>
                      Confirm Reinstallation
                    </Button>
                  </Center>
                </form>
              </Stepper.Step>

              <Stepper.Step label="Delivery Note">
                <NewDeliveryNoteForm taskID={idN} />
              </Stepper.Step>
            </Stepper>
          )}
          {task && !task.unit && (
            <Center>
              <Text> Please Contact Techinical Support To Add Unit For Task</Text>
            </Center>
          )}
        </Paper>
      </Container>
    </Layout>
  );
}
