
export enum vehicleConstants {
  PlateNumber = 'plate_number',
  Vin = 'vin',
  Odometer = 'odometer',
  Mulkiyya = 'mulkiyya',
  MulkiyyaFront = 'mulkiyya_front',
  MulkiyyaBack = 'mulkiyya_back',
  Before = 'before',
  After = 'after',
  Other = 'other',
}

export enum placeOfIssue {
  Abu_Dhabi = 'AUH',
  Ajman = 'AJM',
  Dubai = 'DXB',
  Fujairah = 'FUJ',
  Ras_Al_Khaimah = 'RAK',
  Sharjah = 'SHJ',
  UmmAlQuwain = 'UAQ',
}

export enum VehicleColumnsMode {
  ID = "id",
  Vin = "vin",
  Category = "category",
  PlateNumber = "plateNumber",
  PlaceOfIssue = "placeOfIssue",
  OdometerKM = "odometerKM",
  OdometerHours = "odometerHours",
  Color = "color",
  ImageRepositoryId = "imageRepositoryId",
  LicenseIssueDate = "licenseIssueDate",
  LicenseExpiryDate = "licenseExpiryDate",
}