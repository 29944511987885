export enum types {
  ALL = 'all',
  Count = 'count',
  Attendance = 'attendance',
  Device = 'device',
  DeviceType = 'device-type',
  DeviceConfig = 'device_config',
  SimCard = 'sim-card',
  SimCareType = 'sim-card-type',
  History = 'history',
  Organization = 'organization',
  OrganizationDetailed = 'organization_detailed',
  Unit = 'unit',
  UnitLastMessage = 'unit_last_message',
  PurchaseOrder = 'purchase_order',
  OrgSummary = 'org_summary',
  PurchasePlan = 'purchase_Plan',
  User = 'user',
  UserProfile = 'user_profile',
  Contacts = 'contacts',
  Record = 'record',
  RFID = 'rfid',
  RFIDList = 'rfid_list',
  RFIDListDetailed = 'rfid_list_detailed',
  Settings = 'settings',
  Task = 'task',
  TaskGroup = 'task_group',
  Vehicle = 'vehicle',
  VehicleType = 'vehicle-type',
  AsateelCertificate = 'asateel_certificate',
  Images = 'images',
  Ticket = 'ticket',
  Comment = 'comment',
  Payment = 'payment',
  DeliveryNote = 'delivery_note',
  Quotation = 'quotation',
  Templete = 'quotationtemplete',
  QuotationContent = 'quotation-content',
  PreSet = 'pre-set',
  Accessories = 'accessories',
  AccessoriesType = 'accessories_type',
  Invoice = 'invoice',
  WialonSearch = 'wialon_search',
}
