import { Container, Group, Paper, Title } from '@mantine/core';
import { useAttendances } from '../../modules/attendance';
import { commonConstants } from '../../modules/common';
import { URLParams } from '../../utils';
import { BaseAvatar } from '../card';
import Layout from '../layout/Layout';

export function AttendanceListWidget() {
  const params = new URLParams();
  params.includes('user', 'user.profiles');
  params.sort('-attendance.id');
  params.filterSetField(
    'day',
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
      .getDate()
      .toString()
      .padStart(2, '0')}`,
  );
  params.filterSetField('timeIn', 'null');
  // params.filterSetField('=timeOut', 'null');
  const { data: allAttendances } = useAttendances({ params: params.toString() });
  const activeUsers = allAttendances?.data.items.map((item) => {
    const profile = item.user.profiles.find((prof) => prof.app === commonConstants.AiosApp);
    return (
      <BaseAvatar
        name={item.user.firstName + ' ' + item.user.lastName}
        img={profile?.imageRepositoryId ?? null}
        key={item.id}
        online={item.timeOut ? false : true}
      />
      // <Paper withBorder>
      //     <Avatar src={img?.path ?? null} />
      //     <Text key={item.id} my='sm'>{(index + 1) + ' - ' + item.user.firstName + ' ' + item.user.lastName}</Text>
      // </Paper>
    );
  });
  return (
    <Layout>
      <Container>
        <Title order={2}>Active Users: </Title>
        <Paper p="lg" withBorder mt="sm">
          <Group>{activeUsers}</Group>
        </Paper>
      </Container>
    </Layout>
  );
}
