import { TransferListData, TransferListItem } from "@mantine/core";
import { createFormContext } from "@mantine/form";

interface RFIDListValues {
	name: string;
	rfids: [TransferListItem[], TransferListItem[]];
	rfidLists: TransferListData;
}

export type SpecifiedValues = RFIDListValues;

// You can give context variables any name
export const [FormProvider, useFormContext, useForm] = createFormContext<SpecifiedValues>();
