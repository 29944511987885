import {
  Alert,
  Button,
  Center,
  Container,
  Divider,
  Grid,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useListState } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { DetailsCard } from '../../../../components/detailsCards';
import { SelectEditable, TextInputEditable } from '../../../../components/form';
import { types } from '../../../../constants';
import { URLParams, enumToSelectData } from '../../../../utils';
import { IaxiosError, UploadButton, UploadButtonMultiple, commonConstants, uploadMultiplefilesProps, useUploadImage } from '../../../common';
import { postOrganization, useOrganizations } from '../../api';
import { OrganizationStatus, OrganizationType } from '../../constants/constants';
import { ContactsInput, IorganizationInput } from '../../types/types';
import { OrganizationCard } from '../organizationCard';
import { ContactSummary } from './components/ContactSummary';
import { NewMultipleContactsForm } from './components/NewMultipleContactForm';

interface Ifiles {
  file: File | null;
  name: string | null;
  tags: string | null;
  folder: string | null;
  itemId: number | null;
  defaultImage?: string;
}

export function NewOrganizationForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [organizationParentsSelect, setOrganizationParentsSelect] = useState([
    { value: '', label: '' },
  ]);
  const [attachments, setAttachments] = useState<uploadMultiplefilesProps[]>([]);
  const [multipleFiles, MultipleFilesHandlers] = useListState<Ifiles>(
    Array(4).fill({ file: null, name: null, tags: null }),
  );
  const [openEdit, setOpenEdited] = useState(-1)
  const [deleteContact, setDeleteContact] = useState(false)
  const [deleteContactID, setDeleteContactID] = useState(-1)




  const [organizationParentId, setOrganizationParentId] = useState(0);
  const { uploadMultiple, progress } = useUploadImage();

  const queryClient = useQueryClient();

  const contacts: ContactsInput = {
    name: '',
    position: '',
    phone: '',
    email: '',
  }

  const form = useForm<IorganizationInput>({
    initialValues: {
      name: '',
      type: '',
      status: OrganizationStatus.Potential,
      tradeLicense: '',
      trn: '',
      trafficFile: '',
      abbreviation: '',
      parent: {
        id: 0,
      },
      contacts: [],
    },
  });

  const orgParams = new URLParams()
  orgParams.select('id', 'name')
  const { data: organizationsData } = useOrganizations({ params: orgParams.toString() });
  useEffect(() => {
    if (organizationsData) {
      const tempArr = organizationsData.data.items.map((organization) => {
        return { value: `${organization.id}`, label: `${organization.name}` };
      });
      tempArr.unshift({ value: `0`, label: 'No parent' });
      setOrganizationParentsSelect(tempArr);
    }
  }, [organizationsData]);

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postOrganization, {
    onSuccess: (data) => {
      setAlert(null);
      const imageRepositoryId = data.data.imageRepositoryId;
      const filestToUpload = [
        ...multipleFiles.filter((item) => item.file !== null),
      ] as uploadMultiplefilesProps[];
      if (imageRepositoryId && filestToUpload.length) {
        uploadMultiple(imageRepositoryId, filestToUpload.map((item) => ({ ...item, itemId: data.data.id })))
        if (attachments.length) uploadMultiple(imageRepositoryId, attachments)
      }
      setOrganizationParentId(0);
      form.reset();
      queryClient.invalidateQueries([types.Organization, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IorganizationInput) {
    setAlert(null);
    const newValues = Object.assign({}, values);

    if (newValues.parent?.id === null || newValues.parent?.id === 0) {
      newValues.parent = null;
    }
    newMutate(newValues);
  }

  const contact = form.values.contacts.map((contact, index) => {
    return openEdit === index ? <NewMultipleContactsForm key={index} form={form} setOpenEdited={setOpenEdited} contactIndex={index} />
      : <ContactSummary key={index} contact={contact} setOpenEdited={setOpenEdited} contactIndex={index} setDeleteContact={setDeleteContact} setDeleteContactID={setDeleteContactID} />
  })
  if (deleteContact) {
    setDeleteContact(false)
    form.values.contacts.splice(deleteContactID, 1)
  }

  return (
    <>

      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Organiztion:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              label="Organization"
              placeholder="Organization Name"
              {...form.getInputProps('name')}
              onChange={(v) => {
                form.setFieldValue('name', v.target.value);
                if (v.target.value.trim().split(' ').length > 1) {
                  const value = v.target.value.trim().split(' ').flatMap((item) => item[0].toUpperCase()).join('')
                  form.setFieldValue('abbreviation', value)
                } else {
                  const value = v.target.value.trim().slice(0, 3)
                  form.setFieldValue('abbreviation', value)
                }
              }}
              required
            />
            <SelectEditable
              label="Type"
              placeholder="Search for Organization"
              {...form.getInputProps('type')}
              data={enumToSelectData(OrganizationType)}
              mt="md"
              required
            />
            <SelectEditable
              label="Status"
              placeholder="Search for Status"
              {...form.getInputProps('status')}
              data={enumToSelectData(OrganizationStatus)}
              mt="md"
              required
            />
            <TextInputEditable
              label="Trade License"
              placeholder="Trade License"
              {...form.getInputProps('tradeLicense')}
              mt="md"

            />
            <TextInputEditable
              label="Traffic File"
              placeholder="Traffic File"
              {...form.getInputProps('trafficFile')}
              mt="md"

            />
            <TextInputEditable
              label="TRN"
              placeholder="TRN"
              {...form.getInputProps('trn')}
              mt="md"

            />
            <TextInputEditable
              label="Name abbreviation"
              placeholder="abbreviation"
              {...form.getInputProps('abbreviation')}
              mt="md"
            />
            <SelectEditable
              label="Parent"
              placeholder="Search for Parent Organization"
              {...form.getInputProps('parent.id')}
              onChange={(v) => {
                form.setFieldValue('parent.id', parseInt(v || '0'));
                setOrganizationParentId(parseInt(v || '0'));
              }}
              searchable
              nothingFound="Not found"
              data={organizationParentsSelect}
              value={`${form.values.parent?.id}`}
              mt="md"
            />
            <Title order={4} mt="md">
              Contacts:
            </Title>
            <Divider size="md" />
            {contact}
            {openEdit === -1 && <Center>
              <Button radius="xs" size="xs" m='md' ml={0} onClick={() => { setOpenEdited(form.values.contacts.length), form.insertListItem('contacts', contacts) }}>
                Add Contact
              </Button>
            </Center>}

            <UploadButton file={multipleFiles[0].file} onChange={(v) => {
              const profileIMG = {
                file: v,
                name: `${form.values.name}_${commonConstants.Profile}`,
                tags: `${types.Organization},${commonConstants.Profile}`,
                folder: types.Organization,
                itemId: 0,
                defaultImage: 'true'
              };
              MultipleFilesHandlers.setItem(0, profileIMG);
            }}
              progress={progress}>
              {(props) => (
                <Button {...props} mt="md">
                  Upload profile image
                </Button>
              )}
            </UploadButton>

            <Title order={4} mt='md'>Attachments:</Title>
            <Group position='apart'>
              <UploadButton file={multipleFiles[1].file} onChange={(v) => {
                const tradeLicense = {
                  file: v,
                  name: form.values.tradeLicense,
                  tags: form.values.tradeLicense,
                  folder: types.Organization,
                  itemId: 0,
                };
                MultipleFilesHandlers.setItem(1, tradeLicense);
              }}
                progress={progress}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload Trade License
                  </Button>
                )}
              </UploadButton>
              <UploadButton file={multipleFiles[2].file} onChange={(v) => {
                const trafficFile = {
                  file: v,
                  name: form.values.trafficFile,
                  tags: form.values.trafficFile,
                  folder: types.Organization,
                  itemId: 0,
                };
                MultipleFilesHandlers.setItem(2, trafficFile);
              }}
                progress={progress}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload traffic File
                  </Button>
                )}
              </UploadButton>
              <UploadButton file={multipleFiles[3].file} onChange={(v) => {
                const trn = {
                  file: v,
                  name: form.values.trn,
                  tags: form.values.trn,
                  folder: types.Organization,
                  itemId: 0,
                };
                MultipleFilesHandlers.setItem(3, trn);
              }}
                progress={progress}>
                {(props) => (
                  <Button {...props} mt="md">
                    Upload TRN
                  </Button>
                )}
              </UploadButton>
              <UploadButtonMultiple files={attachments.map(item => item.file)} onChange={(v) => {
                const values = v.map(item => {
                  return { file: item, name: '', tags: '', folder: types.Organization, itemId: 0 }
                })
                setAttachments(values)
              }
              } progress={progress}>
                {(props) => (
                  <Button {...props} mt="md">
                    Others
                  </Button>
                )}
              </UploadButtonMultiple>
            </Group>

            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Grid>
        <Grid.Col lg={6}>
          {organizationParentId !== 0 && (
            <DetailsCard
              id={organizationParentId}
              cardHei={400}
              image={
                organizationsData &&
                organizationsData.data &&
                organizationsData.data.items.filter(
                  (org) => org.id === organizationParentId,
                )[0].imageRepositoryId
              }
              withImage
            >
              <OrganizationCard id={organizationParentId} />
            </DetailsCard>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
}
