import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Container,
  Group,
  Input,
  List,
  LoadingOverlay,
  Modal,
  Paper,
  Table,
  Text,
  Textarea,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { TbAlertOctagon, TbReload } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DatePickerInputEditable, SelectEditable } from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { URLParams, enumToSelectData, noEmptyString } from '../../../../utils';
import { IaxiosError } from '../../../common';
import { DeviceStatus } from '../../../devices';
import { DevicePlatforms, UnitWithAllExtras, WialonExternalId, useUnits } from '../../../unit';
import { patchUnitDeviceChange } from '../../../unit/api/patchUnitTaskBased';
import { useTask } from '../../api';
import { TaskStatus } from '../../constants/constants';
import { Task } from '../../types/types';

interface deviceChange {
  newUnit: number;
  jobRemarks: string | null;
  platform: string | null;
  secondaryPlatform: string | null;
  completionDate: Date | null;
  deviceStatus: string;
}

export function DeviceChangeApprovalForm() {
  const { id } = useParams();
  const [idN] = useState(id && !isNaN(+id) ? +id : 0);
  const [task, setTask] = useState<Task>();
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const [wialonOpened, { open: openWialon, close: closeWialon }] = useDisclosure(false);

  const [units, setUnits] = useState([{ value: '', label: '' }]);

  const unitParams = new URLParams();
  unitParams.filterSetField('device', 'null');
  unitParams.filterSetField('=vehicle', 'null');
  unitParams.filterSetField('simCard', 'null');
  unitParams.includes('device', 'simCard', 'deviceConfig');
  unitParams.select(
    'id',
    'device.id',
    'device.imei',
    'simCard.id',
    'simCard.number',
    'deviceConfig.platform',
    'deviceConfig.secondaryPlatform',
  );
  const { data: unitsData, refetch } = useUnits({ params: unitParams.toString() });
  useEffect(() => {
    if (unitsData) {
      const tempArr = unitsData.data.items.map((unit) => {
        return {
          value: `${unit.id}`,
          label: `${unit.device?.imei}, ${unit.simCard?.number}, platform: ${unit.deviceConfig?.platform}, secondary platform: ${
            unit.deviceConfig?.secondaryPlatform ?? ''
          }`,
        };
      });
      setUnits(tempArr);
    }
  }, [unitsData]);

  const { data: taskData } = useTask(idN, {
    joins: [
      'unit',
      'unit.deviceConfig as deviceConfig',
      'installationUnit',
      'unit.device as device',
      'unit.vehicle as vehicle',
      'unit.simCard as simCard',
    ],
    selects: [
      'id',
      'completionDate',
      'jobRemarks',
      'unit.id',
      'device.id',
      'device.imei',
      'simCard.id',
      'simCard.number',
      'vehicle.id',
      'vehicle.vin',
      'vehicle.plateNumber',
      'deviceConfig.id',
      'deviceConfig.platform',
      'deviceConfig.secondaryPlatform',
      'installationUnit.id',
    ],
    then: (task) => {
      form.setValues({
        newUnit: task.data.installationUnit?.id,
        jobRemarks: task.data.jobRemarks ? task.data.jobRemarks : '',
        platform: task.data.unit?.deviceConfig.platform ?? '',
        secondaryPlatform: task.data.unit?.deviceConfig.secondaryPlatform ?? '',
        completionDate: task.data.completionDate ? new Date(task.data.completionDate) : new Date(),
        deviceStatus: DeviceStatus.Not_Active,
      });
    },
  });

  useEffect(() => {
    if (taskData) {
      setTask(taskData.data);
    }
  }, [taskData]);
  const form = useForm<deviceChange>({
    initialValues: {
      newUnit: taskData?.data.installationUnit?.id || 0,
      jobRemarks: taskData?.data.jobRemarks || '',
      platform: taskData?.data.unit?.deviceConfig.platform || '',
      secondaryPlatform: taskData?.data.unit?.deviceConfig.secondaryPlatform || '',
      completionDate: taskData?.data.completionDate ? new Date(taskData?.data.completionDate) : new Date(),
      deviceStatus: DeviceStatus.Not_Active,
    },
  });

  const queryClient = useQueryClient();
  const { mutate: patchMutate, isLoading: patchLoading } = useMutation(patchUnitDeviceChange, {
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: `Successfully Created`,
        color: 'green',
        autoClose: 10000,
      });
      setAlert(null);
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: deviceChange) {
    setAlert(null);
    if (taskData && task) {
      const newValues = {
        newUnit: values.newUnit,
        completionDate: values.completionDate,
        jobRemarks: values.jobRemarks ? values.jobRemarks : null,
        deviceConfig: {
          id: task.unit.deviceConfig.id,
          platform: values.platform,
          secondaryPlatform: values.secondaryPlatform ?? null,
          externalId: task.unit.deviceConfig.externalId,
          rfidList: task.unit.deviceConfig.rfidList,
        },
        deviceStatus: values.deviceStatus,
      };
      const finalValues = noEmptyString(newValues);
      patchMutate({ id: taskData.data.unit.id, data: { ...finalValues, taskId: taskData.data.id, taskStatus: TaskStatus.Done } });
    }
  }

  return (
    <Layout>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Device Change Form:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos={'relative'}>
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg, i) => (
                    <List.Item key={`E-${i}`}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {task && task.unit && task.installationUnit && (
            <>
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td>Plate No.:</td>
                    <td>{task.unit.vehicle?.plateNumber}</td>
                  </tr>
                  <tr>
                    <td>Vin:</td>
                    <td>{task.unit.vehicle?.vin}</td>
                  </tr>
                  <tr>
                    <td>Imei:</td>
                    <td>{task.unit.device?.imei}</td>
                  </tr>
                  <tr>
                    <td>Sim Card Number:</td>
                    <td>{task.unit.simCard?.number}</td>
                  </tr>
                  <tr>
                    <td>Platform:</td>
                    <td>{task.unit.deviceConfig.platform}</td>
                  </tr>
                  <tr>
                    <td>secondary Platform:</td>
                    <td>{task.unit.deviceConfig.secondaryPlatform}</td>
                  </tr>
                </tbody>
              </Table>
              <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <SelectEditable
                  {...form.getInputProps('newUnit')}
                  required
                  withAsterisk={false}
                  label={
                    <Group>
                      <Input.Label required>Device</Input.Label>
                      <ActionIcon onClick={() => refetch()}>
                        <TbReload />
                      </ActionIcon>
                    </Group>
                  }
                  placeholder="Search here"
                  onChange={(v) => {
                    if (v) form.setFieldValue('newUnit', parseInt(v));

                    const found = unitsData?.data.items.find((unit) => v && unit.id === parseInt(v));
                    if (found) {
                      form.setFieldValue('platform', found.deviceConfig.platform);
                      form.setFieldValue('secondaryPlatform', found.deviceConfig.secondaryPlatform || '');
                    }
                  }}
                  value={`${form.values.newUnit}`}
                  searchable
                  limit={20}
                  nothingFound="Not found"
                  data={units}
                  clearable
                />
                <DatePickerInputEditable
                  placeholder="Search Date"
                  label="completion Date"
                  {...form.getInputProps('completionDate')}
                  required
                  mt="md"
                />
                <SelectEditable
                  label="Platform"
                  placeholder="Search here"
                  {...form.getInputProps('platform')}
                  data={enumToSelectData(DevicePlatforms)}
                  clearable
                  mt="md"
                />
                <SelectEditable
                  label="Secondary Platform"
                  placeholder="Search here"
                  {...form.getInputProps('secondaryPlatform')}
                  data={enumToSelectData(DevicePlatforms)}
                  mt="md"
                />

                <SelectEditable
                  dirty={form.isDirty('deviceStatus')}
                  label="removed Device Status"
                  placeholder="Search Here"
                  {...form.getInputProps('deviceStatus')}
                  required
                  data={enumToSelectData(DeviceStatus)}
                  mt="md"
                />
                <Textarea label="Technician Remarks" placeholder="remarks..." mt="md" {...form.getInputProps('jobRemarks')} />
                <Group position="right">
                  <Button color="blue" mt="md" onClick={() => openWialon()}>
                    Connect Wialon
                  </Button>
                </Group>
                <Center mt="lg">
                  <Button type="submit" fullWidth>
                    Confirm Change Device
                  </Button>
                </Center>
              </form>
            </>
          )}
          {task && !task.unit && !task.installationUnit && (
            <Center>
              <Text> Please Contact Techinical Support To Add Unit For Task</Text>
            </Center>
          )}
        </Paper>
      </Container>
      <Modal opened={wialonOpened} onClose={closeWialon} title="Connect With Wialon">
        {taskData && taskData.data.unit.vehicle && <WialonExternalId unit={taskData.data.unit as unknown as UnitWithAllExtras} />}
      </Modal>
    </Layout>
  );
}
