import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  Container,
  Grid,
  Group,
  Input,
  List,
  LoadingOverlay,
  Paper,
  Stepper,
  Text,
  Textarea,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useListState } from '@mantine/hooks';
import { useCallback, useEffect, useState } from 'react';
import { TbAlertOctagon, TbCircleCheck, TbReload } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  DatePickerInputEditable,
  SelectEditable,
  TextInputEditable
} from '../../../../components/form';
import Layout from '../../../../components/layout/Layout';
import { types } from '../../../../constants';
import { URLParams, noEmptyString } from '../../../../utils';
import {
  IaxiosError,
  UploadButton,
  UploadButtonMultiple,
  uploadMultiplefilesProps,
  useUploadImage
} from '../../../common';
import { NewDeliveryNoteForm } from '../../../deliveryNote';
import { useUnits } from '../../../unit';
import { vehicleConstants } from '../../../vehicles';
import { patchTechnicianTask, useTask } from '../../api';
import { ItechnicianTaskInput } from '../../types/types';

//TODO: make the form have only remarks and image if its not instalation or make the form using stepper and use the second step only
//or separate the forms that needs editing and the one that needs only remarks and image

//TODO: do not show the vehicle fields untill the user request to edit it

interface Ifiles {
  file: File | null;
  name: string | null;
  tags: string | null;
  folder: string | null;
  itemId: number | null;
  defaultImage?: string;
}

export function InstallationTaskForm() {
  const { id } = useParams();
  const [idN] = useState(id && !isNaN(+(id)) ? +id : 0);
  const [alert, setAlert] = useState<string | string[] | null>(null);

  const [unitSelect, setUnitSelect] = useState([
    { value: '', label: '' },
  ]);
  
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const [checkValues, setCheckValues] = useState({
    plateNo: false,
    vin: false,
    odo: false,
    mulkiF: false,
    mulkiB: false,
  });

  const queryClient = useQueryClient();

  const [files, setFiles] = useState<File[]>([]);
  const [fileObjects, setFileObjects] = useState<uploadMultiplefilesProps[]>(
    [],
  );
  const [multipleFiles, MultipleFilesHandlers] = useListState<Ifiles>(
    Array(5).fill({ file: null, name: null, tags: null }),
  );

  const {
    uploadMultiple: uploadMultipleBefore,
    loading: loadingBefore,
    progress: progressBefore,
    uploadCompleted: uploadCompletedBefore,
  } = useUploadImage();
  const {
    uploadMultiple: uploadMultipleTask,
    loading: loadingTask,
    progress: progressTask,
  } = useUploadImage();
  const {
    uploadMultiple: uploadMultipleAfter,
    loading: loadingAfter,
    progress: progressAfter,
    uploadCompleted: uploadCompletedAfter,
  } = useUploadImage();

  const { data: taskData } = useTask(idN, {
    joins:['vehicle', 'unit', 'unit.vehicle as UnitVehicle', 'installationUnit'],
    selects: ['id', 'jobRemarks', 'completionDate', 'unit.id','UnitVehicle.id', 'UnitVehicle.imageRepositoryId', 'installationUnit.id', 'title'],
    then: (task) => {
      form.setValues({
        unit: {
          id: task.data.unit ? task.data.unit.id : NaN,
        },
        installationUnit: {
          id: task.data.installationUnit ? task.data.installationUnit.id : NaN,
        },
        completionDate: task.data.completionDate
          ? new Date(task.data.completionDate)
          : new Date(),
      });
      form.resetDirty({
        unit: {
          id: task.data.unit ? task.data.unit.id : NaN,
        },
        installationUnit: {
          id: task.data.installationUnit ? task.data.installationUnit.id : NaN,
        },
        completionDate: task.data.completionDate
          ? new Date(task.data.completionDate)
          : new Date(),
        plateNumberRemarks: '',
        vinRemarks: '',
        odoRemarks: '',
        mukiFRemarks: '',
        mukiBRemarks: '',
        jobRemarks: '',
      });
      remarksHandler(task.data.jobRemarks ? task.data.jobRemarks : '');
    },
  });

  const form = useForm<ItechnicianTaskInput>({
    initialValues: {
      unit: {
        id: taskData?.data.unit
          ? taskData.data.unit.id
          : NaN,
      },
      installationUnit: {
        id: taskData?.data.installationUnit
          ? taskData.data.installationUnit.id
          : NaN,
      },
      plateNumberRemarks: '',
      vinRemarks: '',
      odoRemarks: '',
      mukiFRemarks: '',
      mukiBRemarks: '',
      jobRemarks: '',
      completionDate: taskData?.data.completionDate
        ? new Date(taskData.data.completionDate)
        : new Date(),
    },
    transformValues: (values) => {
      const remarks: string[] = [];
      values.plateNumberRemarks?.length &&
        remarks.push(`<?plateNumberRemarks/:${values.plateNumberRemarks}/>`);
      values.vinRemarks?.length &&
        remarks.push(`<?vinRemarks/:${values.vinRemarks}/>`);
      values.odoRemarks?.length &&
        remarks.push(`<?odoRemarks/:${values.odoRemarks}/>`);
      values.mukiFRemarks?.length &&
        remarks.push(`<?mukiFRemarks/:${values.mukiFRemarks}/>`);
      values.mukiBRemarks?.length &&
        remarks.push(`<?mukiBRemarks/:${values.mukiFRemarks}/>`);
      values.jobRemarks?.length && remarks.push(values.jobRemarks);
      return {
        ...values,
        jobRemarks: remarks.join(''),
      };
    },
  });

  const remarksHandler = useCallback(
    (value: string) => {
      value.split('/>').forEach((part) => {
        if (part.startsWith('<?')) {
          const temp = part.substring(2).split('/:');
          form.setFieldValue(temp[0], temp[1]);
        } else {
          form.setFieldValue('jobRemarks', part);
        }
      });
    },
    [form],
  );


  const unitParams = new URLParams();
  unitParams.includes('device', 'vehicle', 'deviceConfig');
  unitParams.filterSetField('device','null')
  unitParams.filterSetField('=vehicle','null')
  unitParams.filterSetField('simCard','null')
  unitParams.select('unit.id', 'device.id', 'device.imei', 'deviceConfig.id', 'deviceConfig.platform', 'deviceConfig.secondaryPlatform')
  const { data: unitsData, refetch } = useUnits({
    params: unitParams.toString(),
  });
  useEffect(() => {
    if (unitsData) {
      const tempArr = unitsData.data.items.map((unit) => {
        return {
          value: `${unit.id}`,
          label: `${unit.device?.imei}, ${unit.deviceConfig?.platform}, ${unit.deviceConfig?.secondaryPlatform ?? ''}`,
        };
      });
      setUnitSelect(tempArr);
    }
  }, [unitsData]);

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
  } = useMutation(patchTechnicianTask, {
    onSuccess: () => {
      setAlert(null);
      handleBefore()
      queryClient.invalidateQueries([types.Task, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: ItechnicianTaskInput) {
    setAlert(null);
    // const newValues = removeNotEdited(values, form);
    const newValues = noEmptyString(values)
    newValues.jobRemarks = values.jobRemarks;
    if (newValues.installationUnit?.id !== 0) {
      const filestToUpload = [
        ...multipleFiles.filter((item) => item.file !== null),
      ] as uploadMultiplefilesProps[];
      if (newValues.unit && isNaN(newValues.unit.id))
        newValues.unit = null;
      if (idN !== 0 && !Number.isNaN(idN)) {
        patchMutate({ id: idN, data: newValues })
        if (
          taskData &&
          taskData.data.unit.vehicle?.imageRepositoryId &&
          filestToUpload.length
        ) {
          uploadMultipleTask(taskData.data.unit.vehicle?.imageRepositoryId, filestToUpload, () => {
            if (patchSuccess) nextStep()
          })
        }
      } else {
        setAlert(['The id provided is incorrect']);
      }
    }else form.setFieldError('installationUnit.id', 'Incorrect Value')
  }

  function handleBefore() {
    if (
      taskData &&
      taskData.data.vehicle &&
      taskData.data.unit.vehicle?.imageRepositoryId &&
      fileObjects.length
    ) {
      uploadMultipleBefore(
        taskData.data.unit.vehicle?.imageRepositoryId,
        fileObjects,
        () => {
          nextStep();
          setFiles([]);
          setFileObjects([]);
        },
      );
    } else {
      console.log('nothing uploaded');
      nextStep();
    }
  }

  function handleAfter() {
    if (
      taskData &&
      taskData.data.unit.vehicle?.imageRepositoryId &&
      fileObjects.length
    ) {
      nextStep()
      uploadMultipleAfter(
        taskData.data.unit.vehicle?.imageRepositoryId,
        fileObjects,
        () => {
          setFiles([]);
          setFileObjects([]);
        },
      );
    } else {
      console.log('nothing uploaded');
      nextStep()
    }
  }


  return (
    <Layout>
      { taskData?.data.unit ? 
        <Container pt={10} pos='relative'>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Task Form:
        </Title>
        <Paper
          withBorder
          shadow="md"
          p={30}
          my={30}
          radius="md"
          pos={'relative'}
        >
          <LoadingOverlay visible={patchLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg, i) => (
                    <List.Item key={`E-${i}`}>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          <Text mb='lg'>{taskData.data.title}</Text>
          <Stepper
            orientation='vertical'
            active={active}
            onStepClick={(v) => {
              setActive(v);
              setFiles([]);
              setFileObjects([]);
            }}
          >
            <Stepper.Step label="Before" loading={loadingBefore}>
              {uploadCompletedBefore && (
                <Alert
                  icon={<TbCircleCheck size={16} />}
                  title={'Uploaded!'}
                  color="green"
                  mb={10}
                >
                  Successfully uploaded!
                </Alert>
              )}
              <UploadButtonMultiple
                files={files}
                onChange={(v) => {
                  setFiles(v);
                  const beforeFiles = v.reduce<uploadMultiplefilesProps[]>(
                    (acc, file) => {
                      const temp = {
                        file: file,
                        name: vehicleConstants.Before,
                        tags: vehicleConstants.Before,
                        folder: types.Vehicle,
                        itemId: taskData.data.vehicle?.id ?? 0
                      };
                      acc.push(temp);
                      return acc;
                    },
                    [],
                  );
                  setFileObjects(beforeFiles);
                }}
                progress={progressBefore}
              >
                {(props) => (
                  <Button {...props} mt="md" fullWidth>
                    Select images
                  </Button>
                )}
              </UploadButtonMultiple>
              <Group position="center" mt="xl">
                <Button variant="default" disabled onClick={prevStep}>
                  Back
                </Button>
                <Button onClick={nextStep}>Upload & Next step</Button>
              </Group>
            </Stepper.Step>
            <Stepper.Step label="Task" loading={loadingTask}>
              <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <SelectEditable
                  required
                  withAsterisk={false}
                  dirty={form.isDirty('installationUnit.id')}
                  label={<Group><Input.Label required>Device</Input.Label><ActionIcon onClick={() => refetch()}><TbReload /></ActionIcon></Group>}
                  placeholder="Search here"
                  {...form.getInputProps('installationUnit.id')}
                  onChange={(v) => {
                    form.setFieldValue('installationUnit.id', v);
                  }}
                  searchable
                  value={`${form.values.installationUnit ? form.values.installationUnit.id : ''}`}
                  limit={20}
                  nothingFound="Not found"
                  data={unitSelect}
                  clearable
                  mt="md"
                />
                <Grid>
                  <Grid.Col lg={2} sm={2} xs={4} mt="md" pt={15}>
                    <Checkbox
                      checked={checkValues.plateNo}
                      onChange={(event) => {
                        const newCheckValues = Object.assign({}, checkValues);
                        newCheckValues.plateNo = event.currentTarget.checked;
                        setCheckValues(newCheckValues);
                      }}
                      label="No Image"
                    />
                    {!checkValues.plateNo && (
                      <UploadButton
                        file={multipleFiles[0].file}
                        onChange={(v) => {
                          const plateFile = {
                            file: v,
                            tags: vehicleConstants.PlateNumber,
                            name: vehicleConstants.PlateNumber,
                            folder: types.Vehicle,
                            itemId: taskData.data.vehicle?.id ?? 0
                          };
                          MultipleFilesHandlers.setItem(0, plateFile);
                        }}
                        progress={progressTask}
                      >
                        {(props) => (
                          <Button {...props} fullWidth mt="md">
                            Select plate number image
                          </Button>
                        )}
                      </UploadButton>
                    )}
                    {checkValues.plateNo && (
                      <TextInputEditable
                        placeholder="remarks..."
                        {...form.getInputProps('plateNumberRemarks')}
                      />
                    )}
                  </Grid.Col>
                  <Grid.Col lg={2} sm={2} xs={4} >
                    <Checkbox
                      checked={checkValues.vin}
                      onChange={(event) => {
                        const newCheckValues = Object.assign({}, checkValues);
                        newCheckValues.vin = event.currentTarget.checked;
                        setCheckValues(newCheckValues);
                      }}
                      label="No Image"
                    />
                    {!checkValues.vin && (
                      <UploadButton
                        file={multipleFiles[1].file}
                        onChange={(v) => {
                          const vinFile = {
                            file: v,
                            tags: vehicleConstants.Vin,
                            name: vehicleConstants.Vin,
                            folder: types.Vehicle,
                            itemId: taskData.data.vehicle?.id ?? 0
                          };
                          MultipleFilesHandlers.setItem(1, vinFile);
                        }}
                        progress={progressTask}
                      >
                        {(props) => (
                          <Button {...props} fullWidth mt="md">
                            Select vin image
                          </Button>
                        )}
                      </UploadButton>
                    )}
                    {checkValues.vin && (
                      <TextInputEditable
                        placeholder="remarks..."
                        {...form.getInputProps('vinRemarks')}
                      />
                    )}
                  </Grid.Col>
                  <Grid.Col lg={2} sm={2} xs={4} >
                    <Checkbox
                      checked={checkValues.odo}
                      onChange={(event) => {
                        const newCheckValues = Object.assign({}, checkValues);
                        newCheckValues.odo = event.currentTarget.checked;
                        setCheckValues(newCheckValues);
                      }}
                      label="No Image"
                    />
                    {!checkValues.odo && <UploadButton
                      file={multipleFiles[2].file}
                      onChange={(v) => {
                        const odoMeterFile = {
                          file: v,
                          tags: vehicleConstants.Odometer,
                          name: vehicleConstants.Odometer,
                          folder: types.Vehicle,
                          itemId: taskData.data.vehicle?.id ?? 0
                        };
                        MultipleFilesHandlers.setItem(2, odoMeterFile);
                      }}
                      progress={progressTask}
                    >
                      {(props) => (
                        <Button {...props} fullWidth mt='md'>
                          Select odometer image
                        </Button>
                      )}
                    </UploadButton>}
                    {checkValues.odo && (
                      <TextInputEditable
                        placeholder="remarks..."
                        {...form.getInputProps('odoRemarks')}
                      />
                    )}
                  </Grid.Col>
                  <Grid.Col lg={2} sm={2} xs={4}>
                    <Checkbox
                      checked={checkValues.mulkiF}
                      onChange={(event) => {
                        const newCheckValues = Object.assign({}, checkValues);
                        newCheckValues.mulkiF = event.currentTarget.checked;
                        setCheckValues(newCheckValues);
                      }}
                      label="No Image"
                    />
                    {!checkValues.mulkiF && (
                      <UploadButton
                        file={multipleFiles[3].file}
                        onChange={(v) => {
                          const mulkiyyaFrontFile = {
                            file: v,
                            tags: vehicleConstants.MulkiyyaFront,
                            name: vehicleConstants.MulkiyyaFront,
                            folder: types.Vehicle,
                            itemId: taskData.data.vehicle?.id ?? 0
                          };
                          MultipleFilesHandlers.setItem(3, mulkiyyaFrontFile);
                        }}
                        progress={progressTask}
                      >
                        {(props) => (
                          <Button {...props} fullWidth mt='md'>
                            Select Mulkiyya front image
                          </Button>
                        )}
                      </UploadButton>
                    )}
                    {checkValues.mulkiF && (
                      <TextInputEditable
                        placeholder="remarks..."
                        {...form.getInputProps('mukiFRemarks')}
                      />
                    )}
                  </Grid.Col>
                  <Grid.Col lg={2} sm={2} xs={4}>
                    <Checkbox
                      checked={checkValues.mulkiB}
                      onChange={(event) => {
                        const newCheckValues = Object.assign({}, checkValues);
                        newCheckValues.mulkiB = event.currentTarget.checked;
                        setCheckValues(newCheckValues);
                      }}
                      label="No Image"
                    />
                    {!checkValues.mulkiB && (
                      <UploadButton
                        file={multipleFiles[4].file}
                        onChange={(v) => {
                          const mulkiyyaBackFile = {
                            file: v,
                            tags: vehicleConstants.MulkiyyaBack,
                            name: vehicleConstants.MulkiyyaBack,
                            folder: types.Vehicle,
                            itemId: taskData.data.vehicle?.id ?? 0
                          };
                          MultipleFilesHandlers.setItem(4, mulkiyyaBackFile);
                        }}
                        progress={progressTask}
                      >
                        {(props) => (
                          <Button {...props} fullWidth mt='md'>
                            Select Mulkiyya back image
                          </Button>
                        )}
                      </UploadButton>
                    )}
                    {checkValues.mulkiB && (
                      <TextInputEditable
                        placeholder="remarks..."
                        {...form.getInputProps('mukiBRemarks')}
                      />
                    )}
                  </Grid.Col>
                </Grid>
                <DatePickerInputEditable
                  placeholder="Date"
                  label="Completion date"
                  {...form.getInputProps('completionDate')}
                  mt="md"
                />
                <Textarea
                  label="Remarks"
                  placeholder="Remarks"
                  {...form.getInputProps('jobRemarks')}
                  mt="md"
                />
                <Group position="center" mt="xl">
                  <Button variant="default" onClick={prevStep}>
                    Back
                  </Button>
                  <Button type="submit">Submit & Next step</Button>
                </Group>
              </form>
            </Stepper.Step>
            <Stepper.Step label="After" loading={loadingAfter}>
              {uploadCompletedAfter && (
                <Alert
                  icon={<TbCircleCheck size={16} />}
                  title={'Uploaded!'}
                  color="green"
                  mb={10}
                >
                  Successfully uploaded!
                </Alert>
              )}
              <UploadButtonMultiple
                files={files}
                onChange={(v) => {
                  setFiles(v);
                  const afterFiles = v.reduce<uploadMultiplefilesProps[]>(
                    (acc, file) => {
                      const temp = {
                        file: file,
                        name: vehicleConstants.After,
                        tags: vehicleConstants.After,
                        folder: types.Vehicle,
                        itemId: taskData.data.vehicle?.id ?? 0
                      };
                      acc.push(temp);
                      return acc;
                    },
                    [],
                  );
                  console.log('upload', afterFiles);
                  setFileObjects(afterFiles);
                }}
                progress={progressAfter}
              >
                {(props) => (
                  <Button {...props} mt="md" fullWidth>
                    Select images
                  </Button>
                )}
              </UploadButtonMultiple>
              <Group position="center" mt="xl">
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
                <Button onClick={handleAfter}>Upload & Next step</Button>
              </Group>
            </Stepper.Step>
            <Stepper.Step label="Delivery Note">
              <NewDeliveryNoteForm taskID={idN} />
            </Stepper.Step>
          </Stepper>
        </Paper>
      </Container> : <Text> Please Ask Support to Select The Unit</Text>}
    </Layout>
  );
}
