import { ActionIcon, Checkbox, Popover, ScrollArea } from "@mantine/core"
import { TbColumns } from "react-icons/tb"
import { TableInstanceProps } from "../../../types"

export function ColumnVisibilityFilter<Entity extends object>({ instance }: TableInstanceProps<Entity>) {
   return (
      <Popover width={300} position="left-start" shadow="md">
         <Popover.Target>
            <ActionIcon variant='subtle' ><TbColumns /></ActionIcon>
         </Popover.Target>
         <Popover.Dropdown >
            <ScrollArea.Autosize mah={500}>

               <Checkbox label="toggle all" checked={instance.getIsAllColumnsVisible()} onChange={instance.getToggleAllColumnsVisibilityHandler()} />
               {instance.getAllLeafColumns().map((column) => {
                  return (
                     <Checkbox key={column.id} mt='xs' label={column.id.replaceAll('.', ' ')} checked={column.getIsVisible()} onChange={column.getToggleVisibilityHandler()} />
                     )
                  })}
            </ScrollArea.Autosize>
         </Popover.Dropdown>
      </Popover>
   )
}