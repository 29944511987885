import { useCallback, useState } from 'react';

export const useRowIdSelection = <T extends Record<string, any> & { id: number }>() => {
  const [ids, setIds] = useState<number[]>([]);
  const [selectedData, setSelectedData] = useState<T[]>([]);

  const updateRowSelection = useCallback(
    (id: number | number[], method: 'add' | 'remove' = 'add') => {
      if (Array.isArray(id)) {
        if (method === 'add') {
          setIds(id);
        } else {
          setIds([]);
        }
      } else {
        let newArr = [...ids];
        if (method === 'add') {
          newArr.push(id);
        } else {
          newArr = newArr.filter((i) => i !== id);
        }
        setIds(newArr);
      }
    },
    [ids],
  );

  const updateSelectedData = (data: T[]) => {
    setSelectedData(data.filter((item) => ids.includes(item.id)));
  };

  return { ids, updateRowSelection, selectedData, updateSelectedData };
};
