import api from "../../common/api/api";
import { Idevice, IdeviceInput } from "../types/types";

interface myResDevice {
	data: Idevice;
}

export const patchDevice = (props: { id: number; data: Partial<IdeviceInput> }): Promise<myResDevice> => {
	return api.patch(`devices/${props.id}`, props.data);
};

export const patchDevices = (data: Record<string, any>[]): Promise<myResDevice[]> => {
	return api.patch('devices/multi', data);
};