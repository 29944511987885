
import Layout from '../../../components/layout/Layout';
import { NewAccessoryForm } from '../components/NewAccessoryForm';

export function NewAccessoryFormPage() {

  return (
    <Layout>
      <NewAccessoryForm idM={0}/>
    </Layout>
  );
}
