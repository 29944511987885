import {
  Alert,
  Anchor,
  Box,
  Button,
  Center,
  Container,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Text,
  TextInput,
  Title,
  createStyles,
  rem,
} from '@mantine/core';
import { useState } from 'react';
import { TbAlertOctagon, TbArrowLeft, TbCircleCheck } from 'react-icons/tb';
import { useMutation } from 'react-query';
import { IaxiosError } from '../../api';
import { forgotPassword } from '../api';
  
  const useStyles = createStyles((theme) => ({
    title: {
      fontSize: rem(26),
      fontWeight: 900,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
  
    controls: {
      [theme.fn.smallerThan('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        width: '100%',
        textAlign: 'center',
      },
    },
  }));
  
  export function ForgetPassword() {
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const [success, setSucess] = useState<string | string[] | null>(null);
    const [mail, setMail] = useState('')
    const { classes } = useStyles();

    const {
        mutate: newMutate,
        isLoading: newLoading,
        isSuccess: newSuccess,
      } = useMutation(forgotPassword, {
        onSuccess: (data) => {
            setSucess(data.data.message)
          setAlert(null);
        },
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });

    function handleSubmit(){
        if(mail) newMutate({email: mail})
    }
    return (
        <Center h={window.innerHeight}>
            <Container size={460} my={30}>
                <Title className={classes.title} align="center">
                Forgot your password?
                </Title>
                <Text c="dimmed" fz="sm" ta="center">
                Enter your email to get a reset link
                </Text>
                <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                <LoadingOverlay visible={newLoading} overlayBlur={2} />
                {alert && (
                <Alert
                    icon={<TbAlertOctagon size={16} />}
                    title="Error!"
                    color="red"
                    mb={10}
                >
                    {Array.isArray(alert) ? (
                    <List>
                        {alert.map((errMsg) => (
                        <List.Item>{errMsg}!</List.Item>
                        ))}
                    </List>
                    ) : (
                    alert + '!'
                    )}
                </Alert>
                )}
                {newSuccess && (
                <Alert
                    icon={<TbCircleCheck size={16} />}
                    title={'Success!'}
                    color="green"
                    mb={10}
                >
                    {success}
                </Alert>
                )}
                <TextInput label="Your email" placeholder="me@ats.com" required value={mail} onChange={(v) => v && setMail(v.currentTarget.value)} />
                <Group position="apart" mt="lg" className={classes.controls}>
                <Anchor<'a'>
                color="dimmed" 
                href='/logIn' 
                className={classes.control}
                size="sm"
                >
                    <Center inline>
                        <TbArrowLeft size={rem(12)} />
                        <Box ml={5}>Back to the login page</Box>
                    </Center>
                </Anchor>
                <Button className={classes.control} onClick={handleSubmit}>Reset password</Button>
                </Group>
                </Paper>
            </Container>
        </Center>
    );
  }