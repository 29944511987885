import { Alert, Button, List, LoadingOverlay, Paper, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { noEmptyString, removeNotEdited } from '../../../utils';
import { IaxiosError } from '../../common';
import { patchUserProfile } from '../../user-profiles';
import { patchUser } from '../../users';
import { Iprofile } from '../types/types';

interface userProfile {
    firstName: string,
    lastName: string,
    email: string,
    profile: {
        id: number,
        phone: string,
    }
}
export function UpdateUserProfileForm({ profile }: {profile: Iprofile}){
    const [alert, setAlert] = useState<string | string[] | null>(null);
    const form = useForm<userProfile>({
        initialValues: {
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
            profile: {
                id: profile.profile.id,
                phone: profile.profile.phone,
            }
        }
    })
    const queryClient = useQueryClient();
    const {
        mutate: patchMutate,
        isLoading: patchLoading,
        isSuccess: patchSuccess,
      } = useMutation(patchUser, {
        onSuccess: (data) => {
          setAlert(null);
          if(form.isDirty('profile.phone')) {
            patchUserProfile({id: profile.profile.id, data: {phone: form.values.profile.phone}})
            queryClient.invalidateQueries([types.UserProfile, { id: profile.profile.id }]);
        }
          queryClient.invalidateQueries([types.History, types.User, { id: data.data.id }]);
          queryClient.invalidateQueries([types.History, types.ALL], {
            exact: true,
          });
        },
        onError: (data: IaxiosError) => {
          setAlert(data.response.data.message);
        },
      });

    function handleSubmit(values: userProfile) 
        {
        const newValues = noEmptyString(removeNotEdited(values, form));
        if(newValues.profile) newValues.profile.id = profile.profile.id
        patchMutate({id: profile.id, data: newValues})
        }
    return(
        <Paper withBorder p='md'>
            <LoadingOverlay visible={patchLoading} overlayBlur={2} />
            {alert && (
                <Alert
                icon={<TbAlertOctagon size={16} />}
                title="Error!"
                color="red"
                mb={10}
                >
                {Array.isArray(alert) ? (
                    <List>
                    {alert.map((errMsg) => (
                        <List.Item>{errMsg}!</List.Item>
                    ))}
                    </List>
                ) : (
                    alert + '!'
                )}
                </Alert>
            )}
            {(patchSuccess) && (
                <Alert
                icon={<TbCircleCheck size={16} />}
                title={'Updated!'}
                color="green"
                mb={10}
                >
                Successfully updated in the database!
                </Alert>
            )}
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <TextInputEditable 
                label='First Name:'
                dirty={form.isDirty('firstName')}
                mt="sm" 
                {...form.getInputProps('firstName')} 
                />
                <TextInputEditable
                label='Last Name:' 
                dirty={form.isDirty('lastName')}
                mt="sm" 
                {...form.getInputProps('lastName')} 
                />
                <TextInputEditable
                label='Email:' 
                dirty={form.isDirty('email')}
                mt="sm" 
                {...form.getInputProps('email')}
                />
                <TextInputEditable
                label='Phone:'
                dirty={form.isDirty('profile.phone')}
                mt="sm" 
                {...form.getInputProps('profile.phone')}
                />
                <TextInput
                label='Organization:'
                mt="sm"
                disabled
                value={profile.profile.organization.name}
                />
                <Button type='submit' mt='md' fullWidth>Submit</Button>
            </form>
        </Paper>
    )
}