import axios from 'axios';
import { useState } from 'react';
import { imageRepo } from '../../imageRepository/constants/constants';
import { uploadMultiplefilesProps } from '../types/types';

//TODO: cahnge uploadMultiplefilesProps to uploadFilesProps

//TODO: handel the max request size and send multiple requests when multiple files

export const uploadImage = (id: number, formData: FormData, onUploadProgress?: any) => {
  return axios.post(`${process.env.REACT_APP_IMAGE_REPOSITORY_API_URL}images/aios/collection/upload/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const useUploadImage = () => {
  const [progress, setProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * upload a single image and set it as the default image in the collection
   * to upload multiple images use uploadMultiple
   * @param imageRepositoryId id of the collection
   * @param file file to upload
   * @param name file name without the file format
   * @param tags for multiple tags add a ',' as delimiter
   * @param folder name of the folder
   * @param itemId id of the item
   * @param then a callback function on sucess
   */
  const upload = (imageRepositoryId: number, file: File, name: string, tags: string, folder: string, itemId: number, then?: () => any) => {
    setUploadCompleted(false);
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file, name + '.' + file.name.split('.').at(-1));
    formData.append(`${imageRepo.Folder}[0]`, folder);
    formData.append(`${imageRepo.ItemId}[0]`, itemId.toString());
    formData.append(`${imageRepo.Tags}[0]`, tags);
    formData.append(`${imageRepo.DefaultImage}[0]`, 'true');
    uploadImage(imageRepositoryId, formData, (event: any) => setProgress(Math.round((100 * event.loaded) / event.total))).then(
      () => {
        setLoading(false);
        setUploadCompleted(true);
        if (then) then();
      },
      () => {
        setLoading(false);
      },
    );
  };

  /**
   *
   * @param imageRepositoryId id of the collection
   * @param files array of objects that contains file, name, tags, folder, itemId and defaultImage
   * @param then a callback function on sucess
   * only include the defaultImage if you want to set the image as a default
   * if multiple images have defaultImage the last one will be set as a default
   */
  const uploadMultiple = (imageRepositoryId: number, files: uploadMultiplefilesProps[], then?: () => any) => {
    setUploadCompleted(false);
    setLoading(true);
    const formData = new FormData();
    files.forEach((item, index) => {
      formData.append('file', item.file, item.name + '.' + item.file.name.split('.').at(-1));
      formData.append(`${imageRepo.Folder}[${index}]`, item.folder);
      formData.append(`${imageRepo.ItemId}[${index}]`, item.itemId.toString());
      formData.append(`${imageRepo.Tags}[${index}]`, item.tags);
      if (item.defaultImage) formData.append(`${imageRepo.DefaultImage}[${index}]`, item.defaultImage);
    });
    uploadImage(imageRepositoryId, formData, (event: any) => setProgress(Math.round((100 * event.loaded) / event.total))).then(
      () => {
        setLoading(false);
        setUploadCompleted(true);
        if (then) then();
      },
      () => {
        setLoading(false);
      },
    );
  };

  return { upload, progress, loading, uploadCompleted, uploadMultiple };
};
