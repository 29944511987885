import { Alert, Container, createStyles, LoadingOverlay, Paper, Table, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TbAlertOctagon } from 'react-icons/tb';
import { useUnit } from '../api';
import { UnitWithAllExtras } from '../types/types';

const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));

export function UnitCard({
  id,
  setData,
  setImage,
}: {
  id: number;
  setData?: React.Dispatch<React.SetStateAction<UnitWithAllExtras | undefined>>;
  setImage?: React.Dispatch<React.SetStateAction<number | null>>;
}) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);

  const { isLoading, isError, data, refetch } = useUnit(id, {
    joins: [
      'device',
      'device.deviceType',
      'simCard',
      'simCard.simCardType',
      'vehicle',
      'vehicle.vehicleType',
      'vehicle.organization',
      'deviceConfig',
      'deviceConfig.unitLastMessage',
    ],
  });
  useEffect(() => {
    if (data && setImage) {
      setImage(data.data.device?.deviceType?.imageRepositoryId || data.data.simCard?.simCardType?.imageRepositoryId);
      if (setData) setData(data.data as UnitWithAllExtras);
    }
  }, [data, setImage]);

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
      if (setData) setData(data.data as UnitWithAllExtras);
    }
  }, [data, id, oldId, refetch]);

  return (
    <Paper>
      <Container className={classes.detailsContainer} m={0} p={0} pos="relative">
        <Tabs defaultValue="unit">
          <Tabs.List>
            <Tabs.Tab value="unit">unit</Tabs.Tab>
            <Tabs.Tab value="vehicle">vehicle</Tabs.Tab>
            <Tabs.Tab value="device">device</Tabs.Tab>
            <Tabs.Tab value="sim">sim</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="unit">
            {data && data.data && (
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSideTable}>id:</td>
                    <td>{data.data.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Device imei:</td>
                    <td>{data.data.device?.imei}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Number:</td>
                    <td>{data.data.simCard?.number}</td>
                  </tr>
                  {data.data.vehicle && (
                    <tr>
                      <td className={classes.leftSideTable}>Vehicle Vin:</td>
                      <td>{data.data.vehicle.vin}</td>
                    </tr>
                  )}
                  <tr>
                    <td className={classes.leftSubtitleTable}>Device Config:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSubtitleTable}>Device Config ID:</td>
                    <td>{data.data.deviceConfig.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Platform:</td>
                    <td>{data.data.deviceConfig.platform}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>secondary Platform:</td>
                    <td>{data.data.deviceConfig.secondaryPlatform}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>External Id:</td>
                    <td>{data.data.deviceConfig.externalId}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>remarks:</td>
                    <td>{data.data.remarks}</td>
                  </tr>
                </tbody>
              </Table>
            )}
            {isError && (
              <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
                Error Loading data from the server!!
              </Alert>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="vehicle">
            {data && data.data && (
              <Table mt={12} p={5}>
                <tbody>
                  {data.data.vehicle && (
                    <>
                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Vin:</td>
                        <td>{data.data.vehicle.vin}</td>
                      </tr>

                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Plate Number:</td>
                        <td>{data.data.vehicle?.plateNumber}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Category:</td>
                        <td>{data.data.vehicle?.category}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Place Of Issue:</td>
                        <td>{data.data.vehicle?.placeOfIssue}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Organization:</td>
                        <td>{data.data.vehicle.organization.name}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Odometer by KM:</td>
                        <td>{data.data.vehicle?.odometerKM}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Odometer by Hours:</td>
                        <td>{data.data.vehicle?.odometerHours}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Color:</td>
                        <td>{data.data.vehicle?.color}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSubtitleTable}>Vehicle Type:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Brand:</td>
                        <td>{data.data.vehicle.vehicleType?.brand}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Model:</td>
                        <td>{data.data.vehicle.vehicleType?.model}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Vehicle Year:</td>
                        <td>{data.data.vehicle.vehicleType?.year}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            )}
            {isError && (
              <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
                Error Loading data from the server!!
              </Alert>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="device">
            {data && data.data && (
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSubtitleTable}>Device:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>device id:</td>
                    <td>{data.data.device?.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>imei:</td>
                    <td>{data.data.device?.imei}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Status:</td>
                    <td>{data.data.device?.status}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSubtitleTable}>Device Type:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>deviceType id:</td>
                    <td>{data.data.device?.deviceType?.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Device Supplier:</td>
                    <td>{data.data.device?.deviceType?.supplier}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Device Type:</td>
                    <td>{data.data.device?.deviceType?.type}</td>
                  </tr>
                </tbody>
              </Table>
            )}
            {isError && (
              <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
                Error Loading data from the server!!
              </Alert>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="sim">
            {data && data.data && (
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSubtitleTable}>Sim Card:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card id:</td>
                    <td>{data.data.simCard?.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Number:</td>
                    <td>{data.data.simCard?.number}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Serial:</td>
                    <td>{data.data.simCard?.serial}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSubtitleTable}>Sim Card Type:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Type id:</td>
                    <td>{data.data.simCard?.simCardType?.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Supplier:</td>
                    <td>{data.data.simCard?.simCardType?.supplier}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Type:</td>
                    <td>{data.data.simCard?.simCardType?.type}</td>
                  </tr>
                </tbody>
              </Table>
            )}
            {isError && (
              <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
                Error Loading data from the server!!
              </Alert>
            )}
          </Tabs.Panel>
        </Tabs>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        {isError && (
          <Alert icon={<TbAlertOctagon size={16} />} title="Error!" color="red" mb={10}>
            Error Loading data from the server!!
          </Alert>
        )}
      </Container>
    </Paper>
  );
}
