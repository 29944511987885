import { MantineTheme } from "@mantine/core";

export const getChartTheme = (theme: MantineTheme) => {
	const chartLightTheme = {
		textColor: theme.colors.dark[4],
		fontSize: 11,
		axis: {
			domain: {
				line: {
					stroke: theme.colors.dark[3],
					strokeWidth: 1,
				},
			},
			legend: {
				text: {
					fontSize: 12,
					fill: theme.colors.dark[4],
				},
			},
			ticks: {
				line: {
					stroke: theme.colors.dark[3],
					strokeWidth: 1,
				},
				text: {
					fontSize: 11,
					fill: theme.colors.dark[4],
				},
			},
		},
		grid: {
			line: {
				stroke: theme.colors.dark[2],
				strokeWidth: 1,
			},
		},
		legends: {
			title: {
				text: {
					fontSize: 11,
					fill: theme.colors.dark[4],
				},
			},
			text: {
				fontSize: 11,
				fill: theme.colors.dark[4],
			},
			ticks: {
				line: {},
				text: {
					fontSize: 10,
					fill: theme.colors.dark[4],
				},
			},
		},
		annotations: {
			text: {
				fontSize: 13,
				fill: theme.colors.dark[4],
				outlineWidth: 2,
				outlineColor: theme.white,
				outlineOpacity: 1,
			},
			link: {
				stroke: theme.black,
				strokeWidth: 1,
				outlineWidth: 2,
				outlineColor: theme.white,
				outlineOpacity: 1,
			},
			outline: {
				stroke: theme.black,
				strokeWidth: 2,
				outlineWidth: 2,
				outlineColor: theme.white,
				outlineOpacity: 1,
			},
			symbol: {
				fill: theme.black,
				outlineWidth: 2,
				outlineColor: theme.white,
				outlineOpacity: 1,
			},
		},
		tooltip: {
			container: {
				color: theme.colors.dark[4],
				fontSize: 12,
			},
			basic: {},
			chip: {},
			table: {},
			tableCell: {},
			tableCellValue: {},
		},
	};

	const chartDarkTheme = {
		textColor: theme.colors.dark[1],
		fontSize: 11,
		axis: {
			domain: {
				line: {
					stroke: theme.colors.dark[1],
					strokeWidth: 1,
				},
			},
			legend: {
				text: {
					fontSize: 12,
					fill: theme.colors.dark[1],
				},
			},
			ticks: {
				line: {
					stroke: theme.colors.dark[1],
					strokeWidth: 1,
				},
				text: {
					fontSize: 11,
					fill: theme.colors.dark[1],
				},
			},
		},
		grid: {
			line: {
				stroke: theme.colors.dark[3],
				strokeWidth: 1,
			},
		},
		legends: {
			title: {
				text: {
					fontSize: 11,
					fill: theme.colors.dark[1],
				},
			},
			text: {
				fontSize: 11,
				fill: theme.colors.dark[1],
			},
			ticks: {
				line: {},
				text: {
					fontSize: 10,
					fill: theme.colors.dark[1],
				},
			},
		},
		annotations: {
			text: {
				fontSize: 13,
				fill: theme.colors.dark[1],
				outlineWidth: 2,
				outlineColor: theme.black,
				outlineOpacity: 1,
			},
			link: {
				stroke: theme.white,
				strokeWidth: 1,
				outlineWidth: 2,
				outlineColor: theme.black,
				outlineOpacity: 1,
			},
			outline: {
				stroke: theme.white,
				strokeWidth: 2,
				outlineWidth: 2,
				outlineColor: theme.black,
				outlineOpacity: 1,
			},
			symbol: {
				fill: theme.white,
				outlineWidth: 2,
				outlineColor: theme.black,
				outlineOpacity: 1,
			},
		},
		tooltip: {
			container: {
				color: theme.colors.dark[1],
				fontSize: 12,
			},
			basic: {},
			chip: {},
			table: {},
			tableCell: {},
			tableCellValue: {},
		},
	};

	return theme.colorScheme === "dark" ? chartDarkTheme : chartLightTheme;
};
