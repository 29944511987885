import {
  ActionIcon,
  Badge,
  Header as BaseHeader,
  Burger,
  Group,
  Image,
  Menu,
  Text,
  Tooltip,
  createStyles,
  useMantineColorScheme
} from '@mantine/core';

import { useMediaQuery } from '@mantine/hooks';
import { useMemo } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import {
  TbClipboardText,
  TbDeviceDesktop,
  TbLogout,
  TbSelector,
  TbSettings,
  TbUserCircle,
} from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { useAttendances } from '../../modules/attendance';
import { useCountTasks } from '../../modules/tasks';
import { UserRole } from '../../modules/users';
import { URLParams } from '../../utils';
import logo from './../../assets/ats_logo_xs.png';
import { UserButton } from './UserButton';

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  inner: {
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  search: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

interface HeaderProps {
  opened: boolean;
  toggle: () => void;
  links: { link: string; label: string }[];
}

const Header = ({ links, opened, toggle }: HeaderProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { logout, user } = useAuthContext();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const screenSize = useMediaQuery('(min-width: 56.25em)'); 

  const params = new URLParams();
  if (user) {
    params.filterSetField('status', 'pending');
    params.filterSetField('=recipient', user.id);
  }
  const { data: tasks } = useCountTasks({
    params: params.toString(),
  });
  
  const userAttendanceParams = new URLParams();
  if (user) {
    userAttendanceParams.filterSetField('userId', user.id);
    userAttendanceParams.filterSetField('day', `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`);
  }
  const { data: UserAttendanceData } = useAttendances({
    params: userAttendanceParams.toString(),
  });

  const items = useMemo(
    () =>
      links.map((link) => (
        <Link
          key={link.label}
          to={link.link}
          className={classes.link}
          onClick={(event: { preventDefault: () => any }) =>
            event.preventDefault()
          }
        >
          {link.label}
        </Link>
      )),
    [classes.link, links],
  );

  return (
    <BaseHeader height={56} className={classes.header} mb={120}>
      <div className={classes.inner}>
        <Group>
          {user?.role !== UserRole.Technician && user && (
            <Group>
              <Burger opened={opened} onClick={toggle} size="sm" />
            </Group>
          )}
          <Group>
            <Image
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/')}
              mt={10}
              height={screenSize ? 70 : 35}
              src={logo}
            ></Image>
          </Group>
        </Group>
        <Group>
          <Group ml={50} spacing={5} className={classes.links}>
            {items}
          </Group>
          {user && user.role === UserRole.Technician && (
            <Menu width={200}>
              <Menu.Target>
                <UserButton
                  image={user?.img}
                  name={user?.username}
                  role={user?.role}
                  attendance={UserAttendanceData?.data.items.length}
                  icon={<TbSelector size={14} />}
                  style={{ width: 200 }}
                />
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Personal</Menu.Label>
                <Menu.Item
                  onClick={() => navigate('/profile')}
                  icon={<TbUserCircle size={14} />}
                >
                  Profile
                </Menu.Item>
                <Menu.Item onClick={() => navigate('/userAttendance')} icon={<TbClipboardText size={14} />}>
                  <Group position={'apart'}>
                    <Text>attendance</Text>
                    {!UserAttendanceData?.data.items.length && <Badge>1</Badge>
                    }
                  </Group>
                </Menu.Item>
                <Menu.Item onClick={() => navigate('/myTasks')} icon={<TbClipboardText size={14} />}>
                  <Group position='apart'>
                    <Text>My tasks</Text>
                      {tasks?.data &&  <Badge>{tasks.data}</Badge>
                      }
                  </Group>
                </Menu.Item>
                <Menu.Item onClick={() => navigate('/technician/deviceTransfer')} icon={<TbDeviceDesktop size={14} />}>Issued Devices</Menu.Item>
                <Menu.Divider />
                <Menu.Label>Application</Menu.Label>
                <Menu.Item icon={<TbSettings size={14} />}>Settings</Menu.Item>
                <Menu.Item onClick={() => toggleColorScheme()} icon={colorScheme === 'dark' ? (<FaSun size={14} />) : (<FaMoon size={14} />)} >Color Theme</Menu.Item>
                <Menu.Item onClick={() => logout()} icon={<TbLogout size={20} />} >Sign Out</Menu.Item>
                {/* <Menu.Divider />

                <Menu.Label>Danger zone</Menu.Label>
                <Menu.Item icon={<TbArrowsLeftRight size={14} />}>Transfer my data</Menu.Item>
                <Menu.Item color="red" icon={<TbTrash size={14} />}>Delete my account</Menu.Item> */}
              </Menu.Dropdown>
            </Menu>
          )}
          {user && user.role !== UserRole.Technician && 
          <>
            <Tooltip label="Ctrl + J">
              <ActionIcon
                onClick={() => toggleColorScheme()}
                size="xl"
              >
                {colorScheme === 'dark' ? (
                  <FaSun size={20} />
                ) : (
                  <FaMoon size={20} />
                )}
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Sign out">
              <ActionIcon
                onClick={() => logout()}
                size="xl"
              >
                <TbLogout size={20} />
              </ActionIcon>
            </Tooltip>
          </>
          }
        </Group>
      </div>
    </BaseHeader>
  );
};

export default Header;
