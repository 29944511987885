import { useQuery } from 'react-query';
import { types } from '../../../constants';
import api from '../../common/api/api';
import { Task } from '../types/types';

interface myResTask {
  data: Task;
}

export const getTask = (id: number, joins?: string[], selects?: string[]): Promise<myResTask> => {
  let params = '';
  if (joins) params = '?joins='.concat(joins.join());
  if (selects) params = params ? params.concat('&selects='.concat(selects.join())) : '?selects='.concat(selects.join());
  return api.get(`tasks/${id}${params}`);
};

export const useTask = (id: number, options?: { joins?: string[]; selects?: string[]; then?: (x: myResTask) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Task, { id }];
  if (options && options.joins) cacheKey.push(...options.joins);
  const query = useQuery(
    cacheKey,
    () =>
      getTask(id, options?.joins, options?.selects).then((task) => {
        options && options.then && options.then(task);
        return task;
      }),
    { enabled: id !== 0 },
  );
  return query;
};
