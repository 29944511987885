  
  import Layout from '../../../components/layout/Layout';
import { UpdateRfidForm } from '../components/UpdateRfidForm';
    
    export function UpdateRfidFormPage() {
      return (
        <Layout>
          <UpdateRfidForm />
        </Layout>
      );
    }
    