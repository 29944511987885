import { useQuery } from 'react-query';
import { types } from '../../../constants';
import { Param } from '../../../utils';
import { metaData } from '../../common';
import api from '../../common/api/api';
import { Iquotation } from '../types/types';

interface myResQuotations {
  data: {
    items: Iquotation[];
    meta: metaData,
  };
}

export const getQuotations = (query = ''): Promise<myResQuotations> => {
  return api.get(`quotation${query}`);
};

export const useQuotations = (options?: { params?: string; then?: (x: myResQuotations) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date>)[] = [types.Quotation, types.ALL];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    getQuotations(options?.params).then((quotations) => {
      options && options.then && options.then(quotations);
      return quotations;
    }),
  );
  return query;
};

interface myResCountQuotationsNumber {
  data: number;
}

export const countQuotations = (query = ''): Promise<myResCountQuotationsNumber> => {
  return api.get(`quotation/count${query}`);
};

export const useCountQuotations = (options?: { params?: string; then?: (x: myResCountQuotationsNumber) => any }) => {
  const cacheKey: (string | Record<string, number | string | Date | boolean> | Param)[] = [types.Quotation, types.ALL, types.Count];
  if (options && options.params) cacheKey.push(options.params);
  const query = useQuery(cacheKey, () =>
    countQuotations(options?.params).then((quotations) => {
      options && options.then && options.then(quotations);
      return quotations;
    }),
  );
  return query;
};