export enum InvoiceType {
	Installation = 'installation',
    Removal = 'removal',
    Upgrade = 'upgrade',
    Fees = 'fees',
    Other = 'other',
}

export enum InvoiceColumnsMode {
    ID = "id",
    InvoiceNo = "invoiceNo",
    Date = "date",
    VAT = "vat",
    Type = "type",
    Amount = "amount",
    Remarks = "remarks",
}