import { Center, Container, Grid, Group, Loader, Pagination, Select, Title } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseCard, CardsFilter, useDeepFilter } from '../../../components/card';

import { AddMultipleButton } from '../../../components/buttons/AddMultipleButton';
import Layout from '../../../components/layout/Layout';
import { deviceStatusColor } from '../../../constants';
import { URLParams } from '../../../utils';
import { PageSize } from '../../common';
import { useDevices } from '../api';
import { UpdateDeviceForm } from '../components/UpdateDeviceForm';
import { Idevice } from '../types/types';

export function DeviceCards() {
  const params = new URLParams();
  params.includes('deviceType', 'organization');
  const { isSuccess, data } = useDevices({ params: params.toString() });
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const navigate = useNavigate();
  const { filter, setFilter, formattedData } = useDeepFilter(data ? data.data.items : []);

  let cardsLoop;
  if (isSuccess) {
    cardsLoop = formattedData
      .slice((page - 1) * pagesize, pagesize * page)
      .map((item: Idevice) => (
        <BaseCard
          key={`card-${item.id}`}
          name={item.imei.toString()}
          discription={`Supplier: ${item.deviceType.supplier}, Type: ${item.deviceType.type}, Status: ${item.status}, generation: ${item.deviceType.generation}, organization: ${item.organization?.name}`}
          to={`/device/${item.id}`}
          img={item.deviceType.imageRepositoryId}
          label={item.status}
          color={deviceStatusColor[item.status.split(' ').join('_') as keyof typeof deviceStatusColor]}
          updateModalContent={<UpdateDeviceForm idM={item.id} />}
        />
      ));
  }

  return (
    <Layout>
      <Container fluid>
        <Group px={13} mt={10} position="apart">
          <Title
            order={2}
            align="left"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 800,
            })}
          >
            Devices:
          </Title>
          <AddMultipleButton
            add={() => navigate('/new/device')}
            addMany={() => navigate('/new/devices')}
            updateMany={() => navigate('/edit/devices')}
          />
        </Group>
        <CardsFilter setFilter={setFilter} filter={filter} />
        <Grid>
          {formattedData && cardsLoop ? (
            cardsLoop
          ) : (
            <Container p={25}>
              <Loader />
            </Container>
          )}
        </Grid>
        <Center my={15}>
          <Pagination
            total={Math.ceil(formattedData.length / pagesize)}
            siblings={2}
            boundaries={2}
            onChange={(n) => setPage(n)}
            value={page}
          />
          <Select placeholder="Page Size" size="sm" ml={10} data={Object.values(PageSize)} onChange={(e) => setPagesize(Number(e))} />
        </Center>
      </Container>
    </Layout>
  );
}
