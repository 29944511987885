import {
  Alert,
  Button,
  Checkbox,
  Container,
  List,
  LoadingOverlay,
  Paper,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { useState } from 'react';
import { TbAlertOctagon, TbCircleCheck } from 'react-icons/tb';
import { useMutation, useQueryClient } from 'react-query';
import { TextInputEditable } from '../../../components/form';
import { types } from '../../../constants';
import { IaxiosError } from '../../common';
import { postRfid } from '../api';
import { IrfidInput } from '../types/types';

export function NewRfidForm() {
  const [alert, setAlert] = useState<string | string[] | null>(null);
  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      printedValue: '',
      bceValue: '',
      teltonikaValue: '',
      isUsed: false,
    },
  });

  const {
    mutate: newMutate,
    isLoading: newLoading,
    isSuccess: newSuccess,
  } = useMutation(postRfid, {
    onSuccess: (data) => {
      setAlert(null);
      form.reset();
      queryClient.invalidateQueries([types.RFID, types.ALL]);
      queryClient.invalidateQueries([types.History, types.ALL], {
        exact: true,
      });
    },
    onError: (data: IaxiosError) => {
      setAlert(data.response.data.message);
    },
  });

  function handleSubmit(values: IrfidInput) {
    setAlert(null);
    newMutate(values);
  }

  return (
    <>
      <Container pt={10}>
        <Title
          order={2}
          pl={5}
          align="left"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 800,
          })}
        >
          Add a new Rfid:
        </Title>
        <Paper withBorder shadow="md" p={30} my={30} radius="md" pos="relative">
          <LoadingOverlay visible={newLoading} overlayBlur={2} />
          {alert && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              {Array.isArray(alert) ? (
                <List>
                  {alert.map((errMsg) => (
                    <List.Item>{errMsg}!</List.Item>
                  ))}
                </List>
              ) : (
                alert + '!'
              )}
            </Alert>
          )}
          {newSuccess && (
            <Alert
              icon={<TbCircleCheck size={16} />}
              title={'Created!'}
              color="green"
              mb={10}
            >
              Successfully added to the database!
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInputEditable
              label="Printed Value"
              placeholder="Printed Value"
              {...form.getInputProps('printedValue')}
              required
            />
            <TextInputEditable
              label="bce Value"
              placeholder="bce Value"
              {...form.getInputProps('bceValue')}
              required
              mt="md"
            />
            <TextInputEditable
              label="Teltonika Value"
              placeholder="Teltonika Value"
              {...form.getInputProps('teltonikaValue')}
              required
              mt="md"
            />
            <Checkbox
              label="Used"
              {...form.getInputProps('isUsed', { type: 'checkbox' })}
              mt="md"
            />
            <Button fullWidth mt="xl" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
}
