import {
  ActionIcon,
  Alert,
  Container,
  createStyles,
  Group,
  LoadingOverlay,
  Paper,
  Table,
  Text,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { TbAlertOctagon } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { selectColumns } from '../../../utils';
import { DeviceTypeColumnsMode } from '../../device-types';
import { DeviceColumnsMode } from '../../devices';
import { VehicleTypeColumnsMode } from '../../vehicle-types';
import { VehicleColumnsMode } from '../../vehicles';
import { useRecord } from '../api';
import { RecordColumnsMode } from '../constants';

const useStyles = createStyles(() => ({
  detailsContainer: {
    width: '100%',
    maxWidth: 'inherit',
    overflow: 'never',
  },

  leftSubtitleTable: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },

  leftSideTable: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}));

export function RecordCard({ id }: { id: number }) {
  const { classes } = useStyles();
  const [oldId, setOldId] = useState<number | null>(null);
  const navigate = useNavigate();
  

  const { isLoading, isError, data, refetch } = useRecord(id, 
    {
    joins: [ 
    'vehicle', 'vehicle.vehicleType as vehicleType', 'vehicle.organization as org',
    'unit', 'unit.device as device', 'device.deviceType as deviceType', 'unit.simCard as simCard', 'simCard.simCardType as simType',
    'task', 'task.performedBy as performedBy', 'task.approvedBy as approvedBy',
  ],
  selects: [
    ...selectColumns(RecordColumnsMode), ...selectColumns(VehicleColumnsMode, {join: 'vehicle'}), ...selectColumns(VehicleTypeColumnsMode, {join: 'vehicleType'}), 
  'org.id', 'org.name', 'unit.id', ...selectColumns(DeviceColumnsMode, {join: 'device', exclude: ['purchaseDate']}), ...selectColumns(DeviceTypeColumnsMode, {join: 'deviceType', exclude: ['imageRepositoryId']}),
  'task.id', 'performedBy.id', 'performedBy.firstName', 'approvedBy.id', 'approvedBy.firstName',
],
});

  useEffect(() => {
    if (data && id !== oldId) {
      refetch();
      setOldId(id);
    }
  }, [data, id, oldId, refetch]);

  return (
    <>
      <Paper pos="relative">
        <Container className={classes.detailsContainer} m={0} p={0}>
          <LoadingOverlay visible={isLoading} overlayBlur={2} />
          {data && data.data && (
            <>
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSideTable}>id:</td>
                    <td>{data.data.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Performed By:</td>
                    <td>{data.data.task.performedBy?.firstName}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Approved By:</td>
                    <td>{data.data.task.approvedBy?.firstName}</td>
                  </tr>
                </tbody>
              </Table>
              <Text pt={10} style={{ fontWeight: 700 }}>
                Vehicle's Details
              </Text>
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSideTable}>id:</td>
                    <td>{data.data.vehicle.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Plate Number:</td>
                    <td>{data.data.vehicle.plateNumber}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Color:</td>
                    <td>{data.data.vehicle.color}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Category:</td>
                    <td>{data.data.vehicle.category}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Vin:</td>
                    <td>{data.data.vehicle.vin}</td>
                  </tr>
                  {data.data.vehicle.vehicleType && (
                    <>
                      <tr>
                        <td className={classes.leftSubtitleTable}>
                          Vehicle Type:
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Brand:</td>
                        <td>{data.data.vehicle.vehicleType.brand}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Model:</td>
                        <td>{data.data.vehicle.vehicleType.model}</td>
                      </tr>
                      <tr>
                        <td className={classes.leftSideTable}>Year:</td>
                        <td>{data.data.vehicle.vehicleType.year}</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td className={classes.leftSubtitleTable}>Property Of:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Name:</td>
                    <td>
                      <Group position="apart">
                        {data.data.vehicle.organization.name}
                        <ActionIcon
                          onClick={() =>
                            navigate(
                              `/organization/${data.data.vehicle.organization.id}`,
                            )
                          }
                        >
                          <MdOpenInNew size={18} />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Text pt={10} style={{ fontWeight: 700 }}>
                Unit's Details
              </Text>
              <Table mt={12} p={5}>
                <tbody>
                  <tr>
                    <td className={classes.leftSideTable}>Unit id:</td>
                    <td>{data.data.unit.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSubtitleTable}>Device:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>id:</td>
                    <td>{data.data.unit.device?.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>imei:</td>
                    <td>{data.data.unit.device?.imei}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Status:</td>
                    <td>{data.data.unit.device?.status}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSubtitleTable}>Device Type:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>deviceType id:</td>
                    <td>{data.data.unit.device?.deviceType.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Device Supplier:</td>
                    <td>
                      {data.data.unit.device?.deviceType.supplier}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Device Type:</td>
                    <td>{data.data.unit.device?.deviceType.type}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSubtitleTable}>Sim Card:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card ID:</td>
                    <td>{data.data.unit.simCard?.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Number:</td>
                    <td>{data.data.unit.simCard?.number}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Serial:</td>
                    <td>{data.data.unit.simCard?.serial}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSubtitleTable}>
                      Sim Card Type:
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Type id:</td>
                    <td>{data.data.unit.simCard?.simCardType.id}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>
                      Sim Card Supplier:
                    </td>
                    <td>
                      {data.data.unit.simCard?.simCardType.supplier}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Sim Card Type:</td>
                    <td>{data.data.unit.simCard?.simCardType.type}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSubtitleTable}>
                      Device Config:
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Platform:</td>
                    <td>{data.data.platform}</td>
                  </tr>
                  <tr>
                    <td className={classes.leftSideTable}>Secondary Platform:</td>
                    <td>{data.data.secondaryPlatform ?? ''}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          {isError && (
            <Alert
              icon={<TbAlertOctagon size={16} />}
              title="Error!"
              color="red"
              mb={10}
            >
              Error Loading data from the server!!
            </Alert>
          )}
        </Container>
      </Paper>
    </>
  );
}
