import { Badge, Container, Group, Image, TextInput } from "@mantine/core";
import { IcollectionLink } from "../../modules/common";

export function DisplayImg({imageLink}:{imageLink: IcollectionLink}){
    const tags = imageLink.tags.split(',').map(tag => {
        return(
            <Badge key={tag}>{tag}</Badge>
        )
      })
    return (
        <Container >
            <Image 
                fit="contain"
                src={`${process.env.REACT_APP_IMAGE_REPOSITORY_SERVER_URL}${imageLink?.path}`} 
                />
            <Group spacing='sm' position='center' >
                {tags}
            </Group>
            <TextInput label="Change Tags" />
        </Container>
    )
}